import { DOCUMENT_TEMPLATES_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import { isEmpty } from '@/utils'

const name = 'documentTemplates'

function renderIndexContent(h, index, state, error) {
  if (state) {
    return h(
      'g-icon',
      {
        props: {
          value: error ? 'clear' : 'done',
          color: error ? 'error' : 'success',
          size: 20
        }
      }
    )
  } else {
    return index
  }
}

function renderIndex(h, index, state, error, loading) {
  if (loading) {
    return h(
      'g-progress',
      {
        class: 'mr-3',
        props: {
          size: 36,
          indeterminate: true,
          color: 'primary'
        }
      }
    )
  } else {
    return h(
      'g-chip',
      {
        class: 'ml-0 mt-0 mr-3 mb-0',
        props: {
          circle: true,
          large: true,
          outline: true,
          disabled: !state,
          color: error ? 'error' : state ? 'success' : 'primary'
        }
      },
      [ renderIndexContent.call(this, h, index, state, error) ]
    )
  }
}

function renderRequisites(h) {
  return h(
    'div',
    {
      class: 'fr',
      style: { 'min-height': '68px' }
    },
    [
      renderIndex.call(
        this,
        h,
        1,
        this.proxy.requisiteId,
        !this.proxy.requisiteId,
        this.restData.requisites.find.state === 'loading'
      ),

      renderRequisitesField.call(this, h)
    ]
  )
}

function renderRequisitesField(h) {
  switch (this.restData.requisites.find.state) {
    case 'ready': {
      if (this.restData.requisites.find.pagination.total > 1) {
        return h(
          'g-select',
          {
            props: {
              value: this.proxy.requisiteId,
              label: this.getTranslate(`${name}.labels.requisite`),
              service: 'requisites',
              query: this.restData.requisites.find.filter,
              required: true,
              loading: this.restData.requisites.find.state === 'loading',
              disabled: this.restData.requisites.find.state === 'loading',
              requestItems: () => {
                return this.rest.requisites.find(
                  {},
                  {
                    manipulateData: false,
                    manipulateState: false
                  }
                )
              },
              requestItem: () => this.rest.requisites.get(this.proxy.requisiteId)
            },
            on: {
              input: event => {
                this.proxy.requisiteId = event
              }
            }
          }
        )
      } else if (this.restData.requisites.find.pagination.total === 1 && this.requisite.title) {
        return h(
          'div',
          {
            class: 'mt-2 body-1'
          },
          [
            h(
              'span',
              {
                class: 'text--grey mr-1'
              },
              this.getTranslate(`${name}.singular.requisite`) + ':'
            ),

            this.requisite.title
          ]
        )
      } else {
        return h(
          'div',
          {
            style: { 'min-height': '68px' }
          },
          [
            h(
              'span',
              {
                class: 'text--error',
                domProps: {
                  innerHTML: this.OwnerId
                    ? this.getTranslate(`${name}.warnings.notFound.requisitesForAdmin`, { OwnerId: this.OwnerId })
                    : this.getTranslate(`${name}.warnings.notFound.requisites`)
                }
              }
            )
          ]
        )
      }
    }
    case 'loading':
    case 'empty':
    default: {
      return h(
        'span',
        {
          class: 'body-1 text--primary pt-2'
        },
        this.getTranslate(`${name}.hints.searching.requisites`)
      )
    }
  }
}

function renderCompanies(h) {
  if (this.proxy.requisiteId) {
    return h(
      'div',
      {
        class: 'fr',
        style: { 'min-height': '68px' }
      },
      [
        renderIndex.call(
          this,
          h,
          2,
          this.proxy.companyId,
          !this.proxy.companyId,
          this.restData.companies.find.state === 'loading'
        ),

        renderCompaniesField.call(this, h)
      ]
    )
  }
}

function renderCompaniesField(h) {
  switch (this.restData.companies.find.state) {
    case 'ready': {
      if (this.restData.companies.find.pagination.total > 1) {
        return h(
          'g-select',
          {
            props: {
              value: this.proxy.companyId,
              label: this.getTranslate(`${name}.labels.company`),
              service: 'companies',
              query: this.restData.companies.find.filter,
              loading: this.restData.companies.find.state === 'loading',
              disabled: !this.proxy.requisiteId || this.restData.companies.find.state === 'loading',
              required: true,
              requestItems: () => {
                return this.rest.companies.find(
                  {},
                  {
                    manipulateData: false,
                    manipulateState: false
                  }
                )
              },
              requestItem: () => this.rest.companies.get(this.proxy.companyId)
            },
            on: {
              input: event => {
                this.proxy.companyId = event
              }
            }
          }
        )
      } else if (this.restData.companies.find.pagination.total === 1 && this.company.title) {
        return h(
          'div',
          {
            class: 'mt-2 body-1'
          },
          [
            h(
              'span',
              {
                class: 'text--grey mr-1'
              },
              this.getTranslate(`${name}.singular.company`) + ':'
            ),

            this.company.title
          ]
        )
      } else {
        return h(
          'div',
          {
            style: { 'min-height': '68px' }
          },
          [
            h(
              'span',
              {
                class: 'text--error',
                domProps: { innerHTML: this.getTranslate(`${name}.warnings.notFound.companies`) }
              }
            )
          ]
        )
      }
    }
    case 'loading':
    case 'empty':
    default: {
      return h(
        'span',
        {
          class: 'body-1 text--primary pt-2'
        },
        this.getTranslate(`${name}.hints.searching.companies`)
      )
    }
  }
}

function renderTypesField(h) {
  if (this.types.length > 1) {
    return h(
      'g-select',
      {
        props: {
          value: this.proxy.type,
          label: this.getTranslate(`${name}.labels.type`),
          items: this.types.map(type => {
            return {
              title: this.getTranslate(`documentTemplates.types.${type}`),
              value: type
            }
          }),
          required: true
        },
        on: {
          input: event => {
            this.proxy.type = event
          }
        }
      }
    )
  } else if (this.types.length === 1 && this.proxy.type) {
    return h(
      'div',
      {
        class: 'mt-2 body-1'
      },
      [
        h(
          'span',
          {
            class: 'text--grey mr-1'
          },
          this.getTranslate(`${name}.singular.type`) + ':'
        ),

        this.getTranslate(`documentTemplates.types.${this.proxy.type}`)
      ]
    )
  } else {
    return h(
      'div',
      {
        class: 'mt-2',
        style: { 'min-height': '68px' }
      },
      [
        h(
          'span',
          {
            class: 'text--error',
            domProps: { innerHTML: this.getTranslate(`${name}.warnings.notFound.types`) }
          }
        )
      ]
    )
  }
}

function renderTypes(h) {
  if (
    this.proxy.requisiteId &&
    this.proxy.companyId &&
    this.restData.requisites.find.state === 'ready' &&
    this.restData.companies.find.state === 'ready'
  ) {
    return h(
      'div',
      {
        class: 'fr',
        style: { 'min-height': '68px' }
      },
      [ renderIndex.call(this, h, 3, this.proxy.requisiteId, this.types.length === 0), renderTypesField.call(this, h) ]
    )
  }
}

function renderSum(h) {
  if (
    this.restData.requisites.find.state === 'ready' &&
    this.proxy.requisiteId &&
    this.restData.companies.find.state === 'ready' &&
    this.proxy.companyId &&
    this.types.length &&
    this.proxy.type === DOCUMENT_TEMPLATES_TYPES.invoice
  ) {
    return h(
      'div',
      {
        class: 'fr',
        style: { 'min-height': '68px' }
      },
      [
        renderIndex.call(this, h, 4, !isEmpty(this.proxy.data.SERVICE_PRICE), !this.proxy.data.SERVICE_PRICE),

        h(
          'g-text-field',
          {
            props: {
              value: this.proxy.data.SERVICE_PRICE,
              label: this.getTranslate(`${name}.labels.sum`),
              disabled: !this.proxy.type,
              suffix: '&#8381;',
              type: 'number',
              step: 'any',
              clearable: true
            },
            on: {
              input: event => {
                this.proxy.data.SERVICE_PRICE = event
              }
            }
          }
        )
      ]
    )
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: 'fc'
    },
    [ renderRequisites.call(this, h), renderCompanies.call(this, h), renderTypes.call(this, h), renderSum.call(this, h) ]
  )
}
