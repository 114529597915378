import render from './render'

export default {
  name: 'TwoRowString',

  functional: true,

  props: {
    first: String,
    second: String,

    length: {
      type: Number,
      default: 50
    }
  },

  render
}
