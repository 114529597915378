import { generateServices } from '@/utils'

import render from './render'

const _ = {
  merge: require('lodash/merge'),
  debounce: require('lodash/debounce'),
  defaultsDeep: require('lodash/defaultsDeep'),
  camelCase: require('lodash/camelCase')
}

export default function(options = {}) {
  options = Object.assign(
    {
      filterByIsActive: true,
      dense: false
    },
    options
  )

  const find = _.defaultsDeep(
    {
      defaultFilter: {
        $search: '',
        isActive: undefined,
        t: undefined
      },
      appendMode: true
    },
    options.find
  )

  return {
    name: 'GeneratorSearch',

    mixins: [
      generateServices({
        name: options.name,

        inputFilter: options.inputFilter,
        outputFilter: options.outputFilter,

        find,

        cacher: options.cacher,

        get: false,
        create: false,
        update: false,
        remove: false
      })
    ],

    props: {
      maxWidth: {
        type: Number,
        default: 800
      }
    },

    data() {
      return {
        isActive: true,

        showFilter: false
      }
    },

    computed: {
      computedShowSearch() {
        if (this.viewport.breakpoint.mdDown) {
          if (!this.$route.params.id) {
            return true
          } else if (this.$route.params.id) {
            return false
          }
        }

        return true
      },

      showLoadMore() {
        return (
          this.restData[options.name].find.pagination.limit + this.restData[options.name].find.pagination.offset <
          this.restData[options.name].find.pagination.total
        )
      }
    },

    created() {
      this.onScroll = _.debounce(this.onScroll, 500, {
        leading: false,
        trailing: true
      })
    },

    watch: {
      [`restData.${options.name}.find.filter.$search`]() {
        this.restData[options.name].find.pagination.offset = 0
      },

      'account.id'() {
        this.find()
      }
    },

    mounted() {
      this.find()
    },

    methods: {
      onScroll(event) {
        if (
          this.restData[options.name].find.state === 'ready' &&
          event.target.scrollHeight - event.target.clientHeight - event.target.scrollTop <= 160 &&
          this.showLoadMore
        ) {
          this.restData[options.name].find.pagination.offset += this.restData[options.name].find.pagination.limit
        }
      },

      find() {
        const accept = [ 'advanced', options.serviceForCheckPermissions || options.name, options.methodForCheckPermissions || 'find' ].join('.')
        if (this.checkPermissions(accept, [ 'me', 'manager', 'reseller', true ])) {
          const params = {}
          if (options.avoid304) {
            params.query = { t: Date.now() }
          }
          this.rest[options.name].find(params)
        }
      }
    },

    render(h) {
      return render.call(this, h, options)
    }
  }
}
