import { isArrayNotEmpty, tours } from '@/utils'

const _ = { defaultsDeep: require('lodash/defaultsDeep') }

function renderPrevButton(h, tour) {
  if (!tour.isFirst) {
    return h(
      'g-button',
      {
        class: 'mr-2',
        props: {
          label: tour.labels.buttonPrevious,
          flat: true,
          rounded: true,
          color: 'white'
        },
        on: {
          click: () => {
            tour.previousStep()
          }
        }
      }
    )
  }
}

function renderNextButton(h, tour) {
  if (!tour.isLast) {
    return h(
      'g-button',
      {
        class: 'ml-2',
        props: {
          label: tour.labels.buttonNext,
          flat: true,
          rounded: true,
          color: 'white'
        },
        on: {
          click: () => {
            tour.nextStep()
          }
        }
      }
    )
  }
}

function renderFill(h, condition) {
  if (condition) {
    return h('div', { class: 'ff' })
  }
}

export default function(h) {
  if (isArrayNotEmpty(tours)) {
    return h(
      'div',
      {},
      tours.map(item => {
        return h(
          'v-tour',
          {
            props: {
              name: item.name,
              steps: item.steps.map(step => ({
                target: `#v-step-${item.name}-${step.tag}`,
                content: this.getTranslate(step.content),
                params: Object.assign({ enableScrolling: false }, step.params),
                offset: step.offset,
                duration: step.duration
              })),
              options: _.defaultsDeep(item.options, {
                useKeyboardNavigation: true,
                labels: {
                  buttonSkip: this.getTranslate('misc.buttons.close'),
                  buttonPrevious: this.getTranslate('misc.buttons.tour.previous'),
                  buttonNext: this.getTranslate('misc.buttons.tour.next'),
                  buttonStop: this.getTranslate('misc.buttons.tour.stop')
                }
              })
            },
            scopedSlots: {
              default: tour => {
                return h(
                  'transition',
                  {
                    name: 'fade'
                  },
                  tour.steps.map((step, index) => {
                    if (tour.currentStep === index) {
                      return h(
                        'v-step',
                        {
                          class: 'tour-step pa-0',
                          style: {
                            'max-width': this.viewport.breakpoint.mdUp ? '400px' : null,
                            'z-index': 10
                          },
                          props: {
                            step,
                            previousStep: tour.previousStep,
                            nextStep: tour.nextStep,
                            stop: tour.stop,
                            isFirst: tour.isFirst,
                            isLast: tour.isLast,
                            labels: tour.labels
                          },
                          key: `v-step-${index}`
                        },
                        [
                          h(
                            'div',
                            {
                              class: 'pa-3',
                              slot: 'content'
                            },
                            step.content
                          ),

                          h(
                            'g-card-actions',
                            {
                              slot: 'actions'
                            },
                            [
                              renderPrevButton.call(this, h, tour),
                              renderFill.call(this, h, !tour.isFirst),

                              h(
                                'g-button',
                                {
                                  props: {
                                    label: tour.labels.buttonSkip,
                                    flat: true,
                                    rounded: true,
                                    color: 'white'
                                  },
                                  on: {
                                    click: () => {
                                      tour.stop()
                                    }
                                  }
                                }
                              ),

                              renderFill.call(this, h, !tour.isLast),
                              renderNextButton.call(this, h, tour)
                            ]
                          )
                        ]
                      )
                    }
                  })
                )
              }
            }
          }
        )
      })
    )
  }
}
