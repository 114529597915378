import { serviceTemplate } from '@/utils'

import { serviceName } from '@/components/services/servers/utils'

export default {
  mixins: [
    serviceTemplate({
      serviceName,

      sizeChanger: true
    })
  ]
}
