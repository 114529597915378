import { isPhoneNumber } from '@sigma-legacy-libs/essentials/lib/validators'
import { DICTIONARIES_STATUSES } from '@sigma-legacy-libs/essentials/lib/constants'

const searchResultHeaders = [ [ 'number', 'prefix', 'operator' ], [ 'mcc', 'mnc', 'region' ] ]

function renderSearchResultItem(h, value) {
  return h(
    'div',
    {
      class: 'faic caption mb-1',
      style: { 'min-width': '150px' }
    },
    [
      h('div', { class: 'text--grey' }, value.title),
      h('div', { class: 'ml-2' }, value.content)
    ]
  )
}

function renderSearchResultIcon(h) {
  return h(
    'g-icon',
    {
      style: {
        'min-width': '48px',
        'min-height': '48px'
      },
      props: {
        value: 'subject',
        color: 'grey',
        size: 22
      }
    }
  )
}

function renderSearchResult(h) {
  if (this.searchResult) {
    return h(
      'div',
      {
        class: 'faifs'
      },
      [
        renderSearchResultIcon.call(this, h),

        h(
          'div',
          {
            class: {
              'pt-2 pr-3 pb-3': true,
              fr: this.viewport.breakpoint.mdUp,
              fc: this.viewport.breakpoint.smDown
            }
          },
          searchResultHeaders.map((headers, index) => {
            return h(
              'div',
              {
                class: {
                  'fc fjcfs facfs': true,
                  'pr-1': index === 0
                }
              },
              headers.map(header => {
                if (this.searchResult[header]) {
                  return renderSearchResultItem.call(this, h, {
                    title: header,
                    content: this.searchResult[header]
                  })
                }
              })
            )
          })
        )
      ]
    )
  }

  if (this.searchError) {
    return h(
      'div',
      {
        class: 'faic'
      },
      [
        renderSearchResultIcon.call(this, h),
        h('div', { class: 'text--grey' }, this.getTranslate('misc.nothingFound'))
      ]
    )
  }
}

export default function(h) {
  if (~[ DICTIONARIES_STATUSES.compiled, DICTIONARIES_STATUSES.activated ].indexOf(this.dictionary.status)) {
    return h(
      'div',
      {
        class: 'fc'
      },
      [
        h(
          'g-form',
          {
            class: 'faic',
            on: {
              submit: () => {
                this.search()
              },
              validation: () => {
                if (isPhoneNumber(this.searchValue).isValid) {
                  this.searchValueIsValid = true
                } else {
                  this.searchValueIsValid = false
                }
              }
            }
          },
          [
            h(
              'g-text-field',
              {
                props: {
                  backgroundColor: 'transparent',
                  value: this.searchValue,
                  placeholder: this.getTranslate('misc.search'),
                  details: false,
                  mode: 'solo',
                  flat: true,
                  loading: this.loading,
                  beforeIcon: 'search',
                  beforeIconCallback: () => {
                    this.search()
                  }
                },
                on: {
                  input: event => {
                    if (!this.searchValue) {
                      this.searchResult = undefined
                    }

                    this.searchValue = event
                  }
                }
              }
            ),

            h(
              'g-button',
              {
                props: {
                  label: this.getTranslate('misc.search'),
                  flat: true,
                  rounded: true,
                  type: 'submit',
                  color: 'primary',
                  loading: this.loading,
                  disabled: !this.searchValueIsValid
                }
              }
            )
          ]
        ),

        renderSearchResult.call(this, h)
      ]
    )
  }
}
