import Vue from 'vue'

import buttonFavorite from '@/components/button/favorite'
import serversItemTitle from '@/components/services/servers/item/title'
import serversItemHost from '@/components/services/servers/item/host'

import { serviceName } from '@/components/services/servers/utils'

function renderTitle(h) {
  return h(
    'td',
    {
      class: 'pl-2 w--100'
    },
    [ h(serversItemTitle, { props: { value: this.value } }) ]
  )
}

function renderHost(h) {
  if (this.viewport.breakpoint.mdUp) {
    return h(
      'td',
      {
        style: 'min-width: 300px'
      },
      [ h(serversItemHost, { props: { value: this.value } }) ]
    )
  }
}

function renderButtons(h) {
  return h(
    'td',
    {
      class: 'px-0'
    },
    [
      h(
        'div',
        {
          class: {
            'fjcfe faic': true,
            'hover-child': this.viewport.breakpoint.mdUp
          }
        },
        [
          h(
            buttonFavorite,
            {
              props: {
                id: this.value.id,
                serviceName
              }
            }
          ),

          h(
            'g-button',
            {
              props: {
                flat: true,
                icon: 'edit'
              },
              on: {
                click: () => {
                  Vue.router.push({
                    name: `${serviceName}.single`,
                    params: { id: this.value.id }
                  })
                }
              }
            }
          )
        ]
      )
    ]
  )
}

export default function(h) {
  return h(
    'tr',
    {
      class: 'hover-parent hover-parent--opacity'
    },
    [
      renderTitle.call(this, h),
      renderHost.call(this, h),
      renderButtons.call(this, h)
    ]
  )
}
