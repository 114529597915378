import OwnerSelect from '@/components/misc/OwnerSelect'

import { serviceName } from '@/components/services/yclients/utils'

function renderSalonIdField(h) {
  return h(
    'g-text-field',
    {
      props: {
        value: this.proxy.salonId,
        label: this.getTranslate(`${serviceName}.labels.salon`),
        mode: 'outline',
        dense: true,
        rounded: true,
        required: true,
        autofocus: true,
        details: false
      },
      on: {
        input: event => {
          this.proxy.salonId = event
        }
      }
    }
  )
}

function renderIsActiveField(h) {
  return h(
    'g-switch',
    {
      props: {
        value: this.proxy.isActive
      },
      on: {
        input: event => {
          this.proxy.isActive = event
        }
      },
      directives: [
        {
          name: 'g-tooltip',
          options: {
            value: this.getTranslate(`${serviceName}.labels.isActive`)
          }
        }
      ]
    }
  )
}

function renderTokenId(h) {
  return h(
    'g-select',
    {
      props: {
        value: this.proxy.TokenId,
        label: this.getTranslate(`${serviceName}.labels.token`),
        service: 'tokens',
        query: { OwnerId: this.proxy.OwnerId },
        itemTitle: 'token',
        itemValue: 'token',
        mode: 'outline',
        dense: true,
        rounded: true,
        details: false
      },
      on: {
        input: event => {
          this.proxy.TokenId = event
        }
      }
    }
  )
}

function renderFieldOwner(h) {
  return h(
    OwnerSelect,
    {
      props: {
        value: this.proxy.OwnerId,
        mode: 'outline',
        dense: true,
        rounded: true,
        details: false
      },
      on: {
        input: event => {
          this.proxy.OwnerId = event
        }
      }
    }
  )
}

function renderError(h) {
  if (this.proxy.error) {
    return h(
      'div',
      {
        class: 'grid grid-gap--8 faic border border--dashed border--rounded pa-2',
        style: { 'grid-template-columns': '24px 1fr' }
      },
      [
        h(
          'g-icon',
          {
            props: {
              value: 'error_outline',
              color: 'error'
            }
          }
        ),

        h(
          'div',
          {
            class: 'caption'
          },
          [ this.proxy.error ]
        )
      ]
    )
  }
}

export default function(h) {
  return h(
    'g-form',
    {
      class: 'grid grid-gap--8',
      on: {
        submit: () => {
          this.$emit('submit', this.proxy)
        }
      }
    },
    [
      h(
        'div',
        {
          class: 'grid grid-gap--8 faic',
          style: { 'grid-template-columns': '1fr 36px' }
        },
        [
          renderSalonIdField.call(this, h),
          renderIsActiveField.call(this, h)
        ]
      ),

      renderFieldOwner.call(this, h),
      renderTokenId.call(this, h),
      renderError.call(this, h)
    ]
  )
}
