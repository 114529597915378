import Vue from 'vue'
import Actions from '@sigma-legacy-libs/essentials/lib/utils/Actions'

import row from '@/components/misc/rules/actions/row'
import dialog from '@/components/misc/rules/actions/dialog'

function renderDialog(h) {
  return h(
    dialog,
    {
      props: {
        show: this.showDialog,
        value: this.proxy[this.tag],
        tag: this.tag
      },
      on: {
        dialog: event => {
          this.showDialog = event
        },
        input: event => {
          if (event) {
            Vue.set(this.proxy, this.tag, event)
          }
        },
        tag: event => {
          this.tag = event
        }
      }
    }
  )
}

function renderRow(h, tag) {
  return h(
    row,
    {
      props: {
        tag,
        value: this.proxy[tag]
      },
      on: {
        dialog: event => {
          this.tag = tag
          this.showDialog = event
        },
        clear: () => {
          Vue.delete(this.proxy, tag)
        }
      }
    }
  )
}

function renderRows(h) {
  return h(
    'div',
    {
      class: 'grid grid-cols--1 grid-gap--8 ff'
    },
    Actions.tagsOnly.filter(this.tagFilter).map(tag => renderRow.call(this, h, tag))
  )
}

function renderContent(h) {
  return h(
    'div',
    {
      class: 'grid grid-cols--1 ff'
    },
    [
      renderRows.call(this, h),
      renderDialog.call(this, h)
    ]
  )
}

export default function(h) {
  return renderContent.call(this, h)
}
