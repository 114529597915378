import { PUBLIC_FIELDS_USER } from '@sigma-legacy-libs/essentials/lib/constants'

import { appendFormattedDates, fillDependence, serviceTemplate } from '@/utils'

import { serviceName } from '@/components/services/tokens/utils'

export default {
  mixins: [
    serviceTemplate({
      serviceName,

      async inputFilter(result) {
        if (!result.Owner) {
          await fillDependence.call(this, result, {
            service: 'users',
            permissions: [ 'reseller', true ],
            pathToId: 'OwnerId',
            pathToObject: 'Owner',
            outputPath: 'Owner',
            picks: PUBLIC_FIELDS_USER
          })
        }

        result.OwnerId = result.Owner && result.Owner.id || result.OwnerId

        appendFormattedDates.call(this, result)

        return result
      },

      find: {
        defaultFilter: { OwnerId: undefined }
      },

      type: 'cards',
      viewChanger: false,
      width: 400
    })
  ]
}
