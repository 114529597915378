import { getRUB } from '@/utils'

import UserWidget from '@/components/misc/UserWidget'
import SingleLink from '@/components/misc/SingleLink'

const _ = { get: require('lodash/get') }

function renderIcon(h) {
  let tooltip
  if (this.overdraft > 0) {
    tooltip = this.getTranslate('account.tooltips.warning')
  } else if (this.overdraft === 0) {
    tooltip = this.getTranslate('account.tooltips.error')
  }

  if (this.balance <= 0) {
    return h(
      'g-icon',
      {
        props: {
          value: 'info',
          color: this.overdraft > 0 ? 'warning' : this.overdraft === 0 ? 'error' : '',
          size: 16
        },
        directives: [
          {
            name: 'g-tooltip',
            options: {
              value: tooltip,
              placement: 'bottom'
            }
          }
        ]
      }
    )
  }

  return h('div')
}

export default function(h) {
  return h(
    'div',
    {
      class: 'fc h--100'
    },
    [
      h(
        'g-menu',
        {
          class: 'ff',
          props: {
            value: this.show,
            closeOnContentClick: false,
            minWidth: 300,
            maxWidth: 400,
            placement: 'bottom-end',
            offsetDistance: 16,
            transparent: true
          },
          on: {
            input: event => {
              this.show = event
            }
          }
        },
        [
          h(
            'g-button',
            {
              props: { toolbar: true },
              slot: 'activator'
            },
            [
              h(
                'div',
                {
                  class: 'grid grid-cols--1 grid-gap--2 ff pr-2'
                },
                [
                  h(
                    'div',
                    {
                      class: 'fjcfe'
                    },
                    [
                      h(
                        SingleLink,
                        {
                          props: {
                            value: this.account,
                            type: 'text'
                          }
                        }
                      )
                    ]
                  ),

                  h(
                    'div',
                    {
                      class: 'fjcfe faic grid grid-gap--4',
                      style: { 'grid-template-columns': '16px 1fr' }
                    },
                    [
                      renderIcon.call(this, h),

                      h('div', { class: 'faic fz-12 lh-12' }, getRUB(this.balance))
                    ]
                  )
                ]
              ),

              h(
                'g-avatar',
                {
                  props: {
                    title: this.account && this.account.username,
                    size: 48
                  }
                }
              )
            ]
          ),

          h(
            UserWidget,
            {
              props: {
                overdraft: _.get(this.globalSettings, 'billings.overdraft', 0),
                rounded: this.viewport.breakpoint.mdUp
              },
              on: {
                show: event => {
                  this.show = event
                }
              }
            }
          )
        ]
      )
    ]
  )
}
