import { getLocaleDateString, getLocaleTimeString, getPrice, permissionPresets } from '@/utils'

import SingleLink from '@/components/misc/SingleLink'

import { colors, icons, serviceName } from '@/components/services/payments/utils'

function renderSum() {
  return getPrice(this.value.amount)
}

function renderStatus(h) {
  return h(
    'div',
    {
      class: 'faic grid grid-gap--2',
      style: { 'grid-template-columns': '15px 1fr' }
    },
    [
      h(
        'g-icon',
        {
          props: {
            value: icons[this.value.status],
            color: colors[this.value.status],
            size: 15
          }
        }
      ),

      h(
        'div',
        {
          class: `text--${colors[this.value.status]}`
        },
        this.getTranslate(`${serviceName}.statuses.${this.value.status}`)
      )
    ]
  )
}

function renderInfo(h) {
  return h(
    'div',
    {
      class: 'faic grid grid-gap--4 caption',
      style: { 'grid-template-columns': 'repeat(2, minmax(min-content, max-content))' }
    },
    [
      renderStatus.call(this, h),

      h(
        SingleLink,
        {
          props: {
            value: this.value.Owner,
            type: 'link'
          }
        }
      )
    ]
  )
}

function renderDescription(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--2'
    },
    [
      renderSum.call(this, h),
      renderInfo.call(this, h)
    ]
  )
}

function renderLogsButton(h) {
  if (this.checkPermissions(`advanced.${serviceName}.get`)) {
    return h(
      'g-button',
      {
        class: 'ma-0',
        props: {
          icon: 'history',
          flat: true
        },
        on: {
          click: () => {
            this.$emit('paymentLogs', this.value.id)
          }
        }
      }
    )
  }
}

function renderBillingsButton(h) {
  return h(
    'g-button',
    {
      class: 'ma-0',
      props: {
        flat: true,
        icon: 'request_page',
        color: 'success',
        disabled: !Array.isArray(this.value.Billings) || Array.isArray(this.value.Billings) && !this.value.Billings.length
      },
      on: {
        click: () => {
          this.$emit('billings', this.value.Billings)
        }
      }
    }
  )
}

function renderButtons(h) {
  if (this.checkPermissions(`advanced.${serviceName}.update`, permissionPresets.reseller)) {
    let cols = 1
    if (this.checkPermissions(`advanced.${serviceName}.get`)) {
      cols = 2
    }

    return h(
      'div',
      {
        class: {
          'grid grid-gap--8': true,
          'hover-child': this.viewport.breakpoint.mdUp
        },
        style: { 'grid-template-columns': `repeat(${cols}, 36px)` }
      },
      [
        renderLogsButton.call(this, h),
        renderBillingsButton.call(this, h)
      ]
    )
  }
}

function renderDates(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--2 text-right text--grey'
    },
    [
      h('div', {}, getLocaleTimeString(this.value.createdAt)),
      h('div', {}, getLocaleDateString(this.value.createdAt))
    ]
  )
}

export default function(h) {
  return h(
    'g-expansion-panel',
    {
      props: { preventClick: true },
      scopedSlots: {
        header: () => {
          return h(
            'div',
            {
              class: 'faic grid grid-gap--8 cursor-default hover-parent hover-parent--opacity caption py-1 px-2',
              style: { 'grid-template-columns': '1fr repeat(2, minmax(min-content, max-content))' }
            },
            [
              renderDescription.call(this, h),
              renderButtons.call(this, h),
              renderDates.call(this, h)
            ]
          )
        }
      }
    }
  )
}
