import { unVue } from '@sigma-legacy-libs/unvue'

import draggable from '@/components/misc/draggable'
import rulesGroup from '@/components/services/tariffs/rules/group'

function renderRules(h) {
  if (this.proxy.length) {
    return h(
      draggable,
      {
        class: 'tariffs-rules',
        props: {
          value: this.proxy,
          handle: 'drag_handle'
        },
        on: {
          input: event => {
            this.proxy = event
          }
        },
        scopedSlots: {
          item: (item, index) => {
            return h(
              rulesGroup, {
                props: {
                  value: unVue(item),
                  target: this.proxy,
                  meta: this.meta,
                  index
                },
                key: `rules-group-${index}`,
                on: {
                  input: event => {
                    this.proxy[index] = event
                  }
                }
              }
            )
          }
        }
      }
    )
  }
}

function renderAddButton(h) {
  return h(
    'g-button',
    {
      class: 'ma-0',
      props: {
        label: this.getTranslate('tariffs.addRule'),
        icon: 'add',
        flat: true,
        rounded: true,
        color: 'primary'
      },
      on: {
        click: () => {
          this.add()
        }
      }
    }
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8'
    },
    [
      renderRules.call(this, h),

      h(
        'div',
        {
          class: 'fjcfe'
        },
        [ renderAddButton.call(this, h) ]
      )
    ]
  )
}
