import proxy from '@sigma-legacy-libs/g-proxy'

import { unVue } from '@sigma-legacy-libs/unvue'
import { EMPTY_USERS, REQUISITE_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import { REQUISITE_OPF } from '@/utils/constants/services/requisites/_opf'

import render from './render'

const _ = { get: require('lodash/get') }

export default {
  name: 'Form',

  mixins: [ proxy({ type: 'object' }) ],

  props: {
    errors: {
      type: Object,
      default: () => ({})
    },

    User: {
      type: Object,
      default: () => unVue(EMPTY_USERS)
    }
  },

  data() {
    return {
      contactMatches: true
    }
  },

  computed: {
    contactValidation() {
      const result = Object.keys(this.proxy.contact).some(key => !!this.proxy.contact[key])

      return result ? result : undefined
    },
    signerValidation() {
      let result = true

      if (!this.proxy.signer.fullName.nominative) {
        result = false
      }
      if (!this.proxy.signer.position.nominative) {
        result = false
      }
      if (!this.proxy.signer.basis.nominative) {
        result = false
      }
      if (!this.proxy.signer.fullName.genitive) {
        result = false
      }
      if (!this.proxy.signer.position.genitive) {
        result = false
      }
      if (!this.proxy.signer.basis.genitive) {
        result = false
      }

      return result
    },
    legalValidation() {
      if (!this.proxy.data.legal.opf || !~REQUISITE_OPF.indexOf(this.proxy.data.legal.opf)) {
        return false
      }
      if (!this.proxy.data.legal.name) {
        return false
      }
      if (!/^\d{10,12}$/.test(this.proxy.data.legal.inn)) {
        return false
      }
      if (!/^\d{13,15}$/.test(this.proxy.data.legal.ogrn)) {
        return false
      }

      return true
    },
    bankValidation() {
      if (!this.proxy.data.bank.name) {
        return false
      }

      if (!/^\d{20}$/.test(this.proxy.data.bank.rs)) {
        return false
      }
      if (!/^\d{20}$/.test(this.proxy.data.bank.ks)) {
        return false
      }
      if (!/^\d{9}$/.test(this.proxy.data.bank.bik)) {
        return false
      }

      if (!this.proxy.data.tax.system) {
        return false
      }

      return true
    },
    legalAddressValidation() {
      if (!this.proxy.data.legalAddress.country) {
        return false
      }
      if (!this.proxy.data.legalAddress.city) {
        return false
      }
      if (!/^\d{6}$/.test(this.proxy.data.legalAddress.index)) {
        return false
      }

      return true
    },
    postalAddressValidation() {
      if (!this.proxy.data.postalAddress.country) {
        return false
      }
      if (!this.proxy.data.postalAddress.city) {
        return false
      }
      if (!/^\d{6}$/.test(this.proxy.data.postalAddress.index)) {
        return false
      }

      return true
    },
    validation() {
      if (!this.proxy.title) {
        return false
      }

      if (!this.proxy.type || !~REQUISITE_TYPES.indexOf(this.proxy.type)) {
        return false
      }

      return this.signerValidation && this.legalValidation && this.bankValidation && this.legalAddressValidation && this.postalAddressValidation
    }
  },

  watch: {
    contactMatches() {
      if (this.contactMatches) {
        this.setContact(this.User)
      } else {
        this.setContact(undefined)
      }
    },
    validation() {
      this.$emit('validation', this.validation)
    }
  },

  mounted() {
    this.proxy.type = REQUISITE_TYPES.business
    this.$emit('validation', this.validation)
    this.setContact(this.User)
  },

  methods: {
    setContact(user) {
      this.proxy.contact.firstName = this.proxy.contact.firstName || _.get(user, 'data.firstName')
      this.proxy.contact.lastName = this.proxy.contact.lastName || _.get(user, 'data.lastName')
      this.proxy.contact.middleName = this.proxy.contact.middleName || _.get(user, 'data.middleName')
      this.proxy.contact.phone = this.proxy.contact.phone || _.get(user, 'data.phone')
      this.proxy.contact.email = this.proxy.contact.email || _.get(user, 'data.email')
    }
  },

  render
}
