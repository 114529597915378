import { TRAFFIC_TYPE } from '@sigma-legacy-libs/essentials/lib/constants'

import { STATES, getLocaleDateString, getLocaleTimeString } from '@/utils'

import importButton from '@/components/services/tariffRates/import'
import HeadTitle from '@/components/misc/HeadTitle'

import { serviceName, trafficTypeColors } from '@/components/services/tariffRates/utils'

const _ = { get: require('lodash/get') }

function renderSelectSlot(h, item) {
  return h(
    'div',
    {
      class: 'fc'
    },
    [
      h('div', { class: 'caption' }, getLocaleDateString(item.value)),
      h('div', { class: 'tiny text--grey' }, getLocaleTimeString(item.value, { milliseconds: true }))
    ]
  )
}

function renderVersions(h) {
  if (!this.readonly) {
    return h(
      'g-select',
      {
        props: {
          value: this.version,
          label: this.getTranslate(`${serviceName}.labels.version`),
          items: this.versions,
          mode: 'outline',
          rounded: true,
          dense: true,
          details: false
        },
        on: {
          input: event => {
            this.version = event
          }
        },
        scopedSlots: {
          selection: ({ item }) => renderSelectSlot.call(this, h, item),
          item: ({ item }) => renderSelectSlot.call(this, h, item)
        }
      }
    )
  }
}

function renderImportButton(h, TariffId, fullVersion) {
  if (!this.readonly) {
    return h(
      importButton,
      {
        props: {
          fullVersion,
          TariffId
        },
        on: {
          finish: () => {
            this.getVersions()
          }
        }
      }
    )
  }
}

function renderRemoveDialog(h) {
  return h(
    'g-dialog',
    {
      props: {
        value: this.showRemoveDialog,
        rounded: true,
        maxWidth: 400
      },
      on: {
        input: event => {
          this.showRemoveDialog = event
        }
      }
    },
    [
      h(
        HeadTitle,
        {
          props: {
            value: this.getTranslate(`${serviceName}.titles.dialog.remove`)
          }
        }
      ),

      h(
        'div',
        {
          class: 'pa-3'
        },
        [ this.getTranslate(`${serviceName}.contents.dialog.remove`, { version: this.version }) ]
      ),

      h(
        'g-card-actions',
        {
          class: 'fjcfe',
          slot: 'footer'
        },
        [
          h(
            'g-button',
            {
              props: {
                flat: true,
                rounded: true,
                icon: 'clear',
                label: this.getTranslate('misc.buttons.cancel')
              },
              on: {
                click: () => {
                  this.showRemoveDialog = false
                }
              }
            }
          ),

          h(
            'g-button',
            {
              props: {
                rounded: true,
                depressed: true,
                icon: 'delete',
                color: 'error',
                label: this.getTranslate('misc.buttons.remove')
              },
              on: {
                click: () => {
                  this.removeVersion()
                }
              }
            }
          )
        ]
      )
    ]
  )
}
function renderRemoveButton(h) {
  if (!this.readonly && this.version) {
    return h(
      'g-button',
      {
        class: 'ma-0',
        props: {
          flat: true,
          icon: 'remove',
          color: 'error'
        },
        on: {
          click: () => {
            this.showRemoveDialog = true
          }
        },
        scopedSlots: {
          dialog: () => renderRemoveDialog.call(this, h)
        }
      }
    )
  }
}

function renderFilter(h) {
  return h(
    'g-text-field',
    {
      props: {
        value: this.restData[serviceName].find.filter.$search,
        label: this.getTranslate('commons.labels.search'),
        suffix: this.restData[serviceName].find.pagination.total,
        loading: this.restData[serviceName].find.state === STATES.loading,
        mode: 'outline',
        dense: true,
        rounded: true,
        clearable: true,
        details: false
      },
      on: {
        input: event => {
          this.restData[serviceName].find.filter.$search = event
        }
      }
    }
  )
}

function renderHeader(h, TariffId) {
  if (this.readonly) {
    return h(
      'div',
      {
        class: 'pt-2 px-2'
      },
      [ renderFilter.call(this, h) ]
    )
  }

  if (this.viewport.breakpoint.mdUp) {
    return h(
      'div',
      {
        class: `${serviceName}__header`
      },
      [
        renderImportButton.call(this, h, TariffId),
        renderVersions.call(this, h),
        renderFilter.call(this, h),
        renderRemoveButton.call(this, h)
      ]
    )
  } else {
    return h(
      'div',
      {
        class: 'grid grid-gap--8 pt-2 px-2'
      },
      [
        h(
          'div',
          {
            class: 'grid grid-gap--8',
            style: {
              'grid-template-columns': 'repeat(2, 1fr)'
            }
          },
          [
            renderVersions.call(this, h),
            renderFilter.call(this, h)
          ]
        ),

        h(
          'div',
          {
            class: 'fjcc'
          },
          [
            h(
              'div',
              {
                class: 'grid grid-gap--8',
                style: {
                  'grid-template-columns': 'repeat(3, 36px)'
                }
              },
              [
                renderImportButton.call(this, h, TariffId),
                renderRemoveButton.call(this, h)
              ]
            )
          ]
        )
      ]
    )
  }
}

function renderPriceHeadCell(h, column) {
  return h(
    'th',
    {
      class: {
        [`${serviceName}__head-cell`]: true,
        [`${serviceName}__head-cell--${column}`]: true
      }
    },
    [ this.getTranslate(`${serviceName}.labels.${column}`) ]
  )
}

function renderPriceHead(h) {
  return h(
    'div',
    {
      class: `${serviceName}__head`
    },
    [
      h(
        'thead',
        {},
        [
          h(
            'tr',
            {},
            [ ...this.columns, 'price' ].map(column => renderPriceHeadCell.call(this, h, column))
          )
        ]
      )
    ]
  )
}

function renderPrice(h, { price }) {
  return h(
    'td',
    {
      class: `${serviceName}__cell ${serviceName}__cell--price`,
      key: 'price'
    },
    [ price, this.getTranslate(`currency.${this.tariffCurrency || 'RUB'}.symbol.unicode`) ].join(' ')
  )
}

function renderPriceCellContent(h, content, path) {
  const value = _.get(content, path)
  switch (path) {
    case 'trafficType': {
      return h(
        'div',
        {
          class: `text--${trafficTypeColors[value] || 'grey'}`
        },
        [ ~TRAFFIC_TYPE.indexOf(value) ? this.getTranslate(`${serviceName}.trafficTypes.${value}`) : value ]
      )
    }

    default: {
      return value
    }
  }
}

function renderPriceCell(h, content, path) {
  return h(
    'td',
    {
      class: `${serviceName}__cell ${serviceName}__cell--${path}`,
      key: path
    },
    [ renderPriceCellContent.call(this, h, content, path) ]
  )
}

function renderPriceRow(h, price) {
  return h(
    'tr',
    {
      class: `${serviceName}__row`
    },
    [
      ...this.columns.map(column => renderPriceCell.call(this, h, price, column)),

      renderPrice.call(this, h, price)
    ]
  )
}

function renderPriceBody(h, data) {
  return h(
    'tbody',
    {},
    data.map(price => renderPriceRow.call(this, h, price))
  )
}

function renderPriceTable(h, data) {
  return h(
    'table',
    {
      class: `${serviceName}__table`
    },
    [ renderPriceBody.call(this, h, data) ]
  )
}

function renderPriceList(h) {
  const data = this.restData[serviceName].find.data
  if (Array.isArray(data) && data.length) {
    return h(
      'div',
      {
        class: 'grid grid-gap--2'
      },
      [
        renderPriceHead.call(this, h),

        h(
          'div',
          {
            class: `${serviceName}__list`,
            on: { scroll: this.onScroll },
            ref: 'list'
          },
          [ renderPriceTable.call(this, h, data) ]
        )
      ]
    )
  } else {
    return h('g-empty')
  }
}

function renderState(h, TariffId) {
  if (this.loadingVersions) {
    return h(
      'div',
      {
        class: 'faic fjcc pa-5'
      },
      [ h('g-progress', { props: { indeterminate: true } }) ]
    )
  } else {
    return h(
      'div',
      {
        class: 'grid'
      },
      [
        h(
          'g-empty',
          {
            props: {
              title: this.getTranslate(`${serviceName}.hints.notFound`)
            }
          }
        ),

        h(
          'div',
          {
            class: 'fjcc faic'
          },
          [ renderImportButton.call(this, h, TariffId, true) ]
        )
      ]
    )
  }
}

function renderContent(h, TariffId) {
  if (Array.isArray(this.versions) && this.versions.length) {
    return [
      renderHeader.call(this, h, TariffId),
      h('g-divider', { props: { style: 'dashed' } }),
      renderPriceList.call(this, h)
    ]
  } else {
    return renderState.call(this, h, TariffId)
  }
}

export default function(h, TariffId) {
  return h(
    'div',
    {
      class: serviceName
    },
    [ renderContent.call(this, h, TariffId) ]
  )
}
