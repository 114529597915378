import { serviceName } from '@/components/services/apisender/utils'

const _ = { get: require('lodash/get') }

export default function(h) {
  const id = _.get(this.value, 'id')
  const idInstance = _.get(this.value, 'instance.idInstance')
  if (id && idInstance) {
    return h(
      'router-link',
      {
        class: 'text-overflow link link--passive fz-16 lh-14',
        props: {
          to: {
            name: `${serviceName}.single`,
            params: { id }
          }
        }
      },
      idInstance
    )
  }

  return h(
    'div',
    {
      class: 'text--grey'
    },
    [ this.getTranslate(`${serviceName}.hints.badInstance`) ]
  )
}
