import proxy from '@sigma-legacy-libs/g-proxy'

import { unVue } from '@sigma-legacy-libs/unvue'

import render from './render'

const _ = {
  isEqual: require('lodash/isEqual'),
  isPlainObject: require('lodash/isPlainObject')
}

export default {
  name: 'GeneratorContent',

  mixins: [ proxy({ type: 'array' }) ],

  props: {
    defaultValue: {
      type: Object,
      default: () => ({})
    },

    toObject: Boolean
  },

  methods: {
    addField() {
      this.proxy.push(unVue(this.defaultValue))
    },
    removeField(index) {
      this.proxy.splice(index, 1)
      if (this.proxy.length === 0) {
        this.proxy.push(unVue(this.defaultValue))
      }
    },

    _inputFilter(data) {
      if (this.toObject && _.isPlainObject(data)) {
        if (Object.keys(data).length === 0) {
          return [ unVue(this.defaultValue) ]
        }

        return Object.keys(data).reduce((result, key) => {
          result.push({
            key,
            value: data[key]
          })

          return result
        }, [])
      }

      return data
    },
    _outputFilter(data) {
      if (this.toObject && Array.isArray(data)) {
        return data.reduce((result, element) => {
          return Object.assign(result, { [element.key]: element.value })
        }, {})
      }

      return data
    },

    receiveValue() {
      const value = this._inputFilter(this.value)
      if (!this.toObject || !_.isEqual(this.proxy, value)) {
        this.proxy = value
      }
    },

    transmitValue() {
      const proxy = this._outputFilter(this.proxy)
      if (!this.toObject || !_.isEqual(proxy, this.value)) {
        this.$emit('input', proxy)
      }
    },

    watchValueHandler() {
      if (!this.toObject) {
        this.receiveValue()
      }
    }
  },

  render
}
