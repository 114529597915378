import Vue from 'vue'

import { STATES, generateServices, globalErrorHandler, globalErrorProcessor } from '@/utils'

import { serviceName } from '@/components/services/tariffRates/utils'

const _ = { debounce: require('lodash/debounce') }

import render from './render'

export default function(TariffId) {
  return {
    name: serviceName,

    mixins: [
      generateServices({
        name: serviceName,

        find: {
          defaultFilter: {
            TariffId,
            version: undefined,
            $search: undefined,
            $limit: 100
          },
          defaultOrder: { mcc: 'asc' },
          appendMode: true
        },

        get: false,
        create: false,
        update: false,
        remove: false
      })
    ],

    props: {
      readonly: Boolean,
      tariffCurrency: String
    },

    data() {
      return {
        showRemoveDialog: false,
        loadingVersions: false,

        version: undefined,
        versions: []
      }
    },

    computed: {
      columns() {
        const result = [ 'mcc', 'mnc', 'step' ]

        if (this.viewport.breakpoint.mdUp) {
          result.push('trafficType', 'sender')
        }

        return result
      }
    },

    watch: {
      version() {
        this.getVersion()
      }
    },

    mounted() {
      this.getVersions()
    },

    created() {
      this.onScroll = _.debounce(this.onScroll, 500, {
        leading: false,
        trailing: true
      })
    },

    methods: {
      async getVersions() {
        this.loadingVersions = true
        const { data } = await Vue.$GRequest.get(`${serviceName}/${TariffId}`, 'versions')
        if (Array.isArray(data)) {
          if (data.length) {
            this.versions = data
            this.version = data[0]
            this.getVersion(data[0])
          } else {
            this.versions = []
            this.version = undefined
            this.restData[serviceName].find.filter.version = undefined
          }
        }
        this.loadingVersions = false
      },
      async getVersion(version) {
        version = version || this.version
        if (version) {
          this.version = version
          this.restData[serviceName].find.filter.version = version
          this.scrollListTop()
        } else {
          this.getVersions()
        }
      },

      async removeVersion() {
        try {
          await Vue.$GRequest.remove(`${serviceName}/${TariffId}/versions`, this.version)
          this.showRemoveDialog = false
        } catch (error) {
          globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
        } finally {
          this.getVersions()
        }
      },

      onScroll(event) {
        const targetState = this.restData[serviceName].find.state === STATES.ready
        const targetScrollPosition = event.target.scrollHeight - event.target.clientHeight - event.target.scrollTop <= 5 * 20
        const targetLimit = this.restData[serviceName].find.pagination.limit + this.restData[serviceName].find.pagination.offset < this.restData[serviceName].find.pagination.total
        if (targetState && targetScrollPosition && targetLimit) {
          this.restData[serviceName].find.pagination.offset += this.restData[serviceName].find.pagination.limit
        }
      },

      scrollListTop() {
        const list = this.$refs.list
        if (list) {
          list.scrollTop = 0
        }
      }
    },

    render(h) {
      return render.call(this, h, TariffId)
    }
  }
}
