export default {
  title: 'Tariff rates updating',

  titles: {
    create: 'Create tariff rate',
    child: 'Child tariffs'
  },

  labels: {
    default: 'Default price',
    price: 'Price',
    exceptions: 'Exceptions',
    tariff: {
      parent: 'Parent tariff',
      child: 'Child tariff'
    }
  },

  buttons: {
    operators: 'Operators',
    addParent: 'Add parent',
    addChild: 'Add child'
  },

  tooltips: {
    favorites: 'Add to favorites'
  },

  hints: {
    wholeCountry: 'All country'
  },

  snackbars: {
    created: 'Tariff rate created',
    updated: 'Tariff rate updated',
    removed: 'Tariff rate removed'
  }
}
