import { unVue } from '@sigma-legacy-libs/unvue'

import { TASKS_FILTER, dateFilter } from '@/utils'

import render from './render'

export default {
  name: 'TasksFilter',

  mixins: [ dateFilter ],

  props: {
    type: {
      type: [ Array, String ],
      default: () => []
    }
  },

  methods: {
    getDefaultFilter() {
      const replacedFilter = {}
      if (~this.type.indexOf('sendings:bulk')) {
        replacedFilter.ListId = undefined
        replacedFilter.data = {
          sending: {
            type: undefined
          }
        }
      }

      return Object.assign({}, unVue(TASKS_FILTER), { type: this.type }, replacedFilter)
    }
  },

  render
}
