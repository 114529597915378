import { serviceName } from '@/components/services/sendernames/utils'

import { permissionPresets } from '@/utils'

import render from './render'

export default {
  name: 'Card',

  props: {
    value: {
      type: Object,
      default: () => ({})
    },

    size: String
  },

  computed: {
    hasComments() {
      const { meta = {} } = this.value

      if (this.checkPermissions(`advanced.${serviceName}.get`, permissionPresets.reseller)) {
        return !!meta.comment
      } else if (this.checkPermissions(`advanced.${serviceName}.get`, [ 'me', false ])) {
        return !!meta.adminComment
      }
    }
  },

  render
}
