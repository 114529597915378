import titleDivider from '@/components/title/divider'
import actions from '@/components/misc/rules/actions'
import conditions from '@/components/misc/rules/conditions'
import title from '@/components/services/routes/form/title'

import { serviceName } from '@/components/services/messageRules/utils'

function renderTitleField(h) {
  return h(
    title,
    {
      props: {
        value: this.proxy,
        serviceName
      },
      on: {
        input: event => {
          this.proxy = event
        }
      }
    }
  )
}

function renderConditions(h) {
  return h(
    conditions,
    {
      props: {
        value: this.proxy.conditions
      },
      on: {
        input: event => {
          this.proxy.conditions = event
        }
      }
    }
  )
}

function renderActions(h) {
  return h(
    actions,
    {
      props: {
        value: this.proxy.actions
      },
      on: {
        input: event => {
          this.proxy.actions = event
        }
      }
    }
  )
}

export default function(h) {
  return h(
    'g-form',
    {
      class: 'grid',
      style: { 'grid-template-columns': '100%' },
      on: {
        submit: () => {
          this.$emit('submit', this.proxy)
        }
      }
    },
    [
      renderTitleField.call(this, h),
      h(titleDivider, { props: { value: this.getTranslate(`${serviceName}.subtitles.conditions`) } }),
      renderConditions.call(this, h),
      h(titleDivider, { props: { value: this.getTranslate(`${serviceName}.subtitles.actions`) } }),
      renderActions.call(this, h)
    ]
  )
}
