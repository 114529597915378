function renderEdgeArrow(h, options) {
  if (this.arrows && this.viewport.breakpoint.mdUp) {
    return h(
      'g-button',
      {
        class: 'ma-0',
        props: {
          icon: options.icon,
          flat: true,
          small: true,
          disabled: options.disabled
        },
        on: {
          click: () => {
            this.setPage(options.page)
          }
        }
      }
    )
  }
}

function renderArrow(h, options) {
  if (this.arrows) {
    return h(
      'g-button',
      {
        class: 'ma-0',
        props: {
          icon: options.icon,
          flat: true,
          small: true,
          disabled: options.disabled
        },
        on: {
          click: () => {
            this.setPage(options.page)
          }
        }
      }
    )
  }
}

function renderPages(h) {
  return this.pages.map(page => {
    return h(
      'g-button',
      {
        class: 'ma-0',
        props: {
          label: page,
          flat: this.currentPage !== page,
          small: true,
          round: true,
          depressed: true,
          color: this.currentPage === page ? 'primary' : ''
        },
        on: {
          click: () => {
            this.setPage(page)
          }
        }
      }
    )
  })
}

function renderSelectRows(h) {
  if (this.selectRows) {
    return h(
      'g-menu',
      {
        props: {
          rounded: true,
          placement: 'bottom',
          offsetDistance: 8
        }
      },
      [
        h(
          'g-button',
          {
            class: 'ma-0',
            style: { 'min-width': 'unset' },
            props: {
              label: this.proxy.limit,
              icon: 'list',
              small: true,
              rounded: true,
              depressed: true,
              color: 'primary'
            },
            slot: 'activator'
          }
        ),

        h(
          'g-list',
          {
            props: {
              dense: true,
              rounded: true
            }
          },
          this.rowsPerPage.map(rows => {
            return h(
              'g-list-item',
              {
                props: {
                  active: this.proxy.limit === rows
                },
                on: {
                  click: () => {
                    this.setPage(1, rows)
                  }
                }
              },
              rows
            )
          })
        )
      ]
    )
  }
}

function renderTotal(h) {
  if (this.total) {
    return h(
      'g-chip',
      {
        class: 'ma-0',
        props: {
          flat: true,
          rounded: true,
          outline: true,
          color: 'primary',
          label: this.proxy.total
        }
      }
    )
  }
}

export default function(h) {
  if (this.proxy.total > 0 && this.show) {
    return h(
      'div',
      {
        class: 'grid grid-gap--8',
        style: { 'grid-template-columns': this.buttonColumnsStyle }
      },
      [
        renderEdgeArrow.call(
          this,
          h,
          {
            icon: 'first_page',
            page: 1,
            disabled: this.proxy.offset === 0
          }
        ),

        renderArrow.call(
          this,
          h,
          {
            icon: 'keyboard_arrow_left',
            page: this.currentPage - 1,
            disabled: this.proxy.offset === 0
          }
        ),

        renderPages.call(this, h),

        renderArrow.call(
          this,
          h,
          {
            icon: 'keyboard_arrow_right',
            page: this.currentPage + 1,
            disabled: this.proxy.offset + this.proxy.limit >= this.proxy.total
          }
        ),

        renderEdgeArrow.call(
          this,
          h,
          {
            icon: 'last_page',
            page: this.totalPages,
            disabled: this.proxy.offset + this.proxy.limit >= this.proxy.total
          }
        ),

        renderSelectRows.call(this, h),

        renderTotal.call(this, h)
      ]
    )
  }
}
