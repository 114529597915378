import { SENDING_PAYLOADS, SENDING_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

const _ = {
  get: require('lodash/get'),
  flatten: require('lodash/flatten')
}

export * from '@sigma-legacy-libs/essentials/lib/validators'

export function isEmpty(value) {
  return [
    undefined,
    null,
    ''
  ].indexOf(value) > -1
}

export function isBoolean(value) {
  return typeof value === 'boolean'
}

export function isString(value) {
  return typeof value === 'string'
}
export function isStringEmpty(value) {
  return isString(value) && value === ''
}
export function isStringNotEmpty(value) {
  return isString(value) && value !== ''
}

export function isArray(value) {
  return Array.isArray(value)
}
export function isArrayEmpty(value) {
  return isArray(value) && value.length === 0
}
export function isArrayNotEmpty(value) {
  return isArray(value) && value.length > 0
}

export function isObject(value) {
  return typeof value === 'object'
}
export function isObjectEmpty(value) {
  return isObject(value) && !isEmpty(value) && Object.keys(value).length === 0
}
export function isObjectNotEmpty(value) {
  return isObject(value) && !isEmpty(value) && Object.keys(value).length > 0
}
export function isPlainObject(value) {
  if (isObjectNotEmpty(value)) {
    if (typeof Object.getPrototypeOf === 'function') {
      const proto = Object.getPrototypeOf(value)

      return proto === Object.prototype || proto === null
    }

    return Object.prototype.toString.call(value) === '[object Object]'
  }

  return false
}

export function isValidEmail(value = '', regexp = /.*?@.*?\..{2,}/) {
  return regexp.test(value)
}
export function isValidPassword(value = '', regexp = /^[a-zA-Z0-9_-]{6,18}$/g) {
  return regexp.test(value)
}
export function isValidDate(value = '') {
  return value instanceof Date && !isNaN(value)
}

export function checkPermissions(params, logic = null, accept = null, permissions = {}) {
  if (logic && !~[ 'and', 'or' ].indexOf(logic)) {
    accept = logic
    logic = null
  }

  const result = {
    permissions: [],
    logic: 'or'
  }

  if (isStringNotEmpty(params)) {
    result.permissions = [
      {
        permission: params,
        accept: accept || true
      }
    ]
    result.logic = logic || result.logic
  }

  if (isArrayNotEmpty(params)) {
    result.permissions = params.map(item => {
      return {
        permission: item.permission || item,
        accept: item.accept || accept || true
      }
    })
    result.logic = 'or'
  }

  // TODO: проверить на объект

  const cmp = result.logic === 'and' ? 'every' : 'some'

  return result.permissions[cmp](item => {
    return ~_.flatten([ item.accept ]).indexOf(_.get(permissions, item.permission, false))
  })
}

export function sendingPayloadValidator(type, payload = {}) {
  if (!type || !payload) {
    return false
  }

  if (!~Object.keys(SENDING_PAYLOADS).indexOf(type)) {
    return false
  }

  // Проверка обязательных полей
  for (const key of [ 'sender', 'text' ]) {
    // Исключения
    switch (type) {
      case SENDING_TYPES.voice:
      case SENDING_TYPES.whatsappchat:
      case SENDING_TYPES.viberchat:
      case SENDING_TYPES.vkchat:
      case SENDING_TYPES.telegramchat:
        continue
    }
    if (isEmpty(payload[key])) {
      return false
    }
  }

  switch (type) {
    case SENDING_TYPES.viber: {
      if (!isEmpty(payload.image)) {
        if (payload.button) {
          if (isEmpty(payload.button.text) || !isEmpty(payload.button.text) && payload.button.text.length > 30) {
            return false
          }
          if (isEmpty(payload.button.url) || !isEmpty(payload.button.url) && payload.button.url.length > 2048) {
            return false
          }
        }
      }
      break
    }

    case SENDING_TYPES.voice: {
      if (isEmpty(payload.sender)) {
        return false
      }
      if (isEmpty(payload.text)) {
        if (isEmpty(payload.audio)) {
          return false
        }
      } else {
        if (isEmpty(payload.tts)) {
          return false
        }
      }
      break
    }

    case SENDING_TYPES.whatsappchat:
    case SENDING_TYPES.viberchat:
    case SENDING_TYPES.vkchat:
    case SENDING_TYPES.telegramchat: {
      if (isEmpty(payload.sender)) {
        return false
      }
      break
    }
  }

  return true
}

export default {
  isEmpty,

  isString,
  isStringNotEmpty,

  isArray,
  isArrayNotEmpty,

  isObject,
  isObjectNotEmpty,
  isPlainObject,

  isValidEmail,
  isValidPassword,
  isValidDate,

  checkPermissions,

  sendingPayloadValidator
}
