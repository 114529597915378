import HeadTitle from '@/components/misc/HeadTitle'
import ContactListsForm from '@/components/services/contactLists/form'

function renderAddButton(h, type) {
  return h(
    'g-button',
    {
      attrs: { id: `v-step-create-recipients-${type}` },
      class: 'mr-0',
      props: {
        icon: 'add',
        flat: true,
        color: 'secondary',
        tabindex: -1
      },
      directives: [
        {
          name: 'g-tooltip',
          options: {
            value: this.getTranslate('contactLists.tooltips.create'),
            placement: 'right'
          }
        }
      ],
      on: {
        click: () => {
          this.contactLists.show.dialog = true
        }
      }
    }
  )
}

function renderRecipientField(h, type) {
  const proxyKeys = Object.keys(this.proxy)

  return h(
    'div',
    {
      class: 'faifs'
    },
    [
      h(
        'g-select',
        {
          props: {
            value: this.proxy[type],
            itemsDisabled: this.proxy[proxyKeys[Math.abs(proxyKeys.indexOf(type) - 1)]],
            label: this.getTranslate(`sendings.labels.targets.${type}`),
            hint: this.getTranslate('sendings.labels.targets.info'),
            error: this.error,
            mode: 'outline',
            rounded: true,
            service: 'contactLists',
            paste: true,
            pasteFilter: this.pasteFilter,
            setOnBlur: true,
            autocomplete: true,
            combobox: true,
            multiple: true,
            freeInputTriggers: [ 9, 13, 32, /* 188,  */194 ] // FIXME: 188 код на кнопке с буквой Б
          },
          on: {
            input: event => {
              this.proxy[type] = this.checkValues(event)
            }
          }
        }
      ),

      renderAddButton.call(this, h, type)
    ]
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: 'grid grid-cols--1 grid-gap--8'
    },
    [
      renderRecipientField.call(this, h, 'include'),
      renderRecipientField.call(this, h, 'exclude'),

      h(
        'g-dialog',
        {
          props: {
            rounded: true,
            value: this.contactLists.show.dialog,
            maxWidth: 400
          },
          on: {
            input: event => {
              this.contactLists.show.dialog = event
            }
          }
        },
        [
          h(
            HeadTitle,
            {
              props: { value: this.getTranslate('contactLists.titles.create') },
              slot: 'header'
            }
          ),

          h(
            ContactListsForm,
            {
              class: 'pa-3',
              props: {
                value: this.restData.contactLists.create.data
              },
              on: {
                input: event => {
                  this.restData.contactLists.create.data = event
                },
                submit: async () => {
                  this.createContactList()
                },
                validation: event => {
                  this.restData.contactLists.create.isValid = event
                }
              }
            }
          ),

          h(
            'g-card-actions',
            {
              class: 'fjcfe facfe',
              slot: 'footer'
            },
            [
              h(
                'g-button',
                {
                  props: {
                    label: this.getTranslate('misc.buttons.close'),
                    flat: true,
                    rounded: true
                  },
                  on: {
                    click: () => {
                      this.contactLists.show.dialog = false
                    }
                  }
                }
              ),

              h(
                'g-button',
                {
                  props: {
                    label: this.getTranslate('misc.buttons.create'),
                    rounded: true,
                    depressed: true,
                    loading: this.restData.contactLists.create.state === 'loading',
                    disabled: this.restData.contactLists.create.state === 'loading' || !this.restData.contactLists.create.isValid,
                    color: 'primary'
                  },
                  on: {
                    click: async () => {
                      this.createContactList()
                    }
                  }
                }
              )
            ]
          )
        ]
      )
    ]
  )
}
