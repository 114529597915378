import { RECURRENT_PAYMENTS_STATUSES, RECURRENT_PAYMENTS_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import { backendServerIP, generateServices, globalErrorHandler, globalErrorProcessor, permissionPresets } from '@/utils'

import { getRUB } from '@/utils'

import render from './render'

const name = 'recurrentPayments'

export default {
  name: 'RecurrentPaymentsItem',

  mixins: [
    generateServices({
      name,

      find: false,
      create: false,

      update: {
        params: {
          query: {
            $scope: [ 'full' ]
          }
        }
      }
    })
  ],

  props: {
    value: {
      type: Object,
      default: () => ({})
    },

    type: {
      type: String,
      validator: value => {
        return ~[ 'panel', 'card' ].indexOf(value)
      }
    }
  },

  computed: {
    result() {
      switch (this.value.type) {
        case RECURRENT_PAYMENTS_TYPES.threshold: {
          return this.getTranslate(`${name}.contents.${this.value.type}`, { threshold: getRUB(this.value.threshold) })
        }
        case RECURRENT_PAYMENTS_TYPES.frequency: {
          return this.getTranslate(`tasks.frequencies.${this.value.frequency}`)
        }
      }
    },
    isPaused() {
      return this.value.status === RECURRENT_PAYMENTS_STATUSES.pause
    }
  },

  methods: {
    async repeatRecurrentPayment() {
      if (this.checkPermissions(`advanced.${name}.update`, permissionPresets.me)) {
        if (~[ RECURRENT_PAYMENTS_STATUSES.init, RECURRENT_PAYMENTS_STATUSES.error ].indexOf(this.value.status)) {
          try {
            const url = new URL(`/api/${name}/${this.value.id}/reInit`, backendServerIP)
            const urlSearchParams = new URLSearchParams({ method: this.value.method })
            url.search = urlSearchParams.toString()

            const response = await fetch(url.toString(), {
              method: 'get',
              headers: { authorization: window.localStorage.getItem('token') }
            })
            if (response.ok) {
              const { url } = await response.json()
              if (url) {
                window.location.href = url
              }
            }
          } catch (error) {
            globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
          }
        }
      }
    },

    async activateRecurrentPayment() {
      if (this.checkPermissions(`advanced.${name}.update`, permissionPresets.me)) {
        if (!~[ RECURRENT_PAYMENTS_STATUSES.init, RECURRENT_PAYMENTS_STATUSES.error ].indexOf(this.value.status)) {
          try {
            await this.rest[name].update(this.value.id, Object.assign({}, this.value, { status: this.isPaused ? RECURRENT_PAYMENTS_STATUSES.ready : RECURRENT_PAYMENTS_STATUSES.pause }))
          } catch (error) {
            globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
          }
        }
      }
    }
  },

  render
}
