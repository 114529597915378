import buttonCopy from '@/components/button/copy'
import buttonRefresh from '@/components/button/refresh'
import componentNotFound from '@/components/misc/componentNotFound'
import HeadTitle from '@/components/misc/HeadTitle'
import tokensForm from '@/components/services/tokens/form'
import ConfirmRemoveButton from '@/components/misc/ConfirmRemoveButton'
import pagination from '@/components/misc/pagination'

import { serviceName } from '@/components/services/tokens/utils'

import { STATES, permissionPresets } from '@/utils'

function renderEditDialog(h) {
  return h(
    'g-dialog',
    {
      props: {
        value: this.showEditDialog,
        rounded: true,
        maxWidth: 400
      },
      on: {
        input: event => {
          if (event === false) {
            this.payload = undefined
          }
          this.showEditDialog = event
        }
      }
    },
    [
      h(
        HeadTitle,
        {
          props: {
            value: this.getTranslate(`${serviceName}.titles.edit`)
          },
          slot: 'header'
        }
      ),

      h(
        tokensForm,
        {
          props: { value: this.payload },
          on: {
            input: event => {
              this.payload = event
            },
            submit: () => {
              this.update()
            }
          }
        }
      ),

      h(
        'g-card-actions',
        {
          class: 'fjcfe',
          slot: 'footer'
        },
        [
          h(
            'g-button',
            {
              props: {
                flat: true,
                rounded: true,
                label: this.getTranslate('misc.buttons.cancel')
              },
              on: {
                click: () => {
                  this.showEditDialog = false
                }
              }
            }
          ),
          h(
            'g-button',
            {
              props: {
                rounded: true,
                depressed: true,
                color: 'primary',
                label: this.getTranslate('misc.buttons.update')
              },
              on: {
                click: () => {
                  this.update()
                }
              }
            }
          )
        ]
      )
    ]
  )
}

function renderPagination(h) {
  if (this.restData[serviceName].find.pagination.total) {
    return h(
      'div',
      {
        class: 'fjcfe facfe faic'
      },
      [
        renderCreateButton.call(this, h),

        h('div', { class: 'ff' }),

        h(
          pagination, {
            props: {
              value: this.restData[serviceName].find.pagination
            },
            on: {
              input: event => {
                this.restData[serviceName].find.pagination = event
              }
            }
          }
        ),

        h(
          buttonRefresh,
          {
            props: {
              state: this.restData[serviceName].find.state,
              shortClickMethod: () => this.rest[serviceName].find(),
              longClickMethod: () => this.rest[serviceName].find({}, { noCache: true })
            }
          }
        )
      ]
    )
  }
}

function renderCreateButton(h, label = false) {
  try {
    return h(
      require(`@/components/services/${serviceName}/create`).default,
      {
        props: {
          label,
          OwnerId: this.OwnerId
        }
      }
    )
  } catch (error) {
    return h(componentNotFound)
  }
}

function renderHeader(h) {
  if (this.restData[serviceName].find.pagination.total) {
    return renderPagination.call(this, h)
  } else {
    return h(
      'div',
      {
        class: 'faic fjcc'
      },
      [ renderCreateButton.call(this, h, true) ]
    )
  }
}

function renderRemoveButton(h, id) {
  if (this.checkPermissions(`advanced.${serviceName}.remove`, permissionPresets.me)) {
    return h(
      ConfirmRemoveButton,
      {
        class: 'ma-0',
        props: {
          loading: this.restData[serviceName].remove.state === 'loading',
          disabled: this.restData[serviceName].remove.state === 'loading',
          callback: () => this.rest[serviceName].remove(id)
        }
      }
    )
  }
}

function renderItem(h, item) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8 pa-2 hover-parent hover-parent--opacity hover-background',
      style: { 'grid-template-columns': '36px 1fr 80px' }
    },
    [
      h(
        buttonCopy,
        {
          class: 'ma-0',
          props: { value: item.token }
        }
      ),

      h(
        'div',
        {
          class: 'grid grid-gap--2'
        },
        [
          h(
            'div',
            {
              style: { wordBreak: 'break-all' },
              class: 'text-overflow monospace'
            },
            item.token
          ),
          h('div', { class: 'small text--grey' }, item.description)
        ]
      ),

      h(
        'div',
        {
          class: {
            'grid grid-gap--8 faic': true,
            'hover-child': this.viewport.breakpoint.mdUp
          },
          style: { 'grid-template-columns': '36px 36px' }
        },
        [
          renderRemoveButton.call(this, h, item.id),

          h(
            'g-button',
            {
              class: 'ma-0',
              props: {
                flat: true,
                icon: 'edit'
              },
              on: {
                click: () => {
                  this.payload = item
                  this.showEditDialog = true
                }
              }
            }
          )
        ]
      )
    ]
  )
}

function renderItems(h) {
  return h(
    'div',
    {
      class: 'grid grid-cols--1 grid-gap--0'
    },
    this.restData[serviceName].find.data.map(item => renderItem.call(this, h, item))
  )
}

function renderList(h) {
  if (this.restData[serviceName].find.state === STATES.ready) {
    if (this.restData[serviceName].find.pagination.total > 0) {
      return renderItems.call(this, h)
    } else {
      return h(
        'div',
        {
          class: 'py-5 px-3'
        },
        [ h('g-empty', { props: { title: this.getTranslate(`${serviceName}.hints.notFound`) } }) ]
      )
    }
  } else if (this.restData[serviceName].find.state === STATES.loading) {
    return h(
      'div',
      {
        class: 'fjcc facc pa-3'
      },
      [ h('g-progress', { props: { indeterminate: true } }) ]
    )
  }

  return h('div')
}

function renderFooter(h) {
  if (this.restData[serviceName].find.pagination.total > 10) {
    return renderPagination.call(this, h)
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: 'fc'
    },
    [
      renderHeader.call(this, h),
      renderList.call(this, h),
      renderFooter.call(this, h),
      renderEditDialog.call(this, h)
    ]
  )
}
