import { Swatches } from 'vue-color'

function renderInput(h) {
  if (this.showEditValueInput) {
    return h(
      'g-text-field',
      {
        props: {
          value: this.proxy,
          label: this.label,
          defaultValue: this.defaultValue,
          error: !/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(this.proxy) ? this.getTranslate('commons.errors.hex') : undefined,
          mode: 'outline',
          flat: true,
          dense: true,
          rounded: true,
          clearable: true,
          details: false,
          autofocus: true
        },
        on: {
          input: event => {
            this.proxy = event
          }
        }
      }
    )
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8 faic',
      style: { 'grid-template-columns': '48px 1fr' }
    },
    [
      h(
        'g-menu',
        {
          props: {
            value: this.showColorSwatch,
            rounded: true,
            maxWidth: 300
          },
          on: {
            input: event => {
              this.showColorSwatch = event
            }
          }
        },
        [
          h(
            'div',
            {
              class: 'cursor-pointer fjcc facc faic square--48',
              slot: 'activator'
            },
            [
              h(
                'div',
                {
                  class: 'square--24',
                  style: {
                    'background-color': this.proxy || this.defaultValue,
                    'border-radius': '50%'
                  }
                }
              )
            ]
          ),

          h(
            Swatches,
            {
              props: {
                value: this.proxy
              },
              on: {
                input: event => {
                  this.proxy = event.hex
                }
              }
            }
          )
        ]
      ),

      h(
        'div',
        {
          class: 'grid grid-gap--4'
        },
        [
          h(
            'span',
            {
              class: 'body-1 lh-14 text-overflow'
            },
            this.label
          ),

          h(
            'g-menu',
            {
              props: {
                value: this.showEditValueInput,
                closeOnContentClick: false,
                rounded: true,
                maxWidth: 300
              },
              on: {
                input: event => {
                  this.showEditValueInput = event
                }
              }
            },
            [
              h(
                'span',
                {
                  class: 'link link--dashed caption',
                  slot: 'activator'
                },
                this.proxy || this.defaultValue
              ),

              renderInput.call(this, h)
            ]
          )
        ]
      )
    ]
  )
}
