import { serviceName } from '@/components/services/tariffs/utils'

import { getPrice } from '@/utils'

function renderRule(rule) {
  if (rule) {
    return this.getTranslate(`${serviceName}.account.rules.rule.${rule}`)
  }
}

function renderPrice(h, { price, direction, rule }) {
  return h(
    'div',
    {
      class: `${serviceName}-rules__default-rule-price`
    },
    [
      h(
        'div',
        {
          class: `${serviceName}-rules__default-rule-price-value`
        },
        getPrice(price, this.value.currency)
      ),

      h(
        'div',
        {
          class: `${serviceName}-rules__default-rule-price-description`
        },
        [
          this.getTranslate(`${serviceName}.account.rules.direction.${direction}`),
          renderRule.call(this, rule)
        ].filter(v => !!v).join(' ')
      )
    ]
  )
}

function renderAction(h) {
  return h(
    'div',
    {
      class: `${serviceName}-rules__default-rule-action`
    },
    [
      h(
        'div',
        {
          class: `${serviceName}-rules__default-rule-action-title`
        },
        this.getTranslate(`${serviceName}.actions.main.decrease`)
      ),

      h(
        'g-icon',
        {
          class: `${serviceName}-rules__default-rule-action-icon`,
          props: {
            value: 'arrow_drop_down',
            color: 'error'
          }
        }
      )
    ]
  )
}

function renderItem(h, item) {
  return h(
    'div',
    {
      class: `${serviceName}-account-rules__rule`
    },
    [
      renderAction.call(this, h),
      renderPrice.call(this, h, item)
    ]
  )
}

function renderItems(h) {
  return this.value._rules.map(item => renderItem.call(this, h, item))
}

export default function(h) {
  if (Array.isArray(this.value._rules) && this.value._rules.length) {
    return h(
      'div',
      {
        class: `${serviceName}-account-rules`
      },
      [ renderItems.call(this, h) ]
    )
  }
}
