export default {
  title: 'Keyword lists',

  one: 'Keyword list',
  many: 'Keyword lists',

  titles: {
    create: 'Create keyword list'
  },

  labels: {
    title: 'Title',
    search: 'Search'
  },

  dialogs: {
    new: 'New keyword list'
  },

  hints: {
    notFound: 'Keyword list not found'
  },

  contents: {
    confirm: {
      remove: 'Confirm remove {title}?'
    }
  },

  tooltips: {
    isActive: 'Activate or deactivate a keyword list.',
    favorites: 'Make the keyword list a favorite'
  },

  snackbars: {
    created: 'Keyword list successfully created.',
    updated: 'Keyword list successfully updated.',
    removed: 'Keyword list successfully removed.'
  }
}
