import render from './render'

const _ = { get: require('lodash/get') }

export default {
  name: 'Credentials',

  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    credentialsTooltip() {
      const result = []
      const text = []
      const fields = [ 'username', 'token', 'apikey' ]
      for (const field of fields) {
        const prefix = this.getTranslate(`credentials.labels.records.${field}`) + ': '
        const value = _.get(this.value, `Credentials.records.${field}`)
        if (value) {
          text.push(prefix + value)
        }
      }

      if (text.length) {
        result.push({
          name: 'g-tooltip',
          options: { value: text.join('\n') }
        })

        return result
      }
    }
  },

  render
}
