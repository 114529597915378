import proxy from '@sigma-legacy-libs/g-proxy'

import { unVue } from '@sigma-legacy-libs/unvue'

const _ = { isEqual: require('lodash/isEqual') }

export const dateFilter = {
  mixins: [ proxy({ type: 'object' }) ],

  methods: {
    _inputFilter(data) {
      const result = unVue(data)
      if (result.createdAt) {
        result.createdAt.$lt = this.prepareDate(result.createdAt.$lt)
      }

      return result
    },
    _outputFilter(data) {
      const result = unVue(data)
      if (result.createdAt) {
        result.createdAt.$lt = this.prepareDate(result.createdAt.$lt, true)
      }

      return result
    },

    watchProxyHandler() {
      if (!_.isEqual(this._outputFilter(this.proxy), this.value)) {
        this.transmitValue()
      }
    },

    prepareDate(date, add = false) {
      const lt = new Date(date)
      if (add) {
        lt.setMinutes(lt.getMinutes() + 1)
      } else {
        lt.setMinutes(lt.getMinutes() - 1)
      }

      return lt.toISOString()
    },

    getDefaultFilter() {
      return undefined
    },

    clearFilter() {
      this.proxy = unVue(this._inputFilter(this.getDefaultFilter()))
    }
  }
}

export default { dateFilter }
