import { generateKey, getUrlToStorage } from '@/utils'

export default function(h, ctx) {
  if (ctx.props.image) {
    let id
    if (typeof ctx.props.image === 'object') {
      id = generateKey(ctx.props.image)
    }

    if (typeof ctx.props.image === 'string') {
      id = ~ctx.props.image.indexOf('/') ? ctx.props.image : [ ctx.props.owner, ctx.props.image ].join('/')
    }

    return h(
      'div',
      {
        class: ctx.data.class,
        style: {
          'background-position': 'center',
          'background-repeat': 'no-repeat',
          'background-size': 'cover',
          'background-image': `url(${getUrlToStorage([ id ])})`,
          height: '100%'
        }
      },
      ctx.children
    )
  }
}
