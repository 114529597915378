import FileManager from '@/components/file/manager'
import RoutesFormTitle from '@/components/services/routes/form/title'
import keywordsList from '@/components/services/keywords/list'

import { serviceName } from '@/components/services/keywordLists/utils'

function renderTitleField(h) {
  return h(
    RoutesFormTitle,
    {
      props: {
        value: this.proxy,
        serviceName
      },
      on: {
        input: event => {
          this.proxy = event
        }
      }
    }
  )
}

function renderImportButton(h) {
  if (!this.isNew) {
    return h(
      'div',
      {
        class: 'fjcc'
      },
      [
        h(
          'g-button',
          {
            class: 'ma-0',
            props: {
              flat: true,
              rounded: true,
              label: this.getTranslate('misc.buttons.import'),
              icon: 'import_export',
              color: 'primary'
            },
            on: {
              click: () => {
                this.showFileManager = true
              }
            }
          }
        )
      ]
    )
  }
}

function renderFileManager(h) {
  if (!this.isNew) {
    return h(
      FileManager,
      {
        props: {
          show: this.showFileManager,
          flat: true,
          readonly: true,
          embedded: true,
          types: [ 'csv', 'xls', 'xlsx' ]
        },
        on: {
          show: event => {
            this.showFileManager = event
          },
          input: event => {
            this.showFileManager = false
            this.import(event.id)
          }
        }
      }
    )
  }
}

function renderKeywordsList(h) {
  if (!this.isNew) {
    return h(keywordsList)
  }
}

export default function(h) {
  return h(
    'g-form',
    {
      class: 'grid',
      style: { 'grid-template-columns': '100%' },
      on: {
        submit: () => {
          this.$emit('submit', this.proxy)
        }
      }
    },
    [
      renderTitleField.call(this, h),
      renderImportButton.call(this, h),
      renderFileManager.call(this, h),
      renderKeywordsList.call(this, h)
    ]
  )
}
