import titleDivider from '@/components/title/divider'
import rules from '@/components/misc/rules/conditions'

import { serviceName } from '@/components/services/providers/utils'

function renderRules(h) {
  return h(
    rules,
    {
      props: {
        value: this.proxy.rules,
        whitelist: [ 'sender', 'text', 'mccmnc' ]
      },
      on: {
        input: event => {
          this.proxy.rules = event
        }
      }
    }
  )
}

function renderReplacement(h) {
  return h(
    'div',
    {
      class: 'grid grid-cols--1'
    },
    [
      h(
        'g-select',
        {
          props: {
            value: this.proxy.replacement.payload.sender,
            label: this.getTranslate(`${serviceName}.labels.sendername`),
            service: 'sendernames',
            itemTitle: 'name',
            itemValue: 'name',
            mode: 'outline',
            rounded: true,
            details: false,
            combobox: true,
            clearable: true,
            autocomplete: true
          },
          on: {
            input: event => {
              this.proxy.replacement.payload.sender = event
            }
          }
        }
      ),

      h(
        'g-textarea',
        {
          props: {
            value: this.proxy.replacement.payload.text,
            label: this.getTranslate(`${serviceName}.labels.text`),
            mode: 'outline',
            grow: true,
            rounded: true,
            details: false,
            clearable: true
          },
          on: {
            input: event => {
              this.proxy.replacement.payload.text = event
            }
          }
        }
      )
    ]
  )
}

function renderContent(h) {
  return h(
    'g-form',
    {
      class: 'grid grid-cols--1'
    },
    [
      h(titleDivider, { props: { value: this.getTranslate(`${serviceName}.hints.rules`) } }),

      renderRules.call(this, h),

      h(titleDivider, { props: { value: this.getTranslate(`${serviceName}.hints.replacement`) } }),

      renderReplacement.call(this, h)
    ]
  )
}

export default function(h) {
  return renderContent.call(this, h)
}
