import { SENDING_STATUS } from '@sigma-legacy-libs/essentials/lib/constants'

import { cleanUserForSendingsFilterByOwner } from '@/utils'

import OwnerSelect from '@/components/misc/OwnerSelect'
import DateTimeFilter from '@/components/misc/DateTimeFilter'
import RowWithMessageTypeIcon from '@/components/misc/RowWithMessageTypeIcon'

function renderFieldProvider(h) {
  if (this.checkPermissions('advanced.providers.find', [ 'reseller', true ])) {
    return h(
      'g-select',
      {
        props: {
          value: this.proxy.meta._routing.provider,
          label: this.getTranslate('sendings.filter.meta._routing.provider'),
          service: 'providers',
          autocomplete: true,
          clearable: true,
          mode: 'outline',
          dense: true,
          rounded: true,
          details: false
        },
        on: {
          input: event => {
            this.proxy.meta._routing.provider = event
          }
        }
      }
    )
  }
}

function renderFirstColumn(h) {
  if (this.checkPermissions([ 'advanced.users.find', 'advanced.providers.find' ], [ 'reseller', true ])) {
    return h(
      'div',
      {
        class: 'grid grid-cols--1 grid-gap--8'
      },
      [
        h(
          OwnerSelect,
          {
            props: {
              value: this.proxy.meta.user.id,
              clearItems: cleanUserForSendingsFilterByOwner,
              mode: 'outline',
              dense: true,
              rounded: true,
              details: false
            },
            on: {
              input: event => {
                this.proxy.meta.user.id = event
              }
            }
          }
        ),

        renderFieldProvider.call(this, h)
      ]
    )
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: {
        'grid grid-gap--8 px-2': true,
        'grid-cols--5': this.viewport.breakpoint.lgUp,
        'grid-cols--3': this.viewport.breakpoint.md,
        'grid-cols--2': this.viewport.breakpoint.sm,
        'grid-cols--1': this.viewport.breakpoint.xs
      }
    },
    [
      renderFirstColumn.call(this, h),

      h(
        'div',
        {
          class: 'grid grid-cols--1 grid-gap--8'
        },
        [
          h(
            'g-select',
            {
              props: {
                value: this.proxy.type,
                label: this.getTranslate('sendings.filter.type'),
                items: this.getSendingTypesByPermission('advanced.sendings.find').map(item => ({
                  title: this.getTranslate(item.title),
                  value: item.value
                })),
                multiple: true,
                clearable: true,
                mode: 'outline',
                dense: true,
                rounded: true,
                details: false
              },
              scopedSlots: {
                item: ({ item }) => {
                  return h(
                    RowWithMessageTypeIcon,
                    {
                      props: {
                        value: item.value,
                        title: item.title
                      }
                    }
                  )
                }
              },
              on: {
                input: event => {
                  this.proxy.type = event
                }
              }
            }
          ),

          h(
            'g-select',
            {
              props: {
                value: this.proxy.state.status,
                label: this.getTranslate('sendings.filter.state.status'),
                items: SENDING_STATUS.map(status => ({
                  title: this.getTranslate(`sendings.statuses.${status}`),
                  value: status
                })),
                clearable: true,
                mode: 'outline',
                dense: true,
                rounded: true,
                details: false
              },
              on: {
                input: event => {
                  this.proxy.state.status = event
                }
              }
            }
          )
        ]
      ),

      h(
        'div',
        {
          class: 'grid grid-cols--1 grid-gap--8'
        },
        [
          h(
            'g-text-field',
            {
              props: {
                value: this.proxy.payload.recipient,
                label: this.getTranslate('sendings.filter.payload.recipient'),
                clearable: true,
                mode: 'outline',
                dense: true,
                rounded: true,
                details: false
              },
              on: {
                input: event => {
                  this.proxy.payload.recipient = event
                }
              }
            }
          ),

          h(
            'g-text-field',
            {
              props: {
                value: this.proxy.payload.sender,
                label: this.getTranslate('sendings.filter.payload.sender'),
                clearable: true,
                mode: 'outline',
                dense: true,
                rounded: true,
                details: false
              },
              on: {
                input: event => {
                  this.proxy.payload.sender = event
                }
              }
            }
          )
        ]
      ),

      h(
        'div',
        {
          class: 'grid grid-cols--1 grid-gap--8'
        },
        [
          h(
            'g-text-field',
            {
              props: {
                value: this.proxy.id,
                label: this.getTranslate('sendings.filter.id'),
                clearable: true,
                mode: 'outline',
                dense: true,
                rounded: true,
                details: false
              },
              on: {
                input: event => {
                  this.proxy.id = event
                }
              }
            }
          ),

          h(
            'g-text-field',
            {
              props: {
                value: this.proxy.groupId,
                label: this.getTranslate('sendings.filter.groupId'),
                clearable: true,
                mode: 'outline',
                dense: true,
                rounded: true,
                details: false
              },
              on: {
                input: event => {
                  this.proxy.groupId = event
                }
              }
            }
          )
        ]
      ),

      h(
        'div',
        {
          class: 'fc fjcc facc faic'
        },
        [
          h(
            DateTimeFilter,
            {
              props: { value: this.proxy.createdAt },
              on: {
                input: event => {
                  this.proxy.createdAt = event
                }
              }
            }
          ),

          h(
            'g-button',
            {
              props: {
                label: this.getTranslate('misc.filter.clear'),
                flat: true,
                rounded: true
              },
              on: {
                click: () => {
                  this.clearFilter()
                }
              }
            }
          )
        ]
      )
    ]
  )
}
