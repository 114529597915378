export default function(h, { path, serviceName }) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8',
      style: { gridTemplateColumns: 'repeat(2, 1fr) 36px' }
    },
    [
      h(
        'g-text-field',
        {
          props: {
            value: this.proxy.id,
            label: this.getTranslate(`${serviceName}.labels.${path}.id`),
            mode: 'outline',
            dense: true,
            rounded: true,
            details: false,
            clearable: true
          },
          on: {
            input: event => {
              this.proxy.id = event
            }
          }
        }
      ),

      h(
        'g-text-field',
        {
          props: {
            value: this.proxy.token,
            label: this.getTranslate(`${serviceName}.labels.${path}.token`),
            mode: 'outline',
            dense: true,
            rounded: true,
            details: false,
            clearable: true
          },
          on: {
            input: event => {
              this.proxy.token = event
            }
          }
        }
      ),

      h(
        'g-button',
        {
          class: 'ma-0',
          props: {
            depressed: true,
            icon: 'done',
            color: 'success'
          },
          on: {
            click: () => {
              this.$emit('input', this.proxy)
            }
          }
        }
      )
    ]
  )
}
