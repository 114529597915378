import { unVue } from '@sigma-legacy-libs/unvue'

import RefreshButton from '@/components/button/refresh'
import SingleLink from '@/components/misc/SingleLink'
import HeadTitle from '@/components/misc/HeadTitle'
import ContractsForm from '@/components/services/contracts/form'
import SpacerCell from '@/components/misc/SpacerCell'
import ExpandArrow from '@/components/misc/ExpandArrow'
import FileDialog from '@/components/file/dialog'
import OwnerSelect from '@/components/misc/OwnerSelect'
import pagination from '@/components/misc/pagination'

const name = 'contracts'

function renderCreateButton(h) {
  if (this.checkPermissions('advanced.contracts.create', [ 'reseller', true ])) {
    return h(
      'g-button',
      {
        style: { 'margin-bottom': this.viewport.breakpoint.smDown ? '66px' : undefined },
        props: {
          fab: true,
          icon: 'file_upload',
          fixed: true,
          right: true,
          bottom: true,
          color: 'secondary'
        },
        on: {
          click: () => {
            this.showCreateDialog = true
          }
        }
      }
    )
  }

  return h('div')
}

function renderCreateDialog(h) {
  if (this.checkPermissions('advanced.contracts.create', [ 'reseller', true ])) {
    return h(
      'g-dialog',
      {
        props: {
          rounded: true,
          value: this.showCreateDialog,
          maxWidth: 500
        },
        on: {
          input: event => {
            this.showCreateDialog = event
          }
        }
      },
      [
        h(
          HeadTitle,
          {
            props: { value: this.getTranslate(`${name}.titles.upload`) },
            slot: 'header'
          }
        ),

        h(
          ContractsForm,
          {
            props: {
              value: this.restData[name].create.data
            },
            on: {
              input: event => {
                this.restData[name].create.data = event
              },
              validation: event => {
                this.restData[name].create.isValid = event
              }
            }
          }
        ),

        h(
          'g-card-actions',
          {
            class: 'fjcfe facfe',
            slot: 'footer'
          },
          [
            h(
              'g-button',
              {
                props: {
                  label: this.getTranslate('misc.buttons.cancel'),
                  flat: true,
                  rounded: true,
                  disabled: this.restData[name].create.state === 'loading'
                },
                on: {
                  click: () => {
                    this.showCreateDialog = false
                  }
                }
              }
            ),

            h(
              'g-button',
              {
                props: {
                  label: this.getTranslate('misc.buttons.upload'),
                  rounded: true,
                  depressed: true,
                  loading: this.restData[name].create.state === 'loading',
                  disabled: !this.restData[name].create.isValid || this.restData[name].create.state === 'loading',
                  color: 'primary'
                },
                on: {
                  click: () => {
                    this.createContract()
                  }
                }
              }
            )
          ]
        )
      ]
    )
  }
}

function renderConfirmRemoveDialog(h) {
  if (this.checkPermissions('advanced.contracts.remove', [ 'reseller', true ])) {
    return h(
      'g-dialog',
      {
        props: {
          rounded: true,
          value: this.showConfirmRemoveDialog,
          maxWidth: 400
        },
        on: {
          input: event => {
            this.showConfirmRemoveDialog = event
          }
        }
      },
      [
        h(
          HeadTitle,
          {
            props: { value: this.getTranslate(`${name}.titles.confirm.remove`) },
            slot: 'header'
          }
        ),

        h(
          'div',
          {
            class: 'pa-3'
          },
          this.getTranslate(`${name}.contents.confirm.remove`)
        ),

        h(
          'g-card-actions',
          {
            class: 'fjcfe facfe',
            slot: 'footer'
          },
          [
            h(
              'g-button',
              {
                props: {
                  label: this.getTranslate('misc.buttons.cancel'),
                  flat: true,
                  rounded: true,
                  disabled: this.restData[name].remove.state === 'loading'
                },
                on: {
                  click: () => {
                    this.showConfirmRemoveDialog = false
                  }
                }
              }
            ),

            h(
              'g-button',
              {
                props: {
                  label: this.getTranslate('misc.buttons.remove'),
                  rounded: true,
                  depressed: true,
                  loading: this.restData[name].remove.state === 'loading',
                  disabled: this.restData[name].remove.state === 'loading',
                  color: 'error'
                },
                on: {
                  click: () => {
                    this.removeContract()
                  }
                }
              }
            )
          ]
        )
      ]
    )
  }
}

function renderRenameDialog(h) {
  if (this.checkPermissions('advanced.contracts.update', [ 'reseller', true ])) {
    return h(
      'g-dialog',
      {
        props: {
          rounded: true,
          value: this.showRenameDialog,
          maxWidth: 400
        },
        on: {
          input: event => {
            this.showRenameDialog = event
          }
        }
      },
      [
        h(
          HeadTitle,
          {
            props: { value: this.getTranslate('commons.titles.rename') },
            slot: 'header'
          }
        ),

        h(
          'div',
          {
            class: 'pa-3'
          },
          [
            h(
              'g-text-field',
              {
                props: {
                  value: this.currentEditContract.title,
                  label: this.getTranslate(`${name}.labels.title`),
                  mode: 'outline',
                  rounded: true,
                  details: false,
                  clearable: true
                },
                on: {
                  input: event => {
                    this.currentEditContract.title = event
                  },
                  keypress: event => {
                    if (event.keyCode === 13) {
                      this.renameContract()
                    }
                  }
                }
              }
            )
          ]
        ),

        h(
          'g-card-actions',
          {
            class: 'fjcfe facfe',
            slot: 'footer'
          },
          [
            h(
              'g-button',
              {
                props: {
                  label: this.getTranslate('misc.buttons.cancel'),
                  flat: true,
                  rounded: true,
                  disabled: this.restData[name].update.state === 'loading'
                },
                on: {
                  click: () => {
                    this.showRenameDialog = false
                  }
                }
              }
            ),

            h(
              'g-button',
              {
                props: {
                  label: this.getTranslate('misc.buttons.rename'),
                  flat: true,
                  rounded: true,
                  loading: this.restData[name].update.state === 'loading',
                  disabled: !this.currentEditContract.title || this.restData[name].update.state === 'loading',
                  color: 'primary'
                },
                on: {
                  click: () => {
                    this.renameContract()
                  }
                }
              }
            )
          ]
        )
      ]
    )
  }
}

function renderEditListItem(h, item) {
  if (this.checkPermissions('advanced.contracts.update', [ 'reseller', true ])) {
    return h(
      'g-list-item',
      {
        props: {
          icon: 'description',
          label: this.getTranslate('misc.buttons.edit')
        },
        on: {
          click: () => {
            this.editFile(item)
          }
        }
      }
    )
  }
}

function renderRenameListItem(h, item) {
  if (this.checkPermissions('advanced.contracts.update', [ 'reseller', true ])) {
    return h(
      'g-list-item',
      {
        props: {
          icon: 'edit',
          label: this.getTranslate('misc.buttons.rename')
        },
        on: {
          click: () => {
            this.currentEditContract = unVue(item)
            this.showRenameDialog = true
          }
        }
      }
    )
  }
}

function renderDownloadListItem(h, item) {
  return h(
    'g-list-item',
    {
      props: {
        icon: 'file_download',
        label: this.getTranslate('misc.buttons.download'),
        color: 'primary'
      },
      on: {
        click: () => {
          this.downloadFile(item)
        }
      }
    }
  )
}

function renderRemoveListItem(h, item) {
  if (this.checkPermissions('advanced.contracts.remove', [ 'reseller', true ])) {
    return h(
      'g-list-item',
      {
        props: {
          icon: 'delete',
          label: this.getTranslate('misc.buttons.remove'),
          color: 'error'
        },
        on: {
          click: () => {
            this.currentEditContract = unVue(item)
            this.showConfirmRemoveDialog = true
          }
        }
      }
    )
  }
}

function renderActions(h, item) {
  return h(
    'g-menu',
    {
      class: { 'pl-2': this.type === 'cards' },
      props: { disabled: this.editFileProgress[item.id] },
      key: 'actions-menu:' + item.id
    },
    [
      h(
        'g-button',
        {
          props: {
            icon: 'more_vert',
            flat: true,
            loading: this.editFileProgress[item.id],
            disabled: this.editFileProgress[item.id]
          },
          slot: 'activator'
        }
      ),

      h(
        'g-list',
        {
          props: {
            dense: this.viewport.breakpoint.mdUp
          }
        },
        [
          renderEditListItem.call(this, h, item),
          renderRenameListItem.call(this, h, item),
          renderDownloadListItem.call(this, h, item),
          renderRemoveListItem.call(this, h, item)
        ]
      )
    ]
  )
}

function renderDivider(h) {
  if (this.restData[name].find.pagination.total) {
    return h('g-divider')
  }
}

function renderHeader(h) {
  return h(
    this.viewport.breakpoint.mdUp ? 'div' : 'g-expansion-panel',
    {
      scopedSlots: {
        header: ({ expanded }) => {
          if (this.viewport.breakpoint.smDown) {
            return h(
              'div',
              {
                class: 'default-expansion-panel-header pl-3 pr-0'
              },
              [
                this.getTranslate('commons.titles.filter'),

                h('div', { class: 'ff' }),

                h(ExpandArrow, { props: { expanded } })
              ]
            )
          }
        }
      }
    },
    [
      h(
        'div',
        {
          class: {
            'grid grid-gap--8 pa-2': true,
            'grid-cols--4': this.viewport.breakpoint.mdUp,
            'grid-cols--1': this.viewport.breakpoint.smDown
          }
        },
        [
          h(
            'g-text-field',
            {
              props: {
                value: this.restData[name].find.filter.$search,
                label: this.getTranslate('commons.labels.search'),
                mode: 'outline',
                dense: true,
                rounded: true,
                details: false,
                clearable: true
              },
              on: {
                input: event => {
                  this.restData[name].find.filter.$search = event
                }
              }
            }
          ),

          h(
            OwnerSelect,
            {
              props: {
                value: this.restData[name].find.filter.OwnerId,
                mode: 'outline',
                dense: true,
                rounded: true,
                details: false,
                clearable: true
              },
              on: {
                input: event => {
                  this.restData[name].find.filter.OwnerId = event
                }
              }
            }
          ),

          h(
            'g-select',
            {
              props: {
                value: this.restData[name].find.filter.RequisiteId,
                label: this.getTranslate(`${name}.labels.requisite`),
                service: 'requisites',
                mode: 'outline',
                dense: true,
                rounded: true,
                details: false,
                clearable: true,
                autocomplete: true
              },
              on: {
                input: event => {
                  this.restData[name].find.filter.RequisiteId = event
                }
              }
            }
          ),

          h(
            'g-select',
            {
              props: {
                value: this.restData[name].find.filter.CompanyId,
                label: this.getTranslate(`${name}.labels.company`),
                service: 'companies',
                mode: 'outline',
                dense: true,
                rounded: true,
                details: false,
                clearable: true,
                autocomplete: true
              },
              on: {
                input: event => {
                  this.restData[name].find.filter.CompanyId = event
                }
              }
            }
          )
        ]
      )
    ]
  )
}

function renderPagination(h) {
  if (this.restData[name].find.pagination.total) {
    return h(
      'div',
      {
        class: 'grid grid-gap--8 fjcfe faic pa-2',
        style: { 'grid-template-columns': '1fr 36px' }
      },
      [
        h(
          pagination, {
            props: {
              value: this.restData[name].find.pagination
            },
            on: {
              input: event => {
                this.restData[name].find.pagination = event
              }
            }
          }
        ),

        h(
          RefreshButton,
          {
            class: 'ma-0',
            props: {
              state: this.restData[name].find.state,
              shortClickMethod: () => this.rest[name].find(),
              longClickMethod: () => this.rest[name].find({}, { noCache: true })
            }
          }
        )
      ]
    )
  }
}

function renderLink(h, options = {}) {
  if (options.value) {
    return h(
      SingleLink,
      {
        props: {
          value: options.value,
          service: options.service || 'users'
        }
      }
    )
  }
}

function renderFormattedDate(h, date) {
  return h(
    'div',
    {
      class: 'text--grey text-right',
      style: { 'white-space': 'pre' },
      domProps: { innerHTML: date }
    }
  )
}

function renderBody(h) {
  if (this.viewport.breakpoint.mdUp) {
    return h(
      'g-table',
      {
        props: {
          headers: this.headers,
          hideHead: this.restData[name].find.pagination.total < 1,
          items: this.restData[name].find.data,
          value: this.restData[name].find.order,
          noDataText: this.getTranslate('misc.no.data.text')
        },
        on: {
          input: event => {
            this.restData[name].find.order = event
          }
        },
        scopedSlots: {
          items: ({ item }) => {
            return h(
              'tr',
              {
                key: item.id
              },
              [
                h(
                  'td',
                  {
                    class: 'px-2 w--100'
                  },
                  [ h(SpacerCell, { props: { value: item.title } }) ]
                ),

                h(
                  'td',
                  {
                    class: 'px-2'
                  },
                  [
                    renderLink.call(this, h, {
                      value: item.Owner
                    })
                  ]
                ),

                h(
                  'td',
                  {
                    class: 'px-2'
                  },
                  [
                    renderLink.call(this, h, {
                      value: item.Requisite,
                      service: 'requisites'
                    })
                  ]
                ),

                h(
                  'td',
                  {
                    class: 'px-2'
                  },
                  [
                    renderLink.call(this, h, {
                      value: item.Company,
                      service: 'companies'
                    })
                  ]
                ),

                h(
                  'td',
                  {
                    class: 'px-2'
                  },
                  [ renderFormattedDate.call(this, h, item.$formattedCreatedAt) ]
                ),

                h(
                  'td',
                  {
                    class: 'px-2'
                  },
                  [ renderFormattedDate.call(this, h, item.$formattedUpdatedAt) ]
                ),

                h(
                  'td',
                  {
                    class: 'pa-0'
                  },
                  [ renderActions.call(this, h, item) ]
                )
              ]
            )
          }
        }
      }
    )
  } else {
    return h(
      'g-expansion-panels',
      {
        props: { flat: true }
      },
      this.restData[name].find.data.map(item => {
        return h(
          'g-expansion-panel',
          {
            scopedSlots: {
              header: ({ expanded }) => {
                return h(
                  'div',
                  {
                    class: 'default-expansion-panel-header pl-2 pr-0'
                  },
                  [
                    h(SpacerCell, { props: { value: item.title } }),
                    h(ExpandArrow, { props: { expanded } })
                  ]
                )
              }
            }
          },
          [
            h(
              'div',
              {
                class: 'faic'
              },
              [
                h(
                  'div',
                  {
                    class: 'fc caption pa-2'
                  },
                  [
                    h(
                      'div',
                      {
                        class: 'faic'
                      },
                      [
                        h(
                          'div',
                          {
                            class: 'text--grey mr-1'
                          },
                          this.getTranslate(`${name}.headers.owner`) + ':'
                        ),

                        renderLink.call(this, h, { value: item.Owner })
                      ]
                    ),

                    h(
                      'div',
                      {
                        class: 'faic'
                      },
                      [
                        h(
                          'div',
                          {
                            class: 'text--grey mr-1'
                          },
                          this.getTranslate(`${name}.headers.requisite`) + ':'
                        ),

                        renderLink.call(this, h, {
                          value: item.Requisite,
                          service: 'requisites'
                        })
                      ]
                    ),

                    h(
                      'div',
                      {
                        class: 'faic'
                      },
                      [
                        h(
                          'div',
                          {
                            class: 'text--grey mr-1'
                          },
                          this.getTranslate(`${name}.headers.company`) + ':'
                        ),

                        renderLink.call(this, h, {
                          value: item.Company,
                          service: 'companies'
                        })
                      ]
                    )
                  ]
                ),

                h('div', { class: 'ff' }),

                renderActions.call(this, h, item)
              ]
            )
          ]
        )
      })
    )
  }
}

export default function(h) {
  return h(
    'g-card',
    {
      props: { rounded: true }
    },
    [
      renderHeader.call(this, h),
      renderDivider.call(this, h),
      renderPagination.call(this, h),
      renderDivider.call(this, h),
      renderBody.call(this, h),
      renderDivider.call(this, h),
      renderPagination.call(this, h),
      renderCreateButton.call(this, h),
      renderCreateDialog.call(this, h),
      renderConfirmRemoveDialog.call(this, h),
      renderRenameDialog.call(this, h),

      h(
        FileDialog,
        {
          props: {
            value: this.currentEditFile,
            googleFileURL: this.googleFileURL,
            show: this.showEditDialog,
            type: this.editDialogType
          },
          on: {
            show: event => {
              this.showEditDialog = event
            },
            input: event => {
              this.currentEditFile = event
            }
          }
        }
      )
    ]
  )
}
