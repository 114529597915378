import proxy from '@sigma-legacy-libs/g-proxy'

import render from './render'

export default {
  name: 'Form',

  mixins: [ proxy({ type: 'object' }) ],

  props: {
    isNew: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },

    itemsDisabled: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    validation() {
      if (!this.proxy.operator || !this.proxy.price) {
        return false
      }

      return true
    }
  },

  watch: {
    validation() {
      this.$emit('validation', this.validation)
    }
  },

  mounted() {
    this.$emit('validation', this.validation)
  },

  render
}
