import { unVue } from '@sigma-legacy-libs/unvue'
import { PUBLIC_FIELDS_USER } from '@sigma-legacy-libs/essentials/lib/constants'

import { PAYMENTS_FILTER, fillDependence, generateServices, getToday } from '@/utils'

import render from './render'

const name = 'payments'

export default function(options = {}) {
  const defaultFilter = unVue(PAYMENTS_FILTER)
  if (options.isPage) {
    Object.assign(defaultFilter, { createdAt: getToday() })
  }

  return {
    name: 'PaymentsList',

    mixins: [
      generateServices({
        name,

        async inputFilter(result) {
          if (!result.Owner) {
            await fillDependence.call(this, result, {
              service: 'users',
              permissions: [ 'reseller', true ],
              pathToId: 'OwnerId',
              pathToObject: 'Owner',
              outputPath: 'Owner',
              picks: PUBLIC_FIELDS_USER
            })
          }

          result.OwnerId = result.OwnerId || result.Owner && result.Owner.id

          return result
        },

        find: {
          defaultFilter,
          alwaysCreateFromWebSocket: true,
          alwaysUpdateFromWebSocket: true,
          alwaysRemoveFromWebSocket: true
        },

        get: false,
        create: false,
        update: false,
        remove: false
      })
    ],

    data() {
      return {
        showPaymentLogsDialog: false,
        showBillingsDialog: false,

        paymentsBillings: [],

        paymentId: undefined
      }
    },

    mounted() {
      if (options.isPage) {
        this.rest[name].find()
      } else {
        this.restData[name].find.filter.RecurrentPaymentId = options.RecurrentPaymentId
      }
    },

    render(h) {
      return render.call(this, h, options)
    }
  }
}
