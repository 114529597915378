import { RECURRENT_PAYMENTS_METHODS, RECURRENT_PAYMENTS_STATUSES, RECURRENT_PAYMENTS_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import OwnerSelect from '@/components/misc/OwnerSelect'

const name = 'recurrentPayments'

export default function(h) {
  return h(
    'div',
    {
      class: {
        ff: true,
        faic: this.viewport.breakpoint.smUp,
        fc: this.viewport.breakpoint.xs
      }
    },
    [
      h(
        'div',
        {
          class: {
            'grid grid-gap--8 ff py-2 pl-2': true,
            'grid-cols--4': this.viewport.breakpoint.smUp,
            'grid-cols--1 pr-2': this.viewport.breakpoint.xs
          }
        },
        [
          h(
            OwnerSelect,
            {
              props: {
                value: this.proxy.OwnerId,
                mode: 'outline',
                dense: true,
                rounded: true,
                details: false,
                clearable: true
              },
              on: {
                input: event => {
                  this.proxy.OwnerId = event
                }
              }
            }
          ),

          h(
            'g-select',
            {
              props: {
                value: this.proxy.type,
                label: this.getTranslate(`${name}.labels.type`),
                items: RECURRENT_PAYMENTS_TYPES.map(type => {
                  return {
                    title: this.getTranslate(`${name}.types.${type}`),
                    value: type
                  }
                }),
                mode: 'outline',
                dense: true,
                rounded: true,
                details: false,
                clearable: true
              },
              on: {
                input: event => {
                  this.proxy.type = event
                }
              }
            }
          ),

          h(
            'g-select',
            {
              props: {
                value: this.proxy.status,
                label: this.getTranslate(`${name}.labels.status`),
                items: RECURRENT_PAYMENTS_STATUSES.map(status => {
                  return {
                    title: this.getTranslate(`${name}.statuses.${status}`),
                    value: status
                  }
                }),
                mode: 'outline',
                dense: true,
                rounded: true,
                details: false,
                clearable: true
              },
              on: {
                input: event => {
                  this.proxy.status = event
                }
              }
            }
          ),

          h(
            'g-select',
            {
              props: {
                value: this.proxy.method,
                label: this.getTranslate(`${name}.labels.method`),
                items: RECURRENT_PAYMENTS_METHODS.map(method => {
                  return {
                    title: this.getTranslate(`${name}.methods.${method}`),
                    value: method
                  }
                }),
                mode: 'outline',
                dense: true,
                rounded: true,
                details: false,
                clearable: true
              },
              on: {
                input: event => {
                  this.proxy.method = event
                }
              }
            }
          )
        ]
      ),

      h(
        'div',
        {
          class: 'faic'
        },
        [ this.$scopedSlots.buttons ? this.$scopedSlots.buttons() : this.$slots.buttons ]
      )
    ]
  )
}
