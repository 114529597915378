const _ = { get: require('lodash/get') }

export function cleanUserForSendingsFilterByOwner(items) {
  const id = _.get(this.account, 'id', '')
  let prepend = true

  if (this.searchValue) {
    const username = _.get(this.account, 'username', '')
    const email = _.get(this.account, 'data.email', '')
    const phone = _.get(this.account, 'data.phone', '')
    if (
      !~username.indexOf(this.searchValue) &&
      !~email.indexOf(this.searchValue) &&
      !~phone.indexOf(this.searchValue)
    ) {
      prepend = false
    }
  }

  if (~items.findIndex(item => item.id === id)) {
    prepend = false
  }

  if (prepend) {
    items.unshift(this.account)
  }

  return items
}

export default { cleanUserForSendingsFilterByOwner }
