import Vue from 'vue'
import proxy from '@sigma-legacy-libs/g-proxy'

import { SUBSCRIPTION_OPERATORS } from '@sigma-legacy-libs/essentials/lib/constants'

import render from './render'

const _ = { isEmpty: require('lodash/isEmpty') }

export default {
  name: 'Operators',

  mixins: [ proxy({ type: 'object' }) ],

  data() {
    return {
      operators: []
    }
  },

  methods: {
    _inputFilter(result) {
      if (_.isEmpty(result)) {
        const operators = {}
        for (const operator of SUBSCRIPTION_OPERATORS.sort()) {
          const { approved = false, isPaid = false } = result[operator] || {}
          operators[operator] = {
            approved,
            isPaid
          }
        }

        result = operators
      }

      return result
    },

    async getOperators() {
      const { data } = await Vue.$GRequest.find('operatorPrices') || {}

      if (data.data && data.data.length) {
        this.operators = data.data
      }
    }
  },

  mounted() {
    this.getOperators()
  },

  render
}
