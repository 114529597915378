export default {
  title: 'Обновление цен тарифов',

  titles: {
    create: 'Создание связи обновления цен',
    child: 'Дочерние тарифы'
  },

  labels: {
    default: 'Обычная цена',
    price: 'Цена',
    exceptions: 'Исключения',
    tariff: {
      parent: 'Родительский тариф',
      child: 'Дочерний тариф'
    }
  },

  buttons: {
    operators: 'Операторы',
    addParent: 'Добавить родительский тариф',
    addChild: 'Добавить дочерний тариф'
  },

  tooltips: {
    favorites: 'Добавить в избранное'
  },

  hints: {
    wholeCountry: 'Вся страна'
  },

  snackbars: {
    created: 'Связь обновления цен создана',
    updated: 'Связь обновления цен обновлена',
    removed: 'Связь обновления цен удалена'
  }
}
