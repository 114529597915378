export default {
  title: 'Servers',

  titles: {
    one: 'Server',
    create: 'Create server',
    update: 'Update server',
    remove: 'Remove server'
  },

  labels: {
    title: 'Title',
    host: 'Host',
    port: 'Port',
    processor: 'Processor',
    search: 'Search'
  },

  contents: {
    confirm: {
      remove: 'Confirm remove server.'
    }
  },

  hints: {
    notFound: 'Servers not found.',
    other: 'Other'
  },

  tooltips: {
    favorites: 'Make the server a favorite'
  },

  snackbars: {
    created: 'Server created',
    updated: 'Server updated',
    removed: 'Server removed'
  }
}
