import { serviceName } from '@/components/services/subscriptions/utils'
import { getPrice } from '@/utils'

function renderOperator(h, { operator, price }) {
  return h(
    'div',
    {
      class: {
        'grid grid-gap--8 faic border border--rounded py-2 plpx-12 pr-2': true,
        'border--dashed': !this.proxy[operator].approved && !this.proxy[operator].isPaid,
        'border--warning': this.proxy[operator].approved && !this.proxy[operator].isPaid,
        'border--success': this.proxy[operator].approved && this.proxy[operator].isPaid
      },
      style: { gridTemplateColumns: 'auto 1fr repeat(2, 36px)' }
    },
    [
      h('div', {}, this.getTranslate(`${serviceName}.operators.${operator}`)),

      h(
        'div',
        {
          class: 'caption text--grey'
        },
        [ getPrice(price) ]
      ),

      h(
        'g-button',
        {
          class: 'ma-0',
          props: {
            icon: 'currency_ruble',
            flat: this.proxy[operator].isPaid ? false : true,
            outline: this.proxy[operator].isPaid ? true : false,
            color: this.proxy[operator].isPaid ? 'success' : 'warning'
          },
          directives: [
            {
              name: 'g-tooltip',
              value: this.getTranslate(`${serviceName}.hints.operator.isPaid`)
            }
          ],
          on: {
            click: () => {
              this.proxy[operator].isPaid = !this.proxy[operator].isPaid
            }
          }
        }
      ),
      h(
        'g-button',
        {
          class: 'ma-0',
          props: {
            icon: 'done',
            flat: this.proxy[operator].approved ? false : true,
            outline: this.proxy[operator].approved ? true : false,
            color: this.proxy[operator].approved ? 'success' : 'warning'
          },
          directives: [
            {
              name: 'g-tooltip',
              value: this.getTranslate(`${serviceName}.hints.operator.approved`)
            }
          ],
          on: {
            click: () => {
              this.proxy[operator].approved = !this.proxy[operator].approved
            }
          }
        }
      )
    ]
  )
}

function renderContent(h) {
  if (this.operators.length === 0) {
    return h(
      'div',
      {
        class: 'grid grid-gap--8 faic caption text--grey border border--dashed border--rounded pa-2',
        style: { gridTemplateColumns: '24px 1fr' }
      },
      [
        h(
          'g-icon',
          {
            props: {
              value: 'info',
              size: 24
            }
          }
        ),

        h(
          'div',
          {},
          this.getTranslate(`${serviceName}.hints.no.operators`)
        )
      ]
    )
  }

  return this.operators.map(operator => renderOperator.call(this, h, operator))
}

export default function(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8'
    },
    [ renderContent.call(this, h) ]
  )
}
