import render from './render'

export default function(options = {}) {
  return {
    name: 'Item',

    props: {
      value: {
        type: Object,
        default: () => ({})
      },

      type: {
        type: String,
        default: 'card',
        validator: value => {
          return ~[ 'row', 'card' ].indexOf(value)
        }
      },

      size: {
        type: String,
        default: 'small',
        validator: value => {
          return ~[ 'small', 'medium', 'large' ].indexOf(value)
        }
      }
    },

    render(h) {
      return render.call(this, h, options)
    }
  }
}
