export default function(h, { path, serviceName }) {
  return h(
    'g-select',
    {
      props: {
        value: this.proxy,
        label: this.getTranslate(`${serviceName}.labels.${path}`),
        service: 'users',
        query: { isActive: true },
        itemTitle: 'username',
        mode: 'outline-label',
        dense: true,
        rounded: true,
        clearable: true,
        autocomplete: true,
        details: false
      },
      on: {
        input: event => {
          this.$emit('input', event)
        }
      }
    }
  )
}
