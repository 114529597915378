export default {
  title: 'Payments log',

  tabs: {
    request: 'Request',
    response: 'Response'
  },

  directions: {
    incoming: 'Incoming',
    outgoing: 'Outgoing'
  },

  hints: {
    notFound: 'No records found'
  }
}
