import { ruleProcessor } from '@/utils'

import render from './render'

export default {
  name: 'Row',

  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    computedProxy: {
      get() {
        return this.value
      },
      set(value) {
        return value
      }
    }
  },

  mounted() {
    if (Array.isArray(this.computedProxy.rules) && this.computedProxy.rules.length) {
      for (const rule of this.computedProxy.rules) {
        rule.value = ruleProcessor(rule.value, rule.type || rule.tag)
      }
    }
  },

  render
}
