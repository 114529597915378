import Vue from 'vue'

import { mapGetters } from 'vuex'

import { globalErrorHandler, globalErrorProcessor } from '@/utils'

import render from './render'

export default {
  name: 'ProvidersPricesImport',

  props: {
    ProviderId: String,

    disabled: Boolean
  },

  data() {
    return {
      showFileManager: false,
      loading: false
    }
  },

  computed: {
    ...mapGetters({ uploadedFile: 'file/uploadedFile' })
  },

  watch: {
    uploadedFile: {
      async handler({ data }) {
        this.import(data.id)

        this.showFileManager = false
      },
      deep: true
    }
  },

  methods: {
    async import(fileId) {
      try {
        this.loading = true
        await Vue.$GRequest.update('providersPrices', 'import', {
          ProviderId: this.ProviderId,
          fileId
        })
        this.$emit('finish', true)
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      } finally {
        this.loading = false
      }
    }
  },

  render
}
