import ExpandArrow from '@/components/misc/ExpandArrow'
import EntityTable from '@/components/misc/EntityTable'

const _ = { get: require('lodash/get') }

function renderItem(h, item) {
  return h(
    EntityTable,
    {
      props: {
        rows: [
          {
            title: this.getTranslate('requisites.labels.data.personal.firstName'),
            value: [
              _.get(item, 'contact.firstName'),
              _.get(item, 'contact.middleName'),
              _.get(item, 'contact.lastName')
            ].filter(v => !!v).join(' ')
          },
          {
            title: this.getTranslate('requisites.labels.data.legal.name'),
            copy: true,
            value: _.get(item, 'data.legal.name')
          },
          {
            title: this.getTranslate('requisites.labels.data.legal.inn'),
            copy: true,
            value: _.get(item, 'data.legal.inn')
          }
        ]
      }
    }
  )
}

function renderItems(h) {
  if (this.requisites.length) {
    return h(
      'div',
      {
        class: 'py-2'
      },
      this.requisites.map(item => renderItem.call(this, h, item))
    )
  } else {
    return h('g-empty')
  }
}

export default function(h) {
  return h(
    'g-expansion-panels',
    {
      props: {
        outline: true,
        rounded: true
      }
    },
    [
      h(
        'g-expansion-panel',
        {
          scopedSlots: {
            header: ({ expanded }) => {
              return h(
                'div',
                {
                  class: 'default-expansion-panel-header pr-0'
                },
                [
                  h('div', {}, this.getTranslate('requisites.title')),
                  h('div', { class: 'ff' }),
                  h(ExpandArrow, { props: { expanded } })
                ]
              )
            },
            default: () => renderItems.call(this, h)
          }
        }
      )
    ]
  )
}
