import proxy from '@sigma-legacy-libs/g-proxy'

import { setPrice } from '@/components/services/tariffs/utils'

import render from './render'

export default {
  name: 'PriceTextField',

  mixins: [ proxy({ type: 'array' }) ],

  props: {
    meta: {
      type: Object,
      default: () => {
        return {
          type: undefined,
          currency: undefined
        }
      }
    }
  },

  watch: {
    meta: {
      handler() {
        this.proxy = setPrice(this.proxy, this.meta.type)
      },
      deep: true
    }
  },

  render
}
