import proxy from '@sigma-legacy-libs/g-proxy'

import Conditions from '@sigma-legacy-libs/essentials/lib/utils/Conditions'

import { ruleProcessor } from '@/components/services/tariffs/utils'

import render from './render'

const _ = { isEqual: require('lodash/isEqual') }

export default {
  name: 'Conditions',

  mixins: [ proxy({ type: 'array' }) ],

  props: {
    type: String,

    whitelist: {
      type: Array,
      default: () => []
    },
    blacklist: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      showDialog: false,
      tag: undefined
    }
  },

  methods: {
    _inputFilter(data) {
      const result = {}
      for (const key in Conditions.tagsMeta) {
        if (this.whitelist.length) {
          if (!~this.whitelist.indexOf(key)) {
            continue
          }
        }
        if (this.blacklist.length) {
          if (~this.blacklist.indexOf(key)) {
            continue
          }
        }
        const tag = Conditions.tagsMeta[key].tag
        if (!result[tag]) {
          result[tag] = ruleProcessor(undefined, tag)
        }
      }
      if (Array.isArray(data)) {
        data.reduce((accumulator, { path, type, tag, value }) => {
          const tagMeta = Conditions.tagsMeta[path || type || tag]
          if (tagMeta) {
            accumulator[tagMeta.tag] = ruleProcessor(value, tagMeta.tag)
          }

          return accumulator
        }, result)
      }

      return result
    },

    _outputFilter(data) {
      const result = []
      for (const tag in data) {
        const value = data[tag]
        if (Conditions.validateValue(value, tag)) {
          result.push({
            tag,
            value
          })
        }
      }

      return result
    },

    _tagFilter(tag) {
      if (this.whitelist.length) {
        if (!~this.whitelist.indexOf(tag)) {
          return false
        }
      }
      if (this.blacklist.length) {
        if (~this.blacklist.indexOf(tag)) {
          return false
        }
      }

      return true
    },

    receiveValue() {
      const value = this._inputFilter(this.value)
      if (!_.isEqual(this.proxy, value)) {
        this.proxy = value
      }
    },

    transmitValue() {
      const proxy = this._outputFilter(this.proxy)
      if (!_.isEqual(proxy, this.value)) {
        this.$emit('input', proxy)
      }
    }
  },

  render
}
