import notificationsEnabled from '@/components/services/settings/notifications/enabled'

export default function(h, { serviceName }) {
  return h(
    notificationsEnabled,
    {
      props: {
        value: this.proxy,
        serviceName
      },
      on: {
        input: event => {
          this.proxy = event
        }
      }
    }
  )
}
