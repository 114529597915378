import { fillStringZeros, leadZero } from '@/utils'

export function millisecondsToHummerTime(value = 0, ms = false) {
  if (~[ 'string', 'number' ].indexOf(typeof value)) {
    value = parseFloat(value / 1000, 10).toFixed(3)
    if (!isNaN(value)) {
      const hummerTime = []
      const result = []

      const hours = Math.floor(value / 60 / 60)
      const minutes = Math.floor(value / 60) % 60
      const seconds = Math.floor(value - minutes * 60)
      const milliseconds = value.slice(-3)

      if (hours > 0) {
        hummerTime.push(hours)
      }
      hummerTime.push(leadZero(minutes))
      hummerTime.push(leadZero(seconds))

      result.push(hummerTime.join(':'))

      if (ms) {
        result.push(fillStringZeros(milliseconds, 3))
      }

      return result.filter(v => !!v).join('.')
    }
  }
}
