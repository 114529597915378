import Vue from 'vue'
import VueRouter from 'vue-router'
import VuexRouterSync from 'vuex-router-sync'

import store from '@/store'
import routes from '@/routes'

import { checkPermissions } from '@/utils'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject)
  }

  return originalPush.call(this, location).catch(err => err)
}

export const router = new VueRouter({ routes })
router.beforeEach((to, from, next) => {
  if (to.matched.some(m => m.meta.auth) && !store.getters['authentication/token']) {
    next({ name: 'login' })
  } else if (to.matched.some(m => m.meta.guest) && store.getters['authentication/token']) {
    next({ name: 'home' })
  } else if (to.meta.permission && store.getters['account/account'] && store.getters['account/account'].id) {
    if (
      checkPermissions(
        to.meta.permission,
        'or',
        to.meta.permissionAccept || [ 'me', 'reseller', true ],
        store.getters['account/account'].permissions
      )
    ) {
      next()
    } else {
      store
        .dispatch('notifications/addSnackbar', {
          text: 'Нет прав на просмотр данной страницы.',
          type: 'error'
        })
        .then(() => {
          next({ name: 'account' })
        })
    }
  } else if (
    !to.matched.some(m =>
      m.meta.handler
        ? m.meta.handler({
          store,
          to,
          from,
          next
        })
        : false)
  ) {
    next()
  }
})

VuexRouterSync.sync(store, router)
Vue.router = router

export default { router }
