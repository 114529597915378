export default function(h) {
  return h(
    'div',
    {
      class: 'grid grid-cols--1 pa-3'
    },
    [
      h('div', { class: 'headline fjcc' }, this.getTranslate('templates.dialogs.create')),

      h(
        'g-text-field',
        {
          props: {
            value: this.proxy.title,
            label: this.getTranslate('templates.labels.title'),
            error: this.errors.title,
            mode: 'outline',
            rounded: true,
            details: false,
            autofocus: true
          },
          on: {
            input: event => {
              this.proxy.title = event
            },
            keypress: event => {
              if (event.keyCode === 13) {
                this.$emit('submit', this.proxy)
              }
            }
          }
        }
      ),

      this.$slots.default
    ]
  )
}
