import { DOCUMENT_TEMPLATES_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import DocumentTemplatesGenerateForm from '@/components/services/documentTemplates/generate/form'

export default function(h) {
  return h(
    'g-dialog',
    {
      props: {
        rounded: true,
        value: this.showForm,
        maxWidth: 500
      },
      on: {
        input: event => {
          this.$emit('show', event)
        }
      }
    },
    [
      h(
        DocumentTemplatesGenerateForm,
        {
          class: 'pa-3',
          props: {
            value: this.proxy,
            allowedTypes: [ DOCUMENT_TEMPLATES_TYPES.invoice ],
            OwnerId: this.OwnerId,
            ResellerId: this.ResellerId
          },
          on: {
            input: event => {
              this.proxy = event
            }
          }
        }
      ),

      h(
        'g-card-actions',
        {
          class: 'fjcfe facfe',
          slot: 'footer'
        },
        [
          h(
            'g-button',
            {
              props: {
                label: this.getTranslate('misc.buttons.close'),
                flat: true,
                rounded: true
              },
              on: {
                click: () => {
                  this.$emit('show', false)
                }
              }
            }
          ),

          h(
            'g-button',
            {
              props: {
                label: this.getTranslate('misc.buttons.save'),
                depressed: true,
                rounded: true,
                color: 'primary',
                disabled: !this.isValid
              },
              on: {
                click: () => {
                  this.$emit('invoice', this.proxy)
                  this.$emit('show', false)
                }
              }
            }
          )
        ]
      )
    ]
  )
}
