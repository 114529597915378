export default {
  title: 'Tasks',

  schedule: {
    now: 'Start now',
    delay: 'Schedule for ',
    delayed: 'Schedule for '
  },

  labels: {
    title: 'Title',
    cycle: {
      batch: 'Numbers of messages',
      every: 'Every (seconds)'
    },
    owner: 'Owner',
    type: 'Type',
    state: 'State',
    data: {
      source: 'Source',
      amount: 'Amount',
      frequency: 'Frequency',
      endsAt: 'Ends at'
    },
    startsAt: 'Starts at',
    analytics: 'Analytics',
    contactLists: 'Contact lists'
  },

  headers: {
    title: 'Title',
    progress: 'Progress',
    type: 'Type',
    state: 'State',
    sent: 'Sent',
    statistic: 'Statistic',
    errored: 'Errored',
    text: 'Text',
    owner: 'Owner',
    actions: 'Actions'
  },

  dialogs: {
    create: 'Create new task'
  },

  states: {
    scheduled: 'Scheduled',
    running: 'Running',
    paused: 'Paused',
    completed: 'Completed',
    failed: 'Failed'
  },

  simpleStates: {
    scheduled: 'Active',
    running: 'Active',
    paused: 'Paused',
    completed: 'Done',
    failed: 'Failed'
  },

  actions: {
    resume: 'Resume',
    start: 'Start',
    schedule: 'Schedule',
    paused: 'Pause'
  },

  misc: {
    receivers: 'Total recipients',
    sent: 'Sent',
    errored: 'Errored',
    rest: 'Remaining',
    include: {
      lists: 'Lists include',
      recipients: 'Recipients include'
    },
    exclude: {
      lists: 'Lists exclude',
      recipients: 'Recipients exclude'
    }
  },

  titles: {
    analytics: 'Tasks analytics',
    filter: 'Tasks filter'
  },

  types: {
    bulk: 'Dispatch',
    'sendings:bulk': 'Sendings bulk',
    'payments:recurrent': 'Recurrent Payment',
    'billings:operations': 'Financial Operation'
  },

  subheaders: {
    include: {
      lists: 'Lists include',
      recipients: 'Recipients include'
    },
    exclude: {
      lists: 'Lists exclude',
      recipients: 'Recipients exclude'
    },
    errors: 'Errors'
  },

  contents: {
    confirm: {
      remove: 'Are you sure you want to delete the task {title}?'
    }
  },

  buttons: {
    today: 'Todays',
    twoWeeks: 'Scheduled',
    analytics: 'Analytics'
  },

  confirm: {
    remove: {
      title: 'Confirm remove task',
      text: 'You`re about to delete a task {title}.'
    }
  },

  frequencies: {
    once: 'Once',
    daily: 'Daily',
    weekly: 'Weekly',
    monthly: 'Monthly'
  },

  snackbars: {
    updated: 'Task success updated',
    removed: 'Task success removed',
    created: 'Task success created'
  }
}
