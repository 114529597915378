import proxy from '@sigma-legacy-libs/g-proxy'

import { mapGetters } from 'vuex'

import render from './render'

export default {
  name: 'Form',

  mixins: [ proxy({ type: 'object' }) ],

  data() {
    return {
      showFileManager: false
    }
  },

  computed: {
    ...mapGetters({ uploadedFile: 'file/uploadedFile' }),

    validation() {
      return this.proxy.title && this.proxy.FileId
    }
  },

  watch: {
    uploadedFile: {
      handler({ data }) {
        this.showFileManager = false
        this.proxy.FileId = data.id
      },
      deep: true
    },

    validation() {
      this.$emit('validation', this.validation)
    }
  },

  render
}
