import { classMerger } from '@/utils'

export default function(h, ctx) {
  return h(
    'table',
    {
      attrs: ctx.data.attrs,
      class: classMerger('fixed-table', ctx.data.class),
      style: ctx.data.style,
      on: ctx.data.on,
      directives: ctx.data.directives
    },
    [ h('tr', {}, [ h('td', {}, ctx.children || ctx.props.value) ]) ]
  )
}
