export const serviceName = 'tariffRatesUpdating'

export const decodeKeys = data => {
  const result = {}
  for (const mcc_mnc of Object.keys(data)) {
    const [ mcc, mnc ] = mcc_mnc.split('_')
    if (mnc === 'all') {
      result[mcc] = mnc
    } else {
      if (!result[mcc]) {
        result[mcc] = []
      }
      result[mcc].push(mnc)
    }
  }

  return result
}

export const encodeKeys = (data, proxy) => {
  const result = {}
  for (const mcc of Object.keys(data)) {
    if (data[mcc] === 'all') {
      const key = [ mcc, 'all' ].join('_')
      result[key] = proxy[key] || undefined
    } else {
      for (const mnc of data[mcc]) {
        const key = [ mcc, mnc ].join('_')
        result[key] = proxy[key] || undefined
      }
    }
  }

  return result
}

export default {
  serviceName,

  decodeKeys,
  encodeKeys
}
