import RefreshButton from '@/components/button/refresh'
import sendingsOutgoingDetailsItem from '@/components/services/sendings/outgoing/details/item'
import detailsDialog from '@/components/services/sendings/outgoing/details/dialog'
import pagination from '@/components/misc/pagination'

import { serviceName } from '@/components/services/sendings/utils'

function renderReleaseBucketButton(h) {
  if (this.restData[serviceName].find.bucketLength > 0) {
    return h(
      'g-button',
      {
        style: { 'min-width': 'auto' },
        class: 'my-0',
        props: {
          label: this.restData[serviceName].find.bucketLength,
          icon: 'add',
          rounded: true,
          depressed: true,
          disabled: this.restData[serviceName].find.state === 'loading',
          color: 'primary'
        },
        on: {
          click: () => {
            this.rest[serviceName].bucketRelease()
          }
        }
      }
    )
  }
}

function renderSourceButton(h, item) {
  return h(
    'g-button',
    {
      props: {
        flat: true,
        icon: 'code',
        color: 'info'
      },
      on: {
        click: () => {
          this.source = item
          this.showDialog = true
        }
      }
    }
  )
}

function renderContentByBreakpoint(h) {
  if (this.viewport.breakpoint.mdUp) {
    return h(
      'g-table',
      {
        props: {
          headers: this.headers,
          hideHead: this.restData[serviceName].find.pagination.total < 1,
          items: this.restData[serviceName].find.data,
          value: this.restData[serviceName].find.order,
          noDataText: this.getTranslate(`${serviceName}.hints.empty`)
        },
        on: {
          input: event => {
            this.restData[serviceName].find.order = event
          }
        },
        scopedSlots: {
          items: ({ item }) => {
            return h(
              sendingsOutgoingDetailsItem,
              {
                props: {
                  value: item,
                  type: 'row'
                },
                scopedSlots: {
                  sourceButton: () => renderSourceButton.call(this, h, item)
                }
              }
            )
          }
        }
      }
    )
  } else {
    return h(
      'g-expansion-panels',
      {
        props: { flat: true }
      },
      this.restData[serviceName].find.data.map(item => {
        return h(
          sendingsOutgoingDetailsItem,
          {
            props: {
              value: item,
              type: 'panel'
            },
            scopedSlots: {
              sourceButton: () => renderSourceButton.call(this, h, item)
            }
          }
        )
      })
    )
  }
}

function renderDivider(h, trigger = false) {
  const showDivider = this.restData[serviceName].find.pagination.total && Array.isArray(this.restData[serviceName].find.data) && this.restData[serviceName].find.data.length
  if (trigger || showDivider) {
    return h('g-divider')
  }
}

function renderPagination(h) {
  return h(
    'div',
    {
      class: 'fjcfe faic pa-2'
    },
    [
      h(
        pagination, {
          props: {
            value: this.restData[serviceName].find.pagination
          },
          on: {
            input: event => {
              this.restData[serviceName].find.pagination = event
            }
          }
        }
      )
    ]
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: 'fc'
    },
    [
      h(
        'div',
        {
          class: {
            'faic pa-2': true,
            fw: this.viewport.breakpoint.smDown
          }
        },
        [
          this.$slots.title,

          h('div', { class: 'ff' }),

          renderReleaseBucketButton.call(this, h),

          h(
            RefreshButton,
            {
              class: 'ma-0',
              props: {
                disabled: this.downloadable,
                label: this.getTranslate(`${serviceName}.buttons.refresh`),
                tooltip: this.getTranslate(`${serviceName}.tooltips.refresh`),
                state: this.restData[serviceName].find.state,
                shortClickMethod: () => this.rest[serviceName].find(),
                longClickMethod: () => this.rest[serviceName].find({}, { noCache: true })
              }
            }
          )
        ]
      ),

      this.$slots.filter,

      renderPagination.call(this, h),
      renderDivider.call(this, h),
      renderContentByBreakpoint.call(this, h),
      renderDivider.call(this, h),
      renderPagination.call(this, h),

      h(
        detailsDialog,
        {
          props: {
            value: this.source,
            show: this.showDialog
          },
          on: {
            close: () => {
              this.showDialog = false
            }
          }
        }
      )
    ]
  )
}
