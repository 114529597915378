import RefreshButton from '@/components/button/refresh'
import TemplatesItem from '@/components/services/templates/item'
import TemplatesFilter from '@/components/services/templates/filter'
import pagination from '@/components/misc/pagination'

import { serviceName } from '@/components/services/templates/utils'

import { STATES } from '@/utils'

function renderFilter(h) {
  return h(
    TemplatesFilter,
    {
      props: {
        value: this.filter
      },
      on: {
        input: event => {
          this.filter = event
        }
      }
    }
  )
}

function renderSeparator(h) {
  if (this.restData[serviceName].find.pagination.total > 0) {
    return h('g-divider')
  }
}

function renderPagination(h) {
  if (this.restData[serviceName].find.pagination.total > 0) {
    return h(
      'div',
      {
        class: 'faic fjcfe facfe'
      },
      [
        h(
          pagination, {
            props: {
              value: this.restData[serviceName].find.pagination,
              rowsPerPage: [ 10, 50, 100 ]
            },
            on: {
              input: event => {
                this.restData[serviceName].find.pagination = event
              }
            }
          }
        ),

        h(
          RefreshButton,
          {
            props: {
              state: this.restData[serviceName].find.state,
              shortClickMethod: () => this.rest[serviceName].find(),
              longClickMethod: () => this.rest[serviceName].find({}, { noCache: true })
            }
          }
        )
      ]
    )
  }
}

function renderContent(h) {
  if (this.restData[serviceName].find.state === STATES.ready) {
    if (this.restData[serviceName].find.pagination.total > 0) {
      return h(
        'g-expansion-panels',
        {
          props: { flat: true }
        },
        this.restData[serviceName].find.data.map(template => {
          return h(
            TemplatesItem,
            {
              props: {
                value: template,
                template: this.currentChosenTemplate
              },
              on: {
                template: event => {
                  this.currentChosenTemplate = event
                  this.$emit('template', event)
                }
              },
              key: template.id
            }
          )
        })
      )
    } else {
      return h('g-empty')
    }
  } else {
    return h(
      'div',
      {
        class: 'fjcc facc pa-3'
      },
      [ h('g-progress', { props: { indeterminate: true } }) ]
    )
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: 'fc ff'
    },
    [
      renderFilter.call(this, h),

      h('g-divider'),

      renderPagination.call(this, h),
      renderSeparator.call(this, h),

      renderContent.call(this, h),

      renderSeparator.call(this, h),
      renderPagination.call(this, h)
    ]
  )
}
