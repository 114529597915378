import { currentTab } from '@/utils'

import render from './render'

export default {
  name: 'ProvidersThirdCol',

  mixins: [ currentTab('rates') ],

  render
}
