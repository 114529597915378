import FileType from '@/components/file/type'

export default function(h) {
  if (this.uploadState === 'loading') {
    return h(
      'g-card',
      {
        class: 'upload-modal fjcc facc',
        props: { width: 300 }
      },
      [
        h(
          'div',
          {
            class: 'faic pa-2'
          },
          [
            h(
              FileType,
              {
                props: {
                  value: this.file,
                  readonly: true,
                  showPlayer: false
                }
              }
            ),
            h(
              'div',
              {
                class: 'overflow-hidden ml-3'
              },
              [
                h(
                  'div',
                  {
                    class: 'caption text-overflow'
                  },
                  this.file ? this.file.name : ''
                ),
                h(
                  'div',
                  {
                    class: 'tiny text--grey'
                  },
                  this.getTranslate('files.states.loading')
                )
              ]
            )
          ]
        ),
        h(
          'g-progress',
          {
            props: {
              value: this.progress.loaded / this.progress.total * 100,
              type: 'linear'
            }
          }
        )
      ]
    )
  }
}
