import { serviceName } from '@/components/services/requisites/utils'

const _ = { get: require('lodash/get') }

function renderRow(h, path) {
  const [ string ] = path.split('.')
  if (string === 'subheaders') {
    return h(
      'tr',
      {
        class: `${serviceName}-preview__row ${serviceName}-preview__row--subheader`
      },
      [
        h(
          'td',
          {
            attrs: { colspan: 2 }
          },
          this.getTranslate(`${serviceName}.${path}`)
        )
      ]
    )
  }

  const value = _.get(this.value, path)
  if (value) {
    return h(
      'tr',
      {},
      [
        h(
          'td',
          {},
          this.getTranslate(`${serviceName}.labels.${path}`)
        ),
        h(
          'td',
          {},
          value
        )
      ]
    )
  }
}

function renderTable(h) {
  const paths = [
    'title',
    'type',
    'subheaders.contact',
    'contact.firstName',
    'contact.middleName',
    'contact.lastName',
    'contact.email',
    'contact.phone',
    'subheaders.signer',
    'signer.position.nominative',
    'signer.position.genitive',
    'signer.basis.nominative',
    'signer.basis.genitive',
    'signer.fullName.nominative',
    'signer.fullName.genitive',
    'subheaders.legal',
    'data.legal.businessType',
    'data.legal.name',
    'data.legal.opf',
    'data.legal.ogrn',
    'data.legal.inn',
    'data.legal.kpp',
    'data.legal.okpo',
    'subheaders.bank',
    'data.bank.name',
    'data.bank.rs',
    'data.bank.ks',
    'data.bank.bik',
    'data.tax.system',
    'data.tax.vat',
    'subheaders.legalAddress',
    'data.legalAddress.country',
    'data.legalAddress.city',
    'data.legalAddress.index',
    'data.legalAddress.address',
    'data.legalAddress.phone',
    'data.legalAddress.phoneAdd',
    'subheaders.postalAddress',
    'data.postalAddress.country',
    'data.postalAddress.city',
    'data.postalAddress.index',
    'data.postalAddress.address',
    'data.postalAddress.phone',
    'data.postalAddress.phoneAdd'
  ]

  return h(
    'table',
    {
      class: `${serviceName}-preview`
    },
    paths.map(path => renderRow.call(this, h, path))
  )
}

function renderDialog(h) {
  return h(
    'g-dialog',
    {
      props: {
        value: this.showDialog,
        rounded: true,
        maxWidth: 600
      },
      on: {
        input: event => {
          this.showDialog = event
        }
      }
    },
    [ renderTable.call(this, h) ]
  )
}

export default function(h) {
  if (this.checkPermissions(`advanced.${serviceName}.get`)) {
    return h(
      'g-button',
      {
        class: 'hover-child ma-0',
        props: {
          flat: true,
          icon: 'preview',
          color: 'primary'
        },
        on: {
          click: () => {
            this.showDialog = true
          }
        },
        scopedSlots: {
          dialog: () => renderDialog.call(this, h)
        }
      }
    )
  }
}
