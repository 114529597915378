import { allowedSendingTypes } from '@/utils'

import RowWithMessageTypeIcon from '@/components/misc/RowWithMessageTypeIcon'

export default function(h, { path, serviceName }) {
  return h(
    'g-select',
    {
      class: 'mt-3',
      props: {
        value: this.proxy,
        label: this.getTranslate(`${serviceName}.labels.${path}`),
        items: allowedSendingTypes.map(type => ({
          title: this.getTranslate(`sendings.types.${type}`),
          value: type
        })),
        mode: 'outline-label',
        dense: true,
        details: false,
        rounded: true
      },
      scopedSlots: {
        selection: ({ item }) => {
          return h(
            RowWithMessageTypeIcon,
            {
              props: {
                value: item.value,
                title: item.title
              }
            }
          )
        },
        item: ({ item }) => {
          return h(
            RowWithMessageTypeIcon,
            {
              props: {
                value: item.value,
                title: item.title
              }
            }
          )
        }
      },
      on: {
        input: event => {
          this.proxy = event
        }
      }
    }
  )
}
