import messageTypeIcon from '@/components/message/type/icon'

import { moderationColors, moderationIcons, sendingTypeColors } from '@/utils'

function renderValue(h) {
  if (this.tag) {
    if (this.hasValue) {
      switch (this.tag) {
        case 'sender': {
          return h(
            'div',
            {
              class: 'faic ff'
            },
            [
              h(
                'div',
                {
                  class: 'condition__title faic'
                },
                [
                  this.value.map((sender, index) => {
                    if (index < 3) {
                      return h(
                        'g-chip',
                        {
                          props: {
                            label: sender,
                            small: true,
                            color: 'primary',
                            cut: true,
                            length: 50
                          }
                        }
                      )
                    }
                  }),

                  this.value.length > 3 ? h(
                    'g-chip',
                    {
                      props: {
                        small: true,
                        round: true,
                        label: `+${this.value.length - 3}`,
                        color: 'primary'
                      }
                    }
                  ) : undefined
                ]
              )
            ]
          )
        }

        case 'type': {
          return h(
            'div',
            {
              class: 'faic ff'
            },
            [
              h(
                'div',
                {
                  class: 'condition__title'
                },
                [
                  h(
                    'g-chip',
                    {
                      props: {
                        small: true,
                        color: sendingTypeColors[this.value] || 'primary',
                        cut: true,
                        length: 50
                      }
                    },
                    [
                      h(
                        'div',
                        {
                          class: 'grid grid-gap--4 faic',
                          style: { 'grid-template-columns': '16px 1fr' }
                        },
                        [
                          h(
                            messageTypeIcon,
                            {
                              props: {
                                value: this.value,
                                color: 'white',
                                size: 16
                              }
                            }
                          ),
                          this.getTranslate(`sendings.types.${this.value}`)
                        ]
                      )
                    ]
                  )
                ]
              )
            ]
          )
        }

        case 'moderation': {
          return h(
            'div',
            {
              class: 'faic ff'
            },
            [
              h(
                'div',
                {
                  class: 'condition__title'
                },
                [
                  h(
                    'g-chip',
                    {
                      props: {
                        label: this.getTranslate(`misc.moderation.${this.value}`),
                        small: true,
                        icon: moderationIcons[this.value],
                        color: moderationColors[this.value] || 'primary',
                        cut: true,
                        length: 50
                      }
                    }
                  )
                ]
              )
            ]
          )
        }

        case 'text':
        case 'fallbacks': {
          return h(
            'div',
            {
              class: 'condition__title'
            },
            [
              h(
                'g-chip',
                {
                  props: {
                    label: `${this.getTranslate(`conditions.labels.${this.tag}`)} | ${this.value.length}`,
                    small: true,
                    color: 'primary',
                    cut: true
                  }
                }
              )
            ]
          )
        }
      }
    } else {
      return h(
        'div',
        {
          class: 'fc ff'
        },
        [
          h(
            'div',
            {
              class: 'fz-13 lh-15 text--grey pl-2'
            },
            this.getTranslate(`conditions.tags.${this.tag}`)
          ),

          h(
            'div',
            {
              class: 'condition__description'
            },
            this.getTranslate('conditions.hints.noRule')
          )
        ]
      )
    }
  }
}

function renderClearButton(h) {
  if (!this.readonly) {
    return h(
      'g-button',
      {
        class: {
          'ma-0': true,
          'hover-child': this.viewport.breakpoint.mdUp
        },
        props: {
          flat: true,
          icon: 'clear',
          color: 'grey',
          disabled: !this.hasValue
        },
        on: {
          click: () => {
            this.$emit('clear', true)
          }
        }
      }
    )
  }
}

function renderRow(h) {
  if (this.tag) {
    return h(
      'div',
      {
        class: {
          [`condition__row condition__row--${this.tag}`]: true,
          ['condition__row--empty']: !this.hasValue
        },
        on: {
          click: () => {
            this.$emit('dialog', true)
          }
        }
      },
      [ renderValue.call(this, h) ]
    )
  }
}

function renderCondition(h) {
  if (this.tag) {
    return h(
      'div',
      {
        class: 'condition__holder',
        directives: [
          {
            name: 'g-tooltip',
            options: {
              value: this.getTranslate(`conditions.tags.${this.tag}`),
              placement: 'left'
            }
          }
        ]
      },
      [ renderRow.call(this, h) ]
    )
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: {
        'condition hover-parent hover-parent--opacity': true,
        'condition--readonly': this.readonly
      }
    },
    [
      renderCondition.call(this, h),
      renderClearButton.call(this, h)
    ]
  )
}
