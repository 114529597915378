export default {
  title: 'Sendings create',

  titles: {
    message: 'Message',
    preview: 'Message preview'
  },

  voice: {
    default: 'Select a voice',
    generate: 'Generate audio',
    voice: 'Voice:',
    names: {
      'aws:maxim': 'Maxim',
      'aws:tatyana': 'Tatyana',
      'yandex:oksana': 'Oksana',
      'yandex:jane': 'Jane',
      'yandex:omazh': 'Omazh',
      'yandex:zahar': 'Zahar',
      'yandex:ermil': 'Ermil',
      'yandex:alena': 'Alena',
      'yandex:filipp': 'Filipp',
      'yandex:silaerkan': 'Silaerkan',
      'yandex:erkanyavas': 'Erkanyavas',
      'yandex:alyss': 'Alyss',
      'yandex:nick': 'Nick'
    },
    company: {
      aws: 'Amazon',
      yandex: 'Yandex'
    },
    upload: 'Upload file',
    misc: {
      generatedFile: 'The generated audio file will be sent',
      uploadedFile: 'The downloaded file will be sent',
      can: 'You can also',
      ownFile: 'upload your file',
      textFile: 'generate audio file by text'
    },
    timer: 'Wait for {timerCount} sec for the next request'
  },

  attachMenu: {
    attach: 'Attach',
    choose: 'Choose file',
    items: {
      file: 'File',
      image: 'Image'
    }
  },

  hints: {
    viberTemplates: 'Possible variations: Text, Text + Button, Text + Button + Picture, Picture'
  },

  createNewUser: 'Create new sender',
  insertVariables: 'Insert variables'
}
