import Vue from 'vue'

import { unVue } from '@sigma-legacy-libs/unvue'

import { serviceName } from '@/components/services/apisender/utils'
import { globalErrorHandler, globalErrorProcessor } from '@/utils'

import render from './render'

const _ = { get: require('lodash/get') }

export default {
  name: 'RenewInstance',

  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      showDialog: false,
      loading: false
    }
  },

  computed: {
    priceByTariff() {
      const tariffs = unVue(this.account.Tariffs)
      tariffs.sort((a, b) => a.priority - b.priority)
      const tariff = tariffs.find(({ source }) => source === `subscription.${serviceName}`)

      // FIXME: переписать под разные source новых тарифов
      return _.get(tariff, 'usersTariffs.finalRules[0].price[0]', 0)
    },

    disabled() {
      return this.loading || this.value.isPaid || !this.value.isActive
    }
  },

  methods: {
    async renewInstance() {
      try {
        this.loading = true
        await Vue.$GRequest.create(`${serviceName}/renewInstance/${this.value.id}`)
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      } finally {
        this.showDialog = false
        this.loading = false
      }
    }
  },

  render
}
