import { unVue } from '@sigma-legacy-libs/unvue'

import { routerQueryReplace } from '@/utils'

import ExpandArrow from '@/components/misc/ExpandArrow'
import titleDivider from '@/components/title/divider'
import componentNotFound from '@/components/misc/componentNotFound'

const _ = {
  isPlainObject: require('lodash/isPlainObject'),
  isEqual: require('lodash/isEqual')
}

function renderPreloader(h) {
  if (this.loading.find) {
    return h(
      'div',
      {
        class: 'fjcc facc faic ff pa-5'
      },
      [ h('g-progress', { props: { indeterminate: true } }) ]
    )
  }
}

function renderIcon(h, icon) {
  return h(
    'div',
    {
      class: 'fjcc facc faic square--48'
    },
    [ h('g-icon', { props: { value: icon || 'settings' } }) ]
  )
}

function renderMenu(h, options) {
  if (options.renderSchema && this.viewport.breakpoint.mdUp) {
    return h(
      'g-card',
      {
        props: {
          rounded: true,
          outline: true
        }
      },
      [
        h(
          'g-list',
          {
            props: {
              dense: true,
              rounded: true
            }
          },
          options.renderSchema.map(({ key, icon }) => {
            return h(
              'g-list-item',
              {
                class: 'pl-0',
                props: { active: this.currentKey === key },
                on: {
                  click: () => {
                    const { query } = this.$route
                    if (query && (!query.settingKey || query.settingKey !== key)) {
                      routerQueryReplace({ settingKey: key })
                    }
                  }
                }
              },
              [
                renderIcon.call(this, h, icon),

                this.getTranslate(`${options.serviceName}.titles.${key}`)
              ]
            )
          })
        )
      ]
    )
  }
}

function renderSubtitle(h, subtitle, options) {
  if (subtitle) {
    return h(
      titleDivider,
      {
        props: { value: this.getTranslate(`${options.serviceName}.subtitles.${subtitle}`) }
      }
    )
  }
}

function renderChildrenByPath(h, path, options) {
  if (path) {
    try {
      const component = require(`@/components/services/${options.serviceName}/${path.split('.').join('/')}`).default({
        path,
        ...options
      })

      return h(
        component,
        {
          // Здесь нужен unVue чтобы настройке не изменялись внутри компонентов
          // Так как настройки передаются в реактивном виде, они могут это делать
          // Изменение настроек происходит только при успешном ответе от сервера в методе updateSetting
          props: {
            value: unVue(this.settings[path]),
            options
          },
          on: {
            input: event => {
              if (!_.isEqual(this.settings[path], event)) {
                this.updateSetting(path, event)
              }
            }
          }
        }
      )
    } catch (error) {
      return h(componentNotFound)
    }
  }
}

function renderChild(h, child, options) {
  switch (true) {
    case typeof child === 'string':
    default: {
      return renderChildrenByPath.call(this, h, child, options)
    }

    case _.isPlainObject(child): {
      if (child.subtitle) {
        return renderSubtitle.call(this, h, child.subtitle, options)
      } else if (child.path) {
        return renderChildrenByPath.call(this, h, child.path, options)
      } else if (child.divider) {
        return h('g-divider', { class: 'my-2' })
      }
      break
    }

    case Array.isArray(child): {
      return h(
        'div',
        {
          class: 'grid'
        },
        child.map(path => renderChildrenByPath.call(this, h, path, options))
      )
    }
  }
}

function renderChildrenByViewport(h, key, icon, children, options) {
  if (this.viewport.breakpoint.mdUp) {
    if (this.currentKey === key) {
      return h(
        'div',
        {
          class: 'grid pa-2'
        },
        [
          h('div', { class: 'headline' }, this.getTranslate(`${options.serviceName}.titles.${key}`)),

          h(
            'div',
            {
              class: 'grid'
            },
            children.map(child => renderChild.call(this, h, child, options))
          )
        ]
      )
    }
  } else {
    return h(
      'g-expansion-panel',
      {
        scopedSlots: {
          header: ({ expanded }) => {
            return h(
              'div',
              {
                class: 'default-expansion-panel-header px-0'
              },
              [
                renderIcon.call(this, h, icon),

                this.getTranslate(`${options.serviceName}.titles.${key}`),

                h('div', { class: 'ff' }),

                h(ExpandArrow, { props: { expanded } })
              ]
            )
          }
        }
      },
      [
        h(
          'div',
          {
            class: 'grid pa-3'
          },
          children.map(child => renderChild.call(this, h, child, options))
        )
      ]
    )
  }
}

function renderContent(h, options) {
  if (this.settings !== undefined) {
    const content = options.renderSchema.map(({ key, icon, children }) => {
      return renderChildrenByViewport.call(this, h, key, icon, children, options)
    })

    const props = { flat: true }
    if (options.panels && options.panels.props) {
      Object.assign(props, options.panels.props)
    }

    if (this.viewport.breakpoint.mdUp) {
      return h('g-card', { props }, content)
    } else {
      return h('g-expansion-panels', { props }, content)
    }
  }
}

export default function(h, options) {
  if (this.loading.find) {
    return renderPreloader.call(this, h)
  } else {
    return h(
      'div',
      {
        class: 'grid grid-gap--8 faifs',
        style: { gridTemplateColumns: '260px 1fr' }
      },
      [
        renderMenu.call(this, h, options),
        renderContent.call(this, h, options)
      ]
    )
  }
}
