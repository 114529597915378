import HeadTitle from '@/components/misc/HeadTitle'

const textFieldProps = {
  mode: 'outline-label',
  dense: true,
  rounded: true,
  clearable: true,
  details: false
}

function renderForm(h, { path, serviceName }) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8 pa-3'
    },
    [
      h(
        'div',
        {
          class: {
            'grid grid-gap--8': true,
            'grid-cols--2': this.viewport.breakpoint.mdUp,
            'grid-cols--1': this.viewport.breakpoint.smDown
          }
        },
        [
          h(
            'g-text-field',
            {
              props: {
                value: this.proxy.host,
                label: this.getTranslate(`${serviceName}.labels.${path}.host`),
                ...textFieldProps
              },
              on: {
                input: event => {
                  this.proxy.host = event
                }
              }
            }
          ),

          h(
            'g-text-field',
            {
              props: {
                value: this.proxy.port,
                label: this.getTranslate(`${serviceName}.labels.${path}.port`),
                type: 'number',
                step: 'any',
                ...textFieldProps
              },
              on: {
                input: event => {
                  this.proxy.port = event
                }
              }
            }
          )
        ]
      ),

      h(
        'g-text-field',
        {
          props: {
            value: this.proxy.from,
            label: this.getTranslate(`${serviceName}.labels.${path}.from`),
            ...textFieldProps
          },
          on: {
            input: event => {
              this.proxy.from = event
            }
          }
        }
      ),

      h(
        'div',
        {
          class: {
            'grid grid-gap--8': true,
            'grid-cols--2': this.viewport.breakpoint.mdUp,
            'grid-cols--1': this.viewport.breakpoint.smDown
          }
        },
        [
          h(
            'g-text-field',
            {
              props: {
                value: this.proxy.login,
                label: this.getTranslate(`${serviceName}.labels.${path}.login`),
                ...textFieldProps
              },
              on: {
                input: event => {
                  this.proxy.login = event
                }
              }
            }
          ),
          h(
            'g-text-field',
            {
              props: {
                value: this.proxy.password,
                label: this.getTranslate(`${serviceName}.labels.${path}.password`),
                name: 'smtp-password',
                afterIcon: this.passwordVisibility ? 'visibility' : 'visibility_off',
                afterIconCallback: () => {
                  this.passwordVisibility = !this.passwordVisibility
                },
                type: this.passwordVisibility ? 'password' : 'text',
                ...textFieldProps
              },
              on: {
                input: event => {
                  this.proxy.password = event
                }
              }
            }
          )
        ]
      )
    ]
  )
}

function renderDialog(h, { path, serviceName }) {
  return h(
    'g-dialog',
    {
      props: {
        value: this.showDialog,
        rounded: true,
        maxWidth: 400
      },
      on: {
        input: event => {
          this.showDialog = event
        }
      }
    },
    [
      h(
        HeadTitle,
        {
          props: {
            value: this.getTranslate(`${serviceName}.dialogs.headers.${path}`)
          }
        }
      ),

      renderForm.call(this, h, {
        path,
        serviceName
      }),

      h(
        'g-card-actions',
        {
          class: 'fjcfe'
        },
        [
          h(
            'g-button',
            {
              props: {
                flat: true,
                rounded: true,
                label: this.getTranslate('misc.buttons.cancel')
              },
              on: {
                click: () => {
                  this.showDialog = false
                }
              }
            }
          ),

          h(
            'g-button',
            {
              props: {
                flat: true,
                rounded: true,
                icon: 'save',
                color: 'primary',
                label: this.getTranslate('misc.buttons.save')
              },
              on: {
                click: () => {
                  this.$emit('input', this.proxy)
                  this.showDialog = false
                }
              }
            }
          )
        ]
      )
    ]
  )
}

export default function(h, { path, serviceName }) {
  return h(
    'div',
    {
      class: 'fjcc faic'
    },
    [
      h(
        'g-button',
        {
          class: 'ma-0',
          props: {
            flat: true,
            rounded: true,
            icon: 'email',
            color: 'primary',
            label: this.getTranslate(`${serviceName}.buttons.${path}`)
          },
          on: {
            click: () => {
              this.showDialog = true
            }
          },
          scopedSlots: {
            dialog: () => renderDialog.call(this, h, {
              path,
              serviceName
            })
          }
        }
      )
    ]
  )
}
