import Columns from '@/components/misc/Columns'

import { routerQueryReplace } from '@/utils'

function renderContent(h) {
  if (this.viewport.breakpoint.mdUp) {
    return h(
      Columns,
      {},
      this.tabs.map(tab => {
        return h(
          'div',
          {
            class: 'fc ff'
          },
          [
            h(
              'div',
              {
                class: 'faic headline pa-3'
              },
              [ this.getTranslate(`payments.tabs.${tab}`) ]
            ),

            h(
              'g-card',
              {
                class: 'ff',
                props: { rounded: true }
              },
              [ h(require(`@/components/services/${tab}/list`).default({ isPage: true })) ]
            )
          ]
        )
      })
    )
  } else {
    return h(
      'div',
      {
        class: 'fc ff'
      },
      [
        h(
          'g-tabs-header',
          {
            class: 'pl-3',
            props: {
              value: this.currentTab,
              tabs: this.tabs.map(key => {
                return {
                  key,
                  title: this.getTranslate(`payments.tabs.${key}`)
                }
              })
            },
            on: {
              input: event => {
                routerQueryReplace({ currentTab: event })
              }
            }
          }
        ),

        h(
          'g-card',
          {
            props: { rounded: true }
          },
          [
            h(
              'g-tabs',
              {
                props: { value: this.currentTab }
              },
              this.tabs.map(tab => {
                return h(
                  require(`@/components/services/${tab}/list`).default({ isPage: true }),
                  {
                    key: tab,
                    scopedSlots: {
                      filter: ({ filter }) => {
                        return h(
                          require(`@/components/services/${tab}/filter`).default,
                          {
                            props: { value: filter },
                            on: {
                              input: event => {
                                filter = event
                              }
                            }
                          }
                        )
                      }
                    }
                  }
                )
              })
            )
          ]
        )
      ]
    )
  }
}

export default function(h) {
  return renderContent.call(this, h)
}
