import proxy from '@sigma-legacy-libs/g-proxy'

import render from './render'

export default {
  name: 'Form',

  mixins: [ proxy({ type: 'object' }) ],

  computed: {
    validation() {
      if (!this.proxy.salonId || !this.proxy.OwnerId || !this.proxy.TokenId) {
        return false
      }

      return true
    }
  },

  watch: {
    validation() {
      this.$emit('validation', this.validation)
    },
    'proxy.OwnerId'() {
      this.proxy.TokenId = undefined
    }
  },

  mounted() {
    this.$emit('validation', this.validation)
  },

  render
}
