import SingleLink from '@/components/misc/SingleLink'
import state from '@/components/services/apisender/item/state'
import title from '@/components/services/apisender/item/title'
import phone from '@/components/services/apisender/item/phone'
import isPaid from '@/components/services/apisender/item/isPaid'
import buttons from '@/components/services/apisender/item/buttons'

import { serviceName } from '@/components/services/apisender/utils'

function renderStateCell(h) {
  return h(
    'td',
    {
      class: 'px-2'
    },
    [ h(state, { props: { value: this.value } }) ]
  )
}

function renderTitleCell(h) {
  return h(
    'td',
    {
      class: 'pa-0'
    },
    [ h(title, { props: { value: this.value } }) ]
  )
}

function renderPhoneCell(h) {
  return h(
    'td',
    {
      class: 'px-2'
    },
    [ [ h(phone, { props: { value: this.value } }) ] ]
  )
}
function renderIsPaidCell(h) {
  return h(
    'td',
    {
      class: 'pa-0'
    },
    [ [ h(isPaid, { props: { value: this.value } }) ] ]
  )
}

function renderOwnerCell(h) {
  if (this.checkPermissions(`advanced.${serviceName}.get`)) {
    return h(
      'td',
      {
        class: 'px-2'
      },
      [
        h(
          SingleLink,
          {
            props: {
              value: this.value.OwnerId
            }
          }
        )
      ]
    )
  }
}

function renderButtons(h) {
  return h(
    'td',
    {
      class: 'pa-2 w--100'
    },
    [
      h(
        'div',
        {
          class: 'fjcfe'
        },
        [ h(buttons, { props: { value: this.value } }) ]
      )
    ]
  )
}

export default function(h) {
  return h(
    'tr',
    {
      class: 'hover-parent hover-parent--opacity'
    },
    [
      renderStateCell.call(this, h),
      renderTitleCell.call(this, h),
      renderPhoneCell.call(this, h),
      renderIsPaidCell.call(this, h),
      renderOwnerCell.call(this, h),
      renderButtons.call(this, h)
    ]
  )
}
