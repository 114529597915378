const baseFields = {
  requisite: 'Реквизит',
  title: 'Название',
  type: 'Тип',
  sum: 'Сумма',
  contact: {
    firstName: 'Имя',
    middleName: 'Отчество',
    lastName: 'Фамилия',
    email: 'E-mail',
    phone: 'Телефон',
    matches: 'Совпадает с данными указанными в профиле личного кабинета'
  },
  signer: {
    position: {
      nominative: 'Должность',
      genitive: 'Должность'
    },
    basis: {
      nominative: 'Основание',
      genitive: 'Основание'
    },
    fullName: {
      nominative: 'Ф.И.О. полностью',
      genitive: 'Ф.И.О. полностью'
    }
  },
  data: {
    legal: {
      businessType: 'Тип компании',
      name: 'Название компании',
      opf: 'ОПФ',
      ogrn: 'ОГРН',
      inn: 'ИНН',
      kpp: 'КПП',
      okpo: 'ОКПО'
    },
    bank: {
      name: 'Название банка',
      rs: 'Расчётный счёт',
      ks: 'Корр. счёт',
      bik: 'БИК'
    },
    tax: {
      system: 'Налоговая система',
      vat: 'Учитывать НДС'
    },
    legalAddress: {
      title: 'Юридический адрес',
      country: 'Страна',
      city: 'Город',
      index: 'Индекс',
      address: 'Адрес',
      addressMatches: 'Совпадает с юридическим адресом',
      phone: 'Телефон',
      phoneAdd: 'Добавочный номер'
    },
    postalAddress: {
      title: 'Почтовый адрес',
      country: 'Страна',
      city: 'Город',
      index: 'Индекс',
      address: 'Адрес',
      addressMatches: 'Совпадает с юридическим адресом',
      phone: 'Телефон',
      phoneAdd: 'Добавочный номер'
    },
    personal: {
      firstName: 'Имя',
      middleName: 'Отчество',
      lastName: 'Фамилия',
      document: {
        series: 'Серия документа',
        number: 'Номер документа',
        issuer: 'Кем выдан',
        issuerCode: 'Код подразделения',
        issueDate: 'Дата выдачи',
        registrationAddress: 'Адрес регистрации',
        registrationDate: 'Дата регистрации',
        citizenship: 'Гражданство'
      }
    }
  },
  moderation: 'Модерация',
  documents: {
    templates: {
      shared: 'Доступные шаблоны',
      assigned: 'Назначенный шаблон',
      purpose: 'Назначение',
      purposes: {
        bill: 'Счёт',
        contract: 'Основной договор',
        contractAnnex1: 'Приложение №1 к договору',
        addAgreementCreditPayment: 'Доп-соглашение о кредитном способе оплат',
        invoice: 'Счёт фактура',
        UTD: 'Акт УПД',
        UTDplus: 'УПД',
        Letter1: 'Письмо 1',
        Letter2: 'Письмо 2',
        Letter3: 'Письмо 3'
      }
    }
  },
  country: 'Страна',
  city: 'Город',
  index: 'Индекс',
  address: 'Адрес',
  addressMatches: 'Совпадает с юридическим адресом',
  phone: 'Телефон',
  phoneAdd: 'Добавочный номер'
}

export default {
  title: 'Реквизиты',

  titles: {
    create: 'Создать новые реквизиты',
    document: {
      templates: 'Шаблоны'
    },
    empty: {
      title: 'Не создано ни одного реквизита',
      sub: 'Создайте новые реквизиты с помощью кнопки ниже'
    },
    generate: {
      document: 'Скачать документ'
    },
    requisites: 'Реквизиты',
    notRequired: 'Не обязательная часть'
  },

  dialogs: {
    titles: {
      create: 'Создать реквизиты',
      update: 'Обновить реквизиты',
      remove: 'Удалить реквизиты'
    }
  },

  headers: {
    ...baseFields,

    businessInfo: 'ИНН и КПП',
    contactInfo: 'Контакты'
  },

  labels: {
    ...baseFields
  },

  subheaders: {
    title: 'Название',
    contact: 'Ответственный за заключение договора',
    signer: 'Подписант',
    general: 'Основное',
    legal: 'Правовая часть',
    bank: 'Банк',
    tax: 'Налоги',
    personal: 'Персональное',
    document: 'Документ',
    legalAddress: 'Юридический адрес',
    postalAddress: 'Почтовый адрес',
    documents: {
      templates: {
        shared: 'Доступные шаблоны',
        assigned: 'Назначенные шаблоны'
      }
    }
  },

  contents: {
    documents: {
      templates: {
        shared: 'Шаблоны документов, которые реселлер сможет прикреплять к своим пользователям',
        assigned: 'Шаблоны документов, которые будут использованы при генерации документов пользователя'
      }
    },
    confirm: {
      remove: 'Подтвердите удаление реквизитов.'
    }
  },

  types: {
    business: 'Юридическое лицо',
    personal: 'Физическое лицо'
  },

  errors: {
    ogrn: 'Должно быть от 13 до 15 цифр',
    inn: 'Должно быть от 10 до 12 цифр',
    kpp: 'Должно быть 9 цифр',
    okpo: 'Должно быть 8 цифр',
    rs: 'Должно быть 20 цифр',
    ks: 'Должно быть 20 цифр',
    bik: 'Должно быть 9 цифр',
    index: 'Должно быть 6 цифр',
    document: {
      number: 'Должно быть 6 цифр'
    },
    documentsNoRequisitesFound: 'Не найдены реквизиты',
    documentsNoDocumentTemplateFound: 'Шаблонов документов не найдено.'
  },

  hints: {
    title: 'Название реквизита для быстрого поиска на платформе при выставлении счета.',
    required: 'Поля отмеченные * обязательны к заполнению',
    data: {
      legal: {
        name: 'Без кавычек и правовой формы, например ваша компания ООО "Ландыши", впишите только Ландыши',
        opf: 'Организационно-правовая форма (ИП, ООО...)',
        ogrn: 'Основной государственный регистрационный номер',
        inn: 'Идентификационный номер налогоплательщика',
        kpp: 'Код причины постановки на учет',
        okpo: 'Общероссийский классификатор предприятий и организаций'
      }
    },
    nominative: 'Именительный падеж',
    genitive: 'Родительный падеж',
    sum: 'Минимальная сумма 100 рублей',
    address: 'Улица, дом, корпус, строение, офис / квартира',
    validation: {
      state: {
        true: 'Заполнено',
        false: 'Ошибки',
        undefined: 'Не заполнено'
      }
    }
  },

  vat: {
    true: 'С НДС',
    false: 'Без НДС'
  },

  buttons: {
    add: 'Добавить реквизиты',
    Edit: 'Изменить реквизиты',
    document: {
      templates: 'Шаблоны документов пользователей'
    }
  },

  snackbars: {
    created: 'Реквизиты успешно созданы.',
    updated: 'Реквизиты успешно обновлены.',
    removed: 'Реквизиты успешно удалены.'
  },

  tooltips: {
    isActive: 'Активный или неактивный реквизит. Если реквизит неактивен вы не сможете использовать его для, к примеру, выставления счетов.',
    favorites: 'Сделать реквизит избранным',
    filter: {
      isActive: 'Показать деактивированные реквизиты.'
    }
  }
}
