import mime from 'mime'

export default {
  namespaced: true,

  state: {
    newFile: undefined,

    file: undefined,

    progress: undefined,

    attributes: {
      isPublic: undefined,
      multiple: undefined,
      name: undefined,
      types: undefined,
      url: undefined,
      method: undefined,
      maxFileSize: undefined
    },

    uploadState: undefined,

    uploadedFile: undefined,

    error: undefined
  },

  mutations: {
    setNewFile(state, newFile) {
      state.newFile = newFile
    },

    setFile(state, file) {
      state.file = file
    },

    setProgress(state, progress) {
      state.progress = progress
    },

    setAttributes(state, attributes) {
      state.attributes = attributes
    },

    setUploadState(state, uploadState) {
      state.uploadState = uploadState
    },

    setUploadedFile(state, uploadedFile) {
      state.uploadedFile = uploadedFile
    },

    setError(state, error) {
      state.error = error
    }
  },

  actions: {
    setNewFile({ commit }, newFile) {
      commit('setNewFile', newFile)
    },

    setFile({ commit }, file) {
      commit('setFile', {
        name: file.name,
        lastModified: file.lastModified,
        lastModifiedDate: file.lastModifiedDate,
        webkitRelativePath: file.webkitRelativePath,
        size: file.size,
        type: file.type || mime.getType(file.name.match(/.*\.(.+)/)[1])
      })
    },

    setProgress({ commit }, progress) {
      commit('setProgress', progress)
    },

    setAttributes({ commit }, attributes) {
      commit('setAttributes', {
        isPublic: attributes.isPublic,
        multiple: attributes.multiple,
        name: attributes.name,
        types: attributes.types,
        url: attributes.url,
        method: attributes.method,
        maxFileSize: attributes.maxFileSize
      })
    },

    setUploadState({ commit }, uploadState) {
      commit('setUploadState', uploadState)
    },

    setUploadedFile({ commit }, uploadedFile) {
      commit('setUploadedFile', uploadedFile)
    },

    setError({ commit }, error) {
      commit('setError', error)
    }
  },

  getters: {
    newFile: state => state.newFile,
    file: state => state.file,
    progress: state => state.progress,
    attributes: state => state.attributes,
    uploadState: state => state.uploadState,
    uploadedFile: state => state.uploadedFile,
    error: state => state.error
  }
}
