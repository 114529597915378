import { RECURRENT_PAYMENTS_METHODS, RECURRENT_PAYMENTS_TYPES, TASK_FREQUENCY } from '@sigma-legacy-libs/essentials/lib/constants'

import HintRow from '@/components/misc/HintRow'
import PaymentsForm from '@/components/services/payments/form'

const name = 'recurrentPayments'

function renderTypes(h) {
  return h(
    'g-card-actions',
    {
      class: 'fjcc facc faic mb-2'
    },
    RECURRENT_PAYMENTS_TYPES.map(type => {
      return h(
        'g-button',
        {
          props: {
            label: this.getTranslate(`${name}.types.${type}`),
            flat: this.proxy.type !== type,
            rounded: true,
            depressed: true,
            color: this.proxy.type === type ? 'primary' : undefined
          },
          on: {
            click: () => {
              this.proxy.type = type
            }
          }
        }
      )
    })
  )
}

function renderFieldByType(h) {
  switch (this.proxy.type) {
    case RECURRENT_PAYMENTS_TYPES.threshold: {
      this.proxy.frequency = undefined

      return h(
        'g-text-field',
        {
          props: {
            value: this.proxy.threshold,
            hint: this.getTranslate(`${name}.labels.threshold`),
            error: this.localErrors.threshold,
            suffix: '&#8381;',
            type: 'number',
            step: 'any',
            mode: 'outline',
            rounded: true,
            required: true,
            autofocus: true
          },
          on: {
            input: event => {
              this.proxy.threshold = event
            }
          }
        }
      )
    }

    case RECURRENT_PAYMENTS_TYPES.frequency: {
      this.proxy.threshold = undefined

      return h(
        'g-select',
        {
          props: {
            value: this.proxy.frequency,
            hint: this.getTranslate(`${name}.labels.frequency`),
            items: TASK_FREQUENCY.reduce((frequencies, frequency) => {
              if (!~[ TASK_FREQUENCY.once ].indexOf(frequency)) {
                frequencies.push({
                  title: this.getTranslate(`tasks.frequencies.${frequency}`),
                  value: frequency
                })
              }

              return frequencies
            }, []),
            mode: 'outline',
            rounded: true,
            required: true
          },
          on: {
            input: event => {
              this.proxy.frequency = event
            }
          }
        }
      )
    }
  }
}

function renderHint(h) {
  if (this.isNew) {
    return h(
      HintRow,
      {
        class: 'mb-2',
        props: { value: this.getTranslate(`${name}.hints.redirect`) }
      }
    )
  }
}

export default function(h) {
  return h(
    'g-form',
    {
      on: {
        submit: event => {
          event.preventDefault()
          this.$emit('submit', this.proxy)
        }
      }
    },
    [
      h(
        'div',
        {
          class: 'fc ff py-3 px-5'
        },
        [
          h(
            'div',
            {
              class: 'fc ff pb-5'
            },
            [
              renderTypes.call(this, h),
              renderFieldByType.call(this, h)
            ]
          ),

          h(
            PaymentsForm,
            {
              props: {
                value: this.proxy,
                methods: RECURRENT_PAYMENTS_METHODS
              },
              on: {
                input: event => {
                  this.proxy = event
                }
              }
            }
          )
        ]
      ),

      renderHint.call(this, h)
    ]
  )
}
