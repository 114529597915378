import { unVue } from '@sigma-legacy-libs/unvue'

import { keyValue, properties } from '@/utils'

import render from './render'

const defaultPayload = {
  key: undefined,
  value: undefined
}

export const propertiesSelect = (options = {}) => {
  return {
    name: `${options.serviceName}Properties`,

    mixins: [ keyValue, properties(options) ],

    props: {
      processor: String
    },

    data() {
      return {
        editMode: false,
        showDialog: false,

        index: undefined,

        payload: unVue(defaultPayload)
      }
    },

    watch: {
      'payload.key'() {
        if (this.payload.key) {
          const { type } = this._getProperty()
          switch (type) {
            case 'boolean': {
              if (typeof this.payload.value !== 'boolean') {
                this.payload.value = false
              }
              break
            }
          }
        }
      },
      showDialog() {
        if (this.showDialog === false) {
          this.cancel()
        }
      }
    },

    methods: {
      add() {
        this.proxy.push(this.payload)
        this.cancel()
      },
      cancel() {
        this.payload = unVue(defaultPayload)
        if (this.editMode) {
          this.index = undefined
          this.editMode = false
        }
        this.showDialog = false
      },
      edit(index) {
        this.index = index
        this.payload = this.proxy[index]
        this.editMode = true
        this.showDialog = true
      },
      update() {
        this.proxy.splice(this.index, 1, this.payload)
        this.cancel()
      },

      _inputFilter(data) {
        return Object.keys(data).reduce((result, key) => {
          result.splice(result.length, 0, {
            key,
            value: data[key]
          })

          return result
        }, [])
      },
      _outputFilter(data) {
        return data.reduce((result, item) => Object.assign(result, { [item.key]: item.value }), {})
      },
      _getProperty(key) {
        key = this.payload.key || key
        if (key) {
          return this.properties.find(item => item.property === key)
        }

        return {}
      }
    },

    render(h) {
      return render.call(this, h, options)
    }
  }
}

export default { propertiesSelect }
