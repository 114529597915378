import { getLocaleTimeString } from '@/utils'

export default function(h) {
  return h(
    'div',
    {
      class: {
        'fc lh-n pl-3': true,
        faifs: this.align === 'left',
        faife: this.align === 'right'
      }
    },
    [
      h('div', { class: 'fz-14' }, getLocaleTimeString(this.date)),
      h('div', { class: 'small' }, this.timezone)
    ]
  )
}
