import render from './render'

export default {
  name: 'Title',

  props: {
    value: {
      type: Object,
      default: () => ({})
    },

    edit: Boolean
  },

  render
}
