import { unVue } from '@sigma-legacy-libs/unvue'
import { PUBLIC_FIELDS_USER } from '@sigma-legacy-libs/essentials/lib/constants'

import {
  TASKS_FILTER,
  appendFormattedDates,
  fillDependence,
  generateServices,
  globalErrorHandler,
  globalErrorProcessor
} from '@/utils'
import { serviceName } from '@/components/services/tasks/utils'

import render from './render'

const _ = { get: require('lodash/get') }

export default function(options = {}) {
  return {
    name: serviceName,

    mixins: [
      generateServices({
        name: serviceName,

        async inputFilter(result) {
          if (!result.Owner) {
            await fillDependence.call(this, result, {
              service: 'users',
              permissions: [ 'reseller', true ],
              pathToId: 'OwnerId',
              pathToObject: 'Owner',
              outputPath: 'Owner',
              picks: PUBLIC_FIELDS_USER
            })
          }

          result.OwnerId = result.Owner && result.Owner.id || result.OwnerId

          const payload = _.get(result, 'data.sending.payload')

          if (payload && (payload.file || payload.image || payload.audio)) {
            const [ id1, id2 ] = (payload.file || payload.image || payload.audio).split('/')

            result.$FileId = id2 || id1

            await fillDependence.call(this, result, {
              service: 'storage',
              permissions: [ 'me', 'reseller', true ],
              pathToId: '$FileId',
              pathToObject: 'File',
              outputPath: 'File',
              requestParams: {
                query: {
                  return: 'meta'
                }
              }
            })
          }

          appendFormattedDates.call(this, result)

          return result
        },
        outputFilter(result) {
          if (result.frequency === 'once') {
            result.data.endsAt = undefined
          }

          return result
        },

        find: {
          defaultFilter: Object.assign({}, unVue(TASKS_FILTER), options.defaultFilter),
          alwaysUpdateFromWebSocket: true
        },
        get: false,
        update: false,
        remove: false
      })
    ],

    props: {
      headers: {
        type: Array,
        default: () => []
      }
    },

    data() {
      return {
        defaultFilter: unVue(options.defaultFilter),

        showCreateTask: false,
        showDatePicker: false,
        showTasksInfo: false,
        showFilter: false,

        showButtons: [],

        filter: Object.assign({}, unVue(TASKS_FILTER), options.defaultFilter)
      }
    },

    computed: {
      computedHeaders() {
        return options.headers || this.headers
      },

      presets() {
        return options.presets
      },

      createDataIsValid() {
        return this.restData[serviceName].create.state !== 'loading' && this.restData[serviceName].create.isValid
      }
    },

    watch: {
      filter: {
        handler() {
          this.restData[serviceName].find.filter = this.filter
        },
        deep: true
      }
    },

    mounted() {
      this.rest[serviceName].find()
    },

    methods: {
      async createTask() {
        if (this.createDataIsValid) {
          try {
            const result = await this.rest.tasks.create(this.restData[serviceName].create.data)
            if (result) {
              this.showCreateTask = false
            }
          } catch (error) {
            globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
          }
        }
      }
    },

    render
  }
}
