import Vue from 'vue'

import io from 'socket.io-client'

import { backendServerIP } from '@/utils'

export function socketsRenew() {
  Vue.$socket && Vue.$socket.disconnect()

  Vue.$socket = io(backendServerIP, {
    autoConnect: false,
    query: { token: window.localStorage.getItem('token') },
    transports: [ 'websocket' ]
  })

  Vue.$socket && Vue.$socket.connect()
}

export default { socketsRenew }
