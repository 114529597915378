import { PUBLIC_FIELDS_USER } from '@sigma-legacy-libs/essentials/lib/constants'

import { fillDependence, generateServices, globalErrorHandler, globalErrorProcessor } from '@/utils'

import { serviceName } from '@/components/services/tokens/utils'

import render from './render'

export default {
  name: 'UsersTokens',

  mixins: [
    generateServices({
      name: serviceName,

      async inputFilter(result) {
        if (!result.Owner) {
          await fillDependence.call(this, result, {
            service: 'users',
            permissions: [ 'reseller', true ],
            pathToId: 'OwnerId',
            pathToObject: 'Owner',
            outputPath: 'Owner',
            picks: PUBLIC_FIELDS_USER
          })
        }

        result.OwnerId = result.Owner && result.Owner.id || result.OwnerId

        return result
      },

      find: {
        defaultFilter: {
          OwnerId: undefined
        }
      },

      get: false,
      create: false,

      update: { manipulateData: false },
      remove: { manipulateData: false }
    })
  ],

  props: {
    OwnerId: String
  },

  data() {
    return {
      showEditDialog: false,
      payload: undefined
    }
  },

  watch: {
    OwnerId() {
      this.filterOrFind()
    }
  },

  mounted() {
    this.filterOrFind()
  },

  methods: {
    filterOrFind() {
      if (this.OwnerId) {
        this.restData[serviceName].find.filter.OwnerId = this.OwnerId
      } else {
        this.rest[serviceName].find()
      }
    },

    async update() {
      try {
        await this.rest[serviceName].update(Object.assign({}, this.payload, { OwnerId: this.OwnerId }))
        this.showEditDialog = false
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      }
    }
  },

  render
}
