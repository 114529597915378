import proxy from '@sigma-legacy-libs/g-proxy'

import { currentTab } from '@/utils'

import { serviceName } from '@/components/services/users/utils'

import render from './render'

export default {
  name: 'UsersForm',

  mixins: [ proxy({ type: 'object' }), currentTab('general') ],

  props: {
    errors: {
      type: Object,
      default: () => ({})
    },

    isNew: Boolean
  },

  data() {
    return {
      showDocumentTemplates: false,

      editable: false,
      passwordVisibility: true,

      currentType: 'sms',
      currentItem: undefined
    }
  },

  computed: {
    tabs() {
      const result = [ 'general' ]

      if (this.checkPermissions('advanced.settingsUser.get', [ 'manager', 'reseller', true ]) && !this.isNew) {
        result.push('settingsUser')
      }

      if (this.checkPermissions(`advanced.${serviceName}.setTariffs`, [ 'manager', 'reseller', true ])) {
        result.push('tariffs')
      }

      if (this.checkPermissions(`advanced.${serviceName}.setRoutingGroups`, [ 'manager', 'reseller', true ])) {
        result.push('routingGroups')
      }
      if (this.checkPermissions(`advanced.${serviceName}.update`, [ 'manager', 'reseller', true ]) && !this.isNew) {
        result.push('processMessages')
      }

      if (this.checkPermissions('advanced.tokens.get', [ 'me', 'manager', 'reseller', true ]) && !this.isNew) {
        result.push('tokens')
      }

      if (this.checkPermissions(`advanced.${serviceName}.setPermissions`, [ 'manager', 'reseller', true ]) && !this.isNew) {
        result.push('permissions')
      }

      if (!this.isNew) {
        result.push('requisites', 'referral')
      }

      return result
    }
  },

  render
}
