import { unVue } from '@sigma-legacy-libs/unvue'
import { EMPTY_SENDINGS } from '@sigma-legacy-libs/essentials/lib/constants'

import render from './render'

export default {
  name: 'MessagePreview',

  props: {
    value: {
      type: Object,
      default: () => unVue(EMPTY_SENDINGS)
    }
  },

  render
}
