import { tableHeaderParser } from '@/utils'

import render from './render'

const name = 'storage'

export default {
  name: 'FileItems',

  props: {
    type: {
      type: String,
      default: 'cards'
    },

    items: {
      type: Array,
      default: () => []
    },

    order: {
      type: Object,
      default: () => ({})
    },

    currentChosenFileId: String,

    readonly: Boolean,
    embedded: Boolean
  },

  computed: {
    headers() {
      return tableHeaderParser.call(this, [
        {
          value: 'type'
        },
        {
          title: `${name}.headers.title`,
          value: 'title',
          align: 'left',
          class: 'pl-0 pr-0'
        },
        {
          title: `${name}.headers.owner`,
          value: 'owner',
          align: 'left',
          class: 'pl-2 pr-2',
          show: this.checkPermissions(`advanced.${name}.get`, [
            true,
            'reseller'
          ])
        },
        {
          title: `${name}.headers.size`,
          value: 'size',
          align: 'right',
          class: 'pl-2 pr-2',
          sortable: true
        },
        {
          title: `${name}.headers.createdAt`,
          value: 'createdAt',
          align: 'right',
          class: 'pl-2 pr-2',
          sortable: true
        },
        {
          value: 'actions',
          show: this.editable
        }
      ])
    },

    editable() {
      return this.checkPermissions([ `advanced.${name}.update`, `advanced.${name}.remove` ], [ 'me', 'reseller', true ]) && !this.readonly
    }
  },

  render
}
