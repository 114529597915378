import OwnerSelect from '@/components/misc/OwnerSelect'
import buttonCopy from '@/components/button/copy'

import { serviceName } from '@/components/services/tokens/utils'

function renderToken(h) {
  if (!this.isNew) {
    return h(
      'div',
      {
        class: 'grid grid-gap--4'
      },
      [
        h('div', { class: 'caption text--grey' }, this.getTranslate(`${serviceName}.labels.token`)),
        h(
          'div',
          {
            class: 'grid grid-gap--8',
            style: { 'grid-template-columns': '1fr 36px' }
          },
          [
            h(
              'div',
              {
                class: 'monospace',
                style: { wordBreak: 'break-all' }
              },
              this.proxy.token
            ),
            h(
              buttonCopy, {
                class: 'ma-0',
                props: { value: this.proxy.token }
              }
            )
          ]
        )
      ]
    )
  }
}

export default function(h) {
  return h(
    'g-form',
    {
      class: 'grid pa-3',
      style: { 'grid-template-columns': '100%' },
      on: {
        submit: () => {
          this.$emit('submit', this.proxy)
        }
      }
    },
    [
      renderToken.call(this, h),

      h(
        'g-textarea',
        {
          props: {
            value: this.proxy.description,
            label: this.getTranslate(`${serviceName}.labels.description`),
            hint: this.getTranslate(`${serviceName}.hints.description`),
            mode: 'outline',
            maxlength: 250,
            count: this.proxy.description ? this.proxy.description.length : 0,
            rounded: true,
            dense: true,
            grow: true,
            clearable: true,
            details: true,
            autofocus: true
          },
          on: {
            input: event => {
              this.proxy.description = event
            }
          }
        }
      ),

      h(
        OwnerSelect,
        {
          props: {
            value: this.proxy.OwnerId,
            details: false
          },
          on: {
            input: event => {
              this.proxy.OwnerId = event
            }
          }
        }
      )
    ]
  )
}
