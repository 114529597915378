import render from './render'

export default {
  name: 'MessageImage',

  functional: true,

  props: {
    image: {
      type: [ String, Object ],
      required: true
    },

    owner: {
      type: String,
      default: ''
    }
  },

  render
}
