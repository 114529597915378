import Vue from 'vue'

import { unVue } from '@sigma-legacy-libs/unvue'

import { appendFormattedDates, generateServices, getUrlToStorage, globalErrorHandler, globalErrorProcessor, tableHeaderParser } from '@/utils'

import render from './render'

const name = 'contracts'

export default {
  name,

  mixins: [
    generateServices({
      name,

      async inputFilter(result) {
        appendFormattedDates.call(this, result)

        return result
      },
      async outputFilter(result) {
        if (!result.FileId) {
          if (result.File && result.File.id) {
            result.FileId = result.File.id
          }
        }

        return result
      },

      find: {
        defaultFilter: {
          $scope: [ 'Owner', 'Requisite', 'Company', 'File' ],
          $search: undefined,
          OwnerId: undefined,
          RequisiteId: undefined,
          CompanyId: undefined
        }
      }
    })
  ],

  data() {
    return {
      editFileProgress: {},

      showEditDialog: false,
      showCreateDialog: false,
      showRenameDialog: false,
      showConfirmRemoveDialog: false,

      editDialogType: undefined,

      googleFileURL: undefined,
      currentEditFile: undefined,
      currentEditContract: {
        id: undefined,
        title: undefined
      },

      loading: false
    }
  },

  computed: {
    headers() {
      return tableHeaderParser.call(this, [
        {
          title: `${name}.headers.title`,
          value: 'title',
          align: 'left',
          class: 'pl-2 pr-2'
        },
        {
          title: `${name}.headers.owner`,
          value: 'owner',
          align: 'left',
          class: 'pl-2 pr-2'
        },
        {
          title: `${name}.headers.requisite`,
          value: 'requisite',
          align: 'left',
          class: 'pl-2 pr-2'
        },
        {
          title: `${name}.headers.company`,
          value: 'company',
          align: 'left',
          class: 'pl-2 pr-2'
        },
        {
          title: `${name}.headers.createdAt`,
          value: 'createdAt',
          align: 'right',
          class: 'pl-2 pr-2',
          sortable: true
        },
        {
          title: `${name}.headers.updatedAt`,
          value: 'updatedAt',
          align: 'right',
          class: 'pl-2 pr-2',
          sortable: true
        },
        {
          value: 'actions'
        }
      ])
    }
  },

  mounted() {
    this.rest[name].find()
  },

  methods: {
    async createContract() {
      try {
        await this.rest[name].create(this.restData[name].create.data)
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      } finally {
        this.showCreateDialog = false
      }
    },

    async removeContract() {
      try {
        await this.rest[name].remove(this.currentEditContract.id)
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      } finally {
        this.showConfirmRemoveDialog = false
      }
    },

    async renameContract() {
      if (this.currentEditContract.title) {
        try {
          await this.rest[name].update(this.currentEditContract)
        } catch (error) {
          globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
        } finally {
          this.showRenameDialog = false
        }
      }
    },

    async editFile(item) {
      try {
        Vue.set(this.editFileProgress, item.id, true)
        const { data } = await Vue.$GRequest.find(`storage/edit/${item.File.id}`, { query: { OwnerId: item.File.OwnerId } })
        if (data) {
          this.googleFileURL = data.result
          this.currentEditFile = unVue(item.File)
          this.editDialogType = 'edit'
          this.showEditDialog = true
        }
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      } finally {
        Vue.set(this.editFileProgress, item.id, false)
      }
    },

    async downloadFile(item) {
      try {
        this.editDialogType = 'export'
        this.showEditDialog = true
        const { data: file } = await Vue.$GRequest.find(`storage/export/${item.File.id}`, { query: { OwnerId: item.File.OwnerId } })
        if (file) {
          window.location.href = getUrlToStorage([ file.OwnerId || file.Owner && file.Owner.id, file.id ])
        }
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      } finally {
        this.showEditDialog = false
      }
    }
  },

  render
}
