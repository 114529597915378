import Vue from 'vue'
import proxy from '@sigma-legacy-libs/g-proxy'

import { mapActions, mapGetters } from 'vuex'
import { SENDING_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import { FILE_TYPES, globalErrorHandler, globalErrorProcessor } from '@/utils'

import render from './render'

let interval
function clearTimer() {
  if (interval) {
    clearInterval(interval)
  }
}

export default {
  name: 'SendingsCreateFormAttach',

  mixins: [ proxy({ type: 'object' }) ],

  data() {
    return {
      loading: false,

      showFileManager: false,

      isGeneratedFile: true,
      generatedAudioFile: undefined,

      timerCount: undefined,

      isGenerate: false,

      uploadedFileMeta: undefined,
      attachFileType: undefined
    }
  },

  computed: {
    ...mapGetters({
      file: 'file/file',
      uploadedFile: 'file/uploadedFile'
    }),

    computedFileType() {
      switch (this.proxy.type) {
        case SENDING_TYPES.voice: {
          return FILE_TYPES.wav.extensions
        }
        case SENDING_TYPES.mms:
        case SENDING_TYPES.viber:
        case SENDING_TYPES.whatsappchat:
        case SENDING_TYPES.viberchat:
        case SENDING_TYPES.vkchat:
        case SENDING_TYPES.telegramchat: {
          return FILE_TYPES.jpg.extensions
        }
        case SENDING_TYPES.whatsapp: {
          if (this.attachFileType === 'image') {
            return FILE_TYPES.jpg.extensions
          }
          break
        }
      }
    },

    attachFileTypes() {
      switch (this.proxy.type) {
        case SENDING_TYPES.mms:
        case SENDING_TYPES.viber:
        case SENDING_TYPES.whatsappchat:
        case SENDING_TYPES.viberchat:
        case SENDING_TYPES.vkchat:
        case SENDING_TYPES.telegramchat:
          return [ 'image' ]
        case SENDING_TYPES.whatsapp:
          return [ 'image', 'file' ]
        case SENDING_TYPES.voice:
          return [ 'audio' ]
        default:
          return []
      }
    },

    showAttachButton() {
      switch (this.proxy.type) {
        case SENDING_TYPES.mms:
        case SENDING_TYPES.viber:
        case SENDING_TYPES.whatsappchat:
        case SENDING_TYPES.viberchat:
        case SENDING_TYPES.vkchat:
        case SENDING_TYPES.telegramchat:
          return !this.proxy.payload.image
        case SENDING_TYPES.whatsapp:
          return !this.proxy.payload.image && !this.proxy.payload.file
        default:
          return false
      }
    },

    hasPerSecondTariff() {
      return this.account.Tariffs.some(tariff => {
        if (tariff.source.split('.')[1] === SENDING_TYPES.voice) {
          return tariff.data.rules.some(rule => rule.price.length === 2 && rule.price[1] > 0)
        }

        return false
      })
    }
  },

  watch: {
    proxy: {
      handler() {
        if (!this.attachFileType) {
          this.attachFileType = this.attachFileTypes[0]
        }

        if (this.proxy.payload.audio) {
          this.isGeneratedFile = false
        }
        if (this.proxy.payload.text && this.proxy.payload.tts) {
          this.isGeneratedFile = true
        }
      },
      deep: true
    },

    'proxy.type'() {
      this.isGeneratedFile = true
      this.cleaningAttachments()
    },

    isGeneratedFile() {
      this.$emit('isGeneratedFile', this.isGeneratedFile)
      this.attachFileType = this.attachFileTypes[0]
      if (!this.isGeneratedFile) {
        Vue.set(this.proxy.payload, 'tts', undefined)
      }
    },

    file() {
      this.loading = true
    },

    uploadedFile: {
      handler({ data }) {
        this.setPayload(data)
      },
      deep: true
    }
  },

  mounted() {
    if (this.proxy.payload.audio) {
      this.isGeneratedFile = false
    }

    this.attachFileType = this.attachFileTypes[0]

    clearTimer()
  },

  beforeDestroy() {
    clearTimer()
  },

  methods: {
    ...mapActions({ setStatus: 'audio/setStatus' }),

    setTimer() {
      if (this.timerCount) {
        interval = setInterval(() => {
          this.timerCount--

          if (this.timerCount === 0) {
            this.timerCount = undefined
            clearTimer()
          }
        }, 1000)
      }
    },

    setPayload(event) {
      this.cleaningAttachments()

      Vue.set(this.proxy.payload, this.attachFileType, event.id)

      this.uploadedFileMeta = event

      this.showFileManager = false
      this.loading = false
    },

    async generateAudioFile() {
      if (this.proxy.payload.text && this.proxy.payload.tts && !this.timerCount) {
        this.isGenerate = true

        try {
          const tts = this.proxy.payload.tts
          const text = this.proxy.payload.text
          const { data } = await Vue.$GRequest.create('/tts/generate', {
            tts,
            text
          })

          if (data) {
            this.generatedAudioFile = await Vue.$GRequest.get('storage', data.fileId, { query: { return: 'meta' } })
          }
        } catch (error) {
          globalErrorHandler.call(this, globalErrorProcessor.call(this, error))

          if (error && error.error === 429 && error['retry-after']) {
            this.timerCount = error['retry-after']
            this.setTimer()
          }
        }

        this.isGenerate = false
      }
    },

    cleanAttach(type) {
      Vue.set(this.proxy.payload, type || this.attachFileType, undefined)
    },

    cleaningAttachments() {
      const fileTypes = [ 'image', 'audio', 'file' ]
      for (const type of fileTypes) {
        if (!~this.attachFileTypes.indexOf(type) && this.attachFileType !== type) {
          this.cleanAttach(type)
        }
      }
    }
  },

  render
}
