export default function(h) {
  let style
  if (this.computedDisabled) {
    style = {
      opacity: 0.5,
      cursor: 'default',
      'user-select': 'none'
    }
  }

  switch (this.type) {
    case 'button':
      return h(
        'g-button',
        {
          class: this.classes,
          props: {
            label: !this.icon && (this.text || this.getTranslate('misc.buttons.upload')),
            icon: this.buttonIcon || 'file_upload',
            flat: this.flat,
            rounded: this.rounded,
            small: this.small,
            large: this.large,
            depressed: this.depressed,
            disabled: this.computedDisabled,
            loading: this.loading || this.uploadState === 'loading',
            color: this.color
          },
          on: {
            click: async () => {
              const file = document.getElementById('file')

              await this.setCurrentAttributes()

              if (!this.computedDisabled && file) {
                file.click()
              }
            }
          }
        }
      )
    case 'drop':
      return h(
        'div',
        {
          style,
          on: {
            dragover: this.dragover,
            dragleave: this.dragleave,
            dragend: this.dragleave,
            drop: this.drop
          }
        },
        [ this.$slots.default ]
      )
  }
}
