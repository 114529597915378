import Vue from 'vue'
import proxy from '@sigma-legacy-libs/g-proxy'

import { decodeKeys } from '../../../utils'

import render from './render'

export default {
  name: 'Prices',

  mixins: [ proxy({ type: 'object' }) ],

  data() {
    return {
      groups: []
    }
  },

  watch: {
    proxy: {
      handler() {
        this.getGroups()
      },
      deep: true
    }
  },

  mounted() {
    this.getGroups()
  },

  methods: {
    async getGroups() {
      const keys = decodeKeys(this.proxy)
      const parsedData = Object.keys(keys).map(mcc => {
        return {
          mcc,
          mnc: keys[mcc]
        }
      })
      if (Array.isArray(parsedData) && parsedData.length) {
        const { data } = await Vue.$GRequest._request({
          method: 'POST',
          url: '/dictionaries/getOperatorsData',
          headers: { Authorization: window.localStorage.getItem('token') },
          data: { data: parsedData }
        })

        this.groups = data || []
      }
    }
  },

  render
}
