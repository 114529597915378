import proxy from '@sigma-legacy-libs/g-proxy'

import { SENDING_TYPES, TARIFFS_SOURCES } from '@sigma-legacy-libs/essentials/lib/constants'

import { dataFilter, serviceName, transformTypes } from '@/components/services/tariffs/utils'

import render from './render'

export default {
  name: 'Form',

  mixins: [ proxy({ type: 'object' }) ],

  props: {
    errors: {
      type: Object,
      default: () => ({})
    },

    isNew: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    sources() {
      if (this.proxy.type) {
        return TARIFFS_SOURCES.map(source => {
          const type = this.proxy.type.split('.')[1]
          let disabled = true
          switch (source) {
            case 'data': {
              switch (type) {
                case SENDING_TYPES.viber:
                case SENDING_TYPES.vk:
                case SENDING_TYPES.voice:
                case SENDING_TYPES.whatsapp: {
                  disabled = false
                  break
                }
              }
              break
            }
            case 'data.legacy': {
              disabled = false
              break
            }
            case 'rates': {
              switch (type) {
                case SENDING_TYPES.sms: {
                  disabled = false
                  break
                }
              }
              break
            }
          }

          return {
            title: this.getTranslate(`tariffs.sources.${source === 'data' ? 'data.new' : source}`),
            value: source,
            disabled
          }
        })
      }

      return []
    },

    types() {
      const types = this.getSendingTypesByPermission(`advanced.${serviceName}.update`).map(({ value }) => value)
      types.push('subscription.apisender')

      return transformTypes.call(this, types)
    }
  },

  watch: {
    'proxy.source'() {
      this.initData(true)
    },
    'proxy.type'() {
      this.initData(true)
    }
  },

  mounted() {
    this.initData()
  },

  methods: {
    initData(clear = false, resetSource = true) {
      dataFilter(this.proxy, clear)

      if (resetSource && this.isNew) {
        if (this.sources.length) {
          if (this.proxy.source) {
            const { disabled } = this.sources.find(({ value }) => value === this.proxy.source)
            if (!disabled) {
              return
            }
          }

          const { value } = this.sources.find(({ disabled }) => !disabled)
          this.proxy.source = value
        }
      }
    }
  },

  render
}
