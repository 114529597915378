export default {
  RUB: {
    title: 'рубль',
    short: 'руб.',
    symbol: {
      html: '&#8381;',
      unicode: '₽'
    }
  },
  EUR: {
    title: 'евро',
    short: 'eur',
    symbol: {
      html: '&#8364;',
      unicode: '€'
    }
  },
  USD: {
    title: 'доллар',
    short: 'usd',
    symbol: {
      html: '&#36;',
      unicode: '$'
    }
  }
}
