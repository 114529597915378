export default {
  name: 'Dashboard',

  metaInfo: {
    title: 'dashboard.title'
  },

  render(h) {
    return h()
  }
}
