export default {
  title: 'Rules',

  one: 'Rule',
  many: 'Rules',

  titles: {
    create: 'Create message rule'
  },

  labels: {
    title: 'Title',
    conditions: 'Conditions',
    actions: 'Actions',
    search: 'Search',
    text: 'Text',
    sender: 'Sender',
    moderation: 'Moderation',
    type: 'Type'
  },

  dialogs: {
    new: 'New message rule'
  },

  hints: {
    notFound: 'Message rule templates not found',
    texts: 'One of the texts specified here will be randomly selected.',
    fallbacks: 'Fallbacks order will be followed.'
  },

  contents: {
    confirm: {
      remove: 'Confirm remove {title}?'
    }
  },

  tooltips: {
    isActive: 'Activate or deactivate a message rule.',
    favorites: 'Make the message rule a favorite'
  },

  subtitles: {
    conditions: 'Conditions',
    actions: 'Actions'
  },

  buttons: {
    texts: 'Texts',
    fallbacks: 'Fallbacks'
  },

  snackbars: {
    created: 'Message rule successfully created.',
    updated: 'Message rule successfully updated.',
    removed: 'Message rule successfully removed.'
  }
}
