import render from './render'

export default {
  name: 'SimpleValue',

  props: {
    value: {
      type: Array,
      default: () => []
    },
    count: {
      type: Number,
      default: 3
    }
  },

  computed: {
    isMany() {
      return this.value.length > this.count
    }
  },

  render
}
