export default {
  title: 'Operators',

  labels: {
    operator: 'Operator',
    price: 'Price'
  },

  snackbars: {
    created: 'Operator created',
    updated: 'Operator updated',
    removed: 'Operator removed'
  }
}
