import Vue from 'vue'

import { unVue } from '@sigma-legacy-libs/unvue'
import { mapActions, mapGetters } from 'vuex'

import { generateServices, globalErrorHandler, globalErrorProcessor } from '@/utils'

import render from './render'
import { permissionPresets } from '@/utils/constants'

const _ = {
  get: require('lodash/get'),
  merge: require('lodash/merge')
}

export function serviceTemplate(options = {}) {
  const { serviceName, inputFilter, outputFilter } = options

  const getCheckPermissionParams = method => {
    const permission = [
      'advanced',
      _.get(options, `permission.${method}.serviceName`, serviceName),
      _.get(options, `permission.${method}.method`, method)
    ].join('.')

    return [ permission, _.get(options, `permission.${method}.accept`, permissionPresets.me) ]
  }

  options.type = options.type || 'table'
  options.size = options.size || 'medium'
  options.limit = options.limit || 24
  options.width = options.width || 400
  options.dialog = options.dialog || {
    create: {},
    remove: {}
  }
  options.viewChanger = options.viewChanger == false ? false : true

  options.errorProcessor = options.errorProcessor || undefined
  options.beforeCreated = options.beforeCreated || (() => {})
  options.mounted = options.mounted || (() => {})

  return {
    name: 'Template',

    mixins: [
      generateServices([
        {
          name: serviceName,

          inputFilter,
          outputFilter,

          errorProcessor: options.errorProcessor,

          find: _.merge({
            defaultFilter: { $search: undefined },
            defaultPagination: { limit: options.limit }
          }, options.find || {}),

          get: _.merge({
            params: { query: { $scope: [ 'full' ] } }
          }, options.get || {}),

          update: _.merge({
            params: { query: { $scope: [ 'full' ] } }
          }, options.update || {}),

          create: _.merge({
            params: { query: { $scope: [ 'full' ] } }
          }, options.create || {}),

          remove: _.merge({
            params: { query: { $scope: [ 'full' ] } },
            redirect: true
          }, options.remove || {})
        }
      ])
    ],

    data() {
      return {
        type: options.type,
        size: options.size,

        showCreateDialog: false,
        showRemoveDialog: false,
        showFavorites: false,

        favoritesItems: [],
        favoritesSearch: undefined,

        updatedData: undefined
      }
    },

    computed: {
      ...mapGetters({ favorites: 'favorites/favorites' }),

      canCreate() {
        return this.checkPermissions(...getCheckPermissionParams('create'))
      },
      canRemove() {
        return this.checkPermissions(...getCheckPermissionParams('remove'))
      },
      canUpdate() {
        return this.checkPermissions(...getCheckPermissionParams('update'))
      },
      canGet() {
        return this.checkPermissions(...getCheckPermissionParams('get'))
      },

      title() {
        const data = this.restData[options.serviceName].get.data
        if (data) {
          return data.title || _.get(data, options.titlePath) || this.getTranslate(`${options.serviceName}.title`)
        }
      },
      titleURLSlack() {
        if (this.title) {
          return `[${this.title}](${window.location.href})`
        }
      }
    },

    watch: {
      $route: {
        handler() {
          this.getOrFind()
          this.watchRouteQuery()
        },
        deep: true
      },

      showFavorites() {
        if (this.showFavorites) {
          this.fillFavorites()
        }
      }
    },

    beforeCreated() {
      options.beforeCreated.call(this)
    },

    mounted() {
      options.mounted.call(this)

      this.getOrFind()
      this.watchRouteQuery()
    },

    methods: {
      ...mapActions({
        refreshFavorites: 'favorites/refresh',
        removeFavorite: 'favorites/remove'
      }),

      async create() {
        if (this.canCreate) {
          if (this.restData[serviceName].create.isValid) {
            try {
              const result = await this.rest[serviceName].create(this.restData[serviceName].create.data)
              if (result) {
                this.showCreateDialog = false
              }
            } catch (error) {
              globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
            }
          }
        }
      },

      async remove() {
        if (this.canRemove) {
          try {
            const result = await this.rest[serviceName].remove(this.$route.params.id)
            if (result) {
              this.showRemoveDialog = false
            }
          } catch (error) {
            globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
          }
        }
      },

      async update() {
        if (this.canUpdate) {
          if (this.restData[serviceName].update.isValid) {
            try {
              const result = await this.rest[serviceName].update(this.restData[serviceName].get.data)
              if (result) {
                this.updatedData = unVue(result)
              }
            } catch (error) {
              globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
            }
          }
        }
      },

      async getOrFind() {
        this.updatedData = undefined

        if (this.canGet) {
          if (this.$route.params.id) {
            this.rest[serviceName].get(this.$route.params.id)
          } else {
            await this.rest[serviceName].find()
            this.refreshFavorites(options.serviceName)
            this.fillFavorites()
          }
        }
      },

      async watchRouteQuery() {
        const create = _.get(this.$route, 'query.create')
        if (create) {
          this.showCreateDialog = true
        }
      },

      async fillFavorites() {
        if (!this.$route.params.id) {
          if (Array.isArray(this.favorites[serviceName]) && this.favorites[serviceName].length) {
            const items = await Promise.all(this.favorites[serviceName].map(async id => {
              let item = this.restData[serviceName].find.data.find(item => item.id === id)
              if (!item) {
                try {
                  const { data } = await Vue.$GRequest.get(serviceName, id)
                  if (data) {
                    item = data
                  }
                } catch (error) {
                  await this.removeFavorite({
                    serviceName,
                    id
                  })
                }
              }

              return item
            }))
            this.favoritesItems = items.filter(item => {
              if (this.favoritesSearch) {
                return ~item.title.indexOf(this.favoritesSearch)
              }
              if (options.favorites && typeof options.favorites.filter === 'function') {
                return options.favorites.filter.call(this, item)
              }

              return true
            })
          } else {
            this.favoritesItems = []
          }
        }
      }
    },

    render(h) {
      return render.call(this, h, options)
    }
  }
}
