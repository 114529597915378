import proxy from '@sigma-legacy-libs/g-proxy'

import render from './render'

export default function(options) {
  return {
    name: 'NotificationsEmailSMTP',

    mixins: [ proxy({ type: 'object' }) ],

    data() {
      return {
        showDialog: false,
        passwordVisibility: true
      }
    },

    methods: {
      watchValueHandler() {
        return
      },
      watchProxyHandler() {
        return
      }
    },

    render(h) {
      return render.call(this, h, options)
    }
  }
}
