import HeadTitle from '@/components/misc/HeadTitle'

function renderSlotHeader(h) {
  if (this.$slots.title) {
    return this.$slots.title
  } else {
    return h(
      HeadTitle,
      {
        props: { value: this.title || this.getTranslate('commons.titles.confirm.remove') },
        slot: 'header'
      }
    )
  }
}

function renderSlotContent(h) {
  if (this.$slots.content) {
    return this.$slots.content
  } else {
    return h(
      'div',
      {
        class: 'pa-3 subheading'
      },
      this.content || this.getTranslate('commons.contents.confirm.remove')
    )
  }
}

function renderDialog(h) {
  return h(
    'g-dialog',
    {
      props: {
        rounded: true,
        value: this.show,
        maxWidth: this.maxWidth
      },
      on: {
        input: event => {
          this.show = event
        }
      }
    },
    [
      renderSlotHeader.call(this, h),
      renderSlotContent.call(this, h),

      h(
        'g-card-actions',
        {
          class: 'fjcfe facfe',
          slot: 'footer'
        },
        [
          h(
            'g-button',
            {
              props: {
                label: this.getTranslate('misc.buttons.cancel'),
                flat: true,
                rounded: true
              },
              on: {
                click: () => {
                  this.show = false
                }
              }
            }
          ),

          h(
            'g-button',
            {
              props: {
                label: this.getTranslate('misc.buttons.remove'),
                rounded: true,
                depressed: true,
                loading: this.loading,
                disabled: this.disabled,
                color: 'error'
              },
              on: {
                click: async () => {
                  await this.callback()
                  this.show = false
                }
              }
            }
          )
        ]
      )
    ]
  )
}

export default function(h) {
  return h(
    'g-button',
    {
      props: {
        flat: this.flat,
        rounded: this.rounded,
        small: this.small,
        large: this.large,
        depressed: this.depressed,
        loading: this.loading,
        disabled: this.disabled,
        label: this.buttonText,
        icon: 'delete',
        color: 'error'
      },
      on: {
        click: event => {
          if (this.stopPropagation) {
            event.stopPropagation()
          }
          this.show = true
        }
      },
      scopedSlots: {
        dialog: () => renderDialog.call(this, h)
      }
    }
  )
}
