import ButtonReport from '@/components/button/report'
import SendingsFilter from '@/components/services/sendings/outgoing/filter'
import SendingsIncomingDetailsTemplate from '@/components/services/sendings/incoming/template'
import sendingsTasksTemplate from '@/components/services/sendings/tasks/template'

import { routerQueryReplace } from '@/utils'

function renderSelectPresetField(h) {
  if (this.checkPermissions('advanced.reports.get', [ 'me', 'reseller', true ])) {
    return h(
      'g-select',
      {
        style: {
          'min-width': this.viewport.breakpoint.smDown ? '100%' : '308px',
          'max-width': this.viewport.breakpoint.smDown ? '100%' : '308px'
        },
        class: {
          'mr-2': this.viewport.breakpoint.mdUp,
          'mb-2': this.viewport.breakpoint.smDown
        },
        props: {
          value: this.preset,
          items: this.presets
            .filter(item => !!item.show)
            .map(preset => ({
              title: this.getTranslate(preset.title),
              value: preset.value
            })),
          required: true,
          mode: 'outline',
          rounded: true,
          dense: true,
          details: false
        },
        on: {
          input: event => {
            this.preset = event
          }
        },
        slot: 'title'
      }
    )
  }
}

function renderButtonFilter(h) {
  return h(
    'g-button',
    {
      class: 'my-0 ml-2',
      props: {
        label: this.viewport.breakpoint.mdUp ? this.getTranslate('misc.buttons.filter') : undefined,
        icon: 'filter_list',
        flat: true,
        rounded: this.viewport.breakpoint.mdUp
      },
      on: {
        click: () => {
          this.showFilter = !this.showFilter
        }
      },
      slot: 'title'
    }
  )
}

function renderFilter(h) {
  if (this.showFilter) {
    return h(
      SendingsFilter,
      {
        props: {
          preset: this.preset,
          value: this.filter
        },
        on: {
          input: event => {
            this.filter = event
          }
        },
        slot: 'filter'
      }
    )
  }
}

function renderOutgoingTabItem(h) {
  if (this.checkPermissions('advanced.sendings.find', [ 'me', 'reseller', true ])) {
    return h(
      'g-card',
      {
        props: { rounded: true },
        key: 'outgoing'
      },
      [
        h(
          this.presetNameKebab,
          {
            props: { filter: this.filter },
            on: {
              input: event => {
                this.filter = event
              }
            }
          },
          [
            renderSelectPresetField.call(this, h),

            h(
              ButtonReport,
              {
                class: 'ma-0',
                props: {
                  preset: this.preset,
                  filter: this.filter
                },
                slot: 'title'
              }
            ),

            renderButtonFilter.call(this, h),
            renderFilter.call(this, h)
          ]
        )
      ]
    )
  }
}

function renderIncomingTabItem(h) {
  if (this.checkPermissions('advanced.sendingsIncoming.get', [ 'me', 'reseller', true ])) {
    return h(SendingsIncomingDetailsTemplate, { key: 'incoming' })
  }
}

function renderTasksTabItem(h) {
  return h(sendingsTasksTemplate, { key: 'tasks' })
}

export default function(h) {
  return h(
    'div',
    {
      class: 'fc ff'
    },
    [
      h(
        'g-tabs-header',
        {
          class: 'pl-3',
          props: {
            value: this.currentTab,
            tabs: this.tabs.map(key => {
              return {
                key,
                title: this.getTranslate(`sendings.tabs.${key}`)
              }
            })
          },
          on: {
            input: event => {
              routerQueryReplace({ currentTab: event })
            }
          }
        }
      ),
      h(
        'g-tabs',
        {
          props: { value: this.currentTab }
        },
        [
          renderOutgoingTabItem.call(this, h),
          renderIncomingTabItem.call(this, h),
          renderTasksTabItem.call(this, h)
        ]
      )
    ]
  )
}
