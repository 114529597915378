// import { waitMs } from '@sigma-legacy-libs/essentials/lib/utils'

// const shift = 10
// const shiftDelay = 100
// const padding = 50

/**
 * @param holder DOM элемент с элементами внутри для перетаскиваний
 * @param handle DOM элемент для захвата переноса
 * @param list массив для сортировки данных
 * @param events События которые нужно отслеживать drag, dragstart, dragend, dragover, dragenter, dragexit, dragleave, drop
 *
 * @export
 * @class Draggable
 */
export class Draggable {
  constructor(holder, handle, list) {
    this.debugMode = false

    this.holder = holder
    this.handle = handle
    this.list = list

    this.newIndex = 0
    this.oldIndex = 0

    this.handleValid = false

    this.mousedown = this.mousedown.bind(this)
    this.mouseup = this.mouseup.bind(this)
    this.dragstart = this.dragstart.bind(this)
    this.dragend = this.dragend.bind(this)
    this.dragenter = this.dragenter.bind(this)

    this.dragged = false

    for (const element of this.holder.getElementsByClassName(this.handle)) {
      element.classList.add('cursor-grab')
    }

    if (this.holder) {
      this.refreshEvents()

      if (typeof this.holder.addEventListener === 'function') {
        this.holder.addEventListener('dragstart', this.dragstart, false)
        this.holder.addEventListener('dragend', this.dragend, false)
        this.holder.addEventListener('dragenter', this.dragenter, false)
      }
    }
  }

  debug(method, data = '') {
    if (this.debugMode) {
      console.log(method, data) // eslint-disable-line
    }
  }

  mousedown(event) {
    this.debug('mousedown', event)

    this.handleValid = true
  }

  mouseup(event) {
    this.debug('mouseup', event)

    this.handleValid = false

    this.scroll()
  }

  dragstart(event) {
    this.debug('dragstart', event)

    this.dragged = true

    if (this.handle) {
      if (!this.handleValid) {
        event.preventDefault()
      }
      event.target.classList.add('dragging')
    }

    this.oldIndex = [].slice.call(this.holder.children).indexOf(event.target)
  }

  dragend(event) {
    this.debug('dragend', event)

    this.dragged = false

    event.target.classList.remove('dragging')

    const [ item ] = this.list.splice(this.oldIndex, 1)

    this.list.splice(this.newIndex, 0, item)
    this.handleValid = false
  }

  async dragenter(event) {
    this.debug('dragenter', event)

    let index = -1
    let target = event.target
    while (!~(index = [].slice.call(this.holder.children).indexOf(target))) { // Не выносить приравнивание индекса из условия while
      if (!target) {
        break
      }
      target = target.parentElement
    }

    if (index < 0) {
      index = this.oldIndex
    }

    this.newIndex = index

    // const y = event.offsetY
    // const offset = this.holder.clientHeight - padding

    // while (this.dragged) {
    //   await waitMs(shiftDelay)

    //   if (y < padding) {
    //     this.scroll(-1)
    //   } else if (y >= offset) {
    //     this.scroll(1)
    //   } else {
    //     break
    //   }
    // }
  }

  // scroll(direction) {
  //   this.debug('scroll', direction)

  //   if (direction) {
  //     this.holder.scrollTop = this.holder.scrollTop + shift * direction
  //   }
  // }

  refreshEvents() {
    this.debug('refreshEvents')

    for (const element of this.holder.children) {
      element.setAttribute('draggable', true)
    }

    for (const element of this.holder.getElementsByClassName(this.handle)) {
      if (element && typeof element.removeEventListener === 'function') {
        element.removeEventListener('mousedown', this.mousedown, false)
        element.removeEventListener('mouseup', this.mouseup, false)
      }
      if (element && typeof element.addEventListener === 'function') {
        element.addEventListener('mousedown', this.mousedown, false)
        element.addEventListener('mouseup', this.mouseup, false)
      }
    }
  }
}

export default { Draggable }
