import tokensItemButtons from '@/components/services/tokens/item/buttons'

import { serviceName } from '@/components/services/tokens/utils'

function renderButtons(h) {
  if (this.buttons) {
    return h(
      tokensItemButtons,
      {
        props: {
          value: this.value
        }
      }
    )
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: 'faic',
      style: { 'min-height': '36px' }
    },
    [
      h(
        'div',
        {
          class: 'grid grid-gap--2 caption px-2'
        },
        [
          h(
            'router-link',
            {
              class: 'text-overflow link link--passive monospace',
              props: {
                to: {
                  name: `${serviceName}.single`,
                  params: { id: this.value.id }
                }
              }
            },
            this.value.token
          ),

          h(
            'div',
            {
              class: 'grid grid-gap--8 text--grey',
              style: { gridTemplateColumns: 'repeat(2, minmax(min-content, max-content))' }
            },
            [
              h(
                'div',
                {
                  class: 'text-overflow'
                },
                [
                  new Date(this.value.createdAt).toLocaleDateString(this.locale, {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    weekday: 'long'
                  })
                ]
              ),
              h(
                'div',
                {
                  class: 'text-overflow'
                },
                this.value.description || this.getTranslate('commons.hints.noDescription')
              )
            ]
          )
        ]
      ),

      renderButtons.call(this, h)
    ]
  )
}
