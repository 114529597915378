import { unVue } from '@sigma-legacy-libs/unvue'

import HeadTitle from '@/components/misc/HeadTitle'
import d360generator from '@/components/services/providers/d360generator'

function renderRemoveButton(h, index) {
  return h(
    'g-button',
    {
      class: {
        'ma-0': true,
        'hover-child': this.viewport.breakpoint.mdUp
      },
      props: {
        flat: true,
        icon: 'remove',
        color: 'error'
      },
      on: {
        click: () => {
          this.removeField(index)
        }
      }
    }
  )
}

function renderTitle(h, { key }, listItem = false) {
  const property = this.properties.find(item => item.property === key)
  const item = Object.assign({}, { title: key }, unVue(property))

  return h(
    'div',
    {
      class: {
        'grid grid-cols--1 grid-gap--4 ff pr-3': true,
        'py-2': listItem
      }
    },
    [
      h('div', {}, item.title),
      h(
        'div',
        {
          class: 'fc ff text--grey small'
        },
        [
          h('div', item.group && item.group.text),
          h('div', item.description)
        ]
      )
    ]
  )
}

function renderValue(h, item) {
  switch (item.key) {
    case 'd360ApiKeys': {
      return h(
        'div',
        {
          class: 'fjcfe',
          style: { 'max-width': '150px' }
        },
        [ h('div', { class: 'body-1 text-overflow' }, `${Object.keys(item.value).length}`) ]
      )
    }

    default: {
      return h(
        'div',
        {
          class: 'fjcfe',
          style: { 'max-width': '150px' }
        },
        [ h('div', { class: 'body-1 text-overflow' }, `${item.value}`) ]
      )
    }
  }
}

function renderItem(h, item, index) {
  return h(
    'div',
    {
      class: 'faic grid grid-gap--8 hover-parent hover-parent--opacity',
      style: { 'grid-template-columns': '1fr 36px' }
    },
    [
      h(
        'div',
        {
          class: 'faifs hover-background cursor-pointer br--16 py-2 px-3',
          on: {
            click: () => {
              this.edit(index)
            }
          }
        },
        [
          renderTitle.call(this, h, item),
          renderValue.call(this, h, item, index)
        ]
      ),

      renderRemoveButton.call(this, h, index)
    ]
  )
}

function renderItems(h) {
  if (Array.isArray(this.proxy) && this.proxy.length) {
    return h(
      'div',
      {
        class: 'grid grid-cols--1 grid-gap--8'
      },
      this.proxy.map((item, index) => renderItem.call(this, h, item, index))
    )
  } else {
    return h(
      'div',
      {
        class: 'faic'
      },
      [
        h(
          'g-icon',
          {
            class: 'mr-2',
            props: {
              value: 'search_off',
              color: 'grey',
              size: 36
            }
          }
        ),

        h(
          'div',
          {
            class: 'grid grid-cols--1 grid-gap--4 ff'
          },
          [
            h('div', {}, this.getTranslate('providers.titles.properties.notFound')),
            h('div', { class: 'small text--grey' }, this.getTranslate('providers.hints.properties.notFound'))
          ]
        )
      ]
    )
  }
}

function renderValueField(h) {
  if (this.payload.key) {
    const { type, property } = this._getProperty()
    switch (type) {
      case 'boolean': {
        return h(
          'g-switch',
          {
            class: 'mtpx-12',
            props: { value: this.payload.value },
            on: {
              input: event => {
                this.payload.value = event
              }
            }
          }
        )
      }
      default: {
        switch (property) {
          case 'd360ApiKeys': {
            return h(
              d360generator,
              {
                props: {
                  value: this.payload.value
                },
                on: {
                  input: event => {
                    this.payload.value = event
                  }
                }
              }
            )
          }

          default: {
            return h(
              'g-text-field',
              {
                props: {
                  value: this.payload.value,
                  label: this.getTranslate('misc.value'),
                  type: type === 'string' ? 'text' : type,
                  mode: 'outline',
                  dense: true,
                  rounded: true,
                  clearable: true,
                  details: false
                },
                on: {
                  input: event => {
                    this.payload.value = event
                  }
                }
              }
            )
          }
        }
      }
    }
  }
}

function renderDialog(h) {
  let type
  let hint = ''
  if (this.payload.key) {
    const property = this._getProperty()
    if (Array.isArray(property.processor)) {
      hint += this.getTranslate('servers.labels.processor') + ': '
      hint += property.processor.join(' | ')
      hint += '<br>'
    }
    hint += property.description
    type = property.type
  }

  const filter = ({ whiteList, blackList }) => {
    if (!this.processor) {
      return true
    }
    if (Array.isArray(blackList) && blackList.length) {
      if (~blackList.indexOf(this.processor)) {
        return false
      }
    }
    if (!Array.isArray(whiteList) || !whiteList.length) {
      return true
    }
    if (~whiteList.indexOf(this.processor)) {
      return true
    }

    return false
  }

  return h(
    'g-dialog',
    {
      props: {
        value: this.showDialog,
        maxWidth: 400,
        rounded: true
      },
      on: {
        input: event => {
          this.showDialog = event
        }
      }
    },
    [
      h(
        HeadTitle,
        {
          props: { value: this.getTranslate(`providers.titles.properties.${this.editMode ? 'edit' : 'add'}`) },
          slot: 'header'
        }
      ),

      h(
        'div',
        {
          class: {
            'pa-3': true,
            'grid grid-cols--1 grid-gap--8': type !== 'boolean',
            faifs: type === 'boolean'
          }
        },
        [
          h(
            'g-select',
            {
              class: 'mr-3',
              props: {
                value: this.payload.key,
                label: this.getTranslate('misc.key'),
                hint,
                items: this.properties.filter(filter).map(item => {
                  return {
                    title: item.property,
                    value: item.property,
                    group: item.group,
                    description: item.description
                  }
                }),
                itemsDisabled: this.proxy.map(({ key }) => key),
                searchValidation: (searchValue, { description, group }) => {
                  searchValue = searchValue.toLocaleLowerCase()
                  description = description.toLocaleLowerCase()
                  const tag = group.tag.toLocaleLowerCase()
                  const text = group.text.toLocaleLowerCase()

                  return ~description.indexOf(searchValue) || ~tag.indexOf(searchValue) || ~text.indexOf(searchValue)
                },
                loading: this.propertiesLoading,
                mode: 'outline',
                dense: true,
                rounded: true,
                clearable: true,
                combobox: true,
                autocomplete: true
              },
              on: {
                input: event => {
                  this.payload.key = event
                }
              },
              scopedSlots: {
                item: ({ item }) => renderTitle.call(this, h, { key: item.title }, true)
              }
            }
          ),

          renderValueField.call(this, h)
        ]
      ),

      h(
        'g-card-actions',
        {
          class: 'fjcfe',
          slot: 'footer'
        },
        [
          h(
            'g-button',
            {
              props: {
                flat: true,
                rounded: true,
                label: this.getTranslate('misc.buttons.cancel')
              },
              on: {
                click: () => {
                  this.cancel()
                }
              }
            }
          ),

          h(
            'g-button',
            {
              props: {
                flat: true,
                rounded: true,
                color: 'primary',
                label: this.getTranslate(`misc.buttons.${this.editMode ? 'update' : 'add'}`)
              },
              on: {
                click: () => {
                  if (this.editMode) {
                    this.update()
                  } else {
                    this.add()
                  }
                }
              }
            }
          )
        ]
      )
    ]
  )
}

function renderAddButton(h) {
  return h(
    'div',
    {
      class: 'fjcfe'
    },
    [
      h(
        'g-button',
        {
          class: 'ma-0',
          props: {
            flat: true,
            rounded: true,
            icon: 'add',
            color: 'primary',
            label: this.getTranslate('providers.buttons.add.property')
          },
          on: {
            click: () => {
              this.showDialog = true
            }
          },
          scopedSlots: {
            dialog: () => renderDialog.call(this, h)
          }
        }
      )
    ]
  )
}

function renderContent(h) {
  return h(
    'div',
    {
      class: 'grid grid-cols--1'
    },
    [
      renderItems.call(this, h),
      renderAddButton.call(this, h)
    ]
  )
}

export default function(h) {
  return renderContent.call(this, h)
}
