import OwnerSelect from '@/components/misc/OwnerSelect'

import { serviceName } from '@/components/services/yclients/utils'

export default function(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8',
      style: { 'grid-template-columns': '250px 36px' }
    },
    [
      h(
        OwnerSelect,
        {
          props: {
            value: this.proxy.OwnerId,
            mode: 'outline',
            dense: true,
            rounded: true,
            clearable: true,
            details: false
          },
          on: {
            input: event => {
              this.proxy.OwnerId = event
            }
          }
        }
      ),

      h(
        'g-switch',
        {
          props: {
            value: this.proxy.isActive
          },
          on: {
            input: event => {
              this.proxy.isActive = event
            }
          },
          directives: [
            {
              name: 'g-tooltip',
              options: {
                value: this.getTranslate(`${serviceName}.tooltips.filter.isActive`)
              }
            }
          ]
        }
      )
    ]
  )
}
