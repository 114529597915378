import { getToday } from '@/utils/misc' // Этот путь должен быть прямым иначе webpack не сможет его найти

export const TASKS_FILTER = {
  OwnerId: undefined,
  state: undefined,
  type: undefined,
  createdAt: getToday(),
  isActive: true
}

export default { TASKS_FILTER }
