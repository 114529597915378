import { types } from './sendings'

export default {
  title: 'Пользователи',

  titles: {
    create: 'Создать нового пользователя',
    add: {
      funds: 'Пополнить баланс'
    },
    token: 'API ключ',
    presets: 'Пресеты'
  },

  user: 'Пользователь',
  users: 'Пользователи',
  currentTariff: 'Текущий тариф',

  contents: {
    routingGroups: {
      confirm: {
        remove: 'Подтвердите удаление группы марщрутизаций только из настроек текущего пользователя.'
      }
    }
  },

  labels: {
    owner: 'Владелец',
    username: 'Имя пользователя',
    password: 'Пароль',
    reseller: 'Реселлер',
    manager: 'Менеджер',
    referralCode: 'Реферальный код пользователя',
    data: {
      firstName: 'Имя',
      lastName: 'Фамилия',
      email: 'E-mail',
      phone: 'Телефон',
      partnership: {
        hasContract: 'Есть контракт'
      },
      meta: {
        source: 'Источник',
        usedReferralCode: 'Использованный реферальный код'
      }
    },
    settings: {
      sendings: {
        $title: 'Отправления',
        allowDuplicates: {
          title: 'Разрешить дубликаты',
          false: 'Нет',
          true: 'Да'
        },
        sendernamesCheck: 'Ограничить имена отправителей для типов',
        strictTemplates: Object.assign({ title: 'Строгий шаблон для указанных типов отправлений' }, types),
        anySendername: Object.assign({ title: 'Любые имена отправителей' }, types),
        smpp: { async: 'SMPP async' },
        allow: 'Разрешить'
      },
      routingGroup: {
        $title: 'Группа маршрутизаций',
        sms: 'Группа маршрутизаций SMS',
        viber: 'Группа маршрутизаций Viber',
        vk: 'Группа маршрутизаций ВКонтакте',
        whatsapp: 'Группа маршрутизаций WhatsApp',
        push: 'Группа маршрутизаций Push',
        mms: 'Группа маршрутизаций MMS',
        voice: 'Группа маршрутизаций Voice',
        whatsappchat: 'Группа маршрутизаций WhatsApp чат',
        viberchat: 'Группа маршрутизаций Viber чат',
        vkchat: 'Группа маршрутизаций ВКонтакте чат ',
        telegramchat: 'Группа маршрутизаций Телеграм чат',
        facebookchat: 'Группа маршрутизаций Facebook чат',
        flashcall: 'Группа маршрутизаций Flash call'
      },
      billing: {
        $title: 'Расчетные операции',
        overdraft: { title: 'Перерасход' }
      },
      websocket: {
        $title: 'WEB Socket',
        channels: {
          title: 'Websocket каналы',
          dbUpdates: 'Обновление базы',
          everything: 'Все',
          health: 'Здоровье платформы',
          sendingStatusUpdates: 'Обноление статуса отправлений'
        }
      },
      smpp: {
        $title: 'SMPP',
        rudeMode: {
          title: 'Грубый режим',
          false: 'Нет',
          true: 'Да'
        }
      },
      reseller: {
        $title: 'Реселлер',
        auth: {
          registration: {
            defaults: {
              title: 'Значения по умолчанию при регистрации',
              tariffs: { title: 'Тариф по умолчанию' },
              balance: { title: 'Баланс по умолчанию' },
              routingGroups: { title: 'Группа маршрутизаций по умолчанию' },
              patterns: { title: 'Паттерны' },
              sendernames: { title: 'Имена отправителей' }
            }
          }
        },
        enabled: {
          title: 'Включить настройки реселлера',
          false: 'Выключены',
          true: 'Включены'
        },
        frontend: {
          $title: 'Настройки сайта',
          title: { title: 'Заголовок сайта' },
          colors: {
            title: 'Цвета',
            primary: { title: 'Основной цвет' },
            secondary: { title: 'Второстепенный цвет' },
            accent: { title: 'Акцентный цвет' },
            info: { title: 'Информационный цвет' },
            warning: { title: 'Цвет предупреждения' },
            error: { title: 'Цвет при ошибочных действиях' },
            success: { title: 'Цвет при успешных действиях' }
          }
        },
        paymentMethods: {
          title: 'Методы оплаты',
          key: { title: 'Методы оплаты' },
          value: {
            title: '',
            key: { title: 'Настройки метода оплаты' },
            value: { title: '' }
          },
          simplepay: { title: 'SimplePay' }
        },
        billings: {
          processChildrenOperations: {
            title: 'Обрабатывать операции дочерних пользователей как свои'
          }
        }
      },
      webhooks: {
        $title: 'Webhooks',
        sendings: {
          title: 'Отправления',
          hookParameters: {
            url: { title: 'URL запроса' },
            method: { title: 'Метод запроса' },
            headers: { title: 'Заголовки запроса' },
            body: { title: 'Тело запроса' },
            statuses: {
              title: 'Мэппинг статусов',
              seen: { title: 'Просмотрено' },
              processing: { title: 'В процессе' },
              paused: { title: 'Пауза' },
              delivered: { title: 'Доставлено' },
              failed: { title: 'Не удачно' },
              pending: { title: 'В ожидании' },
              sent: { title: 'Отправлено' }
            },
            numericStatuses: { title: 'Цифровые статусы' },
            maxConnections: { title: 'Максимальное количество соединений' },
            successOn: {
              title: 'Условия успешной обработки',
              status: { title: 'Проверка статуса (regexp)' },
              body: { title: 'Проверка тела ответа (regexp)' }
            }
          }
        }
      }
    },
    permissions: {
      name: {
        create: 'Создать',
        finalize: 'Завершать',
        find: 'Найти',
        generate: 'Генерировать',
        set: 'Установить',
        get: 'Получить',
        remove: 'Удалить',
        update: 'Обновить',
        modify: 'Изменить',
        seek: 'Seek',
        download: 'Скачать',
        upload: 'Загрузить',
        setPermissions: 'Задавать права',
        setRoutingGroups: 'Задавать группы маршрутизаций',
        setSettings: 'Задавать настройки',
        setSendernames: 'Задавать имена отправителей',
        setPatterns: 'Задавать паттерны',
        setTariffs: 'Задавать тарифы',
        showSecrets: 'Показывать секретные права',
        health: 'Производительность',
        loginAs: 'Войти под',
        request: 'Запрос',
        controlDocuments: 'Работы с документами',
        edit: 'Редактировать',
        viewChangelogs: 'Просматривать список изменений',
        contracts: 'Договоры',
        companies: 'Компании',
        documentTemplates: 'Шаблоны документов',
        refund: 'Возврат платежа',
        presetUsersBalance: 'Возможность скачивать список пользователей'
      }
    },
    sendernames: {
      strict: 'Имя должно совпадать с одним из указанных ниже',
      names: 'Имена отправителей'
    },
    patterns: {
      strict: 'Сообщение должно подходить под один из этих паттернов',
      names: 'Паттерны'
    },
    target: 'нет имён отправителей | одно имя отправителя | {count} имён отправителей(я)'
  },

  dialogs: {
    new: 'Новый пользователь'
  },

  subheaders: {
    routingGroups: 'Группы маршрутизаций',
    sendings: 'Отправления',
    billing: 'Рассчетный счёт',
    websocket: 'Websocket',
    smpp: 'SMPP',
    partnership: 'Партнёрство',
    permissions: {
      billings: 'Расчётные счета',
      dictionaries: 'Словари',
      providerGroups: 'Группы провайдеров',
      providers: 'Провайдеры',
      routingGroups: 'Группы маршрутизаций',
      routingRules: 'Правила маршрутизаций',
      sendings: 'Отправления',
      storage: 'Хранилище',
      tariffs: 'Тарифы',
      users: 'Пользователи',
      platform: 'Платформа',
      reports: 'Отчеты',
      components: 'Компоненты',
      tasks: 'Задачи',
      contacts: 'Контакты',
      payment: 'Платежи',
      settings: 'Настройки сайта',
      messageProcessors: 'Обработчики сообщений',
      templates: 'Шаблоны',
      patterns: 'Паттерн',
      sendernames: 'Имена отправителей',
      requisites: 'Реквизиты',
      sendingsIncoming: 'Входящие сообщения',
      changelogs: 'Список изменений',
      contracts: 'Договоры',
      companies: 'Компании',
      documentTemplates: 'Шаблоны документов',
      operatorGroups: 'Группы операторов',
      secret: 'Секретные',
      paymentLogs: 'История платежей',
      recurrentPayments: 'Автоплатежи'
    },
    sendernames: 'Имена отправителей',
    patterns: 'Паттерны'
  },

  permissions: {
    allowAnySendername: 'Разрешены все имена отправителей',
    allowSending: 'Резрешные отправления',
    smppAsync: 'SMPP async',
    options: {
      true: 'true',
      false: 'false',
      me: 'me'
    }
  },

  tabs: {
    general: 'Основное',
    settings: 'Настройки',
    settingsUser: 'Настройки',
    permissions: 'Права',
    tariffs: 'Тарифы',
    routingGroups: 'Маршруты',
    processMessages: 'Обработка сообщений',
    requisites: 'Реквизиты',
    whatsapp: 'WhatsApp',
    recurrentPayments: 'Автоплатежи',
    referral: 'Реферальные пополнения',
    tokens: 'Токены'
  },

  warnings: {
    apiKey:
      'К сожалению, API ключ превышает допустимую длину. Попробуйте изменить имя пользователя и перезагрузить страницу или обратитесь к вашему менеджеру за помощью.'
  },

  tooltips: {
    add: {
      funds: 'Пополнить баланс'
    },
    generate: {
      document: 'Скачать документ'
    },
    copy: {
      referral: {
        code: 'Скопировать реферальный код',
        link: 'Скопировать реферальную ссылку'
      },
      apiKey: 'Скопировать API ключ'
    }
  },

  buttons: {
    generate: {
      document: 'Скачать документ'
    },
    account: 'Профиль',
    reseller: 'Реселлер',
    me: 'Пользователь',
    false: 'False'
  },

  hints: {
    title: 'Тонкая настройка'
  },

  snackbars: {
    created: 'Пользователь успешно создан.',
    updated: 'Пользователь успешно обновлён.',
    removed: 'Пользователь успешно удалён.',
    linkCopied: 'Ссылка успешно скопирована.',
    refCopied: 'Реферальный код успешно скопирован.',
    copy: {
      apiKey: 'API ключ успешно скопирован.'
    }
  }
}
