import FileManager from '@/components/file/manager'

import { FILE_TYPES } from '@/utils'

function renderFileManager(h) {
  return h(
    FileManager,
    {
      props: {
        flat: true,
        readonly: true,
        embedded: true,
        show: this.showFileManager,
        types: FILE_TYPES.csv.extensions,
        isPublic: true
      },
      on: {
        show: event => {
          this.showFileManager = event
        },
        input: event => {
          this.importCallback(event.id)
        }
      }
    }
  )
}

export default function(h) {
  return h(
    'g-button',
    {
      class: 'ma-0',
      props: {
        flat: true,
        icon: 'file_upload',
        color: 'success',
        rounded: this.fullVersion,
        label: this.fullVersion ? this.getTranslate('misc.buttons.import') : undefined,
        loading: this.loading,
        disabled: this.loading || this.disabled
      },
      on: {
        click: () => {
          this.showFileManager = true
        }
      },
      scopedSlots: {
        dialog: () => renderFileManager.call(this, h)
      }
    }
  )
}
