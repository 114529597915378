import { PAYMENTS_LOG_DIRECTION } from '@sigma-legacy-libs/essentials/lib/constants'

import TwoRowString from '@/components/misc/TwoRowString'
import SourceBox from '@/components/source/box'

import { STATES, getLocaleDateString, getLocaleTimeString } from '@/utils'

const name = 'paymentLogs'

function renderPreloader(h) {
  return h(
    'div',
    {
      class: 'fjcc facc pa-3'
    },
    [ h('g-progress', { props: { indeterminate: true } }) ]
  )
}

function renderList(h) {
  if (this.restData[name].find.state === STATES.ready) {
    if (this.restData[name].find.pagination.total > 0) {
      return h(
        'g-list',
        {},
        this.restData[name].find.data.map(item => {
          return renderItem.call(this, h, item)
        })
      )
    } else {
      return h('g-empty', { props: { title: this.getTranslate(`${name}.hints.notFound`) } })
    }
  } else if (this.restData[name].find.state === STATES.loading) {
    return renderPreloader.call(this, h)
  }
}

function renderItem(h, { id, direction, request, response, createdAt }) {
  return h(
    'g-list-item',
    {
      class: 'pl-0',
      on: {
        click: () => {
          this.request = request
          this.response = response
          this.showSourceDialog = true
        }
      }
    },
    [
      renderStatusIcon.call(this, h, direction),
      renderInfo.call(this, h, id, direction, createdAt)
    ]
  )
}

function renderStatusIcon(h, direction) {
  return h(
    'div',
    {
      class: 'fjcc facc faic square--48'
    },
    [
      h(
        'g-icon',
        {
          props: {
            value: direction === PAYMENTS_LOG_DIRECTION.incoming ? 'arrow_drop_down' : 'arrow_drop_up',
            color: direction === PAYMENTS_LOG_DIRECTION.incoming ? 'success' : 'primary'
          },
          directives: [
            {
              name: 'g-tooltip',
              options: {
                value: this.getTranslate(`${name}.directions.${direction}`),
                placement: 'top'
              }
            }
          ]
        }
      )
    ]
  )
}

function renderInfo(h, id, direction, createdAt) {
  return h(
    'div',
    {
      class: 'faic fjcsb ff caption'
    },
    [
      h(
        TwoRowString,
        {
          props: {
            first: this.getTranslate(`${name}.directions.${direction}`),
            second: id,
            length: this.viewport.breakpoint.smDown ? 20 : undefined
          }
        }
      ),

      h(
        'div',
        {
          class: 'fc faife text--grey'
        },
        [
          h('div', {}, getLocaleDateString(createdAt)),
          h('div', {}, getLocaleTimeString(createdAt))
        ]
      )
    ]
  )
}

function renderSourceDialog(h) {
  const tabs = [ 'request', 'response' ]

  return h(
    'g-dialog',
    {
      props: {
        rounded: true,
        value: this.showSourceDialog,
        maxWidth: 1000
      },
      on: {
        input: event => {
          if (event === false) {
            this.currentTab = undefined
            this.request = undefined
            this.response = undefined
          }
          this.showSourceDialog = event
        }
      }
    },
    [
      h(
        'g-tabs-header',
        {
          props: {
            value: this.currentTab,
            tabs: tabs.map(key => {
              return {
                key,
                title: this.getTranslate(`${name}.tabs.${key}`)
              }
            })
          },
          on: {
            input: event => {
              this.currentTab = event
            }
          },
          slot: 'header'
        }
      ),

      h(
        'g-tabs',
        {
          props: { value: this.currentTab }
        },
        tabs.map(key => {
          return h(
            SourceBox,
            {
              props: { value: this[key] },
              key
            }
          )
        })
      ),

      h(
        'g-card-actions',
        {
          class: 'fjcfe facfe',
          slot: 'footer'
        },
        [
          h(
            'g-button',
            {
              props: {
                label: this.getTranslate('misc.buttons.close'),
                flat: true,
                rounded: true
              },
              on: {
                click: () => {
                  this.showSourceDialog = false
                }
              }
            }
          )
        ]
      )
    ]
  )
}

export default function(h) {
  if (this.checkPermissions('advanced.paymentLogs.get')) {
    return h(
      'div',
      {
        class: 'fc ff'
      },
      [
        renderSourceDialog.call(this, h),
        renderList.call(this, h)
      ]
    )
  }
}
