import proxy from '@sigma-legacy-libs/g-proxy'

import render from './render'

export default {
  name: 'Item',

  mixins: [ proxy({ type: 'object' }) ],

  props: {
    size: {
      type: String,
      default: 'medium',
      validator: value => {
        return ~[ 'small', 'medium', 'large' ].indexOf(value)
      }
    },

    type: {
      type: String,
      default: 'card',
      validator: value => {
        return ~[ 'row', 'card' ].indexOf(value)
      }
    }
  },

  render
}
