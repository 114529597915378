import { REQUISITE_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import FileManager from '@/components/file/manager'
import OwnerSelect from '@/components/misc/OwnerSelect'

const name = 'contracts'

export default function(h) {
  return h(
    'g-form',
    {
      class: 'grid grid-cols--1 pa-3',
      on: {
        submit: () => {
          this.$emit('submit', this.proxy)
        }
      }
    },
    [
      h(
        'g-text-field',
        {
          props: {
            value: this.proxy.title,
            label: this.getTranslate(`${name}.labels.title`),
            mode: 'outline',
            rounded: true,
            details: false,
            required: true,
            clearable: true
          },
          on: {
            input: event => {
              this.proxy.title = event
            }
          }
        }
      ),

      h(
        OwnerSelect,
        {
          props: {
            value: this.proxy.OwnerId,
            mode: 'outline',
            rounded: true,
            details: false
          },
          on: {
            input: event => {
              this.proxy.OwnerId = event
            }
          }
        }
      ),

      h(
        'g-select',
        {
          props: {
            value: this.proxy.RequisiteId,
            label: this.getTranslate(`${name}.labels.requisite`),
            service: 'requisites',
            query: { type: REQUISITE_TYPES.business },
            mode: 'outline',
            rounded: true,
            details: false,
            clearable: true,
            autocomplete: true
          },
          on: {
            input: event => {
              this.proxy.RequisiteId = event
            }
          }
        }
      ),

      h(
        'g-select',
        {
          props: {
            value: this.proxy.CompanyId,
            label: this.getTranslate(`${name}.labels.company`),
            service: 'companies',
            mode: 'outline',
            rounded: true,
            details: false,
            autocomplete: true,
            clearable: true
          },
          on: {
            input: event => {
              this.proxy.CompanyId = event
            }
          }
        }
      ),

      h(
        'div',
        {
          class: 'fjcfe'
        },
        [
          h(
            'g-button',
            {
              class: 'ma-0',
              props: {
                label: this.getTranslate(`${name}.buttons.attach`),
                icon: 'attach_file',
                flat: true,
                rounded: true,
                color: 'primary'
              },
              on: {
                click: () => {
                  this.showFileManager = true
                }
              }
            }
          )
        ]
      ),

      h(
        FileManager,
        {
          props: {
            show: this.showFileManager,
            currentChosenFileId: this.proxy.FileId,
            flat: true,
            embedded: true,
            readonly: true,
            types: [ 'doc', 'docx' ]
          },
          on: {
            show: event => {
              this.showFileManager = event
            },
            input: event => {
              this.showFileManager = false
              this.proxy.FileId = event.id
            }
          }
        }
      )
    ]
  )
}
