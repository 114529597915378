import Vue from 'vue'

import { prefix } from '@/utils'

const key = serviceName => `${prefix}:favorites:${serviceName}`
const setItems = (serviceName, items) => window.localStorage.setItem(key(serviceName), JSON.stringify(items))

export default {
  namespaced: true,

  state: {
    favorites: {}
  },

  mutations: {
    refresh(state, { serviceName, items }) {
      Vue.set(state.favorites, serviceName, items)
      setItems(serviceName, items)
    },
    add(state, { serviceName, id }) {
      if (!Array.isArray(state.favorites[serviceName])) {
        Vue.set(state.favorites, serviceName, [])
      }
      state.favorites[serviceName].push(id)
      setItems(serviceName, state.favorites[serviceName])
    },
    remove(state, { serviceName, id }) {
      state.favorites[serviceName].splice(state.favorites[serviceName].findIndex(item => item === id), 1)
      setItems(serviceName, state.favorites[serviceName])
    }
  },

  actions: {
    add({ commit }, { serviceName, id }) {
      commit('add', {
        serviceName,
        id
      })
    },
    remove({ commit }, { serviceName, id }) {
      commit('remove', {
        serviceName,
        id
      })
    },
    async toggle({ dispatch }, { serviceName, id }) {
      const isFavorite = await dispatch('check', {
        serviceName,
        id
      })
      if (isFavorite) {
        dispatch('remove', {
          serviceName,
          id
        })
      } else {
        dispatch('add', {
          serviceName,
          id
        })
      }
    },
    async check({ state, dispatch }, { serviceName, id }) {
      await dispatch('refresh', serviceName)
      if (!Array.isArray(state.favorites[serviceName])) {
        return false
      }

      return !!~state.favorites[serviceName].indexOf(id)
    },
    refresh({ commit }, serviceName) {
      let items = []

      const item = window.localStorage.getItem(key(serviceName))
      if (item) {
        const ids = JSON.parse(item)
        if (Array.isArray(ids) && ids.length) {
          items = ids
        }
      }

      commit('refresh', {
        serviceName,
        items
      })
    }
  },

  getters: {
    favorites: state => state.favorites
  }
}
