import Vue from 'vue'

import schemas from '@sigma-legacy-libs/essentials/lib/schemas'

import { processSchemaKey } from '@sigma-legacy-libs/essentials/lib/utils/prism'

import { globalErrorHandler, globalErrorProcessor } from '@/utils'

const _ = { debounce: require('lodash/debounce') }

export const settingsSiteUser = options => {
  return {
    name: options.serviceName,

    data() {
      return {
        loading: {
          find: false,
          update: false
        },

        settings: undefined,

        settingKey: undefined
      }
    },

    computed: {
      computedSchema() {
        switch (options.serviceName) {
          case 'settingsSite': {
            if (this.checkPermissions(`advanced.${options.serviceName}.set`)) {
              return schemas.site.SETTINGS_SITE_SCHEMA
            } else if (this.checkPermissions(`advanced.${options.serviceName}.set`, 'reseller')) {
              return schemas.site.SETTINGS_SITE_SCHEMA_RESELLER
            }
            break
          }
          case 'settingsUser': {
            if (this.checkPermissions(`advanced.${options.serviceName}.set`)) {
              return schemas.user.SETTINGS_USER_SCHEMA
            } else if (this.checkPermissions(`advanced.${options.serviceName}.set`, 'reseller')) {
              if (this.account.id !== options.UserId) {
                return schemas.user.SETTINGS_USER_SCHEMA_RESELLER_CHILD
              }

              return schemas.user.SETTINGS_USER_SCHEMA_RESELLER
            } else if (this.checkPermissions(`advanced.${options.serviceName}.set`, 'me')) {
              return schemas.user.SETTINGS_USER_SCHEMA_ME
            }
            break
          }
        }
      }
    },

    watch: {
      $route: {
        handler() {
          this.getKeyFromQuery()
        },
        deep: true
      }
    },

    async mounted() {
      await this.findSettings()
      this.getKeyFromQuery()
    },

    methods: {
      getKeyFromQuery() {
        const { query } = this.$route
        if (query && query.settingKey) {
          this.settingKey = query.settingKey
        }
      },

      async findSettings() {
        try {
          this.loading.find = true
          const { data } = await Vue.$GRequest.find([ options.serviceName, options.UserId ].filter(item => !!item).join('/'))
          if (data) {
            this.settings = data
          }
        } catch (error) {
          globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
        } finally {
          this.loading.find = false
        }
      },

      updateSetting: _.debounce(
        async function(field, value) {
          try {
            this.loading.update = true
            value = processSchemaKey(value, field, this.computedSchema, true)
            const { data } = await Vue.$GRequest.update(options.serviceName, [ options.UserId, field ].filter(item => !!item).join('/'), { value })
            if (data) {
              this.settings[field] = data.value
              this.addSnackbar({
                type: 'success',
                text: this.getTranslate(`${options.serviceName}.snackbars.updated`)
              })
            }
          } catch (error) {
            globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
          } finally {
            this.loading.update = false
          }
        },
        1000,
        {
          leading: false,
          trailing: true
        }
      )
    }
  }
}

export default { settingsSiteUser }
