export function getRUB(value = 0) {
  if (value !== undefined) {
    value = parseFloat(value)
    if (!isNaN(value)) {
      return value.toLocaleString('ru', {
        style: 'currency',
        currency: 'RUB'
      })
    }
  }
}

export function getPrice(value = 0, currency = 'RUB') {
  if (value !== undefined) {
    value = parseFloat(value)
    if (!isNaN(value)) {
      return value.toLocaleString('ru', {
        style: 'currency',
        currency
      })
    }
  }
}

export default {
  getRUB,
  getPrice
}
