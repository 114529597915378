import tariffRates from '@/components/services/tariffRates'
import messageTypeIcon from '@/components/message/type/icon'
import defaultRules from '@/components/services/tariffs/defaultRules'
import rules from '@/components/services/tariffs/rules/readonly/rules'

import { serviceName } from '@/components/services/tariffs/utils'

function renderType(h, item) {
  const parsedType = item.type.split('.')[1]

  return h(
    'div',
    {
      class: 'grid grid-gap--8 faic ff',
      style: { 'grid-template-columns': '36px 1fr' }
    },
    [
      h(
        'div',
        {
          class: 'faic fjcc square--36'
        },
        [
          h(
            messageTypeIcon,
            {
              props: {
                value: parsedType
              }
            }
          )
        ]
      ),

      h(
        'div',
        {
          class: 'headline text-overflow'
        },
        this.getTranslate(`sendings.types.${parsedType}`)
      )
    ]
  )
}

function renderRules(h, item) {
  switch (item.source) {
    case 'data': {
      return h(rules, { props: { value: item } })
    }

    case 'data.legacy': {
      return h(defaultRules, { props: { value: item } })
    }

    case 'rates': {
      return h(
        tariffRates(item.id),
        {
          props: {
            readonly: true,
            tariffCurrency: item.currency
          }
        }
      )
    }
  }
}

function renderItem(h, item) {
  return h(
    'div',
    {
      class: `${serviceName}-account__item`
    },
    [
      renderType.call(this, h, item),
      renderRules.call(this, h, item)
    ]
  )
}

export default function(h) {
  if (Array.isArray(this.value) && this.value.length) {
    return h(
      'div',
      {
        class: `${serviceName}-account`
      },
      this.value.map(item => renderItem.call(this, h, item))
    )
  } else {
    return h(
      'g-empty',
      {
        props: {
          title: this.getTranslate(`${serviceName}.hints.notFound`)
        }
      }
    )
  }
}
