import { MODERATION_VALUES } from '@sigma-legacy-libs/essentials/lib/constants'

import { serviceName } from '@/components/services/sendernames/utils'

import { generateServices } from '@/utils'

import render from './render'

export default {
  name: 'Buttons',

  mixins: [
    generateServices({
      name: serviceName,

      find: false,
      create: false,
      remove: false
    })
  ],

  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    // https://sigmasms.slack.com/archives/D01SHR01X1R/p1656321489405479
    rejectVariants() {
      return {
        contact: 'По регистрации имени свяжитесь с нами по телефону 8 800 333 16 50',
        comment: 'В поле "Комментарий" добавьте полное наименование юридического лица и ИНН',
        length: 'Имя отправителя должно содержать от 4 до 11 латинских букв',
        common: 'Общее имя отправителя',
        international: 'Международное имя отправителя'
      }
    }
  },

  methods: {
    approve() {
      this.rest[serviceName].update({
        ...this.value,
        moderation: MODERATION_VALUES.approved,
        meta: {
          adminComment: 'Активно по Билайн и Теле2, по работе имени на МТС и Мегафон - обратитесь к своему менеджеру' // https://sigmasms.slack.com/archives/D01SHR01X1R/p1656321489405479
        }
      })
    },

    reject(adminComment) {
      const replacement = {
        moderation: MODERATION_VALUES.rejected,
        meta: { adminComment: 'Причина не указана' }
      }
      if (adminComment) {
        replacement.meta.adminComment = this.rejectVariants[adminComment]
      }
      this.rest[serviceName].update({
        ...this.value,
        ...replacement
      })
    }
  },

  render
}
