import { unVue } from '@sigma-legacy-libs/unvue'

import { attach, isArrayNotEmpty, serviceTemplate } from '@/utils'

import { serviceName } from '@/components/services/providerGroups/utils'

export default {
  mixins: [
    serviceTemplate({
      serviceName,

      inputFilter(result) {
        if (isArrayNotEmpty(result.Providers)) {
          result._Providers = unVue(result.Providers)
          result.Providers = result.Providers.map(item => {
            return {
              id: item.id,
              priority: item.priority,
              $attach: true
            }
          }).sort((a, b) => (a.priority || 0) - (b.priority || 0))
        } else {
          result.Providers = []
        }

        return result
      },
      outputFilter(result) {
        if (isArrayNotEmpty(result.Providers)) {
          attach(result, 'Providers')
        } else {
          result.Providers = undefined
        }

        return result
      }
    })
  ]
}
