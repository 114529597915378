import proxy from '@sigma-legacy-libs/g-proxy'

const _ = {
  padStart: require('lodash/padStart'),
  debounce: require('lodash/debounce')
}

export const createdAtFilter = {
  mixins: [ proxy({ type: 'object' }) ],

  computed: {
    rangePresets() {
      const time = {
        $gt: '00:00',
        $lt: '23:59'
      }
      const date = new Date()
      date.setHours(0, 0, 0, 0)

      const yesterday = new Date()
      yesterday.setDate(yesterday.getDate() - 1)
      yesterday.setHours(0, 0, 0, 0)

      const day = date.getDay()
      const diff = date.getDate() - day + (day === 0 ? -6 : 1)

      const currentWeekStart = new Date(date.setDate(diff))
      const currentMonthStart = new Date(date.setDate(1))

      const previousWeekStart = new Date(date.setDate(diff))
      previousWeekStart.setDate(previousWeekStart.getDate() - 7)
      const previousWeekEnd = new Date(date.setDate(diff))
      previousWeekEnd.setDate(previousWeekEnd.getDate() - 1)

      const previousMonthStart = new Date()
      previousMonthStart.setMonth(previousMonthStart.getMonth() - 1)
      previousMonthStart.setDate(1)
      const previousMonthEnd = new Date(previousMonthStart.getFullYear(), previousMonthStart.getMonth() + 1, 0)

      const today = new Date()
      today.setHours(0, 0, 0, 0)

      const now = Date.now()
      const dateNow = new Date(now)
      const nowTime = `${_.padStart(dateNow.getHours(), 2, '0')}:${_.padStart(dateNow.getMinutes(), 2, '0')}`

      return [
        {
          title: 'misc.dateTimeFilter.presets.yesterday',
          date: [ yesterday.getTime(), yesterday.getTime() ],
          time
        },
        {
          title: 'misc.dateTimeFilter.presets.today',
          date: [ today.getTime(), today.getTime() ],
          time: {
            $gt: time.$gt,
            $lt: nowTime
          }
        },
        {
          title: 'misc.dateTimeFilter.presets.previousWeek',
          date: [ previousWeekStart.getTime(), previousWeekEnd.getTime() ],
          time
        },
        {
          title: 'misc.dateTimeFilter.presets.currentWeek',
          date: [ currentWeekStart.getTime(), Date.now() ],
          time: {
            $gt: time.$gt,
            $lt: nowTime
          }
        },
        {
          title: 'misc.dateTimeFilter.presets.previousMonth',
          date: [ previousMonthStart.getTime(), previousMonthEnd.getTime() ],
          time
        },
        {
          title: 'misc.dateTimeFilter.presets.currentMonth',
          date: [ currentMonthStart.getTime(), Date.now() ],
          time: {
            $gt: time.$gt,
            $lt: nowTime
          }
        }
      ]
    }
  },

  created() {
    this.watchProxyHandler = _.debounce(this.watchProxyHandler, 1000, {
      leading: false,
      trailing: true
    })
  },

  methods: {
    _inputFilter(data) {
      const localDateGT = new Date(data.$gt)
      const localDateLT = new Date(data.$lt)
      const hoursGT = _.padStart(localDateGT.getHours(), 2, '0')
      const minutesGT = _.padStart(localDateGT.getMinutes(), 2, '0')
      const hoursLT = _.padStart(localDateLT.getHours(), 2, '0')
      const minutesLT = _.padStart(localDateLT.getMinutes(), 2, '0')

      return {
        date: [ localDateGT.getTime(), localDateLT.getTime() ],
        time: {
          $gt: `${hoursGT}:${minutesGT}`,
          $lt: `${hoursLT}:${minutesLT}`
        }
      }
    },

    _outputFilter(data) {
      const $gt = data.date[0] ? new Date(data.date[0]) : new Date()
      const $lt = data.date[1] ? new Date(data.date[1]) : new Date($gt)

      $gt.setHours(...data.time.$gt.split(':').map(n => parseInt(n)))
      $lt.setHours(...data.time.$lt.split(':').map(n => parseInt(n)))

      return {
        $gt: $gt.toISOString(),
        $lt: $lt.toISOString()
      }
    },

    watchProxyHandler() {
      const proxy = this._outputFilter(this.proxy)
      if (
        proxy.$gt && Date.parse(proxy.$gt) !== Date.parse(this.value.$gt) ||
        proxy.$lt && Date.parse(proxy.$lt) !== Date.parse(this.value.$lt)
      ) {
        this.transmitValue()
      }
    }
  }
}

export default { createdAtFilter }
