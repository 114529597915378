export default {
  titles: {
    export: 'Экспорт',
    filter: 'Фильтр',
    rename: 'Переименовать',
    confirm: {
      remove: 'Подверждение удаления'
    },
    data: 'Исходные данные',
    file: {
      edit: 'Редактировать файл'
    },
    warning: 'Предупреждение',
    copy: 'Скопировать',
    title: 'Заголовок',
    loading: 'Загрузка...',
    country: 'Страна',
    conditions: 'Условия'
  },

  subheader: {
    general: 'Основной раздел',
    admin: 'Административный раздел'
  },

  labels: {
    id: 'ID',
    title: 'Название',
    filter: 'Фильтр',
    search: 'Поиск',
    owner: 'Владелец',
    type: 'Тип файла',
    isActive: {
      true: 'Активен',
      false: 'Не активен'
    },
    turnOff: 'Выключить',
    turnOn: 'Включить',
    countries: 'Страны',
    operators: 'Операторы',
    contactList: 'Адресная книга',
    format: 'Формат',
    createdAt: 'Создано в',
    updatedAt: 'Обновлено в'
  },

  contents: {
    confirm: {
      remove: 'Вы уверены что хотите удалить?'
    }
  },

  filters: {
    isActive: 'Показать неактивные записи'
  },

  chips: {
    more: 'И ещё {title}'
  },

  hints: {
    platinum: 'Платиновый аккаунт',
    grafana: {
      empty: 'Данные для графика не доступны.'
    },
    unsavedData: 'У вас есть несохраненные данные на этой вкладке. Нажмите кнопку обновить чтобы сохранить их.',
    notFound: 'Ничего не найдено',
    notSpecified: 'Не указано',
    noDescription: 'Нет описания'
  },

  tooltips: {
    getReport: {
      me: 'Отчеты за период больше 31 дня доступны только для скачивания в CSV формате.',
      reseller: 'Отчеты за период больше 93 дней доступны только для скачивания в CSV формате.'
    },
    copy: 'Скопировать',
    copyLinkForSlack: 'Скопировать ссылку на эту карточку отформатированную для Slack',
    favorites: 'Добавить в избранное'
  },

  views: {
    table: 'Вид как таблица',
    cards: 'Вид как карточки'
  },

  sizes: {
    small: 'Маленький',
    medium: 'Средний',
    large: 'Большой'
  },

  snackbars: {
    tokenCopied: 'Токен скопирован',
    copied: 'Скопировано'
  },

  errors: {
    hex: 'Значение цвета должно быть в HEX формате.'
  },

  expand: {
    all: 'Раскрыть все'
  },

  collapse: {
    all: 'Свернуть все'
  },

  tabs: {
    all: 'Все',
    checked: 'Выбранные'
  },

  removed: '< УДАЛЕНО >',
  loading: 'Загрузка...'
}
