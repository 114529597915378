import Vue from 'vue'
import proxy from '@sigma-legacy-libs/g-proxy'

import { serviceName } from '@/components/services/tariffRatesUpdating/utils'

import render from './render'

export default {
  name: 'Form',

  mixins: [ proxy({ type: 'object' }) ],

  data() {
    return {
      parents: []
    }
  },

  computed: {
    validation() {
      if (!this.proxy.parentTariffId && !this.proxy.childTariffId) {
        return false
      }
      if (!this.proxy.data.default || this.proxy.data.default <= 0) {
        return false
      }

      return true
    }
  },

  watch: {
    validation() {
      this.$emit('validation', this.validation)
    }
  },

  mounted() {
    this.$emit('validation', this.validation)
    this.getParents()
  },

  methods: {
    async getParents() {
      const { data } = await Vue.$GRequest.find(serviceName)
      if (Array.isArray(data) && data.length) {
        this.parents = data.map(({ id }) => id)
      }
    }
  },

  render
}
