import proxy from '@sigma-legacy-libs/g-proxy'

import { isUUID } from '@sigma-legacy-libs/essentials/lib/validators'
import { TASK_FREQUENCY } from '@sigma-legacy-libs/essentials/lib/constants'

import { BILLINGS_SOURCES } from '@/utils'

import render from './render'

export default {
  name: 'TasksForm',

  mixins: [ proxy({ type: 'object' }) ],

  computed: {
    validation() {
      let result = true

      if (!this.proxy.title) {
        result = false
      }
      if (!isUUID(this.proxy.OwnerId)) {
        result = false
      }
      if (!~BILLINGS_SOURCES.indexOf(this.proxy.data.source)) {
        result = false
      }

      const amount = parseFloat(this.proxy.data.amount)
      if (isNaN(amount) || amount <= 0) {
        result = false
      }

      if (!~TASK_FREQUENCY.indexOf(this.proxy.data.frequency)) {
        result = false
      }

      return result
    }
  },

  watch: {
    validation() {
      this.$emit('validation', this.validation)
    }
  },

  render
}
