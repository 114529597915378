export const touch = (options = {}) => {
  options.target = options.target || document

  return {
    data() {
      return {
        xDown: undefined,
        yDown: undefined
      }
    },

    mounted() {
      if (typeof options.target.addEventListener === 'function') {
        options.target.addEventListener('touchstart', this.handleTouchStart, false)
        options.target.addEventListener('touchmove', this.handleTouchMove, false)
      }
    },

    beforeDestroy() {
      if (typeof options.target.removeEventListener === 'function') {
        options.target.removeEventListener('touchstart', this.handleTouchStart)
        options.target.removeEventListener('touchmove', this.handleTouchMove)
      }
    },

    methods: {
      handleTouchStart(event) {
        const [ touch ] = event.touches

        this.xDown = touch.clientX
        this.yDown = touch.clientY
      },

      handleTouchMove(event) {
        const touches = event.touches

        if (!this.xDown || !this.yDown || touches.length > 1) {
          return
        }

        const [ touch ] = touches

        const threshold = 50

        const xUp = touch.clientX
        const yUp = touch.clientY

        const xDiff = this.xDown - xUp
        const yDiff = this.yDown - yUp

        if (Math.abs(xDiff) > Math.abs(yDiff)) {
          if (xDiff > threshold) {
            // left swipe
            if (typeof this.leftSwipeCallback === 'function') {
              this.leftSwipeCallback()
              this.reset()
            }
          } else if (xDiff < threshold * -1) {
            // right swipe
            if (typeof this.rightSwipeCallback === 'function') {
              this.rightSwipeCallback()
              this.reset()
            }
          }
        } else {
          if (yDiff > threshold) {
            // up swipe
            if (typeof this.upSwipeCallback === 'function') {
              this.upSwipeCallback()
              this.reset()
            }
          } else if (yDiff < threshold * -1) {
            // down swipe
            if (typeof this.downSwipeCallback === 'function') {
              this.downSwipeCallback()
              this.reset()
            }
          }
        }
      },

      reset() {
        this.xDown = undefined
        this.yDown = undefined
      }
    }
  }
}

export default { touch }
