import { mapActions, mapGetters } from 'vuex'

import { isArrayNotEmpty, isStringNotEmpty } from '@/utils'

import render from './render'

const presetType = {
  doc: [
    '.doc',
    '.docx',
    'application/msword',
    'application/vnd.oasis.opendocument.text',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  ],
  csv: [ '.csv' ],
  xls: [ '.xls' ],
  xlsx: [ '.xlsx' ],
  gif: [ '.gif', 'image/gif' ],
  jpg: [ '.jpg', '.jpeg', 'image/jpeg' ],
  png: [ '.png', 'image/png' ],
  svg: [ '.svg', 'image/svg+xml' ],
  wav: [ '.wav', '.mp3' ],
  pdf: [ '.pdf' ]
}

export default {
  name: 'UploadFileButton',

  props: {
    multiple: {
      type: Boolean,
      default: false
    },
    name: {
      type: [ String, Boolean ],
      default: 'file'
    },
    accepted: [ String, Array ],

    url: {
      type: String,
      default: '/storage'
    },
    method: {
      type: String,
      default: 'POST'
    },

    maxFileSize: {
      type: Number,
      default: 20971520 // 20 mb in binary
    },

    disabled: Boolean,
    loading: Boolean,

    classes: [ String, Object, Array ],
    icon: Boolean,
    flat: Boolean,
    rounded: Boolean,
    small: Boolean,
    large: Boolean,
    depressed: Boolean,
    text: String,
    color: {
      type: String,
      default: 'primary'
    },

    buttonIcon: String,

    isPublic: Boolean,

    type: {
      type: String,
      default: 'button'
    }
  },

  data() {
    return {
      state: 'empty'
    }
  },

  computed: {
    ...mapGetters({
      uploadState: 'file/uploadState'
    }),

    types() {
      let types = []
      if (isArrayNotEmpty(this.accepted)) {
        types = this.accepted.reduce((result, type) => {
          if (presetType[type]) {
            result.push(...presetType[type])
          } else {
            result.push(type)
          }

          return result
        }, [])
      } else if (isStringNotEmpty(this.accepted)) {
        types = [ this.accepted ]
      }

      return types.length ? types.join(',') : false
    },

    computedDisabled() {
      return this.disabled || this.loading || this.uploadState === 'loading'
    }
  },

  methods: {
    ...mapActions({
      setAttributes: 'file/setAttributes',
      setNewFile: 'file/setNewFile'
    }),

    async setCurrentAttributes() {
      await this.setAttributes({
        isPublic: this.isPublic || undefined,
        multiple: this.multiple || undefined,
        name: this.name || undefined,
        types: this.types || undefined,
        url: this.url || undefined,
        method: this.method || undefined,
        maxFileSize: this.maxFileSize || undefined
      })
    },

    dragover(event) {
      if (!this.computedDisabled) {
        event.preventDefault()
        document.body.classList.add('dragover')
      }
    },

    dragleave(event) {
      if (!this.computedDisabled) {
        event.preventDefault()
        document.body.classList.remove('dragover')
      }
    },

    async drop(event) {
      if (!this.computedDisabled) {
        event.preventDefault()
        await this.setCurrentAttributes()
        this.setNewFile(event.dataTransfer.files)
        document.body.classList.remove('dragover')
      }
    }
  },

  render
}
