import proxy from '@sigma-legacy-libs/g-proxy'

import { DOCUMENT_TEMPLATES_TYPES, REQUISITE_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import { generateServices } from '@/utils'

import render from './render'

export default {
  name: 'Form',

  props: {
    allowedTypes: {
      type: Array,
      default: () => []
    },

    OwnerId: String,
    ResellerId: String
  },

  mixins: [
    proxy({ type: 'object' }),
    generateServices([
      {
        name: 'requisites',

        find: {
          useCache: true,
          defaultFilter: {
            isActive: true,
            type: REQUISITE_TYPES.business,
            OwnerId: undefined
          }
        },
        get: {
          useCache: true
        },
        update: false,
        create: false,
        remove: false,

        disableWatchers: true
      },
      {
        name: 'companies',

        find: {
          useCache: true,
          defaultFilter: {
            vat: undefined,
            $scope: [ 'documentTypes' ],
            OwnerId: undefined
          }
        },
        get: {
          useCache: true
        },
        update: false,
        create: false,
        remove: false,

        disableWatchers: true
      }
    ])
  ],

  data() {
    return {
      requisite: {
        title: undefined,
        vat: undefined
      },
      company: {
        title: undefined,
        types: []
      }
    }
  },

  computed: {
    types() {
      return DOCUMENT_TEMPLATES_TYPES.reduce((result, type) => {
        if (
          Array.isArray(this.company.types) &&
          this.company.types.length &&
          ~this.company.types.indexOf(type) &&
          (!this.allowedTypes.length || ~this.allowedTypes.indexOf(type))
        ) {
          result.push(type)
        }

        return result
      }, [])
    }
  },

  async mounted() {
    if (this.checkPermissions('advanced.requisites.get', 'me')) {
      this.restData.requisites.find.filter.OwnerId = this.account.id
    } else if (this.checkPermissions('advanced.requisites.get', [ 'reseller', true ]) && this.OwnerId) {
      this.restData.requisites.find.filter.OwnerId = this.OwnerId
    }

    if (this.checkPermissions('advanced.companies.get', [ 'reseller', true ])) {
      this.restData.companies.find.filter.OwnerId = this.ResellerId || '$global'
    }

    const requisites = await this.rest.requisites.find()
    if (Array.isArray(requisites) && requisites.length) {
      let requisite
      if (!this.proxy.requisiteId) {
        this.proxy.requisiteId = requisites[0].id
        requisite = requisites[0]
      } else {
        requisite = await this.rest.requisites.get(this.proxy.requisiteId)
      }
      this.requisite.title = requisite.title
      this.requisite.vat = requisite.data.tax.vat
    } else {
      this.proxy.requisiteId = undefined
    }
  },

  beforeDestroy() {
    this.proxy.requisiteId = undefined
    this.proxy.companyId = undefined
  },

  watch: {
    types() {
      if (this.types && this.types.length) {
        this.proxy.type = this.types[0]
      }
    },
    async 'proxy.requisiteId'() {
      const { title, data } = await this.rest.requisites.get(this.proxy.requisiteId)
      this.requisite.title = title
      this.requisite.vat = data.tax.vat
      this.proxy.companyId = undefined
      this.setVatAndGetCompanies()
    },
    'requisite.vat'() {
      if (this.restData.companies.find.filter.vat !== this.requisite.vat) {
        this.setVatAndGetCompanies()
      }
    },
    $route: {
      handler() {
        this.$emit('show', false)
      },
      deep: true
    }
  },

  methods: {
    async setVatAndGetCompanies() {
      this.restData.companies.find.filter.vat = this.requisite.vat

      const companies = await this.rest.companies.find()
      if (Array.isArray(companies) && companies.length) {
        let company
        if (!this.proxy.companyId) {
          this.proxy.companyId = companies[0].id
          company = companies[0]
        } else {
          company = await this.rest.companies.get(this.proxy.companyId, {
            query: {
              $scope: [ 'documentTypes' ]
            }
          })
        }
        this.company.title = company.title
        this.company.types = company.DocumentTemplatesTypes
      } else {
        this.proxy.companyId = undefined
      }
    }
  },

  render
}
