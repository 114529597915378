let interval

export default {
  namespaced: true,

  state: {
    paymentStep: undefined,
    date: new Date(),
    currency: 'rub'
  },

  mutations: {
    setPaymentStep(state, paymentStep) {
      state.paymentStep = paymentStep
    },

    setDate(state, date) {
      state.date = date
    }
  },

  actions: {
    setPaymentStep({ commit }, paymentStep) {
      commit('setPaymentStep', paymentStep)
    },

    startTimeUpdater({ commit }) {
      if (!interval) {
        interval = setInterval(() => {
          commit('setDate', new Date())
        }, 1000)
      }
    }
  },

  getters: {
    paymentStep: state => state.paymentStep,
    date: state => state.date,
    currency: state => state.currency
  }
}
