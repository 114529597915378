import Prism from 'prismjs'

import render from './render'

export default {
  name: 'SourceBox',

  props: {
    value: null,

    language: {
      type: String,
      default: 'json',
      validator: value => {
        return !!~[ 'json' ].indexOf(value)
      }
    },

    rounded: Boolean
  },

  data() {
    return {
      preWrap: true
    }
  },

  computed: {
    parsedSource() {
      return Prism.highlight(JSON.stringify(this.value, null, 2), Prism.languages[this.language], this.language)
    }
  },

  render
}
