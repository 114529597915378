export default {
  title: 'Moderation templates',
  pattern: 'Pattern',

  titles: {
    new: 'New moderation template',
    create: 'Create new moderation template',
    users: 'Users'
  },

  headers: {
    moderation: 'Moderation',
    title: 'Title',
    comment: 'Comment'
  },

  labels: {
    title: 'Title',
    name: 'Sender name',
    text: 'Text',
    type: 'Type',
    moderation: 'Moderation',
    owner: 'Owner',
    search: 'Search',
    id: 'ID',
    isActive: 'Activate or deactivate pattern',
    meta: {
      comment: 'Comment',
      adminComment: 'Administrator comment',
      adminCommentPrivate: 'Privat administrator comment',
      resellerComment: 'Reseller comment',
      resellerCommentPrivate: 'Privat resller comment'
    }
  },

  statuses: {
    disabled: 'Not requested',
    requested: 'Requested',
    moderation: 'Moderation',
    approved: 'Approved',
    rejected: 'Rejected'
  },

  contents: {
    confirm: {
      remove: 'Delete pattern?'
    }
  },

  errors: {
    isSms: 'Only latin characters or digists allowed, 15 symbols maximum'
  },

  buttons: {
    users: 'Users {count}'
  },

  hints: {
    emptyState: 'No one pattern found',
    notFound: 'Patterns not found'
  },

  tooltips: {
    favorites: 'Make the pattern a favorite',
    filter: {
      isActive: 'Show deactivated patterns'
    }
  },

  snackbars: {
    created: 'Moderation template successfully created.',
    updated: 'Moderation template successfully updated.',
    removed: 'Moderation template successfully removed.'
  }
}
