import { REQUISITE_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import titleDivider from '@/components/title/divider'
import ExpandArrow from '@/components/misc/ExpandArrow'
import EntityTable from '@/components/misc/EntityTable'
import RequisitesFormAddress from '@/components/services/requisites/formAddress'

import { serviceName } from '@/components/services/requisites/utils'

import { REQUISITE_OPF } from '@/utils/constants/services/requisites/_opf'

const defaultPropsForTextField = {
  mode: 'outline',
  trim: true,
  dense: true,
  rounded: true,
  clearable: true,
  details: false
}

function checkField(value, regexp, error) {
  if (value && !regexp.test(value)) {
    return error
  }

  return undefined
}

function renderValidationContent(h, state) {
  return this.getTranslate(`${serviceName}.hints.validation.state.${state}`)
}

function renderValidation(h, state) {
  return h(
    'div',
    {
      class: {
        caption: true,
        'text--success': state === true,
        'text--error': state === false,
        'text--grey': state === undefined
      }
    },
    [ renderValidationContent.call(this, h, state) ]
  )
}

function renderExpansionPanel(h, title, content, validation, permission = true) {
  if (permission) {
    return h(
      'g-expansion-panel',
      {
        scopedSlots: {
          header: ({ expanded }) => {
            return h(
              'div',
              {
                class: 'default-expansion-panel-header pr-0'
              },
              [
                h('div', {}, title),
                h('div', { class: 'ff' }),
                renderValidation.call(this, h, validation),
                h(ExpandArrow, { props: { expanded } })
              ]
            )
          },
          default: () => content
        }
      }
    )
  }
}

function renderTitleField(h) {
  return h(
    'g-text-field',
    {
      attrs: { id: `${serviceName}.form.title` },
      props: {
        value: this.proxy.title,
        label: this.getTranslate(`${serviceName}.labels.title`),
        hint: this.getTranslate(`${serviceName}.hints.title`),
        error: this.errors.title,
        mode: 'outline-label',
        trim: true,
        dense: true,
        rounded: true,
        autofocus: true,
        required: true,
        clearable: true
      },
      on: {
        input: event => {
          this.proxy.title = event
        }
      }
    }
  )
}
function renderIsActiveField(h) {
  return h(
    'g-switch',
    {
      props: { value: this.proxy.isActive },
      on: {
        input: event => {
          this.proxy.isActive = event
        }
      },
      directives: [
        {
          name: 'g-tooltip',
          options: {
            value: this.getTranslate(`${serviceName}.tooltips.isActive`),
            placement: 'left'
          }
        }
      ]
    }
  )
}

function renderTitle(h) {
  return h(
    'div',
    {
      class: 'faic grid grid-gap--8 pa-2',
      style: {
        'grid-template-columns': '1fr 36px'
      }
    },
    [
      renderTitleField.call(this, h),
      renderIsActiveField.call(this, h)
    ]
  )
}

function renderContact(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8 pa-2'
    },
    [
      h(
        'div',
        {
          class: {
            'grid grid-gap--8 faifs': true,
            'grid-cols--2': this.viewport.breakpoint.mdUp
          }
        },
        [
          h(
            'div',
            {
              class: 'grid grid-gap--8'
            },
            [
              h(
                'g-text-field',
                {
                  attrs: { id: `${serviceName}.form.contact.firstName` },
                  props: {
                    value: this.proxy.contact.firstName,
                    label: this.getTranslate(`${serviceName}.labels.contact.firstName`),
                    error: this.errors['contact.firstName'],
                    ...defaultPropsForTextField
                  },
                  on: {
                    input: event => {
                      this.proxy.contact.firstName = event
                    }
                  }
                }
              ),

              h(
                'g-text-field',
                {
                  props: {
                    value: this.proxy.contact.middleName,
                    label: this.getTranslate(`${serviceName}.labels.contact.middleName`),
                    ...defaultPropsForTextField
                  },
                  on: {
                    input: event => {
                      this.proxy.contact.middleName = event
                    }
                  }
                }
              ),

              h(
                'g-text-field',
                {
                  attrs: { id: `${serviceName}.form.contact.lastName` },
                  props: {
                    value: this.proxy.contact.lastName,
                    label: this.getTranslate(`${serviceName}.labels.contact.lastName`),
                    error: this.errors['contact.lastName'],
                    ...defaultPropsForTextField
                  },
                  on: {
                    input: event => {
                      this.proxy.contact.lastName = event
                    }
                  }
                }
              )
            ]
          ),

          h(
            'div',
            {
              class: 'grid grid-gap--8'
            },
            [
              h(
                'g-text-field',
                {
                  attrs: { id: `${serviceName}.form.contact.email` },
                  props: {
                    value: this.proxy.contact.email,
                    label: this.getTranslate(`${serviceName}.labels.contact.email`),
                    error: this.errors['contact.email'],
                    ...defaultPropsForTextField
                  },
                  on: {
                    input: event => {
                      this.proxy.contact.email = event
                    }
                  }
                }
              ),

              h(
                'g-text-field',
                {
                  attrs: { id: `${serviceName}.form.contact.phone` },
                  props: {
                    value: this.proxy.contact.phone,
                    label: this.getTranslate(`${serviceName}.labels.contact.phone`),
                    error: this.errors['contact.phone'],
                    ...defaultPropsForTextField,
                    mask: [
                      {
                        mask: '+0 000 000-00-00',
                        startsWith: '7',
                        lazy: false,
                        country: 'Russia'
                      }
                    ],
                    validateOnBlur: true
                  },
                  on: {
                    input: event => {
                      this.proxy.contact.phone = event
                    }
                  }
                }
              )
            ]
          )
        ]
      ),

      h(
        'div',
        {
          class: 'faic grid grid-gap--8',
          style: {
            'grid-template-columns': '36px 1fr'
          }
        },
        [
          h(
            'g-switch',
            {
              props: {
                value: this.contactMatches
              },
              on: {
                input: event => {
                  this.contactMatches = event
                }
              }
            }
          ),

          h(
            'div',
            {
              class: 'text--grey caption'
            },
            [ this.getTranslate(`${serviceName}.labels.contact.matches`) ]
          )
        ]
      )
    ]
  )
}

function renderSigner(h) {
  return h(
    'div',
    {
      class: {
        'grid grid-gap--8 faifs pa-2': true,
        'grid-cols--2': this.viewport.breakpoint.mdUp
      }
    },
    [
      h(
        'div',
        {
          class: 'grid grid-gap--8'
        },
        [
          h(
            'div',
            {
              class: 'fz-14 plpx-12 text--grey'
            },
            this.getTranslate(`${serviceName}.hints.nominative`) + ' *'
          ),

          h(
            'g-text-field',
            {
              props: {
                value: this.proxy.signer.fullName.nominative,
                label: this.getTranslate(`${serviceName}.labels.signer.fullName.nominative`),
                ...defaultPropsForTextField
              },
              on: {
                input: event => {
                  this.proxy.signer.fullName.nominative = event
                }
              }
            }
          ),

          h(
            'g-text-field',
            {
              props: {
                value: this.proxy.signer.position.nominative,
                label: this.getTranslate(`${serviceName}.labels.signer.position.nominative`),
                ...defaultPropsForTextField
              },
              on: {
                input: event => {
                  this.proxy.signer.position.nominative = event
                }
              }
            }
          ),

          h(
            'g-text-field',
            {
              props: {
                value: this.proxy.signer.basis.nominative,
                label: this.getTranslate(`${serviceName}.labels.signer.basis.nominative`),
                ...defaultPropsForTextField
              },
              on: {
                input: event => {
                  this.proxy.signer.basis.nominative = event
                }
              }
            }
          )
        ]
      ),
      h(
        'div',
        {
          class: 'grid grid-gap--8'
        },
        [
          h(
            'div',
            {
              class: 'fz-14 plpx-12 text--grey'
            },
            this.getTranslate(`${serviceName}.hints.genitive`) + ' *'
          ),

          h(
            'g-text-field',
            {
              props: {
                value: this.proxy.signer.fullName.genitive,
                label: this.getTranslate(`${serviceName}.labels.signer.fullName.genitive`),
                ...defaultPropsForTextField
              },
              on: {
                input: event => {
                  this.proxy.signer.fullName.genitive = event
                }
              }
            }
          ),

          h(
            'g-text-field',
            {
              props: {
                value: this.proxy.signer.position.genitive,
                label: this.getTranslate(`${serviceName}.labels.signer.position.genitive`),
                ...defaultPropsForTextField
              },
              on: {
                input: event => {
                  this.proxy.signer.position.genitive = event
                }
              }
            }
          ),

          h(
            'g-text-field',
            {
              props: {
                value: this.proxy.signer.basis.genitive,
                label: this.getTranslate(`${serviceName}.labels.signer.basis.genitive`),
                ...defaultPropsForTextField
              },
              on: {
                input: event => {
                  this.proxy.signer.basis.genitive = event
                }
              }
            }
          )
        ]
      )
    ]
  )
}

function renderLegal(h) {
  if (this.proxy.type === REQUISITE_TYPES.business) {
    return h(
      'div',
      {
        class: 'grid grid-gap--8 pa-2'
      },
      [
        h(
          'div',
          {
            class: {
              'grid grid-gap--8 faifs': true,
              'grid-cols--2': this.viewport.breakpoint.mdUp
            }
          },
          [
            h(
              'g-select',
              {
                props: {
                  value: this.proxy.data.legal.opf,
                  label: this.getTranslate(`${serviceName}.labels.data.legal.opf`),
                  hint: this.getTranslate(`${serviceName}.hints.data.legal.opf`),
                  items: REQUISITE_OPF,
                  ...defaultPropsForTextField,
                  mode: 'outline-label',
                  details: true,
                  required: true
                },
                on: {
                  input: event => {
                    this.proxy.data.legal.opf = event
                  }
                }
              }
            ),

            h(
              'g-text-field',
              {
                props: {
                  value: this.proxy.data.legal.name,
                  label: this.getTranslate(`${serviceName}.labels.data.legal.name`),
                  hint: this.getTranslate(`${serviceName}.hints.data.legal.name`),
                  ...defaultPropsForTextField,
                  mode: 'outline-label',
                  details: true,
                  required: true
                },
                on: {
                  input: event => {
                    this.proxy.data.legal.name = event
                  }
                }
              }
            ),

            h(
              'g-text-field',
              {
                props: {
                  value: this.proxy.data.legal.inn,
                  label: this.getTranslate(`${serviceName}.labels.data.legal.inn`),
                  hint: this.getTranslate(`${serviceName}.hints.data.legal.inn`),
                  error: checkField(
                    this.proxy.data.legal.inn,
                    /^\d{10,12}$/,
                    this.getTranslate(`${serviceName}.errors.inn`)
                  ),
                  ...defaultPropsForTextField,
                  mode: 'outline-label',
                  mask: '000000000000',
                  required: true,
                  pattern: '^\\d{10,12}$'
                },
                on: {
                  input: event => {
                    this.proxy.data.legal.inn = event
                  }
                }
              }
            ),

            h(
              'g-text-field',
              {
                props: {
                  value: this.proxy.data.legal.ogrn,
                  label: this.getTranslate(`${serviceName}.labels.data.legal.ogrn`),
                  hint: this.getTranslate(`${serviceName}.hints.data.legal.ogrn`),
                  error: checkField(
                    this.proxy.data.legal.ogrn,
                    /^\d{13,15}$/,
                    this.getTranslate(`${serviceName}.errors.ogrn`)
                  ),
                  ...defaultPropsForTextField,
                  mode: 'outline-label',
                  mask: '000000000000000',
                  required: true,
                  pattern: '^\\d{13,15}$'
                },
                on: {
                  input: event => {
                    this.proxy.data.legal.ogrn = event
                  }
                }
              }
            )
          ]
        ),

        h(
          'div',
          {
            class: {
              'grid faife': true,
              'grid-cols--2': this.viewport.breakpoint.mdUp
            }
          },
          [
            h(
              'g-select',
              {
                props: {
                  value: this.proxy.data.tax.system,
                  label: this.getTranslate(`${serviceName}.labels.data.tax.system`),
                  items: [ 'ОСНО', 'УСН', 'ЕНВД', 'ЕСН' ],
                  ...defaultPropsForTextField,
                  mode: 'outline-label',
                  required: true
                },
                on: {
                  input: event => {
                    this.proxy.data.tax.system = event
                  }
                }
              }
            ),

            h(
              'g-switch',
              {
                class: 'mb-2',
                props: {
                  value: this.proxy.data.tax.vat,
                  label: this.getTranslate(`${serviceName}.labels.data.tax.vat`)
                },
                on: {
                  input: event => {
                    this.proxy.data.tax.vat = event
                  }
                }
              }
            )
          ]
        ),

        h(
          titleDivider,
          {
            props: {
              value: this.getTranslate(`${serviceName}.titles.notRequired`)
            }
          }
        ),

        h(
          'div',
          {
            class: {
              'grid grid-gap--8 faifs': true,
              'grid-cols--2': this.viewport.breakpoint.mdUp
            }
          },
          [
            h(
              'g-text-field',
              {
                props: {
                  value: this.proxy.data.legal.kpp,
                  label: this.getTranslate(`${serviceName}.labels.data.legal.kpp`),
                  hint: this.getTranslate(`${serviceName}.hints.data.legal.kpp`),
                  error: checkField(
                    this.proxy.data.legal.kpp,
                    /^\d{9}$/,
                    this.getTranslate(`${serviceName}.errors.kpp`)
                  ),
                  ...defaultPropsForTextField,
                  mode: 'outline-label',
                  mask: '000000000',
                  pattern: '^\\d{9}$'
                },
                on: {
                  input: event => {
                    this.proxy.data.legal.kpp = event
                  }
                }
              }
            ),

            h(
              'g-text-field',
              {
                props: {
                  value: this.proxy.data.legal.okpo,
                  label: this.getTranslate(`${serviceName}.labels.data.legal.okpo`),
                  hint: this.getTranslate(`${serviceName}.hints.data.legal.okpo`),
                  ...defaultPropsForTextField,
                  mode: 'outline-label'
                },
                on: {
                  input: event => {
                    this.proxy.data.legal.okpo = event
                  }
                }
              }
            )
          ]
        )
      ]
    )
  }
}

function renderBank(h) {
  if (this.proxy.type === REQUISITE_TYPES.business) {
    return h(
      'div',
      {
        class: {
          'grid grid-gap--8 faifs pa-2': true,
          'grid-cols--2': this.viewport.breakpoint.mdUp
        }
      },
      [
        h(
          'g-text-field',
          {
            props: {
              value: this.proxy.data.bank.name,
              label: this.getTranslate(`${serviceName}.labels.data.bank.name`),
              ...defaultPropsForTextField,
              mode: 'outline-label',
              required: true
            },
            on: {
              input: event => {
                this.proxy.data.bank.name = event
              }
            }
          }
        ),

        h(
          'div',
          {
            class: 'grid grid-gap--8'
          },
          [
            h(
              'g-text-field',
              {
                props: {
                  value: this.proxy.data.bank.rs,
                  label: this.getTranslate(`${serviceName}.labels.data.bank.rs`),
                  error: checkField(
                    this.proxy.data.bank.rs,
                    /^\d{20}$/,
                    this.getTranslate(`${serviceName}.errors.rs`)
                  ),
                  ...defaultPropsForTextField,
                  mode: 'outline-label',
                  mask: '00000000000000000000',
                  required: true,
                  validateOnBlur: true,
                  pattern: '^\\d{20}$'
                },
                on: {
                  input: event => {
                    this.proxy.data.bank.rs = event
                  }
                }
              }
            ),

            h(
              'g-text-field',
              {
                props: {
                  value: this.proxy.data.bank.ks,
                  label: this.getTranslate(`${serviceName}.labels.data.bank.ks`),
                  error: checkField(
                    this.proxy.data.bank.ks,
                    /^\d{20}$/,
                    this.getTranslate(`${serviceName}.errors.ks`)
                  ),
                  ...defaultPropsForTextField,
                  mode: 'outline-label',
                  mask: '00000000000000000000',
                  required: true,
                  validateOnBlur: true,
                  pattern: '^\\d{20}$'
                },
                on: {
                  input: event => {
                    this.proxy.data.bank.ks = event
                  }
                }
              }
            ),

            h(
              'g-text-field',
              {
                props: {
                  value: this.proxy.data.bank.bik,
                  label: this.getTranslate(`${serviceName}.labels.data.bank.bik`),
                  error: checkField(
                    this.proxy.data.bank.bik,
                    /^\d{9}$/,
                    this.getTranslate(`${serviceName}.errors.bik`)
                  ),
                  ...defaultPropsForTextField,
                  mode: 'outline-label',
                  mask: '000000000',
                  required: true,
                  validateOnBlur: true,
                  pattern: '^\\d{9}$'
                },
                on: {
                  input: event => {
                    this.proxy.data.bank.bik = event
                  }
                }
              }
            )
          ]
        )
      ]
    )
  }
}

function renderLegalAddress(h) {
  return h(
    'div',
    {
      class: 'pa-2'
    },
    [
      h(
        RequisitesFormAddress,
        {
          props: { value: this.proxy.data.legalAddress },
          on: {
            input: event => {
              this.proxy.data.legalAddress = event
            }
          }
        }
      )
    ]
  )
}
function renderPostalAddress(h) {
  return h(
    'div',
    {
      class: 'pa-2'
    },
    [
      h(
        RequisitesFormAddress,
        {
          props: {
            value: this.proxy.data.postalAddress,
            address: this.proxy.data.legalAddress,
            matches: this.proxy.type === REQUISITE_TYPES.business
          },
          on: {
            input: event => {
              this.proxy.data.postalAddress = event
            }
          }
        }
      )
    ]
  )
}

function renderForm(h) {
  return h(
    'g-form',
    {
      class: 'grid grid-gap--8',
      on: {
        submit: () => {
          this.$emit('submit', this.proxy)
        }
      }
    },
    [
      renderTitle.call(this, h),

      h(
        'g-expansion-panels',
        {
          props: { flat: true }
        },
        [
          renderExpansionPanel.call(this, h, this.getTranslate(`${serviceName}.subheaders.contact`), renderContact.call(this, h), this.contactValidation),
          renderExpansionPanel.call(this, h, this.getTranslate(`${serviceName}.subheaders.signer`), renderSigner.call(this, h), this.signerValidation),
          renderExpansionPanel.call(this, h, this.getTranslate(`${serviceName}.subheaders.legal`), renderLegal.call(this, h), this.legalValidation),
          renderExpansionPanel.call(this, h, this.getTranslate(`${serviceName}.subheaders.bank`), renderBank.call(this, h), this.bankValidation),
          renderExpansionPanel.call(this, h, this.getTranslate(`${serviceName}.subheaders.legalAddress`), renderLegalAddress.call(this, h), this.legalAddressValidation),
          renderExpansionPanel.call(this, h, this.getTranslate(`${serviceName}.subheaders.postalAddress`), renderPostalAddress.call(this, h), this.postalAddressValidation)
        ]
      )
    ]
  )
}

function renderInfoAddress(h) {
  if (this.checkPermissions(`advanced.${serviceName}.get`)) {
    return h(
      EntityTable,
      {
        props: {
          rows: [
            {
              title: this.getTranslate(`${serviceName}.labels.country`),
              value: this.proxy.data.postalAddress.country,
              copy: true
            },
            {
              title: this.getTranslate(`${serviceName}.labels.city`),
              value: this.proxy.data.postalAddress.city,
              copy: true
            },
            {
              title: this.getTranslate(`${serviceName}.labels.index`),
              value: this.proxy.data.postalAddress.index,
              copy: true
            },
            {
              title: this.getTranslate(`${serviceName}.labels.address`),
              value: this.proxy.data.postalAddress.address,
              copy: true
            }
          ]
        }
      }
    )
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8'
    },
    [
      renderForm.call(this, h),
      renderInfoAddress.call(this, h)
    ]
  )
}
