import { classMerger } from '@/utils'

export default function(h, ctx) {
  return h(
    'div',
    {
      class: classMerger(`fjcc facc faic cursor-pointer square--${ctx.props.size}`, ctx.data.class),
      style: Object.assign(
        {
          transition: 'transform 150ms',
          transform: ctx.props.expanded ? 'rotate(180deg)' : 'rotate(0deg)'
        },
        ctx.data.style
      ),
      on: {
        click: () => {
          ctx.props.toggle()
        }
      }
    },
    [
      h(
        'g-icon',
        {
          props: {
            value: 'keyboard_arrow_down',
            color: 'grey'
          }
        }
      )
    ]
  )
}
