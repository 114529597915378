import draggable from '@/components/misc/draggable'
import tariffRates from '@/components/services/tariffRates'
import defaultRules from '@/components/services/tariffs/defaultRules'
import rules from '@/components/services/tariffs/rules/readonly/rules'
import RowWithMessageTypeIcon from '@/components/misc/RowWithMessageTypeIcon'

import { serviceName } from '@/components/services/tariffs/utils'

function renderAddTariffButton(h) {
  return h(
    'g-button',
    {
      class: 'ma-0',
      props: {
        flat: true,
        rounded: true,
        icon: 'add',
        label: this.getTranslate(`${serviceName}.add.tariff`),
        color: 'primary'
      },
      on: {
        click: () => {
          this.add()
        }
      }
    }
  )
}

function renderHeader(h) {
  return h(
    'div',
    {
      class: `${serviceName}-rules__generator-header`
    },
    [ renderAddTariffButton.call(this, h) ]
  )
}

function renderDragHandle(h) {
  return h(
    'div',
    {
      class: 'fjcc faic square--36 drag_handle'
    },
    [
      h(
        'g-icon',
        {
          props: {
            value: 'drag_handle',
            color: 'grey'
          }
        }
      )
    ]
  )
}

function renderLink(h, { id }) {
  return h(
    id ? 'router-link' : 'div',
    {
      class: {
        'fjcc faic square--36': true,
        link: !!id
      },
      props: {
        to: {
          name: 'tariffs.single',
          params: { id }
        }
      }
    },
    [
      h(
        'g-icon',
        {
          props: {
            value: 'link',
            color: 'grey'
          }
        }
      )
    ]
  )
}

function renderTariffField(h, item) {
  return h(
    'g-select',
    {
      props: {
        value: item.id,
        label: this.getTranslate(`${serviceName}.tariff`),
        itemsDisabled: this.proxy.reduce((result, tariff) => {
          if (tariff.id !== item.id) {
            result.push(tariff.id)
          }

          return result
        }, []),
        query: { $scope: [ 'Owner' ] },
        picks: [ 'id', 'title', 'type', 'Owner' ],
        service: 'tariffs',
        mode: 'outline',
        dense: true,
        rounded: true,
        details: false,
        clearable: true,
        autocomplete: true
      },
      on: {
        input: event => {
          item.id = event
        }
      },
      scopedSlots: {
        selection: ({ item }) => {
          return h(
            RowWithMessageTypeIcon,
            {
              props: {
                value: item.type.split('.')[1],
                title: item.title
              }
            }
          )
        },
        item: ({ item }) => {
          return h(
            RowWithMessageTypeIcon,
            {
              props: {
                value: item.type.split('.')[1],
                title: item.title
              }
            }
          )
        }
      }
    }
  )
}

function renderRemoveButton(h, index) {
  return h(
    'g-button',
    {
      class: 'ma-0',
      props: {
        flat: true,
        icon: 'delete',
        color: 'error'
      },
      on: {
        click: () => {
          this.remove(index)
        }
      }
    }
  )
}

function renderItemHeader(h, item, index) {
  return h(
    'div',
    {
      class: `${serviceName}-rules__generator-list-item-header`
    },
    [
      renderDragHandle.call(this, h),
      renderLink.call(this, h, item),
      renderTariffField.call(this, h, item),
      renderRemoveButton.call(this, h, index)
    ]
  )
}

function renderRules(h, item) {
  switch (item.source) {
    case 'data': {
      return h(rules, { props: { value: item } })
    }
    case 'data.legacy': {
      return h(defaultRules, { props: { value: item } })
    }
    case 'rates': {
      return h(
        tariffRates(item.id),
        {
          props: {
            readonly: true,
            tariffCurrency: item.currency
          }
        }
      )
    }
  }
}

function renderItemBody(h, item) {
  return h(
    'div',
    {
      class: `${serviceName}-rules__generator-list-item-body`
    },
    [ renderRules.call(this, h, item) ]
  )
}

function renderItem(h, item, index) {
  return h(
    'div',
    {
      class: {
        [`${serviceName}-rules__generator-list-item`]: true
      },
      key: `tariff-${index}`
    },
    [
      renderItemHeader.call(this, h, item, index),
      renderItemBody.call(this, h, item)
    ]
  )
}

function renderItems(h) {
  if (Array.isArray(this.proxy) && this.proxy.length) {
    return h(
      draggable,
      {
        class: `${serviceName}-rules__generator-list`,
        props: {
          value: this.proxy,
          handle: 'drag_handle'
        },
        on: {
          input: event => {
            this.proxy = event
          }
        },
        scopedSlots: {
          item: (item, index) => renderItem.call(this, h, item, index)
        }
      }
    )
  } else {
    return h(
      'div',
      {
        class: 'fjcc faic'
      },
      [ h('g-empty') ]
    )
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: `${serviceName}-rules__generator`
    },
    [
      renderHeader.call(this, h),
      renderItems.call(this, h)
    ]
  )
}
