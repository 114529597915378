import Vue from 'vue'

import { Macro } from '@sigma-legacy-libs/cache'

import { globalErrorHandler, globalErrorProcessor } from '@/utils'

import render from './render'

const Cache = new Macro({
  ttl: 5 * 1000,
  ttlInterval: 1000
})

const name = 'requisites'

export default {
  name: 'RequisitesButton',

  props: {
    OwnerId: {
      type: String,
      required: true
    },

    foreign: Boolean,

    title: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      show: false,

      requisites: []
    }
  },

  mounted() {
    this.getRequisites()
  },

  methods: {
    cachedFind: Cache.wrapWithCache(async (key, query) => {
      return await Vue.$GRequest.find(name, { query })
    }),
    async getRequisites() {
      if (this.checkPermissions(`advanced.${name}.get`, [ 'reseller', true ]) && this.foreign && !this.OwnerId) {
        return false
      }
      try {
        const { data } = await this.cachedFind(`${name}:${this.OwnerId}`, { OwnerId: this.OwnerId })
        if (data && Array.isArray(data.data) && data.data.length) {
          this.requisites = data.data
        }
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      }
    }
  },

  render
}
