const name = 'storage'

function wrapperForCheckPermission(permission, content, type, accept = [ 'me', 'reseller', true ]) {
  if (this.checkPermissions(`advanced.${name}.${permission}`, accept) && ~this.include.indexOf(type)) {
    return content
  }
}

function isDocOrExcel(type, content) {
  if (/(ms-?word|wordprocessingml)/.test(type) || /(ms-excel|spreadsheetml)/.test(type)) {
    return content
  }
}

export default function(h) {
  if (this.editable) {
    return h(
      'g-menu',
      {
        props: {
          disabled: this.loading,
          placement: 'bottom-end',
          offsetDistance: 16
        },
        key: `file-menu:${this.value.id}`
      },
      [
        h(
          'g-button',
          {
            class: 'ma-0',
            props: {
              icon: 'more_vert',
              flat: true,
              loading: this.loading,
              disabled: this.loading
            },
            slot: 'activator'
          }
        ),

        h(
          'g-list',
          {
            props: { dense: this.viewport.breakpoint.mdUp }
          },
          [
            isDocOrExcel.call(
              this,
              this.value.type,
              wrapperForCheckPermission.call(
                this,
                'edit',
                h(
                  'g-list-item',
                  {
                    props: {
                      icon: 'edit',
                      label: this.getTranslate('misc.buttons.edit')
                    },
                    on: {
                      click: () => {
                        this.editFile()
                      }
                    }
                  }
                ),
                'edit'
              )
            ),

            wrapperForCheckPermission.call(
              this,
              'download',
              h(
                'g-list-item',
                {
                  props: {
                    color: 'primary',
                    icon: 'cloud_download',
                    label: this.getTranslate('misc.buttons.download')
                  },
                  on: {
                    click: () => {
                      this.downloadFile()
                    }
                  }
                }
              ),
              'download'
            ),

            isDocOrExcel.call(
              this,
              this.value.type,
              wrapperForCheckPermission.call(
                this,
                'download',
                h(
                  'g-list-item',
                  {
                    props: {
                      color: 'primary',
                      icon: 'picture_as_pdf',
                      label: this.getTranslate('misc.buttons.exports.pdf')
                    },
                    on: {
                      click: () => {
                        this.exportFile()
                      }
                    }
                  }
                ),
                'export'
              )
            ),

            wrapperForCheckPermission.call(
              this,
              'update',
              h(
                'g-list-item',
                {
                  props: {
                    icon: 'info',
                    label: this.getTranslate('misc.buttons.properties')
                  },
                  on: {
                    click: () => {
                      this.editMetaFile()
                    }
                  }
                }
              ),
              'update'
            ),

            wrapperForCheckPermission.call(
              this,
              'remove',
              h(
                'g-list-item',
                {
                  props: {
                    color: 'error',
                    icon: 'delete',
                    label: this.getTranslate('misc.buttons.remove')
                  },
                  on: {
                    click: () => {
                      this.removeFile()
                    }
                  }
                }
              ),
              'remove'
            ),

            this.$slots.default
          ]
        )
      ]
    )
  }
}
