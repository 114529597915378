import proxy from '@sigma-legacy-libs/g-proxy'

import { unVue } from '@sigma-legacy-libs/unvue'

import render from './render'

const _ = { isEqual: require('lodash/isEqual') }

export default {
  name: 'RequisitesFormAddress',

  mixins: [ proxy({ type: 'object' }) ],

  props: {
    address: {
      type: Object,
      default: () => ({})
    },

    matches: Boolean
  },

  data() {
    return {
      addressMatches: false
    }
  },

  watch: {
    addressMatches() {
      if (this.addressMatches) {
        this.setAddress(unVue(this.address))
      } else {
        this.setAddress(undefined)
      }
    },

    address: {
      handler() {
        if (this.matches && this.addressMatches && !_.isEqual(this.address, this.proxy)) {
          this.proxy = unVue(this.address)
        }
      },
      deep: true
    }
  },

  methods: {
    setAddress(address) {
      this.proxy.country = this.proxy.country || address && address.country || undefined
      this.proxy.city = this.proxy.city || address && address.city || undefined
      this.proxy.index = this.proxy.index || address && address.index || undefined
      this.proxy.address = this.proxy.address || address && address.address || undefined
      this.proxy.phone = this.proxy.phone || address && address.phone || undefined
      this.proxy.phoneAdd = this.proxy.phoneAdd || address && address.phoneAdd || undefined
    }
  },

  render
}
