import Vue from 'vue'

import render from './render'

export default {
  name: 'ServicesValue',

  props: {
    value: {
      type: Array,
      default: () => []
    },
    count: {
      type: Number,
      default: 3
    },
    service: {
      type: String
    }
  },

  data() {
    return {
      loading: false,

      proxy: []
    }
  },

  computed: {
    isMany() {
      return this.value.length > this.count
    }
  },

  watch: {
    value: {
      handler() {
        this.fillData()
      },
      deep: true
    }
  },

  mounted() {
    this.fillData()
  },

  methods: {
    async fillData() {
      this.loading = true

      for (const item of this.value) {
        const result = {
          id: undefined,
          title: undefined
        }
        if (typeof item === 'string') {
          result.id = item
        } else {
          result.id = item.id
          result.title = item.title
        }
        if (!result.title) {
          const { data } = await Vue.$GRequest.get(this.service, result.id)
          result.title = data.title
        }

        this.proxy.push(result)
      }

      this.loading = false
    }
  },

  render
}
