function renderButton(h, trigger) {
  if (this.readonly) {
    return
  }

  if (trigger) {
    return h(
      'g-button',
      {
        attrs: { id: `button-${this._uid}` },
        class: 'mr-0',
        props: {
          icon: 'settings_ethernet',
          flat: true,
          color: 'primary',
          disabled: this.disabled
        },
        ref: `button-${this._uid}`,
        key: `button-${this._uid}`,
        directives: [
          {
            name: 'g-tooltip',
            options: {
              value: this.getTranslate('sendingsCreate.insertVariables'),
              placement: 'right',
              offsetDistance: 8
            }
          }
        ],
        slot: 'activator',
        on: {
          click: () => {
            this.blurInput()
            if (this.viewport.breakpoint.smDown) {
              this.showDialog = true
            }
          }
        }
      }
    )
  }
}

function renderDialogFooter(h) {
  if (this.viewport.breakpoint.smDown) {
    return h(
      'g-card-actions',
      {
        class: 'fjcfe facfe',
        slot: 'footer'
      },
      [
        h(
          'g-button',
          {
            props: {
              label: this.getTranslate('misc.buttons.close'),
              flat: true,
              rounded: true
            },
            on: {
              click: () => {
                this.showDialog = false
                this.focusInput()
              }
            }
          }
        )
      ]
    )
  }
}

function renderTagMenu(h) {
  if (this.readonly) {
    return
  }

  return h(
    this.viewport.breakpoint.smDown ? 'g-dialog' : 'g-menu',
    {
      attrs: { id: `menu-button-${this._uid}` },
      props: {
        value: this.viewport.breakpoint.smDown ? this.showDialog : undefined,
        openOnHover: !this.viewport.breakpoint.smDown,
        fullWidth: true,
        offsetOverflow: true,
        closeOnClick: true,
        closeOnContentClick: true,
        closeOnEsc: true,
        maxHeight: 300,
        minWidth: 200,
        overflowY: 'auto',
        zIndex: 900
      },
      ref: `menu-button-${this._uid}`,
      key: `menu-button-${this._uid}`
    },
    [
      renderButton.call(this, h, this.viewport.breakpoint.mdUp),

      h(
        'g-list',
        {
          props: {
            dense: this.viewport.breakpoint.mdUp,
            rounded: true
          }
        },
        this.customMenu.map((item, index) => {
          return h(
            'g-list-item',
            {
              props: { active: index === this.currentOption },
              on: {
                click: () => {
                  this.insertText(item.value)
                  if (this.viewport.breakpoint.smDown) {
                    this.showDialog = false
                  }
                }
              },
              key: `${item.value}-${index}`
            },
            this.getTranslate(item.title)
          )
        })
      ),

      renderDialogFooter.call(this, h)
    ]
  )
}

function renderPopUpMenu(h) {
  if (this.readonly) {
    return
  }

  if (!this.disabled && !this.readonly && this.viewport.breakpoint.mdUp) {
    return h(
      'g-menu',
      {
        attrs: { id: `menu-${this._uid}` },
        props: {
          value: this.showMenu,
          closeOnClick: true,
          closeOnContentClick: true,
          maxHeight: 300,
          minWidth: 200,
          zIndex: 900,
          activator: this.catchInput,
          offsetDistance: this.catchInput ? -this.catchInput.offsetHeight + this.computedPosition.top : 0,
          offsetSkidding: this.catchInput ? this.computedPosition.left : 0,
          overflowY: 'auto',
          attach: this.$refs[`holder-${this._uid}`]
        },
        ref: `menu-${this._uid}`,
        key: `menu-${this._uid}`
      },
      [
        h(
          'g-list',
          {
            props: {
              dense: this.viewport.breakpoint.mdUp,
              rounded: true
            }
          },
          this.computedMenu.map((item, index) => {
            return h(
              'g-list-item',
              {
                props: { active: index === this.currentOption },
                on: {
                  click: () => {
                    this.insertText(item.value)
                  }
                },
                key: `${item.value}-${index}`
              },
              this.getTranslate(item.title)
            )
          })
        )
      ]
    )
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: 'fr',
      style: { position: 'relative' },
      attrs: { id: `holder-${this._uid}` },
      ref: `holder-${this._uid}`,
      key: `holder-${this._uid}`
    },
    [
      h(
        this.component,
        {
          props: {
            id: `input-${this._uid}`,
            value: this.proxy,
            type: this.type,
            label: this.label,
            hint: this.hint || this.computedHint,
            mode: this.mode,
            rounded: this.rounded,
            dense: this.dense,
            disabled: this.disabled,
            readonly: this.readonly,
            required: this.required,
            maxlength: this.maxLength,
            error: this.error,
            rows: 4,
            grow: true
          },
          ref: `input-${this._uid}`,
          key: `input-${this._uid}`,
          on: {
            input: event => {
              this.proxy = event
            },
            focus: () => {
              this.setFocus()
            },
            mouseup: () => {
              this.getTagsFromText()
            },
            keyup: () => {
              this.getTagsFromText()
            },
            keydown: event => {
              if (this.section) {
                if (event.keyCode === 40) {
                  event.stopPropagation()
                  event.preventDefault()
                  this.setOption(this.currentOption + 1)
                } else if (event.keyCode === 38) {
                  event.stopPropagation()
                  event.preventDefault()
                  this.setOption(this.currentOption - 1)
                } else if (event.keyCode === 13) {
                  event.stopPropagation()
                  event.preventDefault()
                  this.insertText(this.computedMenu[this.currentOption].value)
                }
              }
            }
          }
        }
      ),

      renderButton.call(this, h, this.viewport.breakpoint.smDown),
      renderTagMenu.call(this, h),
      renderPopUpMenu.call(this, h)
    ]
  )
}
