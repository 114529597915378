import { CURRENCY } from '@sigma-legacy-libs/essentials/lib/constants'

import RowWithMessageTypeIcon from '@/components/misc/RowWithMessageTypeIcon'
import OwnerSelect from '@/components/misc/OwnerSelect'
import buttonUpDown from '@/components/button/upDown'
import newForm from '@/components/services/tariffs/form/new'
import oldForm from '@/components/services/tariffs/form/old'
import tariffRates from '@/components/services/tariffRates'
import tariffRatesUpdating from '@/components/services/tariffs/form/tariffRatesUpdating'

import { serviceName } from '@/components/services/tariffs/utils'
import { permissionPresets } from '@/utils'

function renderTypeField(h) {
  if (this.types.length) {
    return h(
      'g-select',
      {
        props: {
          value: this.proxy.type,
          label: this.getTranslate(`${serviceName}.labels.type`),
          items: this.types,
          mode: 'outline-label',
          dense: true,
          rounded: true,
          required: true,
          details: false,
          disabled: !this.isNew
        },
        scopedSlots: {
          selection: ({ item }) => {
            return h(
              RowWithMessageTypeIcon,
              {
                props: {
                  value: item.value ? item.value.split('.')[1] : undefined,
                  title: item.title
                }
              }
            )
          },
          item: ({ item }) => {
            return h(
              RowWithMessageTypeIcon,
              {
                props: {
                  value: item.value ? item.value.split('.')[1] : undefined,
                  title: item.title
                }
              }
            )
          }
        },
        on: {
          input: event => {
            this.proxy.type = event
          }
        }
      }
    )
  }
}

function renderSourceField(h) {
  if (this.sources.length) {
    return h(
      'g-select',
      {
        props: {
          value: this.proxy.source,
          label: this.getTranslate(`${serviceName}.labels.source`),
          items: this.sources,
          itemsDisabled: this.sources.reduce((result, { value, disabled }) => {
            if (disabled) {
              result.push(value)
            }

            return result
          }, []),
          mode: 'outline-label',
          dense: true,
          rounded: true,
          required: true,
          details: false,
          disabled: !this.isNew
        },
        on: {
          input: event => {
            this.proxy.source = event
          }
        }
      }
    )
  }
}

function renderTitleField(h) {
  return h(
    'g-text-field',
    {
      props: {
        value: this.proxy.title,
        label: this.getTranslate(`${serviceName}.labels.title`),
        mode: 'outline-label',
        dense: true,
        rounded: true,
        required: true,
        clearable: true,
        details: false
      },
      on: {
        input: event => {
          this.proxy.title = event
        }
      }
    }
  )
}

function renderCurrencyField(h) {
  return h(
    'g-select',
    {
      props: {
        value: this.proxy.currency,
        label: this.getTranslate(`${serviceName}.labels.currency`),
        items: CURRENCY.map(value => {
          const title = this.getTranslate(`currency.${value}.title`)

          return {
            title: title.charAt(0).toUpperCase() + title.slice(1),
            value
          }
        }),
        mode: 'outline-label',
        dense: true,
        rounded: true,
        required: true,
        details: false
      },
      on: {
        input: event => {
          this.proxy.currency = event
        }
      }
    }
  )
}

function renderFieldFromEmail(h) {
  return h(
    'g-text-field',
    {
      props: {
        value: this.proxy.data.import.fromEmail,
        label: this.getTranslate(`${serviceName}.labels.fromEmail`),
        type: 'email',
        mode: 'outline-label',
        dense: true,
        rounded: true,
        details: false
      },
      on: {
        input: event => {
          this.proxy.data.import.fromEmail = event
        }
      }
    }
  )
}
function renderFieldSearchPattern(h) {
  return h(
    'g-text-field',
    {
      props: {
        value: this.proxy.data.import.searchPattern,
        label: this.getTranslate(`${serviceName}.labels.searchPattern`),
        mode: 'outline-label',
        dense: true,
        rounded: true,
        details: false
      },
      on: {
        input: event => {
          this.proxy.data.import.searchPattern = event
        }
      }
    }
  )
}

function renderHeader(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8'
    },
    [
      h(
        'div',
        {
          class: 'grid grid-gap--8 grid-cols--2'
        },
        [
          renderTypeField.call(this, h),
          renderSourceField.call(this, h)
        ]
      ),

      h(
        'div',
        {
          class: 'grid grid-gap--8 grid-cols--2'
        },
        [
          renderTitleField.call(this, h),
          renderCurrencyField.call(this, h)
        ]
      )
    ]
  )
}

function renderContentBySource(h) {
  switch (this.proxy.source) {
    case 'data': {
      return h(
        newForm,
        {
          props: {
            value: this.proxy
          },
          on: {
            input: event => {
              this.proxy = event
            }
          }
        }
      )
    }

    case 'rates': {
      if (this.isNew) {
        return h(
          'div',
          {
            class: `${serviceName}-info`
          },
          [ this.getTranslate(`${serviceName}.hints.rates`) ]
        )
      }

      return h(
        'div',
        {
          class: 'grid grid-gap--8'
        },
        [
          h(
            'div',
            {
              class: 'grid grid-gap--8 grid-cols--2'
            },
            [
              renderFieldFromEmail.call(this, h),
              renderFieldSearchPattern.call(this, h)
            ]
          ),

          h(
            tariffRates(this.proxy.id),
            {
              props: {
                tariffCurrency: this.proxy.currency
              }
            }
          )
        ]
      )
    }

    case 'data.legacy':
    default: {
      return h(
        oldForm,
        {
          props: {
            value: this.proxy
          },
          on: {
            input: event => {
              this.proxy = event
            }
          }
        }
      )
    }
  }
}

function renderOwnerField(h) {
  return h(
    OwnerSelect,
    {
      props: {
        value: this.proxy.OwnerId,
        permission: `advanced.${serviceName}.update`,
        accept: [ true ],
        mode: 'outline-label',
        dense: true,
        rounded: true,
        details: false
      },
      on: {
        input: event => {
          this.proxy.OwnerId = event
        }
      }
    }
  )
}

function renderTariffRatesUpdating(h) {
  if (this.checkPermissions(`advanced.${serviceName}.get`, permissionPresets.admin)) {
    return h(
      tariffRatesUpdating,
      {
        props: {
          value: this.proxy
        }
      }
    )
  }
}

export default function(h) {
  return h(
    'g-form',
    {
      class: 'grid grid-gap--8',
      on: {
        validation: event => {
          this.$emit('validation', event)
        }
      }
    },
    [
      renderHeader.call(this, h),
      renderContentBySource.call(this, h),
      renderOwnerField.call(this, h),
      renderTariffRatesUpdating.call(this, h),

      h(buttonUpDown)
    ]
  )
}
