import form from '@/components/services/operatorPrices/form'
import titleDivider from '@/components/title/divider'

import { serviceName } from '@/components/services/operatorPrices/utils'
import { STATES } from '@/utils'

function renderOperator(h, operator) {
  if (this.operators[operator.operator]) {
    return h(
      'div',
      {
        class: 'grid grid-gap--8 faic',
        style: { gridTemplateColumns: '1fr 36px 36px' }
      },
      [
        h(
          form,
          {
            props: {
              value: this.operators[operator.operator],
              readonly: true
            },
            on: {
              input: event => {
                this.operators[operator.operator] = event
              }
            }
          }
        ),

        h(
          'g-button',
          {
            class: 'ma-0',
            props: {
              icon: 'delete',
              color: 'error',
              flat: true,
              disabled: this.restData[serviceName].remove.state === STATES.loading,
              loading: this.restData[serviceName].remove.state === STATES.loading
            },
            on: {
              click: () => {
                this.rest[serviceName].remove(this.operators[operator.operator].operator)
              }
            }
          }
        ),
        h(
          'g-button',
          {
            class: 'ma-0',
            props: {
              icon: 'save',
              color: 'primary',
              flat: true,
              disabled: !this.operators[operator.operator].operator || !this.operators[operator.operator].price || this.restData[serviceName].update.state === STATES.loading,
              loading: this.restData[serviceName].update.state === STATES.loading
            },
            on: {
              click: () => {
                this.rest[serviceName].update(this.operators[operator.operator].operator, this.operators[operator.operator])
              }
            }
          }
        )
      ]
    )
  }
}

function renderOperators(h) {
  if (this.restData[serviceName].find.state === STATES.ready) {
    if (this.restData[serviceName].find.data.length) {
      return this.restData[serviceName].find.data.map(operator => {
        return renderOperator.call(this, h, operator)
      })
    } else {
      return h('g-empty')
    }
  } else {
    return h(
      'g-progress',
      {
        props: {
          indeterminate: true
        }
      }
    )
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8'
    },
    [
      renderOperators.call(this, h),

      h(titleDivider, { props: { value: 'Добавить оператора' } }),

      h(
        'div',
        {
          class: 'grid grid-gap--8',
          style: { gridTemplateColumns: '1fr auto' }
        },
        [
          h(
            form,
            {
              props: {
                value: this.operator,
                itemsDisabled: Object.keys(this.operators)
              },
              on: {
                input: event => {
                  this.operator = event
                }
              }
            }
          ),

          h(
            'g-button',
            {
              class: 'ma-0',
              props: {
                depressed: true,
                rounded: true,
                icon: 'add',
                color: 'secondary',
                disabled: !this.operator.operator || !this.operator.price || this.rest[serviceName].create.state === STATES.loading,
                loading: this.rest[serviceName].create.state === STATES.loading
              },
              on: {
                click: () => {
                  this.rest[serviceName].create(this.operator)
                }
              }
            }
          )
        ]
      )
    ]
  )
}
