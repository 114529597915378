import titleDivider from '@/components/title/divider'
import generatorRow from '@/components/generator/row'
import RoutesFormTitle from '@/components/services/routes/form/title'

import { serviceName } from '@/components/services/messageRuleGroups/utils'

function renderTitleField(h) {
  return h(
    RoutesFormTitle,
    {
      props: {
        value: this.proxy,
        serviceName
      },
      on: {
        input: event => {
          this.proxy = event
        }
      }
    }
  )
}

function renderRulesField(h) {
  if (Array.isArray(this.proxy.MessageRules)) {
    return h(
      generatorRow,
      {
        props: {
          value: this.proxy.MessageRules,
          defaultValue: {
            id: undefined,
            priority: 0,
            $attach: true
          },
          sortable: true,
          sortableProperty: 'priority'
        },
        scopedSlots: {
          row: ({ item }) => {
            const attrs = {}
            if (item.id) {
              attrs.href = `#/admin/messageProcessing/messageRules/${item.id}`
            }

            return h(
              'div',
              {
                class: 'grid faic',
                style: { 'grid-template-columns': '24px 1fr' }
              },
              [
                h(
                  'a',
                  {
                    attrs,
                    class: 'link link--passive square--24'
                  },
                  [
                    h(
                      'g-icon',
                      {
                        props: {
                          value: 'link',
                          color: 'grey'
                        }
                      }
                    )
                  ]
                ),

                h(
                  'g-select',
                  {
                    props: {
                      value: item.id,
                      label: this.getTranslate(`${serviceName}.labels.rule`),
                      itemsDisabled: this.proxy.MessageRules.map(({ id }) => id),
                      service: 'messageRules',
                      query: { isActive: true },
                      clearable: true,
                      mode: 'outline',
                      rounded: true,
                      dense: true,
                      details: false,
                      autocomplete: true
                    },
                    on: {
                      input: event => {
                        item.id = event
                      }
                    }
                  }
                )
              ]
            )
          }
        },
        on: {
          input: event => {
            this.proxy.MessageRules = event
          }
        }
      }
    )
  }
}

export default function(h) {
  return h(
    'g-form',
    {
      class: 'grid',
      style: { 'grid-template-columns': '100%' },
      on: {
        submit: () => {
          this.$emit('submit', this.proxy)
        }
      }
    },
    [
      renderTitleField.call(this, h),
      h(titleDivider, { props: { value: this.getTranslate(`${serviceName}.subtitles.rules`) } }),
      renderRulesField.call(this, h)
    ]
  )
}
