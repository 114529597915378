import render from './render'

export default {
  name: 'TextEditor',

  props: {
    value: null,

    patterns: {
      type: Array,
      default: () => [
        'username',
        'email',
        'phone',
        'recipient.username',
        'recipient.email',
        'recipient.phone',
        'url',
        'domain',
        'title'
      ]
    }
  },

  computed: {
    proxy: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },

  render
}
