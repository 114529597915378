export default function(h) {
  const { expireAt } = this.value
  if (expireAt) {
    return h(
      'div',
      {
        class: 'text-overflow text--grey'
      },
      new Date(expireAt).toLocaleDateString(this.locale, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        weekday: 'long'
      })
    )
  }
}
