import { PAYMENTS_METHODS } from '@sigma-legacy-libs/essentials/lib/constants'
import { site } from '@sigma-legacy-libs/essentials/lib/schemas'

import titleDivider from '@/components/title/divider'
import HeadTitle from '@/components/misc/HeadTitle'

function renderIcon(h) {
  return h(
    'div',
    {
      class: 'faic fjcc square--48'
    },
    [
      h(
        'g-icon',
        {
          props: {
            value: 'currency_ruble',
            color: 'grey'
          }
        }
      )
    ]
  )
}

function renderTitle(h, { path, serviceName }) {
  return h(
    'div',
    {
      class: 'grid grid-gap--2'
    },
    [
      h(
        'div',
        {
          class: 'caption'
        },
        this.getTranslate(`${serviceName}.contents.${path}`)
      ),

      h(
        'div',
        {
          class: 'small text--grey'
        },
        this.getTranslate(`${serviceName}.hints.${path}`)
      )
    ]
  )
}

function renderPaymentMethods(h) {
  return PAYMENTS_METHODS.map(method => {
    const { SETTINGS_SITE_SCHEMA } = site
    const props = Object.keys(SETTINGS_SITE_SCHEMA['payments.methods'].$default[method])

    return h(
      'div',
      {
        class: 'grid grid-cols--1 grid-gap--8 pa-3'
      },
      [
        h(
          titleDivider,
          {
            props: { value: this.getTranslate(`payments.methods.${method}`) }
          }
        ),

        h(
          'div',
          {
            class: 'grid grid-cols--2 grid-gap--8 faife'
          },
          props.map(prop => {
            switch (typeof this.proxy[method][prop]) {
              case 'boolean': {
                return h(
                  'g-switch',
                  {
                    class: 'pbpx-10',
                    props: {
                      value: this.proxy[method][prop]
                    },
                    directives: [
                      {
                        name: 'g-tooltip',
                        options: {
                          value: this.getTranslate(`payments.labels.methods.${method}.${prop}`)
                        }
                      }
                    ],
                    on: {
                      input: event => {
                        this.proxy[method][prop] = event
                      }
                    }
                  }
                )
              }

              case 'string':
              default: {
                return h(
                  'g-text-field',
                  {
                    props: {
                      value: this.proxy[method][prop],
                      label: this.getTranslate(`payments.labels.methods.${method}.${prop}`),
                      mode: 'outline-label',
                      defaultValue: '',
                      dense: true,
                      rounded: true,
                      clearable: true,
                      details: false
                    },
                    on: {
                      input: event => {
                        this.proxy[method][prop] = event
                      }
                    }
                  }
                )
              }
            }
          })
        )
      ]
    )
  })
}

function renderDialog(h, { path, serviceName }) {
  return h(
    'g-dialog',
    {
      props: {
        rounded: true,
        value: this.showDialog,
        maxWidth: 400
      },
      on: {
        input: event => {
          this.showDialog = event
        }
      }
    },
    [
      h(
        HeadTitle,
        {
          props: { value: this.getTranslate(`${serviceName}.subtitles.${path}`) },
          slot: 'header'
        }
      ),

      renderPaymentMethods.call(this, h, { path }),

      h(
        'g-card-actions',
        {
          class: 'fjcfe',
          slot: 'footer'
        },
        [
          h(
            'g-button',
            {
              props: {
                label: this.getTranslate('misc.buttons.close'),
                flat: true,
                rounded: true
              },
              on: {
                click: () => {
                  this.showDialog = false
                }
              }
            }
          ),

          h(
            'g-button',
            {
              props: {
                label: this.getTranslate('misc.buttons.save'),
                flat: true,
                rounded: true,
                color: 'primary'
              },
              on: {
                click: () => {
                  this.$emit('input', this.proxy)
                  this.showDialog = false
                }
              }
            }
          )
        ]
      )
    ]
  )
}

export default function(h, options) {
  return h(
    'div',
    {
      class: 'grid grid-gap--0 faic cursor-pointer hover-background',
      style: {
        'grid-template-columns': '48px 1fr',
        border: '1px dashed rgba(0, 0, 0, 0.12)',
        'border-radius': '16px'
      },
      on: {
        click: () => {
          this.showDialog = true
        }
      }
    },
    [
      renderIcon.call(this, h),
      renderTitle.call(this, h, options),
      renderDialog.call(this, h, options)
    ]
  )
}
