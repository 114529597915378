import proxy from '@sigma-legacy-libs/g-proxy'

import render from './render'

export default function(options) {
  return {
    name: 'ReferralBillingsProcessedChildOperations',

    mixins: [ proxy({ type: 'array' }) ],

    render(h) {
      return render.call(this, h, options)
    }
  }
}
