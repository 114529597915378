import { classMerger } from '@/utils'

import OneRowString from '@/components/misc/OneRowString'

function renderSlot(ctx, slot) {
  if (ctx.scopedSlots[slot]) {
    return ctx.scopedSlots[slot]()
  }

  return ctx.$slots[slot]
}
function renderContent(h, ctx) {
  const result = []
  for (const slot of [ 'first', 'second' ]) {
    if (ctx.props[slot]) {
      result.push(
        h(
          OneRowString, {
            class: slot === 'second' ? 'text--grey' : undefined,
            props: {
              value: ctx.props[slot],
              length: ctx.props.length
            }
          }
        )
      )
    } else {
      result.push(h(OneRowString, {}, renderSlot.call(this, ctx, slot)))
    }
  }
  if (Array.isArray(result) && result.length) {
    return result
  } else {
    return [ ctx.children ]
  }
}

export default function(h, ctx) {
  return h(
    'div',
    {
      class: classMerger('fc', ctx.data.class),
      directives: ctx.data.directives
    },
    renderContent.call(this, h, ctx)
  )
}
