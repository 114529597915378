import { unVue } from '@sigma-legacy-libs/unvue'
import { mapGetters } from 'vuex'

import SendingsDetails from '@/components/services/sendings/outgoing/details'
import SendingsGroup001 from '@/components/services/sendings/outgoing/group001'
import SendingsGroup002 from '@/components/services/sendings/outgoing/group002'
import SendingsGroup003 from '@/components/services/sendings/outgoing/group003'

import { SENDINGS_OUTGOING_FILTER, currentTab, sudoData } from '@/utils'

import render from './render'

const _ = {
  isEmpty: require('lodash/isEmpty'),
  merge: require('lodash/merge')
}

export default {
  name: 'SendingsTemplate',

  components: {
    'sendings-details': SendingsDetails,
    'sendings-group001': SendingsGroup001,
    'sendings-group002': SendingsGroup002,
    'sendings-group003': SendingsGroup003
  },

  mixins: [ currentTab('outgoing'), sudoData ],

  data() {
    return {
      showFilter: false,

      filter: unVue(SENDINGS_OUTGOING_FILTER),

      preset: 'sendings.details'
    }
  },

  computed: {
    ...mapGetters({ isLoggedAs: 'login/isLoggedAs' }),

    validateUser() {
      let userPermission = this.checkPermissions('advanced.sendings.find', [ 'reseller', true ])
      if (!userPermission && this.isLoggedAs) {
        userPermission = this.checkPermissions(
          'advanced.sendings.find',
          'or',
          [ 'reseller', true ],
          JSON.parse(window.localStorage.getItem('prevAccount')).permissions
        )
      }

      return userPermission
    },

    presets() {
      return [
        {
          title: 'sendings.presets.sendings.details',
          value: 'sendings.details',
          show: true
        },
        {
          title: 'sendings.presets.sendings.group001',
          value: 'sendings.group001',
          show: true
        },
        {
          title: 'sendings.presets.sendings.group002',
          value: 'sendings.group002',
          show: true
        },
        {
          title: 'sendings.presets.sendings.group003',
          value: 'sendings.group003',
          show: this.validateUser
        }
      ]
    },

    presetNameKebab() {
      return this.preset.replace('.', '-')
    },

    tabs() {
      const result = []

      if (this.checkPermissions('advanced.sendings.find', [ 'me', 'reseller', true ])) {
        result.push('outgoing')
      }
      if (this.checkPermissions('advanced.sendingsIncoming.get', [ 'me', 'reseller', true ])) {
        result.push('incoming')
      }
      if (this.checkPermissions('advanced.tasks.find', [ 'me', 'reseller', true ])) {
        result.push('tasks')
      }

      return result
    }
  },

  watch: {
    $route: {
      handler() {
        if (!_.isEmpty(this.$route.params.filter)) {
          _.merge(this.filter, this.$route.params.filter)
          this.showFilter = true
        }
      },
      deep: true
    },

    preset() {
      this.filter = unVue(SENDINGS_OUTGOING_FILTER)
    }
  },

  render
}
