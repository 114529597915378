export default function(h) {
  return h(
    'g-chip',
    {
      props: {
        small: true,
        color: this.stateColor,
        icon: this.stateIcon,
        label: this.stateLabel
      },
      directives: this.stateTooltip
    }
  )
}
