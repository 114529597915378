export default {
  title: 'Tariffs',

  tariff: 'Tariff',
  tariffs: 'Tariffs',
  new: 'New tariff',
  create: 'Create new tariff',
  activeAt: 'Active at',
  cycle: 'Cycle',
  for: 'for',
  inherited: 'Inherited from tariff',
  withOperatorGroups: '(w/ operator groups)',
  sendingId: 'Sending ID',
  conditions: 'Conditions',
  customize: 'Customize',
  unconditional: 'Unconditional rule',
  usersOnTariff: 'Users on the tariff',
  addRule: 'Add rule',
  addSetConditions: 'Add set of conditions',
  addAnotherSetConditions: 'Add another set of conditions',
  always: 'Always',
  otherCases: 'In other cases',
  choosePath: '<Choose what to test>',
  chooseCondition: '<Set test conditions>',
  matchesWith: 'Matches',
  matchesWithRegex: 'regular expression',
  matchesWithMultiple: 'one these values',
  operator: 'no operators | one operator | {count} operators',
  sender: 'no sender names | one sender name | {count} sender names',

  account: {
    rules: {
      direction: {
        incoming: 'By incoming',
        outgoing: 'By outgoing'
      },
      rule: {
        inSession: 'in session',
        nonSession: 'non session',
        service: 'service message',
        marketing: 'marketing message',
        second: 'by second',
        minute: 'by minute',
        segment: 'by segment',
        message: 'by message'
      }
    }
  },

  per: {
    second: 'Per second',
    minute: 'Per minute',
    segment: 'Per segment'
  },

  types: {
    subscription: {
      apisender: 'Subscription API sender'
    }
  },

  sources: {
    data: {
      new: 'New format',
      legacy: 'Old format'
    },
    rates: 'Rates'
  },

  add: {
    tariff: 'Add tariff'
  },

  cycles: {
    month: 'Month',
    week: 'Week',
    day: 'Day',
    trigger: 'Trigger'
  },

  titles: {
    create: 'Create new tariff',
    defaultRule: 'The default rule will be executed if no other rules are specified or the billed message does not fit the conditions specified in the above rules.'
  },

  labels: {
    tariff: 'Tariff',
    title: 'Title',
    type: 'Type',
    source: 'Format',
    cycle: 'Per',
    cycleStart: 'Cycle start',
    price: 'Price',
    comment: 'Comment',
    owner: 'Owner',
    actions: 'Actions',
    refundOnFailed: 'Refund on failed',
    noPayments: 'May be free',
    search: 'Search',
    currency: 'Currency',
    per: 'Per',
    fromEmail: 'E-mail',
    searchPattern: 'Search pattern',

    activePeriod: {
      start: 'Start date',
      end: 'End date'
    },

    conditions: {
      path: 'Path',
      comparator: 'Comparator',
      value: 'Value',
      negate: 'Negate',
      multiple: 'Choose from the list',
      regex: 'Enter regular expression'
    },

    directions: {
      outgoing: {
        service: 'Price for service message',
        marketing: 'Price for marketing message',
        nonSession: 'Price for non session message',
        inSession: 'Price for in session message'
      },
      incoming: {
        nonSession: 'Price for non session message',
        inSession: 'Price for in session message'
      }
    }
  },

  dialogs: {
    new: 'New tariff'
  },

  subheaders: {
    rules: 'Rules',
    conditions: 'Conditions',
    activePeriod: {
      title: 'Active period',
      start: 'Active period start',
      end: 'Active period end'
    },
    cycleStart: 'Cycle start'
  },

  actions: {
    decrease: 'Decrease',
    increase: 'Increase',
    main: {
      decrease: 'Decrease',
      increase: 'Increase'
    }
  },

  directions: {
    outgoing: 'Outgoing',
    incoming: 'Incoming'
  },

  buttons: {
    add: {
      tariff: 'Add tariff',
      rule: 'Add rule',
      condition: 'Add condition'
    }
  },

  tooltips: {
    empty: {
      condition: 'Condition is not properly set up'
    },
    custom: 'Customized',
    favorites: 'Make tariff favorite'
  },

  snackbars: {
    created: 'Tariff successfully created.',
    updated: 'Tariff successfully updated.',
    removed: 'Tariff successfully removed.'
  },

  hints: {
    default: 'Per message',
    perCall: 'Per segment',
    perSecond: 'Per second',
    notFound: 'Tariffs not found',
    rates: 'Rates import will become available after creating a tariff'
  }
}
