import { mapGetters } from 'vuex'
import { MIME_TO_EXT, PUBLIC_FIELDS_USER } from '@sigma-legacy-libs/essentials/lib/constants'

import { FILE_TYPES, fillDependence, generateServices } from '@/utils'

import render from './render'

const name = 'storage'

export default {
  name: 'FileManager',

  mixins: [
    generateServices({
      name,

      async inputFilter(result) {
        if (!result.Owner) {
          await fillDependence.call(this, result, {
            service: 'users',
            permissions: [ 'reseller', true ],
            pathToId: 'OwnerId',
            pathToObject: 'Owner',
            outputPath: 'Owner',
            picks: PUBLIC_FIELDS_USER
          })
        }

        result.OwnerId = result.Owner && result.Owner.id || result.OwnerId

        return result
      },

      find: {
        defaultFilter: {
          id: undefined,
          OwnerId: undefined,
          type: [],
          $search: undefined,
          $scope: [ 'Owner' ]
        },
        defaultOrder: {
          createdAt: 'desc',
          size: undefined
        },
        defaultPagination: {
          limit: 30
        },
        async websocketFindCreatedEvent(instance) {
          if (!this.findData.filterIsEqualToDefault && !this.options.find.alwaysCreateFromWebSocket) {
            return
          }

          if (this.findData.filterIsEqualToDefault || this.options.find.alwaysCreateFromWebSocket) {
            this.findData.pagination.total++
          }

          let workingArray = this.findData.data
          let limit = this.findData.pagination.limit

          if (this.options.find.bucketEnabled) {
            workingArray = this.bucket

            limit = this.options.find.bucketMaxLength
          } else {
            instance = await this.options.inputFilter.call(this.ctx, instance)
          }

          Object.keys(FILE_TYPES).forEach(title => {
            if (title !== 'file' && ~FILE_TYPES[title].extensions.indexOf(MIME_TO_EXT[instance.type])) {
              if (this.findData.filter.type && this.findData.filter.type.length) {
                if (~this.findData.filter.type.indexOf(FILE_TYPES[title].value)) {
                  workingArray.unshift(instance)
                }
              }

              if (this.findData.filter.type && !this.findData.filter.type.length) {
                workingArray.unshift(instance)
              }
            }
          })

          if (workingArray.length > limit && !this.options.find.appendMode) {
            workingArray.splice(limit)
          }

          if (workingArray === this.bucket) {
            this.findData.bucketLength = this.bucket.length
          }
        },

        alwaysCreateFromWebSocket: true,
        alwaysUpdateFromWebSocket: true,
        alwaysRemoveFromWebSocket: true
      },
      create: false
    })
  ],

  props: {
    show: Boolean,

    currentChosenFileId: String,

    readonly: Boolean,

    embedded: {
      type: Boolean,
      default: false
    },

    isPublic: Boolean,

    maxFileSize: Number,
    name: {
      type: [ String, Boolean ],
      default: 'file'
    },

    types: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      googleFileURL: undefined,
      currentEditFile: undefined,
      editDialogType: undefined,
      showEditDialog: false,
      showLoadingArea: false,

      type: 'cards',

      searchTypes: []
    }
  },

  computed: {
    ...mapGetters({ uploadState: 'file/uploadState' }),

    computedTypes() {
      return this.types.reduce((types, type) => {
        if (typeof type === 'string' && type) {
          for (const key in FILE_TYPES) {
            const { value, extensions } = FILE_TYPES[key]
            if (key !== 'file' && ~extensions.indexOf(type) && !~types.indexOf(value)) {
              types.push(value)
            }
          }
        }
        this.searchTypes = types

        return types
      }, [])
    }
  },

  watch: {
    searchTypes: {
      handler() {
        this.search()
      },
      deep: true
    }
  },

  mounted() {
    this.search()
    this.rest[name].find()
  },

  methods: {
    search() {
      this.restData[name].find.filter.type = this.searchTypes.reduce((types, type) => {
        if (typeof type === 'string' && type) {
          for (const key in FILE_TYPES) {
            const { value, extensions } = FILE_TYPES[key]
            if (key !== 'file' && ~extensions.indexOf(type) && !~types.indexOf(value)) {
              types.push(...extensions)
            }
          }
        }

        return types
      }, [])
    }
  },

  render
}
