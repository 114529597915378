import SingleLink from '@/components/misc/SingleLink'
import title from '@/components/services/apisender/item/title'
import state from '@/components/services/apisender/item/state'
import phone from '@/components/services/apisender/item/phone'
import isPaid from '@/components/services/apisender/item/isPaid'
import buttons from '@/components/services/apisender/item/buttons'

import { serviceName, stateColors } from '@/components/services/apisender/utils'

function renderOwner(h) {
  if (this.checkPermissions(`advanced.${serviceName}.get`)) {
    return h(
      SingleLink,
      {
        class: 'small',
        props: {
          value: this.value.Owner || this.value.OwnerId
        }
      }
    )
  }
}

function renderTitle(h) {
  return h(
    'div',
    {
      class: 'grid faife',
      style: {
        'grid-template-columns': 'repeat(3, minmax(min-content, max-content))'
      }
    },
    [
      h(title, { props: { value: this.value } }),

      renderOwner.call(this, h)
    ]
  )
}

function renderDescription(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--4 tiny',
      style: {
        'grid-template-columns': 'repeat(3, minmax(min-content, max-content))'
      }
    },
    [
      h(phone, { props: { value: this.value } }),
      h(isPaid, { props: { value: this.value } })
    ]
  )
}

export default function(h) {
  return h(
    'g-card',
    {
      class: 'hover-parent hover-parent--opacity pa-2',
      props: {
        outline: true,
        rounded: true,
        accent: true,
        accentColorName: this.value.$state ? stateColors[this.value.$state] : 'grey'
      }
    },
    [
      h(
        'div',
        {
          class: 'faic'
        },
        [
          h(state, { props: { value: this.value } }),

          h(
            'div',
            {
              class: 'grid grid-cols--1 grid-gap--2 px-2 ff'
            },
            [
              renderTitle.call(this, h),
              renderDescription.call(this, h)
            ]
          ),

          h(buttons, { props: { value: this.value } })
        ]
      )
    ]
  )
}
