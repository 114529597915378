import { MODERATION_VALUES } from '@sigma-legacy-libs/essentials/lib/constants'

import { serviceName } from '@/components/services/sendernames/utils'

const _ = { get: require('lodash/get') }

function renderComment(h, comment, { value, color }) {
  const directives = []

  let slicedComment = comment.replace(new RegExp(/\n/, 'g'), ' ').slice(0, 80)
  if (comment.length > 80) {
    directives.push({
      name: 'g-tooltip',
      options: {
        value: comment,
        placement: 'top'
      }
    })
    slicedComment += '...'
  }

  return h(
    'div',
    {
      class: 'faic grid grid-gap--8',
      style: { 'grid-template-columns': '36px 1fr' },
      directives
    },
    [
      h(
        'div',
        {
          class: 'faic fjcc square--36'
        },
        [
          h(
            'g-icon',
            {
              props: {
                value,
                color
              }
            }
          )
        ]
      ),

      h(
        'div',
        {
          class: `small text--${color} word-break`
        },
        slicedComment
      )
    ]
  )
}

function renderCommentFromAdmin(h) {
  if (this.checkPermissions(`advanced.${serviceName}.get`, [ 'me', false, 'reseller' ])) {
    if (this.commentFromAdminAndReseller) {
      return renderComment.call(this, h, this.commentFromAdminAndReseller, {
        value: this.value.moderation === MODERATION_VALUES.approved ? 'info_outline' : 'error_outline',
        color: this.value.moderation === MODERATION_VALUES.approved ? 'success' : 'error'
      })
    }
  }
}

function renderCommentFromUser(h) {
  if (this.checkPermissions(`advanced.${serviceName}.get`) || this.checkPermissions(`advanced.${serviceName}.get`, 'reseller') && !this.commentFromAdminAndReseller) {
    const comment = _.get(this.value, 'meta.comment')
    if (comment) {
      return renderComment.call(this, h, comment, {
        value: 'article',
        color: 'grey'
      })
    }
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8'
    },
    [
      renderCommentFromUser.call(this, h),
      renderCommentFromAdmin.call(this, h)
    ]
  )
}
