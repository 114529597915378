import { SENDING_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import render from './render'

export default {
  name: 'MessageTypeIcon',

  props: {
    value: {
      type: String,
      default: SENDING_TYPES.sms,
      validator: value => {
        return ~[ 'accrual', 'subtract', ...SENDING_TYPES, 'apisender' ].indexOf(value)
      }
    },

    size: {
      type: Number,
      default: 24
    },

    color: String,
    translate: String
  },

  computed: {
    parsedType() {
      switch (this.value) {
        case 'apisender': {
          return 'whatsapp'
        }

        default: {
          return this.value
        }
      }
    }
  },

  render
}
