import proxy from '@sigma-legacy-libs/g-proxy'

import { isStringNotEmpty } from '@/utils'

import { REGEXP_SENDERNAME_SMS, REGEXP_SENDERNAME_VOICE, SENDING_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import { serviceName } from '@/components/services/sendernames/utils'

import render from './render'

export default {
  name: 'Form',

  mixins: [ proxy({ type: 'object' }) ],

  props: {
    errors: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    senderNameIsValid() {
      if (this.proxy.name) {
        switch (this.proxy.type) {
          case SENDING_TYPES.sms:
            return REGEXP_SENDERNAME_SMS.test(this.proxy.name)
          case SENDING_TYPES.voice:
            return REGEXP_SENDERNAME_VOICE.test(this.proxy.name)
        }

        return true
      }

      return false
    },
    validation() {
      if (this.checkPermissions(`advanced.${serviceName}.create`, 'me')) {
        return isStringNotEmpty(this.proxy.meta.comment)
      }

      return this.senderNameIsValid
    }
  },

  watch: {
    validation() {
      this.$emit('validation', this.validation)
    }
  },

  mounted() {
    this.$emit('validation', this.validation)
  },

  render
}
