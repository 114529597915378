import buttons from '@/components/services/tariffs/item/buttons'
import messageTypeIcon from '@/components/message/type/icon'

import { serviceName } from '@/components/services/tariffs/utils'

function renderIcon(h) {
  return h(
    'div',
    {
      class: 'fjcc facc faic square--36'
    },
    [
      h(
        messageTypeIcon,
        {
          props: {
            value: this.value.type.split('.')[1]
          }
        }
      )
    ]
  )
}

function renderTitleValue(h) {
  return h(
    'router-link',
    {
      class: 'body-1 text-overflow link link--passive',
      props: {
        to: {
          name: `${serviceName}.single`,
          params: { id: this.value.id }
        }
      }
    },
    this.value.title
  )
}

function renderCurrencyValue(h) {
  return h(
    'div',
    {
      class: 'small text--grey'
    },
    [ this.getTranslate(`${serviceName}.labels.currency`) + ': ' + this.getTranslate(`currency.${this.value.currency}.symbol.unicode`) ]
  )
}

function renderTitle(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--2'
    },
    [
      renderTitleValue.call(this, h),
      renderCurrencyValue.call(this, h)
    ]
  )
}

function renderButtons(h) {
  if (this.edit) {
    return h(
      buttons,
      {
        props: {
          value: this.value
        }
      }
    )
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8 faic ff',
      style: { 'grid-template-columns': '36px 1fr repeat(1, minmax(min-content, max-content))' }
    },
    [
      renderIcon.call(this, h),
      renderTitle.call(this, h),
      renderButtons.call(this, h)
    ]
  )
}
