import { createdAtFilter } from '@/utils'

import render from './render'

export default {
  name: 'PaymentsFilterCreatedAt',

  mixins: [ createdAtFilter ],

  render
}
