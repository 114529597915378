import { EMPTY_TARIFFS, SENDING_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'
import { splitRegExpStringToArray } from '@sigma-legacy-libs/essentials/lib/utils/regexp'

import Conditions from '@sigma-legacy-libs/essentials/lib/utils/Conditions'

const _ = {
  get: require('lodash/get'),
  set: require('lodash/set'),
  defaultsDeep: require('lodash/defaultsDeep'),
  toPlainObject: require('lodash/toPlainObject')
}

export const serviceName = 'tariffs'

export const sourceColors = {
  data: 'primary',
  'data.legacy': 'success',
  rates: 'secondary'
}

export function transformType(type) {
  return [ 'sendings', type ].join('.')
}

export function transformTypes(types, source) {
  return types.map(type => {
    const subscription = 'subscription.apisender'

    let pathToTranslate = `sendings.types.${type}`
    let value = `sendings.${type}`

    if (type === subscription) {
      pathToTranslate = `${serviceName}.types.${subscription}`
      value = subscription
    }

    const title = this.getTranslate(pathToTranslate)

    let disabled = true
    switch (source) {
      case 'data': {
        switch (type) {
          case SENDING_TYPES.viber:
          case SENDING_TYPES.vk:
          case SENDING_TYPES.voice:
          case SENDING_TYPES.whatsapp: {
            disabled = false
            break
          }
        }
        break
      }
      case 'data.legacy': {
        disabled = false
        break
      }
      case 'rates': {
        switch (type) {
          case SENDING_TYPES.sms: {
            disabled = false
            break
          }
        }
        break
      }
    }

    return {
      title,
      value,
      disabled
    }
  })
}

export function setPrice(price, type) {
  if (!Array.isArray(price)) {
    price = [ price ]
  }

  price.push(0)

  switch (type) {
    case SENDING_TYPES.voice:
      price.length = 2
      break
    default:
      price.splice(1, price.length)
      break
  }

  return price
}

export function extractDefaultRule(rules, type) {
  const { conditions, action, price } = rules[rules.length - 1] || {}
  if (Array.isArray(conditions) && !conditions.length) {
    rules.pop()

    return {
      action,
      price: setPrice(price, type)
    }
  }
}

export function ruleProcessor(rule, tagOrPath) {
  let result

  const tagMeta = Conditions.tagsMeta[tagOrPath]

  if (tagMeta) {
    switch (tagMeta.tag) {
      case 'text':
      case 'recipient': {
        result = typeof rule === 'string' ? rule : ''
        break
      }
      case 'type':
      case 'group':
      case 'sender':
      case 'keywords':
      case 'contactList': {
        if (Array.isArray(rule) && rule.length) {
          result = rule
          break
        }
        let rules = []
        if (typeof rule === 'string') {
          rules = splitRegExpStringToArray(rule)
        }
        result = rules
        break
      }
      case 'mccmnc': {
        result = _.toPlainObject(rule)
        break
      }
    }
  }

  return result
}

export function rulesToGroup(rules = [], type) {
  const result = []

  if (Array.isArray(rules) && rules.length) {
    rules.sort((a, b) => a.priority - b.priority)

    let lastKey = ''

    for (let { action, price, conditions } of rules) {
      const rule = { conditions: [] }

      price = setPrice(price, type)

      const key = `${action}_${price.toString()}`

      if (conditions) {
        rule.conditions = conditions.reduce((accumulator, { tag, path, value }) => {
          const meta = Conditions.tagsMeta[tag || path]
          const rule = ruleProcessor(value, meta.tag)
          if (rule) {
            accumulator.push({
              tag: meta.tag,
              value: rule
            })
          }

          return accumulator
        }, [])
      }

      if (key === lastKey) {
        result[result.length - 1].rules.push(rule)
      } else {
        result.push({
          action,
          price,
          rules: [ rule ]
        })
      }

      lastKey = key
    }
  }

  return result
}
export function groupToRules(groups = []) {
  const result = []

  if (Array.isArray(groups) && groups.length) {
    for (const { action, price, rules } of groups) {
      if (Array.isArray(rules) && rules.length) {
        for (const { conditions } of rules) {
          result.push({
            action,
            price,
            priority: result.length,
            conditions: conditions.reduce((result, { tag, path, value }) => {
              const meta = Conditions.tagsMeta[tag || path]
              const rule = ruleProcessor(value, meta.tag)
              if (rule) {
                result.push({
                  tag: meta.tag,
                  value: rule
                })
              }

              return result
            }, [])
          })
        }
      }
    }
  }

  return result
}

export function userTariffsInputFilter(tariff = {}) {
  const { id, title, type, source, currency, data = {}, priority = 0 } = tariff

  const result = {
    $attach: true,
    id,
    title,
    type,
    source,
    currency,
    data,
    priority
  }

  const parsedType = type ? type.split('.')[1] : undefined

  switch (source) {
    case 'data': {
      result._rules = []

      for (const direction in data) {
        switch (parsedType) {
          case SENDING_TYPES.viber: {
            if (direction === 'outgoing') {
              for (const rule in data[direction]) {
                result._rules.push({
                  price: data[direction][rule].price,
                  direction,
                  rule
                })
              }
            } else {
              result._rules.push({
                price: data[direction].price,
                direction
              })
            }

            break
          }
          case SENDING_TYPES.vk: {
            result._rules.push({
              price: data[direction].price,
              direction
            })

            break
          }
          case SENDING_TYPES.voice: {
            if (direction === 'outgoing') {
              result._rules.push({
                price: data[direction].price,
                direction,
                rule: data[direction].per
              })
            } else {
              result._rules.push({
                price: data[direction].price,
                direction
              })
            }

            break
          }
          case SENDING_TYPES.whatsapp: {
            for (const rule in data[direction]) {
              result._rules.push({
                price: data[direction][rule].price,
                direction,
                rule
              })
            }

            break
          }
        }
      }

      break
    }

    case 'data.legacy': {
      const { rules = [] } = data

      if (Array.isArray(rules) && rules.length) {
        const _defaultRule = extractDefaultRule(rules, parsedType)
        if (_defaultRule) {
          result._defaultRule = _defaultRule
        }

        result._defaultRules = rulesToGroup(rules, parsedType)
      }

      break
    }
  }

  return result
}
export function userTariffsOutputFilter(tariff) {
  const { $attach, id, title, type, source, currency, data = {}, priority = 0 } = tariff

  const result = {
    $attach: $attach === undefined || !!$attach,
    id,
    title,
    type,
    source,
    currency,
    data,
    priority
  }

  if (source === 'data.legacy') {
    const { rules = [] } = data

    if (Array.isArray(rules) && rules.length) {
      result.data.rules = groupToRules(rules)
    }
  }

  return result
}

export function dataFilter(tariff = {}, clear = false) {
  if (clear) {
    tariff.data = {}
  }

  switch (tariff.source) {
    case 'rates': {
      tariff.data = {
        import: {
          fromEmail: undefined,
          searchPattern: undefined
        }
      }
      break
    }
    case 'data.legacy': {
      _.defaultsDeep(tariff.data, {
        rules: [],
        options: {
          refundOnFailed: false,
          noPayments: false
        },
        hidden: {
          comment: undefined
        }
      })
      break
    }

    case 'data':
    default: {
      const paths = []
      switch (tariff.type) {
        case transformType(SENDING_TYPES.vk): {
          paths.push(
            'data.outgoing.price',
            'data.incoming.price'
          )
          break
        }
        case transformType(SENDING_TYPES.voice): {
          paths.push(
            'data.outgoing.price',
            'data.outgoing.per',
            'data.incoming.price'
          )
          break
        }
        case transformType(SENDING_TYPES.whatsapp): {
          paths.push(
            'data.outgoing.nonSession.price',
            'data.outgoing.inSession.price',
            'data.incoming.nonSession.price',
            'data.incoming.inSession.price'
          )
          break
        }

        case transformType(SENDING_TYPES.viber):
        default: {
          paths.push(
            'data.outgoing.service.price',
            'data.outgoing.marketing.price',
            'data.incoming.price'
          )
          break
        }
      }

      for (const path of paths) {
        _.set(tariff, path, _.get(tariff, path))
      }

      break
    }
  }

  return tariff
}

export function inputFilter(tariff = {}) {
  _.defaultsDeep(tariff, EMPTY_TARIFFS)

  dataFilter(tariff)

  if (tariff.source === 'data.legacy') {
    const rules = tariff.data.rules || []
    if (Array.isArray(rules) && rules.length) {
      const type = tariff.type ? tariff.type.split('.')[1] : undefined
      const _defaultRule = extractDefaultRule(tariff.data.rules, type)
      if (_defaultRule) {
        tariff._defaultRule = _defaultRule
      }

      tariff.data.rules = rulesToGroup(rules, type)
    }
  }

  tariff.OwnerId = tariff.Owner && tariff.Owner.id || tariff.OwnerId

  return tariff
}
export function outputFilter(tariff = {}) {
  const { source, data = {}, _defaultRule } = tariff

  if (source === 'data.legacy') {
    const { rules = [] } = data

    if (rules.length) {
      tariff.data.rules = groupToRules(rules)
    } else {
      tariff.data.rules = []
    }

    if (_defaultRule) {
      const { action, price } = _defaultRule
      tariff.data.rules.push({
        action,
        price,
        priority: rules.length,
        conditions: []
      })

      delete tariff._defaultRule
    }
  }

  return tariff
}

export default {
  serviceName,
  sourceColors,

  transformType,
  transformTypes,

  setPrice,
  extractDefaultRule,
  ruleProcessor,
  rulesToGroup,
  groupToRules,
  userTariffsInputFilter,
  userTariffsOutputFilter,
  dataFilter,
  inputFilter,
  outputFilter
}
