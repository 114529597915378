import { serviceName } from '@/components/services/apisender/utils'

const _ = { get: require('lodash/get') }

export default function(h) {
  return h(
    'div',
    {
      class: 'text--grey text-nowrap'
    },
    [ _.get(this.value, 'phone') || this.getTranslate(`${serviceName}.hints.phoneNotFound`) ]
  )
}
