import render from './render'

export default {
  name: 'DefaultRules',

  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    type() {
      return this.value.type ? this.value.type.split('.')[1] : undefined
    }
  },

  render
}
