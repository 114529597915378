import { TASK_STATES } from '@sigma-legacy-libs/essentials/lib/constants'

import OwnerSelect from '@/components/misc/OwnerSelect'
import DateTimeFilter from '@/components/misc/DateTimeFilter'
import RowWithMessageTypeIcon from '@/components/misc/RowWithMessageTypeIcon'

import { permissionPresets } from '@/utils'

function renderFieldSendingsType(h) {
  return h(
    'g-select',
    {
      props: {
        value: this.proxy.data.sending.type,
        label: this.getTranslate('tasks.labels.type'),
        items: this.getSendingTypesByPermission('advanced.tasks.find').map(item => ({
          title: this.getTranslate(item.title),
          value: item.value
        })),
        clearable: true,
        mode: 'outline',
        dense: true,
        rounded: true,
        details: false
      },
      scopedSlots: {
        selection: ({ item }) => {
          return h(
            RowWithMessageTypeIcon,
            {
              props: {
                value: item.value,
                title: item.title
              }
            }
          )
        },
        item: ({ item }) => {
          return h(
            RowWithMessageTypeIcon,
            {
              props: {
                value: item.value,
                title: item.title
              }
            }
          )
        }
      },
      on: {
        input: event => {
          this.proxy.data.sending.type = event
        }
      }
    }
  )
}

function renderFieldContactList(h) {
  return h(
    'g-select',
    {
      props: {
        value: this.proxy.ListId,
        label: this.getTranslate('tasks.labels.contactLists'),
        service: 'contactLists',
        clearable: true,
        mode: 'outline',
        dense: true,
        rounded: true,
        details: false
      },
      on: {
        input: event => {
          this.proxy.ListId = event
        }
      }
    }
  )
}

function renderFieldState(h) {
  return h(
    'g-select',
    {
      props: {
        value: this.proxy.state,
        label: this.getTranslate('tasks.labels.state'),
        items: TASK_STATES.map(state => {
          return {
            title: this.getTranslate(`tasks.states.${state}`),
            value: state
          }
        }),
        clearable: true,
        mode: 'outline',
        dense: true,
        rounded: true,
        details: false
      },
      on: {
        input: event => {
          this.proxy.state = event
        }
      }
    }
  )
}

function renderFirstColumn(h) {
  if (this.checkPermissions('advanced.users.find', permissionPresets.reseller)) {
    return h(
      OwnerSelect,
      {
        props: {
          value: this.proxy.OwnerId,
          mode: 'outline',
          dense: true,
          rounded: true,
          details: false
        },
        on: {
          input: event => {
            this.proxy.OwnerId = event
          }
        }
      }
    )
  }
}

function renderSecondColumn(h) {
  if (~this.type.indexOf('sendings:bulk')) {
    return h(
      'div',
      {
        class: 'grid grid-cols--1 grid-gap--8'
      },
      [
        renderFieldSendingsType.call(this, h),
        renderFieldContactList.call(this, h)
      ]
    )
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: {
        'grid grid-gap--8 px-2': true,
        'grid-cols--4': this.viewport.breakpoint.lgUp,
        'grid-cols--3': this.viewport.breakpoint.md,
        'grid-cols--2': this.viewport.breakpoint.sm,
        'grid-cols--1': this.viewport.breakpoint.xs
      }
    },
    [
      renderFirstColumn.call(this, h),
      renderSecondColumn.call(this, h),

      renderFieldState.call(this, h),

      h(
        'div',
        {
          class: 'fc faic'
        },
        [
          h(
            DateTimeFilter,
            {
              props: { value: this.proxy.createdAt },
              on: {
                input: event => {
                  this.proxy.createdAt = event
                }
              }
            }
          ),

          h(
            'g-button',
            {
              props: {
                label: this.getTranslate('misc.filter.clear'),
                flat: true,
                rounded: true
              },
              on: {
                click: () => {
                  this.clearFilter()
                }
              }
            }
          )
        ]
      )
    ]
  )
}
