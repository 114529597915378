import render from './render'

import { balance } from '@/utils'

export default {
  name: 'ButtonAccount',

  mixins: [ balance() ],

  data() {
    return {
      show: false
    }
  },

  render
}
