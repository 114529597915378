import Vue from 'vue'

import proxy from '@sigma-legacy-libs/g-proxy'

import { DICTIONARIES, DICTIONARIES_STATUSES } from '@sigma-legacy-libs/essentials/lib/constants'

import { generateServices, globalErrorHandler, globalErrorProcessor } from '@/utils'

import render from './render'

import { serviceName } from '../../services/operatorGroups/utils'

const _ = { isEqual: require('lodash/isEqual') }

export default {
  name: 'OperatorGroupsTree',

  mixins: [
    proxy({ type: 'array' }),
    generateServices([
      {
        name: serviceName,

        find: {
          defaultFilter: {
            DictionaryId: undefined,
            $search: undefined,
            $scope: [ 'GroupByGroup', 'available' ]
          },
          defaultPagination: {
            limit: 300
          },
          defaultOrder: { group: 'asc' },
          appendMode: true
        },

        get: false,
        update: false,
        create: false,
        remove: false
      },
      {
        name: 'dictionaries',

        find: {
          defaultFilter: {
            type: DICTIONARIES['phone:opgroups'],
            status: DICTIONARIES_STATUSES.activated
          }
        },

        get: false,
        update: false,
        create: false,
        remove: false
      }
    ])
  ],

  props: {
    readonly: Boolean
  },

  data() {
    return {
      DictionaryId: undefined,

      groups: {},
      groupsState: {},

      searchValue: undefined,

      tab: 'all'
    }
  },

  computed: {
    checkedGroups() {
      return this.proxy.filter(item => {
        if (this.searchValue) {
          return ~(item + '').toLowerCase().indexOf((this.searchValue + '').toLowerCase())
        }

        return true
      })
    }
  },

  watch: {
    tab() {
      this.search(this.searchValue)
    }
  },

  mounted() {
    this.search()
  },

  methods: {
    _inputFilter(data) {
      if (!Array.isArray(data)) {
        data = []
      }

      return data
    },

    watchProxyHandler() {
      if (!_.isEqual(this.proxy, this.value)) {
        this.transmitValue()
      }
    },

    async getDictionary() {
      try {
        if (this.DictionaryId) {
          return this.DictionaryId
        } else {
          const dictionaries = await this.rest.dictionaries.find()
          if (Array.isArray(dictionaries) && dictionaries.length) {
            this.DictionaryId = dictionaries[0].id

            return this.DictionaryId
          }

          throw new Error('Dictionary with operator groups not found')
        }
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      }
    },

    async search(search) {
      try {
        this.searchValue = search
        switch (this.tab) {
          case 'all': {
            if (this.DictionaryId) {
              if (search) {
                Vue.set(this.restData[serviceName].find.filter, '$search', search)
              } else {
                Vue.delete(this.restData[serviceName].find.filter, '$search')
                await this.rest[serviceName].find()
              }
            } else {
              if (await this.getDictionary()) {
                this.search(search)
              }
            }
            break
          }
        }
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      }
    }
  },

  render
}
