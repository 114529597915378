import Vue from 'vue'

import { unVue } from '@sigma-legacy-libs/unvue'

import { allowedSendingTypes, generateServices, globalErrorHandler, globalErrorProcessor } from '@/utils'

import { ruleProcessor } from '@/components/services/tariffs/utils'
import { serviceName } from '@/components/services/routingGroups/utils'

import render from './render'

const _ = {
  get: require('lodash/get'),
  isEqual: require('lodash/isEqual')
}

const services = {
  routingGroups: {
    association: 'RoutingRules',
    nextService: 'routingRules',
    hasDeep: true
  },
  routingRules: {
    association: 'ProviderGroups',
    nextService: 'providerGroups',
    hasDeep: true
  },
  providerGroups: {
    association: 'Providers',
    nextService: 'providers',
    hasDeep: true
  },
  providers: {
    hasDeep: false
  }
}

export default {
  name: 'RoutesTree',

  mixins: [
    generateServices([
      {
        name: 'routingGroups',

        find: false,
        create: false,
        update: false,
        remove: false
      },
      {
        name: 'routingRules',

        find: false,
        create: false,
        update: false,
        remove: false
      },
      {
        name: 'providerGroups',

        find: false,
        create: false,
        update: false,
        remove: false
      }
    ])
  ],

  props: {
    value: {
      type: Object,
      default: () => ({})
    },

    highlight: {
      type: Object,
      default: () => ({})
    },

    type: String,
    readonly: Boolean
  },

  data() {
    const showDialog = {
      full: {},
      direct: {}
    }
    const showAddRouteMenu = {}

    const RoutingGroups = {}
    const initRoutingGroups = {}
    for (const type of allowedSendingTypes) {
      showDialog.full[type] = false
      showDialog.direct[type] = false
      showAddRouteMenu[type] = false

      RoutingGroups[type] = []
      initRoutingGroups[type] = []
    }

    return {
      showDialog,
      showAddRouteMenu,
      showConfirmRemoveMenu: {},

      routeSelector: undefined, // Переменная для временного хранения выбранного маршрута

      rowState: {}, // closed, opened, loading

      RoutingGroups,
      initRoutingGroups,

      RoutingRules: {},
      ProviderGroups: {},
      Providers: {}
    }
  },

  computed: {
    types() {
      if (this.type) {
        return [
          {
            title: `sendings.types.${this.type}`,
            value: this.type
          }
        ]
      }

      return this.getSendingTypesByPermission('advanced.users.setRoutingGroups')
    }
  },

  watch: {
    type() {
      this.fillRoutingGroups()
    },
    value: {
      handler() {
        if (!_.isEqual(this.value, this.RoutingGroups)) {
          this.initRoutingGroups = unVue(this.value)
          this.fillRoutingGroups()
        }
      },
      deep: true
    },
    RoutingGroups: {
      handler() {
        if (!_.isEqual(this.value, this.RoutingGroups)) {
          this.$emit('input', this.RoutingGroups)
        }
      },
      deep: true
    }
  },

  async mounted() {
    this.initRoutingGroups = unVue(await this.fillRoutingGroups())
  },

  methods: {
    // Аргумент inside решает какой объект данных наполнять.
    // Если true значит будет выполнен проход по внутренним данным из this.RoutingGroups.
    // Если false - по внешним из this.value.
    async fillRoutingGroups(inside = false) {
      const data = unVue(inside ? this.RoutingGroups : this.value)

      for (const type in data) {
        if (~allowedSendingTypes.indexOf(type)) {
          const result = await Promise.all(data[type].map(async item => {
            const lastState = _.get(this.rowState, `${serviceName}.${item.id}`, 'closed')
            Vue.set(this.rowState, `${serviceName}.${item.id}`, 'loading')
            if (!item.title || !item.type) {
              try {
                const routingGroup = await this.rest.routingGroups.get(item.id)
                Object.assign(item, routingGroup)
              } catch (error) {
                globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
              }
            }
            Vue.set(this.rowState, `${serviceName}.${item.id}`, lastState)

            return item
          }))
          this.RoutingGroups[type].splice(0, data[type].length, ...result)
        }
      }

      return this.RoutingGroups
    },

    addRoutingGroup(id, type) {
      if (id) {
        if (!~this.RoutingGroups[type].findIndex(item => item.id === id)) {
          this.RoutingGroups[type].push({ id })
          this.fillRoutingGroups(true)
          this.showAddRouteMenu[type] = false
          this.routeSelector = null // null потому что undefined ничего не изменит
        }
      }
    },
    removeRoutingGroup(id, type) {
      if (id) {
        const index = this.RoutingGroups[type].findIndex(item => item.id === id)
        if (index > -1) {
          this.RoutingGroups[type].splice(index, 1)
          Vue.set(this.showConfirmRemoveMenu, id, false)
          Vue.delete(this.rowState, `${serviceName}.${id}`)
        }
      }
    },

    getRowState(service, id, defaults = 'closed') {
      return _.get(this.rowState, `${service}.${id}`, defaults)
    },

    closeRow(service, id, force = false) {
      if (force || this.getRowState(service, id) != 'closed') {
        Vue.set(this.rowState, `${service}.${id}`, 'closed')

        return this.getRowState(service, id)
      }
    },
    openRow(service, id, force = false) {
      if (force || this.getRowState(service, id) != 'opened') {
        Vue.set(this.rowState, `${service}.${id}`, 'opened')

        return this.getRowState(service, id)
      }
    },

    async getEntities(service, association, id) {
      if (service && id) {
        switch (this.getRowState(service, id)) {
          case 'closed': {
            try {
              Vue.set(this.rowState, `${service}.${id}`, 'loading')
              const response = await this.rest[service].get(id, { query: { $scope: association } })
              const data = response[association]
              if (!this[association][id]) {
                this[association][id] = []
              }
              this[association][id].splice(0, data.length, ...data)
              if (service === 'routingGroups') {
                this[association][id].map(item => {
                  if (Array.isArray(item.rules) && item.rules.length) {
                    for (const rule of item.rules) {
                      rule.value = ruleProcessor(rule.value, rule.type || rule.tag)
                    }
                  }
                })
              }
              this[association][id].sort((a, b) => a.priority - b.priority || a.percentage - b.percentage)

              return data
            } catch (error) {
              globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
            } finally {
              this.openRow(service, id)
            }
            break
          }
          case 'opened': {
            this.closeRow(service, id)
            break
          }
        }
      }
    },

    async getAllEntities(service, association, id) {
      if (service && id) {
        try {
          if (services[service].hasDeep) {
            const entities = await this.getEntities(service, association, id)
            if (entities) {
              await Promise.all(entities.map(entity => {
                this.closeRow(services[service].nextService, entity.id, true)

                return this.getAllEntities(services[service].nextService, services[services[service].nextService].association, entity.id)
              }))
            }
          }
        } catch (error) {
          globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
        }
      }
    },

    clearShowAddRouteMenu(currentType) {
      for (const type of allowedSendingTypes) {
        if (currentType !== type) {
          this.showAddRouteMenu[type] = false
        }
      }
    }
  },

  render
}
