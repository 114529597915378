export default {
  title: 'Абонентские платы',

  titles: {
    create: 'Создать абонентскую плату'
  },

  labels: {
    type: 'Тип абонентской платы',
    expireAt: 'Следующий платёж',
    pricePerMonth: 'Цена за месяц',
    sendername: 'Имя отправителя',
    counterType: 'Тип счетчика',
    maximum: 'Максимум',
    discountPrice: 'Цена со скидкой',
    operator: {
      approved: 'Одобрен',
      isPaid: 'Оплачен'
    }
  },

  types: {
    subscription: {
      sms: {
        sendername: 'Имя отправителя SMS'
      },
      viber: 'Viber',
      whatsapp: {
        waba: 'WhatsApp business API',
        wapi: 'WhatsApp API'
      }
    }
  },

  counterTypes: {
    session: 'Сессия',
    message: 'Сообщение',
    price: 'Цена'
  },

  operators: {
    tele2: 'Tele2',
    mts: 'MTS',
    megafon: 'Megafon',
    beeline: 'Beeline',
    motiv: 'Motiv'
  },

  tooltips: {
    favorites: 'Избранное'
  },

  hints: {
    noOperators: 'Нет операторов',
    operator: {
      approved: 'Оператор одобрен',
      isPaid: 'Оператор оплачен'
    },
    no: {
      operators: 'Не указана ни одна цена для оператора. Указать цены можно в настройках сайта, раздел "Операторы".'
    }
  },

  buttons: {
    add: 'Добавить абонентскую плату'
  },

  snackbars: {
    created: 'Абонентская плата успешно создана.',
    updated: 'Абонентская плата успешно обновлена.',
    removed: 'Абонентская плата успешно удалена.'
  }
}
