import Vue from 'vue'

import { generateServices, globalErrorHandler, globalErrorProcessor, tableHeaderParser } from '@/utils'

import render from './render'

const name = 'billings'
const $gt = new Date()
$gt.setHours(0, 0, 0, 0)
$gt.setDate(1)
const $lt = new Date()
$lt.setMilliseconds(0)
$lt.setSeconds(0)

export default {
  name: 'ReferralPayments',

  mixins: [
    generateServices({
      name,
      get: false,
      find: {
        defaultFilter: {
          createdAt: {
            $gt,
            $lt
          },
          OwnerId: undefined,
          $scope: [ 'referral' ]
        }
      },
      create: false,
      update: false,
      remove: false
    })
  ],

  props: {
    OwnerId: {
      type: String
    }
  },

  data() {
    return {
      loading: false,

      lastMonthSum: 0,
      referralCode: ''
    }
  },

  computed: {
    headers() {
      return tableHeaderParser.call(this, [
        {
          title: 'referral.headers.referral',
          value: 'referrer',
          align: 'left',
          class: 'pl-2 pr-2'
        },
        {
          title: 'referral.headers.amount',
          value: 'amount',
          align: 'right',
          class: 'pl-2 pr-2'
        },
        {
          title: 'referral.headers.date',
          value: 'createdAt',
          align: 'right',
          class: 'pl-2 pr-2'
        }
      ])
    },
    totalAmount() {
      if (this.restData[name].find.pagination.total) {
        return this.restData[name].find.data.reduce((sum, { amount }) => {
          return sum + parseFloat(amount)
        }, 0)
      }
    }
  },

  mounted() {
    this.restData[name].find.filter.OwnerId = this.OwnerId || this.account.id
    this.getSumAndReferralCode()
  },

  methods: {
    async getSumAndReferralCode() {
      try {
        this.loading = true
        const result = await Vue.$GRequest.get('users', this.OwnerId || this.account.id, {
          query: {
            $scope: [ 'referral' ]
          }
        })
        this.referralCode = result.data.referralCode
        this.lastMonthSum = result.data.$referral.lastMonthSum
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      } finally {
        this.loading = false
      }
    }
  },

  render
}
