import Vue from 'vue'

import { unVue } from '@sigma-legacy-libs/unvue'
import { permission as SCHEMA } from '@sigma-legacy-libs/essentials/lib/schemas'

import { globalErrorHandler, globalErrorProcessor } from '@/utils'

import { serviceName } from '@/components/services/permissions/utils'

import render from './render'

const _ = {
  debounce: require('lodash/debounce'),
  isEqual: require('lodash/isEqual'),
  merge: require('lodash/merge')
}

function findSubStr(str, substr) {
  return !!~str.toLowerCase().indexOf(substr.toLowerCase())
}

export default function(options) {
  return {
    name: serviceName,

    data() {
      return {
        loading: {
          find: false,
          update: {}
        },

        permissions: undefined,
        permissionSearch: '',

        showInfoDialog: false
      }
    },

    computed: {
      computedPermissions() {
        const result = {}
        const pathToTranslate = `${serviceName}.services`

        let schema = SCHEMA.RESELLER_PERMISSIONS_SCHEMA
        if (this.checkPermissions('advanced.users.setPermissions')) {
          schema = SCHEMA.PERMISSIONS_SCHEMA
        }

        for (const key in schema) {
          const [ root, service, action ] = key.split('.')

          if (root) {
            switch (root) {
              case 'advanced': {
                if (service && action) {
                  if (findSubStr(service, this.permissionSearch) || findSubStr(this.getTranslate(`${pathToTranslate}.${service}`), this.permissionSearch)) {
                    if (!result[service]) {
                      result[service] = {
                        group: service,
                        title: this.getTranslate(`${pathToTranslate}.${service}`),
                        children: []
                      }
                    }

                    result[service].children.push(
                      Object.assign(
                        {
                          $key: key,
                          $name: action
                        },
                        schema[key]
                      )
                    )
                  }
                }
                break
              }
              case 'secret': {
                if (findSubStr(root, this.permissionSearch) || findSubStr(this.getTranslate(`${pathToTranslate}.${root}`), this.permissionSearch)) {
                  if (service) {
                    if (!result[root]) {
                      result[root] = {
                        group: root,
                        title: this.getTranslate(`${pathToTranslate}.${root}`),
                        children: []
                      }
                    }

                    result[root].children.push(
                      Object.assign(
                        {
                          $key: key,
                          $name: service
                        },
                        schema[key]
                      )
                    )
                  }
                }
                break
              }
            }
          }
        }

        return Object.values(result).sort((a, b) => a.title.localeCompare(b.title))
      }
    },

    mounted() {
      this.findPermissions()
    },

    methods: {
      async findPermissions() {
        try {
          this.loading.find = true
          const { data } = await Vue.$GRequest.find([ serviceName, options.UserId ].filter(item => !!item).join('/'))
          if (data) {
            this.permissions = data
          }
        } catch (error) {
          globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
        } finally {
          this.loading.find = false
        }
      },

      updatePermission: _.debounce(
        async function(field, value) {
          const previousValue = unVue(this.permissions[field])
          if (!_.isEqual(previousValue, value)) {
            try {
              const { data } = await Vue.$GRequest.update(serviceName, `${options.UserId}/${field}`, { value })
              if (data) {
                this.permissions[field] = data.value
                this.addSnackbar({
                  type: 'success',
                  text: this.getTranslate(`${serviceName}.snackbars.updated`)
                })
              }
            } catch (error) {
              globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
            } finally {
              Vue.set(this.loading.update, `${field}:${value}`, false)
            }
          }
        },
        250,
        {
          leading: false,
          trailing: true
        }
      )
    },

    render(h) {
      return render.call(this, h, options)
    }
  }
}
