import proxy from '@sigma-legacy-libs/g-proxy'

import { decodeKeys, encodeKeys } from '@/components/services/tariffRatesUpdating/utils'

import render from './render'

export default {
  name: 'Exceptions',

  mixins: [ proxy({ type: 'object' }) ],

  data() {
    return {
      dataForTree: {},
      showDialogTree: false
    }
  },

  watch: {
    dataForTree: {
      handler() {
        this.proxy = encodeKeys(this.dataForTree, this.proxy)
      },
      deep: true
    }
  },

  mounted() {
    this.dataForTree = decodeKeys(this.proxy)
  },

  render
}
