import Vue from 'vue'

import GRequest from '@sigma-legacy-libs/g-request'

import store from '@/store'

import { backendServerIP } from '@/utils'

const _ = {
  get: require('lodash/get'),
  set: require('lodash/set'),
  merge: require('lodash/merge')
}

Vue.$GRequest = new GRequest({
  baseURL: `${backendServerIP}/api`,
  preHook(params) {
    const token = store.getters['authentication/token']

    return token ? _.set(params, 'headers.Authorization', store.getters['authentication/token']) : params
  },
  async onFailed(error) {
    if (_.get(error, 'response.data.error') === 401) {
      await store.dispatch('login/logout')
    }

    if (typeof error.response === 'object' && _.get(error, 'response.data.error') === 429) {
      if (error.response.headers && typeof error.response.headers === 'object') {
        if (
          error.response.headers['retry-after'] &&
          error.response.headers['x-ratelimit-limit'] &&
          error.response.headers['x-ratelimit-remaining'] &&
          error.response.headers['x-ratelimit-reset']
        ) {
          throw _.merge(_.get(error, 'response.data', error), {
            'retry-after': error.response.headers['retry-after'],
            'x-ratelimit-limit': error.response.headers['x-ratelimit-limit'],
            'x-ratelimit-remaining': error.response.headers['x-ratelimit-remaining'],
            'x-ratelimit-reset': error.response.headers['x-ratelimit-reset']
          })
        }
      }
    }

    throw _.get(error, 'response.data', error)
  }
})
