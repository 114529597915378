import Vue from 'vue'

import { Macro } from '@sigma-legacy-libs/cache'

import Service from './Service'

export const mergeWithReplacingArrays = (a, b) => Array.isArray(b) ? b : undefined

const _ = {
  mergeWith: require('lodash/mergeWith'),
  toPairs: require('lodash/toPairs'),
  isPlainObject: require('lodash/isPlainObject')
}

const defaultServiceOptions = {
  cacher: new Macro({
    ttl: 5 * 1000,
    ttlInterval: 1000
  }),

  get: {
    async method(id, params = {}) {
      return (await Vue.$GRequest.get(this.path, id, params)).data
    },

    paramsSanitizer(params = {}) {
      return _.mergeWith({}, this.options.get.params, params, mergeWithReplacingArrays)
    }
  },

  find: {
    async method(params = {}) {
      return (await Vue.$GRequest.find(this.path, params)).data
    },

    paramsSanitizer(params = {}) {
      return _.mergeWith(
        {
          query: {
            $offset: this.findData.pagination.offset,
            $limit: this.findData.pagination.limit,
            $order: _.toPairs(this.findData.order),
            ...this.findData.filter || {}
          }
        },
        this.options.find.params,
        params,
        mergeWithReplacingArrays
      )
    }
  },

  update: {
    async method(id, data, params = {}) {
      return (await Vue.$GRequest.update(this.path, id, data, params)).data
    },

    paramsSanitizer(params = {}) {
      return _.mergeWith({}, this.options.update.params, params, mergeWithReplacingArrays)
    }
  },

  patch: {
    async method(id, data, params = {}) {
      return (await Vue.$GRequest.patch(this.path, id, data, params)).data
    },

    paramsSanitizer(params = {}) {
      return _.mergeWith({}, this.options.patch.params, params, mergeWithReplacingArrays)
    }
  },

  create: {
    async method(data, params = {}) {
      return (await Vue.$GRequest.create(this.path, data, params)).data
    },

    paramsSanitizer(params = {}) {
      return _.mergeWith({}, this.options.create.params, params, mergeWithReplacingArrays)
    }
  },

  remove: {
    async method(id, params = {}) {
      return (await Vue.$GRequest.remove(this.path, id, params)).data
    },

    paramsSanitizer(params = {}) {
      return _.mergeWith({}, this.options.remove.params, params, mergeWithReplacingArrays)
    }
  }
}

export const changeDefaultServiceOptions = (options = {}) => {
  _.mergeWith(defaultServiceOptions, options, mergeWithReplacingArrays)
}

export const generateServices = (services = []) => {
  if (!Array.isArray(services)) {
    services = [ services ]
  }

  const mixin = {
    data() {
      const restData = {}
      for (const serviceName of Object.keys(this.rest)) {
        if (!restData[serviceName]) {
          const service = this.rest[serviceName]

          restData[serviceName] = {
            get: service.getData,
            find: service.findData,
            create: service.createData,
            update: service.updateData,
            remove: service.removeData
          }
        }
      }

      return { restData }
    },

    beforeCreate() {
      this.rest = {}
      this.unwatchers = []

      for (let service of services) {
        service = new Service(
          _.mergeWith(
            { context: this },
            defaultServiceOptions,
            service,
            mergeWithReplacingArrays
          )
        )

        this.rest[service.as] = service
      }
    },

    created() {
      for (const serviceName in this.rest) {
        const service = this.rest[serviceName]
        service.init(this)

        if (Array.isArray(service.websocketEvents)) {
          for (const { event, handler } of service.websocketEvents) {
            Vue.$socket.on(event, data => handler.call(service, data))
          }
        }

        if (Array.isArray(service.watchers)) {
          service.watchers.forEach(watcher => {
            this.unwatchers.push(this.$watch(...watcher))
          })
        }
      }
    },

    beforeDestroy() {
      for (const serviceName in this.rest) {
        const service = this.rest[serviceName]

        for (const { event, handler } of service.websocketEvents) {
          Vue.$socket.off(event, data => handler.call(service, data))
        }
      }

      for (const unwatcher of this.unwatchers) {
        unwatcher()
      }
    },

    methods: {
      restGet(serviceName, id, params = {}) {
        return this.rest[serviceName].get(id, params)
      },
      restFind(serviceName, params = {}) {
        return this.rest[serviceName].find(params)
      },
      restUpdate(serviceName, id, data = {}, params = {}) {
        return this.rest[serviceName].update(id, data, params)
      },
      restPatch(serviceName, id, data = {}, params = {}) {
        return this.rest[serviceName].patch(id, data, params)
      },
      restCreate(serviceName, data = {}, params = {}) {
        return this.rest[serviceName].create(data, params)
      },
      restRemove(serviceName, id, params = {}) {
        return this.rest[serviceName].remove(id, params)
      }
    }
  }

  return mixin
}

export default {
  changeDefaultServiceOptions,
  generateServices
}
