import Vue from 'vue'

import { PROVIDER_STATES } from '@sigma-legacy-libs/essentials/lib/constants'

import { globalErrorHandler, globalErrorProcessor } from '@/utils'

import { serviceName } from '@/components/services/providers/utils'

import render from './render'

export default {
  name: 'State',

  props: {
    id: {
      type: String,
      required: true
    },

    size: {
      type: Number,
      default: 24
    }
  },

  data() {
    return {
      state: PROVIDER_STATES.inactive
    }
  },

  watch: {
    id() {
      this.getState()
    }
  },

  mounted() {
    Vue.$socket.on(`${serviceName}.state.changed`, data => {
      if (this.id === data.providerId) {
        this.state = data.state
      }
    })

    this.getState()
  },

  methods: {
    async getState(id) {
      try {
        const { data } = await Vue.$GRequest.get(serviceName, this.id || id, { query: { state: true } })
        if (data && data.result) {
          this.state = data.result
        }
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      }
    }
  },

  render
}
