import { unVue } from '@sigma-legacy-libs/unvue'
import { isUUID } from '@sigma-legacy-libs/essentials/lib/validators'
import { EMPTY_PROVIDERGROUPS, EMPTY_ROUTINGRULES } from '@sigma-legacy-libs/essentials/lib/constants'

import { STATES, generateServices, globalErrorHandler, globalErrorProcessor } from '@/utils'

import render from './render'

const defaultPayload = {
  title: undefined,
  RoutingRules: []
}

export default {
  name: 'RoutesCreateFull',

  mixins: [
    generateServices([
      {
        name: 'routingGroups',

        find: {
          defaultFilter: {
            title: undefined
          }
        },

        get: false,
        update: false,
        remove: false
      },
      {
        name: 'routingRules',

        find: false,
        update: false,
        remove: false
      },
      {
        name: 'providerGroups',

        find: false,
        update: false,
        remove: false
      },
      {
        name: 'providers',

        find: false,
        create: false,
        update: false,
        remove: false
      }
    ])
  ],

  props: {
    show: Boolean,
    type: String
  },

  data() {
    return {
      colsToShow: 1,

      routingRuleIndex: undefined,

      payload: unVue(defaultPayload),
      payloads: {
        routingRules: unVue(EMPTY_ROUTINGRULES),
        providerGroups: unVue(EMPTY_PROVIDERGROUPS)
      }
    }
  },

  computed: {
    loading() {
      return this.restData.routingGroups.create.state === STATES.loading ||
      this.restData.routingRules.create.state === STATES.loading ||
      this.restData.providerGroups.create.state === STATES.loading
    },
    disabled() {
      return this.restData.routingGroups.find.pagination.total > 0 || this.loading
    },
    validation() {
      return {
        routingGroups: !!this.payload.title && this.payload.RoutingRules.length !== 0,
        routingRules: !!this.payloads.routingRules.title && this.payloads.routingRules.ProviderGroups.length !== 0,
        providerGroups: !!this.payloads.providerGroups.title && this.payloads.providerGroups.Providers.length !== 0 && this.payloads.providerGroups.Providers.every(({ id }) => id !== undefined)
      }
    },
    gridTemplateColumns() {
      if (this.viewport.breakpoint.lgUp) {
        return 'repeat(1, minmax(500px, 1fr))'
      } else {
        return 'repeat(1, minmax(300px, 1fr))'
      }
    }
  },

  methods: {
    async create() {
      try {
        const RoutingRules = await Promise.all(this.payload.RoutingRules.map(async (routingRule, routingRuleIndex) => {
          const routingRulesResult = {
            id: routingRule.id,
            title: routingRule.title,
            priority: routingRuleIndex,
            $attach: true
          }

          if (Array.isArray(routingRule.ProviderGroups)) {
            routingRule.ProviderGroups = await Promise.all(routingRule.ProviderGroups.map(async providerGroup => {
              const providerGroupsResult = {
                id: providerGroup.id,
                title: providerGroup.title,
                percentage: providerGroup.percentage || 100,
                $attach: true
              }
              if (!providerGroupsResult.id && providerGroupsResult.title) {
                const { id } = await this.rest.providerGroups.create(providerGroup)
                providerGroupsResult.id = id
              }

              return providerGroupsResult
            }))
          }

          if (!routingRulesResult.id && routingRulesResult.title) {
            const { id } = await this.rest.routingRules.create(routingRule)
            routingRulesResult.id = id
          }

          return routingRulesResult
        }))

        const routingGroup = await this.rest.routingGroups.create({
          title: this.payload.title,
          type: this.type,
          RoutingRules
        })
        this.$emit('input', routingGroup.id)
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      } finally {
        this.payload = unVue(defaultPayload)
        this.$emit('showDialog', false)
      }
    },

    async addRoutingRuleFromSelect(id) {
      if (isUUID(id)) {
        if (!~this.payload.RoutingRules.findIndex(item => item.id === id)) {
          if (~[ STATES.empty, STATES.ready ].indexOf(this.restData.routingRules.get.state)) {
            try {
              const routingRule = await this.rest.routingRules.get(id)
              this.payload.RoutingRules.push({
                id: routingRule.id,
                title: routingRule.title
              })
            } catch (error) {
              globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
            }
          }
        }
      }
    },

    addRoutingRuleFromForm() {
      if (this.validation.routingRules) {
        if (this.routingRuleIndex !== undefined) {
          this.payload.RoutingRules.splice(this.routingRuleIndex, 1, this.payloads.routingRules)
        } else {
          this.payload.RoutingRules.push(this.payloads.routingRules)
        }
        this.payloads.routingRules = unVue(EMPTY_ROUTINGRULES)
        this.routingRuleIndex = undefined
        this.colsToShow -= 1
      }
    },
    addProviderGroupToRuleFromForm() {
      if (this.validation.providerGroups) {
        this.payloads.routingRules.ProviderGroups.push(this.payloads.providerGroups)
        this.payloads.providerGroups = unVue(EMPTY_ROUTINGRULES)
        this.colsToShow -= 1
      }
    }
  },

  render
}
