import SingleLink from '@/components/misc/SingleLink'
import salon from '@/components/services/yclients/item/salon'
import error from '@/components/services/yclients/item/error'
import buttons from '@/components/services/yclients/item/buttons'

function renderSalon(h) {
  return h(salon, { props: { value: this.value } })
}
function renderError(h) {
  return h(error, { props: { value: this.value } })
}

function renderToken(h) {
  return h(
    SingleLink,
    {
      props: {
        value: this.value.Token || this.value.TokenId,
        pathToTitle: 'token',
        service: 'tokens',
        length: 20
      }
    }
  )
}
function renderOwner(h) {
  return h(
    SingleLink,
    {
      props: {
        value: this.value.Owner || this.value.OwnerId
      }
    }
  )
}

function renderDescription(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8 caption text--grey',
      style: { 'grid-template-columns': 'repeat(2, minmax(min-content, max-content))' }
    },
    [
      renderToken.call(this, h),
      renderOwner.call(this, h)
    ]
  )
}

export default function(h) {
  return h(
    'g-card',
    {
      class: 'hover-parent hover-parent--opacity pa-2',
      props: {
        outline: true,
        rounded: true,
        accent: true,
        accentColorName: this.value.isActive ? 'success' : 'error'
      }
    },
    [
      h(
        'div',
        {
          class: 'grid grid-gap--8 faic',
          style: { 'grid-template-columns': '1fr auto' }
        },
        [
          h(
            'div',
            {
              class: 'grid grid-gap--2'
            },
            [
              h(
                'div',
                {
                  class: 'grid gird-gap--8',
                  style: { gridTemplateColumns: 'repeat(2, minmax(min-content, max-content))' }
                },
                [
                  renderSalon.call(this, h),
                  renderError.call(this, h)
                ]
              ),
              renderDescription.call(this, h)
            ]
          ),

          h(buttons, { props: { value: this.value } })
        ]
      )
    ]
  )
}
