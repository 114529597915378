import { renderReleaseBucketButton, renderSudoDataDialog } from '@/components/misc'

import RefreshButton from '@/components/button/refresh'
import BillingsDetailsItem from '@/components/services/billings/details/item'
import pagination from '@/components/misc/pagination'

import { serviceName } from '@/components/services/billings/utils'

function renderContentByBreakpoint(h) {
  if (this.viewport.breakpoint.mdUp) {
    return h(
      'g-table',
      {
        props: {
          value: this.restData[serviceName].find.order,
          items: this.restData[serviceName].find.data,
          headers: this.headers,
          hideHead: this.restData[serviceName].find.pagination.total < 1,
          noDataText: this.getTranslate('misc.no.data.text')
        },
        on: {
          input: event => {
            this.restData[serviceName].find.order = event
          }
        },
        scopedSlots: {
          items: ({ item }) => {
            return h(
              BillingsDetailsItem,
              {
                props: {
                  value: item,
                  mode: 'table'
                },
                on: {
                  sudoData: ({ showDialog, source }) => {
                    this.sudoData.showDialog = showDialog
                    this.sudoData.source = source
                  }
                }
              }
            )
          }
        }
      }
    )
  } else {
    return h(
      'g-expansion-panels',
      {
        props: { flat: true }
      },
      this.restData[serviceName].find.data.map(item => {
        return h(
          BillingsDetailsItem,
          {
            props: {
              value: item,
              mode: 'panels'
            },
            on: {
              sudoData: ({ showDialog, source }) => {
                this.sudoData.showDialog = showDialog
                this.sudoData.source = source
              }
            }
          }
        )
      })
    )
  }
}

function renderDivider(h, trigger = false) {
  const showDivider = this.restData[serviceName].find.pagination.total && Array.isArray(this.restData[serviceName].find.data) && this.restData[serviceName].find.data.length
  if (trigger || showDivider) {
    return h('g-divider')
  }
}

function renderPagination(h) {
  return h(
    'div',
    {
      class: 'fjcfe faic pa-2'
    },
    [
      h(
        pagination, {
          props: {
            value: this.restData[serviceName].find.pagination
          },
          on: {
            input: event => {
              this.restData[serviceName].find.pagination = event
            }
          }
        }
      )
    ]
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: 'fc ff'
    },
    [
      h(
        'div',
        {
          class: {
            'faic pa-2': true,
            fw: this.viewport.breakpoint.smDown
          }
        },
        [
          this.$slots.title,

          h('div', { class: 'ff' }),

          renderReleaseBucketButton.call(this, h, { name: serviceName }),

          h(
            RefreshButton,
            {
              class: 'ma-0',
              props: {
                disabled: this.downloadable,
                label: this.getTranslate(`${serviceName}.buttons.refresh`),
                tooltip: this.getTranslate(`${serviceName}.tooltips.refresh`),
                state: this.restData[serviceName].find.state,
                shortClickMethod: () => this.rest[serviceName].find(),
                longClickMethod: () => this.rest[serviceName].find({}, { noCache: true })
              }
            }
          )
        ]
      ),

      this.$slots.filter,

      renderPagination.call(this, h),
      renderDivider.call(this, h),
      renderContentByBreakpoint.call(this, h),
      renderDivider.call(this, h),
      renderPagination.call(this, h),

      renderSudoDataDialog.call(this, h)
    ]
  )
}
