import Vue from 'vue'

import { globalErrorHandler, globalErrorProcessor } from '@/utils'

import { serviceName } from '@/components/services/billings/utils'

import render from './render'

export default {
  name: 'UpdateStatus',

  props: {
    id: {
      type: String,
      required: true
    },

    status: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      loading: false
    }
  },

  methods: {
    async update(status) {
      try {
        this.loading = true
        await Vue.$GRequest.update(serviceName, this.id, { status }, { query: { $scope: [ 'Owner' ] } })
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      } finally {
        this.loading = false
      }
    }
  },

  render
}
