import Vue from 'vue'

import EntityInfo from '@/components/misc/EntityInfo'
import viewChanger from '@/components/serviceTemplate/viewChanger'
import sizeChanger from '@/components/serviceTemplate/sizeChanger'
import buttonCopy from '@/components/button/copy'
import buttonFavorite from '@/components/button/favorite'
import RefreshButton from '@/components/button/refresh'
import componentNotFound from '@/components/misc/componentNotFound'
import pagination from '@/components/misc/pagination'

import { STATES } from '@/utils'

function renderRemoveDialog(h, options) {
  return h(
    'g-dialog',
    {
      props: {
        value: this.showRemoveDialog,
        rounded: true,
        maxWidth: 400
      },
      on: {
        input: event => {
          this.showRemoveDialog = event
        }
      }
    },
    [
      h(
        'div',
        {
          class: 'headline pa-3',
          slot: 'header'
        },
        this.getTranslate('commons.titles.confirm.remove')
      ),

      h(
        'div',
        {
          class: 'body-1 pa-3'
        },
        this.getTranslate('commons.contents.confirm.remove')
      ),

      h(
        'g-card-actions',
        {
          class: 'fjcfe',
          slot: 'footer'
        },
        [
          h(
            'g-button',
            {
              props: {
                flat: true,
                rounded: true,
                label: this.getTranslate('misc.buttons.cancel'),
                disabled: this.restData[options.serviceName].remove.state === STATES.loading
              },
              on: {
                click: () => {
                  this.showRemoveDialog = false
                }
              }
            }
          ),

          h(
            'g-button',
            {
              props: {
                rounded: true,
                depressed: true,
                label: this.getTranslate('misc.buttons.remove'),
                color: 'error',
                loading: this.restData[options.serviceName].remove.state === STATES.loading,
                disabled: this.restData[options.serviceName].remove.state === STATES.loading || !this.canRemove
              },
              on: {
                click: () => {
                  this.remove()
                }
              }
            }
          )
        ]
      )
    ]
  )
}
function renderRemoveButton(h, options) {
  return h(
    'g-button',
    {
      class: 'ma-0',
      props: {
        icon: 'delete',
        flat: true,
        color: 'error',
        disabled: !this.canRemove
      },
      on: {
        click: () => {
          this.showRemoveDialog = true
        }
      },
      scopedSlots: {
        dialog: () => renderRemoveDialog.call(this, h, options)
      }
    }
  )
}
function renderCreateDialog(h, options) {
  return h(
    'g-dialog',
    {
      props: {
        value: this.showCreateDialog,
        rounded: true,
        maxWidth: options.width,

        ...options.dialog.create
      },
      on: {
        input: event => {
          this.showCreateDialog = event
        }
      }
    },
    [
      h(
        'div',
        {
          class: 'headline pa-3',
          slot: 'header'
        },
        this.getTranslate(`${options.serviceName}.titles.create`)
      ),

      renderForm.call(this, h, options, this.restData[options.serviceName].create.data, 'create'),

      h(
        'g-card-actions',
        {
          class: 'fjcfe',
          slot: 'footer'
        },
        [
          h(
            'g-button',
            {
              props: {
                flat: true,
                rounded: true,
                label: this.getTranslate('misc.buttons.cancel'),
                disabled: this.restData[options.serviceName].create.state === STATES.loading
              },
              on: {
                click: () => {
                  this.showCreateDialog = false
                }
              }
            }
          ),

          h(
            'g-button',
            {
              props: {
                rounded: true,
                depressed: true,
                label: this.getTranslate('misc.buttons.create'),
                color: 'primary',
                loading: this.restData[options.serviceName].create.state === STATES.loading,
                disabled: this.restData[options.serviceName].create.state === STATES.loading || !this.restData[options.serviceName].create.isValid || !this.canCreate
              },
              on: {
                click: () => {
                  this.create()
                }
              }
            }
          )
        ]
      )
    ]
  )
}
function renderCreateButton(h, options) {
  return h(
    'g-button',
    {
      class: 'ma-0',
      props: {
        flat: true,
        icon: 'add',
        color: 'secondary',
        disabled: !this.canCreate
      },
      on: {
        click: () => {
          this.showCreateDialog = true
        }
      },
      scopedSlots: {
        dialog: () => renderCreateDialog.call(this, h, options)
      }
    }
  )
}
function renderViewChanger(h, options) {
  if (options.viewChanger) {
    return h(
      viewChanger,
      {
        props: {
          value: this.type,
          serviceName: options.serviceName
        },
        on: {
          input: event => {
            this.type = event
          }
        }
      }
    )
  }
}
function renderSizeChanger(h, options) {
  if (options.sizeChanger) {
    if (this.type === 'cards') {
      return h(
        sizeChanger,
        {
          props: {
            value: this.size,
            serviceName: options.serviceName
          },
          on: {
            input: event => {
              this.size = event
            }
          }
        }
      )
    }
  }
}

function renderFavoriteButton(h) {
  return h(
    'g-button',
    {
      class: 'ma-0',
      props: {
        flat: true,
        icon: this.showFavorites ? 'favorite' : 'favorite_outline',
        color: this.showFavorites ? 'error' : undefined
      },
      on: {
        click: () => {
          this.showFavorites = !this.showFavorites
        }
      }
    }
  )
}

function renderPagination(h, options) {
  if (!this.$route.params.id && !this.showFavorites) {
    if (this.restData[options.serviceName].find.pagination.total) {
      return h(
        'div',
        {
          class: 'grid grid-gap--8 faic',
          style: { 'grid-template-columns': '1fr auto 36px' }
        },
        [
          h('div', { class: 'ff' }),

          h(
            pagination, {
              props: {
                value: this.restData[options.serviceName].find.pagination,
                rowsPerPage: [ options.limit, options.limit * 2, options.limit * 4 ]
              },
              on: {
                input: event => {
                  this.restData[options.serviceName].find.pagination = event
                }
              }
            }
          ),

          h(
            RefreshButton,
            {
              class: 'ma-0',
              props: {
                state: this.restData[options.serviceName].find.state,
                shortClickMethod: () => this.rest[options.serviceName].find(),
                longClickMethod: () => this.rest[options.serviceName].find({}, { noCache: true })
              }
            }
          )
        ]
      )
    }
  }
}

function renderSearch(h, options) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8',
      style: {
        'grid-template-columns': this.viewport.breakpoint.mdUp ? '36px 250px' : '36px 1fr'
      }
    },
    [
      renderFavoriteButton.call(this, h),

      h(
        'g-text-field',
        {
          props: {
            value: this.showFavorites ? this.favoritesSearch : this.restData[options.serviceName].find.filter.$search,
            label: this.getTranslate(`${options.serviceName}.labels.search`),
            suffix: this.showFavorites ? this.favoritesItems.length : this.restData[options.serviceName].find.pagination.total,
            mode: 'outline',
            afterIcon: 'search',
            afterIconCallback: () => !this.showFavorites && this.rest[options.serviceName].find(),
            rounded: true,
            dense: true,
            details: false,
            clearable: true
          },
          on: {
            input: event => {
              if (this.showFavorites) {
                this.favoritesSearch = event
              } else {
                this.restData[options.serviceName].find.filter.$search = event
              }
            }
          }
        }
      )
    ]
  )
}

function renderFilter(h, options) {
  try {
    return h(
      require(`@/components/services/${options.serviceName}/filter`).default,
      {
        props: { value: this.restData[options.serviceName].find.filter },
        on: {
          input: event => {
            this.restData[options.serviceName].find.filter = event
          }
        }
      }
    )
  } catch (error) {
    return
  }
}

function renderHeader(h, options) {
  if (!this.$route.params.id) {
    const buttonsGridStyle = [ '36px' ]
    if (options.viewChanger) {
      buttonsGridStyle.push('36px')
    }
    if (options.sizeChanger) {
      buttonsGridStyle.push('auto')
    }

    return h(
      'div',
      {
        class: {
          faic: this.viewport.breakpoint.mdUp,
          fc: this.viewport.breakpoint.smDown
        }
      },
      [
        h(
          'div',
          {
            class: {
              'grid grid-gap--8': true,
              faic: this.viewport.breakpoint.mdUp
            },
            style: {
              'grid-template-columns': this.viewport.breakpoint.mdUp ? 'auto auto' : undefined
            }
          },
          [
            h(
              'div',
              {
                class: {
                  'grid grid-gap--8': true,
                  'grid-cols--1': this.viewport.breakpoint.smDown
                },
                style: {
                  'grid-template-columns': this.viewport.breakpoint.mdUp ? '1fr auto' : undefined
                }
              },
              [
                renderSearch.call(this, h, options),
                renderFilter.call(this, h, options)
              ]
            ),

            h(
              'div',
              {
                class: 'grid grid-gap--8',
                style: {
                  'grid-template-columns': `${buttonsGridStyle.join(' ')}`
                }
              },
              [
                renderCreateButton.call(this, h, options),
                renderViewChanger.call(this, h, options),
                renderSizeChanger.call(this, h, options)
              ]
            )
          ]
        ),

        h('div', { class: 'ff' }),

        renderPagination.call(this, h, options)
      ]
    )
  }
}

function renderTable(h, options, items) {
  return h(
    'g-card',
    {
      props: {
        outline: true,
        rounded: true
      }
    },
    [
      h(
        'g-table',
        {
          props: {
            items,
            noDataText: this.getTranslate(`${options.serviceName}.hints.notFound`)
          },
          scopedSlots: {
            items: ({ item }) => {
              const props = {
                value: item,
                type: 'row',
                size: this.size
              }

              try {
                return h(
                  require(`@/components/services/${options.serviceName}/item`).default,
                  {
                    props: {
                      ...props,
                      metrics: this.metrics && this.metrics[item.id]
                    }
                  }
                )
              } catch (error) {
                return h(require('@/components/serviceTemplate/item').default(options), { props })
              }
            }
          }
        }
      )
    ]
  )
}

function renderCards(h, options, items) {
  return h(
    'div',
    {
      class: 'service-template-cards'
    },
    items.map(item => {
      const props = {
        value: item,
        type: 'card',
        size: this.size
      }

      try {
        return h(require(`@/components/services/${options.serviceName}/item`).default, { props })
      } catch (error) {
        return h(require('@/components/serviceTemplate/item').default(options), { props })
      }
    })
  )
}

function renderContentByType(h, options, items) {
  switch (this.type) {
    case 'table': {
      return renderTable.call(this, h, options, items)
    }
    case 'cards': {
      return renderCards.call(this, h, options, items)
    }
  }
}

function renderFavorites(h, options) {
  if (Array.isArray(this.favoritesItems) && this.favoritesItems.length) {
    return renderContentByType.call(this, h, options, this.favoritesItems)
  } else {
    return h(
      'div',
      {
        class: 'py-5 px-3'
      },
      [ h('g-empty', { props: { title: this.getTranslate(`${options.serviceName}.hints.notFound`) } }) ]
    )
  }
}

function renderBackButton(h, options) {
  return h(
    'g-button',
    {
      class: 'ma-0',
      props: {
        flat: true,
        icon: 'keyboard_arrow_left'
      },
      on: {
        click: () => {
          Vue.router.push({ name: options.serviceName })
        }
      }
    }
  )
}

function renderCopyLinkForSlackButton(h, { serviceName }) {
  if (this.checkPermissions(`advanced.${serviceName}.get`)) {
    return h(
      buttonCopy,
      {
        props: {
          value: this.titleURLSlack,
          marginless: true,
          icon: 'slack',
          iconLibrary: 'icofont'
        },
        directives: [
          {
            name: 'g-tooltip',
            options: {
              value: this.getTranslate('commons.tooltips.copyLinkForSlack')
            }
          }
        ]
      }
    )
  }
}

function renderAfterTitle(h, options) {
  try {
    return h(
      require(`@/components/services/${options.serviceName}/after/title`).default,
      {
        props: {
          value: this.restData[options.serviceName].get.data,
          get: () => this.rest[options.serviceName].get(this.restData[options.serviceName].get.data.id)
        }
      }
    )
  } catch (error) {
    return h(
      'g-button',
      {
        class: 'ma-0',
        props: {
          flat: true,
          icon: 'save',
          color: 'primary',
          loading: this.restData[options.serviceName].update.state === STATES.loading,
          disabled: this.restData[options.serviceName].update.state === STATES.loading || !this.restData[options.serviceName].update.isValid || !this.canUpdate
        },
        on: {
          click: () => {
            this.update()
          }
        }
      }
    )
  }
}
function renderTitle(h, options) {
  if (this.restData[options.serviceName].get.state === STATES.ready) {
    if (this.restData[options.serviceName].get.data) {
      return h(
        'div',
        {
          class: 'grid grid-gap--8',
          style: { 'grid-template-columns': '1fr auto' }
        },
        [
          h(
            'div',
            {
              class: 'grid grid-gap--2 pr-3'
            },
            [
              h(
                'div',
                {
                  class: {
                    'headline text-overflow': true,
                    'text--grey': !this.restData[options.serviceName].get.data.title
                  }
                },
                this.title
              ),

              h(
                'div',
                {
                  class: 'small text-overflow text--grey'
                },
                this.restData[options.serviceName].get.data.id
              )
            ]
          ),

          h(
            'div',
            {
              class: 'faic fjcfe'
            },
            [ renderAfterTitle.call(this, h, options) ]
          )
        ]
      )
    }
  } else if (this.restData[options.serviceName].get.state === STATES.loading) {
    return h(
      'div',
      {
        class: 'fjcc facc faic square--36 ml-3'
      },
      [ h('g-progress', { props: { indeterminate: true } }) ]
    )
  }
}

function renderFormHeader(h, options) {
  const cols = this.checkPermissions(`advanced.${options.serviceName}.get`) ? 2 : 1

  return h(
    'div',
    {
      class: 'grid grid-gap--8',
      style: { 'grid-template-columns': `repeat(${cols}, 36px) 1fr` }
    },
    [
      renderBackButton.call(this, h, options),
      renderCopyLinkForSlackButton.call(this, h, options),
      renderTitle.call(this, h, options)
    ]
  )
}

function renderForm(h, options, data, method) {
  try {
    return h(
      require(`@/components/services/${options.serviceName}/form`).default,
      {
        class: options.formPaddles ? '' : 'pa-3',
        props: {
          value: data,
          isNew: method === 'create',
          updatedData: this.updatedData
        },
        on: {
          input: event => {
            data = event
          },
          submit: () => {
            this[method]()
          },
          validation: event => {
            this.restData[options.serviceName][method].isValid = event
          }
        }
      }
    )
  } catch (error) {
    return h(componentNotFound)
  }
}

function renderFormContent(h, options) {
  let { accent, accentColor, accentColorName, accentColorNameSelector, accentSize, accentPosition } = options.form || {}

  accentColorNameSelector ? accentColorName = accentColorNameSelector(this.restData[options.serviceName].get.data) : accentColorName

  return h(
    'g-card',
    {
      props: {
        rounded: true,
        maxWidth: options.width,
        width: '100%',
        accent,
        accentColor,
        accentColorName,
        accentSize,
        accentPosition
      }
    },
    [
      renderForm.call(this, h, options, this.restData[options.serviceName].get.data, 'update'),

      h(
        EntityInfo,
        {
          class: 'mb-3',
          props: { value: this.restData[options.serviceName].get.data }
        }
      )
    ]
  )
}

function renderFormFooter(h, options) {
  return h(
    'div',
    {
      class: 'fjcfe grid grid-gap--8',
      style: {
        'grid-template-columns': '36px 1fr 36px 36px auto',
        'max-width': `${options.width}px`,
        width: '100%'
      }
    },
    [
      renderRemoveButton.call(this, h, options),

      h('div', { class: 'ff' }),

      h(
        RefreshButton,
        {
          class: 'ma-0',
          props: {
            state: this.restData[options.serviceName].get.state,
            shortClickMethod: () => this.rest[options.serviceName].get(this.restData[options.serviceName].get.data.id),
            longClickMethod: () => this.rest[options.serviceName].get(this.restData[options.serviceName].get.data.id, { noCache: true })
          }
        }
      ),

      h(
        buttonFavorite,
        {
          class: 'ma-0',
          props: {
            id: this.restData[options.serviceName].get.data.id,
            serviceName: options.serviceName
          }
        }
      ),

      h(
        'g-button',
        {
          class: 'ma-0',
          props: {
            rounded: true,
            depressed: true,
            icon: 'save',
            color: 'primary',
            label: this.getTranslate('misc.buttons.save'),
            loading: this.restData[options.serviceName].update.state === STATES.loading,
            disabled: this.restData[options.serviceName].update.state === STATES.loading || !this.restData[options.serviceName].update.isValid || !this.canUpdate
          },
          on: {
            click: () => {
              this.update()
            }
          }
        }
      )
    ]
  )
}

function renderFirstCol(h, options) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8'
    },
    [
      renderFormHeader.call(this, h, options),
      renderFormContent.call(this, h, options),
      renderFormFooter.call(this, h, options)
    ]
  )
}
function renderSecondCol(h, options) {
  try {
    return h(require(`@/components/services/${options.serviceName}/cols/second`).default)
  } catch (error) {
    return h('div')
  }
}
function renderThirdCol(h, options) {
  try {
    return h(require(`@/components/services/${options.serviceName}/cols/third`).default)
  } catch (error) {
    return h('div')
  }
}

function renderItems(h, options) {
  if (this.showFavorites) {
    return renderFavorites.call(this, h, options)
  } else {
    if (this.restData[options.serviceName].find.state === STATES.ready) {
      if (this.restData[options.serviceName].find.pagination.total > 0) {
        return renderContentByType.call(this, h, options, this.restData[options.serviceName].find.data)
      } else {
        return h(
          'div',
          {
            class: 'py-5 px-3'
          },
          [ h('g-empty', { props: { title: this.getTranslate(`${options.serviceName}.hints.notFound`) } }) ]
        )
      }
    } else if (this.restData[options.serviceName].find.state === STATES.loading) {
      return h(
        'div',
        {
          class: 'fjcc facc pa-3'
        },
        [ h('g-progress', { props: { indeterminate: true } }) ]
      )
    }
  }
}

function renderContent(h, options) {
  if (this.$route.params.id) {
    switch (this.restData[options.serviceName].get.state) {
      case STATES.ready: {
        if (this.restData[options.serviceName].get.data) {
          return h(
            'div',
            {
              class: 'fc ff'
            },
            [
              h(
                'div',
                {
                  class: {
                    'grid faifs': true,
                    'grid-cols--1': this.viewport.breakpoint.smDown
                  },
                  style: {
                    'grid-template-columns': this.viewport.breakpoint.mdUp ? `${options.width}px repeat(2, minmax(400px, 1fr))` : undefined
                  }
                },
                [
                  renderFirstCol.call(this, h, options),
                  renderSecondCol.call(this, h, options),
                  renderThirdCol.call(this, h, options)
                ]
              )
            ]
          )
        } else {
          return h(
            'g-empty',
            {
              props: {
                title: this.getTranslate(`${options.serviceName}.hints.notFound`)
              }
            }
          )
        }
      }
      case STATES.loading: {
        return h(
          'g-progress',
          {
            props: {
              indeterminate: true
            }
          }
        )
      }
      case STATES.error: {
        return h(
          'g-empty',
          {
            props: {
              title: this.getTranslate(`${options.serviceName}.hints.notFound`)
            }
          }
        )
      }
    }
  } else {
    return renderItems.call(this, h, options)
  }
}

function renderFooter(h, options) {
  return renderPagination.call(this, h, options)
}

export default function(h, options) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8'
    },
    [
      renderHeader.call(this, h, options),
      renderContent.call(this, h, options),
      renderFooter.call(this, h, options)
    ]
  )
}
