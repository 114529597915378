import { SUBSCRIPTION_OPERATORS } from '@sigma-legacy-libs/essentials/lib/constants'

import { serviceName } from '@/components/services/operatorPrices/utils'

function renderFieldTitle(h) {
  return h(
    'g-select',
    {
      props: {
        value: this.proxy.operator,
        items: SUBSCRIPTION_OPERATORS.map(operator => ({
          title: this.getTranslate(`subscriptions.operators.${operator}`),
          value: operator
        })),
        itemsDisabled: this.itemsDisabled,
        label: this.getTranslate(`${serviceName}.labels.title`),
        mode: 'outline',
        dense: true,
        rounded: true,
        clearable: true,
        details: false,
        readonly: this.readonly
      },
      on: {
        input: event => {
          this.proxy.operator = event
        }
      }
    }
  )
}

function renderFieldPrice(h) {
  return h(
    'g-text-field',
    {
      props: {
        value: this.proxy.price,
        label: this.getTranslate(`${serviceName}.labels.price`),
        mode: 'outline',
        dense: true,
        rounded: true,
        clearable: true,
        details: false
      },
      on: {
        input: event => {
          this.proxy.price = event
        }
      }
    }
  )
}

export default function(h) {
  return h(
    'g-form',
    {
      class: 'grid grid-gap--8',
      style: { gridTemplateColumns: '1fr 200px' },
      on: {
        submit: () => {
          this.$emit('submit', this.proxy)
        }
      }
    },
    [
      renderFieldTitle.call(this, h),
      renderFieldPrice.call(this, h)
    ]
  )
}
