const presets = {
  labels: {
    queue: 'Очередь',
    speed: 'Скорость',
    count: 'Всего за 5 минут',
    deliveryRate: 'Процент доставки'
  },
  hints: {
    queue: 'Количество сообщений стоящих в очереди провайдера',
    speed: 'Скорость с которой сообщения уходят на провайдера за последнюю минуту',
    count: 'Количество сообщений которые были отправлены на провайдера за последние 5 минут',
    deliveryRate: 'Рассчитывается на основе данных о количестве отправленных и доставленных сообщений за последние 5 минут'
  },
  measure: {
    queue: 'сообщение',
    speed: 'сообщение/сек',
    count: 'сообщение',
    deliveryRate: '%'
  }
}

export default {
  title: 'Провайдеры',

  provider: 'Провайдер',
  providers: 'Провайдеры',
  one: 'Провайдер',
  many: 'Провайдеры',

  titles: {
    create: 'Создать провайдера',
    update: 'Обновить провайдера',
    remove: 'Удалить провайдера',
    properties: {
      add: 'Добавить свойство',
      edit: 'Редактировать свойство',
      notFound: 'Свойства не указаны'
    },
    replacements: {
      add: 'Добавить подмену контента',
      edit: 'Редактировать подмену контента',
      notFound: 'Подмена контента не указана'
    }
  },

  labels: {
    title: 'Заголовок',
    type: 'Тип',
    ownProcessor: 'Собственный процессор',
    settings: {
      protocol: 'Протокол',
      processor: 'Процессор',
      credentials: {
        username: 'Имя пользователя',
        password: 'Пароль'
      },
      server: {
        host: 'Хост',
        port: 'Порт'
      },
      limits: {
        bulk: 'Количество сообщений в запросе',
        concurrency: 'Количество одновременных запросов',
        rpm: 'Количество сообщений в минуту'
      }
    },
    search: 'Поиск',
    server: 'Сервер',
    credentials: 'Учетные данные',
    comment: 'Комментарий',
    version: 'Версия',
    text: 'Текст',
    sender: 'Имя',
    sendername: 'Имя отправителя',
    d360: {
      owner: 'Владелец',
      token: 'Токен'
    }
  },

  contents: {
    confirm: {
      remove: 'Подтвердите удаление провайдера.'
    }
  },

  dialogs: {
    new: 'Новый провайдер'
  },

  tabs: {
    send: 'Отправления',
    check: 'Проверка',
    ping: 'Ping',
    rates: 'Цены',
    logs: 'Логи'
  },

  subheaders: {
    general: 'Основное',
    settings: 'Настройки',
    credentials: 'Учетные данные',
    server: 'Сервер',
    options: 'Опции',
    limits: 'Лимиты'
  },

  chart: { labels: presets.labels },

  states: {
    inactive: 'Не активен',
    connecting: 'Подключение',
    authorizing: 'Авторизация',
    disconnecting: 'Отключение',
    active: 'Активен',
    stopped: 'Остановлен',
    'half-open': 'Временно активен'
  },

  buttons: {
    edit: 'Редактировать',
    remove: 'Удалить',
    add: {
      property: 'Свойство',
      replacement: 'Подмена'
    }
  },

  tooltips: {
    liveRefresh: 'Включить или выключить живое обновление аналитики.',
    isActive: 'Активировать или деактивировать провайдера.',
    incoming: 'Входящие',
    outgoing: 'Исходящие',
    logs: {
      online: 'Запрашивать логи с сервера раз в 15 секунд'
    },
    presets: {
      queue: `${presets.labels.queue}: {count} ${presets.measure.queue}\n\n${presets.hints.queue}`,
      speed: `${presets.labels.speed}: {count} ${presets.measure.speed}\n\n${presets.hints.speed}`,
      count: `${presets.labels.count}: {count} ${presets.measure.count}\n\n${presets.hints.count}`,
      deliveryRate: `${presets.labels.deliveryRate}: {count} ${presets.measure.deliveryRate}\n\n${presets.hints.deliveryRate}`
    },
    favorites: 'Сделать провайдера избранным'
  },

  measure: {
    presets: presets.measure
  },

  hints: {
    notFound: 'Провайдеры не найдены.',
    other: 'Прочие значения',
    rules: 'Условия',
    replacement: 'Данные для подмены контента',
    replacements: {
      hint: 'Подмена контента',

      notFound: 'Добавьте подмену контента нажав кнопку ниже.',

      rules: 'Условия',
      replacement: 'Подмена'
    },
    properties: {
      hint: 'Свойства',

      notFound: 'Добавьте свойства нажав кнопку ниже.',

      defaultWebhookId: 'ID для вебхука по умолчанию',
      customWebhookId: 'Кастомный ID для вебхука',
      messagePartIdPrefix: 'Кастомный префикс для ID части сообщения',

      keepAlive: 'Держать ли соединение открытым',

      noSeenStatus: '[FakeHTTP] Использовать ли статус seen в провайдере',
      failurePercent: '[FakeHTTP] Процент Failed статусов',

      chatPultIntegration: '[ChatPult] Передается в тело запроса при создании сообщения',

      tts: '[AWSAST | Telerobot] Тип синтезатор для трансформации текста в речь',
      trunk: '[AWSAST] 531544, quantek, trunk_84992774787',
      prefix: '[AWSAST] quantek - 392500',
      trycount: '[AWSAST] Значение по умолчанию 0, делается одна попытка, если указать 1, то апи будет воспринимать как одну дополнительную попытку помимо стандартной. Т.е. всего получается 2 попытки дозвона.',
      trydelay: '[AWSAST] Какая задержка между попытками дозвона в минутах',
      length: '[AWSAST] Количество рандомных символов с конца',
      record_timeout: '[AWSAST] Продолжительность записи ответа, 0 если нужно выключить запись. Undefined - значение по умолчанию астериска (45с)',
      wait_timeout: '[AWSAST] Задержка перед воспроизведением файла',
      dtmf_timeout: '[AWSAST] Сколько ждать секунд ответа от клиента',

      menu: '[Telerobot] 0 / 1 - Включает запись нажатия кнопок абонентом',
      asr: '[Telerobot] 0 / 1 - Распознавание голосового ответа пользователя в текст',

      resendSms: 'Переотправить как смс в случае недоставки',
      priority: '[ShkolainfoPush] Приоритет',
      validityPeriodSec: 'Время жизни сообщения',

      rus: '[SmartDelivery] По умолчанию 1',
      traffic_type: '[SmartDelivery] Тип трафика, по умолчанию \'promotion\'',

      proxy: 'Настройки прокси',

      accountId: '[Tyntec] ID аккаунта в Tyntec',

      routes: '[VKMailru] vk,ok / vk / ok',
      delivery_policy: '[VKMailru] Настройки доставки. mobile_device_required или ничего',
      ttl: '[VKMailru] Время жизни сообщения',

      dlr: '[Messaggio] 0 / 1 - Возвращать статус сообщения (по умолчанию 1)',
      dlr_timeout: '[Messaggio]',
      expiry_txt: '[Messaggio]',

      system_type: '[SMPP] Передается в bind_ команды',
      preferredDataCodings: '[SMPP] Устанавливает предпочитаемые кодировки (\'1:3,8\')',
      registeredDelivery: '[SMPP] Устанавливает способ получения статуса сообщения (query_sm/deliver_sm)',
      interface_version: '[SMPP] Устанавливает параметр interface_version при подключении по SMPP (3,5)',
      tls: '[SMPP] Флаг для подключения к SMPP через TLS',
      enquireLinkTimeout: '[SMPP] Таймаут запуска enquireLink команды',

      source_addr_ton: '[XSMPP] Передается в submit_sm команду',
      source_addr_npi: '[XSMPP] Передается в submit_sm команду',
      dest_addr_ton: '[XSMPP] Передается в submit_sm команду',
      dest_addr_npi: '[XSMPP] Передается в submit_sm команду',

      senderName: 'Имя отправителя для подмены',

      _testType: 'Тип отправления для теста',
      _testSendername: 'Имя отправителя для теста',
      _testRecipient: 'Получатель для теста',
      _testPayloadText: 'Текст для теста',

      rateLimit_concurrency: 'Количество одновременных запросов',
      rateLimit_messagesPerSecond: 'Количество сообщений в секунду'
    },
    presets: presets.hints
  },

  snackbars: {
    created: 'Провайдер успешно создан.',
    updated: 'Провайдер успешно обновлён.',
    removed: 'Провайдер успешно удалён.'
  }
}
