import { currentTab } from '@/utils'

import render from './render'

export default {
  name: 'PaymentsTemplate',

  mixins: [ currentTab('payments') ],

  computed: {
    tabs() {
      const result = []
      for (const service of [ 'payments', 'recurrentPayments' ]) {
        if (this.checkPermissions(`advanced.${service}.get`, [ 'me', 'reseller', true ])) {
          result.push(service)
        }
      }

      return result
    }
  },

  render
}
