import { unVue } from '@sigma-legacy-libs/unvue'

import TasksForm from '@/components/services/tasks/form'
import TasksFilter from '@/components/services/tasks/filter'
import RefreshButton from '@/components/button/refresh'
import HeadTitle from '@/components/misc/HeadTitle'
import pagination from '@/components/misc/pagination'

import { serviceName } from '@/components/services/tasks/utils'

const _ = {
  isEqual: require('lodash/isEqual'),
  merge: require('lodash/merge')
}

function renderFilterButton(h) {
  return h(
    'g-button',
    {
      class: 'ma-0',
      props: {
        label: this.viewport.breakpoint.mdUp ? this.getTranslate('misc.buttons.filter') : undefined,
        icon: 'filter_list',
        flat: true,
        rounded: this.viewport.breakpoint.mdUp
      },
      on: {
        click: () => {
          this.showFilter = !this.showFilter
        }
      }
    }
  )
}

function renderPresetsButtons(h) {
  if (this.viewport.breakpoint.mdUp) {
    if (Array.isArray(this.presets) && this.presets.length > 0) {
      return this.presets.map(preset => {
        return h(
          'g-button',
          {
            class: 'my-0',
            props: {
              label: this.getTranslate(`${serviceName}.buttons.${preset.title}`),
              flat: true,
              rounded: true,
              color: _.isEqual(this.filter, preset.filter) ? 'primary' : 'grey'
            },
            on: {
              click: () => {
                this.filter = unVue(preset.filter)
              }
            }
          }
        )
      })
    }
  }
}

function renderFilter(h) {
  if (this.showFilter) {
    return h(
      TasksFilter,
      {
        props: {
          defaultFilter: this.defaultFilter,
          value: this.filter,
          type: this.restData[serviceName].find.filter.type
        },
        on: {
          input: event => {
            this.filter = event
          }
        }
      }
    )
  }
}

function renderTable(h) {
  if (this.viewport.breakpoint.mdUp) {
    return h(
      'g-table',
      {
        props: {
          headers: this.computedHeaders,
          hideHead: this.restData[serviceName].find.pagination.total < 1 || this.checkPermissions(`advanced.${serviceName}.find`, [ 'me' ]),
          items: this.restData[serviceName].find.data,
          value: this.restData[serviceName].find.order,
          noDataText: this.getTranslate('misc.no.data.text')
        },
        on: {
          input: event => {
            this.restData[serviceName].find.order = event
          }
        },
        scopedSlots: {
          items: ({ item }) => this.$scopedSlots.row ? this.$scopedSlots.row({ item }) : this.$slots.row
        }
      }
    )
  }
}

function renderPanels(h) {
  if (this.viewport.breakpoint.smDown) {
    return h(
      'g-expansion-panels',
      {
        props: { flat: true }
      },
      this.restData[serviceName].find.data.map(item => {
        return this.$scopedSlots.panel ? this.$scopedSlots.panel({ item }) : this.$slots.panel
      })
    )
  }
}

function renderCreateDialog(h) {
  return h(
    'g-dialog',
    {
      props: {
        rounded: true,
        closeOnEsc: false,
        closeOnClick: false,
        value: this.showCreateTask,
        maxWidth: 550
      },
      on: {
        input: event => {
          this.showCreateTask = event
        }
      }
    },
    [
      h(
        HeadTitle,
        {
          props: { value: this.getTranslate(`${serviceName}.dialogs.create`) },
          slot: 'header'
        }
      ),

      h(
        TasksForm,
        {
          props: {
            value: this.restData[serviceName].create.data,
            errors: this.restData[serviceName].create.errors
          },
          on: {
            input: event => {
              this.restData[serviceName].create.data = event
            },
            validation: event => {
              this.restData[serviceName].create.isValid = event
            }
          }
        }
      ),

      h(
        'g-card-actions',
        {
          class: 'fjcfe facfe',
          slot: 'footer'
        },
        [
          h(
            'g-button',
            {
              props: {
                label: this.getTranslate('misc.buttons.cancel'),
                flat: true,
                rounded: true,
                disabled: this.restData[serviceName].create.state === 'loading'
              },
              on: {
                click: () => {
                  this.showCreateTask = false
                }
              }
            }
          ),
          h(
            'g-button',
            {
              props: {
                label: this.getTranslate('misc.buttons.create'),
                rounded: true,
                depressed: true,
                loading: this.restData[serviceName].create.state === 'loading',
                disabled: !this.createDataIsValid,
                color: 'primary'
              },
              on: {
                click: async () => {
                  await this.createTask()
                }
              }
            }
          )
        ]
      )
    ]
  )
}

function renderCreateButton(h) {
  if (this.$route.name === 'billings') {
    if (this.checkPermissions(`advanced.${serviceName}.create`, [ 'reseller', true ])) {
      return h(
        'g-button',
        {
          style: { 'margin-bottom': this.viewport.breakpoint.smDown ? '66px' : undefined },
          props: {
            fab: true,
            icon: 'add',
            fixed: true,
            bottom: true,
            right: true,
            color: 'secondary'
          },
          on: {
            click: () => {
              this.showCreateTask = true
            }
          },
          scopedSlots: {
            dialog: () => renderCreateDialog.call(this, h)
          }
        }
      )
    }
  }
}

function renderPagination(h) {
  return h(
    'div',
    {
      class: 'fjcfe faic pa-2'
    },
    [
      h(
        pagination, {
          props: {
            value: this.restData[serviceName].find.pagination
          },
          on: {
            input: event => {
              this.restData[serviceName].find.pagination = event
            }
          }
        }
      )
    ]
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: 'fc'
    },
    [
      h(
        'div',
        {
          class: 'faic pa-2'
        },
        [
          renderFilterButton.call(this, h),
          renderPresetsButtons.call(this, h),

          h('div', { class: 'ff' }),

          h(
            RefreshButton,
            {
              class: 'ma-0',
              props: {
                state: this.restData[serviceName].find.state,
                shortClickMethod: () => this.rest[serviceName].find(),
                longClickMethod: () => this.rest[serviceName].find({}, { noCache: true })
              }
            }
          )
        ]
      ),

      renderFilter.call(this, h),
      renderPagination.call(this, h),
      renderTable.call(this, h),
      renderPanels.call(this, h),
      renderPagination.call(this, h),
      renderCreateButton.call(this, h)
    ]
  )
}
