import { classMerger, moderationColors, moderationIcons } from '@/utils'

export default function(h, ctx) {
  return h(
    'div',
    {
      class: classMerger('fr faic', ctx.data.class),
      style: {
        'align-items': 'center',
        ...ctx.data.style
      }
    },
    [
      h('g-icon', {
        class: `mr-${ctx.props.distanceBetween}`,
        props: {
          value: moderationIcons[ctx.props.type],
          size: ctx.props.size,
          color: moderationColors[ctx.props.type]
        }
      }),
      ctx.props.title
    ]
  )
}
