export default {
  title: 'Provider groups',

  titles: {
    create: 'Create provider group'
  },

  providerGroup: 'Provider group',
  providerGroups: 'Provider groups',
  one: 'Provider group',
  many: 'Provider groups',

  labels: {
    title: 'Group title',
    type: 'Type',
    provider: 'Provider',
    search: 'Search'
  },

  dialogs: {
    new: 'New provider group'
  },

  subheaders: {
    providers: 'Providers'
  },

  buttons: {
    add: {
      provider: 'Add provider'
    }
  },

  hints: {
    notFound: 'Provider groups not found'
  },

  tooltips: {
    isActive: 'Activate or deactivate a provider group.',
    favorites: 'Make the provider group a favorite',
    providers: 'List of providers'
  },

  subtitles: {
    providers: 'Providers'
  },

  snackbars: {
    created: 'Provider group successfully created.',
    updated: 'Provider group successfully updated.',
    removed: 'Provider group successfully removed.'
  }
}
