import token from '@/components/services/tokens/item/token'

function renderToken(h) {
  return h(
    token,
    {
      props: {
        value: this.value,
        buttons: true
      }
    }
  )
}

export default function(h) {
  return h(
    'g-card',
    {
      class: 'hover-parent hover-parent--flex pa-2',
      props: {
        outline: true,
        rounded: true
      }
    },
    [ renderToken.call(this, h) ]
  )
}
