import Vue from 'vue'

const name = 'login'

export default function(h) {
  return h(
    'g-form',
    {
      class: 'grid grid-cols--1',
      on: {
        submit: () => {
          this.$emit('submit', this.proxy)
        },
        validation: event => {
          this.$emit('validation', event)
        }
      }
    },
    [
      h(
        'g-text-field',
        {
          props: {
            value: this.proxy.username,
            label: this.getTranslate(`${name}.labels.username`),
            autocomplete: 'username',
            mode: 'outline',
            rounded: true,
            details: false,
            autofocus: true,
            required: true
          },
          on: {
            input: event => {
              this.proxy.username = event
            }
          }
        }
      ),

      h(
        'g-text-field',
        {
          props: {
            value: this.proxy.password,
            label: this.getTranslate(`${name}.labels.password`),
            afterIcon: this.passwordVisibility ? 'visibility' : 'visibility_off',
            afterIconCallback: () => this.passwordVisibility = !this.passwordVisibility,
            mode: 'outline',
            rounded: true,
            details: false,
            clearable: true,
            autocomplete: 'current-password',
            type: this.passwordVisibility ? 'password' : 'text',
            required: true
          },
          on: {
            input: event => {
              this.proxy.password = event
            }
          }
        }
      ),

      h(
        'div',
        {
          class: 'fjcfe facfe caption link text--grey',
          on: {
            click: () => {
              Vue.router.push({ name: 'recover' })
            }
          }
        },
        this.getTranslate('recover.hints.recover')
      )
    ]
  )
}
