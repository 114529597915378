import Vue from 'vue'

import LoginForm from '@/components/services/login/form'

import { serviceName } from '@/components/services/login/utils'

import { STATES } from '@/utils'

const _ = { get: require('lodash/get') }

const maxWidth = 380

function renderPlatformTitle(h) {
  const title = _.get(this.globalSettings, 'frontend.title')
  if (title) {
    return h('div', { class: 'headline text--grey' }, title)
  }
}

function renderHeader(h) {
  return h(
    'div',
    {
      class: 'faic px-3'
    },
    [
      h('div', { class: 'headline ff' }, this.getTranslate(`${serviceName}.title`)),

      renderPlatformTitle.call(this, h)
    ]
  )
}

function renderBody(h) {
  return h(
    'g-card',
    {
      props: {
        rounded: true,
        maxWidth,
        width: '100%'
      }
    },
    [
      h(
        LoginForm,
        {
          class: 'pa-3',
          props: { value: this.payload },
          on: {
            input: event => {
              this.payload = event
            },
            submit: () => {
              this.login(this.payload)
            },
            validation: event => {
              this.isValid = event
            }
          }
        }
      )
    ]
  )
}

function renderFooter(h) {
  return h(
    'div',
    {
      class: 'fjcfe'
    },
    [
      h(
        'g-button',
        {
          props: {
            label: this.getTranslate(`${serviceName}.buttons.signup`),
            flat: true,
            rounded: true,
            disabled: this.status === STATES.loading
          },
          on: {
            click: () => {
              Vue.router.push({ name: 'registration' })
            }
          }
        }
      ),

      h(
        'g-button',
        {
          class: 'mr-0',
          props: {
            label: this.getTranslate(`${serviceName}.buttons.signin`),
            rounded: true,
            depressed: true,
            loading: this.status === STATES.loading,
            disabled: this.status === STATES.loading || !this.isValid,
            color: 'primary'
          },
          on: {
            click: () => {
              this.login(this.payload)
            }
          }
        }
      )
    ]
  )
}

function renderContent(h) {
  return h(
    'div',
    {
      class: 'grid grid-cols--1 grid-gap--8',
      style: {
        'max-width': `${maxWidth}px`,
        width: '100%'
      }
    },
    [
      renderHeader.call(this, h),
      renderBody.call(this, h),
      renderFooter.call(this, h)
    ]
  )
}

export default function(h) {
  return renderContent.call(this, h)
}
