export default function(h) {
  const directives = []
  if (this.tooltip) {
    directives.push(
      {
        name: 'g-tooltip',
        options: {
          value: this.tooltip,
          placement: 'top'
        }
      }
    )
  }

  return h(
    'g-button',
    {
      props: {
        flat: true,
        icon: 'refresh',
        label: this.label,
        rounded: !!this.label,
        loading: typeof this.state === 'boolean' ? this.state : this.state === 'loading',
        disabled: typeof this.state === 'boolean' ? this.state : this.state === 'loading' || this.disabled
      },
      directives,
      on: {
        mousedown: this.clickHandler,
        mouseup: this.clickHandler,
        touchstart: this.clickHandler,
        touchend: this.clickHandler
      }
    }
  )
}
