import { serviceName } from '@/components/services/requisites/utils'

const defaultPropsForTextField = {
  mode: 'outline',
  trim: true,
  dense: true,
  rounded: true,
  clearable: true,
  details: false
}

function renderFieldMatches(h) {
  if (this.matches) {
    return h(
      'div',
      {
        class: 'faic grid grid-gap--8',
        style: {
          'grid-template-columns': '36px 1fr'
        }
      },
      [
        h(
          'g-switch',
          {
            props: {
              value: this.addressMatches
            },
            on: {
              input: event => {
                this.addressMatches = event
              }
            }
          }
        ),

        h(
          'div',
          {
            class: 'text--grey caption'
          },
          [ this.getTranslate(`${serviceName}.labels.addressMatches`) ]
        )
      ]
    )
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8'
    },
    [
      h(
        'div',
        {
          class: {
            'grid grid-gap--8 faifs': true,
            'grid-cols--2': this.viewport.breakpoint.mdUp
          }
        },
        [
          h(
            'div',
            {
              class: 'grid grid-gap--8'
            },
            [
              h(
                'g-text-field',
                {
                  props: {
                    value: this.proxy.index,
                    label: this.getTranslate(`${serviceName}.labels.index`),
                    error: this.proxy.index && !/^\d{6}$/.test(this.proxy.index) ? this.getTranslate(`${serviceName}.errors.index`) : undefined,
                    disabled: this.addressMatches,
                    ...defaultPropsForTextField,
                    mask: '000000',
                    validateOnBlur: true,
                    required: true
                  },
                  on: {
                    input: event => {
                      this.proxy.index = event
                    }
                  }
                }
              ),

              h(
                'g-text-field',
                {
                  props: {
                    value: this.proxy.country,
                    label: this.getTranslate(`${serviceName}.labels.country`),
                    disabled: this.addressMatches,
                    ...defaultPropsForTextField,
                    required: true
                  },
                  on: {
                    input: event => {
                      this.proxy.country = event
                    }
                  }
                }
              ),

              h(
                'g-text-field',
                {
                  props: {
                    value: this.proxy.city,
                    label: this.getTranslate(`${serviceName}.labels.city`),
                    disabled: this.addressMatches,
                    ...defaultPropsForTextField,
                    required: true
                  },
                  on: {
                    input: event => {
                      this.proxy.city = event
                    }
                  }
                }
              )
            ]
          ),

          h(
            'div',
            {
              class: 'grid grid-gap--8'
            },
            [
              h(
                'g-text-field',
                {
                  props: {
                    value: this.proxy.address,
                    label: this.getTranslate(`${serviceName}.labels.address`),
                    disabled: this.addressMatches,
                    ...defaultPropsForTextField
                  },
                  on: {
                    input: event => {
                      this.proxy.address = event
                    }
                  }
                }
              ),

              h(
                'g-text-field',
                {
                  props: {
                    value: this.proxy.phone,
                    label: this.getTranslate(`${serviceName}.labels.phone`),
                    disabled: this.addressMatches,
                    mask: [
                      {
                        mask: '+0 000 000-00-00',
                        startsWith: '7',
                        lazy: false,
                        country: 'Russia'
                      }
                    ],
                    type: 'tel',
                    validateOnBlur: true,
                    ...defaultPropsForTextField
                  },
                  on: {
                    input: event => {
                      this.proxy.phone = event
                    }
                  }
                }
              ),

              h(
                'g-text-field',
                {
                  props: {
                    value: this.proxy.phoneAdd,
                    label: this.getTranslate(`${serviceName}.labels.phoneAdd`),
                    disabled: this.addressMatches,
                    ...defaultPropsForTextField
                  },
                  on: {
                    input: event => {
                      this.proxy.phoneAdd = event
                    }
                  }
                }
              )
            ]
          )
        ]
      ),

      renderFieldMatches.call(this, h)
    ]
  )
}
