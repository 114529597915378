import { fillDependence, generateServices, permissionPresets } from '@/utils'

import { serviceName } from '@/components/services/tariffRatesUpdating/utils'

import render from './render'

export default {
  name: serviceName,

  mixins: [
    generateServices({
      name: serviceName,

      async inputFilter(result) {
        if (!result.ParentTariff) {
          await fillDependence.call(this, result, {
            service: 'tariffs',
            permissions: permissionPresets.admin,
            pathToId: 'parentTariffId',
            pathToObject: 'ParentTariff',
            outputPath: 'ParentTariff',
            picks: [ 'id', 'title', 'type' ]
          })
        }
        if (!result.ChildTariff) {
          await fillDependence.call(this, result, {
            service: 'tariffs',
            permissions: permissionPresets.admin,
            pathToId: 'childTariffId',
            pathToObject: 'ChildTariff',
            outputPath: 'ChildTariff',
            picks: [ 'id', 'title', 'type' ]
          })
        }

        return result
      },

      get: false,
      remove: false,
      update: false
    })
  ],

  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      showDialog: false,
      parent: undefined,
      child: undefined
    }
  },

  watch: {
    value: {
      handler() {
        this.get()
      },
      deep: true
    }
  },

  mounted() {
    this.get()
  },

  methods: {
    async get() {
      if (this.value && this.value.id) {
        const id = this.value.id
        const [ parent ] = await this.rest[serviceName].find({ query: { childTariffId: id } })
        const child = await this.rest[serviceName].find({ query: { parentTariffId: id } })
        this.parent = parent
        this.child = child
      }
    },

    createParent() {
      this.restData[serviceName].create.data.childTariffId = this.value.id
      this.showDialog = true
    },
    createChild() {
      this.restData[serviceName].create.data.parentTariffId = this.value.id
      this.showDialog = true
    }
  },

  render
}
