import notificationsEventsBillingsLimit from '@/components/services/settings/notifications/events/billings/limit'

export default function(h, { serviceName }) {
  return h(
    notificationsEventsBillingsLimit({ serviceName }),
    {
      props: { value: this.proxy },
      on: {
        input: event => {
          this.proxy = event
        }
      }
    }
  )
}
