import Vue from 'vue'

const _ = { get: require('lodash/get') }

function renderContent(h) {
  return h(
    'div',
    {
      class: 'faic',
      directives: [
        {
          name: 'g-tooltip',
          options: { value: this.getTranslate('credentials.title') }
        }
      ]
    },
    [
      h(
        'div',
        {
          class: 'fjcc facc faic square--32 mr-2'
        },
        [
          h(
            'g-icon',
            {
              props: {
                value: 'vpn_key',
                color: 'grey'
              }
            }
          )
        ]
      ),

      h(
        'div',
        {
          class: 'body-1 text-overflow link link--passive',
          on: {
            click: () => {
              Vue.router.push({
                name: 'credentials.single',
                params: { id: _.get(this.value, 'Credentials.id') }
              })
            }
          }
        },
        _.get(this.value, 'Credentials.title')
      )
    ]
  )
}

export default function(h) {
  return renderContent.call(this, h)
}
