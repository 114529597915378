import { SENDING_STATES, moderationColors, moderationIcons, sendingTypeColors } from '@/utils'

import HintRow from '@/components/misc/HintRow'
import formatTable from '@/components/format/table'

import { serviceName } from '@/components/services/sendings/utils'

const _ = { get: require('lodash/get') }

function renderError(h, error) {
  if (error) {
    return h(HintRow, { props: { error } })
  }
}

export default function(h) {
  if (this.value) {
    const moderation = _.get(this.value, 'state.moderation', 'pending')
    const status = _.get(this.value, 'state.status')

    const status_color = _.get(SENDING_STATES, `${status}.color`, '')
    const status_name = _.get(SENDING_STATES, `${status}.name`, '')
    const status_icon = _.get(SENDING_STATES, `${status}.icon`, '')

    const error = _.get(this.value, 'state.error')

    return h(
      'div',
      {
        class: 'grid grid-cols--1 py-3'
      },
      [
        h(
          'div',
          {
            class: 'faic fw px-3'
          },
          [
            h(
              'g-chip',
              {
                class: 'ml-0 text-upper',
                props: {
                  small: true,
                  outline: true,
                  color: sendingTypeColors[this.value.type],
                  label: this.value.type
                }
              }
            ),

            h(
              'g-chip',
              {
                class: 'text-upper',
                props: {
                  small: true,
                  outline: true,
                  icon: status_icon,
                  color: status_color,
                  label: this.getTranslate(`${serviceName}.statuses.${status_name}`)
                }
              }
            ),

            h(
              'g-chip',
              {
                class: 'text-upper',
                props: {
                  small: true,
                  outline: true,
                  icon: moderationIcons[moderation],
                  color: moderationColors[moderation],
                  label: this.getTranslate(`misc.moderation.${moderation}`).toLowerCase()
                }
              }
            )
          ]
        ),

        renderError.call(this, h, error),

        h(formatTable, { props: { value: this.value } })
      ]
    )
  }
}
