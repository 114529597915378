import { unVue } from '@sigma-legacy-libs/unvue'

import { getRUB } from '@/utils'

import ExpandArrow from '@/components/misc/ExpandArrow'
import InvoiceDialog from '@/components/services/settings/notifications/events/billings/limit/InvoiceDialog'

const key = 'notifications.events.billings.limit'

function renderTitle(h) {
  return h(
    'div',
    {
      class: 'faic pl-3'
    },
    [
      h('div', { class: 'mr-1' }, this.getTranslate(`settingsUser.subtitles.${key}`)),
      h('div', { class: 'text-upper text--grey' }, this.getTranslate('sendings.types.email'))
    ]
  )
}

function renderInvoiceDialog(h, { UserId, ResellerId }) {
  if (Array.isArray(this.proxy) && this.proxy.length) {
    return h(
      InvoiceDialog, {
        props: {
          value: unVue(this.proxy[this.limitIndex].invoice),
          OwnerId: UserId,
          ResellerId,
          showForm: this.showInvoiceDialog
        },
        on: {
          invoice: event => {
            this.proxy[this.limitIndex].invoice = unVue(event)
          },
          show: event => {
            this.showInvoiceDialog = event
          }
        }
      }
    )
  }
}

function renderCaption(h, limit, toggle, { serviceName }) {
  if (!toggle) {
    let amount
    let hintOverdraft = ''

    const hint = []

    if (limit.includeOverdraft) {
      hint.push(this.getTranslate(`${serviceName}.labels.${key}.include.overdraft`))
      amount = getRUB(limit.balance - this.overdraft)
      if (this.overdraft === undefined) {
        hintOverdraft = this.getTranslate(`${serviceName}.hints.${key}.overdraft`)
      }
    } else {
      hint.push(this.getTranslate(`${serviceName}.labels.${key}.notInclude.overdraft`))
      amount = getRUB(limit.balance)
    }

    if (limit.invoice) {
      hint.push(this.getTranslate(`${serviceName}.labels.${key}.include.invoice`))
    }

    return h(
      'div',
      {
        class: 'grid grid-cols--1 grid-gap--2 small text--grey'
      },
      [
        h('div', {}, hint.join(', ')),
        h('div', {}, this.getTranslate(`${serviceName}.hints.${key}.activator`, { limit: amount + hintOverdraft }))
      ]
    )
  }
}

function renderInvoiceCaption(h, limit, index, { serviceName }) {
  if (limit.invoice) {
    return h(
      'div',
      {
        class: 'caption link link--dashed px-3',
        on: {
          click: () => {
            this.limitIndex = index
            this.showInvoiceDialog = true
          }
        }
      },
      this.getTranslate(`${serviceName}.hints.${key}.invoice`, { amount: limit.invoice.data.SERVICE_PRICE })
    )
  }
}

function renderAttachInvoiceButton(h, limit, index, { serviceName }) {
  if (limit.invoice) {
    return h(
      'g-button',
      {
        props: {
          label: this.getTranslate(`${serviceName}.buttons.${key}.invoice.detach`),
          flat: true,
          rounded: true,
          depressed: true,
          color: 'warning'
        },
        on: {
          click: () => {
            limit.invoice = false
          }
        }
      }
    )
  } else {
    return h(
      'g-button',
      {
        props: {
          label: this.getTranslate(`${serviceName}.buttons.${key}.invoice.attach`),
          flat: true,
          rounded: true,
          depressed: true,
          color: 'primary'
        },
        on: {
          click: () => {
            this.limitIndex = index
            this.showInvoiceDialog = true
          }
        }
      }
    )
  }
}

function renderRemoveLink(h, expanded, index) {
  if (this.viewport.breakpoint.mdUp) {
    if (!expanded) {
      return h(
        'div',
        {
          class: 'link link--dashed link--error caption',
          on: {
            click: () => {
              this.remove(index)
            }
          }
        },
        this.getTranslate('misc.buttons.remove')
      )
    }
  }
}

function renderLimitField(h, limit, trigger, { serviceName }) {
  return h(
    'g-text-field',
    {
      props: {
        value: limit.balance,
        placeholder: this.getTranslate(`${serviceName}.labels.${key}.limit`),
        defaultValue: 0,
        clearable: true,
        autofocus: true,
        mode: trigger ? 'solo' : 'outline',
        flat: trigger,
        dense: true,
        rounded: true,
        details: false,
        suffix: '&#8381;',
        type: 'number',
        step: 'any'
      },
      on: {
        input: event => {
          limit.balance = event
        }
      }
    }
  )
}

function renderHeaderTitle(h, limit, options) {
  return h(
    'div',
    {
      class: 'faic'
    },
    [
      this.getTranslate(`${options.serviceName}.hints.${key}.onLimit`),

      h(
        'g-menu',
        {
          props: {
            closeOnContentClick: false,
            rounded: true,
            maxWidth: 150
          }
        },
        [
          h(
            'div',
            {
              class: 'mx-1 link link--dashed',
              slot: 'activator'
            },
            [ getRUB(limit.balance) ]
          ),

          renderLimitField.call(this, h, limit, true, options)
        ]
      )
    ]
  )
}

function renderLimits(h, options) {
  if (Array.isArray(this.proxy) && this.proxy.length) {
    return h(
      'g-expansion-panels',
      {
        props: {
          rounded: true,
          outline: true
        }
      },
      this.proxy.map((limit, index) => {
        return h(
          'g-expansion-panel',
          {
            props: { preventClick: true },
            scopedSlots: {
              header: ({ expanded, toggle }) => {
                return h(
                  'div',
                  {
                    class: 'fc ff body-1'
                  },
                  [
                    h(
                      'div',
                      {
                        class: 'fjcsb faic pl-3'
                      },
                      [
                        renderHeaderTitle.call(this, h, limit, options),

                        h(
                          ExpandArrow,
                          {
                            props: {
                              expanded,
                              toggle
                            }
                          }
                        )
                      ]
                    ),

                    h(
                      'div',
                      {
                        class: { 'fjcsb faife pb-3 px-3': !expanded }
                      },
                      [
                        renderCaption.call(this, h, limit, expanded, options),
                        renderRemoveLink.call(this, h, expanded, index, options)
                      ]
                    )
                  ]
                )
              }
            }
          },
          [
            h(
              'div',
              {
                class: 'grid grid-cols--1 mt-3'
              },
              [
                h(
                  'div',
                  {
                    class: {
                      'grid faic px-3': true,
                      'grid-cols--2': this.viewport.breakpoint.mdUp,
                      'grid-cols--1': this.viewport.breakpoint.smDown
                    }
                  },
                  [
                    renderLimitField.call(this, h, limit, false, options),

                    h(
                      'g-switch',
                      {
                        props: {
                          value: limit.includeOverdraft,
                          label: this.getTranslate(`${options.serviceName}.labels.${key}.include.overdraft`)
                        },
                        on: {
                          input: event => {
                            limit.includeOverdraft = event
                          }
                        }
                      }
                    )
                  ]
                ),

                renderInvoiceCaption.call(this, h, limit, index, options),

                h(
                  'g-card-actions',
                  {
                    class: 'fjcfe facfe'
                  },
                  [
                    renderAttachInvoiceButton.call(this, h, limit, index, options),

                    h(
                      'g-button',
                      {
                        props: {
                          label: this.getTranslate('misc.buttons.remove'),
                          flat: true,
                          rounded: true,
                          color: 'error'
                        },
                        on: {
                          click: () => {
                            this.remove(index)
                          }
                        }
                      }
                    )
                  ]
                )
              ]
            )
          ]
        )
      })
    )
  }
}

function renderAddLimitButton(h) {
  return h(
    'g-button',
    {
      props: {
        label: this.getTranslate('misc.buttons.add'),
        icon: 'add',
        flat: true,
        rounded: true
      },
      on: {
        click: () => {
          this.add()
        }
      }
    }
  )
}
function renderSaveLimitButton(h) {
  return h(
    'g-button',
    {
      props: {
        label: this.getTranslate('misc.buttons.save'),
        flat: true,
        rounded: true,
        color: 'primary'
      },
      on: {
        click: () => {
          this.$emit('input', unVue(this.proxy))
        }
      }
    }
  )
}

function renderFooter(h, options) {
  return h(
    'g-card-actions',
    {
      class: 'fjcfe pa-0'
    },
    [
      renderAddLimitButton.call(this, h, options),
      renderSaveLimitButton.call(this, h, options)
    ]
  )
}

export default function(h, options) {
  return h(
    'div',
    {
      class: 'grid grid-cols--1 grid-gap--8'
    },
    [
      renderTitle.call(this, h, options),
      renderLimits.call(this, h, options),
      renderFooter.call(this, h, options),
      renderInvoiceDialog.call(this, h, options)
    ]
  )
}
