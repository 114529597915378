import exceptions from '@/components/services/tariffRatesUpdating/form/exceptions'

import { serviceName } from '@/components/services/tariffRatesUpdating/utils'

function renderFieldParentTariff(h) {
  return h(
    'g-select',
    {
      props: {
        value: this.proxy.parentTariffId,
        label: this.getTranslate(`${serviceName}.labels.tariff.parent`),
        itemsDisabled: this.parents,
        service: 'tariffs',
        mode: 'outline',
        dense: true,
        rounded: true,
        details: false
      },
      on: {
        input: event => {
          this.proxy.parentTariffId = event
        }
      }
    }
  )
}

function renderFieldChildTariff(h) {
  return h(
    'g-select',
    {
      props: {
        value: this.proxy.childTariffId,
        label: this.getTranslate(`${serviceName}.labels.tariff.child`),
        itemsDisabled: [ this.proxy.parentTariffId ],
        service: 'tariffs',
        mode: 'outline',
        dense: true,
        rounded: true,
        details: false
      },
      on: {
        input: event => {
          this.proxy.childTariffId = event
        }
      }
    }
  )
}

function renderExceptions(h) {
  return h(
    exceptions,
    {
      props: {
        value: this.proxy.data.exceptions
      },
      on: {
        input: event => {
          this.proxy.data.exceptions = event
        }
      }
    }
  )
}

function renderFieldDefault(h) {
  return h(
    'g-text-field',
    {
      props: {
        value: this.proxy.data.default,
        label: this.getTranslate(`${serviceName}.labels.default`),
        mode: 'outline',
        type: 'number',
        step: '0.0001',
        dense: true,
        rounded: true,
        details: false
      },
      on: {
        input: event => {
          this.proxy.data.default = event
        }
      }
    }
  )
}

export default function(h) {
  return h(
    'g-form',
    {
      class: 'grid grid-gap--8',
      on: {
        submit: () => {
          this.$emit('submit', this.proxy)
        }
      }
    },
    [
      h(
        'div',
        {
          class: 'grid grid-gap--8',
          style: { 'grid-template-columns': '1fr 24px 1fr' }
        },
        [
          renderFieldParentTariff.call(this, h),

          h(
            'g-icon',
            {
              props: {
                value: 'arrow_right'
              }
            }
          ),

          renderFieldChildTariff.call(this, h)
        ]
      ),

      renderExceptions.call(this, h),
      renderFieldDefault.call(this, h)
    ]
  )
}
