import proxy from '@sigma-legacy-libs/g-proxy'
import textareaCaret from 'textarea-caret'

import { unVue } from '@sigma-legacy-libs/unvue'
import { calculateSmsStats, calculateVoiceStats } from '@sigma-legacy-libs/essentials/lib/utils/smpp'

import render from './render'

const _ = {
  throttle: require('lodash/throttle'),
  escapeRegExp: require('lodash/escapeRegExp'),
  debounce: require('lodash/debounce')
}

export default {
  name: 'CatchSymbolsInput',

  mixins: [ proxy({ defaultValue: '' }) ],

  props: {
    tags: {
      type: Array,
      default: () => []
    },

    component: {
      type: String,
      default: 'g-text-field'
    },

    customMenu: {
      type: Array,
      default: () => []
    },

    sendingType: String,

    type: String,
    hint: String,
    mode: String,
    label: String,

    required: Boolean,
    disabled: Boolean,
    readonly: Boolean,
    rounded: Boolean,
    dense: Boolean,

    maxLength: Number,
    error: String
  },

  data() {
    return {
      catchInput: document.getElementById(`input-${this._uid}`),

      tag: null,
      section: null,

      focus: false,

      currentOption: -1,

      showDialog: false
    }
  },

  computed: {
    computedText() {
      const proxy = unVue(this.proxy)
      if (proxy) {
        return proxy.replace(this.computedTags.regExpFullTags, '')
      }

      return ''
    },

    computedHint() {
      let hint = this.getTranslate('sendings.statistic.symbols') + this.computedText.length

      switch (this.sendingType) {
        case 'sms':
          hint += '; ' + this.getTranslate('sendings.statistic.parts') + calculateSmsStats(this.computedText).segments
          break
        case 'voice':
          hint +=
            '; ' + this.getTranslate('sendings.statistic.segment') + calculateVoiceStats(this.computedText).segments
          break
      }

      return hint
    },

    computedMenu() {
      if (this.section) {
        return this.section.menu
      }

      return []
    },

    computedTags() {
      const tags = this.tags || []
      const tagsDefinition = {}
      const starts = []
      const ends = []

      for (const tag of tags) {
        if (tag.start && tag.end) {
          const start = _.escapeRegExp(tag.start)
          const end = _.escapeRegExp(tag.end)

          tagsDefinition[tag.start] = {
            start: tag.start,
            end: tag.end,
            sections: (Array.isArray(tag.sections) && tag.sections.length ? tag.sections : [ true ]).map(section => {
              if (section === true) {
                return section
              }

              return {
                menu: Object.keys(typeof section.menu === 'object' ? section.menu : {}).map(key => {
                  return {
                    title: section.menu[key],
                    value: key
                  }
                })
              }
            })
          }

          starts.push(start)
          ends.push(end)
        }
      }

      const startsGroup = `(${[ ...starts, '\\|' ].join('|')})`
      const endsOptionalGroup = `((?:${ends.map(t => `\\${t}`).join('|')}|[^${ends.join('')}])*?)`
      const endsGroup = `(${[ ...ends, '$' ].join('|')})`

      const regExpFullTags = new RegExp(`${startsGroup}${endsOptionalGroup}${endsGroup}`, 'gmi')

      return {
        starts,
        ends,
        tagsDefinition,
        regExpFullTags
      }
    },

    computedPosition() {
      this.getInput()

      let caret = {
        top: 0,
        left: 0,
        height: 0
      }
      const position = this.section ? this.section.absStart : 0
      if (~position && this.catchInput) {
        caret = textareaCaret(this.catchInput, position)
      }

      return {
        top: caret.top || 0,
        left: caret.left || 0,
        height: (caret.height || 0) + 16
      }
    },

    showMenu() {
      let result = false
      if (!this.focus) {
        return result
      }

      if (this.section) {
        if (this.section.index === 0 || this.section.index === 2) {
          result = !!this.computedMenu.length
        }
      }

      if (!result) {
        this.currentOption = -1 // eslint-disable-line
      }

      return result
    }
  },

  mounted() {
    this.getInput()
    this.getTagsFromText()
  },

  beforeDestroy() {
    this.catchInput = undefined
  },

  methods: {
    blurInput() {
      this.getInput()
      this.catchInput.blur()
    },
    focusInput() {
      this.getInput()
      this.catchInput.focus()
    },

    inRange(start, end, selectionStart, selectionEnd) {
      return start <= selectionStart && end >= selectionStart && start <= selectionEnd && end >= selectionEnd
    },

    getTagsFromText: _.throttle(
      function() {
        this.getInput()

        const selectionStart = this.catchInput.selectionStart
        const selectionEnd = this.catchInput.selectionEnd
        const fullText = this.catchInput.value

        let lastTagEnded = 0
        const tags = (fullText.match(this.computedTags.regExpFullTags) || []).map(tag => {
          const sections = []

          const start = fullText.indexOf(tag, lastTagEnded)
          const end = start + tag.length
          lastTagEnded = end

          let cursor = 0
          let isOpened = true
          let somethingLeft = true
          let lastSectionEndedWith = ''
          let matches
          let openingTag
          let tagDefinition

          const startsGroup = `(${this.computedTags.starts.join('|')})?`
          const endsOptionalGroup = `((?:${this.computedTags.ends.map(v => `\\${v}`).join('|')}|[^${this.computedTags.ends.join('')}])*?)`
          const endsGroup = `(?:\\||${this.computedTags.ends.join('|')}|$)`

          const regExpMatchSections = new RegExp(`${startsGroup}${endsOptionalGroup}${endsGroup}`, 'g')

          while (somethingLeft && (matches = regExpMatchSections.exec(tag)) !== null) {
            const sectionContentFull = matches[0]
            const sectionContent = matches[2]
            const lastChar = sectionContentFull.slice(-1)

            if (!openingTag) {
              openingTag = matches[1]
              if (!tagDefinition) {
                tagDefinition = this.computedTags.tagsDefinition[openingTag]
              }
            }

            if (
              sectionContent ||
              !sectionContent && (!lastSectionEndedWith || lastSectionEndedWith === '|' || lastChar === '|')
            ) {
              const insidePosition = ~sectionContentFull.indexOf(tagDefinition.start) ? tagDefinition.start.length : 0
              const startWithinTag = cursor + insidePosition
              const endWithinTag = startWithinTag + sectionContent.length
              cursor = endWithinTag + (sectionContentFull.length - sectionContent.length - insidePosition)

              sections.push({
                index: sections.length,
                sectionContent,
                start: startWithinTag,
                end: endWithinTag,
                absStart: start + startWithinTag,
                absEnd: start + endWithinTag,
                menu: tagDefinition.sections && tagDefinition.sections[sections.length].menu || []
              })
              lastSectionEndedWith = lastChar
              isOpened = tagDefinition.end !== lastChar || tagDefinition.start === tag
            }

            if (
              !isOpened ||
              !sectionContent && lastChar !== '|' ||
              tagDefinition && tagDefinition.sections.length <= sections.length
            ) {
              somethingLeft = false
            }
          }

          return {
            tag,
            start,
            end,
            sections,
            tagStart: tagDefinition.start,
            tagEnd: tagDefinition.end,
            isOpened
          }
        })

        const tag = tags.find(tag => {
          return this.inRange(
            tag.start + tag.tagStart.length,
            tag.end + tag.tagEnd.length,
            selectionStart,
            selectionEnd
          )
        }) || null

        this.tag = tag
        this.section = tag && tag.sections.find(section => this.inRange(section.absStart, section.absEnd, selectionStart, selectionEnd)) || null
      },
      150,
      {
        leading: false,
        trailing: true
      }
    ),

    setOption(option) {
      if (option < 0) {
        option = 0
      } else if (option >= this.computedMenu.length - 1) {
        option = this.computedMenu.length - 1
      }

      this.currentOption = option
    },

    insertText(text) {
      this.focusInput()

      const startCaret = this.section ? this.section.absStart + 1 : this.catchInput.selectionStart
      const endCaret = this.section ? this.section.absStart + 1 : this.catchInput.selectionEnd

      const sliceStart = this.proxy ? this.proxy.slice(0, this.section ? this.section.absStart : this.catchInput.selectionEnd) : ''
      const sliceEnd = this.proxy ? this.proxy.slice(this.section ? this.section.absEnd : this.catchInput.selectionEnd) : ''
      const tag = this.tag && this.tag.isOpened ? this.tag.tagEnd : ''

      this.proxy = sliceStart + text + tag + sliceEnd

      this.$nextTick(() => {
        this.getTagsFromText()
        this.catchInput.selectionStart = startCaret + text.length
        this.catchInput.selectionEnd = endCaret + text.length
      })
    },

    setFocus: _.debounce(
      function() {
        this.focus = this.catchInput === document.activeElement
      },
      250,
      {
        leading: false,
        trailing: true
      }
    ),

    getInput() {
      if (!this.catchInput) {
        this.catchInput = document.getElementById(`input-${this._uid}`)
      }
    }
  },

  render
}
