import proxy from '@sigma-legacy-libs/g-proxy'

import { currentTab } from '@/utils'

import render from './render'

export default {
  name: 'Form',

  mixins: [ proxy({ type: 'object' }), currentTab('general') ],

  props: {
    errors: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      editable: false,
      passwordVisibility: true
    }
  },

  computed: {
    tabs() {
      const result = [ 'general', 'settingsUser', 'tariffs' ]

      if (this.checkPermissions('advanced.tokens.get', [ 'me', 'manager', 'reseller', true ]) && !this.isNew) {
        result.push('tokens')
      }

      result.push('requisites', 'recurrentPayments', 'referral')

      return result
    }
  },

  render
}
