import { serviceTemplate } from '@/utils'

import { serviceName } from '@/components/services/keywordLists/utils'

export default {
  mixins: [
    serviceTemplate({
      serviceName,
      permission: {
        get: { serviceName: 'keywords' },
        remove: { serviceName: 'keywords' },
        create: { serviceName: 'keywords' },
        update: { serviceName: 'keywords' }
      }
    })
  ]
}
