import { serviceName } from '@/components/services/apisender/utils'

function renderDialog(h) {
  return h(
    'g-dialog',
    {
      props: {
        value: this.showDialog,
        rounded: true,
        maxWidth: 400
      },
      on: {
        input: event => {
          this.showDialog = event
        }
      }
    },
    [
      h(
        'div',
        {
          class: 'grid grid-gap--8 pa-2'
        },
        [ this.getTranslate(`${serviceName}.hints.create`, { payment: this.priceByTariff + this.getTranslate(`currency.${this.currency}.symbol.unicode`) }) ]
      ),

      h(
        'div',
        {
          class: 'fiac fjcsb',
          slot: 'footer'
        },
        [
          h(
            'g-button',
            {
              props: {
                flat: true,
                rounded: true,
                disabled: this.loading,
                label: this.getTranslate('misc.buttons.cancel')
              },
              on: {
                click: () => {
                  this.showDialog = false
                }
              }
            }
          ),

          h(
            'g-button',
            {
              props: {
                flat: true,
                rounded: true,
                color: 'primary',
                loading: this.loading,
                disabled: this.loading,
                label: this.getTranslate('misc.buttons.update')
              },
              on: {
                click: () => {
                  this.renewInstance()
                }
              }
            }
          )
        ]
      )
    ]
  )
}

export default function(h) {
  return h(
    'g-button',
    {
      class: 'ma-0',
      props: {
        flat: true,
        disabled: this.disabled,
        icon: 'autorenew',
        color: 'primary'
      },
      on: {
        click: () => {
          this.showDialog = true
        }
      },
      directives: [
        {
          name: 'g-tooltip',
          options: {
            value: this.getTranslate(`${serviceName}.tooltips.renewInstance`),
            placement: 'top'
          }
        }
      ],
      scopedSlots: {
        dialog: () => renderDialog.call(this, h)
      }
    }
  )
}
