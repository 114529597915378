import proxy from '@sigma-legacy-libs/g-proxy'

import render from './render'

export default {
  name: 'SendingsCreateFormWarning',

  mixins: [ proxy() ],

  props: {
    check: {
      type: Boolean,
      default: false
    }
  },

  render
}
