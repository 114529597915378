import { MODERATION_VALUES } from '@sigma-legacy-libs/essentials/lib/constants'

import OwnerSelect from '@/components/misc/OwnerSelect'
import RowWithMessageTypeIcon from '@/components/misc/RowWithMessageTypeIcon'
import ModerationStatusBarWithIcon from '@/components/misc/ModerationStatusBarWithIcon'

import { serviceName } from '@/components/services/patterns/utils'

export default function(h) {
  let cols = 2
  if (this.checkPermissions('advanced.users.get')) {
    cols = 3
  }

  return h(
    'div',
    {
      class: 'grid grid-gap--8',
      style: { 'grid-template-columns': `repeat(${cols}, 200px) 36px` }
    },
    [
      h(
        'g-select',
        {
          props: {
            value: this.proxy.type,
            label: this.getTranslate(`${serviceName}.labels.type`),
            items: this.sendingTypes.map(item => ({
              title: this.getTranslate(item.title),
              value: item.value
            })),
            mode: 'outline',
            dense: true,
            rounded: true,
            clearable: true,
            details: false
          },
          scopedSlots: {
            selection: ({ item }) => {
              return h(
                RowWithMessageTypeIcon,
                {
                  props: {
                    value: item.value,
                    title: item.title
                  }
                }
              )
            },
            item: ({ item }) => {
              return h(
                RowWithMessageTypeIcon,
                {
                  props: {
                    value: item.value,
                    title: item.title
                  }
                }
              )
            }
          },
          on: {
            input: event => {
              this.proxy.type = event
            }
          }
        }
      ),

      h(
        'g-select',
        {
          props: {
            value: this.proxy.moderation,
            label: this.getTranslate(`${serviceName}.labels.moderation`),
            items: MODERATION_VALUES.map(moderation => {
              return {
                title: this.getTranslate(`misc.moderation.${moderation}`),
                value: moderation
              }
            }),
            mode: 'outline',
            dense: true,
            rounded: true,
            clearable: true,
            details: false
          },
          scopedSlots: {
            selection: ({ item }) => {
              return h(
                ModerationStatusBarWithIcon,
                {
                  props: {
                    type: item.value,
                    size: 20,
                    title: item.title
                  }
                }
              )
            },
            item: ({ item }) => {
              return h(
                ModerationStatusBarWithIcon,
                {
                  props: {
                    type: item.value,
                    size: 20,
                    title: item.title
                  }
                }
              )
            }
          },
          on: {
            input: event => {
              this.proxy.moderation = event
            }
          }
        }
      ),

      h(
        OwnerSelect,
        {
          props: {
            value: this.proxy.OwnerId,
            mode: 'outline',
            dense: true,
            rounded: true,
            clearable: true,
            details: false
          },
          on: {
            input: event => {
              this.proxy.OwnerId = event
            }
          }
        }
      ),

      h(
        'g-switch',
        {
          props: {
            value: this.proxy.isActive
          },
          on: {
            input: event => {
              this.proxy.isActive = event
            }
          },
          directives: [
            {
              name: 'g-tooltip',
              options: {
                value: this.getTranslate(`${serviceName}.tooltips.filter.isActive`)
              }
            }
          ]
        }
      )
    ]
  )
}
