export default {
  title: 'Группы провайдеров',

  titles: {
    create: 'Создать группу провайдеров'
  },

  providerGroup: 'Группа провайдера',
  providerGroups: 'Группа провайдеров',
  one: 'Группа провайдера',
  many: 'Группа провайдеров',

  labels: {
    title: 'Название группы',
    type: 'Тип',
    provider: 'Провайдер',
    search: 'Поиск'
  },

  dialogs: {
    new: 'Новая группа провайдера'
  },

  subheaders: {
    providers: 'Провайдеры'
  },

  buttons: {
    add: {
      provider: 'Добавить провайдера'
    }
  },

  hints: {
    notFound: 'Группы провайдеров не найдены'
  },

  tooltips: {
    isActive: 'Активировать или деактивировать группу провайдеров.',
    favorites: 'Сделать группу провайдеров избранной',
    providers: 'Список провайдеров'
  },

  subtitles: {
    providers: 'Провайдеры'
  },

  snackbars: {
    created: 'Группа провайдера успешно создана.',
    updated: 'Группа провайдера успешно обновлена.',
    removed: 'Группа провайдера успешно удалена.'
  }
}
