export default {
  titles: {
    conditions: 'Conditions',
    actions: 'Actions'
  },

  labels: {
    title: 'Rule title',
    type: 'Type',
    text: 'Text',
    sender: 'Sender',
    routingRule: 'Rule',
    condition: 'Condition',
    providerGroup: 'Providers group',
    percentage: 'Percentage',
    search: 'Search',
    textToCheck: 'Text to check',
    group: {
      pattern: 'Pattern for search group'
    },
    add: {
      condition: 'Add condition'
    },
    phone: 'Phone',
    keywords: 'Keywords lists',
    contactList: 'Contact list',
    fallbacks: 'Fallbacks'
  },

  tags: {
    text: 'Text',
    sender: 'Sender',
    recipient: 'Recipient',
    code: 'Operator code',
    group: 'Operator group',
    mcc: 'MCC',
    mnc: 'MNC',
    mccmnc: 'MCC MNC',
    contactList: 'Contact list',
    type: 'Type',
    moderation: 'Moderation',
    keywords: 'Keywords',
    fallbacks: 'Fallbacks'
  },

  hints: {
    noRule: 'Condition not selected yet. Click on this line to select.'
  }
}
