import Vue from 'vue'

import { getRUB } from '@/utils'

import HintRow from '@/components/misc/HintRow'
import HeadTitle from '@/components/misc/HeadTitle'

function renderLoading(h) {
  if (this.balanceLoading) {
    return h(
      'g-progress',
      {
        props: {
          width: 1,
          size: 13,
          indeterminate: true,
          color: 'primary'
        }
      }
    )
  } else {
    return h(
      'g-icon',
      {
        props: {
          value: 'autorenew',
          size: 16
        },
        on: {
          click: () => {
            this.getBalance()
          }
        }
      }
    )
  }
}

function renderInfoIcon(h) {
  let content
  if (this.overdraft > 0) {
    content = this.getTranslate('account.tooltips.warning')
  }
  if (this.overdraft === 0) {
    content = this.getTranslate('account.tooltips.error')
  }

  if (this.balance <= 0) {
    return h(
      HintRow,
      {
        class: 'mt-3',
        props: { value: content }
      }
    )
  }
}

function renderLoginButton(h) {
  if (this.isLoggedAs) {
    return h(
      'g-button',
      {
        props: {
          icon: 'exit_to_app',
          flat: true
        },
        on: {
          click: () => {
            this.$emit('show', false)
            this.loginAs(false)
          }
        }
      }
    )
  } else {
    return h(
      'g-button',
      {
        props: {
          label: this.getTranslate('login.buttons.logout'),
          flat: true,
          rounded: true
        },
        on: {
          click: () => {
            this.logout()
          }
        }
      }
    )
  }
}

export default function(h) {
  return h(
    'g-card',
    {
      props: {
        flat: this.flat,
        rounded: this.rounded,
        maxWidth: 400
      }
    },
    [
      h(
        HeadTitle,
        {
          props: { value: [ this.account.data.firstName, this.account.data.lastName ].filter(item => !!item).join(' ') || this.account.username }
        }
      ),

      h(
        'div',
        {
          class: 'fc px-3'
        },
        [
          h(
            'div',
            {
              class: 'faic'
            },
            [
              h(
                'span',
                {
                  class: 'text--grey caption',
                  domProps: { innerHTML: `${this.getTranslate('account.current.balance')}:` }
                }
              ),

              h(
                'span',
                {
                  class: {
                    'pl-1': true,
                    'text--error': this.balance <= 0
                  }
                },
                getRUB(this.balance)
              ),

              h(
                'div',
                {
                  class: 'fjcc facc faic square--16 ml-1'
                },
                [ renderLoading.call(this, h) ]
              )
            ]
          ),

          h(
            'div',
            {
              class: 'faic text-nowrap'
            },
            [
              h(
                'span',
                {
                  class: 'text--grey caption',
                  domProps: { innerHTML: `${this.getTranslate('account.current.overdraft')}:` }
                }
              ),

              h('span', { class: 'pl-1' }, getRUB(this.overdraft))
            ]
          )
        ]
      ),

      renderInfoIcon.call(this, h),

      h(
        'g-card-actions',
        {
          class: 'mt-2'
        },
        [
          h(
            'g-button',
            {
              props: {
                label: this.getTranslate('users.buttons.account'),
                flat: true,
                rounded: true,
                color: 'primary'
              },
              on: {
                click: () => {
                  this.$emit('show', false)
                  Vue.router.push({ name: 'account' })
                }
              }
            }
          ),

          h(
            'g-button',
            {
              props: {
                flat: true,
                icon: 'dashboard'
              },
              on: {
                click: () => {
                  this.$emit('show', false)
                  Vue.router.push({ name: 'dashboard' })
                }
              }
            }
          ),

          h('div', { class: 'ff' }),

          renderLoginButton.call(this, h)
        ]
      )
    ]
  )
}
