import titleDivider from '@/components/title/divider'
import generatorRow from '@/components/generator/row'
import RoutesFormType from '@/components/services/routes/form/type'
import RoutesFormTitle from '@/components/services/routes/form/title'
import buttonUpDown from '@/components/button/upDown'

import { serviceName } from '@/components/services/routingGroups/utils'

function renderTypeField(h) {
  if (!this.type) {
    return h(
      RoutesFormType,
      {
        props: {
          value: this.proxy,
          serviceName
        },
        on: {
          input: event => {
            this.proxy = event
          }
        }
      }
    )
  }
}

function renderTitleField(h) {
  return h(
    RoutesFormTitle,
    {
      props: {
        value: this.proxy,
        serviceName
      },
      on: {
        input: event => {
          this.proxy = event
        }
      }
    }
  )
}

function renderRoutingRulesFields(h) {
  return h(
    'div',
    {
      class: 'grid grid-cols--1'
    },
    [
      h(titleDivider, { props: { value: this.getTranslate(`${serviceName}.subtitles.routingRules`) } }),

      h(
        generatorRow,
        {
          props: {
            value: this.proxy.RoutingRules,
            defaultValue: {
              id: undefined,
              priority: 0,
              $attach: true
            },
            sortable: true,
            sortableProperty: 'priority'
          },
          scopedSlots: {
            row: ({ item }) => {
              const attrs = {}
              if (item.id) {
                attrs.href = `#/admin/routes/routingRules/${item.id}`
              }

              return h(
                'div',
                {
                  class: 'grid faic',
                  style: { 'grid-template-columns': '24px 1fr' }
                },
                [
                  h(
                    'a',
                    {
                      attrs,
                      class: 'link link--passive square--24'
                    },
                    [
                      h(
                        'g-icon',
                        {
                          props: {
                            value: 'link',
                            color: 'grey'
                          }
                        }
                      )
                    ]
                  ),

                  h(
                    'g-select',
                    {
                      props: {
                        value: item.id,
                        label: this.getTranslate(`${serviceName}.labels.routingRule`),
                        itemsDisabled: this.proxy.RoutingRules.map(({ id }) => id),
                        query: {
                          type: this.proxy.type,
                          isActive: true
                        },
                        service: 'routingRules',
                        mode: 'outline',
                        rounded: true,
                        details: false,
                        clearable: true,
                        dense: true,
                        autocomplete: true
                      },
                      on: {
                        input: event => {
                          item.id = event
                        }
                      }
                    }
                  )
                ]
              )
            }
          },
          on: {
            input: event => {
              this.proxy.RoutingRules = event
            }
          }
        }
      )
    ]
  )
}

export default function(h) {
  return h(
    'g-form',
    {
      class: 'grid',
      style: { 'grid-template-columns': '100%' },
      on: {
        submit: () => {
          this.$emit('submit', this.proxy)
        }
      }
    },
    [
      renderTypeField.call(this, h),
      renderTitleField.call(this, h),
      renderRoutingRulesFields.call(this, h),

      h(buttonUpDown)
    ]
  )
}
