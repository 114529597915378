import { mapGetters } from 'vuex'

import render from './render'

export default {
  name: 'UploadProgressBar',

  computed: {
    ...mapGetters({
      file: 'file/file',
      progress: 'file/progress',
      uploadState: 'file/uploadState'
    })
  },

  watch: {
    uploadState(value) {
      if (value === 'success' || value === 'error') {
        this.addSnackbar({
          text: this.getTranslate(`files.states.${value}`),
          type: value
        })
      }
    }
  },

  render
}
