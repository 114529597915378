import Vue from 'vue'

import { permissionPresets } from '@/utils'

import render from './render'

export default {
  name: 'APISender',

  props: {
    sender: [ String, Number ]
  },

  data() {
    return {
      loading: false,

      instance: undefined
    }
  },

  watch: {
    sender() {
      this.getInstance()
    }
  },

  mounted() {
    this.getInstance()
  },

  methods: {
    async getInstance() {
      if (this.checkPermissions('advanced.apisender.get', permissionPresets.me)) {
        if (this.sender) {
          try {
            this.instance = undefined
            this.loading = true
            const { data } = await Vue.$GRequest.get('apisender/getInstanceByPhone', this.sender)
            if (data) {
              if (data.result === false) {
                throw new Error('Инстанс не найден')
              } else {
                this.instance = data
              }
            }
          } catch (error) {
            this.instance = undefined
          } finally {
            this.loading = false
          }
        }
      }
    }
  },

  render
}
