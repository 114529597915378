import Vue from 'vue'

import { mapActions, mapGetters } from 'vuex'

import render from './render'

const _ = { get: require('lodash/get') }

export default {
  name: 'MobilePanel',

  data() {
    return {
      isJivositeOpen: false
    }
  },

  computed: {
    items() {
      const result = [
        {
          name: 'create',
          icon: 'add_circle_outline',
          to: { name: 'create' }
        },
        {
          name: 'templates',
          icon: 'save',
          to: { name: 'create' }
        }
      ]
      const jivosite = _.get(this.globalSettings, 'frontend.jivosite')
      const contacts = _.get(this.globalSettings, 'contacts')

      if (jivosite) {
        result.push({
          name: 'jivosite',
          icon: 'help'
        })
      }

      if (contacts && !jivosite) {
        result.push({
          name: 'support',
          icon: 'contact_phone',
          to: { name: 'support' }
        })
      }

      result.push({
        name: 'payment',
        icon: 'monetization_on'
      })

      return result
    },

    ...mapGetters({
      tab: 'currentTab/tab',
      showPaymentDialog: 'currentTab/showPaymentDialog'
    })
  },

  watch: {
    $route: {
      handler() {
        this.init()
      },
      deep: true
    }
  },

  mounted() {
    this.init()
  },

  methods: {
    init() {
      const sameItemRouteName = this.items.some(item => {
        if (item.to && item.to.name) {
          return item.to.name === this.$route.name
        }
      })
      if (sameItemRouteName) {
        const name = this.tab.to && this.tab.to.name || this.tab.name
        if (name && name === this.$route.name) {
          this.setCurrentTab(this.tab)
        } else {
          this.setCurrentTab({ name: this.$route.name })
        }
      } else {
        this.setCurrentTab({
          name: undefined,
          to: {}
        })
      }
    },

    itemProcessor(item) {
      switch (item.name) {
        case 'create':
        case 'templates':
        case 'support': {
          this.closeJivosite()
          this.setTabItemAndPush(item)
          this.setShowPaymentDialog(false)
          break
        }

        case 'payment': {
          this.closeJivosite()
          this.setShowPaymentDialog(true)
          break
        }

        case 'jivosite': {
          this.setShowPaymentDialog(false)
          const jivosite = document.querySelector('.mobileContainer_2k')
          if (jivosite) {
            jivosite.style = 'margin-bottom: 66px !important; margin-top: 56px !important; z-index: 5 !important;'
          }
          this.isJivositeOpen = true
          jivo_api.open() // eslint-disable-line
          break
        }
      }
    },

    setTabItemAndPush(tab) {
      if (this.items.some(item => item.name === tab.name)) {
        this.setCurrentTab(tab)

        if (tab.to) {
          Vue.router.push(tab.to)
        }
      }
    },

    closeJivosite() {
      if (this.isJivositeOpen) {
        this.isJivositeOpen = false
        jivo_api.close() // eslint-disable-line
      }
    },

    ...mapActions({
      setCurrentTab: 'currentTab/setCurrentTab',
      setShowPaymentDialog: 'currentTab/setShowPaymentDialog'
    })
  },

  render
}
