import Actions from '@sigma-legacy-libs/essentials/lib/utils/Actions'

import { serviceTemplate } from '@/utils'

import { serviceName } from '@/components/services/messageRules/utils'

export default {
  mixins: [
    serviceTemplate({
      serviceName,

      outputFilter(result) {
        result.actions = Actions.filterActionsObject(result.actions)

        return result
      },

      width: 500
    })
  ]
}
