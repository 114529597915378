import HeadTitle from '@/components/misc/HeadTitle'

import ContactsForm from '@/components/services/contacts/form'
import ContactListsForm from '@/components/services/contactLists/form'

import { STATES } from '@/utils'

function renderPreloader(h) {
  return h(
    'div',
    {
      class: 'fjcc facc pa-3'
    },
    [ h('g-progress', { props: { indeterminate: true } }) ]
  )
}

function renderHeader(h) {
  if (this.type) {
    const [ service, type ] = this.type.split('.')

    return h(
      HeadTitle,
      {
        props: { value: this.getTranslate(`${service}.dialogs.titles.${type}`) },
        slot: 'header'
      }
    )
  }
}
function renderBody(h) {
  switch (this.type) {
    case 'contacts.create': {
      return h(
        ContactsForm,
        {
          class: 'pa-3',
          props: {
            value: this.restData.contacts.create.data,
            errors: this.restData.contacts.create.errors
          },
          on: {
            input: event => {
              this.restData.contacts.create.data = event
            },
            submit: () => {
              this.create('contacts')
            },
            validation: event => {
              this.restData.contacts.create.isValid = event
            }
          }
        }
      )
    }
    case 'contacts.update': {
      if (this.restData.contacts.get.state === STATES.ready) {
        return h(
          ContactsForm,
          {
            class: 'pa-3',
            props: {
              value: this.restData.contacts.get.data,
              errors: this.restData.contacts.update.errors,
              ListId: this.ListId
            },
            on: {
              input: event => {
                this.restData.contacts.get.data = event
              },
              submit: () => {
                this.update('contacts')
              },
              validation: event => {
                this.restData.contacts.update.isValid = event
              }
            }
          }
        )
      } else if (this.restData.contacts.get.state === STATES.loading) {
        return renderPreloader.call(this, h)
      }
      break
    }
    case 'contacts.remove': {
      return h(
        'div',
        {
          class: 'pa-3'
        },
        this.getTranslate('contacts.contents.confirm.remove')
      )
    }
    case 'contactLists.create': {
      return h(
        ContactListsForm,
        {
          class: 'pa-3',
          props: {
            value: this.restData.contactLists.create.data,
            errors: this.restData.contactLists.create.errors
          },
          on: {
            input: event => {
              this.restData.contactLists.create.data = event
            },
            submit: () => {
              this.create('contactLists')
            },
            validation: event => {
              this.restData.contactLists.create.isValid = event
            }
          }
        }
      )
    }
    case 'contactLists.update': {
      if (this.restData.contactLists.get.state === STATES.ready) {
        return h(
          ContactListsForm,
          {
            class: 'pa-3',
            props: {
              value: this.restData.contactLists.get.data,
              errors: this.restData.contactLists.update.errors
            },
            on: {
              input: event => {
                this.restData.contactLists.get.data = event
              },
              submit: () => {
                this.update('contactLists')
              },
              validation: event => {
                this.restData.contactLists.update.isValid = event
              }
            }
          }
        )
      } else if (this.restData.contacts.get.state === STATES.loading) {
        return renderPreloader.call(this, h)
      }
      break
    }
    case 'contactLists.remove': {
      return h(
        'div',
        {
          class: 'pa-3'
        },
        this.getTranslate('contactLists.contents.confirm.remove')
      )
    }
  }
}
function renderFooter(h) {
  if (this.type) {
    let service
    let method

    switch (this.type) {
      case 'contacts.create': {
        service = 'contacts'
        method = 'create'
        break
      }
      case 'contacts.update': {
        service = 'contacts'
        method = 'update'
        break
      }
      case 'contacts.remove': {
        service = 'contacts'
        method = 'remove'
        break
      }
      case 'contactLists.create': {
        service = 'contactLists'
        method = 'create'
        break
      }
      case 'contactLists.update': {
        service = 'contactLists'
        method = 'update'
        break
      }
      case 'contactLists.remove': {
        service = 'contactLists'
        method = 'remove'
        break
      }
    }

    return h(
      'g-card-actions',
      {
        class: 'fjcfe facfe',
        slot: 'footer'
      },
      [
        h(
          'g-button',
          {
            props: {
              label: this.getTranslate('misc.buttons.cancel'),
              flat: true,
              rounded: true,
              disabled: this.restData[service][method].state === STATES.loading
            },
            on: {
              click: () => {
                this.$emit('show', false)
              }
            }
          }
        ),

        h(
          'g-button',
          {
            props: {
              label: this.getTranslate(`misc.buttons.${method}`),
              rounded: true,
              depressed: true,
              loading: this.restData[service][method].state === STATES.loading,
              disabled: this.restData[service][method].state === STATES.loading || method !== 'remove' && !this.restData[service][method].isValid,
              color: method === 'remove' ? 'error' : 'primary'
            },
            on: {
              click: () => {
                this[method](service)
              }
            }
          }
        )
      ]
    )
  }
}

function renderContent(h) {
  let maxWidth = 400

  switch (this.type) {
    case 'contacts.create': {
      maxWidth = 600
      break
    }
    case 'contacts.update': {
      maxWidth = 600
      break
    }
    case 'contacts.remove': {
      maxWidth = 500
      break
    }
    case 'contactLists.create': {
      break
    }
    case 'contactLists.update': {
      break
    }
    case 'contactLists.remove': {
      maxWidth = 500
      break
    }
  }

  return h(
    'g-dialog',
    {
      props: {
        rounded: true,
        value: this.show,
        maxWidth
      },
      on: {
        input: event => {
          this.$emit('show', event)
        }
      }
    },
    [
      renderHeader.call(this, h),
      renderBody.call(this, h),
      renderFooter.call(this, h)
    ]
  )
}

export default function(h) {
  return renderContent.call(this, h)
}
