export const moderationColors = {
  pending: 'info',
  approved: 'success',
  rejected: 'error'
}

export const moderationIcons = {
  pending: 'schedule',
  approved: 'done',
  rejected: 'clear'
}

export default {
  moderationColors,
  moderationIcons
}
