import routes from '@/routes'

import render from './render'

export default {
  name: 'NavigationList',

  computed: {
    routes() {
      return routes.reduce((result, { name, meta, children }) => {
        if (meta && meta.nav) {
          result.push({
            name,
            meta,
            children
          })
        }

        if (children) {
          for (const child of children) {
            if (child.meta && child.meta.nav) {
              result.push({
                name: child.name,
                meta: child.meta,
                children: child.children
              })
            }
          }
        }

        return result
      }, [])
    }
  },

  render
}
