import { GENDERS } from '@sigma-legacy-libs/essentials/lib/constants'

import ExpandArrow from '@/components/misc/ExpandArrow'
import Columns from '@/components/misc/Columns'

function renderFieldPhone(h) {
  return h(
    'g-text-field',
    {
      props: {
        value: this.proxy.phone,
        label: this.getTranslate('contacts.labels.phone'),
        error: this.errors.phone,
        mode: 'outline',
        rounded: true,
        clearable: true,
        trim: true,
        autofocus: true
      },
      on: {
        input: event => {
          this.proxy.phone = event
        },
        keypress: event => {
          if (event.keyCode === 13) {
            this.$emit('submit', this.proxy)
          }
        }
      }
    }
  )
}
function renderFieldEmail(h) {
  return h(
    'g-text-field',
    {
      props: {
        value: this.proxy.email,
        label: this.getTranslate('contacts.labels.email'),
        mode: 'outline',
        rounded: true,
        clearable: true,
        trim: true,
        error: this.errors.email
      },
      on: {
        input: event => {
          this.proxy.email = event
        },
        keypress: event => {
          if (event.keyCode === 13) {
            this.$emit('submit', this.proxy)
          }
        }
      }
    }
  )
}

function renderFieldLastName(h) {
  return h(
    'g-text-field',
    {
      props: {
        value: this.proxy.lastName,
        label: this.getTranslate('contacts.labels.lastName'),
        error: this.errors.lastName,
        mode: 'outline',
        rounded: true,
        clearable: true,
        trim: true
      },
      on: {
        input: event => {
          this.proxy.lastName = event
        },
        keypress: event => {
          if (event.keyCode === 13) {
            this.$emit('submit', this.proxy)
          }
        }
      }
    }
  )
}
function renderFieldFirstName(h) {
  return h(
    'g-text-field',
    {
      props: {
        value: this.proxy.firstName,
        label: this.getTranslate('contacts.labels.firstName'),
        error: this.errors.firstName,
        mode: 'outline',
        rounded: true,
        clearable: true,
        trim: true
      },
      on: {
        input: event => {
          this.proxy.firstName = event
        },
        keypress: event => {
          if (event.keyCode === 13) {
            this.$emit('submit', this.proxy)
          }
        }
      }
    }
  )
}
function renderFieldMiddleName(h) {
  return h(
    'g-text-field',
    {
      props: {
        value: this.proxy.middleName,
        label: this.getTranslate('contacts.labels.middleName'),
        error: this.errors.middleName,
        mode: 'outline',
        rounded: true,
        clearable: true,
        trim: true
      },
      on: {
        input: event => {
          this.proxy.middleName = event
        },
        keypress: event => {
          if (event.keyCode === 13) {
            this.$emit('submit', this.proxy)
          }
        }
      }
    }
  )
}

function renderFieldGender(h) {
  return h(
    'g-select',
    {
      props: {
        value: this.proxy.gender || undefined,
        label: this.getTranslate('contacts.labels.gender'),
        items: GENDERS.map(gender => ({
          title: this.getTranslate(`misc.genders.${gender}`),
          value: gender
        })),
        mode: 'outline',
        rounded: true,
        error: this.errors.gender,
        clearable: true
      },
      on: {
        input: event => {
          this.proxy.gender = event
        }
      }
    }
  )
}
function renderFieldDate(h) {
  return h(
    'g-menu',
    {
      class: 'w--100',
      props: {
        closeOnContentClick: false,
        rounded: true,
        maxWidth: 282
      }
    },
    [
      h(
        'g-text-field',
        {
          props: {
            value: this.computedDate,
            label: this.getTranslate('contacts.labels.date'),
            afterIcon: 'event',
            defaultValue: null,
            mode: 'outline',
            rounded: true,
            readonly: true
          },
          slot: 'activator'
        }
      ),

      h(
        'div',
        {
          class: 'fc ff'
        },
        [
          h(
            'g-date-picker',
            {
              props: {
                value: this.proxy.date,
                localeTag: this.locale,
                range: false
              },
              on: {
                input: event => {
                  this.proxy.date = event
                }
              }
            }
          ),

          h(
            'g-card-actions',
            {
              class: 'fjcfe facfe'
            },
            [
              h(
                'g-button',
                {
                  props: {
                    label: this.getTranslate('misc.buttons.clear'),
                    flat: true,
                    rounded: true,
                    disabled: !this.proxy.date
                  },
                  on: {
                    click: () => {
                      this.proxy.date = null
                    }
                  }
                }
              )
            ]
          )
        ]
      )
    ]
  )
}

const customs = []
const count = 20
for (let index = 1; index <= count; index++) {
  const current = index + ''
  customs.push(`custom${'0'.repeat(`${count}`.length - current.length) + current}`)
}

function renderFieldCustom(h) {
  if (!this.isFilter) {
    return h(
      'g-expansion-panels',
      {
        props: { flat: true }
      },
      [
        h(
          'g-expansion-panel',
          {
            scopedSlots: {
              header: ({ expanded }) => {
                return h(
                  'div',
                  {
                    class: 'default-expansion-panel-header pr-0'
                  },
                  [
                    this.getTranslate('contacts.titles.custom.fields'),

                    h('div', { class: 'ff' }),

                    h(ExpandArrow, { props: { expanded } })
                  ]
                )
              }
            }
          },
          [
            h(
              'div',
              {
                class: 'fc ff'
              },
              customs.map((field, index) => {
                return h(
                  'g-text-field',
                  {
                    props: {
                      value: this.proxy[field],
                      label: `${this.getTranslate('contacts.labels.custom')} ${index + 1}`,
                      mode: 'outline',
                      rounded: true,
                      clearable: true,
                      trim: true
                    },
                    on: {
                      input: event => {
                        this.proxy[field] = event
                      },
                      keypress: event => {
                        if (event.keyCode === 13) {
                          this.$emit('submit', this.proxy)
                        }
                      }
                    },
                    key: `field-${index}`
                  }
                )
              })
            )
          ]
        )
      ]
    )
  }
}

export default function(h) {
  return h(
    'g-form',
    {
      class: 'fc',
      on: {
        submit: () => {
          this.$emit('submit', this.proxy)
        },
        validation: event => {
          this.$emit('validation', this.validation && event)
        }
      }
    },
    [
      h(Columns, {}, [ renderFieldPhone.call(this, h), renderFieldEmail.call(this, h) ]),

      h(
        Columns,
        {},
        [
          renderFieldLastName.call(this, h),
          renderFieldFirstName.call(this, h),
          renderFieldMiddleName.call(this, h)
        ]
      ),

      h(Columns, {}, [ renderFieldGender.call(this, h), renderFieldDate.call(this, h) ]),

      renderFieldCustom.call(this, h)
    ]
  )
}
