import { unVue } from '@sigma-legacy-libs/unvue'
import { PUBLIC_FIELDS_USER } from '@sigma-legacy-libs/essentials/lib/constants'

import { SENDINGS_OUTGOING_FILTER, appendFormattedDates, downloadable, fillDependence, generateServices, tableHeaderParser } from '@/utils'

import { serviceName } from '@/components/services/sendings/utils'

import render from './render'

export default {
  name: 'SendingsDetails',

  mixins: [
    generateServices({
      name: serviceName,

      async inputFilter(result) {
        if (!result.Owner) {
          await fillDependence.call(this, result, {
            service: 'users',
            permissions: [ 'reseller', true ],
            pathToId: 'OwnerId',
            pathToObject: 'Owner',
            outputPath: 'Owner',
            picks: PUBLIC_FIELDS_USER
          })
        }

        result.OwnerId = result.Owner && result.Owner.id || result.OwnerId

        if (result && result.payload && (result.payload.file || result.payload.image || result.payload.audio)) {
          const [ id1, id2 ] = (result.payload.file || result.payload.image || result.payload.audio).split('/')

          result.payload.fileId = id2 || id1

          await fillDependence.call(this, result, {
            service: 'storage',
            permissions: [ 'me', 'reseller', true ],
            pathToId: 'payload.fileId',
            pathToObject: 'File',
            outputPath: 'File',
            requestParams: {
              query: {
                return: 'meta'
              }
            }
          })
        }

        appendFormattedDates.call(this, result)

        return result
      },

      find: {
        bucketEnabled: true,
        defaultFilter: unVue(SENDINGS_OUTGOING_FILTER),
        disableWatcherFilter: true
      },
      get: false,
      update: false,
      create: false,
      remove: false
    }),

    downloadable({ permissionAccept: `advanced.${serviceName}.find` })
  ],

  data() {
    return {
      showDialog: false,
      source: undefined
    }
  },

  props: {
    filter: {
      type: Object,
      default: () => unVue(SENDINGS_OUTGOING_FILTER),
      required: true
    }
  },

  computed: {
    headers() {
      return tableHeaderParser.call(this, [
        {
          value: 'type',
          class: 'px-0'
        },
        {
          value: 'status',
          class: 'px-0'
        },
        {
          title: `${serviceName}.headers.owner`,
          value: 'owner',
          align: 'left',
          class: 'px-0',
          show: this.checkPermissions('advanced.users.get', [ 'reseller', true ])
        },
        {
          title: `${serviceName}.headers.recipient`,
          value: 'payload.recipient',
          align: 'left',
          class: 'px-2',
          sortable: true
        },
        {
          title: `${serviceName}.headers.sender`,
          value: 'payload.sender',
          align: 'left',
          class: 'px-2',
          sortable: true
        },
        {
          title: `${serviceName}.headers.text`,
          value: 'payload.text',
          align: 'left',
          class: 'px-2',
          sortable: true
        },
        {
          title: '',
          value: 'custom.field.1',
          class: 'px-2'
        },
        {
          value: 'segments',
          class: 'px-2'
        },
        {
          value: 'billSec',
          class: 'px-2'
        },
        {
          title: `${serviceName}.headers.operator`,
          value: 'operator',
          align: 'left',
          class: 'px-2'
        },
        {
          title: `${serviceName}.headers.provider`,
          value: 'provider',
          align: 'left',
          class: 'px-2',
          show: this.checkPermissions('advanced.providers.get', [ 'reseller', true ])
        },
        {
          title: `${serviceName}.headers.amount`,
          value: 'meta.billing.amount',
          align: 'left',
          class: 'px-2'
        },
        {
          title: 'misc.createdAt',
          value: 'createdAt',
          align: 'right',
          class: 'px-2',
          sortable: true
        },
        {
          title: 'misc.updatedAt',
          value: 'updatedAt',
          align: 'right',
          class: 'px-2',
          sortable: true
        }
      ])
    }
  },

  watch: {
    filter: {
      handler() {
        this.restData[serviceName].find.filter = unVue(this.filter)
      },
      deep: true
    },
    $route: {
      handler() {
        const { id } = this.$route.query
        if (id) {
          this.showDialog = false
          this.restData[serviceName].find.filter.id = id
          this.rest[serviceName].find()
        }
      },
      deep: true
    }
  },

  mounted() {
    const { id } = this.$route.query
    if (id) {
      this.showDialog = false
      this.restData[serviceName].find.filter.id = id
      this.rest[serviceName].find()
    }
  },

  render
}
