import Vue from 'vue'

import { globalErrorHandler, globalErrorProcessor } from '@/utils'

import render from './render'

export default {
  name: 'EntityLink',

  props: {
    id: String,
    service: {
      type: String,
      required: true
    },

    pathToId: {
      type: String,
      default: 'id'
    },
    pathToTitle: {
      type: String,
      default: 'title'
    },

    type: String,

    to: {
      type: Object,
      default: () => undefined
    },

    entity: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      value: undefined
    }
  },

  watch: {
    entity: {
      handler() {
        this.inputFilter()
      },
      deep: true
    },
    id() {
      this.inputFilter()
    },
    service() {
      this.inputFilter()
    }
  },

  mounted() {
    this.inputFilter()
  },

  methods: {
    async inputFilter() {
      if (this.checkPermissions(`advanced.${this.service}.get`, [ 'reseller', true ])) {
        if (Object.keys(this.entity).length === 0) {
          if (this.id && this.service) {
            try {
              const { data } = await Vue.$GRequest.get(this.service, this.id)
              if (data) {
                this.value = data
              }
            } catch (error) {
              globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
            }
          }
        } else {
          this.value = this.entity
        }
      }
    }
  },

  render
}
