// import Vue from 'vue'

import { fillDependence, /* STATES, globalErrorHandler, globalErrorProcessor, */ serviceTemplate } from '@/utils'

import { serviceName } from '@/components/services/providers/utils'

export default {
  mixins: [
    serviceTemplate({
      serviceName,

      async inputFilter(result) {
        if (!result.Server) {
          await fillDependence.call(this, result, {
            service: 'servers',
            permissions: true,
            pathToId: 'ServerId',
            pathToObject: 'Server',
            outputPath: 'Server'
          })
        }
        if (!result.Credentials) {
          await fillDependence.call(this, result, {
            service: 'credentials',
            permissions: true,
            pathToId: 'CredentialsId',
            pathToObject: 'Credentials',
            outputPath: 'Credentials'
          })
        }

        return result
      },

      find: {
        defaultFilter: {
          ServerId: undefined,
          CredentialsId: undefined,
          $scope: [ 'Server', 'Credentials' ]
        }
      },

      favorites: {
        filter(item) {
          if (this.restData[serviceName].find.filter.ServerId) {
            return (item.ServerId || item.Server && item.Server.id) === this.restData[serviceName].find.filter.ServerId
          }
          if (this.restData[serviceName].find.filter.CredentialsId) {
            return (item.CredentialsId || item.Credentials && item.Credentials.id) === this.restData[serviceName].find.filter.CredentialsId
          }

          return true
        }
      },

      sizeChanger: true
    })
  ],

  data() {
    return {
      metrics: undefined,
      interval: undefined,

      loading: false
    }
  },

  watch: {
    $route: {
      handler() {
        if (this.$route.params.id) {
          this.clearInterval()
        } else {
          this.clearInterval()
          this.setInterval()
        }
      },
      deep: true
    }
  },

  mounted() {
    this.getMetrics()
    this.setInterval()
  },

  beforeDestroy() {
    this.clearInterval()
  },

  methods: {
    async getMetrics() {
      // if (this.restData[serviceName].find.state === STATES.ready) {
      //   if (this.restData[serviceName].find.pagination.total) {
      //     try {
      //       this.loading = true

      //       const { data } = await Vue.$GRequest.get('providers', 'metrics', { query: { id: this.restData[serviceName].find.data.map(({ id }) => id) } })

      //       this.metrics = data
      //     } catch (error) {
      //       globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      //     } finally {
      //       this.loading = false
      //     }
      //   }
      // }
    },

    setInterval() {
      this.interval = setInterval(() => {
        if (this.loading === false) {
          this.getMetrics()
        }
      }, 5 * 1000)
    },
    clearInterval() {
      if (this.interval) {
        clearInterval(this.interval)
      }
    }
  }
}
