import { DOCUMENT_TEMPLATES_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import OwnerSelect from '@/components/misc/OwnerSelect'
import DocumentTemplatesSelector from '@/components/services/documentTemplates/selector'

import { serviceName } from '@/components/services/companies/utils'

function renderDocumentButtons(h) {
  if (this.proxy.id) {
    return h(
      'div',
      {
        class: 'grid grid-cols--2 grid-gap--8'
      },
      DOCUMENT_TEMPLATES_TYPES.map(type => {
        return h(
          DocumentTemplatesSelector,
          {
            props: {
              CompanyId: this.proxy.id,
              type
            }
          }
        )
      })
    )
  }
}

export default function(h) {
  return h(
    'g-form',
    {
      class: 'grid grid-gap--8',
      on: {
        submit: () => {
          this.$emit('submit', this.proxy)
        },
        validation: event => {
          this.$emit('validation', event)
        }
      }
    },
    [
      h(
        'div',
        {
          class: 'grid grid-gap--8',
          style: {
            'grid-template-columns': '1fr auto'
          }
        },
        [
          h(
            'g-text-field',
            {
              props: {
                value: this.proxy.title,
                label: this.getTranslate(`${serviceName}.labels.title`),
                mode: 'outline',
                dense: true,
                rounded: true,
                required: true,
                clearable: true,
                details: false
              },
              on: {
                input: event => {
                  this.proxy.title = event
                }
              }
            }
          ),

          h(
            'g-switch',
            {
              props: {
                value: this.proxy.vat,
                label: this.getTranslate(`${serviceName}.labels.vat`)
              },
              on: {
                input: event => {
                  this.proxy.vat = event
                }
              }
            }
          )
        ]
      ),

      renderDocumentButtons.call(this, h),

      h(
        OwnerSelect,
        {
          props: {
            value: this.proxy.OwnerId,
            dense: true,
            required: true,
            details: false
          },
          on: {
            input: event => {
              this.proxy.OwnerId = event
            }
          }
        }
      )
    ]
  )
}
