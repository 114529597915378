import { renderReleaseBucketButton, renderSudoDataButton, renderSudoDataDialog } from '@/components/misc'

import TwoRowString from '@/components/misc/TwoRowString'
import RefreshButton from '@/components/button/refresh'
import ButtonReport from '@/components/button/report'
import SendingsIncomingFilter from '@/components/services/sendings/incoming/filter'
import messageTypeIcon from '@/components/message/type/icon'
import ExpandArrow from '@/components/misc/ExpandArrow'
import SingleLink from '@/components/misc/SingleLink'
import FileItemCard from '@/components/file/item/card'
import pagination from '@/components/misc/pagination'

import { ROOT_ID } from '@/utils'

const name = 'sendingsIncoming'

function renderFilterButton(h) {
  return h(
    'g-button',
    {
      class: 'my-0 ml-2',
      props: {
        label: this.viewport.breakpoint.mdUp ? this.getTranslate('misc.buttons.filter') : undefined,
        icon: 'filter_list',
        flat: true,
        rounded: true
      },
      on: {
        click: () => {
          this.showFilter = !this.showFilter
        }
      }
    }
  )
}

function renderFilter(h) {
  if (this.showFilter) {
    return h(
      SendingsIncomingFilter,
      {
        props: {
          preset: `${name}.details`,
          value: this.restData[name].find.filter
        },
        on: {
          input: event => {
            this.restData[name].find.filter = event
          }
        }
      }
    )
  }
}

function renderFileInfo(h, item) {
  if (item.File) {
    return h(
      FileItemCard,
      {
        class: 'pa-0',
        props: {
          value: item.File,
          outline: false,
          displayMenu: false,
          flat: true,
          maxWidth: 300,
          paddingAll: false,
          small: true
        }
      }
    )
  }
}

function renderSudoButton(h, item) {
  if (this.account.id === ROOT_ID) {
    return renderSudoDataButton.call(this, h, item)
  }
}

function renderFormattedDate(h, date) {
  return h(
    'div',
    {
      class: 'text--grey text-right',
      style: { 'white-space': 'pre' },
      domProps: { innerHTML: date }
    }
  )
}

function renderTable(h) {
  if (this.viewport.breakpoint.mdUp) {
    return h(
      'g-table',
      {
        props: {
          headers: this.headers,
          hideHead: this.restData[name].find.pagination.total < 1,
          items: this.restData[name].find.data,
          value: this.restData[name].find.order,
          noDataText: this.getTranslate('misc.no.data.text')
        },
        on: {
          input: event => {
            this.restData[name].find.order = event
          }
        },
        scopedSlots: {
          items: ({ item }) => {
            return h(
              'tr',
              {
                key: item.id
              },
              [
                h(
                  'td',
                  {
                    class: 'pa-0'
                  },
                  [
                    h(
                      'div',
                      {
                        class: 'fjcc facc faic square--48'
                      },
                      [
                        h(
                          messageTypeIcon,
                          {
                            props: {
                              value: item.type
                            }
                          }
                        )
                      ]
                    )
                  ]
                ),

                h(
                  'td',
                  {
                    class: 'px-2'
                  },
                  item.from
                ),

                h(
                  'td',
                  {
                    class: 'px-2 w--100'
                  },
                  [
                    h(
                      'div',
                      {
                        class: 'faic'
                      },
                      [
                        item.payload.text,
                        h('div', { class: 'ff' }),
                        renderFileInfo.call(this, h, item),
                        renderSudoButton.call(this, h, item)
                      ]
                    )
                  ]
                ),

                renderOwnerCell.call(this, h, item),

                h(
                  'td',
                  {
                    class: 'px-2'
                  },
                  [ renderFormattedDate.call(this, h, item.$formattedCreatedAt) ]
                ),

                h(
                  'td',
                  {
                    class: 'px-2'
                  },
                  [ renderFormattedDate.call(this, h, item.$formattedUpdatedAt) ]
                )
              ]
            )
          }
        }
      }
    )
  }
}

function renderPanels(h) {
  if (this.viewport.breakpoint.smDown) {
    return h(
      'g-expansion-panels',
      {
        props: {
          flat: true
        }
      },
      this.restData[name].find.data.map(item => {
        return h(
          'g-expansion-panel',
          {
            key: item.id,
            scopedSlots: {
              header: ({ expanded }) => {
                return h(
                  'div',
                  {
                    class: 'default-expansion-panel-header px-0'
                  },
                  [
                    h(
                      'div',
                      {
                        class: 'fjcc facc faic square--48'
                      },
                      [
                        h(
                          messageTypeIcon,
                          {
                            props: {
                              value: item.type
                            }
                          }
                        )
                      ]
                    ),

                    h(
                      TwoRowString,
                      {
                        props: { first: item.from }
                      },
                      [
                        h(
                          SingleLink,
                          {
                            props: {
                              value: item.Owner
                            },
                            slot: 'second'
                          }
                        )
                      ]
                    ),

                    h('div', { class: 'ff' }),

                    h(ExpandArrow, { props: { expanded } })
                  ]
                )
              }
            }
          },
          [
            h(
              'div',
              {
                class: 'faic body-1 pr-3'
              },
              [
                renderSudoButton.call(this, h, item),

                h('div', { class: 'pt-3 pb-3 pr-3' }, item.payload.text),

                h('div', { class: 'ff' }),

                renderFormattedDate.call(this, h, item.$formattedCreatedAt)
              ]
            ),
            renderFileInfo.call(this, h, item)
          ]
        )
      })
    )
  }
}

function renderOwnerCell(h, item) {
  if (this.checkPermissions('advanced.users.get', [ 'reseller', true ])) {
    return h(
      'td',
      {
        class: 'px-2'
      },
      [ h(SingleLink, { props: { value: item.Owner } }) ]
    )
  }
}

function renderPagination(h) {
  return h(
    'div',
    {
      class: 'fjcfe facfe pa-2'
    },
    [
      h(
        pagination, {
          props: {
            value: this.restData[name].find.pagination
          },
          on: {
            input: event => {
              this.restData[name].find.pagination = event
            }
          }
        }
      )
    ]
  )
}

export default function(h) {
  return h(
    'g-card',
    {
      props: { rounded: true }
    },
    [
      h(
        'div',
        {
          class: {
            'faic pa-2': true,
            fw: this.viewport.breakpoint.smDown
          }
        },
        [
          h(
            ButtonReport,
            {
              class: 'ma-0',
              props: {
                preset: `${name}.details`,
                filter: this.restData[name].find.filter
              }
            }
          ),

          renderFilterButton.call(this, h),

          h('div', { class: 'ff' }),

          renderReleaseBucketButton.call(this, h, { name }),

          h(
            RefreshButton,
            {
              class: 'ma-0',
              props: {
                disabled: this.downloadable,
                label: this.getTranslate('sendings.buttons.refresh'),
                tooltip: this.getTranslate('sendings.tooltips.refresh'),
                state: this.restData[name].find.state,
                shortClickMethod: () => this.rest[name].find(),
                longClickMethod: () => this.rest[name].find({}, { noCache: true })
              }
            }
          )
        ]
      ),

      renderFilter.call(this, h),
      renderPagination.call(this, h),
      renderTable.call(this, h),
      renderPanels.call(this, h),
      renderPagination.call(this, h),
      renderSudoDataDialog.call(this, h)
    ]
  )
}
