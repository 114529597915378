import SingleLink from '@/components/misc/SingleLink'
import title from '@/components/services/companies/item/title'
import vat from '@/components/services/companies/item/vat'
import buttons from '@/components/services/companies/item/buttons'

import { serviceName } from '@/components/services/companies/utils'

function renderOwner(h) {
  if (this.checkPermissions(`advanced.${serviceName}.get`)) {
    return h(
      SingleLink,
      {
        class: 'small',
        props: {
          value: this.value.Owner || this.value.OwnerId
        }
      }
    )
  }
}

function renderTitle(h) {
  return h(title, { props: { value: this.value } })
}

function renderDescription(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8 tiny text--grey',
      style: { 'grid-template-columns': 'repeat(2, minmax(min-content, max-content))' }
    },
    [
      h(vat, { props: { value: this.value } }),

      renderOwner.call(this, h)
    ]
  )
}

export default function(h) {
  return h(
    'g-card',
    {
      class: 'hover-parent hover-parent--opacity pa-2',
      props: {
        outline: true,
        rounded: true
      }
    },
    [
      h(
        'div',
        {
          class: 'faic'
        },
        [
          h(
            'div',
            {
              class: 'grid grid-gap--4 px-2 ff'
            },
            [
              renderTitle.call(this, h),
              renderDescription.call(this, h)
            ]
          ),

          h(buttons, { props: { value: this.value } })
        ]
      )
    ]
  )
}
