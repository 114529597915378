import Vue from 'vue'

import SingleLink from '@/components/misc/SingleLink'
import tasksType from '@/components/services/tasks/type'
import tasksState from '@/components/services/tasks/state'
import tasksFrequency from '@/components/services/tasks/frequency'
import tasksActionButtons from '@/components/services/tasks/actions'

import { getRUB } from '@/utils'

const _ = { get: require('lodash/get') }

const amountColors = {
  accrual: 'success',
  subtract: 'error'
}
const amountIcons = {
  accrual: 'add',
  subtract: 'remove'
}

function renderTitle(h, trigger = true) {
  if (trigger) {
    return h(
      'g-chip',
      {
        props: {
          cut: true,
          small: true,
          outline: true,
          label: this.value.title,
          color: 'primary'
        },
        directives: [
          {
            name: 'g-tooltip',
            options: { value: this.value.title }
          }
        ]
      }
    )
  }
}

function renderInfo(h, showTitle = true) {
  return h(
    'div',
    {
      class: 'faic ff'
    },
    [
      renderTitle.call(this, h, showTitle),

      h(tasksType, { props: { value: this.value } }),
      h(tasksState, { props: { value: this.value } })
    ]
  )
}

function renderButtonLinkToRecurrentPayment(h) {
  if (this.checkPermissions('advanced.recurrentPayments.get', [ 'reseller', true ])) {
    const recurrentPaymentId = _.get(this.value, 'data.recurrentPaymentId')
    if (recurrentPaymentId) {
      return h(
        'g-button',
        {
          props: {
            flat: true,
            icon: 'link',
            color: 'primary'
          },
          directives: [
            {
              name: 'g-tooltip',
              value: this.getTranslate('misc.links.to.recurrentPayments')
            }
          ],
          on: {
            click: () => {
              Vue.router.push({
                name: 'payments',
                query: { recurrentPaymentId }
              })
            }
          }
        }
      )
    }
  }
}

function renderAmount(h) {
  const source = _.get(this.value, 'data.source')
  const amount = _.get(this.value, 'data.amount')
  if (source) {
    return h(
      'g-chip',
      {
        props: {
          small: true,
          label: getRUB(amount),
          icon: amountIcons[source],
          color: amountColors[source]
        }
      }
    )
  } else {
    return h(
      'g-chip',
      {
        props: {
          small: true,
          label: getRUB(amount),
          color: 'primary'
        }
      }
    )
  }
}

function renderOwner(h) {
  if (this.checkPermissions('advanced.users.get', [ 'reseller', true ])) {
    return h(
      SingleLink,
      {
        props: {
          value: this.value.Owner,
          length: 15
        }
      }
    )
  }
}

function renderOwnerCell(h) {
  if (this.checkPermissions('advanced.users.get', [ 'reseller', true ])) {
    return h(
      'td',
      {
        class: 'px-2'
      },
      [ renderOwner.call(this, h) ]
    )
  }
}

function renderFormattedDate(h, date) {
  return h(
    'div',
    {
      class: 'text--grey text-right',
      style: { 'white-space': 'pre' },
      domProps: { innerHTML: date }
    }
  )
}

function renderRow(h) {
  return h(
    'tr',
    {
      class: 'hover-parent hover-parent--opacity',
      key: this.value.id
    },
    [
      h(
        'td',
        {
          class: 'px-2 w--100'
        },
        [
          h(
            'div',
            {
              class: 'faic'
            },
            [
              renderInfo.call(this, h),

              h('div', { class: 'ff' }),

              renderButtonLinkToRecurrentPayment.call(this, h, this.value)
            ]
          )
        ]
      ),

      h(
        'td',
        {
          class: 'px-2'
        },
        [ renderAmount.call(this, h, this.value) ]
      ),

      renderOwnerCell.call(this, h, this.value),

      h(
        'td',
        {
          class: 'px-2'
        },
        [
          h(
            'div',
            {
              class: 'faic'
            },
            [
              h(tasksFrequency, { props: { value: this.value } }),
              h(tasksActionButtons, { props: { value: this.value } })
            ]
          )
        ]
      ),

      h(
        'td',
        {
          class: 'px-2'
        },
        [ renderFormattedDate.call(this, h, this.value.$formattedCreatedAt) ]
      ),

      h(
        'td',
        {
          class: 'px-2'
        },
        [ renderFormattedDate.call(this, h, this.value.$formattedUpdatedAt) ]
      )
    ]
  )
}

function renderPanel(h) {
  return h(
    'g-expansion-panel',
    {
      scopedSlots: {
        header: () => {
          return h(
            'div',
            {
              class: 'default-expansion-panel-header caption pa-2'
            },
            [
              renderAmount.call(this, h),
              renderTitle.call(this, h),

              h('div', { class: 'ff' }),

              renderFormattedDate.call(this, h, this.value.$formattedCreatedAt)
            ]
          )
        },
        default: () => {
          return h(
            'div',
            {
              class: 'fc ff caption pt-2 pl-2'
            },
            [
              renderInfo.call(this, h, false),

              h(
                'div',
                {
                  class: 'faic fjcfe facfe'
                },
                [
                  renderOwner.call(this, h),

                  renderButtonLinkToRecurrentPayment.call(this, h),

                  h(tasksActionButtons, { props: { value: this.value } })
                ]
              )
            ]
          )
        }
      },
      key: this.value.id
    }
  )
}

function renderContent(h) {
  switch (this.type) {
    case 'panel':
      return renderPanel.call(this, h)
    case 'row':
    default:
      return renderRow.call(this, h)
  }
}

export default function(h) {
  return renderContent.call(this, h)
}
