import { TRAFFIC_TYPE } from '@sigma-legacy-libs/essentials/lib/constants'

export const serviceName = 'tariffRates'

export const trafficTypeColors = {
  [TRAFFIC_TYPE.identity]: 'secondary',
  [TRAFFIC_TYPE.marketing]: 'primary',
  [TRAFFIC_TYPE.service]: 'success'
}

export default { serviceName }
