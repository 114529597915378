import { generateServices, globalErrorHandler, globalErrorProcessor } from '@/utils'

import render from './render'

const _ = { camelCase: require('lodash/camelCase') }

export default function(options = {}) {
  return {
    name: 'CreateButton',

    mixins: [
      generateServices({
        name: options.name,

        inputFilter: options.inputFilterForCreateButton,
        outputFilter: options.outputFilterForCreateButton,

        create: {
          redirect: true,
          params: {
            query: {
              $scope: [ 'full' ]
            }
          }
        },

        find: false,
        get: false,
        update: false,
        remove: false
      })
    ],

    components: {
      [`${_.camelCase(`${options.name}-form`)}`]: require(`@/components/services/${options.name}/form`).default
    },

    props: {
      maxWidth: {
        type: Number,
        default: 800
      }
    },

    data() {
      return {
        isActive: true,
        titleIsValid: true,

        showDialog: false
      }
    },

    computed: {
      createDataIsValid() {
        return (
          this.restData[options.name].create.state !== 'loading' &&
          this.restData[options.name].create.isValid &&
          this.titleIsValid
        )
      }
    },

    methods: {
      async createData() {
        if (this.createDataIsValid) {
          try {
            const result = await this.rest[options.name].create(this.restData[options.name].create.data)
            if (result) {
              this.show = false
            }
          } catch (error) {
            globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
          }
        }
      }
    },

    render(h) {
      return render.call(this, h, options)
    }
  }
}
