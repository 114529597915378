export default {
  title: 'Subscriptions',

  titles: {
    create: 'Create subscription'
  },

  labels: {
    type: 'Subscription type',
    expireAt: 'Next payment',
    pricePerMonth: 'Price per month',
    sendername: 'Sender name',
    counterType: 'Counter type',
    maximum: 'Maximum',
    discountPrice: 'Discount price',
    operator: {
      approved: 'Approved',
      isPaid: 'Paid'
    }
  },

  types: {
    subscription: {
      sms: {
        sendername: 'SMS sender name'
      },
      viber: 'Viber',
      whatsapp: {
        waba: 'WhatsApp business API',
        wapi: 'WhatsApp API'
      }
    }
  },

  counterTypes: {
    session: 'Session',
    message: 'Message',
    price: 'Price'
  },

  operators: {
    tele2: 'Tele2',
    mts: 'MTS',
    megafon: 'Megafon',
    beeline: 'Beeline',
    motiv: 'Motiv'
  },

  tooltips: {
    favorites: 'Favorites'
  },

  hints: {
    noOperators: 'No operators',
    operator: {
      approved: 'Operator approved',
      isPaid: 'Operator paid'
    },
    no: {
      operators: 'No prices for operator. You can specify prices in the site settings, "Operators" section.'
    }
  },

  buttons: {
    add: 'Add subscription'
  },

  snackbars: {
    created: 'Subscription created.',
    updated: 'Subscription updated.',
    removed: 'Subscription removed.'
  }
}
