import Columns from '@/components/misc/Columns'

export default function(h, { path, serviceName }) {
  return h(
    Columns,
    {},
    [
      h(
        'g-text-field',
        {
          props: {
            value: this.proxy.limit,
            label: this.getTranslate(`${serviceName}.labels.${path}.limit`),
            type: 'number',
            step: 'any',
            suffix: '&#8381;',
            mode: 'outline-label',
            rounded: true,
            details: false,
            dense: true,
            clearable: true
          },
          on: {
            input: event => {
              this.proxy.limit = event
            }
          }
        }
      ),

      h(
        'g-text-field',
        {
          props: {
            value: this.proxy.requiredBalance,
            label: this.getTranslate(`${serviceName}.labels.${path}.requiredBalance`),
            type: 'number',
            step: 'any',
            suffix: '&#8381;',
            mode: 'outline-label',
            rounded: true,
            details: false,
            dense: true,
            clearable: true
          },
          on: {
            input: event => {
              this.proxy.requiredBalance = event
            }
          }
        }
      )
    ]
  )
}
