import OwnerSelect from '@/components/misc/OwnerSelect'
import { serviceName } from '@/components/services/tokens/utils'

export default function(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8',
      style: { 'grid-template-columns': this.viewport.breakpoint.mdUp ? '250px' : undefined }
    },
    [
      h(
        OwnerSelect,
        {
          props: {
            value: this.proxy.OwnerId,
            label: this.getTranslate(`${serviceName}.labels.owner`),
            dense: true,
            details: false
          },
          on: {
            input: event => {
              this.proxy.OwnerId = event
            }
          }
        }
      )
    ]
  )
}
