import Vue from 'vue'

import { Macro } from '@sigma-legacy-libs/cache'

import { globalErrorHandler, globalErrorProcessor } from '../generators'
import { permissionPresets } from '../constants'

const _ = {
  get: require('lodash/get'),
  isEqual: require('lodash/isEqual')
}

const Cache = new Macro({
  ttl: 5 * 1000,
  ttlInterval: 1000
})

export const balance = (options = {}) => {
  return {
    props: {
      UserId: String
    },

    data() {
      return {
        balance: 0,
        balanceLoading: false,

        overdraft: 0
      }
    },

    computed: {
      computedUserId() {
        return this.UserId || options.UserId
      }
    },

    watch: {
      $route: {
        handler(to, from) {
          if (!_.isEqual(to.params, from.params) || to.name !== from.name) {
            this.getBalance()
            this.getOverdraft()
          }
        },
        deep: true
      }
    },

    mounted() {
      if (!this.computedUserId || this.computedUserId === this.account.id) {
        Vue.$socket.on('billings.created', ({ OwnerId }) => {
          if (this.account.id === OwnerId) {
            this.getBalance()
          }
        })
      }

      this.getBalance()
      this.getOverdraft()
    },

    methods: {
      cachedGetBalance: Cache.wrapWithCache(async (key, UserId) => {
        return await Vue.$GRequest.get('billings/balance', UserId)
      }),
      cachedGetOverdraft: Cache.wrapWithCache(async (key, UserId) => {
        return await Vue.$GRequest.get('settingsUser', `${UserId}/billings.overdraft`)
      }),

      async getBalance() {
        try {
          this.balanceLoading = true

          const UserId = this.computedUserId || this.account.id
          const { data } = await this.cachedGetBalance(`balance:${UserId}`, UserId)
          if (data) {
            this.balance = data.result
          }
        } catch (error) {
          globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
        } finally {
          this.balanceLoading = false
        }
      },

      async getOverdraft() {
        if (this.computedUserId) {
          if (this.checkPermissions('advanced.settingsUser.get', permissionPresets.me)) {
            try {
              const { data } = await this.cachedGetOverdraft(`overdraft:${this.computedUserId}`, this.computedUserId)
              if (data) {
                let overdraft = parseFloat(data.result)
                if (isNaN(overdraft)) {
                  overdraft = 0
                }

                this.overdraft = overdraft
              }
            } catch (error) {
              globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
            }
          }
        } else {
          this.overdraft = _.get(this.account.settings, 'billings.overdraft', 0)
        }
      }
    }
  }
}

export default { balance }
