import { REGEX_IPV4_WITH_MASK_OR_PATTERN } from '@sigma-legacy-libs/essentials/lib/constants/regexp'

import ConfirmButton from '@/components/button/confirm'

export default function(h, { path, serviceName }) {
  return h(
    ConfirmButton,
    {
      props: {
        value: this.proxy,
        margin: {
          top: 22,
          bottom: 0,
          left: 0,
          right: 0
        }
      },
      on: {
        input: event => {
          if (typeof event === 'string') {
            const replace = event.replace(',', '\n')
            const split = replace.split('\n')
            const trim = split.map(item => item.trim())
            const filter = trim.filter(item => REGEX_IPV4_WITH_MASK_OR_PATTERN.test(item))
            this.$emit('input', filter)
          }
        }
      }
    },
    [
      h(
        'g-textarea',
        {
          props: {
            value: this.proxy,
            label: this.getTranslate(`${serviceName}.labels.${path}`),
            mode: 'outline-label',
            details: false,
            grow: true,
            dense: true,
            rounded: true,
            clearable: true
          },
          on: {
            input: event => {
              this.proxy = event
            }
          }
        }
      )
    ]
  )
}
