import { dateFilter } from '@/utils'

import render from './render'

export default {
  name: 'ReferralPaymentsFilter',

  mixins: [ dateFilter ],

  render
}
