import SendernameSelect from '@/components/misc/SendernameSelect'

export default function(h, { path, serviceName }) {
  return h(
    SendernameSelect,
    {
      props: {
        value: this.proxy,
        label: this.getTranslate(`${serviceName}.labels.${path}`),
        service: 'sendernames',
        itemTitle: 'name',
        itemValue: 'name',
        mode: 'outline-label',
        dense: true,
        rounded: true,
        clearable: true,
        setOnBlur: true,
        autocomplete: true,
        details: false
      },
      on: {
        input: event => {
          this.$emit('input', event)
        }
      }
    }
  )
}
