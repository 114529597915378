import HintRow from '@/components/misc/HintRow'

import { serviceName } from '@/components/services/sendings/utils'

function renderHint(h) {
  if (!this.asOwner) {
    return h(
      HintRow,
      {
        props: {
          value: this.getTranslate(`${serviceName}.hints.resend`)
        }
      }
    )
  }
}

function renderDialog(h) {
  return h(
    'g-dialog',
    {
      props: {
        rounded: true,
        value: this.showDialog,
        maxWidth: 400
      },
      on: {
        input: event => {
          this.showDialog = event
        }
      }
    },
    [
      h(
        'div',
        {
          class: 'headline px-3 pt-3 pb-1',
          slot: 'header'
        },
        this.getTranslate(`${serviceName}.titles.resend`)
      ),

      h(
        'g-checkbox',
        {
          props: {
            value: this.asOwner,
            label: this.getTranslate(`${serviceName}.labels.asOwner`),
            details: false
          },
          on: {
            input: event => {
              this.asOwner = event
            }
          }
        }
      ),

      renderHint.call(this, h),

      h(
        'g-card-actions',
        {
          class: 'fjcfe facfe',
          slot: 'footer'
        },
        [
          h(
            'g-button',
            {
              props: {
                label: this.getTranslate('misc.buttons.cancel'),
                flat: true,
                rounded: true,
                disabled: this.loading
              },
              on: {
                click: () => {
                  this.showDialog = false
                }
              }
            }
          ),

          h(
            'g-button',
            {
              props: {
                label: this.getTranslate('misc.buttons.send'),
                flat: true,
                rounded: true,
                loading: this.loading,
                disabled: this.loading,
                color: 'primary'
              },
              on: {
                click: () => {
                  this.resend()
                }
              }
            }
          )
        ]
      )
    ]
  )
}

export default function(h) {
  return h(
    'g-button',
    {
      class: 'ma-0',
      props: {
        flat: true,
        icon: 'send',
        color: 'primary'
      },
      on: {
        click: () => {
          this.showDialog = true
        }
      },
      scopedSlots: {
        dialog: () => renderDialog.call(this, h)
      }
    }
  )
}
