import serversItemTitle from '@/components/services/servers/item/title'
import serversItemHost from '@/components/services/servers/item/host'

function renderTitle(h) {
  return h(
    serversItemTitle,
    {
      props: {
        value: this.value,
        edit: true
      }
    }
  )
}

function renderDescription(h) {
  if (~[ 'medium', 'large' ].indexOf(this.size)) {
    return h(
      'div',
      {
        class: 'w--100'
      },
      [ h(serversItemHost, { props: { value: this.value } }) ]
    )
  }
}

export default function(h) {
  return h(
    'g-card',
    {
      class: 'hover-parent hover-parent--opacity grid grid-gap--8 pa-2',
      props: {
        outline: true,
        rounded: true
      }
    },
    [
      renderTitle.call(this, h),
      renderDescription.call(this, h)
    ]
  )
}
