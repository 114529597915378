import proxy from '@sigma-legacy-libs/g-proxy'

import render from './render'

export default function(options) {
  return {
    name: 'NotificationsSmsTemplates',

    mixins: [ proxy({ type: 'object' }) ],

    data() {
      return {
        showDialog: false
      }
    },

    render(h) {
      return render.call(this, h, options)
    }
  }
}
