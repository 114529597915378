import messageTypeIcon from '@/components/message/type/icon'
import mccmncRowValue from '@/components/misc/rules/conditions/row/mccmnc'

import { sendingTypeColors } from '@/utils'

function renderValue(h) {
  if (this.tag) {
    if (this.hasValue) {
      switch (this.tag) {
        case 'text':
        case 'code':
        case 'recipient': {
          return h(
            'div',
            {
              class: 'faic ff'
            },
            [
              h(
                'div',
                {
                  class: 'condition__title'
                },
                [
                  h(
                    'g-chip',
                    {
                      props: {
                        label: this.value,
                        small: true,
                        color: 'primary',
                        cut: true,
                        length: this.viewport.breakpoint.mdUp ? 50 : 25
                      }
                    }
                  )
                ]
              )
            ]
          )
        }

        case 'group':
        case 'sender': {
          const count = 3

          return h(
            'div',
            {
              class: 'grid grid-gap--8 fjcfs ff',
              style: { 'grid-template-columns': `repeat(${count + (this.value.length > count ? 1 : 0)}, auto)` }
            },
            this.value.reduce((result, item, index) => {
              if (index < count) {
                result.push(
                  h(
                    'g-chip',
                    {
                      props: {
                        label: item,
                        small: true,
                        color: 'primary'
                      }
                    }
                  )
                )
              }
              if (index === count && this.value.length > count) {
                result.push(
                  h(
                    'g-chip',
                    {
                      class: 'ma-0',
                      props: {
                        label: `+${this.value.length - count}`,
                        round: true,
                        small: true,
                        color: 'primary'
                      }
                    }
                  )
                )
              }

              return result
            }, [])
          )
        }

        case 'mccmnc': {
          return h(
            mccmncRowValue,
            {
              props: {
                value: this.value,
                count: this.viewport.breakpoint.mdUp ? 3 : this.viewport.breakpoint.sm ? 2 : 1
              }
            }
          )
        }

        case 'keywords':
        case 'contactList': {
          return h(
            'div',
            {
              class: 'condition__title'
            },
            [
              h(
                'g-chip',
                {
                  props: {
                    label: `${this.getTranslate(`conditions.labels.${this.tag}`)} | ${this.value.length}`,
                    small: true,
                    color: 'primary',
                    cut: true
                  }
                }
              )
            ]
          )
        }

        case 'type': {
          return h(
            'div',
            {
              class: 'condition__title faic'
            },
            [
              this.value.map((type, index) => {
                if (index < 3) {
                  return h(
                    'g-chip',
                    {
                      props: {
                        small: true,
                        color: sendingTypeColors[type] || 'primary',
                        cut: true,
                        length: 50
                      }
                    },
                    [
                      h(
                        'div',
                        {
                          class: 'grid grid-gap--4 faic',
                          style: { 'grid-template-columns': '16px 1fr' }
                        },
                        [
                          h(
                            messageTypeIcon,
                            {
                              props: {
                                value: type,
                                color: 'white',
                                size: 16
                              }
                            }
                          ),
                          this.getTranslate(`sendings.types.${type}`)
                        ]
                      )
                    ]
                  )
                }
              }),

              this.value.length > 3 ? h(
                'g-chip',
                {
                  props: {
                    small: true,
                    round: true,
                    label: `+${this.value.length - 3}`,
                    color: 'primary'
                  }
                }
              ) : undefined
            ]
          )
        }
      }
    } else {
      return h(
        'div',
        {
          class: 'fc ff'
        },
        [
          h(
            'div',
            {
              class: 'fz-13 lh-15 text--grey pl-2'
            },
            this.getTranslate(`conditions.tags.${this.tag}`)
          ),

          h(
            'div',
            {
              class: 'condition__description'
            },
            this.getTranslate('conditions.hints.noRule')
          )
        ]
      )
    }
  }
}

function renderClearButton(h) {
  if (!this.readonly) {
    return h(
      'g-button',
      {
        class: {
          'ma-0': true,
          'hover-child': this.viewport.breakpoint.mdUp
        },
        props: {
          flat: true,
          icon: 'clear',
          color: 'grey',
          disabled: !this.hasValue
        },
        on: {
          click: () => {
            this.$emit('clear', true)
          }
        }
      }
    )
  }
}

function renderRow(h) {
  if (this.tag) {
    return h(
      'div',
      {
        class: {
          [`condition__row condition__row--${this.tag}`]: true,
          ['condition__row--empty']: !this.hasValue
        },
        on: {
          click: () => {
            this.$emit('dialog', true)
          }
        }
      },
      [ renderValue.call(this, h) ]
    )
  }
}

function renderCondition(h) {
  if (this.tag) {
    return h(
      'div',
      {
        class: 'condition__holder',
        directives: [
          {
            name: 'g-tooltip',
            options: {
              value: this.getTranslate(`conditions.tags.${this.tag}`),
              placement: 'left'
            }
          }
        ]
      },
      [ renderRow.call(this, h) ]
    )
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: {
        'condition hover-parent hover-parent--opacity': true,
        'condition--readonly': this.readonly
      }
    },
    [
      renderCondition.call(this, h),
      renderClearButton.call(this, h)
    ]
  )
}
