import HeadTitle from '@/components/misc/HeadTitle'
import TextEditor from '@/components/misc/TextEditor'

function renderIcon(h) {
  let value
  switch (this.prop) {
    case 'billings.limit': {
      value = 'currency_ruble'
      break
    }
    case 'auth.registration': {
      value = 'person_add_alt'
      break
    }
    case 'auth.recover': {
      value = 'key'
      break
    }
    case 'providers.health': {
      value = 'health_and_safety'
      break
    }
    case 'otp.generate.sendernames.moderation': {
      value = 'sms'
      break
    }
  }

  return h(
    'div',
    {
      class: 'faic fjcc square--48'
    },
    [
      h(
        'g-icon',
        {
          props: {
            value,
            color: 'grey'
          }
        }
      )
    ]
  )
}

function renderTitle(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--2'
    },
    [
      h(
        'div',
        {
          class: 'caption'
        },
        this.getTranslate(`${this.service}.contents.${this.path}.${this.prop}`)
      ),

      h(
        'div',
        {
          class: 'small text--grey'
        },
        this.getTranslate(`${this.service}.hints.${this.path}.${this.prop}`)
      )
    ]
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--0 faic cursor-pointer hover-background',
      style: {
        'grid-template-columns': '48px 1fr',
        border: '1px dashed rgba(0, 0, 0, 0.12)',
        'border-radius': '16px'
      },
      on: {
        click: () => {
          this.showDialog = true
        }
      }
    },
    [
      renderIcon.call(this, h),
      renderTitle.call(this, h),

      h(
        'g-dialog',
        {
          props: {
            rounded: true,
            value: this.showDialog,
            maxWidth: 600
          },
          on: {
            input: event => {
              this.showDialog = event
            }
          }
        },
        [
          h(
            HeadTitle,
            {
              props: { value: this.getTranslate(`${this.service}.dialogs.headers.${this.path}.${this.prop}`) },
              slot: 'header'
            }
          ),

          h(
            'div',
            {
              class: 'fc pa-3'
            },
            [
              h(
                'g-text-field',
                {
                  props: {
                    value: this.proxy[this.prop].subject,
                    label: this.getTranslate(`${this.service}.labels.${this.path}.subject`),
                    clearable: true
                  },
                  on: {
                    input: event => {
                      this.proxy[this.prop].subject = event
                    }
                  }
                }
              ),

              h(
                TextEditor,
                {
                  props: {
                    value: this.proxy[this.prop].body,
                    placeholder: this.getTranslate(`${this.service}.labels.${this.path}.body`)
                  },
                  on: {
                    input: event => {
                      this.proxy[this.prop].body = event
                    }
                  }
                }
              )
            ]
          ),

          h(
            'g-card-actions',
            {
              class: 'fjcsb',
              slot: 'footer'
            },
            [
              h(
                'g-button',
                {
                  props: {
                    label: this.getTranslate('misc.buttons.close'),
                    flat: true,
                    rounded: true
                  },
                  on: {
                    click: () => {
                      this.showDialog = false
                    }
                  }
                }
              ),

              h(
                'g-button',
                {
                  props: {
                    label: this.getTranslate('misc.buttons.save'),
                    flat: true,
                    rounded: true,
                    icon: 'save',
                    color: 'primary'
                  },
                  on: {
                    click: () => {
                      this.$emit('input', this.proxy)
                      this.showDialog = false
                    }
                  }
                }
              )
            ]
          )
        ]
      )
    ]
  )
}
