import render from './render'

export default {
  name: 'SpacerCell',

  functional: true,

  props: {
    value: null
  },

  render
}
