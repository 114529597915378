export default function(h) {
  return h(
    'div',
    {
      class: 'grid faic',
      style: { 'grid-template-columns': '24px 1fr 36px' }
    },
    [
      h(
        'g-icon',
        {
          props: {
            value: 'subtitles',
            color: 'grey'
          },
          directives: [
            {
              name: 'g-tooltip',
              options: { value: this.getTranslate(`${this.serviceName}.labels.title`) }
            }
          ]
        }
      ),

      h(
        'g-text-field',
        {
          props: {
            value: this.proxy.title,
            label: this.getTranslate(`${this.serviceName}.labels.title`),
            mode: 'outline',
            rounded: true,
            dense: true,
            clearable: true,
            required: true,
            details: false,
            autofocus: true
          },
          on: {
            input: event => {
              this.proxy.title = event
            }
          }
        }
      ),

      h(
        'g-switch',
        {
          props: {
            value: this.proxy.isActive,
            disabled: this.disabled
          },
          directives: [
            {
              name: 'g-tooltip',
              options: { value: this.getTranslate(`${this.serviceName}.tooltips.isActive`) }
            }
          ],
          on: {
            input: event => {
              this.proxy.isActive = event
            }
          }
        }
      )
    ]
  )
}
