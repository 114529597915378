export default {
  title: 'Sender names',
  sendername: 'Sender name',

  titles: {
    new: 'New sender name',
    create: 'Create new sender name',
    users: 'Users',
    comments: 'Comments'
  },

  headers: {
    moderation: 'Moderation',
    sendername: 'Sender name',
    comment: 'Comment'
  },

  labels: {
    name: 'Sender name',
    type: 'Type',
    moderation: 'Moderation',
    owner: 'Owner',
    isActive: 'Activate or deactivate sender name',
    meta: {
      comment: 'Comment',
      adminComment: 'Administrator comment',
      adminCommentPrivate: 'Private administrator comment',
      resellerComment: 'Reseller comment',
      resellerCommentPrivate: 'Private reseller comment'
    },
    confirmationCode: 'Enter code',
    searchAndAdd: 'Search and add'
  },

  statuses: {
    disabled: 'Not requested',
    requested: 'Requested',
    moderation: 'Moderation',
    approved: 'Approved',
    rejected: 'Rejected'
  },

  contents: {
    confirm: {
      remove: 'Delete sender name?'
    }
  },

  hints: {
    comment: 'Make sure to specify your company name and TIN in comment',
    emptyState: 'No one sender name found',
    confirmationCode: {
      again: 'Request code again',
      timer: 'Repeated request of a code is possible through: {count} sec'
    },
    enterAndAdd: 'Enter the sender names and press ENTER to add it',
    notFound: 'Sender names not found'
  },

  tooltips: {
    create: 'Create new sender name',
    favorites: 'Make the sender name a favorite',
    filter: {
      isActive: 'Show deactivated sender names'
    },
    copy: 'Copy name to buffer'
  },

  errors: {
    sms: 'Only latin characters or digits allowed, 11 symbols maximum',
    voice: 'Numbers only, 6 to 15 characters'
  },

  buttons: {
    users: 'Users {count}',
    confirmationCode: 'Get Verification Code',
    approve: 'Approve',
    reject: 'Reject'
  },

  variants: {
    contact: 'Contact us',
    comment: 'Wrong comment',
    length: 'Wrong length',
    common: 'Common name',
    international: 'International name'
  },

  pills: {
    all: 'Available',
    checked: 'Checked'
  },

  snackbars: {
    created: 'Sender name successfully created.',
    updated: 'Sender name successfully updated.',
    removed: 'Sender name successfully removed.',
    confirmationCode: {
      rateLimit: 'Request code only once per minute',
      invalidCode: 'Invalid code',
      success: 'Verification was successful'
    }
  }
}
