import { TASK_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

const colors = {
  [TASK_TYPES['payments:recurrent']]: 'info',
  [TASK_TYPES['billings:operations']]: 'success'
}

export default function(h) {
  if (this.value.type) {
    return h(
      'g-chip',
      {
        props: {
          small: true,
          color: colors[this.value.type]
        }
      },
      [
        h(
          'div',
          {
            class: 'caption text-nowrap text--white'
          },
          this.getTranslate(`tasks.types.${this.value.type}`)
        )
      ]
    )
  }
}
