import { isArrayNotEmpty } from '@/utils'

function renderItem(h, { name, meta }) {
  const { navIcon, navType, params } = meta || {}
  const props = {
    icon: navIcon,
    active: !!~this.$route.name.indexOf(name),
    label: this.getTranslate(`${name}.title`)
  }
  if (!navType || navType !== 'group') {
    props.to = {
      name,
      params
    }
  }

  return h(
    'g-list-item',
    {
      props,
      slot: navType === 'group' ? 'activator' : undefined
    }
  )
}
function renderItemByType(h, { name, meta, children }) {
  const { permission, permissionAccept, navType } = meta || {}
  if (permission) {
    if (!this.checkPermissions(permission, permissionAccept)) {
      return
    }
  }

  switch (navType) {
    case 'subheader': {
      return h(
        'g-subheader',
        {
          class: 'plpx-48',
          props: { dense: true }
        },
        [ this.getTranslate(`commons.subheader.${name}`) ]
      )
    }

    case 'group': {
      const result = []
      if (isArrayNotEmpty(children)) {
        for (const child of children) {
          result.push(renderItem.call(this, h, child))
        }
      }

      return h(
        'g-list-group',
        {},
        [
          renderItem.call(this, h, {
            name,
            meta
          }),

          ...result
        ]
      )
    }

    case 'item':
    default: {
      return renderItem.call(this, h, {
        name,
        meta
      })
    }
  }
}

export default function(h) {
  return h(
    'g-list',
    {
      props: { dense: true }
    },
    this.routes.map(({ name, meta, children }) => {
      return renderItemByType.call(this, h, {
        name,
        meta,
        children
      })
    })
  )
}
