import { unVue } from '@sigma-legacy-libs/unvue'
import { EMPTY_REQUISITES, PUBLIC_FIELDS_USER } from '@sigma-legacy-libs/essentials/lib/constants'

import { fillDependence, serviceTemplate } from '@/utils'

import { serviceName } from '@/components/services/requisites/utils'

const _ = { defaultsDeep: require('lodash/defaultsDeep') }

export default {
  mixins: [
    serviceTemplate({
      serviceName,

      async inputFilter(result) {
        _.defaultsDeep(result, unVue(EMPTY_REQUISITES)) // потому что с сервера приходит урезанный payload

        if (!result.Owner) {
          await fillDependence.call(this, result, {
            service: 'users',
            permissions: [ 'reseller', true ],
            pathToId: 'OwnerId',
            pathToObject: 'Owner',
            outputPath: 'Owner',
            picks: PUBLIC_FIELDS_USER
          })
        }

        result.OwnerId = result.Owner && result.Owner.id || result.OwnerId

        return result
      },

      find: {
        defaultFilter: {
          OwnerId: undefined,
          isActive: true,
          $scope: [ 'Owner' ]
        }
      },

      width: 600,
      formPaddles: true
    })
  ]
}
