import AccountForm from '@/components/services/account/form'
import DocumentTemplatesGenerateButton from '@/components/services/documentTemplates/generate/button'

import { STATES } from '@/utils'

import { serviceName } from '@/components/services/users/utils'

function renderAccountTitle(h) {
  return h(
    'div',
    {
      class: 'grid pl-2',
      style: {
        'grid-template-columns': '24px 1fr'
      }
    },
    [
      h(
        'g-icon',
        {
          props: {
            value: 'person',
            color: 'grey'
          }
        }
      ),

      h(
        'div',
        {
          class: 'grid grid-gap--2'
        },
        [
          h('div', { class: 'fz-24' }, [ this.title ]),
          h('div', { class: 'small text--grey' }, [ this.restData[serviceName].get.data.data.phone ])
        ]
      )
    ]
  )
}

function renderHeader(h) {
  return h(
    'div',
    {
      class: 'faic fjcsb'
    },
    [
      renderAccountTitle.call(this, h),

      h(DocumentTemplatesGenerateButton, { class: 'ma-0' })
    ]
  )
}

function renderBody(h) {
  return h(
    'g-card',
    {
      props: { rounded: true }
    },
    [
      h(
        AccountForm,
        {
          props: {
            value: this.restData[serviceName].get.data,
            errors: this.restData[serviceName].errors
          },
          on: {
            input: event => {
              this.restData[serviceName].get.data = event
            }
          }
        }
      )
    ]
  )
}

function renderFooter(h) {
  return h(
    'div',
    {
      class: 'fjcfe'
    },
    [
      h(
        'g-button',
        {
          class: 'ma-0',
          props: {
            label: this.getTranslate('misc.buttons.update'),
            rounded: true,
            depressed: true,
            loading: this.restData[serviceName].update.state === 'loading',
            disabled: this.restData[serviceName].update.state === 'loading',
            color: 'primary'
          },
          on: {
            click: () => {
              this.update()
            }
          }
        }
      )
    ]
  )
}

function renderPreLoader(h) {
  return h(
    'div',
    {
      class: 'ff faic pa-3'
    },
    [
      h(
        'g-progress',
        {
          props: {
            indeterminate: true
          }
        }
      )
    ]
  )
}

function renderContent(h) {
  switch (this.restData[serviceName].get.state) {
    case STATES.ready: {
      return h(
        'div',
        {
          class: 'grid grid-gap--8',
          style: { 'max-width': '800px' }
        },
        [
          renderHeader.call(this, h),
          renderBody.call(this, h),
          renderFooter.call(this, h)
        ]
      )
    }

    case STATES.loading: {
      return renderPreLoader.call(this, h)
    }
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: {
        grid: true,
        'grid-cols--2': this.viewport.breakpoint.xl
      }
    },
    [ renderContent.call(this, h) ]
  )
}
