import render from './render'

export default {
  name: 'ModerationStatusBarWithIcon',

  functional: true,

  props: {
    type: {
      type: String,
      default: 'approved'
    },

    size: {
      type: Number,
      default: 24
    },

    title: String,
    distanceBetween: {
      type: Number,
      default: 3
    }
  },

  render
}
