import { unVue } from '@sigma-legacy-libs/unvue'
import { EMPTY_SENDINGS } from '@sigma-legacy-libs/essentials/lib/constants'

import { catchSymbolsMenus, getHammerTime, getISODate, getYesterday, isArrayNotEmpty, routerQueryReplace } from '@/utils'

import HeadTitle from '@/components/misc/HeadTitle'
import MessagePreview from '@/components/message/preview'
import TemplatesForm from '@/components/services/templates/form'
import TemplatesTemplate from '@/components/services/templates/template'
import TemplatesFormLite from '@/components/services/templates/formLite'
import SendingsCreateForm from '@/components/services/sendingsCreate/form'
import SendingsCreateFallbacks from '@/components/services/sendingsCreate/fallbacks'
import SendingsCreateRecipients from '@/components/services/sendingsCreate/recipients'

import { serviceName } from '@/components/services/sendings/utils'

const _ = { pick: require('lodash/pick') }

function renderWarning(h) {
  if (!isArrayNotEmpty(this.sendingTypes)) {
    return h(
      'g-card',
      {
        class: {
          fasc: true,
          'mt-5': this.viewport.breakpoint.mdUp
        },
        props: {
          rounded: true,
          maxWidth: 600
        }
      },
      [
        h(
          'div',
          {
            class: 'faic'
          },
          [
            h(
              'div',
              {
                class: 'fjcc facc faic square--48'
              },
              [
                h(
                  'g-icon',
                  {
                    props: {
                      value: 'error_outline',
                      color: 'error'
                    }
                  }
                )
              ]
            ),

            h(
              'div',
              {
                class: 'py-3 pr-3 fz-14',
                domProps: { innerHTML: this.getTranslate(`${serviceName}.contents.noAllowedSendingTypes`) }
              }
            )
          ]
        )
      ]
    )
  }
}

function renderTabsHeader(h) {
  if (isArrayNotEmpty(this.sendingTypes)) {
    if (this.viewport.breakpoint.mdDown) {
      return h(
        'g-tabs-header',
        {
          class: 'pl-3',
          props: {
            value: this.currentTab,
            tabs: [
              {
                key: 'create',
                title: this.getTranslate(`${serviceName}.tabs.create`)
              },
              {
                key: 'templates',
                title: this.getTranslate(`${serviceName}.tabs.templates`),
                callback: () => {
                  this.template = undefined
                }
              }
            ]
          },
          on: {
            input: event => {
              routerQueryReplace({ currentTab: event })
            }
          }
        }
      )
    }
  }
}

function renderSendingRecipientsFields(h) {
  return h(
    SendingsCreateRecipients,
    {
      props: {
        value: this.restData[serviceName].create.data.recipient,
        error: this.recipientsError
      },
      on: {
        input: event => {
          this.restData[serviceName].create.data.recipient = event
        },
        'update:error': event => {
          this.recipientsError = event
        }
      }
    }
  )
}

function renderSendingTitleField(h) {
  if (this.computedTypeRecipient === 'bulk') {
    return h(
      'g-text-field',
      {
        props: {
          value: this.restData[serviceName].create.data.$options.title,
          label: this.getTranslate(`${serviceName}.labels.title`),
          mode: 'outline',
          rounded: true,
          clearable: true,
          details: false
        },
        on: {
          input: event => {
            this.restData[serviceName].create.data.$options.title = event
          }
        }
      }
    )
  }
}

function renderSendingForm(h) {
  if (!this.fallbacks) {
    return h(
      SendingsCreateForm,
      {
        props: {
          value: this.restData[serviceName].create.data,
          errors: this.restData[serviceName].create.errors,
          template: this.template,
          types: this.sendingTypes,
          isNewMessage: true,
          tags: catchSymbolsMenus.$,
          customMenu: catchSymbolsMenus.custom.$
        },
        on: {
          input: event => {
            this.restData[serviceName].create.data = event
          },
          recipient: event => {
            this.templateRecipient(event)
          }
        }
      }
    )
  }
}

function renderSendingContent(h) {
  return h(
    'div',
    {
      class: 'grid grid-cols--1 grid-gap--8 pa-2'
    },
    [
      renderSendingRecipientsFields.call(this, h),
      renderSendingTitleField.call(this, h),
      renderSendingForm.call(this, h)
    ]
  )
}

function renderFallbacks(h) {
  if (this.fallbacks) {
    return h(
      'div',
      {
        class: 'fc pt-3'
      },
      [
        h(
          SendingsCreateFallbacks,
          {
            props: {
              value: this.restData[serviceName].create.data,
              defaultValue: _.pick(unVue(EMPTY_SENDINGS), [ 'type', 'payload', '$options' ]),
              flat: true
            },
            scopedSlots: {
              default: ({ value }) => {
                return h(
                  SendingsCreateForm,
                  {
                    class: 'pl-3 pr-3',
                    props: {
                      value,
                      types: this.sendingTypes,
                      fallback: true,
                      tags: catchSymbolsMenus.$,
                      customMenu: catchSymbolsMenus.custom.$
                    },
                    on: {
                      input: event => {
                        value = event
                      }
                    }
                  }
                )
              }
            }
          }
        )
      ]
    )
  }
}

function renderScheduled(h) {
  if (this.showScheduled) {
    return h(
      'div',
      {
        class: {
          'grid grid-gap--8 pa-2': true,
          'grid-cols--2': this.viewport.breakpoint.mdUp,
          'grid-cols--1': this.viewport.breakpoint.smDown
        }
      },
      [
        h(
          'g-menu',
          {
            class: 'w--100',
            props: {
              closeOnContentClick: false,
              rounded: true,
              placement: 'left'
            }
          },
          [
            h(
              'g-text-field',
              {
                props: {
                  value: this.dateScheduleDelay,
                  label: this.getTranslate(`${serviceName}.labels.schedule.delay`),
                  afterIcon: 'event',
                  readonly: true,
                  mode: 'outline-label',
                  rounded: true,
                  details: false
                },
                slot: 'activator'
              }
            ),

            h(
              'div',
              {
                class: 'fc ff'
              },
              [
                h(
                  'g-date-picker',
                  {
                    props: {
                      value: this.changeableDates.delay.date,
                      localeTag: this.locale,
                      range: false,
                      min: getYesterday(),
                      filter: dates => dates.map(date => date && getISODate(date))
                    },
                    on: {
                      input: event => {
                        this.changeableDates.delay.date = event
                      }
                    }
                  }
                ),

                h(
                  'div',
                  {
                    class: 'fjcc facc faic px-2'
                  },
                  [
                    h(
                      'g-text-field',
                      {
                        props: {
                          value: this.changeableDates.delay.time,
                          type: 'time',
                          mode: 'outline',
                          rounded: true,
                          details: false
                        },
                        on: {
                          input: event => {
                            this.changeableDates.delay.time = event
                          }
                        }
                      }
                    )
                  ]
                ),

                h(
                  'g-card-actions',
                  {
                    class: 'fjcfe facfe'
                  },
                  [
                    h(
                      'g-button',
                      {
                        props: {
                          label: this.getTranslate('misc.buttons.clear'),
                          flat: true,
                          rounded: true
                        },
                        on: {
                          click: () => {
                            this.changeableDates.delay.date = undefined
                            this.changeableDates.delay.time = getHammerTime()
                          }
                        }
                      }
                    )
                  ]
                )
              ]
            )
          ]
        ),

        h(
          'g-select',
          {
            props: {
              label: this.getTranslate(`${serviceName}.labels.schedule.until`),
              items: [ 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12 ].map(item => {
                return {
                  title: this.getTranslate(`${serviceName}.schedule.until.${item}`),
                  value: item
                }
              }),
              disabled: !this.restData[serviceName].create.data.schedule.delay,
              required: true,
              mode: 'outline-label',
              rounded: true,
              details: false
            },
            on: {
              input: event => {
                this.changeableDates.until = event
              }
            }
          }
        )
      ]
    )
  }
}

function renderSetFallbacks(h) {
  if (!this.fallbacks) {
    return h(
      'div',
      {
        class: 'fjcfe facfe'
      },
      [
        h(
          'g-button',
          {
            attrs: { id: 'v-step-create-fallbacks' },
            class: 'my-0',
            props: {
              label: this.getTranslate('misc.buttons.fallback'),
              icon: 'add',
              rounded: true,
              outline: true,
              depressed: true,
              color: 'primary'
            },
            on: {
              click: this.setFallbacks
            }
          }
        )
      ]
    )
  }
}

function renderTourButton(h) {
  this.$nextTick(() => {
    for (const tour in this.$tours) {
      if (tour === 'create') {
        return h(
          'g-button',
          {
            props: {
              icon: 'help_outline',
              flat: true,
              color: 'grey'
            },
            on: {
              click: () => {
                this.$tours[this.$route.name].start()
              }
            }
          }
        )
      }
    }
  })
}

function renderMessagePreviewContent(h, trigger) {
  if (trigger) {
    return h(
      'div',
      {
        class: 'fc ff'
      },
      [
        renderTitle.call(this, h, 'sendingsCreate.titles.preview'),

        h(
          MessagePreview,
          {
            props: { value: this.restData[serviceName].create.data }
          }
        )
      ]
    )
  }
}

function renderMessagePreviewDialog(h) {
  return h(
    'g-dialog',
    {
      props: {
        value: this.showMessagePreview,
        rounded: true,
        maxWidth: 400
      },
      on: {
        input: event => {
          this.showMessagePreview = event
        }
      }
    },
    [
      renderMessagePreviewContent.call(this, h, true),

      h(
        'g-card-actions',
        {
          class: 'fjcfe'
        },
        [
          h(
            'g-button',
            {
              props: {
                flat: true,
                rounded: true,
                label: this.getTranslate('misc.buttons.close')
              },
              on: {
                click: () => {
                  this.showMessagePreview = false
                }
              }
            }
          )
        ]
      )
    ]
  )
}

function renderMessagePreviewButton(h) {
  return h(
    'g-button',
    {
      props: {
        outline: true,
        icon: 'preview',
        color: 'primary',
        disabled: this.createDisabled
      },
      on: {
        click: () => {
          this.showMessagePreview = true
        }
      },
      scopedSlots: {
        dialog: () => renderMessagePreviewDialog.call(this, h)
      }
    }
  )
}

function renderSendingFooter(h) {
  return h(
    'g-card-actions',
    {},
    [
      renderTourButton.call(this, h),

      h('div', { class: 'ff' }),

      renderMessagePreviewButton.call(this, h),

      h(
        'g-button',
        {
          attrs: { id: 'v-step-create-template-save' },
          props: {
            icon: 'save',
            rounded: true,
            depressed: true,
            outline: true,
            disabled: !this.restData[serviceName].create.data.payload.sender && !this.restData[serviceName].create.data.payload.text,
            color: 'success'
          },
          directives: [
            {
              name: 'g-tooltip',
              options: {
                value: this.getTranslate(`${serviceName}.tooltips.save`),
                offsetDistance: 8
              }
            }
          ],
          on: {
            click: () => {
              this.saveTemplate()
            }
          },
          slot: 'activator'
        }
      ),

      h(
        'g-button',
        {
          attrs: { id: 'v-step-create-schedule' },
          props: {
            icon: this.showScheduled ? 'keyboard_arrow_up' : 'date_range',
            rounded: true,
            depressed: true,
            outline: true,
            dark: !!this.restData[serviceName].create.data.schedule.delay,
            disabled: this.createDisabled,
            color: 'secondary'
          },
          directives: [
            {
              name: 'g-tooltip',
              options: {
                value: this.getTranslate(`${serviceName}.tooltips.planning`),
                offsetDistance: 8
              }
            }
          ],
          on: {
            click: () => {
              this.showScheduled = !this.showScheduled
            }
          },
          slot: 'activator'
        }
      ),

      h(
        'g-button',
        {
          props: {
            label: this.restData[serviceName].create.data.schedule.delay ? this.getTranslate('misc.buttons.scheduled') : this.getTranslate('misc.buttons.send'),
            suffix: this.restData[serviceName].create.data.schedule.delay ? 'schedule' : 'send',
            rounded: true,
            depressed: true,
            disabled: !(this.recipientValidation && this.payloadValidation && this.fallbacksValidation),
            color: this.restData[serviceName].create.data.schedule.delay ? 'secondary' : 'primary'
          },
          on: {
            click: () => {
              this.showConfirmCreateSendings = true
            }
          }
        }
      )
    ]
  )
}

function renderSendingsCreateContent(h) {
  return h(
    'div',
    {
      class: 'fc ff',
      key: 'create'
    },
    [
      renderSendingContent.call(this, h),
      renderFallbacks.call(this, h),
      renderScheduled.call(this, h),
      renderSetFallbacks.call(this, h),
      renderSendingFooter.call(this, h)
    ]
  )
}

function renderTemplatesContent(h) {
  return h(
    TemplatesTemplate,
    {
      key: 'templates',
      on: {
        template: event => {
          this.template = event
        }
      }
    }
  )
}

function renderTitle(h, path) {
  return h(
    'div',
    {
      class: 'headline pa-3'
    },
    this.getTranslate(path)
  )
}

function renderContent(h) {
  if (isArrayNotEmpty(this.sendingTypes)) {
    if (this.viewport.breakpoint.lgUp) {
      return h(
        'div',
        {
          class: 'grid faifs',
          style: {
            'grid-template-columns': this.viewport.breakpoint.xl ? '600px 600px minmax(300px, 400px)' : 'repeat(2, 1fr)'
          }
        },
        [
          h(
            'div',
            {
              class: 'fc ff'
            },
            [
              renderTitle.call(this, h, 'sendingsCreate.titles.message'),

              h(
                'g-card',
                {
                  props: {
                    rounded: true,
                    maxWidth: 600
                  }
                },
                [ renderSendingsCreateContent.call(this, h) ]
              )
            ]
          ),

          h(
            'div',
            {
              class: 'fc ff'
            },
            [
              renderTitle.call(this, h, 'templates.title'),

              h(
                'g-card',
                {
                  props: {
                    rounded: true,
                    maxWidth: 600
                  }
                },
                [ renderTemplatesContent.call(this, h) ]
              )
            ]
          ),

          renderMessagePreviewContent.call(this, h, !this.createDisabled && this.viewport.breakpoint.lgUp)
        ]
      )
    } else {
      return h(
        'g-card',
        {
          props: {
            rounded: true,
            maxWidth: 600
          }
        },
        [
          h(
            'g-tabs',
            {
              props: { value: this.currentTab }
            },
            [
              renderSendingsCreateContent.call(this, h),
              renderTemplatesContent.call(this, h)
            ]
          )
        ]
      )
    }
  }
}

function renderConfirmDialog(h) {
  return h(
    'g-dialog',
    {
      props: {
        rounded: true,
        value: this.showConfirmCreateSendings,
        maxWidth: 600
      },
      on: {
        input: event => {
          this.showConfirmCreateSendings = event
        }
      }
    },
    [
      h(
        HeadTitle,
        {
          props: {
            value:
            this.getTranslate(`${serviceName}.confirm.send.title`) +
            this.getTranslateTemplate(`${serviceName}.confirm.send.count`, {
              count: this.restData[serviceName].create.data.recipient.include
                ? this.restData[serviceName].create.data.recipient.include.length
                : 1
            })
          },
          slot: 'header'
        }
      ),

      h('div', { class: 'pa-3' }, this.getTranslate(`${serviceName}.confirm.send.text`)),

      h(
        'g-card-actions',
        {
          class: 'fjcfe facfe',
          slot: 'footer'
        },
        [
          h(
            'g-button',
            {
              props: {
                label: this.getTranslate('misc.buttons.cancel'),
                flat: true,
                rounded: true,
                disabled: this.restData[serviceName].create.state === 'loading'
              },
              on: {
                click: () => {
                  this.showConfirmCreateSendings = false
                }
              }
            }
          ),
          h(
            'g-button',
            {
              props: {
                label: this.getTranslate('misc.buttons.continue'),
                rounded: true,
                depressed: true,
                loading: this.restData[serviceName].create.state === 'loading',
                disabled: this.restData[serviceName].create.state === 'loading',
                color: 'primary'
              },
              on: {
                click: () => {
                  this.createSending()
                }
              }
            }
          )
        ]
      )
    ]
  )
}

function renderTemplateForm(h) {
  if (this.showEditTemplate) {
    return [
      h(
        HeadTitle,
        {
          props: { value: this.getTranslate('templates.dialogs.create') },
          slot: 'header'
        }
      ),

      h(
        TemplatesForm,
        {
          props: {
            value: this.restData.templates.create.data,
            errors: this.restData.templates.create.errors
          },
          on: {
            input: event => {
              this.restData.templates.create.data = event
            }
          }
        }
      ),

      h(
        'g-card-actions',
        {
          class: 'fjcfe facfe',
          slot: 'footer'
        },
        [
          h(
            'g-button',
            {
              props: {
                label: this.getTranslate('misc.buttons.cancel'),
                flat: true,
                rounded: true
              },
              on: {
                click: () => {
                  this.showCreateTemplates = false
                }
              }
            }
          ),
          h(
            'g-button',
            {
              props: {
                label: this.getTranslate('templates.buttons.create'),
                rounded: true,
                depressed: true,
                color: 'primary'
              },
              on: {
                click: () => {
                  this.createTemplate()
                }
              }
            }
          )
        ]
      )
    ]
  } else {
    return h(
      TemplatesFormLite,
      {
        props: {
          value: this.restData.templates.create.data,
          errors: this.restData.templates.create.errors
        },
        on: {
          input: event => {
            this.restData.templates.create.data = event
          },
          submit: async () => {
            this.createTemplate()
          }
        }
      },
      [
        h(
          'div',
          {
            class: 'faic fajfe'
          },
          [
            h(
              'g-button',
              {
                props: {
                  label: this.getTranslate('misc.buttons.edit'),
                  flat: true,
                  rounded: true
                },
                on: {
                  click: () => {
                    this.showEditTemplate = true
                  }
                }
              }
            ),
            h(
              'g-button',
              {
                class: 'mr-0',
                props: {
                  label: this.getTranslate('misc.buttons.create'),
                  rounded: true,
                  depressed: true,
                  color: 'primary'
                },
                on: {
                  click: async () => {
                    const result = await this.rest.templates.create(this.restData.templates.create.data)
                    if (result) {
                      this.showCreateTemplates = false
                    }
                  }
                }
              }
            )
          ]
        )
      ]
    )
  }
}

function renderCreateTemplateDialog(h) {
  return h(
    'g-dialog',
    {
      props: {
        rounded: true,
        value: this.showCreateTemplates,
        maxWidth: this.showEditTemplate ? 600 : 300
      },
      on: {
        input: event => {
          this.showCreateTemplates = event
        }
      }
    },
    [ renderTemplateForm.call(this, h) ]
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: 'fc ff'
    },
    [
      renderWarning.call(this, h),
      renderTabsHeader.call(this, h),
      renderContent.call(this, h),
      renderConfirmDialog.call(this, h),
      renderCreateTemplateDialog.call(this, h)
    ]
  )
}
