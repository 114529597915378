export default function(h) {
  const { error } = this.value
  if (error) {
    return h(
      'div',
      {
        class: 'text-overflow text--error'
      },
      error
    )
  }
}
