import { isArrayNotEmpty } from '@/utils'

export default function(h) {
  if (isArrayNotEmpty(this.proxy)) {
    return h(
      'div',
      {
        class: 'grid grid-gap--8'
      },
      this.proxy.map((item, index, items) => {
        if (this.$scopedSlots.default) {
          return this.$scopedSlots.default({
            item,
            index,
            length: items.length,
            addField: this.addField,
            removeField: this.removeField
          })
        }
      })
    )
  }
}
