export default function(h) {
  return h(
    'g-input',
    {
      attrs: {
        id: 'file',
        type: 'file',
        hidden: true,
        accept: this.attributes.types,
        multiple: this.attributes.multiple
      },
      on: {
        change: event => {
          this.setNewFile(event.target.files)
        }
      }
    }
  )
}
