import { serviceName } from '@/components/services/messageRuleGroups/utils'

import { STATES } from '@/utils'

function renderRows(h) {
  return this.restData[serviceName].find.data.map(item => {
    return h(
      'div',
      {
        class: 'faic hover-background cursor-pointer lh-32 px-3',
        on: {
          click: () => {
            this.$emit('input', item.id)
            this.clear()
            this.$emit('show', false)
          }
        }
      },
      [ item.title ]
    )
  })
}

function renderRoutingGroups(h) {
  if (this.restData[serviceName].find.state === STATES.ready) {
    if (this.restData[serviceName].find.pagination.total) {
      return h(
        'div',
        {
          class: 'fc ff pt-3'
        },
        [
          h(
            'div',
            {
              class: 'caption text--grey px-3 mb-3',
              domProps: { innerHTML: this.getTranslate('routes.hints.direct.choose') }
            }
          ),

          renderRows.call(this, h)
        ]
      )
    }
  }
}

export default function(h) {
  return h(
    'g-dialog',
    {
      props: {
        value: this.show,
        rounded: true,
        maxWidth: 400
      },
      on: {
        input: event => {
          this.$emit('show', event)
        }
      }
    },
    [
      h(
        'div',
        {
          class: 'faic pt-3 px-3 text--grey headline',
          slot: 'header'
        },
        this.getTranslate(`${serviceName}.dialogs.title.direct`)
      ),

      h(
        'div',
        {
          class: 'fc ff pa-3'
        },
        [
          h(
            'g-select',
            {
              props: {
                value: this.messageRuleId,
                label: this.getTranslate(`${serviceName}.labels.rule`),
                service: 'messageRules',
                query: { isActive: true },
                mode: 'outline',
                rounded: true,
                details: false,
                autocomplete: true
              },
              on: {
                input: event => {
                  this.messageRuleId = event
                }
              }
            }
          ),

          renderRoutingGroups.call(this, h)
        ]
      ),

      h(
        'g-card-actions',
        {
          class: 'fjcfe facfe',
          slot: 'footer'
        },
        [
          h(
            'g-button',
            {
              props: {
                flat: true,
                rounded: true,
                disabled: this.loading,
                label: this.getTranslate('misc.buttons.cancel')
              },
              on: {
                click: () => {
                  this.clear()
                  this.$emit('show', false)
                }
              }
            }
          ),

          h(
            'g-button',
            {
              props: {
                rounded: true,
                depressed: true,
                loading: this.loading,
                disabled: this.disabled || this.loading,
                color: 'primary',
                label: this.getTranslate('misc.buttons.create')
              },
              on: {
                click: () => {
                  this.create()
                }
              }
            }
          )
        ]
      )
    ]
  )
}
