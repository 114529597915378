import { unVue } from '@sigma-legacy-libs/unvue'
import { EMPTY_SENDINGS } from '@sigma-legacy-libs/essentials/lib/constants'

import OwnerSelect from '@/components/misc/OwnerSelect'
import SendingsCreateForm from '@/components/services/sendingsCreate/form'
import SendingsCreateFallbacks from '@/components/services/sendingsCreate/fallbacks'
import SendingsCreateRecipients from '@/components/services/sendingsCreate/recipients'

import { catchSymbolsMenus } from '@/utils'

const _ = { pick: require('lodash/pick') }

const name = 'templates'

function renderForm(h) {
  if (this.fallbacks) {
    return h(
      SendingsCreateFallbacks,
      {
        props: {
          value: this.proxy,
          defaultValue: _.pick(unVue(EMPTY_SENDINGS), [ 'type', 'payload', '$options' ]),
          rounded: true,
          outline: true
        },
        scopedSlots: {
          default: ({ value }) => {
            return h(
              SendingsCreateForm,
              {
                class: 'pl-3 pr-3',
                props: {
                  value,
                  types: this.sendingTypes,
                  fallback: true,
                  tags: catchSymbolsMenus.$,
                  customMenu: catchSymbolsMenus.custom.$
                },
                on: {
                  input: event => {
                    value = event
                  }
                }
              }
            )
          }
        }
      }
    )
  } else {
    return [
      h(
        SendingsCreateForm,
        {
          props: {
            value: this.proxy,
            types: this.sendingTypes,
            isTemplate: true,
            tags: catchSymbolsMenus.$,
            customMenu: catchSymbolsMenus.custom.$
          },
          on: {
            input: event => {
              this.proxy = event
            }
          }
        }
      ),

      h(
        'g-card-actions',
        {
          class: 'fjcfe facfe'
        },
        [
          h(
            'g-button',
            {
              attrs: { id: 'v-step-create-fallbacks' },
              props: {
                label: this.getTranslate('misc.buttons.fallback'),
                icon: 'add',
                rounded: true,
                outline: true,
                depressed: true,
                color: 'primary'
              },
              on: {
                click: () => {
                  this.setFallbacks()
                }
              }
            }
          )
        ]
      )
    ]
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: 'grid grid-cols--1 pa-3'
    },
    [
      h(
        'div',
        {
          class: 'faic'
        },
        [
          h(
            'g-text-field',
            {
              class: 'mr-3',
              props: {
                value: this.proxy.title,
                label: this.getTranslate(`${name}.labels.title`),
                error: this.errors.title,
                mode: 'outline',
                rounded: true,
                details: false,
                autofocus: true
              },
              on: {
                input: event => {
                  this.proxy.title = event
                },
                keypress: event => {
                  if (event.keyCode === 13) {
                    this.$emit('submit', this.proxy)
                  }
                }
              }
            }
          ),

          h(
            'g-switch',
            {
              props: { value: this.proxy.isActive },
              on: {
                input: event => {
                  this.proxy.isActive = event
                }
              }
            }
          )
        ]
      ),

      h(
        SendingsCreateRecipients,
        {
          props: {
            value: this.proxy.recipient
          },
          on: {
            input: event => {
              this.proxy.recipient = event
            }
          }
        }
      ),

      renderForm.call(this, h),

      h(
        OwnerSelect,
        {
          props: {
            value: this.proxy.OwnerId,
            mode: 'outline',
            rounded: true
          },
          on: {
            input: event => {
              this.proxy.OwnerId = event
            }
          }
        }
      )
    ]
  )
}
