import VueTinymce from '@tinymce/tinymce-vue'

const apiKey = '8095citdf75wbmxjw8mek1ipicoh8ab1e0jge5e1y8r4qbn1' // https://www.tiny.cloud/my-account/dashboard/
const toolbarButtons = [
  'bold',
  'italic',
  'underline',
  'strikethrough',
  '|',
  'link',
  'unlink',
  '|',
  'alignleft',
  'aligncenter',
  'alignright',
  'alignjustify',
  '|',
  'code',
  '|',
  'removeformat',
  '|',
  'insertpattern',
  '|',
  'undo',
  'redo'
]

export default function(h) {
  return h(
    VueTinymce,
    {
      props: {
        value: this.proxy,
        apiKey,
        init: {
          height: 300,
          menubar: false,
          plugins: 'link code',
          browser_spellcheck: true,
          toolbar: toolbarButtons.join(' '),
          setup: editor => {
            // https://www.tiny.cloud/docs/ui-components/typesoftoolbarbuttons/#menubutton
            editor.ui.registry.addMenuButton('insertpattern', {
              icon: 'paste',
              tooltip: 'Insert pattern',
              fetch: callback => {
                const items = this.patterns.map(pattern => {
                  return {
                    type: 'menuitem',
                    text: this.getTranslate(`settingsSite.patterns.${pattern}`),
                    onAction: () => {
                      editor.insertContent(`\$\{${pattern}\}`) // eslint-disable-line
                    }
                  }
                })
                callback(items)
              }
            })
          }
        }
      },
      on: {
        input: event => {
          this.proxy = event
        }
      }
    }
  )
}
