export const permissionPresets = {
  all: [ false, 'me', 'manager', 'reseller', true ],
  me: [ 'me', 'manager', 'reseller', true ],
  manager: [ 'manager', 'reseller', true ],
  reseller: [ 'reseller', true ],
  admin: [ true ]
}

export default {
  permissionPresets
}
