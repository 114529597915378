import SingleLink from '@/components/misc/SingleLink'
import messageType from '@/components/services/patterns/item/type'
import title from '@/components/services/patterns/item/title'
import moderation from '@/components/services/patterns/item/moderation'
import buttons from '@/components/services/patterns/item/buttons'

import { serviceName } from '@/components/services/patterns/utils'

function renderOwner(h) {
  if (this.checkPermissions(`advanced.${serviceName}.get`)) {
    return h(
      SingleLink,
      {
        class: 'small',
        props: {
          value: this.value.Owner || this.value.OwnerId
        }
      }
    )
  }
}

function renderTitle(h) {
  return h(title, { props: { value: this.value } })
}

function renderModeration(h) {
  return h(moderation, { props: { value: this.value } })
}

function renderDescription(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8 tiny text--grey',
      style: { 'grid-template-columns': 'repeat(2, minmax(min-content, max-content))' }
    },
    [
      renderModeration.call(this, h),
      renderOwner.call(this, h)
    ]
  )
}

export default function(h) {
  return h(
    'g-card',
    {
      class: 'hover-parent hover-parent--opacity pa-2',
      props: {
        outline: true,
        rounded: true,
        accent: true,
        accentColorName: this.value.isActive ? 'success' : 'error'
      }
    },
    [
      h(
        'div',
        {
          class: 'grid grid-gap--8 faic',
          style: { 'grid-template-columns': '36px 1fr auto' }
        },
        [
          h(messageType, { props: { value: this.value } }),

          h(
            'div',
            {
              class: 'grid grid-gap--2'
            },
            [
              renderTitle.call(this, h),
              renderDescription.call(this, h)
            ]
          ),

          h(buttons, { props: { value: this.value } })
        ]
      )
    ]
  )
}
