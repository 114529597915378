import { getRUB } from '@/utils'

export default function(h) {
  if (this.billingsAmount !== undefined) {
    return h(
      'div',
      {
        class: {
          [this.priceColor]: true,
          'text--line-through': this.refunded
        },
        directives: this.directives
      },
      [ getRUB(this.billingsAmount) ]
    )
  }

  return h(
    'div',
    {
      class: 'fjcc faic ff'
    },
    [
      h(
        'g-progress',
        {
          props: {
            indeterminate: true,
            color: 'grey',
            size: 16,
            width: 1
          }
        }
      )
    ]
  )
}
