import { SENDING_STATUS } from '@sigma-legacy-libs/essentials/lib/constants'

import HintRow from '@/components/misc/HintRow'
import ExpandArrow from '@/components/misc/ExpandArrow'

export default function(h) {
  return h(
    'g-expansion-panels',
    {
      props: {
        outline: true,
        rounded: true
      }
    },
    [
      h(
        'g-expansion-panel',
        {
          scopedSlots: {
            header: ({ expanded }) => {
              return h(
                'div',
                {
                  class: 'default-expansion-panel-header fjcsb pr-0'
                },
                [
                  this.getTranslate('sendings.subheaders.options'),

                  h(ExpandArrow, { props: { expanded } })
                ]
              )
            }
          }
        },
        [
          h(
            'div',
            {
              class: 'grid grid-gap--8 pb-2'
            },
            [
              h(
                HintRow,
                {
                  props: {
                    value: this.getTranslate('sendings.subheaders.resend'),
                    icon: 'done'
                  }
                }
              ),

              h(
                'div',
                {
                  class: 'px-2'
                },
                [
                  h(
                    'g-select',
                    {
                      props: {
                        value: this.proxy.onStatus,
                        label: this.getTranslate('sendings.labels.status'),
                        hint: this.getTranslate('sendings.labels.options.status'),
                        disabled: this.disabled,
                        readonly: this.readonly,
                        items: SENDING_STATUS.filter(item => item !== SENDING_STATUS.paused).map(item => ({
                          title: this.getTranslate(`sendings.statuses.${item}`),
                          value: item
                        })),
                        mode: 'outline',
                        rounded: true,
                        multiple: true
                      },
                      on: {
                        input: event => {
                          this.proxy.onStatus = event
                        }
                      }
                    }
                  )
                ]
              ),

              h(
                HintRow,
                {
                  props: {
                    value: this.getTranslate('misc.or'),
                    icon: 'arrow_drop_down'
                  }
                }
              ),

              h(
                'div',
                {
                  class: 'grid grid-gap--8 px-2',
                  style: {
                    'grid-template-columns': this.viewport.breakpoint.mdUp ? '25% 1fr' : '1fr'
                  }
                },
                [
                  h(
                    'g-text-field',
                    {
                      props: {
                        value: this.proxy.onTimeout.timeout,
                        label: this.getTranslate('sendings.labels.time'),
                        hint: this.getTranslate('sendings.labels.options.timeout'),
                        suffix: this.getTranslate('misc.measure.time.sec').toLowerCase(),
                        disabled: this.disabled,
                        readonly: this.readonly,
                        mode: 'outline',
                        rounded: true,
                        type: 'number',
                        min: 0,
                        step: 'any'
                      },
                      on: {
                        input: event => {
                          this.proxy.onTimeout.timeout = event
                        }
                      }
                    }
                  ),

                  h(
                    'g-select',
                    {
                      props: {
                        value: this.proxy.onTimeout.except,
                        label: this.getTranslate('sendings.labels.status'),
                        hint: this.getTranslate('sendings.labels.options.except'),
                        items: SENDING_STATUS.filter(item => item !== SENDING_STATUS.paused).map(item => ({
                          title: this.getTranslate(`sendings.statuses.${item}`),
                          value: item
                        })),
                        disabled: this.disabled,
                        readonly: this.readonly,
                        mode: 'outline',
                        rounded: true,
                        multiple: true
                      },
                      on: {
                        input: event => {
                          this.proxy.onTimeout.except = event
                        }
                      }
                    }
                  )
                ]
              ),

              h(
                HintRow,
                {
                  props: {
                    value: this.getTranslate('misc.or'),
                    icon: 'arrow_drop_down'
                  }
                }
              ),

              h(
                'div',
                {
                  class: 'px-2'
                },
                [
                  h(
                    'g-text-field',
                    {
                      props: {
                        value: this.proxy.onIncomingPayload.text,
                        label: this.getTranslate('sendings.labels.text'),
                        hint: this.getTranslate('sendings.labels.options.onIncomingPayload.text'),
                        mode: 'outline',
                        rounded: true,
                        disabled: this.disabled,
                        readonly: this.readonly
                      },
                      on: {
                        input: event => {
                          this.proxy.onIncomingPayload.text = event
                        }
                      }
                    }
                  )
                ]
              )
            ]
          )
        ]
      )
    ]
  )
}
