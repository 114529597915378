import { MODERATION_VALUES } from '@sigma-legacy-libs/essentials/lib/constants'

import { cleanUserForSendingsFilterByOwner, permissionPresets } from '@/utils'

import ExpandArrow from '@/components/misc/ExpandArrow'
import RowWithMessageTypeIcon from '@/components/misc/RowWithMessageTypeIcon'
import ModerationStatusBarWithIcon from '@/components/misc/ModerationStatusBarWithIcon'
import OwnerSelect from '@/components/misc/OwnerSelect'
import requisites from '@/components/services/sendernames/form/requisites'
import subscription from '@/components/services/sendernames/form/subscription'

import { serviceName } from '@/components/services/sendernames/utils'

const _ = { get: require('lodash/get') }

function renderIsActiveField(h) {
  if (this.checkPermissions(`advanced.${serviceName}.update`)) {
    return h(
      'g-switch',
      {
        props: {
          value: this.proxy.isActive
        },
        on: {
          input: event => {
            this.proxy.isActive = event
          }
        },
        directives: [
          {
            name: 'g-tooltip',
            options: {
              value: this.getTranslate(`${serviceName}.labels.isActive`)
            }
          }
        ]
      }
    )
  }
}

function renderFieldName(h) {
  const columns = [ '1fr' ]
  if (this.checkPermissions(`advanced.${serviceName}.update`)) {
    columns.push('36px')
  }

  return h(
    'div',
    {
      class: 'grid grid-gap--8 faic',
      style: { 'grid-template-columns': columns.join(' ') }
    },
    [
      h(
        'g-text-field',
        {
          props: {
            value: this.proxy.name,
            label: this.getTranslate(`${serviceName}.labels.name`),
            error: this.proxy.name && !this.senderNameIsValid ? this.getTranslate(`${serviceName}.errors.${this.proxy.type}`) : this.errors.name,
            mode: 'outline-label',
            dense: true,
            rounded: true,
            required: true,
            autofocus: true,
            validateOnBlur: true
          },
          on: {
            input: event => {
              this.proxy.name = event
            }
          }
        }
      ),

      renderIsActiveField.call(this, h)
    ]
  )
}

function renderFieldModeration(h) {
  if (this.checkPermissions(`advanced.${serviceName}.update`, permissionPresets.reseller)) {
    return h(
      'g-select',
      {
        props: {
          value: this.proxy.moderation,
          label: this.getTranslate(`${serviceName}.labels.moderation`),
          items: MODERATION_VALUES.map(moderation => {
            return {
              title: this.getTranslate(`misc.moderation.${moderation}`),
              value: moderation
            }
          }),
          error: this.errors.moderation,
          mode: 'outline',
          dense: true,
          rounded: true,
          required: true,
          details: false
        },
        on: {
          input: event => {
            this.proxy.moderation = event
          }
        },
        scopedSlots: {
          selection: ({ item }) => {
            return h(
              ModerationStatusBarWithIcon,
              {
                props: {
                  type: item.value,
                  size: 20,
                  title: item.title
                }
              }
            )
          },
          item: ({ item }) => {
            return h(
              ModerationStatusBarWithIcon,
              {
                props: {
                  type: item.value,
                  size: 20,
                  title: item.title
                }
              }
            )
          }
        }
      }
    )
  }
}

function renderFieldType(h) {
  return h(
    'g-select',
    {
      props: {
        value: this.proxy.type,
        label: this.getTranslate(`${serviceName}.labels.type`),
        items: this.getSendingTypesByPermission(`advanced.${serviceName}.create`).map(item => ({
          title: this.getTranslate(item.title),
          value: item.value
        })),
        error: this.errors.type,
        mode: 'outline',
        dense: true,
        rounded: true,
        required: true,
        details: false
      },
      on: {
        input: event => {
          this.proxy.type = event
        }
      },
      scopedSlots: {
        selection: ({ item }) => {
          return h(
            RowWithMessageTypeIcon,
            {
              props: {
                value: item.value,
                title: item.title
              }
            }
          )
        },
        item: ({ item }) => {
          return h(
            RowWithMessageTypeIcon,
            {
              props: {
                value: item.value,
                title: item.title
              }
            }
          )
        }
      }
    }
  )
}

function renderFieldComment(h) {
  return h(
    'g-textarea',
    {
      props: {
        value: this.proxy.meta.comment,
        label: this.getTranslate(`${serviceName}.labels.meta.comment`),
        disabled: !this.checkPermissions(`advanced.${serviceName}.create`, [ 'me', 'reseller' ]),
        mode: 'outline',
        grow: true,
        dense: true,
        rounded: true,
        details: false
      },
      on: {
        input: event => {
          this.proxy.meta.comment = event
        }
      }
    }
  )
}

function renderWarning(h) {
  if (this.checkPermissions(`advanced.${serviceName}.create`, [ 'me', 'reseller' ])) {
    return h(
      'div',
      {
        class: 'grid grid-gap--8 faic border border--dashed border--rounded pa-2',
        style: { 'grid-template-columns': '24px 1fr' }
      },
      [
        h(
          'g-icon',
          {
            props: {
              value: 'warning_amber',
              color: 'warning'
            }
          }
        ),

        h(
          'div',
          {
            class: 'caption'
          },
          [ this.getTranslate(`${serviceName}.hints.comment`) ]
        )
      ]
    )
  }
}

function renderFieldOwner(h) {
  return h(
    OwnerSelect,
    {
      props: {
        value: this.proxy.OwnerId,
        clearItems: cleanUserForSendingsFilterByOwner,
        mode: 'outline',
        dense: true,
        rounded: true,
        details: false
      },
      on: {
        input: event => {
          this.proxy.OwnerId = event
        }
      }
    }
  )
}

function renderAdvancedComment(h) {
  switch (true) {
    case this.checkPermissions(`advanced.${serviceName}.create`, 'reseller'): {
      return h(
        'div',
        {
          class: 'grid grid-gap--8'
        },
        [
          h(
            'g-textarea',
            {
              props: {
                value: this.proxy.meta.resellerComment,
                label: this.getTranslate(`${serviceName}.labels.meta.resellerComment`),
                mode: 'outline',
                rounded: true,
                grow: true,
                details: false
              },
              on: {
                input: event => {
                  this.proxy.meta.resellerComment = event
                }
              }
            }
          ),

          h(
            'g-textarea',
            {
              props: {
                value: this.proxy.meta.resellerCommentPrivate,
                label: this.getTranslate(`${serviceName}.labels.meta.resellerCommentPrivate`),
                mode: 'outline',
                rounded: true,
                grow: true,
                details: false
              },
              on: {
                input: event => {
                  this.proxy.meta.resellerCommentPrivate = event
                }
              }
            }
          )
        ]
      )
    }
    case this.checkPermissions(`advanced.${serviceName}.create`): {
      return h(
        'div',
        {
          class: 'grid grid-gap--8'
        },
        [
          h(
            'g-textarea',
            {
              props: {
                value: this.proxy.meta.adminComment,
                label: this.getTranslate(`${serviceName}.labels.meta.adminComment`),
                mode: 'outline',
                rounded: true,
                grow: true,
                details: false
              },
              on: {
                input: event => {
                  this.proxy.meta.adminComment = event
                }
              }
            }
          ),

          h(
            'g-textarea',
            {
              props: {
                value: this.proxy.meta.adminCommentPrivate,
                label: this.getTranslate(`${serviceName}.labels.meta.adminCommentPrivate`),
                mode: 'outline',
                rounded: true,
                grow: true,
                details: false
              },
              on: {
                input: event => {
                  this.proxy.meta.adminCommentPrivate = event
                }
              }
            }
          )
        ]
      )
    }
  }
}

function renderAdvanced(h) {
  if (this.checkPermissions(`advanced.${serviceName}.create`, permissionPresets.reseller)) {
    return h(
      'g-expansion-panels',
      {
        props: {
          outline: true,
          rounded: true
        }
      },
      [
        h(
          'g-expansion-panel',
          {
            scopedSlots: {
              header: ({ expanded }) => {
                return h(
                  'div',
                  {
                    class: 'default-expansion-panel-header pr-0'
                  },
                  [
                    h('div', {}, this.getTranslate('misc.advanced')),
                    h('div', { class: 'ff' }),
                    h(ExpandArrow, { props: { expanded } })
                  ]
                )
              },
              default: () => {
                return h(
                  'div',
                  { class: 'grid grid-gap--8 pa-2' },
                  [
                    renderFieldOwner.call(this, h),
                    renderAdvancedComment.call(this, h)
                  ]
                )
              }
            }
          }
        )
      ]
    )
  }
}

function renderRequisites(h) {
  if (this.checkPermissions('advanced.requisites.get', permissionPresets.reseller)) {
    return h(
      requisites,
      {
        props: {
          OwnerId: this.proxy.Owner && this.proxy.Owner.id || this.proxy.OwnerId,
          foreign: this.proxy.$foreign
        }
      }
    )
  }
}

function renderComment(h, comment, { value, color }) {
  return h(
    'div',
    {
      class: 'faic grid grid-gap--8 border border--dashed border--rounded pa-2',
      style: { 'grid-template-columns': '24px 1fr' }
    },
    [
      h(
        'g-icon',
        {
          props: {
            value,
            color
          }
        }
      ),

      h(
        'div',
        {
          class: `caption text--${color} word-break`
        },
        comment
      )
    ]
  )
}

function renderCommentFromAdmin(h) {
  if (this.checkPermissions(`advanced.${serviceName}.get`, [ 'me', false ])) {
    const comment = _.get(this.value, 'meta.adminComment') || _.get(this.value, 'meta.resellerComment')
    if (comment) {
      return renderComment.call(this, h, comment, {
        value: 'error_outline',
        color: 'error'
      })
    }
  }
}

function renderSubscription(h) {
  if (this.checkPermissions(`advanced.${serviceName}.get`, permissionPresets.admin)) {
    return h(subscription, { props: { value: this.value } })
  }
}

export default function(h) {
  return h(
    'g-form',
    {
      class: 'grid grid-gap--8',
      on: {
        submit: () => {
          this.$emit('submit', this.proxy)
        }
      }
    },
    [
      renderFieldName.call(this, h),
      renderFieldModeration.call(this, h),
      renderFieldType.call(this, h),
      renderFieldComment.call(this, h),
      renderWarning.call(this, h),
      renderAdvanced.call(this, h),
      renderRequisites.call(this, h),
      renderCommentFromAdmin.call(this, h),
      renderSubscription.call(this, h)
    ]
  )
}
