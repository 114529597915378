function renderCount(h) {
  if (this.isMany) {
    return h(
      'div',
      {
        class: 'tree__count ml-1',
        directives: [
          {
            name: 'g-tooltip',
            options: {
              value: this.value.reduce((result, item, index) => {
                if (index < 20) {
                  result.push(item)
                }

                return result
              }, []).join('\n')
            }
          }
        ]
      },
      `+${this.value.length - this.count}`
    )
  }
}

function renderCutValue(h, value) {
  if (typeof value === 'string') {
    if (value.length > 20) {
      return h(
        'span',
        {
          directives: [
            {
              name: 'g-tooltip',
              options: { value }
            }
          ]
        },
        value.slice(0, 20) + '...'
      )
    }

    return value
  }
}

function renderValue(h, value) {
  if (value) {
    return renderCutValue.call(this, h, value)
  }
}

function renderValues(h) {
  return this.value.reduce((result, item, index) => {
    if (index > 0 && index < this.count) {
      result.push(h('span', { class: 'mr-1' }, ','))
    }
    if (index < this.count) {
      result.push(renderValue.call(this, h, item))
    }

    return result
  }, [])
}

export default function(h) {
  return h(
    'div',
    {
      class: {
        faic: true,
        link: this.isMany
      },
      on: {
        click: () => {
          if (this.isMany) {
            this.$emit('click', true)
          }
        }
      }
    },
    [
      renderValues.call(this, h),
      renderCount.call(this, h)
    ]
  )
}
