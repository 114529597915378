import proxy from '@sigma-legacy-libs/g-proxy'

import { SENDING_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import { generateServices } from '@/utils'

import render from './render'

const _ = { defaultsDeep: require('lodash/defaultsDeep') }

const testPayload = {
  [SENDING_TYPES.sms]: {
    sender: 'B-Media',
    text: 'Это тестовое сообщение.\nИзменённый текст будет отправлен на модерацию.'
  },
  [SENDING_TYPES.vk]: {
    sender: 'sigmasmsru',
    text: 'Тестовое сервисное сообщение ВКонтакте от компании SigmaSMS'
  },
  [SENDING_TYPES.viber]: {
    sender: 'MediaGorod',
    text: 'Это тестовое сообщение.\nИзменённый текст будет отправлен на модерацию.',
    tts: 'yandex:filipp'
  },
  [SENDING_TYPES.whatsapp]: {
    sender: '79052108555',
    text: 'Здравствуйте, уважаемый пользователь. \nСпасибо, что обратились в нашу компанию. \nВы можете продолжить диалог, написав нам в ответ.'
  },
  [SENDING_TYPES.voice]: {
    sender: '74956665610',
    text: 'Это тестовое сообщение.\nИзменённый текст будет отправлен на модерацию.'
  }
}

export default {
  name: 'SendingsCreateForm',

  mixins: [
    proxy({ type: 'object' }),

    generateServices({
      name: 'sendernames',

      find: false,
      get: false,
      remove: false,
      update: false,
      create: {
        params: {
          query: {
            $scope: [ 'full' ]
          }
        }
      }
    })
  ],

  props: {
    errors: {
      type: Object,
      default: () => ({})
    },

    types: {
      type: Array,
      default: () => []
    },
    tags: {
      type: Array,
      default: () => []
    },
    customMenu: {
      type: Array,
      default: () => []
    },

    disabled: Boolean,
    readonly: Boolean,
    fallback: Boolean,
    isPattern: Boolean,
    isNewMessage: Boolean,

    template: String
  },

  data() {
    return {
      showCreateNewSenderName: false,
      showIllegalRoutingWarning: false,

      isGeneratedFile: true,

      localTypes: []
    }
  },

  computed: {
    checkTestRoute() {
      let result = false

      if (this.isNewMessage && !this.isPattern && !this.template) {
        switch (this.proxy.type) {
          case SENDING_TYPES.sms: {
            if (this.checkSomeId('RoutingGroups', '9ba7f6e8-7b0b-462d-ba1d-033455c383b0')) {
              result = true
            }
            break
          }

          case SENDING_TYPES.vk: {
            if (this.checkSomeId('Patterns', 'b34498d6-79e5-4c7e-913a-4658390dd5e9')) {
              result = true
            }
            break
          }

          case SENDING_TYPES.viber: {
            if (this.checkSomeId('Patterns', '95d61562-0b92-45ad-a92c-6e9da7dc69a1')) {
              result = true
            }
            break
          }

          case SENDING_TYPES.whatsapp: {
            if (this.checkSomeId('Patterns', 'a60e9979-9b66-4d66-ba8e-fa0e1aa1c8b5')) {
              result = true
            }
            break
          }

          case SENDING_TYPES.voice: {
            if (this.checkSomeId('Patterns', '6e49597a-fe4a-4a63-8c85-43cbe9bd10b4')) {
              result = true
            }
            break
          }
        }
      }
      if (result) {
        this.showIllegalRoutingWarning = true
      } else {
        this.showIllegalRoutingWarning = false
      }

      return result
    },

    validationButtonUrl() {
      if (this.proxy.payload.button.url && this.proxy.payload.button.url.length > 2048) {
        return this.getTranslate('sendings.errors.button.url')
      }
    },
    validationButtonText() {
      if (this.proxy.payload.button.text && this.proxy.payload.button.text.length > 30) {
        return this.getTranslate('sendings.errors.button.text')
      }
    }
  },

  watch: {
    proxy: {
      handler() {
        if (!this.proxy.type) {
          this.proxy.type = this.types[0].value
        }
      },
      deep: true
    },
    'proxy.type'() {
      setTimeout(() => {
        if (this.checkTestRoute) {
          this.proxy.payload.sender = testPayload[this.proxy.type].sender
          this.proxy.payload.text = testPayload[this.proxy.type].text

          const tts = testPayload[this.proxy.type].tts
          if (tts) {
            this.proxy.payload.tts = tts
          }
        } else {
          this.proxy.payload.sender = undefined
          this.proxy.payload.text = undefined
          this.proxy.payload.tts = undefined
        }
      }, 250)
    }
  },

  mounted() {
    const types = this.types.reduce((result, type) => {
      result.push({
        title: type.title,
        value: type.value
      })

      return result
    }, [])

    const currentType = this.proxy.type
    if (currentType && !~types.findIndex(({ value }) => value === currentType)) {
      types.push({
        title: `sendings.types.${currentType}`,
        value: currentType,
        disabled: true
      })
    }

    this.localTypes = types
  },

  methods: {
    _inputFilter(data) {
      if (this.fallback) {
        _.defaultsDeep(data, {
          $options: {
            onTimeout: {
              except: [],
              timeout: undefined
            },
            onStatus: [],
            onIncomingPayload: { text: undefined }
          }
        })
      }

      return data
    },

    checkSomeId(association, value) {
      return Array.isArray(this.account[association]) && this.account[association].length > 0 && this.account[association].some(({ id }) => id === value)
    }
  },

  render
}
