const presets = {
  labels: {
    queue: 'Queue size',
    speed: 'Sending rate',
    count: '5 minute total',
    deliveryRate: 'Delivery percentage'
  },
  hints: {
    queue: 'Amount of messages in provider\'s queue',
    speed: 'Rate is amount of messages sent per second in the last minute',
    count: 'Amount of messages being sent to provider over last 5 minutes',
    deliveryRate: 'Ratio of delivered to sent messages over the last 5 minutes'
  },
  measure: {
    queue: 'message',
    speed: 'message/sec',
    count: 'message',
    deliveryRate: '%'
  }
}

export default {
  title: 'Providers',

  provider: 'Provider',
  providers: 'Providers',
  one: 'Provider',
  many: 'Providers',

  titles: {
    create: 'Create provider',
    update: 'Update provider',
    remove: 'Remove provider',
    properties: {
      add: 'Add properties',
      edit: 'Edit properties',
      notFound: 'Properties not specified'
    },
    replacements: {
      add: 'Add replacement',
      edit: 'Edit replacement',
      notFound: 'Replacements not specified'
    }
  },

  labels: {
    title: 'Title',
    type: 'Type',
    ownProcessor: 'Own processor',
    settings: {
      protocol: 'Protocol',
      processor: 'Processor',
      credentials: {
        username: 'Username',
        password: 'Password'
      },
      server: {
        host: 'Host',
        port: 'Port'
      },
      limits: {
        bulk: 'Bulk',
        concurrency: 'Concurrency',
        rpm: 'RPM'
      }
    },
    search: 'Search',
    server: 'Server',
    credentials: 'Credentials',
    comment: 'Comment',
    version: 'Version',
    text: 'Text',
    sender: 'Sender',
    sendername: 'Sendername',
    d360: {
      owner: 'Owner',
      token: 'Token'
    }
  },

  contents: {
    confirm: {
      remove: 'Confirm remove provider.'
    }
  },

  dialogs: {
    new: 'New provider'
  },

  tabs: {
    send: 'Send',
    check: 'Check',
    ping: 'Ping',
    rates: 'Rates',
    logs: 'Logs'
  },

  subheaders: {
    general: 'General',
    settings: 'Settings',
    credentials: 'Credentials',
    server: 'Server',
    options: 'Options',
    limits: 'Limits'
  },

  chart: { labels: presets.labels },

  states: {
    inactive: 'Inactive',
    connecting: 'Connecting',
    authorizing: 'Authorizing',
    disconnecting: 'Disconnecting',
    active: 'Active',
    stopped: 'Stopped',
    'half-open': 'Temporarily active'
  },

  buttons: {
    edit: 'Edit',
    remove: 'Remove',
    add: {
      property: 'Property',
      replacement: 'Replacement'
    }
  },

  tooltips: {
    liveRefresh: 'Turn on or turn off live refresh analytics.',
    isActive: 'Activate or deactivate a provider.',
    incoming: 'Incoming',
    outgoing: 'Outgoing',
    logs: {
      online: 'Request logs from the server every 15 seconds'
    },
    presets: {
      queue: `${presets.labels.queue}: {count} ${presets.measure.queue}\n\n${presets.hints.queue}`,
      speed: `${presets.labels.speed}: {count} ${presets.measure.speed}\n\n${presets.hints.speed}`,
      count: `${presets.labels.count}: {count} ${presets.measure.count}\n\n${presets.hints.count}`,
      deliveryRate: `${presets.labels.deliveryRate}: {count} ${presets.measure.deliveryRate}\n\n${presets.hints.deliveryRate}`
    },
    favorites: 'Make the provider a favorite'
  },

  measure: {
    presets: presets.measure
  },

  hints: {
    notFound: 'Providers not found.',
    other: 'Other values',
    rules: 'Rules',
    replacement: 'Data for replacements content',
    replacements: {
      hint: 'Replacements content',

      notFound: 'Add replacements by clicking the button below.',

      rules: 'Rules',
      replacement: 'Replacement'
    },
    properties: {
      hint: 'Properties',

      notFound: 'Add properties by clicking the button below.',

      defaultWebhookId: 'ID for the default webhook',
      customWebhookId: 'Custom Webhook ID',
      messagePartIdPrefix: 'Custom prefix for message part ID',

      keepAlive: 'Keep the connection open',

      noSeenStatus: '[FakeHTTP] Whether to use the seen status in the provider',
      failurePercent: '[FakeHTTP] Percentage of Failed Statuses',

      chatPultIntegration: '[ChatPult] Passed to the request body when creating a message',

      tts: '[AWSAST | Telerobot] A type of synthesizer for transforming text to speech',
      trunk: '[AWSAST] 531544, quantek, trunk_84992774787',
      prefix: '[AWSAST] quantek - 392500',
      trycount: '[AWSAST] The default value is 0, one attempt is made, if you specify 1, the api will perceive it as one additional attempt besides the standard one. Those. there are 2 dialing attempts in total.',
      trydelay: '[AWSAST] What is the delay between dialing attempts in minutes',
      length: '[AWSAST] Number of random characters from the end',
      record_timeout: '[AWSAST] Duration of recording the response, 0 if you want to stop recording. Undefined - default value of the asterisk (45s)',
      wait_timeout: '[AWSAST] Delay before playing the file',
      dtmf_timeout: '[AWSAST] How many seconds to wait for a response from the client',

      menu: '[Telerobot] 0 / 1 - Enables the recording of button presses by the subscriber',
      asr: '[Telerobot] 0 / 1 - Recognition of the user\'s voice response into text',

      resendSms: 'Resend as SMS in case of non-delivery',
      priority: '[ShkolainfoPush] Priority',
      validityPeriodSec: 'Message lifetime',

      rus: '[SmartDelivery] By default 1',
      traffic_type: '[SmartDelivery] Traffic type, default "promotion"',

      proxy: 'Proxy settings',

      accountId: '[Tyntec] Tyntec account ID',

      routes: '[VKMailru] vk,ok / vk / ok',
      delivery_policy: '[VKMailru] Delivery settings. mobile_device_required or nothing',
      ttl: '[VKMailru] Message lifetime',

      dlr: '[Messaggio] 0 / 1 - Return message status (default 1)',
      dlr_timeout: '[Messaggio]',
      expiry_txt: '[Messaggio]',

      system_type: '[SMPP] Passed to bind_ commands',
      preferredDataCodings: '[SMPP] Set preferred encodings (\'1:3,8\')',
      registeredDelivery: '[SMPP] Sets the way to get the message status (query_sm/deliver_sm)',
      interface_version: '[SMPP] Sets the interface_version parameter when connecting via SMPP (3,5)',
      tls: '[SMPP] Flag for connecting to SMPP over TLS',
      enquireLinkTimeout: '[SMPP] Timeout for starting enquireLink command',

      source_addr_ton: '[XSMPP] Submitted to submit_sm command',
      source_addr_npi: '[XSMPP] Submitted to submit_sm command',
      dest_addr_ton: '[XSMPP] Submitted to submit_sm command',
      dest_addr_npi: '[XSMPP] Submitted to submit_sm command',

      senderName: 'Sender name for replacing',

      _testType: 'Test submission type',
      _testSendername: 'Test sender name',
      _testRecipient: 'Test Recipient',
      _testPayloadText: 'Test text',

      rateLimit_concurrency: 'Number of concurrent requests',
      rateLimit_messagesPerSecond: 'Messages per second'
    },
    presets: presets.hints
  },

  snackbars: {
    created: 'Provider successfully created.',
    updated: 'Provider successfully updated.',
    removed: 'Provider successfully removed.'
  }
}
