export default {
  title: 'Группы правил',

  one: 'Группа',
  many: 'Группы',

  titles: {
    create: 'Создать группу правил обработки сообщений'
  },

  labels: {
    title: 'Название',
    search: 'Поиск',
    rule: 'Правило',
    group: 'Группа'
  },

  dialogs: {
    new: 'Новая группа правил обработки сообщений',
    types: {
      full: 'Полная',
      direct: 'Прямая'
    },
    title: {
      full: 'Полная группа',
      direct: 'Прямая группа'
    }
  },

  hints: {
    notFound: 'Группы правил обработки сообщений не найдены'
  },

  contents: {
    confirm: {
      remove: 'Подтвердите удаление {title}?'
    }
  },

  tooltips: {
    isActive: 'Активировать или деактивировать группу правил обработки сообщений.',
    favorites: 'Добавить в избранное группу правил обработки сообщений.'
  },

  subtitles: {
    rules: 'Правила',
    groups: 'Правила обработки сообщений'
  },

  buttons: {
    add: 'Добавить группу правил',
    messageRuleCreate: 'Создать правило'
  },

  snackbars: {
    created: 'Группа правил обработки сообщений успешно создана.',
    updated: 'Группа правил обработки сообщений успешно обновлена.',
    removed: 'Группа правил обработки сообщений успешно удалена.'
  }
}
