import { serviceTemplate } from '@/utils'

import { inputFilter, outputFilter, sourceColors } from '@/components/services/tariffs/utils'
import { serviceName } from '@/components/services/tariffs/utils'

export default {
  mixins: [
    serviceTemplate({
      serviceName,

      inputFilter,
      outputFilter,

      find: {
        defaultFilter: {
          isActive: true,
          type: undefined,
          source: undefined
        }
      },
      create: { redirect: true },
      get: {
        params: {
          query: {
            $scope: [ 'Owner' ]
          }
        }
      },
      update: {
        params: {
          query: {
            $scope: [ 'Owner' ]
          }
        }
      },
      remove: {
        params: {
          query: {
            $scope: [ 'Owner' ]
          }
        },
        redirect: true
      },

      form: {
        accent: true,
        accentPosition: 'top',
        accentColorNameSelector: ({ source }) => sourceColors[source] || 'grey'
      },

      width: 600,

      dialog: {
        create: {
          minHeight: 600
        }
      }
    })
  ]
}
