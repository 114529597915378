export default function(h) {
  return h(
    'g-button',
    {
      class: 'my-0',
      props: {
        flat: true,
        icon: this.isFavorite ? 'favorite' : 'favorite_outline',
        color: this.isFavorite ? 'error' : undefined
      },
      directives: [
        {
          name: 'g-tooltip',
          options: {
            value: this.getTranslate(`${this.serviceName}.tooltips.favorites`),
            placement: 'top'
          }
        }
      ],
      on: {
        click: async () => {
          await this.toggle({
            serviceName: this.serviceName,
            id: this.id
          })
        }
      }
    }
  )
}
