import { unVue } from '@sigma-legacy-libs/unvue'

import { TASK_STATES, TASK_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import TasksTemplate from '@/components/services/tasks/template'

import { TASKS_FILTER, getChosenMonth, getToday, tableHeaderParser } from '@/utils'

const tasksDefaultFilter = Object.assign({}, unVue(TASKS_FILTER), {
  type: [ TASK_TYPES['payments:recurrent'], TASK_TYPES['billings:operations'] ]
})

import render from './render'

export default {
  name: 'Tasks',

  components: {
    'tasks-template': TasksTemplate({
      defaultFilter: tasksDefaultFilter,
      presets: [
        {
          title: 'today',
          filter: Object.assign({}, tasksDefaultFilter, {
            state: undefined,
            createdAt: getToday()
          })
        },
        {
          title: 'twoWeeks',
          filter: Object.assign({}, tasksDefaultFilter, {
            state: TASK_STATES.scheduled,
            createdAt: getChosenMonth(-1)
          })
        }
      ]
    })
  },

  computed: {
    headers() {
      return tableHeaderParser.call(this, [
        {
          value: 'title',
          align: 'left',
          class: 'pl-2 pr-2'
        },
        {
          title: 'billings.headers.amount',
          value: 'amount',
          align: 'center',
          class: 'pl-2 pr-2'
        },
        {
          title: 'billings.headers.owner',
          value: 'owner',
          align: 'right',
          class: 'pl-2 pr-2',
          show: this.checkPermissions('advanced.users.get', [ 'reseller', true ])
        },
        {
          value: 'actions',
          class: 'pl-0 pr-0'
        },
        {
          title: 'misc.createdAt',
          value: 'createdAt',
          align: 'right',
          class: 'pl-2 pr-2',
          sortable: true
        },
        {
          title: 'misc.updatedAt',
          value: 'updatedAt',
          align: 'right',
          class: 'pl-2 pr-2',
          sortable: true
        }
      ])
    }
  },

  render
}
