export default {
  title: 'Sender names',

  tabs: {
    sendernames: 'Sendernames',
    patterns: 'Patterns for moderation'
  },

  tour: {
    type: 'Тип сообщения.',
    moderation: 'Статус модераци одобрено или отклонено.',
    sendername: 'Отправитель рассылки, который будет отображаться у получателя.',
    comment: 'Комментарий для менеджера компании.',
    filter: {
      sendername: 'Фильтр по имени отправителя.',
      type: 'Фильтр по типу отправления.',
      moderation: 'Фильтр по статусу модерации.'
    },
    create: 'Кнопка создания нового имени отправителя.'
  },

  tooltips: {
    copy: {
      it: 'Copy',
      all: 'Copy to clipboard name and comment'
    }
  },

  snackbars: {
    copied: 'Copied'
  }
}
