import { classMerger, isStringNotEmpty } from '@/utils'

export default function(h, ctx) {
  if (isStringNotEmpty(ctx.props.value)) {
    if (ctx.props.value.length > ctx.props.length) {
      return h(
        'div',
        {
          class: ctx.data.class,
          style: ctx.data.style,
          directives: [
            {
              name: 'g-tooltip',
              options: {
                value: ctx.props.value,
                placement: ctx.props.direction
              }
            }
          ]
        },
        [
          h(
            'span',
            {
              class: 'text-overflow',
              slot: 'activator'
            },
            ctx.props.value.slice(0, ctx.props.length) + '...'
          )
        ]
      )
    } else {
      return h(
        'div',
        {
          class: classMerger('text-overflow', ctx.data.class),
          style: ctx.data.style,
          directives: ctx.data.directives
        },
        ctx.props.value
      )
    }
  } else if (ctx.children) {
    return h(
      'div',
      {
        class: classMerger('text-overflow', ctx.data.class),
        style: ctx.data.style,
        directives: ctx.data.directives
      },
      ctx.children
    )
  }
}
