import { serviceName } from '@/components/services/companies/utils'

export default function(h) {
  const { vat } = this.value

  return h(
    'div',
    {
      class: 'text-overflow'
    },
    [ this.getTranslate(`${serviceName}.vat.${vat}`) ]
  )
}
