import Vue from 'vue'

import { globalErrorHandler, globalErrorProcessor } from '@/utils'

import render from './render'

export default {
  name: 'Price',

  props: {
    operator: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      price: 0,
      loading: false
    }
  },

  watch: {
    operator() {
      this.getPrice()
    }
  },

  mounted() {
    this.getPrice()
  },

  methods: {
    async getPrice() {
      try {
        this.loading = true

        if (this.operator) {
          const { data } = await Vue.$GRequest.get('operatorPrices', this.operator)
          if (data) {
            this.price = data.price
          }
        }
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      } finally {
        this.loading = false
      }
    }
  },

  render
}
