import proxy from '@sigma-legacy-libs/g-proxy'

import render from './render'

export default {
  name: 'Form',

  mixins: [ proxy({ type: 'object' }) ],

  props: {
    errors: {
      type: Object,
      default: () => ({})
    },

    owner: String
  },

  data() {
    return {
      advanced: false,

      action: 'addFunds'
    }
  },

  computed: {
    computedSource() {
      return [
        {
          title: this.getTranslate('sendings.types.subtract'),
          value: 'subtract'
        },
        ...this.getSendingTypesByPermission('advanced.billings.update').map(item => ({
          title: this.getTranslate('sendings.sendings') + ' ' + this.getTranslate(item.title),
          value: 'sendings.' + item.value
        }))
      ]
    }
  },

  watch: {
    action() {
      this.setDefaults()
    }
  },

  mounted() {
    this.setDefaults()
  },

  methods: {
    setDefaults() {
      if (this.action === 'addFunds') {
        this.proxy.source = 'accrual'
      } else {
        this.proxy.source = 'subtract'
      }
    }
  },

  render
}
