import FileManager from '@/components/file/manager'

import { FILE_TYPES } from '@/utils'

function renderFileManager(h) {
  return h(
    FileManager,
    {
      props: {
        flat: true,
        readonly: true,
        embedded: true,
        show: this.showFileManager,
        types: FILE_TYPES.csv.extensions,
        isPublic: true
      },
      on: {
        show: event => {
          this.showFileManager = event
        },
        input: event => {
          this.import(event.id)

          this.showFileManager = false
        }
      }
    }
  )
}

function renderButton(h) {
  return h(
    'g-button',
    {
      props: {
        flat: true,
        icon: 'file_upload',
        color: 'success',
        loading: this.loading,
        disabled: this.loading || this.disabled
      },
      on: {
        click: () => {
          this.showFileManager = true
        }
      }
    }
  )
}

function renderContent(h) {
  return h(
    'div',
    {
      class: 'faic'
    },
    [
      renderFileManager.call(this, h),
      renderButton.call(this, h)
    ]
  )
}

export default function(h) {
  return renderContent.call(this, h)
}
