import proxy from '@sigma-legacy-libs/g-proxy'

import { prefix } from '@/utils'

import render from './render'

export default {
  name: 'ViewChanger',

  mixins: [ proxy() ],

  props: {
    serviceName: {
      type: String,
      required: true
    }
  },

  mounted() {
    this.set(window.localStorage.getItem(`${prefix}:view:${this.serviceName}`))
  },

  methods: {
    set(view) {
      if (view) {
        this.proxy = view
        window.localStorage.setItem(`${prefix}:view:${this.serviceName}`, view)
      }
    }
  },

  render
}
