import HeadTitle from '@/components/misc/HeadTitle'

export function renderSudoDataDialog(h) {
  return h(
    'g-dialog',
    {
      props: {
        rounded: true,
        value: this.sudoData.showDialog,
        maxWidth: this.viewport.breakpoint.mdUp ? '1000px' : '100%'
      },
      on: {
        input: event => {
          if (event === false) {
            this.sudoData.source = undefined
          }
          this.sudoData.showDialog = event
        }
      }
    },
    [
      h(
        HeadTitle, {
          props: { value: this.getTranslate('commons.titles.data') },
          slot: 'header'
        }
      ),

      h(
        'pre',
        {
          class: 'language-json ma-0 pa-2 w--100',
          style: { 'border-radius': 0 },
          domProps: { innerHTML: this.sudoData.source }
        }
      ),

      h(
        'g-card-actions',
        {
          class: 'fjcfe facfe',
          slot: 'footer'
        },
        [
          h(
            'g-button',
            {
              props: {
                label: this.getTranslate('misc.buttons.close'),
                flat: true,
                rounded: true
              },
              on: {
                click: () => {
                  this.sudoData.showDialog = false
                  this.sudoData.source = undefined
                }
              }
            }
          )
        ]
      )
    ]
  )
}
