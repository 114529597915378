import { unVue } from '@sigma-legacy-libs/unvue'

import { attach, isArrayNotEmpty, serviceTemplate } from '@/utils'

import { serviceName } from '@/components/services/routingRules/utils'

export default {
  mixins: [
    serviceTemplate({
      serviceName,

      inputFilter(result) {
        if (isArrayNotEmpty(result.ProviderGroups)) {
          result._ProviderGroups = unVue(result.ProviderGroups)
          result.ProviderGroups = result.ProviderGroups.map(item => ({
            id: item.id,
            percentage: item.percentage,
            $attach: true
          }))
        } else {
          result.ProviderGroups = []
        }

        return result
      },
      outputFilter(result) {
        if (isArrayNotEmpty(result.ProviderGroups)) {
          attach(result, 'ProviderGroups')
        } else {
          result.ProviderGroups = undefined
        }

        return result
      },

      width: 600
    })
  ]
}
