import Vue from 'vue'
import proxy from '@sigma-legacy-libs/g-proxy'

import { globalErrorHandler, globalErrorProcessor } from '@/utils'

import render from './render'

const _ = { get: require('lodash/get') }

export default {
  name: 'SendernameSelect',

  mixins: [ proxy() ],

  props: {
    errors: {
      type: Object,
      default: () => ({})
    },

    type: String,
    mode: String,
    label: String,

    disabled: Boolean,
    readonly: Boolean,
    rounded: Boolean,
    dense: Boolean,
    details: Boolean,
    forceAnySendername: Boolean
  },

  data() {
    return {
      loading: false
    }
  },

  computed: {
    computedAnySenderName() {
      if (this.forceAnySendername) {
        return true
      }

      const sendernamesCheck = _.get(this.globalSettings, 'sendings.sendernamesCheck')
      if (sendernamesCheck) {
        return sendernamesCheck.indexOf(this.type) === -1
      }

      return false
    }
  },

  methods: {
    async checkSender(items) {
      if (!this.computedAnySenderName && !items.find(item => item.name === this.proxy)) {
        try {
          this.loading = true

          const { data } = await Vue.$GRequest.find('sendernames', {
            query: {
              name: this.proxy,
              type: this.type,
              isActive: true,
              $scope: [ 'usable' ]
            }
          })

          if (data.total === 0) {
            this.proxy = undefined
          } else {
            this.proxy = data.data[0].name
          }
        } catch (error) {
          globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
        } finally {
          this.loading = false
        }
      }
    }
  },

  render
}
