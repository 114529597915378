export default function(h) {
  if (this.proxy && this.check) {
    return h(
      'div',
      {
        class: 'sendings-warning'
      },
      [
        h(
          'div',
          {
            class: 'sendings-warning__body'
          },
          [
            h(
              'g-icon',
              {
                props: {
                  value: 'error',
                  color: 'warning'
                }
              }
            ),

            h(
              'div',
              {
                class: 'sendings-warning__content'
              },
              [
                h('div', { class: 'sendings-warning__content-title' }, this.getTranslate('misc.attention')),

                h(
                  'div',
                  {
                    domProps: {
                      innerHTML: this.getTranslate('sendings.hints.warning')
                    }
                  }
                )
              ]
            )
          ]
        ),

        h(
          'div',
          {
            class: 'sendings-warning__footer'
          },
          [
            h(
              'g-button',
              {
                class: 'ma-0',
                props: {
                  label: this.getTranslate('misc.buttons.support'),
                  flat: true,
                  rounded: true,
                  color: 'primary'
                },
                on: {
                  click: () => {
                    this.$router.push({ name: 'support' })
                  }
                }
              }
            ),

            h(
              'g-button',
              {
                class: 'ma-0',
                props: {
                  label: this.getTranslate('misc.buttons.understand'),
                  flat: true,
                  rounded: true,
                  color: 'black'
                },
                on: {
                  click: () => {
                    this.proxy = false
                  }
                }
              }
            )
          ]
        )
      ]
    )
  }
}
