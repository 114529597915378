import render from './render'

const _ = { get: require('lodash/get') }

export default {
  name: 'Comments',

  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    commentFromAdminAndReseller() {
      return _.get(this.value, 'meta.adminComment') || _.get(this.value, 'meta.resellerComment')
    }
  },

  render
}
