import routingRulesItemTitle from '@/components/services/routingRules/item/title'

function renderTitle(h) {
  return h(
    routingRulesItemTitle,
    {
      props: {
        value: this.value,
        edit: true
      }
    }
  )
}

export default function(h) {
  return h(
    'g-card',
    {
      class: 'hover-parent hover-parent--opacity pa-2',
      props: {
        outline: true,
        rounded: true
      }
    },
    [ renderTitle.call(this, h) ]
  )
}
