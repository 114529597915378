import OwnerSelect from '@/components/misc/OwnerSelect'
import DateTimeFilter from '@/components/misc/DateTimeFilter'
import RowWithMessageTypeIcon from '@/components/misc/RowWithMessageTypeIcon'

function renderFirstColumn(h) {
  if (this.checkPermissions('advanced.users.find', [ 'reseller', true ])) {
    return h(
      OwnerSelect,
      {
        props: {
          value: this.proxy.OwnerId,
          mode: 'outline',
          dense: true,
          rounded: true,
          details: false
        },
        on: {
          input: event => {
            this.proxy.OwnerId = event
          }
        }
      }
    )
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: {
        'grid grid-gap--8 px-2': true,
        'grid-cols--4': this.viewport.breakpoint.lgUp,
        'grid-cols--3': this.viewport.breakpoint.md,
        'grid-cols--2': this.viewport.breakpoint.sm,
        'grid-cols--1': this.viewport.breakpoint.xs
      }
    },
    [
      renderFirstColumn.call(this, h),

      h(
        'g-select',
        {
          props: {
            value: this.proxy.type,
            label: this.getTranslate('sendings.filter.type'),
            items: this.getSendingTypesByPermission('advanced.sendingsIncoming.get').map(item => ({
              title: this.getTranslate(item.title),
              value: item.value
            })),
            multiple: true,
            clearable: true,
            mode: 'outline',
            dense: true,
            rounded: true,
            details: false
          },
          scopedSlots: {
            item: ({ item }) => {
              return h(RowWithMessageTypeIcon, {
                props: {
                  value: item.value,
                  title: item.title
                }
              })
            }
          },
          on: {
            input: event => {
              this.proxy.type = event
            }
          }
        }
      ),

      h(
        'g-text-field', {
          props: {
            value: this.proxy.from,
            label: this.getTranslate('sendings.filter.from'),
            clearable: true,
            mode: 'outline',
            dense: true,
            rounded: true,
            details: false
          },
          on: {
            input: event => {
              this.proxy.from = event
            }
          }
        }
      ),

      h(
        'div',
        {
          class: 'fc faic'
        },
        [
          h(
            DateTimeFilter,
            {
              props: {
                value: this.proxy.createdAt
              },
              on: {
                input: event => {
                  this.proxy.createdAt = event
                }
              }
            }
          ),

          h(
            'g-button',
            {
              props: {
                label: this.getTranslate('misc.filter.clear'),
                flat: true,
                rounded: true
              },
              on: {
                click: () => {
                  this.clearFilter()
                }
              }
            }
          )
        ]
      )
    ]
  )
}
