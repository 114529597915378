import proxy from '@sigma-legacy-libs/g-proxy'

import { unVue } from '@sigma-legacy-libs/unvue'

import render from './render'

const _ = { isEqual: require('lodash/isEqual') }

const defaultValue = {
  id: undefined,
  data: { rules: [] },
  priority: 0
}

export default {
  name: 'Generator',

  mixins: [ proxy({ type: 'array' }) ],

  mounted() {
    this.transmitValue()
  },

  methods: {
    add() {
      this.proxy.unshift(unVue(defaultValue))
    },
    remove(index) {
      this.proxy.splice(index, 1)
    },

    _inputFilter(data) {
      if (data.length === 0) {
        data.push(unVue(defaultValue))
      }

      return data
    },

    _outputFilter(data) {
      const result = unVue(data) || []

      return result.map((item, index) => {
        item.priority = index

        return item
      })
    },

    watchValueHandler() {
      if (!_.isEqual(this.proxy, this.value)) {
        this.receiveValue()
      }
    }
  },

  render
}
