import { unVue } from '@sigma-legacy-libs/unvue'
import { PUBLIC_FIELDS_USER } from '@sigma-legacy-libs/essentials/lib/constants'

import {
  SENDINGS_INCOMING_FILTER,
  appendFormattedDates,
  downloadable,
  fillDependence,
  generateServices,
  sudoData,
  tableHeaderParser
} from '@/utils'

import render from './render'

const name = 'sendingsIncoming'

export default {
  name: 'SendingsIncomingDetails',

  mixins: [
    generateServices({
      name,

      async inputFilter(result) {
        if (!result.Owner) {
          await fillDependence.call(this, result, {
            service: 'users',
            permissions: [ 'reseller', true ],
            pathToId: 'OwnerId',
            pathToObject: 'Owner',
            outputPath: 'Owner',
            picks: PUBLIC_FIELDS_USER
          })
        }

        result.OwnerId = result.Owner && result.Owner.id || result.OwnerId

        if (result && result.payload && result.payload.file) {
          await fillDependence.call(this, result, {
            service: 'storage',
            permissions: [ 'me', 'reseller', true ],
            pathToId: 'payload.file.id',
            pathToObject: 'File',
            outputPath: 'File',
            requestParams: {
              query: {
                return: 'meta'
              }
            }
          })
        }

        appendFormattedDates.call(this, result)

        return result
      },

      find: {
        bucketEnabled: true,
        defaultFilter: Object.assign({}, unVue(SENDINGS_INCOMING_FILTER), { $scope: [ 'full' ] }),
        disableWatcherFilter: true
      },
      get: false,
      update: false,
      create: false,
      remove: false
    }),

    downloadable({ permissionAccept: `advanced.${name}.find` }),

    sudoData
  ],

  data() {
    return {
      showFilter: false
    }
  },

  computed: {
    headers() {
      return tableHeaderParser.call(this, [
        {
          title: 'sendings.headers.type',
          value: 'type',
          align: 'left',
          class: 'pl-2 pr-2',
          sortable: true
        },
        {
          title: 'sendings.headers.from',
          value: 'from',
          align: 'left',
          class: 'pl-2 pr-2',
          sortable: true
        },
        {
          title: 'sendings.headers.text',
          value: 'text',
          align: 'left',
          class: 'pl-2 pr-2'
        },
        {
          title: 'sendings.headers.owner',
          value: 'owner',
          align: 'left',
          class: 'pl-2 pr-2',
          show: this.checkPermissions('advanced.users.get', [ 'reseller', true ])
        },
        {
          title: 'misc.createdAt',
          value: 'createdAt',
          align: 'right',
          class: 'pl-2 pr-2',
          sortable: true
        },
        {
          title: 'misc.updatedAt',
          value: 'updatedAt',
          align: 'right',
          class: 'pl-2 pr-2',
          sortable: true
        }
      ])
    }
  },

  render
}
