import { isBoolean, isString } from '@/utils'

export function renderPreLoader(h, state) {
  if (isString(state) && state === 'loading' || isBoolean(state) && state === true) {
    return h(
      'div',
      {
        class: 'fjcc facc ff pa-3'
      },
      [ h('g-progress', { props: { indeterminate: true } }) ]
    )
  }
}
