import Vue from 'vue'
import proxy from '@sigma-legacy-libs/g-proxy'

// FIXME: reports.sigmasms.ru
import { /* downloadable, */ getUrlToStorage, globalErrorHandler, globalErrorProcessor } from '@/utils'

import render from './render'

export default {
  name: 'ButtonReport',

  mixins: [
    proxy()

    // FIXME: reports.sigmasms.ru
    // downloadable({ permissionAccept: 'advanced.sendings.find' })
  ],

  props: {
    preset: {
      type: String,
      default: ''
    },

    filter: {
      type: Object,
      default: () => ({})
    },

    icon: Boolean
  },

  data() {
    return {
      loading: false,

      encoding: 'win1251',

      delimiter: ';',

      // FIXME: reports.sigmasms.ru
      downloadable: false
    }
  },

  methods: {
    async getReport() {
      try {
        this.loading = true

        const { data } = await Vue.$GRequest.create('reports', {
          preset: this.preset,
          encoding: this.encoding,
          delimiter: this.delimiter,
          where: this.filter
        })

        if (data) {
          window.location.href = getUrlToStorage([ this.account.id, data.key ])
        }
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      } finally {
        this.loading = false
        this.proxy = false
      }
    },

    async getRemoteReport() {
      try {
        this.loading = true

        await Vue.$GRequest.create('reports/remote', {
          preset: this.preset,
          encoding: this.encoding,
          delimiter: this.delimiter,
          where: this.filter
        })
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      } finally {
        this.loading = false
      }
    }
  },

  render
}
