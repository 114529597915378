export default {
  title: 'Files',

  states: {
    error: 'Loading error',
    success: 'File is loaded',
    loading: 'File is uploading'
  },

  drop: 'Drop your file(s) here'
}
