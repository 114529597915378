import HintRow from '@/components/misc/HintRow'
import FileItems from '@/components/file/items'
import FileDialog from '@/components/file/dialog'
import OwnerSelect from '@/components/misc/OwnerSelect'
import RefreshButton from '@/components/button/refresh'
import UploadFileButton from '@/components/misc/UploadFileButton'
import pagination from '@/components/misc/pagination'

import { FILE_TYPES } from '@/utils'

const name = 'storage'

function renderLoadingArea(h) {
  return h(
    'div',
    {
      attrs: { id: 'loading-area' },
      class: {
        'loading-area': true,
        'loading-area--active': this.showLoadingArea
      },
      on: {
        dragleave: event => {
          event.preventDefault()
          this.showLoadingArea = false
        },
        drop: event => {
          event.preventDefault()
          this.showLoadingArea = false
        }
      }
    },
    [ this.uploadState !== 'loading' ? this.getTranslate('files.drop') : undefined ]
  )
}

function renderUploadFileButtonOrArea(h, state = true, type = 'button') {
  if (state && this.checkPermissions(`advanced.${name}.upload`, [ 'me', 'reseller', true ])) {
    return h(
      UploadFileButton,
      {
        props: {
          flat: true,
          rounded: true,
          isPublic: this.isPublic,
          name: this.name,
          maxFileSize: this.maxFileSize,
          disabled: this.disabled,
          accepted: this.types || undefined,
          type: type
        }
      },
      [ type === 'drop' ? renderLoadingArea.call(this, h) : undefined ]
    )
  }
}

function renderCloseButton(h) {
  if (this.embedded) {
    return h(
      'g-button',
      {
        props: {
          label: this.getTranslate('misc.buttons.close'),
          flat: true,
          rounded: true
        },
        on: {
          click: () => {
            this.$emit('show', false)
          }
        }
      }
    )
  }
}

function renderPagination(h) {
  if (this.restData[name].find.pagination.total) {
    return h(
      'div',
      {
        class: 'fjcfe facfe faic'
      },
      [
        h(
          pagination, {
            props: {
              value: this.restData[name].find.pagination,
              rowsPerPage: [ 30, 60, 90 ]
            },
            on: {
              input: event => {
                this.restData[name].find.pagination = event
              }
            }
          }
        ),

        h(
          RefreshButton,
          {
            props: {
              state: this.restData[name].find.state,
              shortClickMethod: () => this.rest[name].find(),
              longClickMethod: () => this.rest[name].find({}, { noCache: true })
            }
          }
        )
      ]
    )
  }
}

function renderFilterByOwner(h) {
  return h(
    OwnerSelect,
    {
      props: {
        value: this.restData[name].find.filter.OwnerId,
        mode: 'outline',
        dense: true,
        rounded: true,
        details: false,
        clearable: true
      },
      on: {
        input: event => {
          this.restData[name].find.filter.OwnerId = event
        }
      }
    }
  )
}

function renderFileIconInFilter(h, type, color, size) {
  return h(
    'g-icon',
    {
      class: 'mr-1',
      props: {
        library: 'icofont',
        value: `file-${type}`,
        color: color,
        size: size
      }
    }
  )
}

function renderFileTypeFilter(h) {
  if (this.checkPermissions('advanced.users.get')) {
    return h(
      'g-select',
      {
        props: {
          value: this.computedTypes,
          label: this.getTranslate('commons.labels.type'),
          items: Object.keys(FILE_TYPES).reduce((result, type) => {
            if (type !== 'file') {
              result.push({
                title: this.getTranslate(FILE_TYPES[type].title),
                value: type
              })
            }

            return result
          },
          []),
          mode: 'outline',
          dense: true,
          rounded: true,
          details: false,
          clearable: true,
          multiple: true
        },
        scopedSlots: {
          selection: ({ item }) => {
            return renderFileIconInFilter.call(this, h, FILE_TYPES[item.value].icon, FILE_TYPES[item.value].color, 16)
          },
          item: ({ item }) => {
            return h(
              'div',
              {
                class: 'fr'
              },
              [
                renderFileIconInFilter.call(this, h, FILE_TYPES[item.value].icon, FILE_TYPES[item.value].color, 20),

                h(
                  'div',
                  { class: 'ml-1' },
                  [
                    item.title,

                    h('div', { class: 'text--grey tiny' }, FILE_TYPES[item.value].extensions.join(', '))
                  ]
                )
              ]
            )
          }
        },
        on: {
          input: event => {
            this.searchTypes = event
          }
        }
      }
    )
  }
}

function renderDivider(h, trigger = true) {
  if (trigger) {
    return h('g-divider')
  }
}

function renderHeader(h) {
  return h(
    'div',
    {
      class: 'fc',
      key: 'file-manager-header',
      slot: this.embedded ? 'header' : undefined
    },
    [
      h(
        'div',
        {
          class: {
            ff: true,
            faic: this.viewport.breakpoint.mdUp,
            fc: this.viewport.breakpoint.smDown
          }
        },
        [
          h(
            'div',
            {
              class: {
                'grid grid-gap--8 pa-2': true,
                'grid-cols--4': this.viewport.breakpoint.mdUp,
                'grid-cols--1': this.viewport.breakpoint.smDown
              },
              style: { 'max-width': '800px' }
            },
            [
              h(
                'g-text-field',
                {
                  props: {
                    value: this.restData[name].find.filter.$search,
                    label: this.getTranslate('commons.labels.search'),
                    backgroundColor: 'transparent',
                    disabled: !this.restData[name].find.filter.$search && !this.restData[name].find.pagination.total,
                    mode: 'outline',
                    dense: true,
                    rounded: true,
                    details: false,
                    clearable: true
                  },
                  on: {
                    input: event => {
                      this.restData[name].find.filter.$search = event
                    }
                  }
                }
              ),

              h(
                'g-text-field',
                {
                  props: {
                    value: this.restData[name].find.filter.id,
                    label: this.getTranslate('commons.labels.id'),
                    backgroundColor: 'transparent',
                    mode: 'outline',
                    dense: true,
                    rounded: true,
                    details: false,
                    clearable: true
                  },
                  on: {
                    input: event => {
                      this.restData[name].find.filter.id = event
                    }
                  }
                }
              ),

              renderFilterByOwner.call(this, h),
              renderFileTypeFilter.call(this, h)
            ]
          ),

          h('div', { class: 'ff' }),

          h(
            'div',
            {
              class: {
                'fjcfe facfe': true,
                fjcsb: this.viewport.breakpoint.smDown && this.restData[name].find.pagination.total > 0
              }
            },
            [
              renderUploadFileButtonOrArea.call(this, h, this.restData[name].find.pagination.total > 0),

              h(
                'g-button',
                {
                  props: {
                    icon: this.type === 'cards' ? 'view_list' : 'view_comfy',
                    flat: true,
                    disabled: !this.restData[name].find.pagination.total
                  },
                  on: {
                    click: () => {
                      if (this.type === 'cards') {
                        this.type = 'list'
                      } else {
                        this.type = 'cards'
                      }
                    }
                  }
                }
              )
            ]
          )
        ]
      ),

      renderDivider.call(this, h, !this.embedded),
      renderPagination.call(this, h),
      renderDivider.call(this, h)
    ]
  )
}

function renderBodyWithDrag(h) {
  return h(
    'div',
    {
      style: { display: this.uploadState === 'loading' && this.embedded ? 'none' : 'block' },
      on: {
        dragenter: event => {
          event.preventDefault()
          event.stopPropagation()

          const area = document.getElementById('loading-area')
          area.style.width = `${area.parentElement.parentElement.offsetWidth}px`
          area.style.height = `${area.parentElement.parentElement.offsetHeight}px`

          this.showLoadingArea = true
        }
      }
    },
    [
      renderUploadFileButtonOrArea.call(this, h, true, 'drop'),
      renderBody.call(this, h)
    ]
  )
}

function renderModalWindowWithPreloader(h) {
  if (this.uploadState === 'loading' && this.embedded) {
    return h(
      'div',
      {
        class: 'fr faic pa-3',
        style: { 'min-height': '48px' }
      },
      [
        h('g-progress', { props: { indeterminate: true } }),
        h('div', { class: 'pl-3' }, this.getTranslate('files.states.loading'))
      ]
    )
  } else {
    return h('div')
  }
}

function renderBody(h) {
  if (this.$slots.default) {
    return this.$slots.default
  } else {
    if (this.restData[name].find.pagination.total) {
      return h(
        'div',
        {
          class: 'fc',
          key: 'file-manager-content'
        },
        [
          h(
            FileItems,
            {
              props: {
                type: this.type,
                items: this.restData[name].find.data,
                order: this.restData[name].find.order,
                currentChosenFileId: this.currentChosenFileId,
                readonly: this.readonly,
                embedded: this.embedded
              },
              on: {
                input: event => {
                  this.$emit('input', event)
                },
                order: event => {
                  this.restData[name].find.order = event
                },
                dialog: event => {
                  if (event.close) {
                    this.googleFileURL = undefined
                    this.currentEditFile = undefined
                    this.editDialogType = undefined
                    this.showEditDialog = false

                    return
                  }
                  this.googleFileURL = event.googleFileURL
                  this.currentEditFile = event.currentEditFile
                  this.editDialogType = event.editDialogType
                  this.showEditDialog = true
                }
              }
            }
          )
        ]
      )
    } else {
      if (~[ 'loading', 'empty' ].indexOf(this.restData[name].find.state) && !this.embedded) {
        return h(
          'div',
          {
            class: 'fjcc facc faic pa-3'
          },
          [ h('g-progress', { props: { indeterminate: true } }) ]
        )
      } else {
        return h(
          'div',
          {
            class: 'fc fjcc facc faic pa-5'
          },
          [
            h(
              'g-empty',
              {
                props:
                {
                  title: this.getTranslate(`${name}.hints.emptyState`)
                }
              }
            ),

            renderUploadFileButtonOrArea.call(this, h)
          ]
        )
      }
    }
  }
}

function renderFooter(h) {
  if (this.restData[name].find.pagination.total || this.embedded) {
    return h(
      'div',
      {
        class: 'fc',
        style: {
          'border-top': '1px solid #eaeaea',
          display: this.$slots.default || this.uploadState === 'loading' && this.embedded ? 'none' : null
        },
        key: 'file-manager-footer',
        slot: this.embedded ? 'footer' : undefined
      },
      [
        h(
          'div',
          {
            class: 'faic'
          },
          [
            renderCloseButton.call(this, h),

            h('div', { class: 'ff' }),

            renderPagination.call(this, h)
          ]
        ),

        h(HintRow, { props: { value: this.getTranslate(`${name}.hints.expire`) } })
      ]
    )
  } else {
    return h('div')
  }
}

export default function(h) {
  return h(
    this.embedded ? 'g-dialog' : 'g-card',
    {
      props: {
        rounded: true,
        value: this.show,
        maxWidth: this.embedded ? 1000 : undefined,
        width: this.uploadState === 'loading' && this.embedded || this.$slots.default ? 'auto' : '100%',
        closeOnClick: this.$slots.default ? false : this.uploadState !== 'loading',
        closeOnEsc: this.$slots.default ? false : this.uploadState !== 'loading'
      },
      on: {
        input: event => {
          this.$emit('show', event)
        },
        isShown: event => {
          this.$emit('isShown', event)

          if (event && this.embedded) {
            this.rest[name].find()
          }
        }
      }
    },
    [
      renderHeader.call(this, h),
      renderBodyWithDrag.call(this, h),
      renderModalWindowWithPreloader.call(this, h),
      renderFooter.call(this, h),

      h(
        FileDialog,
        {
          props: {
            value: this.currentEditFile,
            googleFileURL: this.googleFileURL,
            show: this.showEditDialog,
            type: this.editDialogType
          },
          on: {
            show: event => {
              this.showEditDialog = event
            },
            input: event => {
              this.currentEditFile = event
            }
          }
        }
      )
    ]
  )
}
