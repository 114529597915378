import { serviceName } from '@/components/services/apisender/utils'

const _ = { get: require('lodash/get') }

export default function(h) {
  const isPaid = _.get(this.value, 'isPaid')

  return h(
    'div',
    {
      class: `text--${this.value.isPaid ? 'success' : 'error'}`
    },
    [ this.getTranslate(`${serviceName}.states.isPaid.${isPaid}`) ]
  )
}
