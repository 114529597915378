import { serviceName } from '@/components/services/tariffRatesUpdating/utils'

function renderKey(h, key) {
  if (this.groups.length) {
    const [ mcc, mnc ] = key.split('_')
    const group = this.groups.find(group => {
      if (mnc === 'all') {
        return group.mcc === mcc
      }

      return group.mcc === mcc && group.mnc === mnc
    })

    if (group && group.country && group.operator) {
      const result = [ group.country ]
      if (mnc === 'all') {
        result.push(this.getTranslate(`${serviceName}.hints.wholeCountry`))
      } else {
        result.push(group.operator)
      }

      return h(
        'div',
        {
          class: 'grid grid-gap--0 faic caption text--grey'
        },
        result.map(string => h('div', { class: 'text--overflow' }, [ string ]))
      )
    }
  }
}

function renderContent(h) {
  const keys = Object.keys(this.value)
  if (keys.length) {
    return Object.keys(this.proxy).map(key => {
      return h(
        'div',
        {
          class: 'grid grid-gap--8 faic',
          style: { gridTemplateColumns: '1fr 150px' }
        },
        [
          renderKey.call(this, h, key),

          h(
            'g-text-field',
            {
              props: {
                value: this.proxy[key],
                label: this.getTranslate(`${serviceName}.labels.price`),
                suffix: '&#8381;',
                type: 'number',
                step: '0.0001',
                mode: 'outline',
                dense: true,
                rounded: true,
                details: false
              },
              on: {
                input: event => {
                  this.proxy[key] = event
                }
              }
            }
          )
        ]
      )
    })
  } else {
    return h('g-empty')
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8'
    },
    [ renderContent.call(this, h) ]
  )
}
