import Vue from 'vue'

import { PAYMENTS_METHODS } from '@sigma-legacy-libs/essentials/lib/constants'
import { DOCUMENT_TEMPLATES_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import { OUR_DOMAINS, permissionPresets } from '@/utils'

import HintRow from '@/components/misc/HintRow'
import DocumentTemplatesGenerateForm from '@/components/services/documentTemplates/generate/form'
import PaymentsForm from '@/components/services/payments/form'
import yooMoneyForm from '@/components/button/payment/yooMoneyForm'

const _ = { get: require('lodash/get') }

const widths = {
  first: '300px',
  legal: '500px',
  individual: '360px',
  cards: '400px',
  yooMoney: '500px'
}

function renderArrowLeft(h) {
  if (this.step !== 'first') {
    return h(
      'g-button',
      {
        props: {
          icon: 'keyboard_arrow_left',
          flat: true
        },
        on: {
          click: () => {
            switch (this.step) {
              case 'legal':
              case 'individual': {
                this.step = 'first'
                break
              }
              case 'yooMoney':
              case 'cards': {
                this.step = 'individual'
                break
              }
            }
          }
        }
      }
    )
  }
}

function renderHeader(h) {
  return h(
    'div',
    {
      class: 'faic',
      slot: 'header'
    },
    [
      renderArrowLeft.call(this, h),

      h(
        'div',
        {
          class: {
            'ff faic text-center pl-3 pr-3 w--100': true,
            'fjcc facc': this.step !== 'first'
          }
        },
        [
          h(
            'div',
            {
              class: 'subheading'
            },
            this.getTranslate(`payments.contents.step.${this.step}`)
          )
        ]
      ),

      h(
        'g-button',
        {
          props: {
            icon: 'clear',
            flat: true
          },
          on: {
            click: () => {
              this.step = 'first'
              this.amount = undefined
              this.setShowPaymentDialog(false)
            }
          }
        }
      )
    ]
  )
}

function renderUseYooMoney(h) {
  if (~OUR_DOMAINS.indexOf(window.location.hostname)) {
    return h(
      'div',
      {
        class: 'fc faifs caption pt-3'
      },
      [
        h(
          'span',
          {
            class: 'text--grey'
          },
          this.getTranslate('payments.hints.yooMoney')
        ),

        h(
          'span',
          {
            class: 'link link--dashed',
            on: {
              click: () => {
                this.step = 'yooMoney'
              }
            }
          },
          this.getTranslate('payments.hints.goToPayment')
        )
      ]
    )
  }
}

function renderSteps(h) {
  switch (this.step) {
    case 'first': {
      return h(
        'g-list',
        {
          props: { rounded: this.viewport.breakpoint.mdUp }
        },
        this.paymentTypes.map(type => {
          return h(
            'g-list-item',
            {
              class: 'fjcfs facfs',
              on: {
                click: () => {
                  this.step = type.name
                }
              }
            },
            this.getTranslate(`payments.types.${type.name}`)
          )
        })
      )
    }

    case 'individual': {
      if (this.checkPermissions('advanced.payments.create', permissionPresets.me)) {
        return h(
          'g-list',
          {
            props: { rounded: this.viewport.breakpoint.mdUp }
          },
          this.paymentMethods.map(method => {
            return h(
              'g-list-item',
              {
                class: this.viewport.breakpoint.smDown ? 'fjcfs facfs' : 'fjcc facc',
                on: {
                  click: () => {
                    switch (method.name) {
                      case 'recurrent': {
                        Vue.router.push({
                          name: 'account',
                          hash: 'recurrentPayments'
                        })
                        this.setShowPaymentDialog(false)
                        this.setShowCreateRecurrentPaymentDialog(true)
                        break
                      }

                      default: {
                        this.step = method.name
                        break
                      }
                    }
                  }
                }
              },
              [
                h(
                  'div',
                  {
                    class: {
                      'fjcfs facfs faic': true,
                      'pl-5 pr-5': !this.viewport.breakpoint.smDown
                    }
                  },
                  [
                    h(
                      'img',
                      {
                        attrs: { src: `/logos/payments/${method.name}-padding.svg` },
                        class: 'mr-3 square--40'
                      }
                    ),

                    h(
                      'div',
                      {
                        class: 'fc'
                      },
                      [ this.getTranslate(`payments.methods.${method.name}`) ]
                    )
                  ]
                )
              ]
            )
          })
        )
      } else {
        const contacts = _.get(this.globalSettings, 'contacts.phones', [])
        const emails = _.get(this.globalSettings, 'contacts.emails', [])

        return h(
          'div',
          {
            class: 'grid'
          },
          [
            h(HintRow, { props: { value: this.getTranslate('payments.hints.individual') } }),

            h(
              'div',
              {
                class: 'grid grid-gap--4 px-3'
              },
              [
                h(
                  'div',
                  {
                    class: 'grid grid-gap--2'
                  },
                  contacts.map(({ value }) => {
                    return h(
                      'a',
                      {
                        attrs: {
                          target: '_blank',
                          href: `tel:${value}`
                        },
                        class: 'link'
                      },
                      value
                    )
                  })
                ),
                h(
                  'div',
                  {
                    class: 'grid grid-gap--2'
                  },
                  emails.map(({ value }) => {
                    return h(
                      'a',
                      {
                        attrs: {
                          target: '_blank',
                          href: `mailto:${value}`
                        },
                        class: 'link'
                      },
                      value
                    )
                  })
                )
              ]
            )
          ]
        )
      }
    }
    case 'legal': {
      return h(
        DocumentTemplatesGenerateForm,
        {
          class: 'pa-3',
          props: {
            value: this.payload.legal,
            allowedTypes: [ DOCUMENT_TEMPLATES_TYPES.invoice ]
          },
          on: {
            input: event => {
              this.payload.legal = event
            },
            show: event => {
              this.setShowPaymentDialog(event)
            }
          }
        }
      )
    }

    case 'cards': {
      return h(
        'div',
        {
          class: 'fc ff pa-3'
        },
        [
          h(
            PaymentsForm,
            {
              props: {
                value: this.payload.individual,
                methods: PAYMENTS_METHODS
              },
              on: {
                input: event => {
                  this.payload.individual = event
                }
              }
            }
          ),

          renderUseYooMoney.call(this, h),

          h(
            'div',
            {
              class: 'fjcfe facfe ff faic'
            },
            [
              h(
                'g-button',
                {
                  class: 'ma-0',
                  props: {
                    label: this.getTranslate('misc.buttons.pay'),
                    rounded: true,
                    depressed: true,
                    loading: this.loading,
                    disabled: !this.payload.individual.amount || !this.payload.individual.acceptOffer || this.loading,
                    large: this.viewport.breakpoint.smDown,
                    color: 'primary'
                  },
                  on: {
                    click: () => this.requestPayment()
                  }
                }
              )
            ]
          )
        ]
      )
    }

    case 'yooMoney': {
      return h(yooMoneyForm)
    }
  }
}

function renderFooter(h) {
  switch (this.step) {
    case 'legal': {
      return h(
        'g-card-actions',
        {
          class: 'fjcfe facfe',
          slot: 'footer'
        },
        [
          h(
            'g-button',
            {
              props: {
                label: this.getTranslate('misc.buttons.bill'),
                rounded: true,
                depressed: true,
                loading: this.loading,
                disabled: this.loading || !this.validation,
                color: 'primary'
              },
              on: {
                click: () => {
                  this.requestDocument()
                }
              }
            }
          )
        ]
      )
    }
  }
}

function renderDialog(h) {
  return h(
    'g-dialog',
    {
      style: { 'margin-bottom': this.viewport.breakpoint.smDown ? '70px' : undefined },
      props: {
        value: this.showPaymentDialog,
        rounded: this.viewport.breakpoint.mdUp,
        closeOnEsc: false,
        closeOnClick: true,
        maxWidth: this.viewport.breakpoint.smDown ? '100vw' : widths[this.step],
        fullscreenMobile: false,
        align: this.viewport.breakpoint.smDown ? 'bottom' : undefined
      },
      on: {
        input: event => {
          if (event === false) {
            this.step = 'first'
          }
          this.setShowPaymentDialog(event)
        }
      }
    },
    [
      renderHeader.call(this, h),
      renderSteps.call(this, h),
      renderFooter.call(this, h)
    ]
  )
}

export default function(h) {
  return h(
    'g-button',
    {
      style: { display: this.viewport.breakpoint.smDown ? 'none' : 'inline-flex' },
      props: {
        flat: true,
        rounded: this.viewport.breakpoint.mdUp,
        color: 'white',
        icon: this.viewport.breakpoint.smDown ? 'monetization_on' : undefined,
        label: this.viewport.breakpoint.mdUp ? this.getTranslate('payments.buttons.add.funds') : undefined
      },
      on: {
        click: () => {
          this.setShowPaymentDialog(true)
        }
      },
      scopedSlots: {
        dialog: () => renderDialog.call(this, h)
      }
    }
  )
}
