import Vue from 'vue'

import draggable from '@/components/misc/draggable'
import routesCreateFull from '@/components/services/routes/create/full'
import routesCreateDirect from '@/components/services/routes/create/direct'
import messageTypeIcon from '@/components/message/type/icon'
import routesConditions from '@/components/trees/routes/conditions'

import { serviceName } from '@/components/services/routingGroups/utils'

let pendingClick = 0

const _ = {
  get: require('lodash/get'),
  isEqual: require('lodash/isEqual'),
  isEqualWith: require('lodash/isEqualWith')
}

function renderHasChanges(h, type) {
  if (this.RoutingGroups && this.initRoutingGroups) {
    if (!_.isEqualWith(this.RoutingGroups[type], this.initRoutingGroups[type], _.isEqual)) {
      return h(
        'div',
        {
          class: 'bgc--primary square--12 ml-3',
          style: { 'border-radius': '50%' }
        }
      )
    }
  }
}

function renderType(h, { title, value }) {
  return h(
    'div',
    {
      class: 'faic mr-3'
    },
    [
      h(
        'div',
        {
          class: 'fjcc faic square--36 mr-2'
        },
        [
          h(
            messageTypeIcon,
            {
              props: {
                value,
                size: 20
              }
            }
          )
        ]
      ),

      h(
        'div',
        {
          class: 'fjcsb faic headline'
        },
        [
          h('div', {}, this.getTranslate(title)),

          renderHasChanges.call(this, h, value)
        ]
      )
    ]
  )
}

function renderAddButton(h, type) {
  return h(
    'div',
    {
      class: `${serviceName}-tree__add-button`
    },
    [
      h(
        'g-button',
        {
          class: 'ma-0',
          props: {
            flat: true,
            icon: this.showAddRouteMenu[type] ? 'clear' : 'add',
            color: this.showAddRouteMenu[type] ? 'black' : 'secondary'
          },
          on: {
            click: () => {
              this.clearShowAddRouteMenu(type)
              this.showAddRouteMenu[type] = !this.showAddRouteMenu[type]
            }
          }
        }
      )
    ]
  )
}

function renderAddMenuContent(h, type) {
  if (this.showAddRouteMenu[type]) {
    return h(
      'div',
      {
        class: `${serviceName}-tree__add-menu`
      },
      [
        h(
          'g-select',
          {
            props: {
              value: this.routeSelector,
              label: this.getTranslate(`${serviceName}.labels.route`),
              itemsDisabled: this.RoutingGroups[type].map(({ id }) => id),
              service: 'routingGroups',
              query: {
                type,
                isActive: true
              },
              mode: 'outline',
              autocomplete: true,
              itemsWithTooltips: true,
              rounded: true,
              dense: true,
              details: false
            },
            on: {
              input: event => {
                this.addRoutingGroup(event, type)
              }
            }
          }
        ),

        h(
          'g-menu',
          {
            props: {
              rounded: true,
              offsetSkidding: 8,
              offsetDistance: -38
            }
          },
          [
            h(
              'g-button',
              {
                class: 'my-0',
                props: {
                  flat: true,
                  icon: 'add',
                  color: 'secondary'
                },
                slot: 'activator'
              }
            ),

            h(
              'g-list',
              {
                props: {
                  dense: true,
                  rounded: true
                }
              },
              [
                h(
                  'g-list-item',
                  {
                    props: {
                      label: this.getTranslate('routes.types.full')
                    },
                    on: {
                      click: () => {
                        this.showDialog.full[type] = true
                      }
                    }
                  }
                ),
                h(
                  'g-list-item',
                  {
                    props: {
                      label: this.getTranslate('routes.types.direct')
                    },
                    on: {
                      click: () => {
                        this.showDialog.direct[type] = true
                      }
                    }
                  }
                )
              ]
            )
          ]
        ),

        h(
          routesCreateFull,
          {
            props: {
              show: this.showDialog.full[type],
              type
            },
            on: {
              input: event => {
                if (!~this.RoutingGroups[type].findIndex(item => item.id === event)) {
                  this.RoutingGroups[type].push({ id: event })
                  this.fillRoutingGroups(true)
                }
              },
              showDialog: event => {
                if (typeof event === 'boolean') {
                  this.showDialog.full[type] = event
                }
              }
            }
          }
        ),
        h(
          routesCreateDirect,
          {
            props: {
              value: this.showDialog.direct[type],
              type
            },
            on: {
              input: event => {
                if (!~this.RoutingGroups[type].findIndex(item => item.id === event)) {
                  this.RoutingGroups[type].push({ id: event })
                  this.fillRoutingGroups(true)
                }
              },
              showDialog: event => {
                if (typeof event === 'boolean') {
                  this.showDialog.direct[type] = event
                }
              }
            }
          }
        )
      ]
    )
  }
}

function renderAddMenu(h, type) {
  if (this.viewport.breakpoint.mdUp) {
    return h(
      'transition',
      {
        props: { name: 'route-show-menu' }
      },
      [ renderAddMenuContent.call(this, h, type) ]
    )
  }

  return renderAddMenuContent.call(this, h, type)
}

function renderEditLink(h, service, id) {
  return h(
    'router-link',
    {
      class: {
        'ml-1 hover-background active-background link link--passive link--no-active link--no-hover round square--28 faic fjcc': true,
        'hover-child': this.viewport.breakpoint.mdUp
      },
      props: {
        to: {
          name: `${service}.single`,
          params: { id }
        }
      }
    },
    [
      h(
        'g-icon',
        {
          props: {
            value: 'edit',
            size: 18
          }
        }
      )
    ]
  )
}

function renderHeader(h, { title, value }) {
  if (!this.readonly) {
    return h(
      'div',
      {
        class: `${serviceName}-tree__header`
      },
      [
        renderType.call(this, h, {
          title,
          value
        }),

        h(
          'div',
          {
            class: `${serviceName}-tree__add`
          },
          [
            renderAddButton.call(this, h, value),
            renderAddMenu.call(this, h, value)
          ]
        )
      ]
    )
  }
}

function renderCategoryIcon(h, type) {
  const categoriesIcons = {
    routingGroups: 'align_horizontal_right',
    routingRules: 'rule',
    providerGroups: 'backup_table'
  }

  return h(
    'g-icon',
    {
      props: {
        value: categoriesIcons[type],
        size: 16
      }
    }
  )
}

function renderRow(h, options = {}) {
  return h(
    'div',
    {
      class: {
        [`${serviceName}-tree__row`]: true,
        [`${serviceName}-tree__row--${this.getRowState(options.service, options.id)}`]: true
      },
      on: {
        click: event => {
          if (pendingClick) {
            clearTimeout(pendingClick)
            pendingClick = 0
          }

          switch (event.detail) {
            case 1:
              pendingClick = setTimeout(() => {
                if (typeof options.click === 'function') {
                  options.click()
                }
              }, 300)
              break
            case 2:
              if (typeof options.dblclick === 'function') {
                options.dblclick()
              }
              break
          }
        }
      }
    },
    [
      renderCategoryIcon.call(this, h, options.service),

      h('div', { class: `${serviceName}-tree__title` }, options.title),
      h('div', { class: `${serviceName}-tree__description` }, this.getTranslate(`${options.service}.one`)),
      h('div', { class: `${serviceName}-tree__info` }, options.info)
    ]
  )
}

function renderProviders(h, parentId) {
  if (Array.isArray(this.Providers[parentId]) && this.Providers[parentId].length) {
    const service = 'providers'

    return h(
      'div',
      {
        class: `${serviceName}-tree-${service}`
      },
      this.Providers[parentId].map(({ id, title }) => {
        return h(
          'div',
          {
            class: {
              [`${serviceName}-tree-provider`]: true,
              [`${serviceName}-tree-provider--active`]: this.highlight[service] && !!~this.highlight[service].indexOf(id)
            }
          },
          [
            h(
              'div',
              {
                class: {
                  [`${serviceName}-tree__section`]: true,
                  'hover-parent hover-parent--opacity': true
                }
              },
              [
                h(
                  'div',
                  {
                    class: {
                      [`${serviceName}-tree__row`]: true,
                      [`${serviceName}-tree__row--childless`]: true
                    }
                  },
                  [
                    h('div', { class: `${serviceName}-tree__title` }, title),
                    h('div', { class: `${serviceName}-tree__description` }, this.getTranslate(`${service}.one`))
                  ]
                ),

                renderEditLink.call(this, h, service, id)
              ]
            )
          ]
        )
      })
    )
  }
}

function renderProviderGroups(h, parentId) {
  if (Array.isArray(this.ProviderGroups[parentId]) && this.ProviderGroups[parentId].length) {
    const service = 'providerGroups'

    return h(
      'div',
      {
        class: `${serviceName}-tree-${service}`
      },
      this.ProviderGroups[parentId].map(({ id, title, percentage }) => {
        return h(
          'div',
          {
            class: {
              [`${serviceName}-tree-providerGroup`]: true,
              [`${serviceName}-tree-providerGroup--active`]: this.highlight[service] && !!~this.highlight[service].indexOf(id)
            }
          },
          [
            h(
              'div',
              {
                class: {
                  [`${serviceName}-tree__section`]: true,
                  'hover-parent hover-parent--opacity': true
                }
              },
              [
                renderRow.call(this, h, {
                  id,
                  title,
                  info: `${percentage}%`,
                  service,
                  click: () => this.getEntities(service, 'Providers', id),
                  dblclick: () => this.getAllEntities(service, 'Providers', id)
                }),

                renderEditLink.call(this, h, service, id)
              ]
            ),

            h(
              'div',
              {
                class: {
                  [`${serviceName}-tree__holder`]: true,
                  [`${serviceName}-tree__holder--${this.getRowState(service, id)}`]: true
                }
              },
              [ renderProviders.call(this, h, id) ]
            )
          ]
        )
      })
    )
  }
}

function renderConditions(h, rules) {
  if (Array.isArray(rules) && rules.length) {
    return h(routesConditions, { props: { value: rules } })
  }
}

function renderRoutingRules(h, parentId) {
  if (Array.isArray(this.RoutingRules[parentId]) && this.RoutingRules[parentId].length) {
    const service = 'routingRules'

    return h(
      'div',
      {
        class: `${serviceName}-tree-${service}`
      },
      this.RoutingRules[parentId].map(({ id, title, rules }) => {
        return h(
          'div',
          {
            class: {
              [`${serviceName}-tree-routingRule`]: true,
              [`${serviceName}-tree-routingRule--active`]: this.highlight[service] && !!~this.highlight[service].indexOf(id)
            }
          },
          [
            h(
              'div',
              {
                class: {
                  [`${serviceName}-tree__section`]: true,
                  'hover-parent hover-parent--opacity': true
                }
              },
              [
                renderRow.call(this, h, {
                  id,
                  title,
                  service,
                  click: () => this.getEntities(service, 'ProviderGroups', id),
                  dblclick: () => this.getAllEntities(service, 'ProviderGroups', id)
                }),

                renderEditLink.call(this, h, service, id)
              ]
            ),

            h(
              'div',
              {
                class: {
                  [`${serviceName}-tree__holder`]: true,
                  [`${serviceName}-tree__holder--${this.getRowState(service, id)}`]: true
                }
              },
              [
                renderConditions.call(this, h, rules),
                renderProviderGroups.call(this, h, id)
              ]
            )
          ]
        )
      })
    )
  }
}

function renderRoutingGroupRemoveButton(h, { id, value }) {
  if (!this.readonly) {
    return h(
      'g-menu',
      {
        props: {
          value: this.showConfirmRemoveMenu[id],
          closeOnContentClick: false,
          transparent: true,
          placement: 'right',
          offsetDistance: -32
        },
        on: {
          input: event => {
            Vue.set(this.showConfirmRemoveMenu, id, event)
          }
        }
      },
      [
        h(
          'g-button',
          {
            class: 'my-0 ml-1 mr-0',
            props: {
              icon: 'remove',
              color: 'error',
              flat: true,
              small: true
            },
            slot: 'activator',
            on: {
              click: () => {
                Vue.set(this.showConfirmRemoveMenu, id, true)
              }
            }
          }
        ),

        h(
          'g-card',
          {
            class: 'py-2 px-3',
            props: { rounded: true }
          },
          [
            h(
              'div',
              {
                class: 'faic'
              },
              [
                this.getTranslate('misc.buttons.remove') + '?',

                h(
                  'div',
                  {
                    class: 'link link--dashed link--error ml-2',
                    on: {
                      click: () => {
                        this.removeRoutingGroup(id, value)
                      }
                    }
                  },
                  this.getTranslate('misc.buttons.yes')
                )
              ]
            )
          ]
        )
      ]
    )
  }
}

function renderRoutingGroups(h, { value }) {
  if (Array.isArray(this.RoutingGroups[value]) && this.RoutingGroups[value].length) {
    return h(
      draggable,
      {
        class: { 'mlpx-42': this.viewport.breakpoint.mdUp && !this.readonly },
        props: { value: this.RoutingGroups[value] },
        scopedSlots: {
          item: ({ id, title }) => {
            return h(
              'div',
              {
                class: {
                  [`${serviceName}-tree-routingGroup`]: true,
                  [`${serviceName}-tree-routingGroup--active`]: this.highlight[serviceName] && !!~this.highlight[serviceName].indexOf(id)
                }
              },
              [
                h(
                  'div',
                  {
                    class: {
                      [`${serviceName}-tree__section`]: true,
                      'hover-parent hover-parent--opacity': true,
                      'hover--active': this.showConfirmRemoveMenu[id]
                    }
                  },
                  [
                    renderRow.call(this, h, {
                      id,
                      title,
                      service: serviceName,
                      click: () => this.getEntities(serviceName, 'RoutingRules', id),
                      dblclick: () => this.getAllEntities(serviceName, 'RoutingRules', id)
                    }),

                    h(
                      'div',
                      {
                        class: {
                          'fjcfe facfe': true,
                          'hover-child': this.viewport.breakpoint.mdUp
                        }
                      },
                      [
                        renderEditLink.call(this, h, serviceName, id),
                        renderRoutingGroupRemoveButton.call(this, h, {
                          id,
                          value
                        })
                      ]
                    )
                  ]
                ),

                h(
                  'div',
                  {
                    class: {
                      [`${serviceName}-tree__holder`]: true,
                      [`${serviceName}-tree__holder--${this.getRowState(serviceName, id)}`]: true
                    }
                  },
                  [ renderRoutingRules.call(this, h, id) ]
                )
              ]
            )
          }
        }
      }
    )
  }
}

function renderContent(h) {
  return h(
    'div',
    {
      class: 'grid grid-cols--1 grid-gap--8 pa-3'
    },
    this.types.map(type => {
      return h(
        'div',
        {
          class: 'grid grid-cols--1 grid-gap--8'
        },
        [
          renderHeader.call(this, h, type),
          renderRoutingGroups.call(this, h, type)
        ]
      )
    })
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: `${serviceName}-tree`
    },
    [ renderContent.call(this, h) ]
  )
}
