const _ = { isPlainObject: require('lodash/isPlainObject') }

export function getFileId(data) {
  const result = []
  if (Array.isArray(data)) {
    result.push(...data)
  } else if (_.isPlainObject(data)) {
    result.push(data.Owner && data.Owner.id || data.OwnerId)
    result.push(data.id)
  } else if (typeof data === 'string') {
    const [ id1, id2 ] = data.split('/')
    result.push(id1, id2)
  }

  return result.filter(item => !!item).join('/')
}

export default { getFileId }
