import proxy from '@sigma-legacy-libs/g-proxy'

import { unVue } from '@sigma-legacy-libs/unvue'
import { SENDING_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import render from './render'

const defaultPrice = 0.1
const defaultValue = {
  price: [ defaultPrice ],
  action: 'decrease',
  rules: []
}

export default {
  name: 'TariffsRules',

  mixins: [ proxy({ type: 'array' }) ],

  props: {
    meta: {
      type: Object,
      default: () => {
        return {
          source: undefined,
          currency: undefined
        }
      }
    }
  },

  methods: {
    add() {
      const data = unVue(defaultValue)
      if (this.meta.type === SENDING_TYPES.voice) {
        if (data.price.length < 2) {
          data.price.push(defaultPrice)
        }
      }
      this.proxy.push(data)
    }
  },

  render
}
