import proxy from '@sigma-legacy-libs/g-proxy'

import { unVue } from '@sigma-legacy-libs/unvue'
import { EMPTY_SENDINGS } from '@sigma-legacy-libs/essentials/lib/constants'

import render from './render'

const _ = { pick: require('lodash/pick') }

export default {
  name: 'TemplatesForm',

  mixins: [ proxy({ type: 'object' }) ],

  props: {
    errors: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    fallbacks() {
      return this.proxy && this.proxy.fallbacks && this.proxy.fallbacks.length > 0
    }
  },

  methods: {
    setFallbacks() {
      this.proxy.fallbacks.splice(this.proxy.fallbacks.length, 0, _.pick(unVue(EMPTY_SENDINGS), [ 'type', 'payload', '$options' ]))
    }
  },

  render
}
