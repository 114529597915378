import Vue from 'vue'

import proxy from '@sigma-legacy-libs/g-proxy'

import { isPhoneNumber, isUUID } from '@sigma-legacy-libs/essentials/lib/validators'
import { REGEXP_IS_EMAIL_SIMPLE, REGEXP_IS_RUSSIAN_NUMBER } from '@sigma-legacy-libs/essentials/lib/constants/regexp'

import {
  REGEXP_EXCLUDE_SYMBOLS,
  REGEXP_PLUS_DIGIT,
  REGEXP_REPLACE_PLUS_SEVEN,
  REGEXP_REPLACE_SYMBOLS,
  generateServices,
  globalErrorHandler,
  globalErrorProcessor
} from '@/utils'

import render from './render'

export default {
  name: 'SendingsCreateRecipients',

  mixins: [
    proxy({ type: 'object' }),

    generateServices({
      name: 'contactLists',

      find: false,
      get: false,
      update: false,
      remove: false
    })
  ],

  props: { error: String },

  data() {
    return {
      contactLists: {
        show: {
          dialog: false
        },
        loading: {
          create: false
        }
      }
    }
  },

  methods: {
    pasteFilter(value) {
      const separator = '\n'

      return this.checkValues(
        ('' + value)
          .replace(REGEXP_EXCLUDE_SYMBOLS, '')
          .replace(REGEXP_REPLACE_SYMBOLS, separator)
          .split(separator)
      )
    },

    checkValues(value) {
      return value.reduce((values, currentValue) => {
        let result = null

        if (isUUID(currentValue) && ~value.indexOf(currentValue)) {
          result = currentValue
        } else if (REGEXP_IS_RUSSIAN_NUMBER.test(currentValue.replace(REGEXP_PLUS_DIGIT, ''))) {
          result = currentValue.replace(REGEXP_PLUS_DIGIT, '').replace(REGEXP_REPLACE_PLUS_SEVEN, '+7$2')
        } else if (REGEXP_IS_EMAIL_SIMPLE.test(currentValue)) {
          result = currentValue
        } else {
          if (isPhoneNumber(currentValue).isValid) {
            result = isPhoneNumber(currentValue).formatted
          }
        }

        if (result && !~values.indexOf(result)) {
          values.push(result)
        }

        return values
      }, [])
    },

    async createContactList() {
      try {
        if (this.restData.contactLists.create.isValid) {
          this.contactLists.loading.create = true
          const { id } = await this.rest.contactLists.create(this.restData.contactLists.create.data)
          if (id) {
            Vue.router.push({
              name: 'contacts.single',
              params: { id }
            })
          }
        }
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      } finally {
        this.contactLists.loading.create = false
        this.contactLists.show.dialog = false
      }
    }
  },

  render
}
