import messageTypeIcon from '@/components/message/type/icon'

import { STATES } from '@/utils'

function renderRoutingGroups(h) {
  if (this.restData.routingGroups.find.state === STATES.ready) {
    if (this.restData.routingGroups.find.pagination.total) {
      return h(
        'div',
        {
          class: 'fc ff pt-3'
        },
        [
          h(
            'div',
            {
              class: 'caption text--grey px-3 mb-3',
              domProps: { innerHTML: this.getTranslate('routes.hints.direct.choose') }
            }
          ),

          ...this.restData.routingGroups.find.data.map(item => {
            return h(
              'div',
              {
                class: 'faic hover-background cursor-pointer lh-32 px-3',
                on: {
                  click: () => {
                    this.$emit('input', item.id)
                    this.clear()
                    this.$emit('showDialog', false)
                  }
                }
              },
              [ item.title ]
            )
          })
        ]
      )
    }
  }
}

function renderContent(h) {
  return h(
    'g-dialog',
    {
      props: {
        value: this.value,
        rounded: true,
        overflow: true,
        maxWidth: 400
      },
      on: {
        input: event => {
          this.$emit('showDialog', event)
        }
      }
    },
    [
      h(
        'div',
        {
          class: 'faic px-3 headline',
          slot: 'header'
        },
        [
          h(
            'div',
            {
              class: 'text--grey pr-2'
            },
            this.getTranslate('routes.titles.direct.title')
          ),

          h(
            'div',
            {
              class: 'faic fjcc square--48'
            },
            [
              h(
                messageTypeIcon,
                {
                  props: {
                    value: this.type
                  }
                }
              )
            ]
          ),

          this.getTranslate(`sendings.types.${this.type}`)
        ]
      ),

      h(
        'div',
        {
          class: 'fc ff pa-3'
        },
        [
          h(
            'g-select',
            {
              props: {
                value: this.provider,
                label: this.getTranslate('routes.labels.direct.provider.choose'),
                service: 'providers',
                query: {
                  type: this.type,
                  isActive: true
                },
                mode: 'outline',
                rounded: true,
                details: false,
                autocomplete: true
              },
              on: {
                input: event => {
                  this.provider = event
                }
              }
            }
          ),

          renderRoutingGroups.call(this, h)
        ]
      ),

      h(
        'g-card-actions',
        {
          class: 'fjcfe facfe',
          slot: 'footer'
        },
        [
          h(
            'g-button',
            {
              props: {
                flat: true,
                rounded: true,
                disabled: this.loading,
                label: this.getTranslate('misc.buttons.cancel')
              },
              on: {
                click: () => {
                  this.clear()
                  this.$emit('showDialog', false)
                }
              }
            }
          ),

          h(
            'g-button',
            {
              props: {
                rounded: true,
                depressed: true,
                loading: this.loading,
                disabled: this.disabled || this.loading,
                color: 'primary',
                label: this.getTranslate('misc.buttons.create')
              },
              on: {
                click: () => {
                  this.create()
                }
              }
            }
          )
        ]
      )
    ]
  )
}

export default function(h) {
  return renderContent.call(this, h)
}
