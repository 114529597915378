import { PUBLIC_FIELDS_USER, SUBSCRIPTION_OPERATORS, SUBSCRIPTION_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import { fillDependence, serviceTemplate } from '@/utils'

import { serviceName } from '@/components/services/subscriptions/utils'

export default {
  mixins: [
    serviceTemplate({
      serviceName,

      async inputFilter(result) {
        const { type, data } = result

        if (type === SUBSCRIPTION_TYPES['subscription.sms.sendername']) {
          const operators = {}
          for (const operator of SUBSCRIPTION_OPERATORS.sort()) {
            const { approved = false, isPaid = false } = data[operator] || {}
            operators[operator] = {
              approved,
              isPaid
            }
          }

          result.data = operators
        }

        if (!result.Owner) {
          await fillDependence.call(this, result, {
            service: 'users',
            permissions: [ 'reseller', true ],
            pathToId: 'OwnerId',
            pathToObject: 'Owner',
            outputPath: 'Owner',
            picks: PUBLIC_FIELDS_USER
          })
        }

        return result
      }
    })
  ]
}
