import { NOTIFICATIONS_AVAILABLE_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

const key = 'notifications.enabled'

export default function(h) {
  return h(
    'div',
    {
      class: {
        grid: true,
        'grid-cols--3': this.viewport.breakpoint.smUp,
        'grid-cols--1': this.viewport.breakpoint.xs
      }
    },
    NOTIFICATIONS_AVAILABLE_TYPES.map(type => {
      if (type !== 'voice') {
        return h(
          'g-switch',
          {
            props: {
              value: this.proxy[type],
              label: this.getTranslate(`${this.serviceName}.labels.${key}.types.${type}`),
              color: 'primary'
            },
            on: {
              input: event => {
                this.proxy[type] = event
              }
            }
          }
        )
      }
    })
  )
}
