export default {
  title: 'Имена отправителей',

  tabs: {
    sendernames: 'Имена отправителей',
    patterns: 'Шаблоны для модерации'
  },

  tour: {
    type: 'Тип сообщения.',
    moderation: 'Статус модераци одобрено или отклонено.',
    sendername: 'Отправитель рассылки, который будет отображаться у получателя.',
    comment: 'Комментарий для менеджера компании.',
    filter: {
      sendername: 'Фильтр по имени отправителя.',
      type: 'Фильтр по типу отправления.',
      moderation: 'Фильтр по статусу модерации.'
    },
    create: 'Кнопка создания нового имени отправителя.'
  },

  tooltips: {
    copy: {
      it: 'Скопировать',
      all: 'Скопировать в буфер обмена имя и комментарий'
    }
  },

  snackbars: {
    copied: 'Скопировано'
  }
}
