const _ = { get: require('lodash/get') }

export async function findInArrayAndReplaceOrDelete(haystack, needle, callback) {
  let replacement
  let find = needle

  if (typeof needle !== 'string') {
    replacement = needle
    find = _.get(needle, this.idField)
  }

  const foundIndex = haystack.findIndex(item => _.get(item, this.idField) === find)
  if (foundIndex > -1) {
    const args = [ foundIndex, 1 ]
    if (typeof callback === 'function') {
      replacement = await callback()
    }
    if (replacement !== undefined) {
      args.push(replacement)
    }

    haystack.splice(...args)

    return true
  }

  return false
}

export async function defaultWebsocketFindCreatedEvent(instance) {
  if (!this.findData.filterIsEqualToDefault && !this.options.find.alwaysCreateFromWebSocket) {
    return
  }

  if (this.findData.filterIsEqualToDefault || this.options.find.alwaysCreateFromWebSocket) {
    this.findData.pagination.total++
  }

  let workingArray = this.findData.data
  let limit = this.findData.pagination.limit

  if (this.options.find.bucketEnabled) {
    workingArray = this.bucket

    limit = this.options.find.bucketMaxLength
  } else {
    instance = await this.options.inputFilter.call(this.ctx, instance)
  }

  workingArray.unshift(instance)
  if (workingArray.length > limit && !this.options.find.appendMode) {
    workingArray.splice(limit)
  }

  if (workingArray === this.bucket) {
    this.findData.bucketLength = this.bucket.length
  }
}
export async function defaultWebsocketFindUpdatedEvent(instance) {
  if (!this.findData.filterIsEqualToDefault && !this.options.find.alwaysUpdateFromWebSocket) {
    return
  }

  let alreadyProcessed = false

  if (this.options.find.bucketEnabled) {
    alreadyProcessed = await findInArrayAndReplaceOrDelete.call(this, this.bucket, instance)
  }

  if (!alreadyProcessed) {
    await findInArrayAndReplaceOrDelete.call(
      this,
      this.findData.data,
      instance[this.idField],
      () => this.options.inputFilter.call(this.ctx, instance)
    )
  }
}
export async function defaultWebsocketFindRemovedEvent(instance) {
  if (!this.findData.filterIsEqualToDefault && !this.options.find.alwaysRemoveFromWebSocket) {
    return
  }

  if (this.findData.filterIsEqualToDefault || this.options.find.alwaysRemoveFromWebSocket) {
    this.findData.pagination.total--
  }

  let alreadyProcessed = false

  if (this.options.find.bucketEnabled) {
    alreadyProcessed = await findInArrayAndReplaceOrDelete.call(this, this.bucket, instance.id)
  }

  if (!alreadyProcessed) {
    await findInArrayAndReplaceOrDelete.call(this, this.findData.data, instance.id)
  }
}

export const generateDefaultWebsocketFindEvents = function(options) {
  return [
    {
      event: `${options.name}.created`,
      handler: options.find.websocketFindCreatedEvent ? options.find.websocketFindCreatedEvent : defaultWebsocketFindCreatedEvent
    },
    {
      event: `${options.name}.updated`,
      handler: options.find.websocketFindUpdatedEvent ? options.find.websocketFindUpdatedEvent : defaultWebsocketFindUpdatedEvent
    },
    {
      event: `${options.name}.updated2`,
      handler: options.find.websocketFindUpdatedEvent ? options.find.websocketFindUpdatedEvent : defaultWebsocketFindUpdatedEvent
    },
    {
      event: `${options.name}.removed`,
      handler: options.find.websocketFindRemovedEvent ? options.find.websocketFindRemovedEvent : defaultWebsocketFindRemovedEvent
    },
    {
      event: `${options.name}.removed2`,
      handler: options.find.websocketFindRemovedEvent ? options.find.websocketFindRemovedEvent : defaultWebsocketFindRemovedEvent
    }
  ]
}
