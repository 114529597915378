export default {
  title: 'Права',

  subtitles: {
    presets: 'Пресеты'
  },

  dialogs: {
    titles: {
      info: 'Информация по уровням прав'
    }
  },

  labels: {
    create: 'Создать',
    finalize: 'Завершать',
    find: 'Найти',
    generate: 'Генерировать',
    set: 'Установить',
    get: 'Получить',
    remove: 'Удалить',
    update: 'Обновить',
    modify: 'Изменить',
    seek: 'Seek',
    download: 'Скачать',
    upload: 'Загрузить',
    setPermissions: 'Задавать права',
    setRoutingGroups: 'Задавать группы маршрутизаций',
    setSettings: 'Задавать настройки',
    setSendernames: 'Задавать имена отправителей',
    setPatterns: 'Задавать паттерны',
    setTariffs: 'Задавать тарифы',
    showSecrets: 'Показывать секретные права',
    health: 'Производительность',
    loginAs: 'Войти под',
    request: 'Запрос',
    controlDocuments: 'Работы с документами',
    edit: 'Редактировать',
    viewChangelogs: 'Просматривать список изменений',
    contracts: 'Договоры',
    companies: 'Компании',
    documentTemplates: 'Шаблоны документов',
    refund: 'Возврат платежа',
    presetUsersBalance: 'Возможность скачивать список пользователей'
  },

  hints: {
    false: 'Запрещает любые действия требующие соответствующее право.',
    me: 'Разрешает действия с объектами, которые пользователь создал сам, или ему создал его реселлер или администратор.',
    manager: 'Разрешает действия с объектами, которые принадлежат самому пользователю, либо привязанным к менеджеру пользователям.',
    reseller: 'Разрешает действия с объектами, которые принадлежат самому пользователю, либо дочерним пользователям реселлера.',
    true: 'Разрешает действия с любыми объектами в системе.',
    warning: 'Изменение прав не требует обновления пользователя.'
  },

  tooltips: {
    info: 'Информация по уровням прав'
  },

  buttons: {
    false: 'False',
    me: 'User',
    reseller: 'Reseller'
  },

  services: {
    billings: 'Расчётные счета',
    dictionaries: 'Словари',
    providerGroups: 'Группы провайдеров',
    providers: 'Провайдеры',
    routingGroups: 'Группы маршрутизаций',
    routingRules: 'Правила маршрутизаций',
    sendings: 'Отправления',
    storage: 'Хранилище',
    tariffs: 'Тарифы',
    users: 'Пользователи',
    platform: 'Платформа',
    reports: 'Отчеты',
    components: 'Компоненты',
    tasks: 'Задачи',
    contacts: 'Контакты',
    payment: 'Платежи',
    settings: 'Настройки сайта',
    messageProcessors: 'Обработчики сообщений',
    templates: 'Шаблоны',
    patterns: 'Паттерн',
    sendernames: 'Имена отправителей',
    requisites: 'Реквизиты',
    sendingsIncoming: 'Входящие сообщения',
    changelogs: 'Список изменений',
    contracts: 'Договоры',
    companies: 'Компании',
    documentTemplates: 'Шаблоны документов',
    operatorGroups: 'Группы операторов',
    secret: 'Секретные',
    payments: 'Платежи',
    paymentLogs: 'Журнал платежей',
    recurrentPayments: 'Автоплатежи',
    settingsSite: 'Настройки сайта',
    settingsUser: 'Настройки пользователей',
    servers: 'Серверы',
    credentials: 'Учётные данные',
    tokens: 'Токены',
    apisender: 'API sender',
    messageRules: 'Правила обработки сообщений',
    messageRuleGroups: 'Группы Правил обработки сообщений',
    keywords: 'Ключевые слова',
    yclients: 'YClients'
  },

  snackbars: {
    updated: 'Право успешно обновлено'
  }
}
