export default function(h) {
  return h(
    'div',
    {
      class: 'fjcc faic'
    },
    [
      h('g-divider', { props: { style: this.borderStyle } }),

      h(
        'div',
        {
          class: `text-overflow fz-${this.size} text--${this.color} px-2`,
          domProps: { innerHTML: this.value }
        }
      ),

      h('g-divider', { props: { style: this.borderStyle } })
    ]
  )
}
