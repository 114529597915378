export default {
  title: 'YClients',

  titles: {
    create: 'Create salon'
  },

  labels: {
    salon: 'Salon ID',
    token: 'Token',
    isActive: 'Is active'
  },

  tooltips: {
    filter: {
      isActive: 'Show deactivated entities'
    }
  },

  snackbars: {
    created: 'Salon created',
    updated: 'Salon updated',
    removed: 'Salon removed'
  }
}
