import { SENDING_STATUS, WEBHOOK_EVENTS } from '@sigma-legacy-libs/essentials/lib/constants'

import titleDivider from '@/components/title/divider'
import HeadTitle from '@/components/misc/HeadTitle'
import ExpandArrow from '@/components/misc/ExpandArrow'

function renderExtras(h, type, { path, serviceName }) {
  if (type === WEBHOOK_EVENTS.onStatusChange) {
    return h(
      'div',
      {
        class: 'grid grid-cols--1'
      },
      [
        h(
          titleDivider,
          {
            props: { value: this.getTranslate(`${serviceName}.contents.${path}.statuses`) }
          }
        ),

        h(
          'g-expansion-panels',
          {
            props: {
              rounded: true,
              outline: true
            }
          },
          [
            h(
              'g-expansion-panel',
              {
                scopedSlots: {
                  header: ({ expanded }) => {
                    return h(
                      'div',
                      {
                        class: 'default-expansion-panel-header pr-0'
                      },
                      [
                        this.getTranslate(`${serviceName}.contents.${path}.statuses`),

                        h('div', { class: 'ff' }),

                        h(ExpandArrow, { props: { expanded } })
                      ]
                    )
                  }
                }
              },
              [
                h(
                  'div',
                  {
                    class: 'grid grid-cols--2 pa-3'
                  },
                  SENDING_STATUS.map(status => {
                    return h(
                      'g-text-field',
                      {
                        props: {
                          value: this.proxy[type].extras.statuses[status],
                          label: this.getTranslate(`sendings.statuses.${status}`),
                          mode: 'outline-label',
                          dense: true,
                          rounded: true,
                          clearable: true,
                          details: false
                        },
                        on: {
                          input: event => {
                            this.proxy[type].extras.statuses[status] = event
                          }
                        }
                      }
                    )
                  })
                )
              ]
            )
          ]
        )
      ]
    )
  }

  return h('div')
}

function renderForm(h, type, { path, serviceName }) {
  return h(
    'div',
    {
      class: 'grid grid-cols--1 pa-3'
    },
    [
      h(
        titleDivider,
        {
          props: { value: this.getTranslate(`${serviceName}.subtitles.${path}.general`) }
        }
      ),

      h(
        'div',
        {
          class: 'grid faic',
          style: { 'grid-template-columns': '60% calc(40% - 16px)' }
        },
        [
          h(
            'g-text-field',
            {
              props: {
                value: this.proxy[type].url,
                label: this.getTranslate(`${serviceName}.labels.${path}.url`),
                mode: 'outline',
                rounded: true,
                clearable: true,
                details: false
              },
              on: {
                input: event => {
                  this.payload[type].url = event
                }
              }
            }
          ),

          h(
            'g-select',
            {
              props: {
                value: this.proxy[type].method,
                label: this.getTranslate(`${serviceName}.labels.${path}.method`),
                items: [ 'POST', 'GET' ],
                mode: 'outline',
                rounded: true,
                clearable: true,
                details: false
              },
              on: {
                input: event => {
                  this.payload[type].method = event
                }
              }
            }
          )
        ]
      ),

      h(
        'g-textarea',
        {
          props: {
            value: this.proxy[type].headers,
            label: this.getTranslate(`${serviceName}.labels.${path}.headers`),
            mode: 'outline',
            rounded: true,
            grow: true,
            details: false
          },
          on: {
            input: event => {
              this.payload[type].headers = event
            }
          }
        }
      ),

      h(
        'g-textarea',
        {
          props: {
            value: this.proxy[type].body,
            label: this.getTranslate(`${serviceName}.labels.${path}.body`),
            mode: 'outline',
            rounded: true,
            grow: true,
            details: false
          },
          on: {
            input: event => {
              this.payload[type].body = event
            }
          }
        }
      ),

      h(
        'g-text-field',
        {
          props: {
            value: this.proxy[type].maxConnections,
            label: this.getTranslate(`${serviceName}.labels.${path}.maxConnections`),
            type: 'number',
            step: 'any',
            mode: 'outline',
            rounded: true,
            clearable: true,
            details: false
          },
          on: {
            input: event => {
              this.payload[type].maxConnections = event
            }
          }
        }
      ),

      h(
        titleDivider,
        {
          props: { value: this.getTranslate(`${serviceName}.subtitles.${path}.successOn`) }
        }
      ),

      h(
        'g-text-field',
        {
          props: {
            value: this.proxy[type].successOn.status,
            label: this.getTranslate(`${serviceName}.labels.${path}.successOn.status`),
            mode: 'outline',
            rounded: true,
            clearable: true,
            details: false
          },
          on: {
            input: event => {
              this.payload[type].successOn.status = event
            }
          }
        }
      ),

      h(
        'g-text-field',
        {
          props: {
            value: this.proxy[type].successOn.body,
            label: this.getTranslate(`${serviceName}.labels.${path}.successOn.body`),
            mode: 'outline',
            rounded: true,
            clearable: true,
            details: false
          },
          on: {
            input: event => {
              this.payload[type].successOn.body = event
            }
          }
        }
      ),

      renderExtras.call(this, h, type, {
        path,
        serviceName
      })
    ]
  )
}

export default function(h, { path, serviceName }) {
  return h(
    'div',
    {
      class: 'grid grid-cols--1'
    },
    WEBHOOK_EVENTS.map(type => {
      return h(
        'div',
        {
          class: 'grid faic',
          style: { 'grid-template-columns': '60% calc(40% - 16px)' }
        },
        [
          h(
            'div',
            {
              class: 'grid grid-cols--1 grid-gap--4'
            },
            [
              h(
                'div',
                {
                  class: 'body-1'
                },
                this.getTranslate(`${serviceName}.contents.${path}.${type}`)
              ),
              h(
                'div',
                {
                  class: 'small text--grey'
                },
                this.getTranslate(`${serviceName}.hints.${path}.${type}`)
              )
            ]
          ),

          h(
            'div',
            {
              class: 'fjcfe'
            },
            [
              h(
                'g-button',
                {
                  class: 'ma-0',
                  props: {
                    label: this.viewport.breakpoint.mdUp ? this.getTranslate('misc.buttons.edit') : '',
                    icon: 'edit',
                    flat: true,
                    rounded: true,
                    color: 'primary'
                  },
                  on: {
                    click: () => {
                      this.show[type] = true
                    }
                  }
                }
              )
            ]
          ),

          h(
            'g-dialog',
            {
              props: {
                rounded: true,
                value: this.show[type],
                maxWidth: 600
              },
              on: {
                input: event => {
                  this.show[type] = event
                }
              }
            },
            [
              h(
                HeadTitle,
                {
                  props: { value: this.getTranslate(`${serviceName}.dialogs.titles.${path}.${type}`) },
                  slot: 'header'
                }
              ),

              renderForm.call(this, h, type, {
                path,
                serviceName
              }),

              h(
                'g-card-actions',
                {
                  class: 'fjcfe',
                  slot: 'footer'
                },
                [
                  h(
                    'g-button',
                    {
                      props: {
                        label: this.getTranslate('misc.buttons.cancel'),
                        flat: true,
                        rounded: true
                      },
                      on: {
                        click: () => {
                          this.show[type] = false
                        }
                      }
                    }
                  ),

                  h(
                    'g-button',
                    {
                      props: {
                        label: this.getTranslate('misc.buttons.save'),
                        rounded: true,
                        depressed: true,
                        color: 'primary'
                      },
                      on: {
                        click: () => {
                          this.$emit('input', this.payload)
                          this.show[type] = false
                        }
                      }
                    }
                  )
                ]
              )
            ]
          )
        ]
      )
    })
  )
}
