import Vue from 'vue'

import { unVue } from '@sigma-legacy-libs/unvue'
import { DOCUMENT_TEMPLATES_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import { DEFAULT_INVOICE_PAYLOAD, getUrlToStorage, globalErrorHandler, globalErrorProcessor, isEmpty } from '@/utils'

import render from './render'

export default {
  name: 'GenerateInvoice',

  props: {
    OwnerId: String,
    ResellerId: String
  },

  data() {
    return {
      loading: false,
      showDialog: false,

      payload: unVue(DEFAULT_INVOICE_PAYLOAD)
    }
  },

  computed: {
    validation() {
      let isValidType = false
      if (this.payload.type === DOCUMENT_TEMPLATES_TYPES.contract) {
        isValidType = true
      } else if (this.payload.type === DOCUMENT_TEMPLATES_TYPES.invoice) {
        if (!isEmpty(this.payload.data.SERVICE_PRICE) && this.payload.data.SERVICE_PRICE) {
          isValidType = true
        }
      }

      return !!(this.payload.requisiteId && this.payload.companyId && isValidType)
    }
  },

  methods: {
    async generate() {
      try {
        this.loading = true
        const { data: file } = await Vue.$GRequest.find('documentTemplates/generate', { query: this.payload })
        if (file) {
          window.location.href = getUrlToStorage([ file.OwnerId || file.Owner && file.Owner.id, file.id ])
          this.showDialog = false
        }
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      } finally {
        this.loading = false
      }
    }
  },

  render
}
