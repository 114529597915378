import SingleLink from '@/components/misc/SingleLink'
import title from '@/components/services/requisites/item/title'
import vat from '@/components/services/requisites/item/vat'
import buttons from '@/components/services/requisites/item/buttons'

import { serviceName } from '@/components/services/requisites/utils'

function renderTitleCell(h) {
  return h(
    'td',
    {
      class: 'pl-3'
    },
    [ h(title, { props: { value: this.value } }) ]
  )
}

function renderVATCell(h) {
  return h(
    'td',
    {
      class: 'px-2'
    },
    [ h(vat, { props: { value: this.value } }) ]
  )
}

function renderOwnerCell(h) {
  if (this.checkPermissions(`advanced.${serviceName}.get`)) {
    return h(
      'td',
      {
        class: 'px-2'
      },
      [
        h(
          SingleLink,
          {
            props: {
              value: this.value.OwnerId
            }
          }
        )
      ]
    )
  }
}

function renderFormattedDate(h, date) {
  if (date) {
    return h(
      'div',
      {
        class: 'text--grey text-right',
        style: { 'white-space': 'pre' },
        domProps: { innerHTML: date }
      }
    )
  }
}

function renderCreatedAtCell(h) {
  return h(
    'td',
    {
      class: 'px-2'
    },
    [ renderFormattedDate.call(this, h, this.value.$formattedCreatedAt) ]
  )
}

function renderUpdatedAtCell(h) {
  return h(
    'td',
    {
      class: 'px-2'
    },
    [ renderFormattedDate.call(this, h, this.value.$formattedUpdatedAt) ]
  )
}

function renderButtons(h) {
  return h(
    'td',
    {
      class: 'px-2 w--100'
    },
    [
      h(
        'div',
        {
          class: 'fjcfe'
        },
        [ h(buttons, { props: { value: this.value } }) ]
      )
    ]
  )
}

export default function(h) {
  return h(
    'tr',
    {
      class: 'hover-parent hover-parent--opacity'
    },
    [
      renderTitleCell.call(this, h),
      renderVATCell.call(this, h),
      renderOwnerCell.call(this, h),
      renderButtons.call(this, h),
      renderCreatedAtCell.call(this, h),
      renderUpdatedAtCell.call(this, h)
    ]
  )
}
