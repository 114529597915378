import proxy from '@sigma-legacy-libs/g-proxy'

import { SUBSCRIPTION_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import render from './render'

import { serviceName } from '@/components/services/subscriptions/utils'

export default {
  name: 'Form',

  mixins: [ proxy({ type: 'object' }) ],

  props: {
    sendingType: String
  },

  computed: {
    validation() {
      if (!this.proxy.expireAt || !this.proxy.type) {
        return false
      }

      if (!this.proxy.OwnerId && !this.proxy.SendernameId) {
        return false
      }

      switch (this.proxy.type) {
        case SUBSCRIPTION_TYPES['subscription.sms.sendername']: {
          break
        }
        default: {
          if (!this.proxy.pricePerMonth) {
            return false
          }

          break
        }
      }

      return true
    },

    expireAt() {
      if (this.proxy.expireAt) {
        return new Date(this.proxy.expireAt).toLocaleDateString(this.locale, {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          weekday: 'long'
        })
      }

      return this.getTranslate(`${serviceName}.hints.notSpecified`)
    },

    types() {
      const result = []
      const types = SUBSCRIPTION_TYPES.filter(type => {
        if (this.sendingType) {
          return type.search(this.sendingType) !== -1
        }

        return true
      })
      for (const type of types) {
        result.push({
          title: this.getTranslate(`${serviceName}.types.${type}`),
          value: type
        })
      }

      return result
    }
  },

  watch: {
    validation() {
      this.$emit('validation', this.validation)
    },
    'proxy.type'() {
      this.proxy.data = {}
    },
    types: {
      handler() {
        this.proxy.type = this.types[0].value
      },
      deep: true
    }
  },

  mounted() {
    this.$emit('validation', this.validation)
    this.proxy.type = this.types[0].value
  },

  render
}
