import { MODERATION_VALUES } from '@sigma-legacy-libs/essentials/lib/constants'

import { serviceName } from '@/components/services/sendernames/utils'

function renderApproveButton(h) {
  return h(
    'g-button',
    {
      class: 'ma-0',
      props: {
        flat: true,
        rounded: true,
        icon: 'done',
        color: 'success',
        disabled: MODERATION_VALUES.approved === this.value.moderation
      },
      on: {
        click: () => {
          this.approve()
        }
      }
    }
  )
}
function renderRejectMenu(h) {
  return h(
    'g-menu',
    {
      props: {
        rounded: true,
        offsetDistance: 16,
        disabled: MODERATION_VALUES.rejected === this.value.moderation
      }
    },
    [
      h(
        'g-button',
        {
          class: 'ma-0',
          props: {
            flat: true,
            rounded: true,
            icon: 'clear',
            color: 'warning',
            disabled: MODERATION_VALUES.rejected === this.value.moderation
          },
          slot: 'activator'
        }
      ),

      h(
        'g-list',
        {
          props: {
            dense: true,
            rounded: true
          }
        },
        Object.keys(this.rejectVariants).map(variant => {
          return h(
            'g-list-item',
            {
              props: {
                label: this.getTranslate(`${serviceName}.variants.${variant}`)
              },
              on: {
                click: () => {
                  this.reject(variant)
                }
              }
            }
          )
        })
      )
    ]
  )
}

function renderRejectButton(h) {
  return h(
    'g-button',
    {
      class: 'ma-0',
      props: {
        flat: true,
        rounded: true,
        icon: 'cancel',
        color: 'error',
        label: this.getTranslate(`${serviceName}.buttons.reject`),
        disabled: MODERATION_VALUES.rejected === this.value.moderation
      },
      on: {
        click: () => {
          this.reject()
        }
      }
    }
  )
}

export default function(h) {
  if (this.checkPermissions(`advanced.${serviceName}.get`)) {
    return h(
      'div',
      {
        class: 'faic fjcfe gird grid-gap--8',
        style: { 'grid-template-columns': 'repeat(2, minmax(min-content, max-content))' }
      },
      [
        renderApproveButton.call(this, h),
        renderRejectMenu.call(this, h),
        renderRejectButton.call(this, h)
      ]
    )
  }
}
