import proxy from '@sigma-legacy-libs/g-proxy'

import render from './render'

export default {
  name: 'Pagination',

  mixins: [ proxy({ type: 'object' }) ],

  props: {
    total: {
      type: Boolean,
      default: true
    },

    arrows: {
      type: Boolean,
      default: true
    },

    selectRows: {
      type: Boolean,
      default: true
    },

    show: {
      type: Boolean,
      default: true
    },

    chunk: {
      type: Number,
      default: 3
    },

    rowsPerPage: {
      type: Array,
      default: () => [
        25,
        50,
        100
      ]
    }
  },

  computed: {
    currentPage() {
      let page = Math.ceil(this.proxy.offset / this.proxy.limit + 1)
      if (page < 1) {
        page = 1
      }

      return page
    },

    pages() {
      const pages = []

      const chunk = this.chunk - 1

      let leftAmount = Math.floor(chunk / 2)
      let rightAmount = chunk - leftAmount

      for (let index = 1; index <= leftAmount; index++) {
        const amount = this.currentPage - index
        if (amount > 0) {
          pages.unshift(amount)
        }
      }

      leftAmount -= pages.length
      rightAmount += leftAmount

      pages.push(this.currentPage)

      for (let index = 1; index <= rightAmount; index++) {
        const amount = this.currentPage + index
        if (amount <= this.totalPages) {
          pages.push(amount)
        }
      }

      leftAmount = this.chunk - pages.length

      const b = pages[0]

      for (let i = 1; i <= leftAmount; i++) {
        const a = b - i
        if (a > 0) {
          pages.unshift(a)
        }
      }

      return pages
    },

    totalPages() {
      if (this.proxy.total > 0) {
        return Math.ceil(this.proxy.total / this.proxy.limit)
      } else {
        return 1
      }
    },

    buttonColumnsStyle() {
      const result = []
      const buttonWidth = '28px'

      let arrowColumns = 2
      if (this.viewport.breakpoint.smDown) {
        arrowColumns = 1
      }

      let actionColumns = 2
      if (!this.selectRows) {
        actionColumns = 1
      }

      result.push(`repeat(${arrowColumns}, ${buttonWidth})`) // arrows
      result.push(`repeat(${this.pages.length || 0}, ${buttonWidth})`) // pages
      result.push(`repeat(${arrowColumns}, ${buttonWidth})`) // arrows
      result.push(`repeat(${actionColumns}, minmax(min-content, max-content))`) // select rows and total

      return result.join(' ')
    }
  },

  methods: {
    setPage(page, limit) {
      limit = limit || this.proxy.limit

      Object.assign(this.proxy, {
        limit: limit,
        offset: limit * (page - 1)
      })
    }
  },

  render
}
