import { settingsSiteUser } from '@/utils'

import render from './render'

export default function(options) {
  return {
    mixins: [
      settingsSiteUser({
        serviceName: options.serviceName,
        UserId: options.UserId
      })
    ],

    data() {
      return {
        currentKey: undefined
      }
    },

    watch: {
      settingKey() {
        if (this.settingKey) {
          this.currentKey = this.settingKey
        }
      }
    },

    mounted() {
      if (options.renderSchema) {
        this.currentKey = options.renderSchema[0].key
      }
      if (this.settingKey) {
        this.currentKey = this.settingKey
      }
    },

    render(h) {
      return render.call(this, h, options)
    }
  }
}
