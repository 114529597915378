import proxy from '@sigma-legacy-libs/g-proxy'

import { unVue } from '@sigma-legacy-libs/unvue'

import render from './render'

export default {
  name: 'Fallbacks',

  mixins: [ proxy({ type: 'array' }) ],

  props: {
    defaultValue: {
      type: Object,
      default: () => ({})
    },

    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },

    flat: Boolean,
    outline: Boolean,
    rounded: Boolean
  },

  methods: {
    add() {
      this.proxy.fallbacks.push(unVue(this.defaultValue))
    },
    remove(index) {
      this.proxy.fallbacks.splice(index, 1)
    }
  },

  render
}
