import sendingsTasksItem from '@/components/services/sendings/tasks/item'

function renderContent(h) {
  if (this.checkPermissions('advanced.tasks.find', [ 'me', 'reseller', true ])) {
    return h(
      'g-card',
      {
        props: { rounded: true }
      },
      [
        h(
          'tasks-template',
          {
            props: { headers: this.headers },
            scopedSlots: {
              row: ({ item }) => {
                return h(
                  sendingsTasksItem,
                  {
                    props: {
                      value: item,
                      type: 'row'
                    }
                  }
                )
              },
              panel: ({ item }) => {
                return h(
                  sendingsTasksItem,
                  {
                    props: {
                      value: item,
                      type: 'panel'
                    }
                  }
                )
              }
            }
          }
        )
      ]
    )
  }
}

export default function(h) {
  return renderContent.call(this, h)
}
