import { SENDING_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

export const allowedSendingTypes = [
  SENDING_TYPES.sms,
  // SENDING_TYPES.mms,
  SENDING_TYPES.viber,
  SENDING_TYPES.vk,
  SENDING_TYPES.whatsapp,
  SENDING_TYPES.push,
  SENDING_TYPES.voice,
  SENDING_TYPES.flashcall,
  SENDING_TYPES.whatsappchat,
  SENDING_TYPES.viberchat,
  SENDING_TYPES.vkchat,
  SENDING_TYPES.telegramchat,
  SENDING_TYPES.facebookchat
]

export const allowedSendingTypesForImage = [
  SENDING_TYPES.mms,
  SENDING_TYPES.viber,
  SENDING_TYPES.whatsapp,
  SENDING_TYPES.whatsappchat,
  SENDING_TYPES.viberchat,
  SENDING_TYPES.vkchat,
  SENDING_TYPES.telegramchat,
  SENDING_TYPES.facebookchat
]

export default {
  allowedSendingTypes,
  allowedSendingTypesForImage
}
