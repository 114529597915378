import routesTree from '@/components/trees/routes'

const _ = { get: require('lodash/get') }

export default function(h) {
  if (this.routingGroups) {
    const routingGroup = _.get(this.value, 'meta._routing.routingGroup')
    const routingRule = _.get(this.value, 'meta._routing.routingRule')
    const providerGroup = _.get(this.value, 'meta._routing.providerGroup')
    const provider = _.get(this.value, 'meta._routing.provider')

    return h(
      routesTree,
      {
        props: {
          value: this.routingGroups || {},
          type: this.value.type,
          readonly: true,
          highlight: {
            routingGroups: [ routingGroup ].filter(v => !!v),
            routingRules: [ routingRule ].filter(v => !!v),
            providerGroups: [ providerGroup ].filter(v => !!v),
            providers: [ provider ].filter(v => !!v)
          }
        }
      }
    )
  }
}
