import SingleLink from '@/components/misc/SingleLink'
import messageType from '@/components/services/sendernames/item/type'
import title from '@/components/services/sendernames/item/title'
import moderation from '@/components/services/sendernames/item/moderation'
import moderationButtons from '@/components/services/sendernames/item/moderation/buttons'
import comments from '@/components/services/sendernames/item/comments'
import buttons from '@/components/services/sendernames/item/buttons'

import { serviceName } from '@/components/services/sendernames/utils'

function renderTypeCell(h) {
  return h(
    'td',
    {
      class: 'px-2'
    },
    [ h(messageType, { props: { value: this.value } }) ]
  )
}

function renderTitleCell(h) {
  return h(
    'td',
    {
      class: 'pl-0 pr-2'
    },
    [ h(title, { props: { value: this.value } }) ]
  )
}

function renderModerationCell(h) {
  return h(
    'td',
    {
      class: {
        'pl-0 pr-2': true,
        'w--100': this.viewport.breakpoint.smDown
      }
    },
    [ h(moderation, { props: { value: this.value } }) ]
  )
}

function renderOwnerCell(h) {
  if (this.checkPermissions(`advanced.${serviceName}.get`)) {
    return h(
      'td',
      {
        class: 'pa-0'
      },
      [
        h(
          SingleLink,
          {
            props: {
              value: this.value.OwnerId
            }
          }
        )
      ]
    )
  }
}

function renderCommentsCell(h) {
  if (this.viewport.breakpoint.mdUp) {
    return h(
      'td',
      {
        class: 'pl-0 pr-2 w--100'
      },
      [ h(comments, { props: { value: this.value } }) ]
    )
  }
}

function renderButtons(h) {
  if (this.viewport.breakpoint.mdUp) {
    return h(
      'td',
      {
        class: 'px-2'
      },
      [
        h(
          'div',
          {
            class: 'fjcfe'
          },
          [ h(buttons, { props: { value: this.value } }) ]
        )
      ]
    )
  }
}

function renderModerationButtons(h) {
  if (this.viewport.breakpoint.mdUp) {
    return h(
      'td',
      {
        class: 'px-2'
      },
      [
        h(
          'div',
          {
            class: 'fjcfe'
          },
          [ h(moderationButtons, { props: { value: this.value } }) ]
        )
      ]
    )
  }
}

function renderFormattedDate(h, date) {
  if (date) {
    return h(
      'div',
      {
        class: 'text--grey text-right',
        style: { 'white-space': 'pre' },
        domProps: { innerHTML: date }
      }
    )
  }
}

function renderCreatedAtCell(h) {
  return h(
    'td',
    {
      class: 'pl-0 pr-2',
      directives: [
        {
          name: 'g-tooltip',
          options: {
            value: this.getTranslate(`${serviceName}.labels.createdAt`),
            placement: 'top'
          }
        }
      ]
    },
    [ renderFormattedDate.call(this, h, this.value.$formattedCreatedAt) ]
  )
}

function renderUpdatedAtCell(h) {
  if (this.viewport.breakpoint.mdUp) {
    return h(
      'td',
      {
        class: 'pl-0 pr-2',
        directives: [
          {
            name: 'g-tooltip',
            options: {
              value: this.getTranslate(`${serviceName}.labels.updatedAt`),
              placement: 'top'
            }
          }
        ]
      },
      [ renderFormattedDate.call(this, h, this.value.$formattedUpdatedAt) ]
    )
  }
}

export default function(h) {
  return h(
    'tr',
    {
      class: 'hover-parent hover-parent--opacity'
    },
    [
      renderTypeCell.call(this, h),
      renderTitleCell.call(this, h),
      renderModerationCell.call(this, h),
      renderOwnerCell.call(this, h),
      renderCommentsCell.call(this, h),
      renderButtons.call(this, h),
      renderModerationButtons.call(this, h),
      renderCreatedAtCell.call(this, h),
      renderUpdatedAtCell.call(this, h)
    ]
  )
}
