import tariffs from '@/components/services/tariffRatesUpdating/item/tariffs'
import buttons from '@/components/services/tariffRatesUpdating/item/buttons'

function renderTariffs(h) {
  return h(tariffs, { props: { value: this.value } })
}

function renderButtons(h) {
  return h(buttons, { props: { value: this.value } })
}

export default function(h) {
  return h(
    'g-card',
    {
      class: 'hover-parent hover-parent--opacity pa-2',
      props: {
        outline: true,
        rounded: true
      }
    },
    [
      h(
        'div',
        {
          class: 'grid grid-gap--8 faic pl-2',
          style: { gridTemplateColumns: '1fr auto' }
        },
        [
          renderTariffs.call(this, h),
          renderButtons.call(this, h)
        ]
      )
    ]
  )
}
