import { DICTIONARIES_STATUSES } from '@sigma-legacy-libs/essentials/lib/constants'

import pagination from '@/components/misc/pagination'
import OneRowString from '@/components/misc/OneRowString'
import ConfirmRemoveButton from '@/components/misc/ConfirmRemoveButton'
import HeadTitle from '@/components/misc/HeadTitle'
import Form from '@/components/services/operatorGroups/form'

const name = 'operatorGroups'

const columns = [ [ 'mcc', 'mnc' ], [ 'operator', 'group' ] ]

function renderMobileButtons(h) {
  if (this.viewport.breakpoint.smDown && this.$slots.default) {
    return h(
      'div',
      {
        class: 'fjcfe facfe'
      },
      [ this.$slots.default ]
    )
  }
}

function renderSearchAndCreateButton(h) {
  return h(
    'div',
    {
      class: {
        fjcsb: this.viewport.breakpoint.mdUp,
        faic: this.viewport.breakpoint.smDown
      }
    },
    [
      h(
        'g-text-field',
        {
          props: {
            value: this.restData[name].find.filter.$search,
            label: this.getTranslate('commons.labels.search'),
            backgroundColor: 'transparent',
            disabled: !this.restData[name].find.filter.$search && this.restData[name].find.pagination.total === 0,
            mode: 'solo',
            hint: this.dictionary.id,
            flat: true,
            details: this.viewport.breakpoint.mdUp,
            loading: this.restData[name].find.state === 'loading',
            clearable: true
          },
          on: {
            input: event => {
              this.restData[name].find.filter.$search = event
            }
          }
        }
      ),

      renderRemoveDictionaryButton.call(this, h),
      renderCreateButton.call(this, h)
    ]
  )
}

function renderPagination(h) {
  if (this.restData[name].find.pagination.total > 0) {
    return h(
      'div',
      {
        class: 'fjcfe facfe'
      },
      [
        h(
          pagination,
          {
            props: {
              value: this.restData[name].find.pagination,
              chunk: this.viewport.breakpoint.smDown ? 2 : 5
            },
            on: {
              input: event => {
                this.restData[name].find.pagination = event
              }
            }
          }
        )
      ]
    )
  }
}

function renderCreateDialog(h) {
  if (this.checkPermissions(`advanced.${name}.create`, [ 'reseller', true ])) {
    return h(
      'g-dialog',
      {
        props: {
          rounded: true,
          value: this.showCreateDialog,
          maxWidth: 550
        },
        on: {
          input: event => {
            this.showCreateDialog = event
          }
        }
      },
      [
        h(
          HeadTitle,
          {
            props: { value: this.getTranslate('dictionaries.titles.createGroup') },
            slot: 'header'
          }
        ),

        h(
          Form,
          {
            props: {
              value: this.restData[name].create.data,
              errors: this.restData[name].create.errors,
              dictionary: this.dictionary
            },
            on: {
              input: event => {
                this.restData[name].create.data = event
              },
              validation: event => {
                this.restData[name].create.isValid = event
              }
            }
          }
        ),

        h(
          'g-card-actions',
          {
            class: 'fjcfe facfe pt-0',
            slot: 'footer'
          },
          [
            h(
              'g-button',
              {
                props: {
                  label: this.getTranslate('misc.buttons.cancel'),
                  flat: true,
                  rounded: true
                },
                on: {
                  click: () => {
                    this.showCreateDialog = false
                  }
                }
              }
            ),
            h(
              'g-button',
              {
                props: {
                  label: this.getTranslate('misc.buttons.create'),
                  rounded: true,
                  depressed: true,
                  disabled: !this.checkPermissions(`advanced.${name}.create`, [ 'reseller', true ]) || !this.restData[name].create.isValid,
                  loading: this.restData[name].create.state === 'loading',
                  color: 'primary'
                },
                on: {
                  click: () => {
                    this.createGroup()
                  }
                }
              }
            )
          ]
        )
      ]
    )
  }
}

function renderContent(h) {
  if (this.dictionary.id === this.restData[name].find.filter.DictionaryId) {
    if (this.restData[name].find.state === 'ready') {
      if (this.restData[name].find.pagination.total > 0) {
        if (this.viewport.breakpoint.mdUp) {
          return h(
            'g-table',
            {
              props: {
                headers: this.headers,
                items: this.restData[name].find.data,
                value: this.restData[name].find.order,
                noDataText: this.getTranslate('misc.no.data.text')
              },
              on: {
                input: event => {
                  this.restData[name].find.order = event
                }
              },
              scopedSlots: {
                items: ({ item }) => {
                  return h(
                    'tr',
                    {
                      class: {
                        'hover-parent--opacity': this.viewport.breakpoint.mdUp
                      },
                      key: item.id
                    },
                    [
                      ...columns.map((column, index) => {
                        return column.map(c => {
                          return h(
                            'td',
                            {
                              class: 'pl-2 pr-2',
                              style: {
                                width: index === 1 ? '30%' : null
                              }
                            },
                            [
                              h(OneRowString, {
                                props: {
                                  value: item[c] && `${item[c]}`
                                }
                              })
                            ]
                          )
                        })
                      }),

                      h('td', { class: 'w--100' }),

                      h(
                        'td',
                        {
                          class: 'pa-0'
                        },
                        [ renderRemoveRecordButton.call(this, h, item) ]
                      )
                    ]
                  )
                }
              }
            }
          )
        } else {
          return this.restData[name].find.data.map(item => {
            return h(
              'div',
              {
                class: 'fjcsb faic pl-2',
                key: `${item.id}`
              },
              [
                h(
                  'div',
                  {
                    class: 'faic text-overflow caption'
                  },
                  columns.map((column, index) => {
                    return h(
                      'div',
                      {
                        class: {
                          fc: true,
                          'pr-2': index === 0,
                          'text-overflow': index === 1
                        }
                      },
                      column.reduce((result, value) => {
                        if (item[value]) {
                          const directives = []
                          if ((item[value] + '').length > 15) {
                            directives.push({
                              name: 'g-tooltip',
                              options: {
                                value: item[value]
                              }
                            })
                          }
                          result.push(h(
                            'div',
                            {
                              class: 'faic mb-1'
                            },
                            [
                              h(
                                'span',
                                {
                                  class: 'text--grey'
                                },
                                this.getTranslate(`dictionaries.titles.${value}`)
                              ),

                              h(
                                'span',
                                {
                                  class: 'ml-1 text-overflow',
                                  directives
                                },
                                item[value]
                              )
                            ]
                          ))
                        }

                        return result
                      }, [])
                    )
                  })
                ),

                renderRemoveRecordButton.call(this, h, item)
              ]
            )
          })
        }
      } else {
        return h(
          'g-list-item',
          {
            style: { 'margin-left': '-4px' },
            props: { disabled: true }
          },
          this.getTranslate('misc.emptyList')
        )
      }
    }
  }

  return h('div')
}

function renderRemoveRecordButton(h, item) {
  return h(
    ConfirmRemoveButton,
    {
      class: 'hover-child',
      props: {
        content: this.getTranslate(`${name}.contents.confirm.remove`, { title: item.operator || '' }),
        disabled:
        this.restData[name].remove.state === 'loading' ||
        !this.checkPermissions('advanced.contacts.remove', [ 'reseller', true ]),
        callback: () => {
          this.rest[name].remove(item.id)
        }
      }
    }
  )
}

function renderRemoveDictionaryButton(h) {
  if (this.checkPermissions(`advanced.${name}.remove`, [ 'reseller', true ]) && this.viewport.breakpoint.smDown) {
    return h(
      ConfirmRemoveButton,
      {
        class: 'ml-1',
        props: {
          content: this.getTranslate('dictionaries.contents.confirm.remove.dictionary', { title: this.dictionary.id }),
          disabled: this.dictionary.status === DICTIONARIES_STATUSES.activated,
          stopPropagation: true,
          callback: () => {
            this.removeCallback()
          }
        }
      }
    )
  }
}

function renderCreateButton(h) {
  return h(
    'g-button',
    {
      props: {
        label: this.getTranslate('misc.buttons.create'),
        rounded: true,
        depressed: true,
        disabled: !this.checkPermissions(`advanced.${name}.create`, [ 'reseller', true ]),
        color: 'primary',
        small: true
      },
      on: {
        click: () => {
          this.showCreateDialog = true
        }
      }
    }
  )
}

export default function(h) {
  if (this.isCorrectStatus) {
    return h(
      'g-card',
      {
        class: {
          'ml-3': this.viewport.breakpoint.mdUp
        },
        props: {
          width: '100%',
          outline: true
        }
      },
      [
        renderMobileButtons.call(this, h),

        renderSearchAndCreateButton.call(this, h),

        renderPagination.call(this, h),

        renderContent.call(this, h),

        h('div', { class: 'ff' }),

        renderPagination.call(this, h),

        renderCreateDialog.call(this, h)
      ]
    )
  }
}
