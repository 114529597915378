import Vue from 'vue'

import { getUnixMilliseconds, globalErrorHandler, globalErrorProcessor } from '@/utils'

import render from './render'

const _ = { debounce: require('lodash/debounce') }

function parseDateToInt(date) {
  date = parseInt(date)
  if (!isNaN(date)) {
    return date
  }
}

let interval

function clear() {
  if (interval) {
    clearInterval(interval)
  }
}

export default {
  name: 'ProvidersLogs',

  props: {
    ProviderId: String,
    SendingId: String,

    start: {
      type: [ String, Number ],
      default: getUnixMilliseconds() - 5 * 60 * 1000
    },
    end: {
      type: [ String, Number ],
      default: getUnixMilliseconds()
    },

    readonly: Boolean,
    dialog: Boolean
  },

  data() {
    return {
      loading: false,

      logs: [],

      search: undefined,

      date: [ this.start, this.end ],
      time: {
        gt: '00:00',
        lt: '23:59'
      },

      dateWatchHandler: _.debounce(this.get,
        1000,
        {
          leading: false,
          trailing: true
        }),
      searchWatchHandler: _.debounce(this.get,
        1000,
        {
          leading: false,
          trailing: true
        }),

      online: false
    }
  },

  watch: {
    date: {
      handler() {
        this.dateWatchHandler.call(this)
      },
      deep: true
    },
    search() {
      this.searchWatchHandler.call(this)
    },
    online() {
      if (this.online) {
        this.get()
        clear()
        interval = setInterval(() => {
          if (this.loading === false) {
            this.get()
          }
        }, 15 * 1000)
      } else {
        clear()
      }
    }
  },

  mounted() {
    clear()
    this.get()
  },

  beforeDestroy() {
    clear()
  },

  methods: {
    async get() {
      try {
        this.loading = true

        const date = {
          start: getUnixMilliseconds() - 5 * 60 * 1000,
          end: getUnixMilliseconds()
        }

        const { data } = await Vue.$GRequest.get('grafana', 'range', {
          query: {
            data: { id: this.ProviderId },
            source: 'loki',
            preset: 'providers_logs',
            start: parseDateToInt((this.date[0] || this.start || date.start) / 1000),
            end: parseDateToInt((this.date[1] || this.end || date.end) / 1000),
            search: this.search || this.SendingId,
            params: {
              direction: 'BACKWARD',
              limit: 1000
            }
          }
        })

        const logs = []
        if (Array.isArray(data)) {
          data.reduce((result, item) => {
            if (Array.isArray(item) && item.length === 2) {
              result.push(JSON.parse(item[1]))
            }

            return result
          }, logs)
          if (Array.isArray(logs) && logs.length) {
            logs.sort((a, b) => b.time - a.time)
          }
        }
        this.logs = logs
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      } finally {
        this.loading = false
      }
    },

    parseTime() {
      const gt = this.date[0] ? new Date(this.date[0]) : new Date()
      const lt = this.date[1] ? new Date(this.date[1]) : new Date(gt)

      gt.setHours(...this.time.gt.split(':').map(n => parseInt(n)))
      lt.setHours(...this.time.lt.split(':').map(n => parseInt(n)))

      this.date.splice(0, 1, Date.parse(gt))
      this.date.splice(1, 1, Date.parse(lt))
    }
  },

  render
}
