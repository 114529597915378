import SingleLink from '@/components/misc/SingleLink'
import messageType from '@/components/services/patterns/item/type'
import title from '@/components/services/patterns/item/title'
import moderation from '@/components/services/patterns/item/moderation'
import buttons from '@/components/services/patterns/item/buttons'

import { serviceName } from '@/components/services/patterns/utils'

function renderTypeCell(h) {
  return h(
    'td',
    {
      class: 'px-2'
    },
    [ h(messageType, { props: { value: this.value } }) ]
  )
}

function renderTitleCell(h) {
  return h(
    'td',
    {
      class: 'pl-0 pr-2'
    },
    [ h(title, { props: { value: this.value } }) ]
  )
}

function renderModerationCell(h) {
  return h(
    'td',
    {
      class: 'pl-0 pr-2'
    },
    [ h(moderation, { props: { value: this.value } }) ]
  )
}

function renderOwnerCell(h) {
  if (this.checkPermissions(`advanced.${serviceName}.get`)) {
    return h(
      'td',
      {
        class: 'pa-0'
      },
      [
        h(
          SingleLink,
          {
            props: {
              value: this.value.OwnerId
            }
          }
        )
      ]
    )
  }
}

function renderTextCell(h) {
  return h(
    'td',
    {
      class: 'px-2'
    },
    [
      h(
        'div',
        {
          class: 'text--overflow text-nowrap',
          directives: [
            {
              name: 'g-tooltip',
              options: {
                value: this.value.payload.text,
                placement: 'top'
              }
            }
          ]
        },
        [ this.value.payload.text ]
      )
    ]
  )
}

function renderButtons(h) {
  return h(
    'td',
    {
      class: 'px-2 w--100'
    },
    [
      h(
        'div',
        {
          class: 'fjcfe'
        },
        [ h(buttons, { props: { value: this.value } }) ]
      )
    ]
  )
}

function renderFormattedDate(h, date) {
  if (date) {
    return h(
      'div',
      {
        class: 'text--grey text-right',
        style: { 'white-space': 'pre' },
        domProps: { innerHTML: date }
      }
    )
  }
}

function renderCreatedAtCell(h) {
  return h(
    'td',
    {
      class: 'pl-0 pr-2',
      directives: [
        {
          name: 'g-tooltip',
          options: {
            value: this.getTranslate(`${serviceName}.labels.createdAt`),
            placement: 'top'
          }
        }
      ]
    },
    [ renderFormattedDate.call(this, h, this.value.$formattedCreatedAt) ]
  )
}

function renderUpdatedAtCell(h) {
  return h(
    'td',
    {
      class: 'pl-0 pr-2',
      directives: [
        {
          name: 'g-tooltip',
          options: {
            value: this.getTranslate(`${serviceName}.labels.updatedAt`),
            placement: 'top'
          }
        }
      ]
    },
    [ renderFormattedDate.call(this, h, this.value.$formattedUpdatedAt) ]
  )
}

export default function(h) {
  return h(
    'tr',
    {
      class: 'hover-parent hover-parent--opacity'
    },
    [
      renderTypeCell.call(this, h),
      renderTitleCell.call(this, h),
      renderModerationCell.call(this, h),
      renderOwnerCell.call(this, h),
      renderTextCell.call(this, h),
      renderButtons.call(this, h),
      renderCreatedAtCell.call(this, h),
      renderUpdatedAtCell.call(this, h)
    ]
  )
}
