export default {
  title: 'Документация',

  categories: {
    platform: 'Платформа',
    api: 'API'
  },

  file: {
    title: 'Документация'
  },

  files: {
    instruction: 'Пользовательская документация',
    'HTTP-REST': 'HTTP-REST',
    SMPP: 'SMPP'
  },

  buttons: {
    toc: 'Содержание'
  }
}
