import proxy from '@sigma-legacy-libs/g-proxy'

import render from './render'

export default {
  name: 'OwnerSelect',

  mixins: [ proxy() ],

  props: {
    permission: {
      type: String,
      default: 'advanced.users.find'
    },
    accept: {
      type: Array,
      default: () => [ 'reseller', true ]
    },

    itemsDisabled: {
      type: Array,
      default: () => []
    },

    label: String,
    hint: String,

    query: {
      type: Object,
      default: () => {
        return { isActive: true }
      }
    },

    mode: {
      type: String,
      default: 'outline'
    },

    flat: {
      type: Boolean,
      default: true
    },
    dense: {
      type: Boolean,
      default: false
    },
    details: {
      type: Boolean,
      default: true
    },
    clearable: {
      type: Boolean,
      default: true
    },
    autocomplete: {
      type: Boolean,
      default: true
    },
    required: {
      type: Boolean,
      default: false
    },
    rounded: {
      type: Boolean,
      default: true
    },

    clearItems: {
      type: Function,
      default: v => v
    }
  },

  render
}
