import Actions from '@sigma-legacy-libs/essentials/lib/utils/Actions'

import { MODERATION_VALUES } from '@sigma-legacy-libs/essentials/lib/constants'

import textGenerator from '@/components/misc/rules/actions/dialog/generators/text'
import fallbacksGenerator from '@/components/misc/rules/actions/dialog/generators/fallbacks'

import { allowedSendingTypes } from '@/utils'

function renderHeaderContent(h) {
  if (this.tag) {
    if (this.tagEdit && !this.readonly) {
      return h(
        'g-select',
        {
          props: {
            value: this.tag,
            label: this.getTranslate(`conditions.tags.${this.tag}`),
            items: Actions.tagsOnly.map(tag => {
              return {
                title: this.getTranslate(`conditions.tags.${tag}`),
                value: tag
              }
            }),
            itemsDisabled: this.tagDisabled,
            mode: 'outline',
            dense: true,
            rounded: true,
            details: false,
            required: true
          },
          on: {
            input: event => {
              this.$emit('tag', event)
            }
          }
        }
      )
    } else {
      return h(
        'div',
        {
          class: 'headline'
        },
        this.getTranslate(`conditions.tags.${this.tag}`)
      )
    }
  }
}

function renderHeader(h) {
  return h(
    'div',
    {
      class: 'pa-3',
      slot: 'header'
    },
    [ renderHeaderContent.call(this, h) ]
  )
}

function renderMainComponent(h) {
  if (this.tag) {
    let component = 'g-select'
    let props

    const defaultProps = {
      value: this.proxy,
      label: this.getTranslate(`conditions.tags.${this.tag}`),
      mode: 'outline',
      disabled: this.disabled,
      details: false,
      rounded: true,
      autofocus: true,
      dense: true,
      clearable: true,
      readonly: this.readonly
    }

    switch (this.tag) {
      case 'text': {
        component = textGenerator
        props = Object.assign({}, { value: this.proxy })
        break
      }

      case 'fallbacks': {
        component = fallbacksGenerator
        props = Object.assign({}, { value: this.proxy })
        break
      }

      case 'type': {
        props = Object.assign({}, defaultProps, {
          items: allowedSendingTypes.map(type => ({
            title: this.getTranslate(`sendings.types.${type}`),
            value: type
          }))
        })
        break
      }

      case 'sender': {
        props = Object.assign({}, defaultProps, {
          service: 'sendernames',
          itemTitle: 'name',
          itemValue: 'name',
          multiple: true,
          combobox: true,
          autocomplete: true
        })
        break
      }

      case 'moderation': {
        props = Object.assign({}, defaultProps, {
          items: MODERATION_VALUES.map(moderation => {
            return {
              title: this.getTranslate(`misc.moderation.${moderation}`),
              value: moderation
            }
          })
        })
        break
      }

      default: {
        props = Object.assign({}, defaultProps)
        break
      }
    }

    return h(
      component,
      {
        props,
        on: {
          input: event => {
            this.proxy = event
          }
        }
      }
    )
  }
}

export default function(h) {
  if (this.tag) {
    return h(
      'g-dialog',
      {
        props: {
          value: this.show,
          rounded: true,
          maxWidth: 600
        },
        on: {
          input: event => {
            this.$emit('dialog', event)
          }
        }
      },
      [
        renderHeader.call(this, h),

        h(
          'div',
          {
            class: 'grid grid-cols--1 pa-3'
          },
          [ renderMainComponent.call(this, h) ]
        ),

        h(
          'g-card-actions',
          {
            class: 'fjcfe',
            slot: 'footer'
          },
          [
            h(
              'g-button',
              {
                props: {
                  flat: true,
                  rounded: true,
                  label: this.readonly ? this.getTranslate('misc.buttons.close') : this.getTranslate('misc.buttons.save'),
                  color: this.readonly ? undefined : 'primary'
                },
                on: {
                  click: () => {
                    this.$emit('dialog', false)
                  }
                }
              }
            )
          ]
        )
      ]
    )
  }
}
