import Vue from 'vue'

import { mapActions, mapGetters } from 'vuex'

import { touch } from '@/utils'

import render from './render'

export default {
  name: 'DefaultLayout',

  mixins: [ touch() ],

  data() {
    return {
      showSidebar: false
    }
  },

  computed: {
    ...mapGetters({ isLoggedAs: 'login/isLoggedAs' })
  },

  watch: {
    $route: {
      handler() {
        if (this.viewport.breakpoint.smDown) {
          this.showSidebar = false
        }
      },
      deep: true
    }
  },

  mounted() {
    Vue.$socket.on('users.updated', data => {
      if (this.account.id === data.id) {
        this.getAccount(this.account.id)
      }
    })
    Vue.$socket.on('settingsUser.updated', () => this.getGlobalSettings())
    Vue.$socket.on('settingsSite.updated', () => this.getGlobalSettings())

    this.showSidebar = this.viewport.breakpoint.mdUp
  },

  methods: {
    ...mapActions({
      loginAs: 'login/loginAs',
      logout: 'login/logout',
      getAccount: 'account/getAccount',
      getGlobalSettings: 'settings/getGlobalSettings'
    }),

    leftSwipeCallback() {
      this.showSidebar = false
    },
    rightSwipeCallback() {
      this.showSidebar = true
    }
  },

  render
}
