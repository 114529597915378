export const OUR_DOMAINS = [
  'localhost',
  '52.29.25.132',
  'devonline.sigmasms.ru',
  'stage.sigmasms.ru',
  'online.sigmasms.ru',
  'online.mirsms.ru',
  'online.freesender.net',
  'online.apisender.com'
]

export default { OUR_DOMAINS }
