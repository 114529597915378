import { unVue } from '@sigma-legacy-libs/unvue'

import { STATES, generateServices, globalErrorHandler, globalErrorProcessor } from '@/utils'

import { serviceName } from '@/components/services/messageRuleGroups/utils'

import render from './render'

function prefix(title) {
  return `[DIRECT] ${title}`
}

const defaultFilter = { title: undefined }

export default {
  name: 'CreateDirect',

  mixins: [
    generateServices([
      {
        name: serviceName,

        disableWatchers: true,

        find: {
          defaultFilter: unVue(defaultFilter)
        },

        get: false,
        update: false,
        remove: false
      },
      {
        name: 'messageRules',

        find: false,
        create: false,
        update: false,
        remove: false
      }
    ])
  ],

  props: {
    show: Boolean
  },

  data() {
    return {
      messageRuleId: undefined,
      messageRuleTitle: undefined
    }
  },

  computed: {
    loading() {
      return this.restData[serviceName].create.state === STATES.loading || this.restData.messageRules.get.state === STATES.loading
    },
    disabled() {
      return this.restData[serviceName].find.pagination.total > 0 || this.loading || !this.messageRuleId
    }
  },

  watch: {
    async messageRuleId() {
      if (this.messageRuleId) {
        const { title } = await this.rest.messageRules.get(this.messageRuleId)
        this.messageRuleTitle = title
        this.restData[serviceName].find.filter.title = `[DIRECT] ${title}`
        this.rest[serviceName].find()
      }
    }
  },

  mounted() {
    this.clear()
  },

  beforeDestroy() {
    this.clear()
  },

  methods: {
    clear() {
      this.messageRuleId = undefined
      this.messageRuleTitle = undefined
      this.restData[serviceName].find.data = undefined
      this.restData[serviceName].find.state = STATES.empty
      this.restData[serviceName].find.filter = unVue(defaultFilter)
    },

    async create() {
      try {
        const { id } = await this.rest[serviceName].create({
          title: prefix(this.messageRuleTitle),
          MessageRules: [
            {
              id: this.messageRuleId,
              priority: 0,
              $attach: true
            }
          ]
        })
        this.$emit('input', id)
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      } finally {
        this.clear()
        this.$emit('show', false)
      }
    }
  },

  render
}
