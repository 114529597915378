import titleDivider from '@/components/title/divider'
import providersProperties from '@/components/services/providers/properties'
import providersReplacements from '@/components/services/providers/replacements'

import { serviceName } from '@/components/services/providers/utils'

function renderTitleField(h) {
  return h(
    'div',
    {
      class: 'faic'
    },
    [
      h(
        'g-icon',
        {
          class: 'mr-3',
          props: {
            value: 'subtitles',
            color: 'grey'
          },
          directives: [
            {
              name: 'g-tooltip',
              options: { value: this.getTranslate(`${serviceName}.labels.title`) }
            }
          ]
        }
      ),

      h(
        'g-text-field',
        {
          class: 'mr-2',
          props: {
            value: this.proxy.title,
            label: this.getTranslate(`${serviceName}.labels.title`),
            mode: 'outline',
            dense: true,
            rounded: true,
            clearable: true,
            required: true,
            details: false,
            autofocus: true
          },
          on: {
            input: event => {
              this.proxy.title = event
            }
          }
        }
      ),

      h(
        'g-switch',
        {
          props: {
            value: this.proxy.isActive,
            disabled: this.disabled
          },
          directives: [
            {
              name: 'g-tooltip',
              options: { value: this.getTranslate(`${serviceName}.tooltips.isActive`) }
            }
          ],
          on: {
            input: event => {
              this.proxy.isActive = event
            }
          }
        }
      )
    ]
  )
}

function renderServerField(h) {
  const attrs = {}
  if (this.proxy.ServerId) {
    attrs.href = `#/admin/routes/servers/${this.proxy.ServerId}`
  }

  return h(
    'div',
    {
      class: { 'grid faic': true },
      style: { 'grid-template-columns': '24px 1fr' }
    },
    [
      h(
        'a',
        {
          attrs,
          class: 'link link--passive square--24',
          directives: [
            {
              name: 'g-tooltip',
              options: { value: this.getTranslate(`${serviceName}.labels.server`) }
            }
          ]
        },
        [
          h(
            'g-icon',
            {
              props: {
                value: 'vpn_lock',
                color: 'grey'
              }
            }
          )
        ]
      ),

      h(
        'g-select',
        {
          props: {
            value: this.proxy.ServerId,
            label: this.getTranslate(`${serviceName}.labels.server`),
            service: 'servers',
            picks: [ 'id', 'title', 'host', 'port' ],
            mode: 'outline',
            dense: true,
            rounded: true,
            autocomplete: true,
            clearable: true,
            details: false
          },
          on: {
            input: event => {
              this.proxy.ServerId = event
            }
          },
          scopedSlots: {
            items: ({ item }) => {
              return h(
                'div',
                {
                  class: 'fc'
                },
                [
                  h('div', { class: 'body-1' }, item.title),
                  h('div', { class: 'caption text--grey' }, [ item.host, item.port ].join(':'))
                ]
              )
            }
          }
        }
      )
    ]
  )
}

function renderCredentialsField(h) {
  const attrs = {}
  if (this.proxy.CredentialsId) {
    attrs.href = `#/admin/routes/credentials/${this.proxy.CredentialsId}`
  }

  return h(
    'div',
    {
      class: { 'grid faic': true },
      style: { 'grid-template-columns': '24px 1fr' }
    },
    [
      h(
        'a',
        {
          attrs,
          class: 'link link--passive square--24',
          directives: [
            {
              name: 'g-tooltip',
              options: { value: this.getTranslate(`${serviceName}.labels.credentials`) }
            }
          ]
        },
        [
          h(
            'g-icon',
            {
              props: {
                value: 'vpn_key',
                color: 'grey'
              }
            }
          )
        ]
      ),

      h(
        'g-select',
        {
          props: {
            value: this.proxy.CredentialsId,
            label: this.getTranslate(`${serviceName}.labels.credentials`),
            service: 'credentials',
            mode: 'outline',
            dense: true,
            rounded: true,
            autocomplete: true,
            clearable: true,
            details: false
          },
          on: {
            input: event => {
              this.proxy.CredentialsId = event
            }
          }
        }
      )
    ]
  )
}

function renderOtherFields(h) {
  return h(
    'div',
    {
      class: 'grid grid-cols--1'
    },
    [
      h(titleDivider, { props: { value: this.getTranslate(`${serviceName}.hints.other`) } }),

      h(
        'g-textarea',
        {
          props: {
            value: this.proxy.comment,
            label: this.getTranslate(`${serviceName}.labels.comment`),
            mode: 'outline',
            grow: true,
            rounded: true,
            details: false
          },
          on: {
            input: event => {
              this.proxy.comment = event
            }
          }
        }
      ),

      h(titleDivider, { props: { value: this.getTranslate(`${serviceName}.hints.properties.hint`) } }),

      h(
        providersProperties,
        {
          props: {
            value: this.proxy.properties,
            processor: this.proxy.Server && this.proxy.Server.processor
          },
          on: {
            input: event => {
              this.proxy.properties = event
            }
          }
        }
      ),

      h(titleDivider, { props: { value: this.getTranslate(`${serviceName}.hints.replacements.hint`) } }),

      h(
        providersReplacements,
        {
          props: { value: this.proxy.replacements },
          on: {
            input: event => {
              this.proxy.replacements = event
            }
          }
        }
      )
    ]
  )
}

function renderContent(h) {
  return h(
    'g-form',
    {
      class: 'grid',
      style: { 'grid-template-columns': '100%' }
    },
    [
      renderTitleField.call(this, h),
      renderServerField.call(this, h),
      renderCredentialsField.call(this, h),
      renderOtherFields.call(this, h)
    ]
  )
}

export default function(h) {
  return renderContent.call(this, h)
}
