export default {
  title: 'Tokens',

  titles: {
    create: 'Create token',
    edit: 'Edit description token'
  },

  labels: {
    token: 'Token',
    description: 'Description',
    search: 'Search',
    owner: 'Owner'
  },

  hints: {
    notFound: 'Tokens not found',
    description: 'Maximum 250 characters'
  },

  tooltips: {
    favorites: 'Make the token a favorite'
  },

  buttons: {
    create: 'Create new token'
  },

  snackbars: {
    created: 'Token successfully created.',
    updated: 'Token successfully updated.',
    removed: 'Token successfully removed.'
  }
}
