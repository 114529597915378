import OwnerSelect from '@/components/misc/OwnerSelect'
import PaymentsOffer from '@/components/services/payments/offer'

import { serviceName } from '@/components/services/payments/utils'

function renderMethodField(h) {
  if (this.methods.length > 1) {
    return h(
      'g-select',
      {
        props: {
          value: this.proxy.method,
          label: this.getTranslate(`${serviceName}.labels.method`),
          items: this.methods.map(method => ({
            title: this.getTranslate(`${serviceName}.methods.${method}`),
            value: method
          })),
          disabled: this.loading,
          mode: 'outline-label',
          details: false,
          rounded: true
        },
        on: {
          input: event => {
            this.proxy.method = event
          }
        }
      }
    )
  }
}

function renderAmountField(h) {
  return h(
    'g-text-field',
    {
      props: {
        value: this.proxy.amount,
        label: this.getTranslate(`${serviceName}.labels.amount`),
        type: 'number',
        suffix: this.commission ? `+${this.getCommission()}&#8381; ${this.getTranslate(`${serviceName}.hints.commissions`)}` : '',
        hint: this.commissionHint,
        disabled: this.loading,
        min: 1,
        step: 1,
        mode: 'outline-label',
        rounded: true,
        autofocus: true,
        name: 'amount',
        clearable: true
      },
      on: {
        input: event => {
          this.proxy.amount = event
        }
      }
    }
  )
}

function renderOwnerField(h) {
  return h(
    OwnerSelect,
    {
      class: 'mt-3',
      props: {
        value: this.proxy.OwnerId,
        hint: this.getTranslate(`${serviceName}.hints.owner`),
        mode: 'outline',
        rounded: true
      },
      on: {
        input: event => {
          this.proxy.OwnerId = event
        }
      }
    }
  )
}

function renderOffer(h) {
  return h(
    PaymentsOffer,
    {
      props: {
        value: this.proxy.acceptOffer,
        method: this.proxy.method
      },
      on: {
        input: event => {
          this.proxy.acceptOffer = event
        }
      }
    }
  )
}

export default function(h) {
  return h(
    'g-form',
    {
      class: 'grid grid-cols--1 grid-gap--8 ff'
    },
    [
      renderMethodField.call(this, h),
      renderAmountField.call(this, h),
      renderOwnerField.call(this, h),
      renderOffer.call(this, h)
    ]
  )
}
