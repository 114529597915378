const _ = { get: require('lodash/get') }

export default function(h) {
  const jivosite = _.get(this.globalSettings, 'frontend.jivosite')
  if (jivosite) {
    return h(
      'g-button',
      {
        class: 'mr-0',
        style: {
          display: this.viewport.breakpoint.smDown ? 'none' : 'inline-flex'
        },
        props: {
          label: this.viewport.breakpoint.mdUp ? this.getTranslate('misc.buttons.support') : undefined,
          icon: 'help',
          flat: true,
          rounded: true,
          color: 'white'
        },
        on: {
          click: () => {
            jivo_api.open() // eslint-disable-line
          }
        }
      }
    )
  }
}
