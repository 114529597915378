export default {
  title: 'Groups',

  one: 'Group',
  many: 'Groups',

  titles: {
    create: 'Create message rule group'
  },

  labels: {
    title: 'Title',
    search: 'Search',
    rule: 'Rule',
    group: 'Group'
  },

  dialogs: {
    new: 'New message rule group',
    types: {
      full: 'Full',
      direct: 'Direct'
    },
    title: {
      full: 'Full group',
      direct: 'Direct group'
    }
  },

  hints: {
    notFound: 'Message rule groups not found'
  },

  contents: {
    confirm: {
      remove: 'Confirm remove {title}?'
    }
  },

  tooltips: {
    isActive: 'Activate or deactivate a message rule group.',
    favorites: 'Make the message rule group a favorite'
  },

  subtitles: {
    rules: 'Rules',
    groups: 'Groups of rules message processing'
  },

  buttons: {
    add: 'Add rules group',
    messageRuleCreate: 'Create rule'
  },

  snackbars: {
    created: 'Message rule group successfully created.',
    updated: 'Message rule group successfully updated.',
    removed: 'Message rule group successfully removed.'
  }
}
