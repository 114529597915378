import SingleLink from '@/components/misc/SingleLink'
import salon from '@/components/services/yclients/item/salon'
import buttons from '@/components/services/yclients/item/buttons'

import { serviceName } from '@/components/services/yclients/utils'

function renderSalonCell(h) {
  return h(
    'td',
    {
      class: 'px-2'
    },
    [ h(salon, { props: { value: this.value } }) ]
  )
}

function renderTokenCell(h) {
  return h(
    'td',
    {
      class: 'px-2'
    },
    [
      h(
        SingleLink,
        {
          props: {
            value: this.value.Token || this.value.TokenId,
            pathToTitle: 'token',
            service: 'tokens'
          }
        }
      )
    ]
  )
}

function renderOwnerCell(h) {
  return h(
    'td',
    {
      class: 'px-2'
    },
    [
      h(
        SingleLink,
        {
          props: {
            value: this.value.OwnerId
          }
        }
      )
    ]
  )
}

function renderButtons(h) {
  return h(
    'td',
    {
      class: 'px-2 w--100'
    },
    [
      h(
        'div',
        {
          class: 'fjcfe'
        },
        [ h(buttons, { props: { value: this.value } }) ]
      )
    ]
  )
}

function renderFormattedDate(h, date) {
  if (date) {
    return h(
      'div',
      {
        class: 'text--grey text-right',
        style: { 'white-space': 'pre' },
        domProps: { innerHTML: date }
      }
    )
  }
}

function renderCreatedAtCell(h) {
  return h(
    'td',
    {
      class: 'pl-0 pr-2',
      directives: [
        {
          name: 'g-tooltip',
          options: {
            value: this.getTranslate(`${serviceName}.labels.createdAt`),
            placement: 'top'
          }
        }
      ]
    },
    [ renderFormattedDate.call(this, h, this.value.$formattedCreatedAt) ]
  )
}

function renderUpdatedAtCell(h) {
  return h(
    'td',
    {
      class: 'pl-0 pr-2',
      directives: [
        {
          name: 'g-tooltip',
          options: {
            value: this.getTranslate(`${serviceName}.labels.updatedAt`),
            placement: 'top'
          }
        }
      ]
    },
    [ renderFormattedDate.call(this, h, this.value.$formattedUpdatedAt) ]
  )
}

export default function(h) {
  return h(
    'tr',
    {
      class: 'hover-parent hover-parent--opacity'
    },
    [
      renderSalonCell.call(this, h),
      renderTokenCell.call(this, h),
      renderOwnerCell.call(this, h),
      renderButtons.call(this, h),
      renderCreatedAtCell.call(this, h),
      renderUpdatedAtCell.call(this, h)
    ]
  )
}
