import HeadTitle from '@/components/misc/HeadTitle'
import RefreshButton from '@/components/button/refresh'
import PaymentsItem from '@/components/services/payments/item'
import PaymentsFilter from '@/components/services/payments/filter'
import PaymentsLog from '@/components/services/paymentLogs/template'
import BillingsDetailsItem from '@/components/services/billings/details/item'
import pagination from '@/components/misc/pagination'

import { STATES, permissionPresets } from '@/utils'

const name = 'payments'

function renderPaymentLogsDialog(h) {
  if (this.checkPermissions('advanced.paymentLogs.get')) {
    return h(
      'g-dialog',
      {
        props: {
          rounded: this.viewport.breakpoint.mdUp,
          value: this.showPaymentLogsDialog,
          maxWidth: 500
        },
        on: {
          input: event => {
            if (event === false) {
              this.paymentId = undefined
            }
            this.showPaymentLogsDialog = event
          }
        }
      },
      [
        h(
          HeadTitle,
          {
            props: { value: this.getTranslate(`${name}.titles.paymentLogs`) },
            slot: 'header'
          }
        ),

        h(PaymentsLog({ PaymentId: this.paymentId })),

        h(
          'g-card-actions',
          {
            class: 'fjcfe facfe',
            slot: 'footer'
          },
          [
            h(
              'g-button',
              {
                props: {
                  label: this.getTranslate('misc.buttons.close'),
                  flat: true,
                  rounded: true
                },
                on: {
                  click: () => {
                    this.showPaymentLogsDialog = false
                  }
                }
              }
            )
          ]
        )
      ]
    )
  }
}

function renderBillingsList(h) {
  if (Array.isArray(this.paymentsBillings) && this.paymentsBillings.length) {
    return h(
      'g-list',
      {},
      this.paymentsBillings.map(billing => {
        return h(
          BillingsDetailsItem,
          {
            props: {
              value: billing,
              mode: 'list'
            }
          }
        )
      })
    )
  }
}

function renderBillingsDialog(h) {
  if (this.checkPermissions(`advanced.${name}.get`, permissionPresets.reseller)) {
    return h(
      'g-dialog',
      {
        props: {
          rounded: this.viewport.breakpoint.mdUp,
          value: this.showBillingsDialog,
          maxWidth: 500
        },
        on: {
          input: event => {
            if (event === false) {
              this.paymentsBillings = []
            }
            this.showBillingsDialog = event
          }
        }
      },
      [
        h(
          HeadTitle,
          {
            props: { value: this.getTranslate(`${name}.titles.billings`) },
            slot: 'header'
          }
        ),

        renderBillingsList.call(this, h),

        h(
          'g-card-actions',
          {
            class: 'fjcfe facfe',
            slot: 'footer'
          },
          [
            h(
              'g-button',
              {
                props: {
                  label: this.getTranslate('misc.buttons.close'),
                  flat: true,
                  rounded: true
                },
                on: {
                  click: () => {
                    this.showBillingsDialog = false
                  }
                }
              }
            )
          ]
        )
      ]
    )
  }
}

function renderRefreshButton(h, trigger) {
  if (trigger) {
    return h(
      RefreshButton,
      {
        props: {
          state: this.restData[name].find.state,
          shortClickMethod: () => this.rest[name].find(),
          longClickMethod: () => this.rest[name].find({}, { noCache: true })
        }
      }
    )
  }
}

function renderPagination(h, options, place) {
  let trigger = false
  switch (place) {
    case 'top':
      trigger = this.restData[name].find.pagination.total > 0
      break
    case 'bottom':
      trigger = this.restData[name].find.pagination.total >= 10
      break
  }

  return h(
    'div',
    {
      class: 'fjcfe facfe faic'
    },
    [
      h(
        pagination, {
          props: {
            value: this.restData[name].find.pagination,
            show: this.restData[name].find.pagination.total >= this.restData[name].find.pagination.limit
          },
          on: {
            input: event => {
              this.restData[name].find.pagination = event
            }
          }
        }
      ),

      renderRefreshButton.call(this, h, trigger)
    ]
  )
}

function renderFilter(h, options) {
  if (options.isPage) {
    return h(
      PaymentsFilter,
      {
        props: {
          value: this.restData[name].find.filter
        },
        on: {
          input: event => {
            this.restData[name].find.filter = event
          }
        }
      }
    )
  }
}

function renderPreloader(h) {
  return h(
    'div',
    {
      class: 'fjcc facc pa-3'
    },
    [ h('g-progress', { props: { indeterminate: true } }) ]
  )
}

function renderList(h) {
  if (this.restData[name].find.state === STATES.ready) {
    if (this.restData[name].find.pagination.total > 0) {
      return h(
        'g-expansion-panels',
        {
          props: {
            flat: true,
            preventClick: true
          }
        },
        this.restData[name].find.data.map(item => {
          return h(
            PaymentsItem,
            {
              props: { value: item },
              on: {
                billings: event => {
                  this.paymentsBillings = event
                  this.showBillingsDialog = true
                },
                paymentLogs: event => {
                  this.paymentId = event
                  this.showPaymentLogsDialog = true
                }
              }
            }
          )
        })
      )
    } else {
      return h('g-empty', { props: { title: this.getTranslate(`${name}.hints.notFound`) } })
    }
  } else if (this.restData[name].find.state === STATES.loading) {
    return renderPreloader.call(this, h)
  }
}

function renderDivider(h, trigger) {
  if (trigger) {
    return h('g-divider')
  }

  return h('div') // Не удаляй потому что перестанет рендериться всё что после этого компонента в основном рендере
}

export default function(h, options) {
  if (this.checkPermissions(`advanced.${name}.get`, [ 'me', 'reseller', true ])) {
    return h(
      'div',
      {
        class: 'fc ff'
      },
      [
        renderPaymentLogsDialog.call(this, h),
        renderBillingsDialog.call(this, h),

        renderFilter.call(this, h, options),

        renderDivider.call(this, h, options.isPage),

        renderPagination.call(this, h, options, 'top'),

        renderDivider.call(this, h, options.isPage && this.restData[name].find.pagination.total),

        renderList.call(this, h, options),

        renderDivider.call(this, h, options.isPage && this.restData[name].find.pagination.total),

        renderPagination.call(this, h, options, 'bottom')
      ]
    )
  }
}
