import { STATES, generateServices, globalErrorHandler, globalErrorProcessor } from '@/utils'
import { unVue } from '@sigma-legacy-libs/unvue'

import render from './render'

function prefix(title) {
  return `[DIRECT] ${title}`
}

const defaultFilter = {
  type: undefined,
  title: undefined
}

export default {
  name: 'RoutesCreateDirect',

  mixins: [
    generateServices([
      {
        name: 'routingGroups',

        disableWatchers: true,

        find: {
          defaultFilter: unVue(defaultFilter)
        },

        get: false,
        update: false,
        remove: false
      },
      {
        name: 'routingRules',

        find: false,
        get: false,
        update: false,
        remove: false
      },
      {
        name: 'providerGroups',

        find: false,
        get: false,
        update: false,
        remove: false
      },
      {
        name: 'providers',

        find: false,
        create: false,
        update: false,
        remove: false
      }
    ])
  ],

  props: {
    value: Boolean,
    type: String
  },

  data() {
    return {
      provider: undefined,
      providerTitle: undefined
    }
  },

  computed: {
    loading() {
      return this.restData.routingGroups.create.state === STATES.loading ||
      this.restData.routingRules.create.state === STATES.loading ||
      this.restData.providerGroups.create.state === STATES.loading ||
      this.restData.providers.get.state === STATES.loading
    },
    disabled() {
      return this.restData.routingGroups.find.pagination.total > 0 || this.loading || !this.provider
    }
  },

  watch: {
    async provider() {
      if (this.provider) {
        const { title } = await this.rest.providers.get(this.provider)
        this.providerTitle = title
        this.restData.routingGroups.find.filter.type = this.type
        this.restData.routingGroups.find.filter.title = `[DIRECT] ${title}`
        this.rest.routingGroups.find()
      }
    }
  },

  mounted() {
    this.clear()
  },

  beforeDestroy() {
    this.clear()
  },

  methods: {
    clear() {
      this.provider = undefined
      this.providerTitle = undefined
      this.restData.routingGroups.find.data = undefined
      this.restData.routingGroups.find.state = STATES.empty
      this.restData.routingGroups.find.filter = unVue(defaultFilter)
    },

    async create() {
      try {
        const providerGroup = await this.rest.providerGroups.create({
          title: prefix(this.providerTitle),
          type: this.type,
          Providers: [
            {
              id: this.provider,
              priority: 0,
              $attach: true
            }
          ]
        })
        const routingRule = await this.rest.routingRules.create({
          title: prefix(this.providerTitle),
          type: this.type,
          ProviderGroups: [
            {
              id: providerGroup.id,
              percentage: 100,
              $attach: true
            }
          ]
        })
        const routingGroup = await this.rest.routingGroups.create({
          title: prefix(this.providerTitle),
          type: this.type,
          RoutingRules: [
            {
              id: routingRule.id,
              priority: 0,
              $attach: true
            }
          ]
        })
        this.$emit('input', routingGroup.id)
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      } finally {
        this.clear()
        this.$emit('showDialog', false)
      }
    }
  },

  render
}
