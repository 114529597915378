import proxy from '@sigma-legacy-libs/g-proxy'
import generatePassword from 'generate-password'

import { isPhoneNumber } from '@sigma-legacy-libs/essentials/lib/validators'

import { isValidEmail, isValidPassword } from '@/utils'

import render from './render'

export default {
  name: 'RegistrationForm',

  mixins: [ proxy({ type: 'object' }) ],

  props: {
    errors: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      passwordVisibility: true
    }
  },

  computed: {
    passwordValidation() {
      if (this.proxy.password && !isValidPassword(this.proxy.password)) {
        return this.getTranslate('errors.types.invalid', { field: this.getTranslate('registration.labels.password').toLowerCase() })
      }
    },
    emailValidation() {
      if (this.proxy.data.email && !isValidEmail(this.proxy.data.email)) {
        return this.getTranslate('errors.types.email', { field: this.getTranslate('registration.labels.email').toLowerCase() })
      }
    },
    phoneValidation() {
      if (this.proxy.data.phone && !isPhoneNumber(this.proxy.data.phone).isValid) {
        return this.getTranslate('errors.types.phoneNumber', { field: this.getTranslate('registration.labels.phone').toLowerCase() })
      }
    },

    validation() {
      return !!(
        isValidPassword(this.proxy.password) &&
        isValidEmail(this.proxy.data.email) &&
        isPhoneNumber(this.proxy.data.phone).isValid
      )
    }
  },

  methods: {
    passwordGenerator() {
      this.passwordVisibility = false
      this.proxy.password = generatePassword.generate({
        length: 8,
        numbers: true,
        symbols: false,
        uppercase: true,
        strict: true
      })
    }
  },

  render
}
