import render from './render'

export default function(options) {
  return {
    name: 'OperatorsPrices',

    render(h) {
      return render.call(this, h, options)
    }
  }
}
