import { catchSymbolsMenus } from '@/utils'

import ExpandArrow from '@/components/misc/ExpandArrow'
import SendingsCreateForm from '@/components/services/sendingsCreate/form'

const _ = { get: require('lodash/get') }

function renderHeader(h, data, expanded) {
  if (data) {
    const type = _.get(data, 'type', '')
    const sender = _.get(data, 'payload.sender', '')

    return h(
      'div',
      {
        class: 'default-expansion-panel-header pr-0'
      },
      [
        this.getTranslate('sendings.headers.sending'),
        h('div', { class: 'pl-1' }, type.toUpperCase()),
        h('div', { class: 'text--grey pl-1' }, sender),
        h('div', { class: 'ff' }),
        h(ExpandArrow, { props: { expanded } })
      ]
    )
  }
}

function renderRemoveButton(h, index) {
  if (!this.disabled || !this.readonly) {
    return h(
      'div',
      {
        class: 'fjcfe facfe'
      },
      [
        h(
          'g-button',
          {
            props: {
              label: this.getTranslate('misc.buttons.remove'),
              flat: true,
              rounded: true,
              disabled: this.disabled,
              color: 'error'
            },
            on: {
              click: () => {
                this.remove(index)
                this.$nextTick(() => {
                  const panel = this.$refs[`fallback-${index - 1}`]
                  if (panel) {
                    panel.toggle()
                    document.getElementById(`fallback-${index - 1}`).scrollIntoView({ behavior: 'smooth' })
                  }
                })
              }
            }
          }
        )
      ]
    )
  }
}

function renderFooter(h) {
  if (!this.disabled || !this.readonly) {
    return h(
      'div',
      {
        class: 'fjcfe facfe'
      },
      [
        h(
          'g-button',
          {
            class: {
              'mb-0': true,
              'mt-0': this.flat
            },
            props: {
              label: this.getTranslate('misc.buttons.fallback'),
              icon: 'add',
              rounded: true,
              outline: true,
              depressed: true,
              disabled: this.disabled,
              color: 'primary'
            },
            on: {
              click: () => {
                this.add()
              }
            }
          }
        )
      ]
    )
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: 'fc'
    },
    [
      h(
        'g-expansion-panels',
        {
          props: {
            flat: this.flat,
            outline: this.outline,
            rounded: this.rounded
          }
        },
        [
          h(
            'g-expansion-panel',
            {
              scopedSlots: {
                header: ({ expanded }) => renderHeader.call(this, h, this.proxy, expanded)
              }
            },
            [
              h(
                SendingsCreateForm,
                {
                  class: 'pl-3 pr-3 pb-3',
                  props: {
                    value: this.proxy,
                    types: this.sendingTypes,
                    tags: catchSymbolsMenus.$,
                    customMenu: catchSymbolsMenus.custom.$,
                    disabled: this.disabled,
                    readonly: this.readonly
                  }
                }
              )
            ]
          ),

          ...this.proxy.fallbacks.map((item, index) => {
            return h(
              'g-expansion-panel',
              {
                attrs: { id: `fallback-${index}` },
                on: {
                  mounted: event => {
                    this.$nextTick(() => {
                      event.toggle()
                      document.getElementById(`fallback-${index}`).scrollIntoView({ behavior: 'smooth' })
                    })
                  }
                },
                scopedSlots: {
                  header: ({ expanded }) => renderHeader.call(this, h, item, expanded)
                },
                ref: `fallback-${index}`,
                key: `fallback-${index}`
              },
              [
                this.$slots.default || this.$scopedSlots.default && this.$scopedSlots.default({ value: item }),

                renderRemoveButton.call(this, h, index)
              ]
            )
          })
        ]
      ),

      renderFooter.call(this, h)
    ]
  )
}
