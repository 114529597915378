import globalSnackbar from '@/components/global/snackbar'
import paymentResult from '@/components/services/payments/result'

export default function(h) {
  return h(
    this.layout,
    {},
    [
      h('router-view'),
      h('g-tooltip'),

      h(globalSnackbar),
      h(paymentResult)
    ]
  )
}
