import Vue from 'vue'
import stringify from 'json-stable-stringify'

import { unVue } from '@sigma-legacy-libs/unvue'

import * as EMPTY from '@sigma-legacy-libs/essentials/lib/constants/empty'

import { STATES, mergeReplacingArrays } from '@/utils'

import { globalErrorHandler, globalErrorProcessor } from './errorProcessors'
import { generateDefaultWebsocketFindEvents } from './webSocketsEvents'

const _ = {
  debounce: require('lodash/debounce'),
  defaultsDeep: require('lodash/defaultsDeep'),
  isEqual: require('lodash/isEqual'),
  isPlainObject: require('lodash/isPlainObject'),
  flatten: require('lodash/flatten'),
  compact: require('lodash/compact')
}

function getEmptyPayload(serviceName = '') {
  if (EMPTY) {
    return unVue(EMPTY[`EMPTY_${serviceName.toUpperCase()}`])
  }
}

function defaultGlobalErrorProcessor(errors) {
  return globalErrorProcessor.call(this.ctx, errors)
}
function defaultLocalErrorProcessor(errors) {
  return errors
}
function generateDefaultLocalErrorProcessor(/* restMethod = '' */) {
  return defaultLocalErrorProcessor
}

function defaultGlobalErrorHandler(errors) {
  return errors.map(error => globalErrorHandler.call(this.ctx, error))
}
function defaultLocalErrorHandler(restMethod = '', errors = []) {
  if (!Array.isArray(errors)) {
    errors = [ errors ]
  }

  if (restMethod && this[`${restMethod}Data`]) {
    this[`${restMethod}Data`].errors = errors.reduce((result, error) => {
      if (error.type === 'field') {
        result[error.field] = error.translate
      }

      return result
    }, {})
  }
}
function generateDefaultLocalErrorHandler(restMethod = '') {
  return function generatedDefaultLocalErrorHandler(errors) {
    return defaultLocalErrorHandler.call(this, restMethod, errors)
  }
}

function processRedirect(redirect, params = {}, defaultPath = false) {
  switch (typeof redirect) {
    case 'function':
      redirect.call(this.ctx)
      break
    case 'string':
    case 'boolean': {
      if (typeof redirect === 'boolean') {
        redirect = defaultPath || this.path
      }
      if (this.ctx && this.ctx.$router) {
        this.ctx.$router.push({
          name: redirect,
          params
        })
      }
      break
    }
  }
}

const restMethods = [ 'get', 'find', 'update', 'patch', 'create', 'remove' ]

class Service {
  constructor(options = {}) {
    this.options = {
      name: options.name,
      as: options.as || options.name,
      path: options.path || options.name,

      version: options.version || 1,

      idField: options.id || options.idField || 'id',

      emptyPayload: options.emptyPayload || getEmptyPayload(options.name) || {},

      inputFilter: options.inputFilter || (v => v),
      outputFilter: options.outputFilter || (v => v),

      disableWatchers: options.disableWatchers || false,

      cacher: options.cacher || new Map(),

      errorProcessor: options.errorProcessor || defaultGlobalErrorProcessor,
      errorHandler: options.errorHandler || defaultGlobalErrorHandler,

      context: options.context || this
    }

    if (!this.options.name) {
      throw new Error('Service name is mandatory')
    }

    if (_.isPlainObject(options.get)) {
      this.options.get = Object.assign(
        {
          method: () => {
            throw new Error(`Method get for service ${this.options.name} is not defined`)
          },
          params: {},
          useCache: true,
          errorProcessor: generateDefaultLocalErrorProcessor('get'),
          errorHandler: generateDefaultLocalErrorHandler('get'),
          manipulateData: true,
          manipulateState: true,
          paramsSanitizer: v => v
        },
        options.get
      )

      this.options.get.useCache = !!this.options.get.useCache
      this.options.get.manipulateData = !!this.options.get.manipulateData

      this.getData = {
        state: STATES.empty,
        data: undefined,
        errors: {}
      }
    } else if (options.get === false) {
      this.options.get = false
    }

    if (_.isPlainObject(options.find)) {
      const setMeta = () => {
        this.findData.filterIsEqualToDefault = _.isEqual(this.options.find.defaultFilter, this.findData.filter)
        this.findData.filterIsEqualToLastFilter = _.isEqual(this.options.find.lastFilter, this.findData.filter)
        this.findData.paginationIsEqualToLastPagination = this.options.find.lastPagination.offset === this.findData.pagination.offset && this.options.find.lastPagination.limit === this.findData.pagination.limit
        this.options.find.lastFilter = unVue(this.findData.filter)
        this.options.find.lastPagination = unVue(this.findData.pagination)
      }
      const debouncedFind = _.debounce(
        () => {
          this.find()
        },
        500,
        {
          leading: false,
          trailing: true
        }
      )

      this.options.find = Object.assign(
        {
          method: () => {
            throw new Error(`Method find for service ${this.options.name} is not defined`)
          },
          params: {},
          useCache: true,
          errorProcessor: generateDefaultLocalErrorProcessor('find'),
          errorHandler: generateDefaultLocalErrorHandler('find'),
          manipulateData: true,
          manipulateState: true,
          websocketEvents: generateDefaultWebsocketFindEvents(options),
          paramsSanitizer: v => v,
          defaultFilter: {},
          defaultOrder: options.find.defaultOrder || { createdAt: 'desc' },
          defaultPagination: {},
          lastFilter: {},
          lastPagination: {},
          disableWatcherPagination: options.disableWatcherPagination || false,
          disableWatcherFilter: options.disableWatcherFilter || false,
          disableWatcherOrder: options.disableWatcherOrder || false,
          watchers: [
            [
              function() {
                return (
                  this.restData[options.as || options.name].find.pagination.offset +
                  '-' +
                  this.restData[options.as || options.name].find.pagination.limit
                )
              },
              () => {
                setMeta()
                if (!this.options.find.disableWatcherPagination) {
                  debouncedFind()
                }
              },
              {
                deep: true
              }
            ],
            [
              function() {
                return this.restData[options.as || options.name].find.filter
              },
              () => {
                setMeta()
                if (!this.options.find.disableWatcherFilter) {
                  if (this.options.find.lastPagination.offset !== 0) {
                    this.ctx.restData[options.as || options.name].find.pagination.offset = 0
                  } else {
                    debouncedFind()
                  }
                }
              },
              {
                deep: true
              }
            ],
            [
              function() {
                return this.restData[options.as || options.name].find.order
              },
              () => {
                setMeta()
                if (!this.options.find.disableWatcherOrder) {
                  debouncedFind()
                }
              },
              {
                deep: true
              }
            ]
          ],
          appendMode: false,
          bucketEnabled: false,
          bucketMaxLength: 25,
          alwaysCreateFromWebSocket: false,
          alwaysUpdateFromWebSocket: false,
          alwaysRemoveFromWebSocket: false
        },
        options.find
      )

      this.options.find.useCache = !!this.options.find.useCache
      this.options.find.manipulateData = !!this.options.find.manipulateData

      this.bucket = []

      this.findData = {
        state: STATES.empty,
        filter: unVue(this.options.find.defaultFilter),
        filterIsEqualToDefault: true,
        filterIsEqualToLastFilter: true,
        paginationIsEqualToLastPagination: true,
        bucketLength: 0,
        data: [],
        pagination: Object.assign(
          {
            offset: 0,
            limit: 25,
            total: 0
          },
          unVue(this.options.find.defaultPagination)
        ),
        order: unVue(this.options.find.defaultOrder),
        errors: {}
      }

      this.options.find.lastFilter = unVue(this.findData.filter)
      this.options.find.lastPagination = unVue(this.findData.pagination)
    } else if (options.find === false) {
      this.options.find = false
    }

    if (_.isPlainObject(options.update)) {
      this.options.update = Object.assign(
        {
          method: () => {
            throw new Error(`Method update for service ${this.options.name} is not defined`)
          },
          params: {},
          redirect: false,
          errorProcessor: generateDefaultLocalErrorProcessor('update'),
          errorHandler: generateDefaultLocalErrorHandler('update'),
          manipulateData: true,
          paramsSanitizer: v => v
        },
        options.update
      )

      this.options.update.manipulateData = !!this.options.update.manipulateData

      this.updateData = {
        state: STATES.ready,
        isValid: false,
        errors: {}
      }
    } else if (options.update === false) {
      this.options.update = false
    }

    if (_.isPlainObject(options.create)) {
      this.options.create = Object.assign(
        {
          method: () => {
            throw new Error(`Method create for service ${this.options.name} is not defined`)
          },
          params: {},
          redirect: false,
          errorProcessor: generateDefaultLocalErrorProcessor('create'),
          errorHandler: generateDefaultLocalErrorHandler('create'),
          manipulateData: true,
          paramsSanitizer: v => v
        },
        options.create
      )

      this.options.create.manipulateData = !!this.options.create.manipulateData

      this.createData = {
        state: STATES.ready,
        data: undefined,
        isValid: false,
        errors: {}
      }
    } else if (options.create === false) {
      this.options.create = false
    }

    if (_.isPlainObject(options.remove)) {
      this.options.remove = Object.assign(
        {
          method: () => {
            throw new Error(`Method remove for service ${this.options.name} is not defined`)
          },
          params: {},
          redirect: false,
          errorProcessor: generateDefaultLocalErrorProcessor('remove'),
          errorHandler: generateDefaultLocalErrorHandler('remove'),
          manipulateData: true,
          paramsSanitizer: v => v
        },
        options.remove
      )

      this.options.remove.manipulateData = !!this.options.remove.manipulateData

      this.removeData = {
        state: STATES.ready,
        errors: {}
      }
    } else if (options.remove === false) {
      this.options.remove = false
    }

    if (this.options.cacher && typeof this.options.cacher.wrapWithCache === 'function') {
      if (this.options.get && this.options.get.useCache) {
        const oldGet = this.options.get.method
        const cachedGet = this.options.cacher.wrapWithCache((key, id, params) => {
          return oldGet.call(this, id, params)
        })
        this.options.get.method = (id, params = {}, options = {}) => {
          const key = `get:${this.options.as}:${id}:${stringify(params)}`

          if (options && options.noCache && this.options.cacher.delete) {
            this.options.cacher.delete(key)
          }

          return cachedGet(key, id, params)
        }
      }

      if (this.options.find && this.options.find.useCache) {
        const oldFind = this.options.find.method
        const cachedFind = this.options.cacher.wrapWithCache((key, params) => {
          return oldFind.call(this, params)
        })
        this.options.find.method = (params = {}, options = {}) => {
          const key = `find:${this.options.as}:${stringify(params)}`

          if (options && options.noCache && this.options.cacher.delete) {
            this.options.cacher.delete(key)
          }

          return cachedFind(key, params)
        }
      }
    }
  }

  get emptyPayload() {
    return unVue(this.options.emptyPayload)
  }

  get name() {
    return this.options.name
  }

  get version() {
    return this.options.version
  }

  get as() {
    return this.options.as
  }

  get path() {
    return this.options.path
  }

  get idField() {
    return this.options.idField
  }

  get ctx() {
    return this.options.context
  }

  set ctx(value) {
    this.options.context = value
  }

  get websocketEvents() {
    const events = []

    restMethods.forEach(restMethod => {
      if (this.options[restMethod] && Array.isArray(this.options[restMethod].websocketEvents)) {
        this.options[restMethod].websocketEvents.forEach(e => {
          if (e.event && typeof e.handler === 'function') {
            events.push(e)
          }
        })
      }
    })

    return events
  }

  get watchers() {
    const watchers = []

    restMethods.forEach(restMethod => {
      if (this.options[restMethod] && Array.isArray(this.options[restMethod].watchers)) {
        this.options[restMethod].watchers.forEach(([ what, handler, params ]) => {
          if (
            what &&
            handler &&
            (typeof what === 'string' || typeof what === 'function') &&
            typeof handler === 'function' &&
            !this.options.disableWatchers
          ) {
            const watcher = [ typeof what === 'string' ? what : what.bind(this.ctx), handler.bind(this.ctx) ]
            if (params && params.deep) {
              watcher.push({ deep: true })
            }
            watchers.push(watcher)
          }
        })
      }
    })

    return watchers
  }

  async get(id, params = {}, options = {}) {
    if (this.options.get === false) {
      return
    }

    options = mergeReplacingArrays({}, this.options.get, options || {})

    try {
      if (options && options.manipulateState) {
        this.getData.state = STATES.loading
      }

      this.getData.errors = {}

      if (typeof options.paramsSanitizer === 'function') {
        params = options.paramsSanitizer.call(this, params)
      }

      const result = await options.method.call(this, id, params, options)
      const cleanResult = await this.options.inputFilter.call(this.ctx, unVue(result))

      if (options.manipulateData) {
        this.getData.data = cleanResult
      }

      return cleanResult
    } catch (error) {
      let processedErrors = await this.options.errorProcessor.call(this, _.flatten([ error ]))
      processedErrors = await options.errorProcessor(processedErrors)
      this.options.errorHandler.call(this, processedErrors)
      options.errorHandler.call(this, processedErrors)
    } finally {
      if (options && options.manipulateState) {
        this.getData.state = STATES.ready
      }
    }

    return false
  }

  async find(params = {}, options = {}) {
    if (this.options.find === false) {
      return
    }

    options = mergeReplacingArrays({}, this.options.find, options || {})

    try {
      if (options && options.manipulateState) {
        this.findData.state = STATES.loading
      }

      this.findData.errors = {}

      if (typeof options.paramsSanitizer === 'function') {
        params = options.paramsSanitizer.call(this, params)
      }

      let { data: result, total } = await options.method.call(this, params, options)

      result = await Promise.all(
        result.map(async item => {
          if (this.options && this.options.inputFilter) {
            item = await this.options.inputFilter.call(this.ctx, item)
          }
          if (options && options.inputFilter) {
            item = await options.inputFilter.call(this.ctx, item)
          }

          return item
        })
      )

      if (options.manipulateData) {
        if (
          options.appendMode &&
          this.findData.filterIsEqualToLastFilter &&
          !this.findData.paginationIsEqualToLastPagination
        ) {
          this.findData.data.push(...result)
        } else {
          this.findData.data = result
        }

        this.findData.pagination.total = total
        this.bucket = []
      }

      return result
    } catch (error) {
      let processedErrors = await this.options.errorProcessor.call(this, _.flatten([ error ]))
      processedErrors = await options.errorProcessor(processedErrors)
      this.options.errorHandler.call(this, processedErrors)
      options.errorHandler.call(this, processedErrors)
    } finally {
      if (options && options.manipulateState) {
        this.findData.state = STATES.ready
      }
    }

    return false
  }

  async bucketRelease() {
    if (this.findData.state === STATES.loading || this.options.find === false || this.bucket.length === 0) {
      return
    }

    if (this.findData.filterIsEqualToDefault) {
      this.findData.state = STATES.loading
      Promise.all(this.bucket.map(item => this.options.inputFilter.call(this.ctx, item))).then(filteredBucket => {
        this.findData.data = [ ...filteredBucket, ...this.findData.data ].slice(0, this.findData.pagination.limit)
        this.findData.state = STATES.ready
      })
    }

    this.bucket = []
    this.findData.bucketLength = 0
  }

  async create(data = {}, params = {}) {
    if (this.options.create === false) {
      return
    }

    try {
      this.createData.state = STATES.loading
      this.createData.errors = {}

      if (typeof this.options.create.paramsSanitizer === 'function') {
        params = this.options.create.paramsSanitizer.call(this, params)
      }

      let cleanData = unVue(data)
      cleanData = await this.options.outputFilter.call(this.ctx, cleanData)

      const result = await this.options.create.method.call(this, cleanData, params)

      if (this.options.create.redirect) {
        processRedirect.call(
          this,
          this.options.create.redirect,
          {
            [this.idField]: result[this.idField]
          },
          `${this.path.replace(/\/*$/, '')}.single`
        )
      }
      if (this.options.create.manipulateData) {
        this.createData.data = await this.options.inputFilter.call(this.ctx, this.emptyPayload)
      }

      Vue.$bus.emit(`rest.${this.name}.created`, this.name, 'created', cleanData)

      return result
    } catch (error) {
      let processedErrors = await this.options.errorProcessor.call(this, _.flatten([ error ]))
      processedErrors = await this.options.create.errorProcessor(processedErrors)
      this.options.errorHandler.call(this, processedErrors)
      this.options.create.errorHandler.call(this, processedErrors)
    } finally {
      this.createData.state = STATES.ready
    }

    return false
  }

  async update(id, data = {}, params) {
    if (this.options.update === false) {
      return
    }

    if (typeof id !== 'string' && !params) {
      params = data
      data = id
      id = data && data[this.idField]
    }

    try {
      this.updateData.state = STATES.loading
      this.updateData.errors = {}

      if (typeof this.options.update.paramsSanitizer === 'function') {
        params = this.options.update.paramsSanitizer.call(this, params)
      }

      let cleanData = unVue(data)
      cleanData = await this.options.outputFilter.call(this.ctx, cleanData)

      const result = await this.options.update.method.call(this, id, cleanData, params)
      const cleanResult = await this.options.inputFilter.call(this.ctx, result)

      if (this.options.update.manipulateData) {
        this.getData.data = cleanResult
      }

      Vue.$bus.emit(`rest.${this.name}.updated`, this.name, 'updated', cleanResult)

      return cleanResult
    } catch (error) {
      let processedErrors = await this.options.errorProcessor.call(this, _.flatten([ error ]))
      processedErrors = await this.options.update.errorProcessor(processedErrors)
      this.options.errorHandler.call(this, processedErrors)
      this.options.update.errorHandler.call(this, processedErrors)
    } finally {
      this.updateData.state = STATES.ready
    }

    return false
  }

  async patch(id, data = {}, params = {}) {
    if (this.options.update === false) {
      return
    }

    if (typeof id !== 'string' && !params) {
      params = data
      data = id
      id = data && data[this.idField]
    }

    try {
      this.updateData.state = STATES.loading
      this.updateData.errors = {}

      if (typeof this.options.update.paramsSanitizer === 'function') {
        params = this.options.update.paramsSanitizer.call(this, params)
      }

      const cleanData = unVue(data)
      const result = await this.options.update.method.call(this, id, cleanData, params)

      return result
    } catch (error) {
      let processedErrors = await this.options.errorProcessor.call(this, _.flatten([ error ]))
      processedErrors = await this.options.update.errorProcessor(processedErrors)
      this.options.errorHandler.call(this, processedErrors)
      this.options.update.errorHandler.call(this, processedErrors)
    } finally {
      this.updateData.state = STATES.ready
    }

    return false
  }

  async remove(id, params = {}) {
    if (this.options.remove === false) {
      return
    }

    try {
      this.removeData.state = STATES.loading
      this.removeData.errors = {}

      if (typeof this.options.remove.paramsSanitizer === 'function') {
        params = this.options.remove.paramsSanitizer.call(this, params)
      }

      const result = await this.options.remove.method.call(this, id, params)

      if (this.options.remove.redirect) {
        processRedirect.call(this, this.options.remove.redirect)
      } else if (this.options.remove.manipulateData) {
        this.getData.data = undefined
      }

      Vue.$bus.emit(`rest.${this.name}.removed`, this.name, 'removed', id)

      return result
    } catch (error) {
      let processedErrors = await this.options.errorProcessor.call(this, _.flatten([ error ]))
      processedErrors = await this.options.remove.errorProcessor(processedErrors)
      this.options.errorHandler.call(this, processedErrors)
      this.options.remove.errorHandler.call(this, processedErrors)
    } finally {
      this.removeData.state = STATES.ready
    }

    return false
  }

  init(ctx) {
    this.ctx = ctx

    const setEmptyPayload = emptyPayload => {
      if (this.options.create) {
        this.createData.data = emptyPayload
      }
    }

    if (this.options.inputFilter) {
      const filteredEmptyPayload = this.options.inputFilter.call(this.ctx, this.emptyPayload)
      if (typeof filteredEmptyPayload.then === 'function') {
        filteredEmptyPayload.then(emptyPayload => {
          setEmptyPayload(emptyPayload)
        })
      } else {
        setEmptyPayload(filteredEmptyPayload)
      }
    }
  }
}

export default Service
