export default {
  title: 'Компании',

  titles: {
    create: 'Создать компанию'
  },

  labels: {
    title: 'Название компании',
    vat: 'НДС',
    owner: 'Владелец'
  },

  tooltips: {
    favorites: 'Сделать компанию избранной'
  },

  vat: {
    true: 'С НДС',
    false: 'Без НДС'
  },

  hints: {
    notFound: 'Компании не найдены'
  },

  snackbars: {
    created: 'Компания успешно создана.',
    updated: 'Компания успешно обновлена.',
    removed: 'Компания успешно удалена.'
  }
}
