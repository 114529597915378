import messageTypeIcon from '@/components/message/type/icon'

export default function(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8',
      style: { gridTemplateColumns: '16px minmax(min-content, max-content) 1fr 16px minmax(min-content, max-content)' }
    },
    [
      h(
        messageTypeIcon,
        {
          props: {
            value: this.value.ParentTariff.type.split('.')[1],
            size: 16
          }
        }
      ),

      h(
        'div',
        {
          class: 'text-overflow'
        },
        [ this.value.ParentTariff.title ]
      ),

      h(
        'g-icon',
        {
          props: {
            value: 'arrow_right',
            size: 16
          }
        }
      ),

      h(
        messageTypeIcon,
        {
          props: {
            value: this.value.ChildTariff.type.split('.')[1],
            size: 16
          }
        }
      ),

      h(
        'div',
        {
          class: 'text-overflow'
        },
        [ this.value.ChildTariff.title ]
      )
    ]
  )
}
