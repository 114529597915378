function renderCount(h) {
  if (this.isMany) {
    return h(
      'div',
      {
        class: 'tree__count ml-1',
        directives: [
          {
            name: 'g-tooltip',
            options: {
              value: this.values.reduce((result, { mcc, mnc, country, operator }, index) => {
                if (index < 20) {
                  if (country && operator) {
                    result.push(`${country} | ${operator}`)
                  } else {
                    result.push(`${mcc} | ${mnc}`)
                  }
                }

                return result
              }, []).join('\n')
            }
          }
        ]
      },
      `+${this.values.length - this.count}`
    )
  }
}

function renderCutValue(h, value) {
  if (typeof value === 'string') {
    if (value.length > 20) {
      return h(
        'span',
        {
          directives: [
            {
              name: 'g-tooltip',
              options: { value }
            }
          ]
        },
        value.slice(0, 20) + '...'
      )
    }

    return value
  }
}

function renderValue(h, { mcc, mnc, country, operator }) {
  if (country && operator) {
    return h(
      'div',
      {
        class: 'faic text-nowrap'
      },
      [
        renderCutValue.call(this, h, country),
        h('span', { class: 'mx-1' }, '|'),
        renderCutValue.call(this, h, operator)
      ]
    )
  }

  return h('div', {}, `${mcc} | ${mnc}`)
}

function renderValues(h) {
  return this.values.reduce((result, item, index) => {
    if (index > 0 && index < this.count) {
      result.push(h('span', { class: 'mr-1' }, ','))
    }
    if (index < this.count) {
      result.push(renderValue.call(this, h, item))
    }

    return result
  }, [])
}

function renderPreloader(h) {
  if (this.loading) {
    return h(
      'g-progress',
      {
        class: 'ml-1',
        props: {
          type: 'circular',
          color: 'grey',
          indeterminate: true,
          width: 1,
          size: 16
        }
      }
    )
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: {
        faic: true,
        link: this.isMany
      },
      on: {
        click: () => {
          if (this.isMany) {
            this.$emit('click', true)
          }
        }
      }
    },
    [
      renderValues.call(this, h),
      renderCount.call(this, h),
      renderPreloader.call(this, h)
    ]
  )
}
