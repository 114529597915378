import grafanaRange from '@/components/services/grafana/range'
import providersItemTitle from '@/components/services/providers/item/title'
import providersItemServer from '@/components/services/providers/item/server'
import providersItemCredentials from '@/components/services/providers/item/credentials'

function renderTitle(h) {
  return h(
    providersItemTitle,
    {
      props: {
        value: this.value,
        edit: true
      }
    }
  )
}

function renderDescription(h) {
  if (~[ 'medium', 'large' ].indexOf(this.size)) {
    return h(
      'div',
      {
        class: 'grid grid-gap--8 grid-cols--2 w--100'
      },
      [
        h(providersItemServer, { props: { value: this.value } }),
        h(providersItemCredentials, { props: { value: this.value } })
      ]
    )
  }
}

function renderChart(h) {
  if (~[ 'large' ].indexOf(this.size)) {
    return h(
      grafanaRange,
      {
        props: {
          data: { id: this.value.id },
          presets: [ 'queue', 'speed', 'count', 'delivery_rate' ].map(name => {
            return {
              name,
              label: this.getTranslate(`providers.chart.labels.${name === 'delivery_rate' ? 'deliveryRate' : name}`),
              preset: `providers_${name}`
            }
          }),
          hasFilter: true,
          noData: {
            icon: {
              show: true,
              library: undefined,
              value: 'bar_chart',
              size: 64
            }
          }
        }
      }
    )
  }
}

export default function(h) {
  return h(
    'g-card',
    {
      class: 'hover-parent hover-parent--opacity grid grid-gap--8 pa-2',
      props: {
        outline: true,
        rounded: true,
        height: this.size === 'large' ? 400 : undefined
      }
    },
    [
      renderTitle.call(this, h),
      renderDescription.call(this, h),
      renderChart.call(this, h)
    ]
  )
}
