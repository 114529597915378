function renderPresets(h) {
  return this.rangePresets.map(({ title, date }) => {
    return h(
      'g-list-item',
      {
        class: 'text-upper',
        props: { label: this.getTranslate(title) },
        on: {
          click: () => {
            this.proxy.date = date
          }
        }
      }
    )
  })
}

export default function(h) {
  return h(
    'g-menu',
    {
      props: {
        rounded: true,
        placement: 'bottom-end',
        closeOnContentClick: false
      }
    },
    [
      h(
        'g-button',
        {
          props: {
            icon: 'date_range',
            flat: true,
            rounded: true
          },
          slot: 'activator'
        }
      ),

      h(
        'div',
        {
          class: {
            fr: this.viewport.breakpoint.smUp,
            'fc ff': this.viewport.breakpoint.xs
          }
        },
        [
          h(
            'g-list',
            {
              style: {
                'background-color': '#f5f5f5',
                'border-top-left-radius': '16px',
                'border-top-right-radius': this.viewport.breakpoint.smDown ? '16px' : undefined,
                'border-bottom-left-radius': this.viewport.breakpoint.mdUp ? '16px' : undefined
              },
              class: 'overflow--hidden'
            },
            [ renderPresets.call(this, h) ]
          ),

          h(
            'g-date-picker',
            {
              props: {
                value: this.proxy.date,
                localeTag: this.locale
              },
              on: {
                input: event => {
                  this.proxy.date = event
                }
              }
            }
          )
        ]
      )
    ]
  )
}
