import proxy from '@sigma-legacy-libs/g-proxy'

import { generateServices, globalErrorHandler, globalErrorProcessor } from '@/utils'

import render from './render'

const name = 'storage'

export default {
  name: 'FileDialog',

  mixins: [
    proxy({ type: 'object' }),
    generateServices({
      name,

      find: false,
      create: false
    })
  ],

  props: {
    show: Boolean,
    type: String,
    googleFileURL: String
  },

  data() {
    return {
      loading: false,

      showEditExpireMenu: false
    }
  },

  methods: {
    hideDialog(event = false) {
      this.loading = false
      this.$emit('show', event)
    },

    async updateFile() {
      if (this.proxy.title) {
        try {
          await this.rest[name].update(this.proxy)
        } catch (error) {
          globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
        } finally {
          this.hideDialog()
        }
      }
    },

    async removeFile() {
      if (this.proxy.id) {
        try {
          await this.rest[name].remove(this.proxy.id)
        } catch (error) {
          globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
        } finally {
          this.hideDialog()
        }
      }
    }
  },

  render
}
