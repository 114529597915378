import Vue from 'vue'
import proxy from '@sigma-legacy-libs/g-proxy'

import { globalErrorHandler, globalErrorProcessor } from '@/utils'

import render from './render'

const _ = { debounce: require('lodash/debounce') }

export default {
  name: 'UsersAssociationsByType',

  mixins: [ proxy({ type: 'object' }) ],

  props: {
    services: {
      type: Array,
      default: () => [ 'sendernames', 'patterns' ]
    }
  },

  data() {
    return {
      type: undefined,

      checks: this.services.reduce((result, service) => {
        result[service] = {}

        return result
      }, {})
    }
  },

  computed: {
    types() {
      return this.getSendingTypesByPermission('advanced.users.update')
    }
  },

  async mounted() {
    await Promise.all(this.services.map(async service => {
      this.types.reduce((result, { value }) => {
        result[value] = false

        return result
      }, this.checks[service])

      await this.getSetting(service)
    }))

    this.type = this.types[0].value
  },

  methods: {
    async getSetting(service) {
      try {
        const { data } = await Vue.$GRequest.get('settingsUser', `${this.proxy.id}/sendings.${service}Check`)
        if (data) {
          for (const type of data) {
            Vue.set(this.checks[service], type, true)
          }
        }
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      }
    },

    updateSetting: _.debounce(
      async function(service) {
        try {
          const value = []
          for (const type in this.checks[service]) {
            if (this.checks[service][type] === true && !~value.indexOf(type)) {
              value.push(type)
            }
          }

          await Vue.$GRequest.update('settingsUser', `${this.proxy.id}/sendings.${service}Check`, { value })
        } catch (error) {
          globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
        }
      },
      100,
      {
        leading: false,
        trailing: true
      }
    )
  },

  render
}
