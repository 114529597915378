import proxy from '@sigma-legacy-libs/g-proxy'

import { unVue } from '@sigma-legacy-libs/unvue'

import render from './render'

import { serviceName } from '@/components/services/apisender/utils'

const _ = {
  get: require('lodash/get'),
  isEqual: require('lodash/isEqual')
}

export default {
  name: 'Form',

  mixins: [ proxy({ type: 'object' }) ],

  props: {
    isNew: Boolean,

    updatedData: null
  },

  data() {
    return {
      showDialog: false,
      initProxy: undefined
    }
  },

  computed: {
    validation() {
      return !!this.priceByTariff
    },
    priceByTariff() {
      const tariffs = unVue(this.account.Tariffs)
      tariffs.sort((a, b) => a.priority - b.priority)
      const tariff = tariffs.find(({ source }) => source === `subscription.${serviceName}`)

      // FIXME: переписать под разные source новых тарифов
      return _.get(tariff, 'usersTariffs.finalRules[0].price[0]', false)
    },
    proxyChanged() {
      return !_.isEqual(this.proxy, this.initProxy)
    }
  },

  watch: {
    validation() {
      this.$emit('validation', this.validation)
    },

    updatedData: {
      handler() {
        this.initProxy = unVue(this.proxy)
      },
      deep: true
    }
  },

  mounted() {
    this.initProxy = unVue(this.proxy)
    this.$emit('validation', this.validation)
  },

  render
}
