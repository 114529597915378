import { generateServices, globalErrorHandler, globalErrorProcessor, permissionPresets } from '@/utils'

import { userTariffsInputFilter } from '@/components/services/tariffs/utils'
import { serviceName } from '@/components/services/users/utils'

import render from './render'

export default {
  name: 'Template',

  mixins: [
    generateServices({
      name: serviceName,

      inputFilter(result) {
        if (!Array.isArray(result.Tariffs)) {
          result.Tariffs = []
        }

        result.Tariffs = result.Tariffs
          .map(item => userTariffsInputFilter.call(this, item))
          .sort((a, b) => a.priority - b.priority)

        return result
      },

      outputFilter(result) {
        return Object.keys(result).reduce((res, key) => {
          if (!key[0].match(/[A-Z]/)) {
            res[key] = result[key]
          }

          return res
        }, {})
      },

      find: false,
      create: false,
      remove: false,
      get: {
        params: {
          query: {
            $scope: [ 'full' ]
          }
        }
      },
      update: {
        params: {
          query: {
            $scope: [ 'full' ]
          }
        }
      }
    })
  ],

  computed: {
    title() {
      const data = this.restData[serviceName].get.data

      return [ data.data.firstName, data.data.lastName ].filter(item => !!item).join(' ') || data.username
    }
  },

  mounted() {
    if (this.checkPermissions(`advanced.${serviceName}.get`, permissionPresets.me)) {
      this.rest[serviceName].get(this.account.id)
    }
  },

  methods: {
    async update() {
      if (this.checkPermissions(`advanced.${serviceName}.update`, permissionPresets.me)) {
        try {
          await this.rest[serviceName].update(this.restData[serviceName].get.data.id, this.restData[serviceName].get.data)
        } catch (error) {
          globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
        }
      }
    }
  },

  render
}
