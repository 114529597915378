import form from '@/components/services/subscriptions/form'
import card from '@/components/services/subscriptions/item/card'

import { STATES } from '@/utils'

function renderDialog(h) {
  return h(
    'g-dialog',
    {
      props: {
        value: this.showDialog,
        rounded: true,
        width: 500
      },
      on: {
        input: value => {
          this.showDialog = value
        }
      }
    },
    [
      h(
        'div',
        {
          class: 'pa-2'
        },
        [
          h(
            form,
            {
              props: {
                value: this.restData.subscriptions.create.data,
                sendingType: this.value.type
              },
              on: {
                validation: event => {
                  this.restData.subscriptions.create.isValid = event
                },
                input: event => {
                  this.restData.subscriptions.create.data = event
                }
              }
            }
          )
        ]
      ),

      h(
        'div',
        {
          class: 'grid grid-gap--8 pa-2',
          style: { gridTemplateColumns: '1fr repeat(2, minmax(min-content, max-content))' },
          slot: 'footer'
        },
        [
          h('div'),
          h(
            'g-button',
            {
              class: 'ma-0',
              props: {
                flat: true,
                rounded: true,
                label: this.getTranslate('misc.buttons.cancel')
              },
              on: {
                click: () => {
                  this.showDialog = false
                }
              }
            }
          ),
          h(
            'g-button',
            {
              class: 'ma-0',
              props: {
                depressed: true,
                rounded: true,
                loading: this.restData.subscriptions.create.state === STATES.loading,
                disabled: !this.restData.subscriptions.create.isValid || this.restData.subscriptions.create.state === STATES.loading,
                color: 'primary',
                label: this.getTranslate('misc.buttons.create')
              },
              on: {
                click: async () => {
                  await this.rest.subscriptions.create(this.restData.subscriptions.create.data)
                  this.showDialog = false
                }
              }
            }
          )
        ]
      )
    ]
  )
}

function renderContent(h) {
  if (this.subscription) {
    return h(
      card,
      {
        props: {
          value: this.subscription
        }
      }
    )
  } else {
    if (this.loading) {
      return h(
        'g-progress',
        {
          props: {
            indeterminate: true
          }
        }
      )
    }

    return h(
      'div',
      {
        class: 'fjcc'
      },
      [
        h(
          'g-button',
          {
            class: 'ma-0',
            props: {
              flat: true,
              rounded: true,
              color: 'primary',
              label: this.getTranslate('subscriptions.buttons.add')
            },
            on: {
              click: () => {
                this.showDialog = true
              }
            },
            scopedSlots: {
              dialog: () => renderDialog.call(this, h)
            }
          }
        )
      ]
    )
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8'
    },
    [ renderContent.call(this, h) ]
  )
}
