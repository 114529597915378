export default {
  title: 'Permissions',

  subtitles: {
    presets: 'Presets'
  },

  dialogs: {
    titles: {
      info: 'Information by level of permissions'
    }
  },

  labels: {
    create: 'Create',
    finalize: 'Finalize',
    find: 'Find',
    generate: 'Generate',
    set: 'Set',
    get: 'Get',
    remove: 'Remove',
    update: 'Update',
    modify: 'Modify',
    seek: 'Seek',
    download: 'Download',
    upload: 'Upload',
    setPermissions: 'Set permissions',
    setRoutingGroups: 'Set routing groups',
    setSettings: 'Set settings',
    setSendernames: 'Set sendernames',
    setPatterns: 'Set patterns',
    setTariffs: 'Set tariffs',
    showSecrets: 'Show secret permissions',
    health: 'Health',
    loginAs: 'Login as',
    request: 'Request',
    controlDocuments: 'Control documents',
    edit: 'Edit',
    viewChangelogs: 'View changelogs',
    contracts: 'Contracts',
    companies: 'Companies',
    documentTemplates: 'Document templates',
    refund: 'Refund payment',
    presetUsersBalance: 'Download user list'
  },

  hints: {
    false: 'Prohibits any actions that require corresponding permission.',
    me: 'Allows actions with objects owned by considered user.',
    manager: 'Allows actions with objects that belong to the user himself, or to users tied to the manager.',
    reseller: 'Allows actions with objects owned by considered user or any attached user.',
    true: 'Allows any actions with any object in system.',
    warning: 'Changing permissions does not require user update.'
  },

  tooltips: {
    info: 'Information by level of permissions'
  },

  buttons: {
    false: 'False',
    me: 'Пользователь',
    reseller: 'Реселлер'
  },

  services: {
    billings: 'Billings',
    dictionaries: 'Dictionaries',
    providerGroups: 'Provider groups',
    providers: 'Providers',
    routingGroups: 'Routing groups',
    routingRules: 'Routing rules',
    sendings: 'Sendings',
    storage: 'Storage',
    tariffs: 'Tariffs',
    users: 'Users',
    platform: 'Platform',
    reports: 'Reports',
    components: 'Components',
    tasks: 'Tasks',
    contacts: 'Contacts',
    payment: 'Payment',
    settings: 'Site settings',
    messageProcessors: 'Message processors',
    templates: 'Templates',
    patterns: 'Patterns',
    sendernames: 'Sendernames',
    requisites: 'Requisites',
    sendingsIncoming: 'Incoming messages',
    changelogs: 'Changelogs',
    contracts: 'Contracts',
    companies: 'Companies',
    documentTemplates: 'Document templates',
    operatorGroups: 'Operator groups',
    secret: 'Secret',
    payments: 'Payments',
    paymentLogs: 'Payment logs',
    recurrentPayments: 'Recurrent payments',
    settingsSite: 'Settings site',
    settingsUser: 'Settings user',
    servers: 'Servers',
    credentials: 'Credentials',
    tokens: 'Tokens',
    apisender: 'API sender',
    messageRules: 'Message rules',
    messageRuleGroups: 'Message rule groups',
    keywords: 'Keywords',
    yclients: 'YClients'
  },

  snackbars: {
    updated: 'Permission successfully updated'
  }
}
