import buttonCopy from '@/components/button/copy'

export default function(h) {
  if (this.value || this.error) {
    return h(
      'div',
      {
        class: {
          'hint-row': true,
          'hint-row--error': !!this.error,
          'fjcfe facfe': this.right
        }
      },
      [
        h(
          'g-icon',
          {
            props: { value: this.error ? 'error_outline' : this.icon }
          }
        ),

        h(
          'div',
          {
            class: 'hint-row__text'
          },
          [ this.value || this.error ]
        ),

        this.error && this.copy ? h(
          buttonCopy,
          {
            props: {
              value: this.value || this.error,
              color: 'white',
              marginless: true
            }
          }
        ) : undefined
      ]
    )
  }
}
