import Vue from 'vue'

import { unVue } from '@sigma-legacy-libs/unvue'
import { Macro } from '@sigma-legacy-libs/cache'
import { isUUID } from '@sigma-legacy-libs/essentials/lib/validators'

import { globalErrorHandler, globalErrorProcessor } from '@/utils'

const Cache = new Macro({
  ttl: 5 * 1000,
  ttlInterval: 1000
})

const cachedFind = Cache.wrapWithCache(async (key, service, params) => {
  return await Vue.$GRequest.find(service, params)
})
const cachedGet = Cache.wrapWithCache(async (key, service, id) => {
  return await Vue.$GRequest.get(service, id)
})

const _ = {
  isEqual: require('lodash/isEqual'),
  merge: require('lodash/merge'),
  debounce: require('lodash/debounce'),
  set: require('lodash/set'),
  get: require('lodash/get')
}

export default {
  props: {
    requestItems: {
      type: Function,
      async default() {
        let result = []

        const params = {
          query: _.merge(
            {
              $offset: 0,
              $limit: 25,
              $order: [ [ 'createdAt', 'desc' ] ],
              $search: this.searchValue || undefined
            },
            this.query
          )
        }
        const key = [ this.service, this.account ? this.account.id : 'user', JSON.stringify(params) ].join(':')

        const { data } = await cachedFind(key, this.service, params)
        if (data && Array.isArray(data.data) && data.data.length) {
          result = data.data
        }

        return result
      }
    },
    requestItem: {
      type: Function,
      async default(id) {
        const key = [ this.service, this.account ? this.account.id : 'user', id ].join(':')

        const { data } = await cachedGet(key, this.service, id)

        return data
      }
    },

    service: String,

    query: {
      type: Object,
      default: () => ({})
    },

    clearItems: {
      type: Function,
      default: v => v
    },
    searchValidation: {
      type: Function,
      default: () => true
    },

    picks: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      _proxyQuery: unVue(this.query),
      _searchLocally: this.autocomplete && !this.service,

      _queryHandler: _.debounce(
        function() {
          this.findAll()
        },
        200,
        {
          leading: false,
          trailing: true
        }
      ),
      _searchValueHandler: _.debounce(
        function() {
          this.findAll()
        },
        200,
        {
          leading: false,
          trailing: true
        }
      )
    }
  },

  computed: {
    $itemValue: {
      get() {
        return this.itemValue || (this.service ? 'id' : this._data._itemValue)
      },
      set(value) {
        this._data._itemValue = !!value
      }
    }
  },

  watch: {
    searchValue() {
      this._data._searchValueHandler.call(this)
    },

    query() {
      if (!_.isEqual(this.query, this._proxyQuery)) {
        this._data._queryHandler.call(this)
      }
      this._proxyQuery = unVue(this.query)
    },

    service() {
      if (this.service === undefined) {
        this._data._items = undefined
      }
      this.findAll()
    }
  },

  async mounted() {
    await this.findAll()
    this._receiveValue()
  },

  methods: {
    _checkSearchValidity(title, value, item) {
      const search = this.searchValue.toLocaleLowerCase()

      title = ('' + title).toLocaleLowerCase()
      value = ('' + value).toLocaleLowerCase()

      const checkTitle = !!~title.indexOf(search)
      const checkValue = !!~value.indexOf(search)

      return checkTitle || checkValue || this.searchValidation(this.searchValue, item)
    },

    _clearSelectedItem(item) {
      return this.picks.reduce(
        (result, path) => {
          _.set(result, path, _.get(item, path))

          return result
        },
        {
          [this.$itemTitle]: _.get(item, this.$itemTitle, item),
          [this.$itemValue]: _.get(item, this.$itemValue, item)
        }
      )
    },
    _clearDisplayItem(item, index) {
      return this.picks.reduce((result, path) => {
        _.set(result, path, _.get(item, path))

        return result
      }, this._clearItem(item, index))
    },
    _compareValues(valueA, valueB) {
      return _.isEqual(valueA, valueB)
    },

    async _getItemByValue(value) {
      this.isLoading = true

      const itemValue = _.get(value, this.$itemValue, value)

      let output = {
        [this.$itemTitle]: _.get(value, this.$itemTitle, value),
        [this.$itemValue]: itemValue
      }

      if (this.service && isUUID(itemValue)) {
        try {
          output = await this.requestItem.call(this, itemValue)
        } catch (error) {
          globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
        }
      }

      this.isLoading = false

      return output
    },

    async findAll() {
      if (this.service) {
        try {
          this.isLoading = true
          const result = await this.requestItems.call(this)
          if (result) {
            this._data._items = await this.clearItems.call(this, result)
            this.$nextTick(() => {
              this._receiveValue()
            })
          }
        } catch (error) {
          globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
        } finally {
          this.$emit('itemsFetched', this._data._items)
          this.isLoading = false
        }
      }
    }
  }
}
