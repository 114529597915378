import { getUrlToStorage } from '@/utils'

const _ = { get: require('lodash/get') }

const name = 'registration'

function renderPrivacyPolicy(h) {
  const privacyPolicy = _.get(this.globalSettings, 'frontend.documents.privacyPolicy')
  if (privacyPolicy) {
    return h(
      'div',
      {
        class: 'faic caption'
      },
      [
        h(
          'a',
          {
            attrs: {
              href: getUrlToStorage([ privacyPolicy ])
            },
            class: 'link link--dashed'
          },
          this.getTranslate('settingsSite.titles.privacyPolicy')
        )
      ]
    )
  }
}

function renderPublicOffer(h) {
  const publicOffer = _.get(this.globalSettings, 'frontend.documents.publicOffer')
  if (publicOffer) {
    return h(
      'div',
      {
        class: 'faic caption pt-1'
      },
      [
        h(
          'a',
          {
            attrs: {
              href: getUrlToStorage([ publicOffer ])
            },
            class: 'link link--dashed'
          },
          this.getTranslate('settingsSite.titles.publicOffer')
        )
      ]
    )
  }
}

export default function(h) {
  return h(
    'g-form',
    {
      class: 'grid grid-cols--1',
      props: { autocomplete: 'off' },
      on: {
        submit: () => {
          this.$emit('submit', this.proxy)
        },
        validation: event => {
          this.$emit('validation', this.validation && event)
        }
      }
    },
    [
      h(
        'g-text-field',
        {
          props: {
            value: this.proxy.username,
            label: this.getTranslate(`${name}.labels.username`),
            hint: this.getTranslate(`${name}.hints.username`),
            error: this.errors.username,
            name: 'username',
            mode: 'outline',
            rounded: true,
            autofocus: true,
            required: true,
            maxlength: 25,
            tabindex: 1
          },
          on: {
            input: event => {
              this.proxy.username = event
            }
          }
        }
      ),

      h(
        'div',
        {
          class: 'faic'
        },
        [
          h(
            'g-text-field',
            {
              props: {
                value: this.proxy.password,
                label: this.getTranslate(`${name}.labels.password`),
                error: this.errors.password || this.passwordValidation,
                mode: 'outline',
                rounded: true,
                details: false,
                name: 'password',
                autocomplete: 'new-password',
                afterIcon: this.passwordVisibility ? 'visibility' : 'visibility_off',
                afterIconCallback: () => {
                  this.passwordVisibility = !this.passwordVisibility
                },
                clearable: true,
                type: this.passwordVisibility ? 'password' : 'text',
                required: true,
                maxlength: 25,
                tabindex: 2
              },
              on: {
                input: event => {
                  this.proxy.password = event
                }
              }
            }
          ),

          h(
            'g-button',
            {
              class: 'mr-0',
              props: {
                icon: 'settings_backup_restore',
                flat: true,
                tabindex: -1
              },
              on: {
                click: () => {
                  this.passwordGenerator()
                }
              }
            }
          )
        ]
      ),

      h('div'),

      h(
        'g-text-field',
        {
          props: {
            value: this.proxy.data.firstName,
            label: this.getTranslate(`${name}.labels.firstName`),
            error: _.get(this.errors, 'data.firstName'),
            mode: 'outline',
            rounded: true,
            details: false,
            required: true,
            maxlength: 25,
            tabindex: 3
          },
          on: {
            input: event => {
              this.proxy.data.firstName = event
            }
          }
        }
      ),
      h(
        'g-text-field',
        {
          props: {
            value: this.proxy.data.lastName,
            label: this.getTranslate(`${name}.labels.lastName`),
            error: _.get(this.errors, 'data.lastName'),
            mode: 'outline',
            rounded: true,
            details: false,
            maxlength: 25,
            tabindex: 3
          },
          on: {
            input: event => {
              this.proxy.data.lastName = event
            }
          }
        }
      ),

      h(
        'g-text-field',
        {
          props: {
            value: this.proxy.data.email,
            label: this.getTranslate(`${name}.labels.email`),
            hint: this.getTranslate(`${name}.hints.email`),
            error: _.get(this.errors, 'data.email') || this.emailValidation,
            type: 'email',
            mode: 'outline',
            rounded: true,
            required: true,
            maxlength: 25,
            tabindex: 4
          },
          on: {
            input: event => {
              this.proxy.data.email = event
            }
          }
        }
      ),

      h(
        'g-text-field',
        {
          props: {
            value: this.proxy.data.phone,
            label: this.getTranslate(`${name}.labels.phone`),
            hint: this.getTranslate(`${name}.hints.phone`),
            error: _.get(this.errors, 'data.phone') || this.phoneValidation,
            type: 'tel',
            mode: 'outline',
            rounded: true,
            details: false,
            required: true,
            maxlength: 25,
            tabindex: 5
          },
          on: {
            input: event => {
              this.proxy.data.phone = event
            }
          }
        }
      ),

      h('div'),

      h(
        'g-text-field',
        {
          props: {
            value: this.proxy.data.meta.usedReferralCode,
            label: this.getTranslate(`${name}.labels.usedReferralCode`),
            error: _.get(this.errors, 'data.meta.usedReferralCode'),
            mode: 'outline',
            rounded: true,
            details: false,
            tabindex: 6
          },
          on: {
            input: event => {
              this.proxy.data.meta.usedReferralCode = event
            }
          }
        }
      ),

      renderPrivacyPolicy.call(this, h),
      renderPublicOffer.call(this, h)
    ]
  )
}
