import CreateButton from '@/components/misc/CreateButtonForSearchGenerator'

function renderSearchField(h, options) {
  return h(
    'g-text-field',
    {
      class: 'mb-2',
      props: {
        value: this.restData[options.name].find.filter.$search,
        label: this.getTranslate('commons.labels.search'),
        afterIcon: 'search',
        name: 'search',
        suffix: this.restData[options.name].find.pagination.total,
        loading: this.restData[options.name].find.state === 'loading',
        defaultValue: '',
        clearable: true,
        mode: 'outline',
        rounded: true,
        dense: true,
        details: false
      },
      on: {
        input: event => {
          this.restData[options.name].find.filter.$search = event
        }
      }
    }
  )
}

function renderFilterIsActive(h, options) {
  if (options.filterByIsActive) {
    return h(
      'g-switch',
      {
        class: 'mr-2',
        props: {
          value: this.isActive,
          falseValue: true,
          trueValue: false
        },
        on: {
          input: event => {
            this.isActive = event
            if (event === false) {
              this.restData[options.name].find.filter.isActive = undefined
            } else {
              this.restData[options.name].find.filter.isActive = true
            }
          }
        },
        directives: [
          {
            name: 'g-tooltip',
            options: { value: this.getTranslate('commons.filters.isActive') }
          }
        ]
      }
    )
  }
}

function renderFilterSlot(h, options) {
  if (typeof this.$scopedSlots.filter === 'function') {
    return this.$scopedSlots.filter({ filter: this.restData[options.name].find.filter })
  }

  return this.$slots.filter
}

function renderFilterButton(h) {
  if (this.$slots.filter || typeof this.$scopedSlots.filter === 'function') {
    return h(
      'g-button',
      {
        class: 'ml-0',
        props: {
          flat: true,
          icon: 'filter_list'
        },
        on: {
          click: () => {
            this.showFilter = !this.showFilter
          }
        }
      }
    )
  }
}

function renderFilter(h, options) {
  if (this.showFilter) {
    return h(
      'div',
      {
        class: 'search-generator__filter'
      },
      [ renderFilterSlot.call(this, h, options) ]
    )
  }
}

function renderCreateButton(h, options) {
  if (!options.excludeCreate) {
    if (typeof this.$scopedSlots.create === 'function') {
      return this.$scopedSlots.create()
    } else {
      return h(
        CreateButton(options),
        {
          props: { maxWidth: this.maxWidth },
          scopedSlots: { titleCreate: this.$scopedSlots.titleCreate }
        }
      )
    }
  }
}

function renderReportSlot() {
  if (typeof this.$scopedSlots.report === 'function') {
    return this.$scopedSlots.report()
  }
}

function renderEmpty(h, options) {
  if (!this.restData[options.name].find.pagination.total) {
    return h('g-empty', { props: { title: this.getTranslate('misc.emptyList') } })
  }
}

function renderLoadMoreButton(h, options) {
  if (this.showLoadMore) {
    return h(
      'div',
      {
        class: 'fjcc facc'
      },
      [
        h(
          'g-button',
          {
            class: 'ma-3',
            props: {
              label: this.getTranslate('misc.loadMore'),
              flat: true,
              rounded: true,
              loading: this.restData[options.name].find.state === 'loading',
              disabled: this.restData[options.name].find.state === 'loading' || !this.showLoadMore,
              color: 'primary'
            },
            on: {
              click: () => {
                if (this.restData[options.name].find.state === 'ready' && this.showLoadMore) {
                  this.restData[options.name].find.pagination.offset += this.restData[options.name].find.pagination.limit
                }
              }
            }
          }
        )
      ]
    )
  }
}

function renderActions(h, options) {
  return h(
    'div',
    {
      class: 'search-generator__actions'
    },
    [
      renderFilterIsActive.call(this, h, options),
      renderFilterButton.call(this, h, options),
      renderReportSlot.call(this, h),

      h('div', { class: 'ff' }),

      renderCreateButton.call(this, h, options)
    ]
  )
}

function renderScrollContent(h, options) {
  return h(
    'div',
    {
      class: 'search-generator__scroll',
      on: { scroll: this.onScroll }
    },
    [
      h(
        'g-list',
        {
          props: {
            dense: options.dense,
            transparent: true
          }
        },
        this.restData[options.name].find.data.map(item => {
          if (typeof this.$scopedSlots.default === 'function') {
            return this.$scopedSlots.default({ item })
          }
        })
      ),

      renderEmpty.call(this, h, options),
      renderLoadMoreButton.call(this, h, options)
    ]
  )
}

function renderContent(h, options) {
  return h(
    'div',
    {
      class: 'search-generator__content'
    },
    [
      renderSearchField.call(this, h, options),
      renderActions.call(this, h, options),
      renderFilter.call(this, h, options),
      renderScrollContent.call(this, h, options)
    ]
  )
}

export default function(h, options) {
  if (options.show || this.computedShowSearch) {
    return h(
      'div',
      {
        class: 'search-generator'
      },
      [ renderContent.call(this, h, options) ]
    )
  }
}
