import { TASK_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import { serviceName } from '@/components/services/tasks/utils'

export default function(h) {
  if (this.value.type === TASK_TYPES['sendings:bulk']) {
    if (this.checkPermissions(`advanced.${serviceName}.update`)) {
      return h(
        'g-menu',
        {
          class: 'mx-2',
          props: {
            closeOnContentClick: false,
            placement: 'bottom-start',
            offsetDistance: -51,
            rounded: true
          }
        },
        [
          h(
            'g-button',
            {
              props: {
                label: [ this.proxy.data.cycle.batch, this.proxy.data.cycle.every ].join(' / '),
                flat: true,
                rounded: true,
                color: 'primary'
              },
              slot: 'activator'
            }
          ),

          h(
            'div',
            {
              class: 'faic pa-2'
            },
            [
              h(
                'div',
                {
                  class: 'grid grid-gap--8',
                  style: 'grid-template-columns: repeat(2, 70px)'
                },
                [
                  h(
                    'g-text-field',
                    {
                      props: {
                        value: this.proxy.data.cycle.batch,
                        label: this.getTranslate(`${serviceName}.labels.cycle.batch`),
                        type: 'number',
                        step: 'any',
                        mode: 'outline',
                        dense: true,
                        rounded: true,
                        autofocus: true,
                        details: false
                      },
                      on: {
                        input: event => {
                          this.proxy.data.cycle.batch = event
                        }
                      }
                    }
                  ),

                  h(
                    'g-text-field',
                    {
                      props: {
                        value: this.proxy.data.cycle.every,
                        label: this.getTranslate(`${serviceName}.labels.cycle.every`),
                        type: 'number',
                        step: 'any',
                        mode: 'outline',
                        dense: true,
                        rounded: true,
                        details: false
                      },
                      on: {
                        input: event => {
                          this.proxy.data.cycle.every = event
                        }
                      }
                    }
                  )
                ]
              ),

              h(
                'g-button',
                {
                  class: 'my-0 mr-0',
                  props: {
                    icon: 'keyboard_arrow_right',
                    depressed: true,
                    loading: this.loading,
                    disabled: this.loading,
                    color: 'primary'
                  },
                  on: {
                    click: () => {
                      this.updateTask()
                    }
                  }
                }
              )
            ]
          )
        ]
      )
    }
  }
}
