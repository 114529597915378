import tree from '@/components/trees/mccmnc'
import prices from '@/components/services/tariffRatesUpdating/form/exceptions/prices'

import { serviceName } from '@/components/services/tariffRatesUpdating/utils'

function renderTree(h) {
  return h(
    tree,
    {
      props: {
        value: this.dataForTree
      },
      on: {
        input: event => {
          this.dataForTree = event
        }
      }
    }
  )
}

function renderDialogTree(h) {
  return h(
    'g-dialog',
    {
      props: {
        value: this.showDialogTree,
        rounded: true,
        maxWidth: 500
      },
      on: {
        input: event => {
          this.showDialogTree = event
        }
      }
    },
    [
      h(
        'div',
        {
          class: 'pa-2'
        },
        [ renderTree.call(this, h) ]
      ),

      h(
        'div',
        {
          class: 'fjcfe',
          slot: 'footer'
        },
        [
          h(
            'g-button',
            {
              props: {
                flat: true,
                rounded: true,
                label: this.getTranslate('misc.buttons.close')
              },
              on: {
                click: () => {
                  this.showDialogTree = false
                }
              }
            }
          )
        ]
      )
    ]
  )
}

function renderButtonTree(h) {
  return h(
    'g-button',
    {
      class: 'ma-0',
      props: {
        flat: true,
        rounded: true,
        label: this.getTranslate(`${serviceName}.buttons.operators`)
      },
      on: {
        click: () => {
          this.showDialogTree = true
        }
      },
      scopedSlots: {
        dialog: () => renderDialogTree.call(this, h)
      }
    }
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8 border border--dashed border--rounded pa-2'
    },
    [
      h(
        'div',
        {
          class: 'caption text--grey text-center'
        },
        this.getTranslate(`${serviceName}.labels.exceptions`)
      ),

      h(
        'div',
        {
          class: 'faic'
        },
        [ renderButtonTree.call(this, h) ]
      ),

      h(
        prices,
        {
          props: {
            value: this.proxy
          },
          on: {
            input: event => {
              this.proxy = event
            }
          }
        }
      )
    ]
  )
}
