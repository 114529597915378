import { serviceName } from '@/components/services/recover/utils'

const _ = { get: require('lodash/get') }

const maxWidth = 380

function renderPlatformTitle(h) {
  const title = _.get(this.globalSettings, 'frontend.title')
  if (title) {
    return h('div', { class: 'headline text--grey' }, title)
  }
}

function renderHeader(h) {
  return h(
    'div',
    {
      class: 'faic px-3'
    },
    [
      h('div', { class: 'headline ff' }, this.getTranslate(`${serviceName}.title`)),

      renderPlatformTitle.call(this, h)
    ]
  )
}

function renderValidationResult(h) {
  if (this.validation === true && this.state === 'request') {
    return h(
      'div',
      {
        class: 'faic pa-3'
      },
      [
        h(
          'g-icon',
          {
            class: 'mr-3',
            props: {
              value: 'check_circle',
              size: 48,
              color: 'success'
            }
          }
        ),

        h('div', { class: 'caption text--grey' }, this.getTranslate('recover.contents.validation.request'))
      ]
    )
  }
}

function renderLoginForm(h) {
  if (this.validation === undefined) {
    return h(
      'g-form',
      {
        class: 'faic pa-3',
        on: {
          submit: () => {
            this.requestRecover()
          },
          validation: event => {
            this.$emit('validation', event)
          }
        }
      },
      [
        h(
          'g-text-field',
          {
            props: {
              value: this.login,
              label: this.getTranslate('recover.labels.login'),
              mode: 'outline-label',
              rounded: true,
              clearable: true,
              details: false,
              autofocus: true
            },
            on: {
              input: event => {
                this.login = event
              }
            }
          }
        )
      ]
    )
  }
}
function renderPasswordForm(h) {
  if (this.validation === true && this.state === 'key') {
    return h(
      'g-form',
      {
        class: 'grid faic pa-3',
        on: {
          submit: () => {
            this.changePassword(this.$route.params.key)
          },
          validation: event => {
            this.$emit('validation', event)
          }
        }
      },
      [
        h(
          'g-text-field',
          {
            props: {
              value: this.password,
              label: this.getTranslate('recover.labels.password'),
              hint: this.getTranslate('recover.hints.password'),
              name: 'password',
              afterIcon: this.passwordVisibility ? 'visibility_off' : 'visibility',
              afterIconCallback: () => {
                this.passwordVisibility = !this.passwordVisibility
              },
              mode: 'outline-label',
              rounded: true,
              autofocus: true,
              clearable: true,
              type: this.passwordVisibility ? 'text' : 'password'
            },
            on: {
              input: event => {
                this.password = event
              }
            }
          }
        )
      ]
    )
  }
}

function renderResetButton(h) {
  if (this.validation === undefined || this.state === 'key') {
    return h(
      'g-button',
      {
        class: 'mr-0',
        props: {
          label: this.getTranslate('recover.buttons.reset'),
          rounded: true,
          depressed: true,
          loading: this.loading,
          disabled: this.loading || this.state === 'key' ? !this.password : !this.login,
          color: 'primary'
        },
        on: {
          click: () => {
            if (this.$route.params.key) {
              this.changePassword(this.$route.params.key)
            } else {
              this.requestRecover()
            }
          }
        }
      }
    )
  }
}

function renderFooter(h) {
  return h(
    'div',
    {
      class: 'fjcfe'
    },
    [ renderResetButton.call(this, h) ]
  )
}

function renderBody(h) {
  return h(
    'g-card',
    {
      props: {
        rounded: true,
        maxWidth,
        width: '100%'
      }
    },
    [
      renderLoginForm.call(this, h),
      renderPasswordForm.call(this, h),
      renderValidationResult.call(this, h)
    ]
  )
}

function renderContent(h) {
  return h(
    'div',
    {
      class: 'grid grid-cols--1 grid-gap--8',
      style: {
        'max-width': `${maxWidth}px`,
        width: '100%'
      }
    },
    [
      renderHeader.call(this, h),
      renderBody.call(this, h),
      renderFooter.call(this, h)
    ]
  )
}

export default function(h) {
  return renderContent.call(this, h)
}
