import Chart from '@/components/misc/Chart'

function renderFilter(h) {
  if (this.hasFilter) {
    return h(
      'div',
      {
        class: 'fc'
      },
      [
        h('g-divider'),

        h(
          'div',
          {
            class: 'faic',
            style: { 'min-height': '54px' }
          },
          [
            [ 5, 15, 30, 60 ].map(minute => {
              return h(
                'g-button',
                {
                  props: {
                    flat: true,
                    round: true,
                    label: minute + this.getTranslate('misc.measure.time.m'),
                    disabled: this.loading,
                    color: this.range === minute ? 'primary' : undefined
                  },
                  on: {
                    click: () => {
                      this.range = minute
                      this.get()
                    }
                  }
                }
              )
            }),

            h('div', { class: 'ff' }),

            h(
              'g-switch',
              {
                props: { value: this.online },
                directives: [
                  {
                    name: 'g-tooltip',
                    options: { value: this.getTranslate('grafana.tooltips.metrics.online') }
                  }
                ],
                on: {
                  input: event => {
                    this.online = event
                  }
                }
              }
            ),
            h(
              'g-button',
              {
                props: {
                  flat: true,
                  icon: 'refresh',
                  loading: this.loading,
                  disabled: this.loading
                },
                on: {
                  click: () => {
                    this.get()
                  }
                }
              }
            )
          ]
        ),

        h('g-divider')
      ]
    )
  }
}

function renderChart(h) {
  if (this.datasets.length) {
    return h(
      Chart,
      {
        props: {
          data: {
            labels: this.labels,
            datasets: this.datasets
          },
          options: {
            layout: {
              padding: {
                left: 8,
                right: 8
              }
            },
            interaction: {
              intersect: false,
              mode: 'index'
            },
            scales: {
              x: { display: false },
              queue: {
                axis: 'y',
                min: 0,
                display: false
              },
              speed: {
                axis: 'y',
                min: 0,
                display: false
              },
              count: {
                axis: 'y',
                min: 0,
                display: false
              },
              delivery_rate: {
                axis: 'y',
                min: 0,
                max: 100,
                display: false
              }
            },
            animation: { duration: 10 },
            plugins: {
              legend: {
                labels: {
                  usePointStyle: true,
                  font: {
                    size: 12,
                    lineHeight: '12px'
                  }
                }
              },
              tooltip: {
                caretPadding: 8,
                bodySpacing: 4,
                padding: 8,
                cornerRadius: 16,
                usePointStyle: true
              }
            }
          }
        }
      }
    )
  }
}

function renderEmpty(h) {
  if (this.datasets.length === 0) {
    return h(
      'g-empty',
      {
        props: {
          padless: !this.hasFilter,
          size: this.hasFilter ? 64 : 24
        }
      }
    )
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8'
    },
    [
      renderFilter.call(this, h),
      renderChart.call(this, h),
      renderEmpty.call(this, h)
    ]
  )
}
