export default {
  titles: {
    dialog: {
      remove: 'Confirmation'
    }
  },

  contents: {
    dialog: {
      remove: 'Confirm remove version: {version}'
    }
  },

  labels: {
    mcc: 'MCC',
    mnc: 'MNC',
    step: 'Step',
    trafficType: 'Traffic type',
    sender: 'Sender',
    price: 'Price',
    version: 'Version'
  },

  traffic: {
    identity: 'Identity',
    marketing: 'Marketing',
    service: 'Service'
  },

  hints: {
    notFound: 'Tariff rates not found. Try import one by clicking button below.'
  }
}
