import { getPrice } from '@/utils'

export default function(h) {
  const { pricePerMonth } = this.value

  return h(
    'div',
    {
      class: 'text-overflow'
    },
    getPrice(pricePerMonth)
  )
}
