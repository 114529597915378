import buttonCopy from '@/components/button/copy'

function renderActions(h) {
  return h(
    'div',
    {
      class: 'source-box__actions'
    },
    [
      h(
        buttonCopy,
        {
          props: {
            value: JSON.stringify(this.value),
            flat: true,
            small: true,
            color: 'white'
          }
        }
      ),

      h(
        'g-button',
        {
          props: {
            icon: 'subject',
            flat: true,
            small: true,
            color: this.preWrap ? 'primary' : 'white'
          },
          on: {
            click: () => {
              this.preWrap = !this.preWrap
            }
          }
        }
      )
    ]
  )
}

function renderContent(h) {
  return h(
    'div',
    {
      class: 'source-box'
    },
    [
      renderActions.call(this, h),

      h(
        'pre',
        {
          class: `language-${this.language} source-box__content ma-0 pa-2`,
          style: {
            'border-radius': this.rounded ? '16px' : 0,
            'white-space': this.preWrap ? 'pre-wrap' : undefined
          },
          domProps: { innerHTML: this.parsedSource }
        }
      )
    ]
  )
}

export default function(h) {
  return renderContent.call(this, h)
}
