const { REGEXP_IS_IP_ADDRESS } = require('@sigma-legacy-libs/essentials/lib/constants')

import render from './render'

export default {
  name: 'Host',

  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    url() {
      const REGEXP_URL_PROTOCOL = /^(\w+):\/\//

      const host = this.value.host
      const port = this.value.port

      let baseUrl = ''
      if (!REGEXP_URL_PROTOCOL.test(host)) {
        if (REGEXP_IS_IP_ADDRESS.test(host)) {
          baseUrl += 'http://'
        } else {
          baseUrl += 'https://'
        }
      }

      baseUrl += host

      const url = new URL(baseUrl)

      if (
        typeof port === 'number' && port > 0 && port < 64000 ||
        typeof port === 'string' && /^\d+$/.test(port)
      ) {
        url.port = port
      }

      return url.toString()
    }
  },

  render
}
