import { generateServices } from '@/utils'

import render from './render'

export default function(options = {}) {
  return {
    name: 'GetCreateTemplate',

    mixins: [ generateServices(options) ],

    props: {
      maxWidth: {
        type: Number,
        default: 800
      }
    },

    data() {
      return {
        show: false
      }
    },

    watch: {
      '$route.params.id'() {
        this.getById()
      }
    },

    mounted() {
      this.getById()
    },

    methods: {
      getById() {
        const id = this.$route.params.id
        if (id) {
          this.rest[options.name].get(id)
        }
      }
    },

    render(h) {
      return render.call(this, h, options)
    }
  }
}
