import Vue from 'vue'
import Clipboard from 'clipboard'

import { mapActions, mapGetters } from 'vuex'
import { unVue } from '@sigma-legacy-libs/unvue'
import { isUUID } from '@sigma-legacy-libs/essentials/lib/validators'

import SearchGenerator from '@/components/generator/search'
import GetCreateTemplate from '@/components/GetCreateTemplate'

import { userTariffsInputFilter, userTariffsOutputFilter } from '@/components/services/tariffs/utils'
import { attach, createClicker, generateServices, globalErrorHandler, globalErrorProcessor } from '@/utils'

const _ = {
  forOwn: require('lodash/forOwn'),
  get: require('lodash/get'),
  set: require('lodash/set'),
  uniq: require('lodash/uniq'),
  isPlainObject: require('lodash/isPlainObject')
}

function flatThis(obj, prefix = '') {
  const result = {}

  Object.keys(obj).forEach(key => {
    if (_.isPlainObject(obj[key])) {
      Object.assign(result, flatThis(obj[key], prefix + key + '.'))
    } else {
      result[prefix + key] = obj[key]
    }
  })

  return result
}

function inputFilter(result) {
  result.password = ''
  result.ResellerId = result.ResellerId || result.Reseller && result.Reseller.id || null
  result.data.partnership.ManagerId = _.get(
    result,
    'data.partnership.Manager.id',
    _.get(result, 'data.partnership.ManagerId', null)
  )

  if (!Array.isArray(result.RoutingGroups)) {
    result.RoutingGroups = []
  }
  result._RoutingGroups = result.RoutingGroups.map(item => {
    return {
      id: item.id,
      $attach: true
    }
  })

  const availableSendingTypeForRoutingGroups = {}
  for (const { value } of this.getSendingTypesByPermission('advanced.users.setRoutingGroups')) {
    availableSendingTypeForRoutingGroups[value] = []
  }
  result.RoutingGroups = result.RoutingGroups.reduce((result, group) => {
    if (group.priority === undefined) {
      group.priority = 0
    }
    if (!result[group.type]) {
      result[group.type] = []
    }
    result[group.type].push(group)
    result[group.type].sort((a, b) => a.priority - b.priority)

    return result
  }, availableSendingTypeForRoutingGroups)

  if (!Array.isArray(result.Tariffs)) {
    result.Tariffs = []
  }
  result._Tariffs = unVue(result.Tariffs)
  result.Tariffs = result.Tariffs
    .map(item => userTariffsInputFilter.call(this, item))
    .sort((a, b) => a.priority - b.priority)

  const availableSendingType = {}
  for (const { value } of this.getSendingTypesByPermission('advanced.users.update')) {
    availableSendingType[value] = []
  }

  if (!Array.isArray(result.Sendernames)) {
    result.Sendernames = []
  }
  result._Sendernames = unVue(result.Sendernames)
  result.Sendernames = result.Sendernames.reduce((result, { id, type }) => {
    if (!result[type]) {
      result[type] = []
    }
    result[type].push(id)

    return result
  }, unVue(availableSendingType))

  if (!Array.isArray(result.Patterns)) {
    result.Patterns = []
  }
  result._Patterns = unVue(result.Patterns)
  result.Patterns = result.Patterns.reduce((result, { id, type }) => {
    if (!result[type]) {
      result[type] = []
    }
    result[type].push(id)

    return result
  }, unVue(availableSendingType))

  if (Array.isArray(result.MessageRuleGroups)) {
    result._MessageRuleGroups = unVue(result.MessageRuleGroups)
    result.MessageRuleGroups = result.MessageRuleGroups.map(item => {
      return {
        id: item.id,
        priority: item.priority,
        $attach: true
      }
    }).sort((a, b) => (a.priority || 0) - (b.priority || 0))
  } else {
    result.MessageRuleGroups = []
  }

  if (!result.data.meta) {
    result.data.meta = {}
  }
  if (!result.data.meta.usedReferralCode) {
    result.data.meta.usedReferralCode = undefined
  }

  if (_.isPlainObject(result.permissions)) {
    result.permissions = flatThis(result.permissions)
  } else {
    delete result.permissions
  }

  return result
}

function outputFilter(result) {
  if (!result.ResellerId) {
    result.ResellerId = null
  }
  delete result.Reseller

  if (!result.data.partnership.ManagerId) {
    _.set(result, 'data.partnership.ManagerId', null)
  }
  delete result.data.partnership.Manager

  result.RoutingGroups = Object.keys(result.RoutingGroups).reduce((groups, type) => {
    const group = result.RoutingGroups[type]
    for (let index = 0; index < group.length; index++) {
      const { id } = group[index]
      groups.push({
        id,
        priority: index,
        $attach: true
      })
    }

    return groups
  }, [])
  attach(result, 'RoutingGroups')

  result.Tariffs = _.get(result, 'Tariffs', [])
    .map(item => userTariffsOutputFilter.call(this, item))
    .sort((a, b) => a.priority - b.priority)
  attach(result, 'Tariffs')

  const Sendernames = []
  for (const sendingType in result.Sendernames) {
    for (const id of result.Sendernames[sendingType]) {
      if (!~Sendernames.findIndex(sendername => sendername.id === id)) {
        Sendernames.push({
          id,
          $attach: true
        })
      }
    }
  }
  result.Sendernames = Sendernames
  attach(result, 'Sendernames')

  const Patterns = []
  for (const sendingType in result.Patterns) {
    for (const id of result.Patterns[sendingType]) {
      if (!~Patterns.findIndex(pattern => pattern.id === id)) {
        Patterns.push({
          id,
          $attach: true
        })
      }
    }
  }
  result.Patterns = Patterns
  attach(result, 'Patterns')

  if (result.permissions) {
    const tempPermissions = {}

    _.forOwn(result.permissions, (value, key) => {
      _.set(tempPermissions, key, value)
    })

    result.permissions = tempPermissions
  }

  if (Array.isArray(result.MessageRuleGroups)) {
    result.MessageRuleGroups = result.MessageRuleGroups.map((item, index) => {
      return {
        id: item.id,
        priority: index,
        $attach: true
      }
    }).sort((a, b) => (a.priority || 0) - (b.priority || 0))
    attach(result, 'MessageRuleGroups')
  } else {
    result.MessageRuleGroups = undefined
  }

  delete result.Users

  return result
}

import render from './render'

const name = 'users'

export default {
  name,

  mixins: [
    generateServices({
      name: 'billings',

      inputFilter(result) {
        result.amount = 10

        return result
      },

      outputFilter(result) {
        if (result.source !== 'accrual') {
          result.amount = result.amount * -1
        }

        result.amount = parseFloat(result.amount)

        return result
      },

      get: false,
      find: false,
      update: false,
      remove: false
    })
  ],

  components: {
    'search-generator': SearchGenerator({
      name,

      inputFilterForCreateButton: inputFilter,
      outputFilterForCreateButton: outputFilter,

      find: {
        defaultFilter: {
          isActive: true
        },
        params: {
          query: {
            $scope: [ 'Reseller', 'Manager' ]
          }
        }
      }
    }),

    'get-create-template': GetCreateTemplate({
      name,

      inputFilter,
      outputFilter,

      find: false,
      get: {
        params: {
          query: {
            $scope: [ 'full' ]
          }
        }
      },
      update: {
        params: {
          query: {
            $scope: [ 'full' ]
          }
        }
      },
      create: false,
      remove: {
        redirect: true,
        params: {
          query: {
            $scope: [ 'full' ]
          }
        }
      }
    })
  },

  data() {
    return {
      showAddFunds: false,
      showTokenDialog: false,

      token: '',

      validation: false,

      cp: undefined
    }
  },

  computed: {
    ...mapGetters({ statusLoginAs: 'login/status' }),

    createDataIsValid() {
      return this.restData.billings.create.state !== 'loading' && this.restData.billings.create.isValid
    }
  },

  watch: {
    '$route.params.id'() {
      this.currentTab = 'general'
    }
  },

  mounted() {
    this.cp = new Clipboard('.clipboard-button')
  },

  beforeDestroy() {
    this.cp.destroy()
  },

  methods: {
    ...mapActions({ loginAs: 'login/loginAs' }),

    clickHandler: createClicker({
      shortClickHandler(event) {
        if (event.shiftKey) {
          this.showUserToken()
        } else {
          this.loginAs(this.$route.params.id)
        }
      },

      longClickHandler() {
        this.showUserToken()
      },

      longClickPreFire: true
    }),

    async showUserToken() {
      try {
        if (isUUID(this.$route.params.id)) {
          const result = await Vue.$GRequest.get('login/as', this.$route.params.id)
          if (result && result.status === 200) {
            this.token = result.data.token
            this.showTokenDialog = true
          }
        }
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      }
    },

    async createBilling() {
      if (this.createDataIsValid) {
        try {
          const result = await this.rest.billings.create(this.restData.billings.create.data)
          if (result) {
            this.showAddFunds = false
          }
        } catch (error) {
          globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
        }
      }
    }
  },

  render
}
