import Vue from 'vue'

import { mapGetters } from 'vuex'

import { globalErrorHandler, globalErrorProcessor } from '@/utils'

import { serviceName } from '@/components/services/tariffRates/utils'

import render from './render'

export default {
  name: 'Import',

  props: {
    TariffId: String,

    disabled: Boolean,
    fullVersion: Boolean
  },

  data() {
    return {
      loading: false,
      showFileManager: false
    }
  },

  computed: {
    ...mapGetters({ uploadedFile: 'file/uploadedFile' })
  },

  watch: {
    uploadedFile: {
      async handler({ data }) {
        this.importCallback(data.id)
      },
      deep: true
    }
  },

  methods: {
    async import(fileId) {
      if (fileId && this.TariffId) {
        try {
          this.loading = true
          await Vue.$GRequest.update(serviceName, 'import', {
            fileId,
            TariffId: this.TariffId
          })
          this.$emit('finish', true)
        } catch (error) {
          globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
        } finally {
          this.loading = false
        }
      }
    },

    importCallback(fileId) {
      this.import(fileId)

      this.showFileManager = false
    }
  },

  render
}
