import proxy from '@sigma-legacy-libs/g-proxy'

import { unVue } from '@sigma-legacy-libs/unvue'

import render from './render'

const defaultRule = { conditions: [] }

export default {
  name: 'TariffsRulesGroup',

  mixins: [ proxy({ type: 'object' }) ],

  props: {
    target: {
      type: Array,
      default: () => []
    },
    index: Number,
    meta: {
      type: Object,
      default: () => {
        return {
          type: undefined,
          currency: undefined
        }
      }
    }
  },

  computed: {
    hasRules() {
      return this.proxy && Array.isArray(this.proxy.rules) && this.proxy.rules.length
    }
  },

  methods: {
    _inputFilter(data) {
      if (data.rules.length === 0) {
        data.rules.push(unVue(defaultRule))
      }

      return data
    },
    addConditions() {
      this.proxy.rules.push(unVue(defaultRule))
    }
  },

  render
}
