import Clipboard from 'clipboard'

import render from './render'

export default {
  name: 'ButtonCopy',

  props: {
    value: [ String, Number ],

    snackbar: {
      type: Object,
      default: () => ({})
    },

    icon: {
      type: String,
      default: 'content_copy'
    },
    iconLibrary: String,
    color: {
      type: String,
      default: 'primary'
    },

    tooltip: String,

    flat: {
      type: Boolean,
      default: true

    },
    small: Boolean,
    large: Boolean,
    marginless: Boolean
  },

  data() {
    return {
      cp: undefined
    }
  },

  mounted() {
    this.cp = new Clipboard(`.copy-button-${this._uid}`)
    this.cp.on('success', () => {
      if (this.snackbar.text || this.snackbar.type) {
        this.addSnackbar(this.snackbar)
      } else {
        this.addSnackbar({
          text: this.getTranslate('misc.copied'),
          type: 'success'
        })
      }
    })
  },

  beforeDestroy() {
    this.cp.destroy()
  },

  render
}
