import { moderationColors } from '@/utils'

export default function(h) {
  const { moderation } = this.value
  if (moderation) {
    return h(
      'div',
      {
        class: {
          'text-overflow': true,
          [`text--${moderationColors[moderation]}`]: true
        }
      },
      this.getTranslate(`misc.moderation.${moderation}`)
    )
  }
}
