export const PAYMENTS_FILTER = {
  method: undefined,
  status: undefined,
  createdAt: undefined,
  OwnerId: undefined,
  RecurrentPaymentId: undefined,
  $scope: [ 'full' ]
}

export default { PAYMENTS_FILTER }
