import proxy from '@sigma-legacy-libs/g-proxy'

import render from './render'

export default {
  name: 'Dialog',

  mixins: [ proxy({ type: 'array' }) ],

  props: {
    tag: String,
    tagEdit: Boolean,

    tagDisabled: {
      type: Array,
      default: () => []
    },

    show: Boolean,

    readonly: Boolean
  },

  render
}
