import SingleLink from '../SingleLink'

export default function(h) {
  if (this.value) {
    return h(
      SingleLink,
      {
        props: {
          value: this.value,
          service: this.service,
          pathToId: this.pathToId,
          pathToTitle: this.pathToTitle,
          to: this.to,
          type: this.type
        }
      }
    )
  }
}
