import Vue from 'vue'

import { Macro } from '@sigma-legacy-libs/cache'
import { isUUID } from '@sigma-legacy-libs/essentials/lib/validators'

import { serviceName } from '@/components/services/tasks/utils'

import { globalErrorHandler, globalErrorProcessor } from '@/utils'

import render from './render'

const Cache = new Macro({
  ttl: 5 * 1000,
  ttlInterval: 1000
})

const _ = { get: require('lodash/get') }

export default {
  name: 'TasksAnalytics',

  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      analytics: [],

      showDialog: false,
      loading: false
    }
  },

  methods: {
    cachedGetContactListById: Cache.wrapWithCache(async (key, id) => {
      return await Vue.$GRequest.get('contactLists', id)
    }),

    async analysis() {
      try {
        this.loading = true
        this.clearAnalytics()

        const recipientTypes = [ 'include', 'exclude' ]
        const recipients = _.get(this.value, 'data.recipients')
        for (const type of recipientTypes) {
          if (Array.isArray(recipients[type].lists) && recipients[type].lists.length) {
            this.analytics.push({ subheader: this.getTranslate(`${serviceName}.subheaders.${type}.lists`) })
            await Promise.all(
              recipients[type].lists.map(async id => {
                if (isUUID(id)) {
                  const { data } = await this.cachedGetContactListById(`contactLists:${id}`, id)
                  if (data) {
                    this.analytics.push({
                      title: data.title,
                      id
                    })
                  }
                }
              })
            )
          }
          if (Array.isArray(recipients[type].recipients) && recipients[type].recipients.length) {
            this.analytics.push({ subheader: this.getTranslate(`${serviceName}.subheaders.${type}.recipients`) })
            recipients[type].recipients.forEach(item => this.analytics.push({ title: item }))
          }
        }

        const errors = _.get(this.value, 'meta.errors.$fatals')
        if (errors) {
          for (const key in errors) {
            this.analytics.push({
              title: key,
              error: errors[key]
            })
          }
        }

        if (Array.isArray(this.analytics) && this.analytics.length) {
          this.showDialog = true
        }
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      } finally {
        this.loading = false
      }
    },

    clearAnalytics() {
      this.analytics = []
    }
  },

  render
}
