import 'core-js/stable'
import 'regenerator-runtime/runtime'

import Vue from 'vue'
import VueMeta from 'vue-meta'
import VueTour from 'vue-tour'

import app from '@/app'
import store from '@/store'
import mixins from '@/mixins'
import GSelect from '@/mixins/components/GSelect'

import GComponents from '@sigma-legacy-libs/g-components'

import { EventEmitter2 } from 'eventemitter2'

import 'prismjs/themes/prism-okaidia.css'
import 'vue-tour/dist/vue-tour.css'

import '@/assets/main.scss'

import '@/registerServiceWorker'

import '@/plugins/numeral'
import '@/plugins/gRequest'

import { router } from '@/plugins/router'

import { locales } from '@/utils'

const instructionElement = document.querySelector('.instruction')
if (instructionElement && typeof instructionElement.remove === 'function') {
  instructionElement.remove()
}

Vue.use(VueMeta)
Vue.use(VueTour)

Vue.use(GComponents, { mixins: { GSelect } })

Vue.mixin(mixins)

Vue.$bus = new EventEmitter2({
  wildcard: true,
  newListener: false,
  maxListeners: 20,
  verboseMemoryLeak: true
})

const initToken = window.localStorage.getItem('token')
store.dispatch('authentication/setToken', initToken, { root: true })

window.onstorage = event => {
  if (document.hidden) {
    switch (event.key) {
      case 'token':
      case 'prevToken': {
        window.location.reload()
        break
      }
    }
  }
}

store.dispatch('viewport/init', null, { root: true })

window.addEventListener('mouseup', () => store.dispatch('authentication/setTimer', null, { root: true }))
window.addEventListener('keyup', () => store.dispatch('authentication/setTimer', null, { root: true }))

let locale = window.location.href.replace(/.*?#.*?\?.*?locale=([A-z]+)(&|$)/, '$1')
if (!~locales.indexOf(locale)) {
  locale = window.localStorage.getItem('locale')
  if (!~locales.indexOf(locale)) {
    locale = 'ru'
  }
}

store.dispatch('locale/setLocale', locale)

store.dispatch('authentication/setAccountFromToken', null, { root: true }).then(() => {
  new Vue({
    router,
    store,
    render: h => h(app)
  }).$mount('#app')
})
