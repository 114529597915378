import { serviceName } from '@/components/services/providers/utils'

export default function(h) {
  return h(
    'div',
    {
      class: {
        'grid grid-gap--8': true,
        'grid-cols--1': this.viewport.breakpoint.smDown
      },
      style: { 'grid-template-columns': this.viewport.breakpoint.mdUp ? 'repeat(2, 250px)' : undefined }
    },
    [
      h(
        'g-select',
        {
          props: {
            value: this.proxy.ServerId,
            label: this.getTranslate(`${serviceName}.labels.server`),
            service: 'servers',
            mode: 'outline',
            rounded: true,
            dense: true,
            details: false,
            clearable: true,
            autocomplete: true
          },
          on: {
            input: event => {
              this.proxy.ServerId = event
            }
          }
        }
      ),

      h(
        'g-select',
        {
          props: {
            value: this.proxy.CredentialsId,
            label: this.getTranslate(`${serviceName}.labels.credentials`),
            service: 'credentials',
            mode: 'outline',
            rounded: true,
            dense: true,
            details: false,
            clearable: true,
            autocomplete: true
          },
          on: {
            input: event => {
              this.proxy.CredentialsId = event
            }
          }
        }
      )
    ]
  )
}
