import { serviceName } from '@/components/services/servers/utils'

function renderContent(h) {
  return h(
    'div',
    {
      class: 'faic'
    },
    [
      h(
        'div',
        {
          class: 'fjcc facc faic mr-2 square--32'
        },
        [
          h(
            'g-icon',
            {
              props: {
                value: 'vpn_lock',
                color: 'grey'
              }
            }
          )
        ]
      ),

      h(
        'div',
        {
          class: 'grid grid-cols--1 grid-gap--2 caption'
        },
        [
          h(
            'div',
            {
              class: 'faic'
            },
            [
              h('div', { class: 'text--grey mr-1' }, this.getTranslate(`${serviceName}.labels.host`) + ':'),
              h('div', { class: 'text-overflow' }, this.value.host)
            ]
          ),

          h(
            'div',
            {
              class: 'faic'
            },
            [
              h('div', { class: 'text--grey mr-1' }, this.getTranslate(`${serviceName}.labels.port`) + ':'),
              h('div', { class: 'text-overflow' }, this.value.port)
            ]
          )
        ]
      )
    ]
  )
}

export default function(h) {
  return renderContent.call(this, h)
}
