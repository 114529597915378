import componentNotFound from '@/components/misc/componentNotFound'

import { STATES } from '@/utils'

import { serviceName } from '@/components/services/tokens/utils'

function renderForm(h) {
  try {
    return h(
      require('@/components/services/tokens/form').default,
      {
        props: {
          // FIXME: докинуть сюда this.OwnerId
          value: this.restData[serviceName].create.data,
          isNew: true
        },
        on: {
          input: event => {
            this.restData[serviceName].create.data = event
          },
          submit: () => {
            this.create()
          },
          validation: event => {
            this.restData[serviceName].create.isValid = event
          }
        }
      }
    )
  } catch (error) {
    return h(componentNotFound)
  }
}

function renderDialog(h) {
  return h(
    'g-dialog',
    {
      props: {
        value: this.showDialog,
        rounded: true,
        maxWidth: 400
      },
      on: {
        input: event => {
          this.showDialog = event
        }
      }
    },
    [
      h(
        'div',
        {
          class: 'headline pa-3',
          slot: 'header'
        },
        this.getTranslate(`${serviceName}.titles.create`)
      ),

      renderForm.call(this, h),

      h(
        'g-card-actions',
        {
          class: 'fjcsb',
          slot: 'footer'
        },
        [
          h(
            'g-button',
            {
              props: {
                flat: true,
                rounded: true,
                label: this.getTranslate('misc.buttons.cancel'),
                disabled: this.restData[serviceName].create.state === STATES.loading
              },
              on: {
                click: () => {
                  this.showDialog = false
                }
              }
            }
          ),

          h(
            'g-button',
            {
              props: {
                rounded: true,
                depressed: true,
                label: this.getTranslate('misc.buttons.create'),
                color: 'primary',
                loading: this.restData[serviceName].create.state === STATES.loading,
                disabled: this.restData[serviceName].create.state === STATES.loading || !this.restData[serviceName].create.isValid
              },
              on: {
                click: () => {
                  this.create()
                }
              }
            }
          )
        ]
      )
    ]
  )
}

function renderButton(h) {
  return h(
    'g-button',
    {
      props: {
        icon: 'add',
        flat: true,
        color: 'secondary',
        rounded: this.label,
        label: this.label ? this.getTranslate(`${serviceName}.buttons.create`) : undefined
      },
      on: {
        click: () => {
          this.showDialog = true
        }
      },
      scopedSlots: {
        dialog: () => renderDialog.call(this, h)
      }
    }
  )
}

export default function(h) {
  return renderButton.call(this, h)
}
