import { serviceName } from '@/components/services/yclients/utils'

export default function(h) {
  const { id, salonId } = this.value
  if (id) {
    return h(
      'router-link',
      {
        class: 'text-overflow link',
        props: {
          to: {
            name: `${serviceName}.single`,
            params: { id }
          }
        }
      },
      salonId
    )
  }
}
