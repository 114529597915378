import { getToday } from '@/utils/misc' // Этот путь должен быть прямым иначе webpack не сможет его найти

export const SENDINGS_OUTGOING_FILTER = {
  id: undefined,
  groupId: undefined,
  chainId: undefined,
  type: undefined,
  payload: {
    recipient: undefined,
    sender: undefined
  },
  state: {
    status: undefined
  },
  meta: {
    _routing: {
      provider: undefined
    },
    user: {
      id: undefined
    }
  },
  $scope: [ 'full' ],
  createdAt: getToday()
}

export default { SENDINGS_OUTGOING_FILTER }
