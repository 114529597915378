import Vue from 'vue'

import providersMetrics from '@/components/services/providers/metrics'
import providersItemTitle from '@/components/services/providers/item/title'
import providersItemServer from '@/components/services/providers/item/server'
import providersItemCredentials from '@/components/services/providers/item/credentials'
import buttonFavorite from '@/components/button/favorite'

import { serviceName } from '@/components/services/providers/utils'

function renderTitle(h) {
  return h(
    'td',
    {
      class: 'pl-2 w--100'
    },
    [ h(providersItemTitle, { props: { value: this.value } }) ]
  )
}

function renderServer(h) {
  if (this.viewport.breakpoint.lgUp) {
    return h(
      'td',
      {
        style: 'min-width: 200px'
      },
      [ h(providersItemServer, { props: { value: this.value } }) ]
    )
  }
}

function renderCredentials(h) {
  if (this.viewport.breakpoint.lgUp) {
    return h(
      'td',
      {
        style: 'min-width: 200px'
      },
      [ h(providersItemCredentials, { props: { value: this.value } }) ]
    )
  }
}

function renderInfo(h) {
  if (this.viewport.breakpoint.mdUp) {
    return h(
      'td',
      {
        style: 'min-width: 200px'
      },
      [
        h(
          providersMetrics,
          {
            props: {
              value: this.metrics,
              id: this.value.id
            }
          }
        )
      ]
    )
  }
}

function renderEditButton(h) {
  return h(
    'td',
    {
      class: 'px-0'
    },
    [
      h(
        'div',
        {
          class: {
            'fjcfe faic': true,
            'hover-child': this.viewport.breakpoint.mdUp
          }
        },
        [
          h(
            buttonFavorite,
            {
              props: {
                id: this.value.id,
                serviceName
              }
            }
          ),

          h(
            'g-button',
            {
              props: {
                flat: true,
                icon: 'edit'
              },
              on: {
                click: () => {
                  Vue.router.push({
                    name: `${serviceName}.single`,
                    params: { id: this.value.id }
                  })
                }
              }
            }
          )
        ]
      )
    ]
  )
}

export default function(h) {
  return h(
    'tr',
    {
      class: 'hover-parent hover-parent--opacity'
    },
    [
      renderTitle.call(this, h),
      renderServer.call(this, h),
      renderCredentials.call(this, h),
      renderInfo.call(this, h),
      renderEditButton.call(this, h)
    ]
  )
}
