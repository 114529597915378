import HeadTitle from '@/components/misc/HeadTitle'

import RequisitesForm from '@/components/services/requisites/form'

import { serviceName } from '@/components/services/requisites/utils'

import { STATES } from '@/utils'

function renderHeader(h) {
  if (this.type) {
    const [ service, type ] = this.type.split('.')

    return h(
      HeadTitle,
      {
        props: { value: this.getTranslate(`${service}.dialogs.titles.${type}`) },
        slot: 'header'
      }
    )
  }
}
function renderBody(h) {
  switch (this.type) {
    case `${serviceName}.create`: {
      return h(
        RequisitesForm,
        {
          props: {
            value: this.restData[serviceName].create.data,
            errors: this.restData[serviceName].create.errors,
            User: this.User
          },
          on: {
            input: event => {
              this.restData[serviceName].create.data = event
            },
            submit: () => {
              this.create()
            },
            validation: event => {
              this.restData[serviceName].create.isValid = event
            }
          }
        }
      )
    }
    case `${serviceName}.update`: {
      if (this.restData[serviceName].get.state === STATES.ready) {
        return h(
          RequisitesForm,
          {
            props: {
              value: this.restData[serviceName].get.data,
              errors: this.restData[serviceName].update.errors,
              User: this.User
            },
            on: {
              input: event => {
                this.restData[serviceName].get.data = event
              },
              submit: () => {
                this.update()
              },
              validation: event => {
                this.restData[serviceName].update.isValid = event
              }
            }
          }
        )
      }
      break
    }
    case `${serviceName}.remove`: {
      return h(
        'div',
        {
          class: 'pa-3'
        },
        this.getTranslate(`${serviceName}.contents.confirm.remove`)
      )
    }
  }
}
function renderFooter(h) {
  if (this.type) {
    let method

    switch (this.type) {
      case `${serviceName}.create`: {
        method = 'create'
        break
      }
      case `${serviceName}.update`: {
        method = 'update'
        break
      }
      case `${serviceName}.remove`: {
        method = 'remove'
        break
      }
    }

    return h(
      'g-card-actions',
      {
        class: 'fjcfe facfe',
        slot: 'footer'
      },
      [
        h(
          'g-button',
          {
            props: {
              label: this.getTranslate('misc.buttons.cancel'),
              flat: true,
              rounded: true,
              disabled: this.restData[serviceName][method].state === STATES.loading
            },
            on: {
              click: () => {
                this.$emit('show', false)
              }
            }
          }
        ),

        h(
          'g-button',
          {
            props: {
              label: this.getTranslate(`misc.buttons.${method}`),
              rounded: true,
              depressed: true,
              loading: this.restData[serviceName][method].state === STATES.loading,
              disabled: this.restData[serviceName][method].state === STATES.loading || method !== 'remove' && !this.restData[serviceName][method].isValid,
              color: method === 'remove' ? 'error' : 'primary'
            },
            on: {
              click: () => {
                this[method](serviceName)
              }
            }
          }
        )
      ]
    )
  }
}

function renderContent(h) {
  let maxWidth = 400

  switch (this.type) {
    case `${serviceName}.create`: {
      maxWidth = 800
      break
    }
    case `${serviceName}.update`: {
      maxWidth = 800
      break
    }
    case `${serviceName}.remove`: {
      maxWidth = 500
      break
    }
  }

  return h(
    'g-dialog',
    {
      props: {
        rounded: true,
        value: this.show,
        maxWidth
      },
      on: {
        input: event => {
          this.$emit('show', event)
        }
      }
    },
    [
      renderHeader.call(this, h),
      renderBody.call(this, h),
      renderFooter.call(this, h)
    ]
  )
}

export default function(h) {
  return renderContent.call(this, h)
}
