import fallback from '@/components/misc/rules/actions/dialog/generators/fallbacks/fallback'

function renderContent(h) {
  return this.proxy.map((item, index) => {
    return h(
      'div',
      {
        class: 'grid grid-gap--8 faic pa-2',
        style: {
          'grid-template-columns': '32px 1fr 36px',
          'border-radius': '16px',
          border: '1px dashed rgba(0, 0, 0, 0.12)'
        }
      },
      [
        h(
          'g-icon',
          {
            props: {
              value: 'wrap_text',
              color: 'grey'
            }
          }
        ),

        h(
          fallback,
          {
            props: {
              value: item
            },
            on: {
              input: event => {
                item = event
              }
            }
          }
        ),

        h(
          'g-button',
          {
            class: 'ma-0',
            props: {
              icon: 'remove',
              flat: true,
              color: 'error'
            },
            on: {
              click: () => {
                this.remove(index)
              }
            }
          }
        )
      ]
    )
  })
}

export default function(h) {
  if (Array.isArray(this.proxy)) {
    return h(
      'div',
      {
        class: 'grid grid-cols--1'
      },
      [
        renderContent.call(this, h),

        h(
          'div',
          {
            class: 'faic fjcfe ff'
          },
          [
            h(
              'g-button',
              {
                class: 'mr-0',
                props: {
                  icon: 'add',
                  flat: true,
                  rounded: true,
                  color: 'primary'
                },
                on: {
                  click: () => {
                    this.add()
                  }
                }
              }
            )
          ]
        )
      ]
    )
  }
}
