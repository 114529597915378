import { PUBLIC_FIELDS_USER } from '@sigma-legacy-libs/essentials/lib/constants'

import { appendFormattedDates, fillDependence, permissionPresets, serviceTemplate } from '@/utils'

import { serviceName } from '@/components/services/yclients/utils'

export default {
  mixins: [
    serviceTemplate({
      serviceName,

      async inputFilter(result) {
        if (!result.Owner) {
          await fillDependence.call(this, result, {
            service: 'users',
            permissions: permissionPresets.admin,
            pathToId: 'OwnerId',
            pathToObject: 'Owner',
            outputPath: 'Owner',
            picks: PUBLIC_FIELDS_USER
          })
        }
        if (!result.Token) {
          await fillDependence.call(this, result, {
            service: 'tokens',
            permissions: permissionPresets.admin,
            pathToId: 'TokenId',
            pathToObject: 'Token',
            outputPath: 'Token',
            picks: [ 'id', 'token' ]
          })
        }

        result.OwnerId = result.Owner && result.Owner.id || result.OwnerId

        appendFormattedDates.call(this, result)

        return result
      }
    })
  ]
}
