import proxy from '@sigma-legacy-libs/g-proxy'

import { serviceName, transformTypes } from '@/components/services/tariffs/utils'

import render from './render'

export default {
  name: 'TariffFilter',

  mixins: [ proxy({ type: 'object' }) ],

  computed: {
    types() {
      const types = this.getSendingTypesByPermission(`advanced.${serviceName}.update`).map(({ value }) => value)
      types.push('subscription.apisender')

      return transformTypes.call(this, types, this.proxy.source)
    }
  },

  render
}
