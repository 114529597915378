export const tours = [
  {
    name: 'create',
    steps: [
      {
        content: 'sendings.tour.recipients.include',
        tag: 'recipients-include'
      },
      {
        content: 'sendings.tour.recipients.exclude',
        tag: 'recipients-exclude'
      },
      {
        content: 'sendings.tour.sender',
        tag: 'sender'
      },
      {
        content: 'sendings.tour.text',
        tag: 'text'
      },
      {
        content: 'sendings.tour.fallbacks',
        tag: 'fallbacks'
      },
      {
        content: 'sendings.tour.template.save',
        tag: 'template-save'
      },
      {
        content: 'sendings.tour.schedule',
        tag: 'schedule'
      }
    ]
  }
]

export default { tours }
