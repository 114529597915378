import copyIcon from '../../copy/icon'

function renderCopyButton(h, trigger, value) {
  if (trigger && (typeof value === 'string' || typeof value === 'number')) {
    return h(
      'div',
      {
        class: 'fjcc facc faic square--18'
      },
      [
        h(
          copyIcon,
          {
            props: {
              value,
              size: 11
            }
          }
        )
      ]
    )
  }
}

export default function(h) {
  return h(
    'table',
    {
      class: 'entity__table'
    },
    this.rows.map(row => {
      return h(
        'tr',
        {},
        [
          h('td', {}, [ row.title ]),
          h('td', {}, [ renderCopyButton.call(this, h, row.copy, row.value) ]),
          h('td', { class: 'w--100' }, [ row.value ])
        ]
      )
    })
  )
}
