import fallbackOptions from '@/components/services/sendingsCreate/form/fallback/options'

export default function(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8 grid-cols--1'
    },
    [
      h(
        'div',
        {
          class: 'grid grid-gap--8 grid-cols--2'
        },
        [
          h(
            'g-select',
            {
              props: {
                value: this.proxy.type,
                label: this.getTranslate('conditions.labels.type'),
                items: this.getSendingTypesByPermission('advanced.sendings.create').map(({ title, value }) => {
                  return {
                    title: this.getTranslate(title),
                    value
                  }
                }),
                mode: 'outline',
                details: false,
                dense: true,
                rounded: true,
                clearable: true,
                readonly: this.readonly
              },
              on: {
                input: event => {
                  this.proxy.type = event
                }
              }
            }
          ),

          h(
            'g-select',
            {
              props: {
                value: this.proxy.payload.sender,
                label: this.getTranslate('conditions.labels.sender'),
                service: 'sendernames',
                query: { type: this.proxy.type },
                itemTitle: 'name',
                itemValue: 'name',
                mode: 'outline',
                details: false,
                dense: true,
                rounded: true,
                clearable: true,
                combobox: true,
                autocomplete: true,
                readonly: this.readonly
              },
              on: {
                input: event => {
                  this.proxy.payload.sender = event
                }
              }
            }
          )
        ]
      ),

      h(
        'g-textarea',
        {
          props: {
            value: this.proxy.payload.text,
            label: this.getTranslate('conditions.labels.text'),
            mode: 'outline',
            details: false,
            dense: true,
            rounded: true,
            clearable: true,
            readonly: this.readonly
          },
          on: {
            input: event => {
              this.proxy.payload.text = event
            }
          }
        }
      ),

      h(
        fallbackOptions,
        {
          props: {
            value: this.proxy.$options
          },
          on: {
            input: event => {
              this.proxy.$options = event
            }
          }
        }
      )
    ]
  )
}
