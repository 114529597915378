import priceTextField from '@/components/services/tariffs/priceTextField'

import { serviceName } from '@/components/services/tariffs/utils'

function renderIcon(h) {
  return h(
    'div',
    {
      class: 'faic fjcc square--38',
      directives: [
        {
          name: 'g-tooltip',
          value: this.getTranslate(`${serviceName}.titles.defaultRule`)
        }
      ]
    },
    [
      h(
        'g-icon',
        {
          props: {
            value: 'info',
            color: 'grey'
          }
        }
      )
    ]
  )
}

function renderPriceField(h) {
  return h(
    priceTextField,
    {
      props: {
        value: this.proxy.price,
        meta: this.meta
      }
    }
  )
}

function renderActionButton(h) {
  return h(
    'g-button',
    {
      class: 'ma-0',
      props: {
        flat: true,
        icon: this.proxy.action === 'decrease' ? 'arrow_drop_down' : 'arrow_drop_up',
        color: this.proxy.action === 'decrease' ? 'error' : 'success'
      },
      on: {
        click: () => {
          if (this.proxy.action === 'increase') {
            this.proxy.action = 'decrease'
          } else {
            this.proxy.action = 'increase'
          }
        }
      }
    }
  )
}

function renderRemoveButton(h) {
  return h(
    'g-button',
    {
      class: 'ma-0',
      props: {
        flat: true,
        icon: 'delete',
        color: 'error'
      },
      on: {
        click: () => {
          this.$emit('remove')
        }
      }
    }
  )
}

function renderActions(h) {
  return h(
    'div',
    {
      class: `${serviceName}-defaultRule__actions`
    },
    [
      renderPriceField.call(this, h),
      renderActionButton.call(this, h),
      renderRemoveButton.call(this, h)
    ]
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: `${serviceName}-defaultRule`
    },
    [
      renderIcon.call(this, h),
      renderActions.call(this, h)
    ]
  )
}
