import { unVue } from '@sigma-legacy-libs/unvue'

import { CONTACTS_FILTER } from '@/utils'

import ContactsForm from '@/components/services/contacts/form'

export default function(h) {
  return h(
    'g-card',
    {
      class: 'my-3',
      props: { rounded: true }
    },
    [
      h(
        ContactsForm,
        {
          class: 'pa-3',
          props: {
            value: this.proxy,
            isFilter: true
          },
          on: {
            input: event => {
              this.proxy = event
            }
          }
        }
      ),

      h(
        'g-card-actions',
        {
          class: 'fjcfe facfe'
        },
        [
          h(
            'g-button',
            {
              props: {
                label: this.getTranslate('misc.filter.clear'),
                flat: true,
                rounded: true
              },
              on: {
                click: () => {
                  this.proxy = Object.assign({}, unVue(CONTACTS_FILTER), { ListId: this.$route.params.id })
                }
              }
            }
          )
        ]
      )
    ]
  )
}
