import { unVue } from '@sigma-legacy-libs/unvue'
import { PUBLIC_FIELDS_USER } from '@sigma-legacy-libs/essentials/lib/constants'

import { PATTERNS_FILTER, appendFormattedDates, fillDependence, sendingsOutputFilter, serviceTemplate } from '@/utils'

import { serviceName } from '@/components/services/patterns/utils'

export default {
  mixins: [
    serviceTemplate({
      serviceName,

      async inputFilter(result) {
        if (!result.Owner) {
          await fillDependence.call(this, result, {
            service: 'users',
            permissions: [ 'reseller', true ],
            pathToId: 'OwnerId',
            pathToObject: 'Owner',
            outputPath: 'Owner',
            picks: PUBLIC_FIELDS_USER
          })
        }

        result.OwnerId = result.Owner && result.Owner.id || result.OwnerId

        if (!result.Users) {
          result.Users = []
        }

        appendFormattedDates.call(this, result)

        return result
      },

      outputFilter: sendingsOutputFilter,

      find: { defaultFilter: unVue(PATTERNS_FILTER) },

      width: 600
    })
  ]
}
