import Vue from 'vue'

import { generateServices, globalErrorHandler, globalErrorProcessor } from '@/utils'

import render from './render'

export default {
  name: 'Dialogs',

  mixins: [
    generateServices([
      {
        name: 'contacts',
        outputFilter(result) {
          if (!result.ListId) {
            result.ListId = this.ListId
          }

          for (const key in result) {
            if (result[key] === undefined) {
              result[key] = null
            }
          }

          return result
        },

        find: false
      },
      {
        name: 'contactLists',

        find: false,
        remove: {
          redirect: 'contacts'
        }
      }
    ])
  ],

  props: {
    id: String,
    type: String,

    show: {
      type: Boolean,
      default: false
    },

    ListId: String
  },

  watch: {
    id() {
      this.getByType()
    }
  },

  mounted() {
    this.getByType()
  },

  methods: {
    async get(service) {
      if (this.checkPermissions('advanced.contacts.get', [ 'me', 'reseller', true ])) {
        if (this.id) {
          try {
            await this.rest[service].get(this.id)
          } catch (error) {
            globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
          }
        }
      }
    },
    async create(service) {
      if (this.checkPermissions('advanced.contacts.create', [ 'me', 'reseller', true ])) {
        if (this.restData[service].create.isValid) {
          try {
            const result = await this.rest[service].create(this.restData[service].create.data)
            if (result) {
              if (service === 'contactLists') {
                Vue.router.push({
                  name: 'contacts.single',
                  params: { id: result.id }
                })
              }
              this.$emit('show', false)
            }
          } catch (error) {
            globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
          }
        }
      }
    },
    async update(service) {
      if (this.checkPermissions('advanced.contacts.update', [ 'me', 'reseller', true ])) {
        if (this.restData[service].update.isValid) {
          try {
            const result = await this.rest[service].update(this.restData[service].get.data.id, this.restData[service].get.data)
            if (result) {
              this.$emit('show', false)
            }
          } catch (error) {
            globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
          }
        }
      }
    },
    async remove(service) {
      if (this.checkPermissions('advanced.contacts.remove', [ 'me', 'reseller', true ])) {
        if (this.id) {
          try {
            const result = await this.rest[service].remove(this.id)
            if (result) {
              this.$emit('show', false)
            }
          } catch (error) {
            globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
          }
        }
      }
    },

    getByType() {
      switch (this.type) {
        case 'contacts.update': {
          this.get('contacts')
          break
        }
        case 'contactLists.update': {
          this.get('contactLists')
          break
        }
      }
    }
  },

  render
}
