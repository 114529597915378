import { RECURRENT_PAYMENTS_STATUSES, RECURRENT_PAYMENTS_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import SingleLink from '@/components/misc/SingleLink'

import { getLocaleDateString, getLocaleTimeString, getPrice, permissionPresets } from '@/utils'

import { serviceName } from '@/components/services/recurrentPayments/utils'

const icons = {
  type: {
    [RECURRENT_PAYMENTS_TYPES.threshold]: 'equalizer',
    [RECURRENT_PAYMENTS_TYPES.frequency]: 'event'
  },
  status: {
    [RECURRENT_PAYMENTS_STATUSES.init]: 'schedule',
    [RECURRENT_PAYMENTS_STATUSES.ready]: 'check_circle_outline',
    [RECURRENT_PAYMENTS_STATUSES.pause]: 'pause_circle_outline',
    [RECURRENT_PAYMENTS_STATUSES.error]: 'error_outline',
    [RECURRENT_PAYMENTS_STATUSES.block]: 'block'
  }
}

const colors = {
  [RECURRENT_PAYMENTS_STATUSES.init]: 'grey',
  [RECURRENT_PAYMENTS_STATUSES.ready]: 'success',
  [RECURRENT_PAYMENTS_STATUSES.pause]: 'primary',
  [RECURRENT_PAYMENTS_STATUSES.error]: 'error',
  [RECURRENT_PAYMENTS_STATUSES.block]: 'error'
}

const _ = { get: require('lodash/get') }

function renderStatusIcon(h, size = 24, classes) {
  return h(
    'g-icon',
    {
      class: classes,
      props: {
        value: icons.status[this.value.status],
        color: colors[this.value.status],
        size
      }
    }
  )
}

function renderResult(h) {
  return h(
    'div',
    {
      class: 'faic pl-2'
    },
    [
      h(
        'g-icon',
        {
          class: 'mr-3',
          props: { value: 'rotate_right' }
        }
      ),

      h('div', { class: 'lh-n' }, this.result)
    ]
  )
}

function renderCardNumber(h) {
  const cardNumber = _.get(this.value, 'meta.bankData.cardNumber', '')

  if (cardNumber) {
    return h(
      'div',
      {
        class: 'faic pl-2'
      },
      [
        h(
          'g-icon',
          {
            class: 'mr-3',
            props: { value: 'credit_card' }
          }
        ),

        h('div', { class: 'lh-n' }, cardNumber.replace(/(.{4})(?!$)/g, '$1 '))
      ]
    )
  }
}

function renderPaymentsButton(h) {
  if (this.checkPermissions('advanced.payments.get', permissionPresets.me)) {
    return h(
      'g-button',
      {
        props: {
          icon: 'history',
          flat: true,
          rounded: true,
          color: 'grey'
        },
        directives: [
          {
            name: 'g-tooltip',
            options: {
              value: this.getTranslate(`${serviceName}.tooltips.payments`),
              placement: 'top'
            }
          }
        ],
        on: {
          click: () => {
            this.$emit('payments', this.value.id)
          }
        }
      }
    )
  }
}

function renderConfirmButton(h) {
  if (this.checkPermissions(`advanced.${serviceName}.update`, permissionPresets.me)) {
    if (~[ RECURRENT_PAYMENTS_STATUSES.init, RECURRENT_PAYMENTS_STATUSES.error ].indexOf(this.value.status)) {
      return h(
        'g-button',
        {
          props: {
            label: this.getTranslate(`${serviceName}.links.confirm`),
            flat: true,
            rounded: true,
            color: 'success'
          },
          on: {
            click: () => {
              this.repeatRecurrentPayment()
            }
          }
        }
      )
    }
  }
}

function renderActivateButton(h) {
  if (this.checkPermissions(`advanced.${serviceName}.update`, permissionPresets.me)) {
    if (!~[ RECURRENT_PAYMENTS_STATUSES.init, RECURRENT_PAYMENTS_STATUSES.error ].indexOf(this.value.status)) {
      return h(
        'g-button',
        {
          props: {
            label: this.getTranslate(`${serviceName}.buttons.${this.isPaused ? 'resume' : 'pause'}`),
            flat: true,
            rounded: true,
            color: this.isPaused ? 'success' : 'primary'
          },
          on: {
            click: () => {
              this.activateRecurrentPayment()
            }
          }
        }
      )
    }
  }
}

function renderRemoveButton(h) {
  if (this.checkPermissions(`advanced.${serviceName}.remove`, permissionPresets.me)) {
    return h(
      'g-button',
      {
        props: {
          label: this.getTranslate('misc.buttons.remove'),
          flat: true,
          rounded: true,
          color: 'error'
        },
        on: {
          click: () => {
            this.$emit('removeRecurrentPayment', this.value.id)
          }
        }
      }
    )
  }
}

function renderListItem(h, { permission, accept = permissionPresets.me, validation, callback, icon, color, pathToTranslate }) {
  if (this.checkPermissions(permission, accept)) {
    if (validation) {
      return h(
        'g-list-item',
        {
          on: {
            click: callback
          }
        },
        [
          h(
            'g-icon',
            {
              class: 'mr-3',
              props: {
                value: icon,
                color
              }
            }
          ),

          this.getTranslate(pathToTranslate)
        ]
      )
    }
  }
}

function renderMenu(h) {
  if (this.checkPermissions(`advanced.${serviceName}.update`, permissionPresets.me)) {
    return h(
      'g-menu',
      {
        class: { 'hover-child': this.viewport.breakpoint.mdUp },
        props: {
          rounded: true,
          placement: 'bottom-end',
          offsetDistance: 8
        }
      },
      [
        h(
          'g-button',
          {
            class: 'ma-0',
            props: {
              icon: 'more_vert',
              flat: true
            },
            slot: 'activator'
          }
        ),

        h(
          'g-list',
          {
            props: {
              rounded: true,
              dense: true
            }
          },
          [
            renderListItem.call(this, h, {
              permission: `advanced.${serviceName}.update`,
              validation: ~[ RECURRENT_PAYMENTS_STATUSES.init, RECURRENT_PAYMENTS_STATUSES.error ].indexOf(this.value.status),
              callback: this.repeatRecurrentPayment,
              icon: 'verified_user',
              color: 'success',
              pathToTranslate: `${serviceName}.buttons.confirm`
            }),
            renderListItem.call(this, h, {
              permission: 'advanced.payments.get',
              validation: true,
              callback: () => {
                this.$emit('payments', this.value.id)
              },
              icon: 'history',
              pathToTranslate: `${serviceName}.buttons.payments`
            }),
            renderListItem.call(this, h, {
              permission: `advanced.${serviceName}.update`,
              validation: !~[ RECURRENT_PAYMENTS_STATUSES.init, RECURRENT_PAYMENTS_STATUSES.error ].indexOf(this.value.status),
              callback: this.activateRecurrentPayment,
              icon: this.isPaused ? 'play_arrow' : 'pause',
              color: 'primary',
              pathToTranslate: `${serviceName}.buttons.${this.isPaused ? 'resume' : 'pause'}`
            }),
            renderListItem.call(this, h, {
              permission: `advanced.${serviceName}.update`,
              validation: this.value.status === RECURRENT_PAYMENTS_STATUSES.block,
              callback: () => {
                this.$emit('unlockRecurrentPayment', this.value.id)
              },
              icon: 'done',
              color: 'success',
              pathToTranslate: 'misc.buttons.unlock'
            }),
            renderListItem.call(this, h, {
              permission: `advanced.${serviceName}.update`,
              validation: true,
              callback: () => {
                this.$emit('removeRecurrentPayment', this.value.id)
              },
              icon: 'delete',
              color: 'error',
              pathToTranslate: 'misc.buttons.remove'
            })
          ]
        )
      ]
    )
  }
}

function renderSum() {
  return getPrice(this.value.amount)
}

function renderTitle(h) {
  return h(
    'div',
    {
      class: 'faic grid grid-gap--8',
      style: { 'grid-template-columns': 'repeat(2, minmax(min-content, max-content))' }
    },
    [
      renderSum.call(this, h),

      h('div', { class: 'text--grey' }, this.result)
    ]
  )
}

function renderStatus(h) {
  return h(
    'div',
    {
      class: 'faic grid grid-gap--2',
      style: { 'grid-template-columns': '15px 1fr' }
    },
    [
      renderStatusIcon.call(this, h, 15),

      h(
        'div',
        {
          class: `text--${colors[this.value.status]}`
        },
        this.getTranslate(`${serviceName}.statuses.${this.value.status}`)
      )
    ]
  )
}

function renderInfo(h) {
  return h(
    'div',
    {
      class: 'faic grid grid-gap--4',
      style: { 'grid-template-columns': 'repeat(2, minmax(min-content, max-content))' }
    },
    [
      renderStatus.call(this, h),

      h(
        SingleLink,
        {
          props: {
            value: this.value.Owner,
            type: 'link'
          }
        }
      )
    ]
  )
}

function renderDescription(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--2'
    },
    [
      renderTitle.call(this, h),
      renderInfo.call(this, h)
    ]
  )
}

function renderDates(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--2 text-right text--grey'
    },
    [
      h('div', {}, getLocaleTimeString(this.value.createdAt)),
      h('div', {}, getLocaleDateString(this.value.createdAt))
    ]
  )
}

function renderPanel(h) {
  return h(
    'g-expansion-panel',
    {
      props: { preventClick: true },
      scopedSlots: {
        header: () => {
          return h(
            'div',
            {
              class: 'faic grid grid-gap--8 cursor-default hover-parent hover-parent--opacity caption py-1 px-2',
              style: { 'grid-template-columns': '1fr 36px minmax(min-content, max-content)' }
            },
            [
              renderDescription.call(this, h),
              renderMenu.call(this, h),
              renderDates.call(this, h)
            ]
          )
        }
      },
      key: this.value.id
    }
  )
}

function renderCard(h) {
  return h(
    'g-card',
    {
      class: 'grid grid-cols--1 pa-2',
      props: {
        rounded: true,
        minHeight: 300
      }
    },
    [
      h(
        'div',
        {
          class: 'faic fjcsb pl-2'
        },
        [
          h('div', { class: 'headline' }, getPrice(this.value.amount)),

          renderPaymentsButton.call(this, h)
        ]
      ),

      h(
        'div',
        {
          class: 'faic pl-2'
        },
        [
          renderStatusIcon.call(this, h, 24, 'mr-3'),

          h('div', { class: 'lh-n' }, this.getTranslate(`${serviceName}.hints.statuses.${this.value.status}`))
        ]
      ),

      renderCardNumber.call(this, h),
      renderResult.call(this, h),

      h('div', { class: 'ff' }),

      h(
        'div',
        {
          class: 'fc faic'
        },
        [
          renderConfirmButton.call(this, h),
          renderActivateButton.call(this, h),

          h('g-divider', { class: 'my-2' }),

          renderRemoveButton.call(this, h)
        ]
      )
    ]
  )
}

export default function(h) {
  switch (this.type) {
    case 'panel': {
      return renderPanel.call(this, h)
    }

    case 'card':
    default: {
      return renderCard.call(this, h)
    }
  }
}
