export default {
  title: 'WAPI',

  titles: {
    create: 'Create instance'
  },

  labels: {
    search: 'Search',
    phone: 'Phone',
    isPaid: 'Paid state',
    isCanceled: 'Auto renew',
    instance: {
      id: 'ID',
      state: 'Instance state',
      apiKey: 'API key',
      apiUrl: 'API url',
      expiration: 'Expiration time'
    },
    owner: 'Owner'
  },

  states: {
    isPaid: {
      true: 'Paid',
      false: 'Not paid',
      undefined: 'Not paid'
    },
    instance: {
      authorized: 'Authorized',
      notAuthorized: 'Not authorized',
      sleep: 'Restarting',
      deleted: 'Deleted',
      undefined: 'State not defined'
    }
  },

  hints: {
    create: 'In accordance with the tariff for creating an Instance, your account will be charged {payment}.',
    notFound: 'Not found any instance',
    phoneNotFound: 'Phone is not verified',
    deviceLinkBeta: 'To connect a device to an instance, enable the "Multi-device beta" feature in your WhatsApp.',
    logoutInstance: 'Confirm instance deauthorization?',
    isActive: 'The instance has been deactivated by an administrator. Contact support.',
    badInstance: 'Bad instance'
  },

  links: {
    showExample: 'Show example',
    requestNewQrCode: 'Request new QR code'
  },

  tooltips: {
    favorites: 'Make the instance a favorite',
    isActive: 'Activate or deactivate a instance.',
    scanQrCode: 'Scan QR code',
    renewInstance: 'Renew instance',
    logoutInstance: 'Logout instance'
  },

  errors: {
    'instance-expired': 'The instance has expired. Need to update it with subsequent payment.',
    'instance-removed': 'Instance deleted, go to the instance list page and refresh it.',
    'instance-authorized': 'Instance is already authorized, refresh the page.',
    'phone-already-used': 'This phone is already linked to another instance.',
    'tariff-required': 'Your account is not linked to the required rate. Contact support.'
  },

  snackbars: {
    created: 'Instance successfully created.',
    updated: 'Instance successfully updated.',
    removed: 'Instance successfully removed.'
  }
}
