const fields = {
  title: 'Заголовок',
  owner: 'Владелец',
  requisite: 'Реквизит',
  company: 'Компания',
  createdAt: 'Создано',
  updatedAt: 'Обновлено'
}

export default {
  title: 'Договоры',

  titles: {
    create: 'Создать договор',
    upload: 'Загрузить существующий договор',
    confirm: {
      remove: 'Подтверждение удаления'
    }
  },

  headers: {
    ...fields
  },

  labels: {
    ...fields
  },

  buttons: {
    attach: 'Прикрепить файл'
  },

  actions: {
    edit: 'Редактировать',
    download: 'Скачать',
    remove: 'Удалить'
  },

  contents: {
    confirm: {
      remove: 'Подтвердите удаление договора'
    }
  },

  snackbars: {
    created: 'Договор успешно создан.',
    updated: 'Договор успешно обновлён.',
    removed: 'Договор успешно удалён.'
  }
}
