export default function(h) {
  try {
    return h(
      require(`@/components/services/tariffs/item/${this.type}`).default,
      {
        props: {
          value: this.proxy,
          size: this.size
        }
      }
    )
  } catch (error) {
    return
  }
}
