export default {
  title: 'Операторы',

  labels: {
    operator: 'Оператор',
    price: 'Цена'
  },

  snackbars: {
    created: 'Оператор создан',
    updated: 'Оператор обновлен',
    removed: 'Оператор удален'
  }
}
