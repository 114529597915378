import { upgradeArray } from '@sigma-legacy-libs/essentials/lib/utils'

export const colors = upgradeArray([
  'primary',
  'secondary',
  'info',
  'warning',
  'error',
  'success',
  'accent',
  'white',
  'black',
  'grey',
  'gray'
])

export const colorsHex = {
  primary: '#1976d2',
  secondary: '#ffa726',
  info: '#2196f3',
  warning: '#ffc107',
  error: '#f44336',
  success: '#4caf50',
  accent: '#2196f3',
  white: '#ffffff',
  black: '#000000',
  grey: '#9e9e9e',
  gray: '#9e9e9e'
}

export default {
  colors,
  colorsHex
}
