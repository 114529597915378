import scanQrCode from '@/components/services/apisender/buttons/scanQrCode'

import { serviceName, stateColors } from '@/components/services/apisender/utils'

function renderPreLoader(h) {
  if (this.loading) {
    return h(
      'g-progress',
      {
        props: {
          indeterminate: true,
          size: 24,
          width: 1
        }
      }
    )
  }
}

function renderInstanceInfo(h) {
  return h(
    'div',
    {
      class: 'faic caption'
    },
    [
      h(
        'div',
        {
          class: 'text--grey mr-1'
        },
        this.getTranslate('sendings.hints.apisender')
      ),

      h(
        'g-chip',
        {
          props: {
            label: this.getTranslate(`${serviceName}.states.instance.${this.instance.$state}`),
            color: stateColors[this.instance.$state],
            tiny: true
          }
        }
      ),
      h(
        'g-chip',
        {
          props: {
            label: this.getTranslate(`${serviceName}.states.isPaid.${this.instance.isPaid}`),
            color: this.instance.isPaid ? 'success' : 'error',
            tiny: true
          }
        }
      )
    ]
  )
}

function renderInstance(h) {
  if (this.instance) {
    return h(
      'div',
      {
        class: 'grid grid-gap--8 faic',
        style: { 'grid-template-columns': '24px 1fr 36px' }
      },
      [
        h(
          'div',
          {
            class: 'faic fjcc ff'
          },
          [
            h(
              'a',
              {
                attrs: { href: `#/apisender/${this.instance.id}` },
                class: 'link link--passive square--24'
              },
              [
                h(
                  'g-icon',
                  {
                    props: {
                      value: 'link',
                      color: 'grey'
                    }
                  }
                )
              ]
            )
          ]
        ),

        renderInstanceInfo.call(this, h),

        h(
          scanQrCode,
          {
            props: {
              value: this.instance
            },
            on: {
              update: () => {
                this.getInstance()
              }
            }
          }
        )
      ]
    )
  }
}

export default function(h) {
  if (this.instance || this.loading) {
    return h(
      'div',
      {
        class: 'faic fjcc'
      },
      [
        renderInstance.call(this, h),
        renderPreLoader.call(this, h)
      ]
    )
  }
}
