import { leadZero } from '@/utils'

export function getToday() {
  const gt = new Date()
  gt.setHours(0, 0, 0, 0)
  const lt = new Date(gt)
  lt.setDate(gt.getDate() + 1)

  return {
    $lt: lt.toISOString(),
    $gt: gt.toISOString()
  }
}

export function getChosenDay($gt, $lt) {
  const gt = new Date()
  gt.setHours(0, 0, 0, 0)
  const lt = new Date(gt)
  if ($gt) {
    gt.setDate(gt.getDate() + $gt)
  }
  lt.setDate(lt.getDate() + 1)
  lt.setHours(0, 0, 0, 0)
  if ($lt) {
    lt.setDate(lt.getDate() + $lt)
  }

  return {
    $lt: lt.toISOString(),
    $gt: gt.toISOString()
  }
}
export function getChosenMonth($gt, $lt) {
  const gt = new Date()
  gt.setHours(0, 0, 0, 0)
  const lt = new Date(gt)
  if ($gt) {
    gt.setMonth(gt.getMonth() + $gt)
  }
  lt.setDate(lt.getDate() + 1)
  lt.setHours(0, 0, 0, 0)
  if ($lt) {
    lt.setMonth(lt.getMonth() + $lt)
  }

  return {
    $lt: lt.toISOString(),
    $gt: gt.toISOString()
  }
}

export function getHammerTime(date = new Date().setHours(0, 0, 0, 0)) {
  const parseDate = new Date(date)

  return [ leadZero(parseDate.getHours()), leadZero(parseDate.getMinutes()) ].join(':')
}

export function getISODate(date) {
  if (date) {
    const parseDate = new Date(date)

    return [
      parseDate.getFullYear(),
      leadZero(parseDate.getMonth() + 1),
      leadZero(parseDate.getDate())
    ].join('-')
  }
}

export function getUnix(date, s) {
  if (date) {
    return Date.parse(new Date(date)) / (s ? 1000 : 1)
  }
}

export function getRUDate(date) {
  if (date) {
    const parseDate = new Date(date)

    return [
      leadZero(parseDate.getDate()),
      leadZero(parseDate.getMonth() + 1),
      parseDate.getFullYear()
    ].join('.')
  }
}

export function getLocaleDateString(
  date,
  locale = 'ru',
  options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric'
  }
) {
  return new Date(date).toLocaleDateString(locale, options)
}
export function getLocaleTimeString(date, options = {}) {
  const params = {
    hour: options.hour || '2-digit',
    minute: options.minute || '2-digit',
    second: options.second || '2-digit'
  }
  const locale = options.locale || 'ru'
  const source = new Date(date)
  const time = source.toLocaleTimeString(locale, params)
  const ms = `${source.getMilliseconds()}`.padStart(3, '0')

  return options.milliseconds ? time + `.${ms}` : time
}

export function computeDateDate(plusDate = 0, relativeDate = new Date()) {
  return relativeDate.setDate(relativeDate.getDate() + plusDate)
}

export function getYesterday() {
  return computeDateDate(-1)
}
export function getTomorrow() {
  return computeDateDate(1)
}

export function computeDateHours(plusHours = 0, relativeDate = new Date()) {
  return relativeDate.setHours(relativeDate.getHours() + plusHours)
}

export function appendFormattedDates(object = {}, fieldsForFormatting = [ 'createdAt', 'updatedAt' ], options = {}) {
  for (let i = 0; i < fieldsForFormatting.length; i++) {
    if (object[fieldsForFormatting[i]]) {
      const parseDate = new Date(object[fieldsForFormatting[i]])
      let time = ''
      if (!options.noTime) {
        time += parseDate.toLocaleTimeString(this.locale, {
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit'
        })
      }
      if (!options.noTime && !options.noDate) {
        time += '\n'
      }
      if (!options.noDate) {
        time += parseDate.toLocaleDateString(this.locale, {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric'
        })
      }
      object[`$formatted${fieldsForFormatting[i][0].toUpperCase() + fieldsForFormatting[i].substring(1)}`] = time
    }
  }

  return object
}

export function getUnixSeconds() {
  return parseInt(Date.now() / 1000)
}
export function getUnixMilliseconds() {
  return parseInt(Date.now())
}

export default {
  getToday,
  getChosenDay,
  getChosenMonth,
  getHammerTime,
  getISODate,
  getRUDate,
  getLocaleDateString,
  getLocaleTimeString,
  computeDateDate,
  computeDateHours,
  getYesterday,
  getTomorrow,
  getUnix,
  getUnixSeconds,
  getUnixMilliseconds
}
