function renderButton(h, slot) {
  return h(
    'g-button',
    {
      class: 'ma-0',
      props: {
        label: this.activatorContent,
        icon: 'date_range',
        flat: true,
        rounded: true
      },
      on: {
        click: () => {
          if (!slot) {
            this.showDialog = true
          }
        }
      },
      slot,
      scopedSlots: {
        dialog: () => !slot ? renderDialog.call(this, h) : undefined
      }
    }
  )
}

function renderPresets(h) {
  if (this.viewport.breakpoint.smUp) {
    return h(
      'g-list',
      {
        style: {
          'border-top-left-radius': '16px',
          'border-top-right-radius': this.viewport.breakpoint.smDown ? '16px' : undefined
        },
        class: 'overflow--hidden'
      },
      this.rangePresets.map(({ title, date, time }) => {
        return h(
          'g-list-item',
          {
            class: 'text-upper',
            props: { label: this.getTranslate(title) },
            on: {
              click: () => {
                this.proxy.date = date
                this.proxy.time = time
              }
            }
          }
        )
      })
    )
  } else {
    return h(
      'div',
      {
        class: 'fw fjcc',
        style: { 'max-width': '340px' }
      },
      this.rangePresets.map(({ title, date, time }) => {
        return h(
          'g-button',
          {
            props: {
              flat: true,
              rounded: true,
              label: this.getTranslate(title)
            },
            on: {
              click: () => {
                this.proxy.date = date
                this.proxy.time = time
              }
            }
          }
        )
      })
    )
  }
}

function renderPicker(h) {
  return h(
    'div',
    {
      class: 'fc ff'
    },
    [
      h(
        'g-date-picker',
        {
          props: {
            value: this.proxy.date,
            localeTag: this.locale
          },
          on: {
            input: event => {
              this.proxy.date = event
            }
          }
        }
      ),

      h(
        'div',
        {
          class: 'grid grid-cols--2 grid-gap--8 pa-2'
        },
        [
          h(
            'g-text-field',
            {
              props: {
                value: this.proxy.time.$gt,
                type: 'time',
                mode: 'outline',
                dense: true,
                rounded: true,
                details: false
              },
              on: {
                input: event => {
                  this.proxy.time.$gt = event
                }
              }
            }
          ),

          h(
            'g-text-field',
            {
              props: {
                value: this.proxy.time.$lt,
                type: 'time',
                mode: 'outline',
                dense: true,
                rounded: true,
                details: false
              },
              on: {
                input: event => {
                  this.proxy.time.$lt = event
                }
              }
            }
          )
        ]
      )
    ]
  )
}

function renderForm(h) {
  return h(
    'div',
    {
      class: {
        faifs: this.viewport.breakpoint.smUp,
        'fc ff faic': this.viewport.breakpoint.xs
      }
    },
    [
      renderPresets.call(this, h),
      renderPicker.call(this, h)
    ]
  )
}

function renderDialog(h) {
  return h(
    'g-dialog',
    {
      props: {
        value: this.showDialog,
        fullscreen: true
      },
      on: {
        input: event => {
          this.showDialog = event
        }
      }
    },
    [
      renderForm.call(this, h),

      h(
        'g-card-actions',
        {
          class: 'fjcfe',
          slot: 'footer'
        },
        [
          h(
            'g-button',
            {
              props: {
                flat: true,
                rounded: true,
                label: this.getTranslate('misc.buttons.confirm'),
                color: 'primary'
              },
              on: {
                click: () => {
                  this.showDialog = false
                }
              }
            }
          )
        ]
      )
    ]
  )
}

function renderContent(h) {
  if (this.viewport.breakpoint.smUp) {
    return h(
      'g-menu',
      {
        props: {
          rounded: true,
          placement: 'bottom-end',
          offsetDistance: 8,
          closeOnContentClick: false
        }
      },
      [
        renderButton.call(this, h, 'activator'),
        renderForm.call(this, h)
      ]
    )
  } else {
    return renderButton.call(this, h)
  }
}

export default function(h) {
  return renderContent.call(this, h)
}
