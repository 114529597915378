import Vue from 'vue'

import { unVue } from '@sigma-legacy-libs/unvue'

import { getHammerTime, getLocaleDateString, globalErrorHandler, globalErrorProcessor } from '@/utils'

import render from './render'

const defaultDate = {
  date: undefined,
  time: getHammerTime()
}

export default {
  name: 'TasksActionButtons',

  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      loading: false,

      changeableDate: unVue(defaultDate),

      showSourceDataDialog: false,
      showConfirmRemoveDialog: false
    }
  },

  computed: {
    computedDate() {
      if (this.changeableDate.date) {
        return new Date(this.changeableDate.date + 'T' + this.changeableDate.time)
      }

      return null
    },

    scheduledDate() {
      if (this.computedDate) {
        return `${this.getTranslate('tasks.schedule.delay')} ${getLocaleDateString(this.computedDate)}`
      }

      return this.getTranslate('tasks.schedule.now')
    }
  },

  methods: {
    async updateTask(id, state, startsAt = null) {
      try {
        this.loading = true
        await Vue.$GRequest.update('tasks', id, {
          state,
          startsAt
        })
        this.addSnackbar({
          text: this.getTranslate('tasks.snackbars.updated'),
          type: 'success'
        })
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      } finally {
        this.loading = false
      }
    },

    async removeTask(id) {
      try {
        await Vue.$GRequest.remove('tasks', id)
        this.addSnackbar({
          text: this.getTranslate('tasks.snackbars.removed'),
          type: 'warn'
        })
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      }
    }
  },

  render
}
