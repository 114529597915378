import render from './render'

const _ = { get: require('lodash/get') }

function floor(value, divider) {
  if (divider > 0) {
    return Math.floor(value / divider * 100)
  }

  return 0
}

export default {
  name: 'TasksCounts',

  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    counts() {
      const total = _.get(this.value, 'meta.total', 0)
      const completed = _.get(this.value, 'meta.completed', 0)
      const errored = _.get(this.value, 'meta.errored', 0)
      const remaining = total - (completed + errored)

      return {
        values: {
          total,
          completed,
          errored,
          remaining
        },
        tooltips: {
          total: `${this.getTranslate('tasks.misc.receivers')}: ${total}`,
          completed: `${this.getTranslate('tasks.misc.sent')}: ${completed} (${floor(completed, total)}%)`,
          errored: `${this.getTranslate('tasks.misc.errored')}: ${errored} (${floor(errored, total)}%)`,
          remaining: `${this.getTranslate('tasks.misc.rest')}: ${remaining} (${floor(remaining, total)}%)`
        }
      }
    }
  },

  render
}
