function renderItem(h, item) {
  return h(
    'div',
    {
      class: {
        'mobile-panel-item': true,
        'mobile-panel-item--active': this.tab.name === item.name
      },
      on: {
        click: () => {
          this.itemProcessor(item)
        }
      }
    },
    [
      h(
        'g-icon',
        {
          props: {
            value: item.icon,
            size: 32
          }
        }
      ),

      h(
        'div',
        {
          class: 'mobile-panel-item-title'
        },
        this.getTranslate(`mobilePanel.title.${item.name}`)
      )
    ]
  )
}

export default function(h) {
  if (this.viewport.breakpoint.smDown) {
    return h(
      'g-footer',
      {
        class: 'mobile-panel',
        props: {
          fixed: true,
          color: 'primary',
          height: 70
        }
      },
      this.items.map(item => {
        return renderItem.call(this, h, item)
      })
    )
  }
}
