import proxy from '@sigma-legacy-libs/g-proxy'

import render from './render'

export default {
  name: 'ColorPicker',

  mixins: [ proxy() ],

  props: {
    defaultValue: {
      type: String,
      default: '#CCCCCC'
    },

    label: String,
    hint: String
  },

  data() {
    return {
      showColorSwatch: false,
      showEditValueInput: false
    }
  },

  render
}
