export default function(h, { path, serviceName }) {
  return h(
    'g-switch',
    {
      props: {
        value: this.proxy,
        label: this.getTranslate(`${serviceName}.labels.${path}`),
        falseValue: false,
        trueValue: true,
        color: 'primary'
      },
      on: {
        input: event => {
          this.proxy = event
        }
      }
    }
  )
}
