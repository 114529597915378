import proxy from '@sigma-legacy-libs/g-proxy'

import { generateRegExpFromString } from '@sigma-legacy-libs/essentials/lib/utils/regexp'

import { serviceName } from '@/components/services/routingRules/utils'

import ConditionsFrontend from '@/utils/classes/Conditions'

import render from './render'

const _ = { debounce: require('lodash/debounce') }

export default {
  name: 'Dialog',

  mixins: [ proxy() ],

  props: {
    tag: String,
    tagEdit: Boolean,

    tagDisabled: {
      type: Array,
      default: () => []
    },

    show: Boolean,

    readonly: Boolean
  },

  data() {
    return {
      pattern: undefined,
      text: undefined,
      phone: undefined,
      phoneCheck: undefined,
      phoneCheckLoading: false,
      error: undefined
    }
  },

  watch: {
    proxy: {
      handler() {
        this.error = undefined
        this.phoneCheck = undefined
        this.checkPhone()
      },
      deep: true
    },
    phone() {
      this.checkPhone()
    }
  },

  methods: {
    _inputFilter(data) {
      if (!data && this.tag) {
        switch (this.tag) {
          case 'group':
          case 'sender':
          case 'keywords':
          case 'contactList':
          case 'type': {
            data = []
            break
          }
          case 'mccmnc': {
            data = {}
            break
          }
        }
      }

      return data
    },

    checkPattern() {
      if (this.tag === 'text') {
        if (this.text) {
          try {
            const pattern = generateRegExpFromString(this.value)

            return pattern.test(this.text)
          } catch (error) {
            this.error = this.getTranslate(`${serviceName}.errors.regexp`)

            return false
          }
        }

        return true
      }
    },

    checkPhone: _.debounce(async function() {
      if (this.phone) {
        this.phoneCheckLoading = true
        const result = await ConditionsFrontend.check({
          tag: this.tag,
          value: this.value
        },
        { payload: { recipient: ~this.phone.indexOf('+') ? this.phone : '+' + this.phone } })
        if (result) {
          this.phoneCheck = 'success'
          this.error = undefined
        } else {
          this.phoneCheck = 'error'
          this.error = this.getTranslate(`${serviceName}.errors.phone`)
        }
        this.phoneCheckLoading = false
      } else {
        this.phoneCheck = undefined
        this.error = undefined
      }
    }, 500, {
      leading: false,
      trailing: true
    })
  },

  render
}
