const _ = { camelCase: require('lodash/camelCase') }

export function globalErrorProcessor(errors = []) {
  if (!Array.isArray(errors)) {
    errors = [ errors ]
  }

  const result = []

  for (const source of errors) {
    const { error, code, data, type, message } = source

    if (process.env.NODE_ENV === 'development') {
      result.push({
        type: 'console',
        raw: source
      })
    }

    switch (error || code) {
      case 422: {
        if (Array.isArray(data)) {
          for (const item of data) {
            result.push({
              type: item.type,
              field: item.field,
              translate: item.type
                ? this.getTranslate(`errors.types.${_.camelCase(item.type)}`, {
                  field: item.field && this.getTranslate(`errors.fields.${item.field}`) || undefined,
                  expected: item.expected || undefined
                })
                : item.message,
              message: item.message,
              raw: source
            })
          }
        }
        break
      }
      case 429: {
        result.push({
          type: 'snackbar',
          translate: this.getTranslate('errors.types.rateLimitExceeded', { count: source['retry-after'] }) || message,
          raw: source
        })
        break
      }
      case 500: {
        result.push({
          type: 'snackbar',
          translate: message,
          raw: source
        })
        break
      }
      default: {
        result.push({
          type: 'snackbar',
          translate: type ? this.getTranslate(`errors.types.${type}`) : message,
          raw: source
        })
        break
      }
    }
  }

  return result
}

export function globalErrorHandler(errors = []) {
  if (!Array.isArray(errors)) {
    errors = [ errors ]
  }

  for (const error of errors) {
    const { type, translate, message } = error

    switch (type) {
      case 'console': {
        console.error(error.raw)
        break
      }

      case 'field':
      case 'snackbar':
      default: {
        this.addSnackbar({
          text: translate || message || this.getTranslate('errors.types.serverNotAvailable'),
          type: 'error'
        })
        break
      }
    }
  }
}

export default {
  globalErrorProcessor,
  globalErrorHandler
}
