import render from './render'

export default {
  name: 'RowWithMessageTypeIcon',

  functional: true,

  props: {
    value: String,
    title: String,

    as: {
      type: String,
      default: 'text',
      validator: value => {
        return ~[ 'text', 'chip' ].indexOf(value)
      }
    },

    remove: Function,

    size: {
      type: Number,
      default: 20
    }
  },

  render
}
