import Copyright from '@/components/misc/Copyright'
import LocaleSwitch from '@/components/misc/LocaleSwitch'

function renderLinks(h) {
  let name = 'login'
  switch (this.$route.name) {
    case 'login': {
      name = 'registration'
      break
    }

    case 'documentation':
    case 'documentation.catgory':
    case 'documentation.file':
    default: {
      name = 'login'
      break
    }
  }

  return h(
    'router-link',
    {
      class: 'link caption',
      props: { to: { name } }
    },
    this.getTranslate(`misc.buttons.${name}`)
  )
}

export default function(h) {
  return h(
    'g-app',
    {
      class: 'pa-3',
      props: { center: true }
    },
    [
      h(
        'g-content',
        {
          class: 'faic'
        },
        [ this.$slots.default ]
      ),

      h(
        'g-footer',
        {
          class: {
            'grid grid-gap--8 px-2': true,
            'fjcc facc': this.viewport.breakpoint.mdUp
          },
          style: { 'grid-template-columns': 'repeat(3, minmax(min-content, max-content))' },
          props: {
            fixed: true,
            height: 36
          }
        },
        [
          h(Copyright),
          h(LocaleSwitch),

          renderLinks.call(this, h)
        ]
      )
    ]
  )
}
