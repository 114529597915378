import render from './render'

import { generateServices } from '@/utils'

import { serviceName } from '@/components/services/operatorPrices/utils'

export default {
  name: 'Template',

  mixins: [ generateServices({ name: serviceName }) ],

  data() {
    return {
      operator: {
        operator: undefined,
        price: undefined
      },
      operators: {}
    }
  },

  async mounted() {
    const data = await this.rest[serviceName].find()
    this.operators = data.reduce((result, operator) => {
      result[operator.operator] = operator

      return result
    }, {})
  },

  render
}
