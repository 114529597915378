import Vue from 'vue'

import { EMPTY_USERS, SENDING_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import { allowedSendingTypes, isArrayNotEmpty, platinumAccounts } from '@/utils'

const _ = {
  toPlainObject: require('lodash/toPlainObject'),
  mergeWith: require('lodash/mergeWith')
}

export default {
  namespaced: true,

  state: {
    account: EMPTY_USERS,
    sendingTypes: SENDING_TYPES.reduce((result, type) => {
      if (allowedSendingTypes.includes(type.value)) {
        result.push({
          title: `sendings.types.${type}`,
          value: type
        })
      }

      return result
    }, []),
    isPlatinum: false
  },

  mutations: {
    fillAccount(state, account) {
      account = _.toPlainObject(account)
      state.account = _.mergeWith({}, EMPTY_USERS, account, (objValue, srcValue) => {
        return Array.isArray(srcValue) ? srcValue : undefined
      })
    },

    setSendingTypes(state, types) {
      state.sendingTypes = types
    },
    setPlatinumStatus(state, status) {
      state.isPlatinum = status
    }
  },

  actions: {
    async getAccount({ commit, dispatch }, userId) {
      const { data } = await Vue.$GRequest.get('users', userId, { query: { $scope: [ 'full' ] } })
      if (data) {
        const types = []
        for (const type of allowedSendingTypes) {
          if (
            isArrayNotEmpty(data.Tariffs) &&
            data.Tariffs.find(tariff => tariff.source === `sendings.${type}`) &&
            isArrayNotEmpty(data.RoutingGroups) &&
            data.RoutingGroups.find(routingGroup => routingGroup.type === type)
          ) {
            types.push({
              title: `sendings.types.${type}`,
              value: type
            })
          }
        }

        commit('setSendingTypes', types)

        if (data.id) {
          commit('setPlatinumStatus', !!~platinumAccounts.indexOf(data.id))
        }

        const { permissions } = await dispatch('settings/getGlobalSettings', null, { root: true })
        data.permissions = permissions

        commit('fillAccount', data)
      }
    },

    setAccount({ commit }, account) {
      commit('fillAccount', account)
    },
    setPlatinumStatus({ commit }, status) {
      commit('setPlatinumStatus', status)
    }
  },

  getters: {
    account: state => state.account,
    sendingTypes: state => state.sendingTypes,
    isPlatinum: state => state.isPlatinum
  }
}
