import { PAYMENTS_METHODS } from '@sigma-legacy-libs/essentials/lib/constants'

import PaymentsForm from '@/components/services/payments/form'

function renderForm(h) {
  if (this.$route.params.owner) {
    return h(
      'g-card',
      {
        class: 'pa-5',
        props: {
          rounded: true,
          maxWidth: 400
        }
      },
      [
        h(
          PaymentsForm,
          {
            props: {
              value: this.payload,
              methods: PAYMENTS_METHODS
            },
            on: {
              input: event => {
                this.payload = event
              }
            }
          }
        ),

        h(
          'div',
          {
            class: 'fjcfe facfe ff faic'
          },
          [
            h(
              'g-button',
              {
                class: 'ma-0',
                props: {
                  label: this.getTranslate('misc.buttons.pay'),
                  rounded: true,
                  depressed: true,
                  loading: this.loading,
                  disabled: !this.payload.amount || !this.payload.acceptOffer || this.loading,
                  large: this.viewport.breakpoint.smDown,
                  color: 'primary'
                },
                on: {
                  click: () => this.requestPayment()
                }
              }
            )
          ]
        )
      ]
    )
  } else {
    return h(
      'g-card',
      {
        class: 'pa-3',
        props: {
          rounded: true,
          maxWidth: 500
        }
      },
      [
        h(
          'div',
          {
            class: 'faic'
          },
          [
            h(
              'g-icon',
              {
                props: {
                  value: 'info',
                  color: 'error'
                }
              }
            ),

            h('div', { class: 'ml-3' }, this.getTranslate('pay.errors.owner'))
          ]
        )
      ]
    )
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: 'faic fjcfc h--100'
    },
    [ renderForm.call(this, h) ]
  )
}
