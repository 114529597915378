import { serviceName } from '@/components/services/keywords/utils'

import { STATES, generateServices } from '@/utils'

import render from './render'

export default {
  name: 'List',

  mixins: [
    generateServices([
      {
        name: serviceName,

        find: {
          defaultFilter: { $search: undefined },
          defaultPagination: { limit: 300 },
          appendMode: true
        }
      }
    ])
  ],

  props: {
    ListId: String
  },

  data() {
    return {
      showDialog: false,
      dialogType: 'create',

      keywordPayload: {
        id: undefined,
        regexp: undefined,
        ListId: undefined
      }
    }
  },

  computed: {
    id() {
      return this.ListId || this.$route.params.id
    },
    loading() {
      return this.restData[serviceName].update.state === STATES.loading || this.restData[serviceName].create.state === STATES.loading || this.restData[serviceName].remove.state === STATES.loading
    }
  },

  watch: {
    id() {
      this.rest[serviceName].find({ query: { ListId: this.id } })
    }
  },

  mounted() {
    this.rest[serviceName].find({ query: { ListId: this.id } })
  },

  methods: {
    clearPayload() {
      this.keywordPayload.id = undefined
      this.keywordPayload.regexp = undefined
      this.keywordPayload.ListId = undefined
    }
  },

  render
}
