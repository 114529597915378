import proxy from '@sigma-legacy-libs/g-proxy'

import render from './render'

export default function(options) {
  return {
    name: 'NotificationsEmailTemplatesManager',

    mixins: [ proxy({ type: 'object' }) ],

    render(h) {
      return render.call(this, h, options)
    }
  }
}
