export default function(h) {
  return h(
    'g-select',
    {
      attrs: { id: 'v-step-create-sender' },
      props: {
        value: this.proxy,
        label: this.label || this.getTranslate('sendings.labels.sender'),
        service: 'sendernames',
        query: {
          type: this.type,
          isActive: true,
          $scope: [ 'usable' ]
        },
        error: this.errors.sender,
        loading: this.loading,
        disabled: this.disabled,
        readonly: this.readonly,
        details: this.details,
        combobox: this.computedAnySenderName,
        mode: this.mode,
        rounded: this.rounded,
        dense: this.dense,
        itemTitle: 'name',
        itemValue: 'name',
        clearable: true,
        setOnBlur: true,
        autocomplete: true
      },
      on: {
        input: event => {
          this.$emit('input', event)
        },
        itemsFetched: this.checkSender
      }
    }
  )
}
