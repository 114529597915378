import SpacerCell from '@/components/misc/SpacerCell'
import ExpandArrow from '@/components/misc/ExpandArrow'
import OneRowString from '@/components/misc/OneRowString'
import TwoRowString from '@/components/misc/TwoRowString'
import RequisitesPreview from '@/components/services/requisites/preview'

import { serviceName } from '@/components/services/requisites/utils'

const _ = { get: require('lodash/get') }

function renderButtons(h) {
  return h(
    'div',
    {
      class: {
        'grid grid-gap--8': true,
        [`grid-cols--${this.checkPermissions(`advanced.${serviceName}.get`) ? 3 : 2}`]: true
      }
    },
    [
      h(RequisitesPreview, { props: { value: this.value } }),

      h(
        'g-button',
        {
          class: 'ma-0',
          props: {
            flat: true,
            rounded: true,
            icon: 'edit',
            color: 'primary'
          },
          on: {
            click: () => {
              this.$emit(`${serviceName}.update`, this.value.id)
            }
          }
        }
      ),

      h(
        'g-button',
        {
          class: 'ma-0',
          props: {
            flat: true,
            rounded: true,
            icon: 'delete',
            color: 'error'
          },
          on: {
            click: () => {
              this.$emit(`${serviceName}.remove`, this.value.id)
            }
          }
        }
      )
    ]
  )
}

function renderPanelRow(h, path) {
  const value = _.get(this.value, path)
  if (value) {
    return h(
      'tr',
      {},
      [
        h('td', {}, this.getTranslate(`${serviceName}.labels.${path}`)),
        h('td', {}, value)
      ]
    )
  }
}

function renderItemByType(h) {
  switch (this.type) {
    case 'table': {
      return h(
        'tr',
        {
          class: 'hover-parent hover-parent--opacity'
        },
        [
          h(
            'td',
            {
              class: 'pa-0'
            },
            [
              h(
                'g-checkbox',
                {
                  props: {
                    value: this.value.isActive,
                    disabled: true
                  },
                  directives: [
                    {
                      name: 'g-tooltip',
                      options: {
                        value: this.getTranslate(`commons.labels.isActive.${this.value.isActive}`)
                      }
                    }
                  ]
                }
              )
            ]
          ),

          h(
            'td',
            {
              class: 'pa-0'
            },
            [
              h(
                'g-icon',
                {
                  class: 'fjcc facc faic square--48',
                  props: {
                    value: this.value.type === 'business' ? 'work' : 'face',
                    color: 'grey'
                  },
                  directives: [
                    {
                      name: 'g-tooltip',
                      options: {
                        value: this.getTranslate(`${serviceName}.types.${this.value.type}`)
                      }
                    }
                  ]
                }
              )
            ]
          ),

          h(
            'td',
            {
              class: 'px-2'
            },
            [ h(SpacerCell, { props: { value: this.value.title } }) ]
          ),

          h(
            'td',
            {
              class: 'px-2'
            },
            [
              h(
                TwoRowString,
                {
                  props: {
                    first: this.value.data.legal.inn ? `${this.getTranslate(`${serviceName}.labels.data.legal.inn`)}: ${this.value.data.legal.inn}` : undefined,
                    second: this.value.data.legal.kpp ? `${this.getTranslate(`${serviceName}.labels.data.legal.kpp`)}: ${this.value.data.legal.kpp}` : undefined
                  }
                }
              )
            ]
          ),

          h(
            'td',
            {
              class: 'px-2'
            },
            [
              h(
                'div',
                {
                  class: 'fc'
                },
                [
                  h(
                    'div',
                    {
                      class: 'faic'
                    },
                    [
                      h(
                        'g-icon',
                        {
                          class: 'mr-1',
                          props: {
                            value: 'phone',
                            size: 12,
                            color: 'grey'
                          }
                        }
                      ),

                      h(OneRowString, { props: { value: this.value.contact.phone } })
                    ]
                  ),

                  h(
                    'div',
                    {
                      class: 'faic'
                    },
                    [
                      h(
                        'g-icon',
                        {
                          class: 'mr-1',
                          props: {
                            value: 'email',
                            size: 12,
                            color: 'grey'
                          }
                        }
                      ),

                      h(OneRowString, { props: { value: this.value.contact.email } })
                    ]
                  )
                ]
              )
            ]
          ),

          h(
            'td',
            {
              class: 'px-2'
            },
            [ renderButtons.call(this, h) ]
          )
        ]
      )
    }
    case 'panel': {
      return h(
        'g-expansion-panel',
        {
          scopedSlots: {
            header: ({ expanded }) => {
              return h(
                'div',
                {
                  class: 'default-expansion-panel-header pr-0'
                },
                [
                  this.value.title,

                  h('div', { class: 'ff' }),

                  h(ExpandArrow, { props: { expanded } })
                ]
              )
            },
            default: () => {
              return h(
                'div',
                {
                  class: 'fc ff'
                },
                [
                  h(
                    'table',
                    {
                      class: 'contacts-table'
                    },
                    [
                      renderPanelRow.call(this, h, 'title'),
                      renderPanelRow.call(this, h, 'contact.firstName'),
                      renderPanelRow.call(this, h, 'contact.middleName'),
                      renderPanelRow.call(this, h, 'contact.lastName'),
                      renderPanelRow.call(this, h, 'contact.email'),
                      renderPanelRow.call(this, h, 'contact.phone'),
                      renderPanelRow.call(this, h, 'signer.position.nominative'),
                      renderPanelRow.call(this, h, 'signer.position.genitive'),
                      renderPanelRow.call(this, h, 'signer.basis.nominative'),
                      renderPanelRow.call(this, h, 'signer.basis.genitive'),
                      renderPanelRow.call(this, h, 'signer.fullName.nominative'),
                      renderPanelRow.call(this, h, 'signer.fullName.genitive'),
                      renderPanelRow.call(this, h, 'data.legal.businessType'),
                      renderPanelRow.call(this, h, 'data.legal.name'),
                      renderPanelRow.call(this, h, 'data.legal.opf'),
                      renderPanelRow.call(this, h, 'data.legal.ogrn'),
                      renderPanelRow.call(this, h, 'data.legal.inn'),
                      renderPanelRow.call(this, h, 'data.legal.kpp'),
                      renderPanelRow.call(this, h, 'data.legal.okpo'),
                      renderPanelRow.call(this, h, 'bank.name'),
                      renderPanelRow.call(this, h, 'bank.rs'),
                      renderPanelRow.call(this, h, 'bank.ks'),
                      renderPanelRow.call(this, h, 'bank.bik')
                    ]
                  ),

                  h(
                    'div',
                    {
                      class: 'faic fjcc'
                    },
                    [ renderButtons.call(this, h) ]
                  )
                ]
              )
            }
          }
        }
      )
    }
  }

  return h('div')
}

export default function(h) {
  return renderItemByType.call(this, h)
}
