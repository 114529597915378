import proxy from '@sigma-legacy-libs/g-proxy'

import render from './render'

export default {
  name: 'DefaultRule',

  mixins: [ proxy({ type: 'object' }) ],

  props: {
    meta: {
      type: Object,
      default: () => {
        return {
          type: undefined,
          currency: undefined
        }
      }
    }
  },

  render
}
