import Chart from '@/components/misc/Chart'
import buttonCopy from '@/components/button/copy'
import messageTypeIcon from '@/components/message/type/icon'
import tasksState from '@/components/services/tasks/state'

const presets = [ 'sent', 'errored', 'rest' ]

function renderChart(h) {
  return h(
    'div',
    {
      class: 'fjcc facc faic square--40'
    },
    [
      h(
        Chart,
        {
          props: {
            type: 'doughnut',
            data: {
              labels: presets.map(preset => this.getTranslate(`tasks.misc.${preset}`)),
              datasets: [
                {
                  data: [ this.value.meta.completed, this.value.meta.errored, this.value.meta.total - (this.value.meta.completed + this.value.meta.errored) ],
                  backgroundColor: [ '#4caf50', '#f44336', '#ffa726' ],
                  borderWidth: 0
                }
              ]
            },
            options: {
              aspectRatio: 1,
              layout: { padding: 0 },
              plugins: {
                legend: { display: false },
                tooltip: { enabled: false }
              }
            }
          }
        }
      )
    ]
  )
}

function renderType(h) {
  return h(
    'div',
    {
      class: 'fjcc facc faic square--48'
    },
    [
      h(
        messageTypeIcon,
        {
          props: {
            value: this.value.data.sending.type
          }
        }
      )
    ]
  )
}

function renderInfo(h) {
  return h(
    buttonCopy,
    {
      class: 'ml-0',
      props: {
        value: this.value.title,
        tooltip: this.value.title,
        icon: 'info',
        color: 'grey',
        flat: true
      }
    }
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: 'faic'
    },
    [
      renderChart.call(this, h),
      renderType.call(this, h),
      renderInfo.call(this, h),

      h(tasksState, { props: { value: this.value } })
    ]
  )
}
