import messages from '@/messages'

import { isString, isStringNotEmpty } from '@/utils'

const _ = { get: require('lodash/get') }

export function getTranslate(path, options = {}) {
  if (isStringNotEmpty(path)) {
    let result = _.get(messages, [ options.locale || 'en', path ].join('.'))

    if (!isString(result)) {
      console.warn(`[getTranslate] Translation must be a string - ${path}`)

      return path
    }

    if (result === '') {
      return result
    }

    if (isStringNotEmpty(result)) {
      if (options) {
        Object.keys(options).forEach(key => {
          result = result.replace(`\{${key}\}`, options[key]) // eslint-disable-line
        })
      }

      return result
    } else {
      console.warn(`[getTranslate] No translation found for this path - ${path}`)

      return path
    }
  }
}

export default { getTranslate }
