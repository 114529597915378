import Vue from 'vue'

import buttonFavorite from '@/components/button/favorite'

import { serviceName } from '@/components/services/tariffs/utils'

function renderFavoriteButton(h) {
  return h(
    buttonFavorite,
    {
      class: 'ma-0',
      props: {
        id: this.value.id,
        serviceName
      }
    }
  )
}

function renderEditButton(h) {
  return h(
    'g-button',
    {
      class: 'ma-0',
      props: {
        flat: true,
        icon: 'edit'
      },
      on: {
        click: () => {
          Vue.router.push({
            name: `${serviceName}.single`,
            params: { id: this.value.id }
          })
        }
      }
    }
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: {
        'faic gird grid-gap--8': true,
        'hover-child': this.viewport.breakpoint.mdUp
      },
      style: { 'grid-template-columns': 'repeat(2, 36px)' }
    },
    [
      renderFavoriteButton.call(this, h),
      renderEditButton.call(this, h)
    ]
  )
}
