import Vue from 'vue'

import { unVue } from '@sigma-legacy-libs/unvue'
import { mapGetters } from 'vuex'

import SearchGenerator from '@/components/generator/search'

import { CONTACTS_FILTER, generateServices, globalErrorHandler, globalErrorProcessor, tableHeaderParser } from '@/utils'

import { serviceName } from '@/components/services/contacts/utils'

import render from './render'

export default {
  name: 'Template',

  mixins: [
    generateServices([
      {
        name: serviceName,

        outputFilter(result) {
          Object.keys(result).forEach(key => {
            if (result[key] === undefined) {
              result[key] = null
            }
          })

          return result
        },

        find: {
          defaultFilter: Object.assign({}, unVue(CONTACTS_FILTER), {
            $scope: [ 'Owner' ],
            t: undefined
          }),
          alwaysCreateFromWebSocket: true,
          alwaysUpdateFromWebSocket: true,
          alwaysRemoveFromWebSocket: true,

          useCache: false
        },
        get: { useCache: false }
      },
      {
        name: 'contactLists',

        find: { useCache: false },
        get: { useCache: false },

        remove: {
          redirect: serviceName
        }
      }
    ])
  ],

  components: {
    'search-generator': SearchGenerator({
      name: 'contactLists',

      find: {
        defaultFilter: {
          isActive: true,
          type: undefined,
          $scope: [ 'Owner' ]
        },
        alwaysCreateFromWebSocket: true,
        alwaysUpdateFromWebSocket: true,
        alwaysRemoveFromWebSocket: true,

        useCache: false
      },

      serviceForCheckPermissions: serviceName,
      filterByIsActive: false,
      avoid304: true
    })
  },

  data() {
    return {
      showDialog: false,
      showFilter: false,
      showFileManager: false,

      exportList: undefined,
      exportType: 'new',

      importContactsResult: {
        state: undefined,
        total: 0
      },

      contactId: undefined,
      contactListId: undefined,
      contactListItem: {},
      contactItem: undefined,
      proxyContactList: undefined,
      canUpdate: false,

      dialogType: undefined,

      show: {
        create: {
          contactLists: false,
          contacts: false
        },
        update: {
          contactLists: false,
          contacts: false
        },
        remove: {
          contactLists: false,
          contacts: false
        }
      }
    }
  },

  computed: {
    ...mapGetters({ uploadedFile: 'file/uploadedFile' }),

    headers() {
      return tableHeaderParser.call(this, [
        {
          title: `${serviceName}.headers.phone`,
          value: 'phone',
          align: 'left',
          class: 'pl-2 pr-2'
        },
        {
          title: `${serviceName}.headers.lastName`,
          value: 'lastName',
          align: 'left',
          class: 'pl-2 pr-2'
        },
        {
          title: `${serviceName}.headers.firstName`,
          value: 'firstName',
          align: 'left',
          class: 'pl-2 pr-2'
        },
        {
          title: `${serviceName}.headers.middleName`,
          value: 'middleName',
          align: 'left',
          class: 'pl-2 pr-2'
        },
        {
          title: `${serviceName}.headers.email`,
          value: 'email',
          align: 'left',
          class: 'pl-2 pr-2'
        },
        {
          title: `${serviceName}.headers.date`,
          value: 'date',
          align: 'left',
          class: 'pl-2 pr-2'
        },
        {
          title: `${serviceName}.headers.gender`,
          value: 'gender',
          align: 'left',
          class: 'pl-2 pr-2'
        },
        {
          title: `${serviceName}.labels.custom01`,
          value: 'custom01',
          align: 'left',
          class: 'pl-2 pr-2'
        },
        {
          title: `${serviceName}.labels.custom02`,
          value: 'custom02',
          align: 'left',
          class: 'pl-2 pr-2'
        },
        {
          title: `${serviceName}.labels.custom03`,
          value: 'custom03',
          align: 'left',
          class: 'pl-2 pr-2'
        },
        {
          title: '',
          value: 'actions',
          class: 'pl-0 pr-0'
        }
      ])
    }
  },

  watch: {
    '$route.params.id'() {
      this.restData[serviceName].find.data = undefined
      this.restData[serviceName].find.pagination.total = 0
      this.restData[serviceName].find.state = 'empty'

      this.getCurrentContactList()
    },

    uploadedFile: {
      handler({ data }) {
        this.showImportContacts = true
        this.showFileManager = false
        this.importContacts(data.key)
      },
      deep: true
    }
  },

  mounted() {
    this.getCurrentContactList()
  },

  beforeDestroy() {
    this.restData[serviceName].find.filter = unVue(CONTACTS_FILTER)
  },

  methods: {
    async getCurrentContactList() {
      const id = this.$route.params.id
      if (id) {
        this.proxyContactList = unVue(await this.rest.contactLists.get(id))
        this.restData[serviceName].find.filter.ListId = id
        this.restData[serviceName].find.filter.t = Date.now()
      }
    },

    async importContacts(key) {
      this.importContactsResult.state = 'loading'
      try {
        const result = await Vue.$GRequest._request({
          method: 'PUT',
          url: '/contacts',
          headers: { Authorization: window.localStorage.getItem('token') },
          data: {
            ListId: this.$route.params.id,
            from: { key }
          }
        })
        if (result) {
          this.rest[serviceName].find()
          const total = result.data.total
          if (total) {
            this.importContactsResult.state = 'success'
            this.importContactsResult.total = result.data.total
          } else {
            this.importContactsResult.state = 'error'
          }
        }
      } catch (error) {
        this.importContactsResult.state = 'error'

        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      }
    },

    async exportContacts(id) {
      try {
        await Vue.$GRequest._request({
          method: 'PUT',
          url: '/contacts',
          headers: { Authorization: window.localStorage.getItem('token') },
          data: {
            ListId: id,
            from: { ListId: this.$route.params.id }
          }
        })
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      } finally {
        this.showExportContacts = false
      }
    },

    async tryUpdating() {
      if (this.restData[serviceName].update.isValid) {
        try {
          await this.rest.contacts.update(this.contactItem.id, this.contactItem)
          this.contactItem = undefined
          this.show.update.contacts = false
        } catch (error) {
          globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
        }
      }
    },

    async changeData(service, method, data) {
      const valid =
        method === 'remove'
          ? this.checkPermissions('advanced.contacts.remove', [ 'me', 'reseller', true ])
          : this.restData[service][method].isValid

      if (method && service && data) {
        if (this.restData[service][method].state !== 'loading' && valid) {
          try {
            const result = await this.rest[service][method](data)
            if (result) {
              this.show[method][service] = false
            }

            return result
          } catch (error) {
            globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
          }
        }
      }
    }
  },

  render
}
