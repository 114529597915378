import { serviceTemplate } from '@/utils'

import { serviceName } from '@/components/services/apisender/utils'

export default {
  mixins: [
    serviceTemplate({
      serviceName,

      find: {
        defaultFilter: {
          OwnerId: undefined
        }
      },
      create: { redirect: true },

      titlePath: 'instance.idInstance',
      formPaddles: true,
      width: 420
    })
  ]
}
