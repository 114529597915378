import VirtualList from 'vue-virtual-scroll-list'

import row from '@/components/misc/virtualScroll/row'

import { serviceName } from '@/components/services/keywords/utils'

import { STATES } from '@/utils'

function renderSearch(h) {
  return h(
    'div',
    {
      class: 'grid',
      style: { 'grid-template-columns': '24px 1fr' }
    },
    [
      h(
        'g-icon',
        {
          props: {
            value: 'search',
            color: 'grey'
          }
        }
      ),

      h(
        'g-text-field',
        {
          props: {
            value: this.restData[serviceName].find.filter.$search,
            label: this.getTranslate('commons.labels.search'),
            loading: this.restData[serviceName].find.state === STATES.loading,
            mode: 'outline',
            details: false,
            dense: true,
            rounded: true,
            clearable: true
          },
          on: {
            input: event => {
              this.restData[serviceName].find.filter.$search = event
            }
          }
        }
      )
    ]
  )
}

function renderCreateButton(h) {
  return h(
    'g-button',
    {
      class: 'ma-0',
      props: {
        flat: true,
        icon: 'add',
        color: 'secondary'
      },
      on: {
        click: () => {
          this.keywordPayload.ListId = this.id
          this.showDialog = true
          this.dialogType = 'create'
        }
      }
    }
  )
}

function renderHeader(h) {
  return h(
    'div',
    {
      class: 'grid',
      style: { 'grid-template-columns': '1fr 36px' }
    },
    [
      renderSearch.call(this, h),
      renderCreateButton.call(this, h)
    ]
  )
}

function renderDialogContent(h) {
  switch (this.dialogType) {
    case 'update':
    case 'create': {
      return h(
        'g-text-field',
        {
          props: {
            value: this.keywordPayload.regexp,
            label: this.getTranslate(`${serviceName}.labels.value`),
            mode: 'outline',
            details: false,
            dense: true,
            rounded: true,
            autofocus: true,
            clearable: true
          },
          on: {
            input: event => {
              this.keywordPayload.regexp = event
            }
          }
        }
      )
    }
    case 'remove': {
      return this.getTranslate('commons.contents.confirm.remove')
    }
  }
}

function renderDialog(h) {
  return h(
    'g-dialog',
    {
      props: {
        value: this.showDialog,
        rounded: true,
        maxWidth: 300
      },
      on: {
        input: event => {
          this.showDialog = event
        }
      }
    },
    [
      h(
        'div',
        {
          class: 'pa-3'
        },
        [ renderDialogContent.call(this, h) ]
      ),

      h(
        'g-card-actions',
        {
          class: 'fjcfe'
        },
        [
          h(
            'g-button',
            {
              props: {
                flat: true,
                rounded: true,
                disabled: this.loading,
                label: this.getTranslate('misc.buttons.cancel')
              },
              on: {
                click: () => {
                  this.showDialog = false
                }
              }
            }
          ),

          h(
            'g-button',
            {
              props: {
                flat: true,
                rounded: true,
                color: this.dialogType === 'remove' ? 'error' : 'primary',
                loading: this.loading,
                disabled: this.loading,
                label: this.getTranslate(`misc.buttons.${this.dialogType}`)
              },
              on: {
                click: () => {
                  switch (this.dialogType) {
                    case 'update':
                    case 'create': {
                      this.rest[serviceName][this.dialogType](this.keywordPayload)
                      break
                    }
                    case 'remove': {
                      this.rest[serviceName][this.dialogType](this.keywordPayload.id)
                      break
                    }
                  }

                  this.clearPayload()
                  this.showDialog = false
                }
              }
            }
          )
        ]
      )
    ]
  )
}

function renderItem(h, item) {
  return h(
    'div',
    {
      class: 'keywords-list__item hover-parent hover-parent--opacity'
    },
    [
      h(
        'div',
        {
          class: 'keywords-list__item-title',
          on: {
            click: () => {
              this.keywordPayload = item
              this.dialogType = 'update'
              this.showDialog = true
            }
          }
        },
        item.regexp
      ),

      h('div', { class: 'ff' }),

      h(
        'div',
        {
          class: 'keywords-list__item-remove hover-child',
          on: {
            click: () => {
              this.keywordPayload = item
              this.dialogType = 'remove'
              this.showDialog = true
            }
          }
        },
        this.getTranslate('misc.buttons.remove')
      )
    ]
  )
}

function renderItems(h) {
  return h(
    VirtualList,
    {
      class: 'keywords-list',
      props: {
        dataKey: 'id',
        dataSources: this.restData[serviceName].find.data,
        dataComponent: row,
        itemScopedSlots: {
          item: source => renderItem.call(this, h, source)
        }
      },
      on: {
        tobottom: () => {
          const loadMore = this.restData[serviceName].find.pagination.limit + this.restData[serviceName].find.pagination.offset < this.restData[serviceName].find.pagination.total
          if (this.restData[serviceName].find.state === STATES.ready && loadMore) {
            this.restData[serviceName].find.pagination.offset += this.restData[serviceName].find.pagination.limit
          }
        }
      }
    }
  )
}

function renderContent(h) {
  if (this.restData[serviceName].find.pagination.total) {
    return renderItems.call(this, h)
  } else {
    return h('g-empty')
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: 'grid gird-gap--8'
    },
    [
      renderHeader.call(this, h),
      renderContent.call(this, h),
      renderDialog.call(this, h)
    ]
  )
}
