import { unVue } from '@sigma-legacy-libs/unvue'
import { PUBLIC_FIELDS_USER } from '@sigma-legacy-libs/essentials/lib/constants'

import { serviceName } from '@/components/services/templates/utils'

import { TEMPLATES_FILTER, fillDependence, generateServices, sendingsOutputFilter } from '@/utils'

import render from './render'

export default {
  name: 'TemplatesTemplate',

  mixins: [
    generateServices({
      name: serviceName,

      async inputFilter(data) {
        if (!data.Owner) {
          await fillDependence.call(this, data, {
            service: 'users',
            permissions: [ 'reseller', true ],
            pathToId: 'OwnerId',
            pathToObject: 'Owner',
            outputPath: 'Owner',
            picks: PUBLIC_FIELDS_USER
          })
        }

        data.OwnerId = data.Owner && data.Owner.id || data.OwnerId

        return data
      },

      outputFilter: sendingsOutputFilter,

      find: {
        defaultFilter: unVue(TEMPLATES_FILTER),
        defaultPagination: { limit: 10 }
      },
      get: false,
      update: false,
      create: false,
      remove: false
    })
  ],

  data() {
    return {
      filter: unVue(TEMPLATES_FILTER),

      currentChosenTemplate: ''
    }
  },

  watch: {
    filter: {
      handler() {
        this.restData[serviceName].find.filter = unVue(this.filter)
      },
      deep: true
    }
  },

  mounted() {
    this.rest[serviceName].find()
  },

  render
}
