import { createClicker } from '@/utils'

import render from './render'

export default {
  name: 'RefreshButton',

  props: {
    state: [ String, Boolean ],
    label: String,
    tooltip: String,

    shortClickMethod: {
      type: Function,
      default: () => {}
    },

    longClickMethod: {
      type: Function,
      default: () => {}
    },

    disabled: Boolean
  },

  methods: {
    clickHandler: createClicker({
      shortClickHandler(event) {
        if (event.shiftKey) {
          this.longClickMethod()
        } else {
          this.shortClickMethod()
        }
      },
      longClickHandler() {
        this.longClickMethod()
      },
      longClickPreFire: true
    })
  },

  render
}
