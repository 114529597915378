import proxy from '@sigma-legacy-libs/g-proxy'

import { prefix } from '@/utils'

import render from './render'

export default {
  name: 'SizeChanger',

  mixins: [ proxy() ],

  props: {
    serviceName: {
      type: String,
      required: true
    }
  },

  mounted() {
    this.set(window.localStorage.getItem(`${prefix}:size:${this.serviceName}`))
  },

  methods: {
    set(size) {
      if (size) {
        this.proxy = size
        window.localStorage.setItem(`${prefix}:size:${this.serviceName}`, size)
      }
    }
  },

  render
}
