export const REQUISITE_OPF = [
  'Индивидуальный предприниматель',
  'Общество с ограниченной ответственностью',
  'Акционерное общество',
  'Открытое акционерное общество',
  'Закрытое акционерное общество',
  'Публичное акционерное общество',
  'Унитарное предприятие',
  'Государственное бюджетное учреждение',
  'Частное учреждение',
  'Национальный потребительский кооператив',
  'Профсоюзная организация',
  'Автономная некоммерческая организация высшего образования',
  'Благотворительный фонд'
]

const opfShort = {}
for (const opf of REQUISITE_OPF) {
  const split = opf.split(' ')
  opfShort[opf] = split.reduce((result, word) => {
    if (word.length > 1) {
      result += word.substring(0, 1).toUpperCase()
    }

    return result
  }, '')
}
export const REQUISITE_OPF_SHORT = opfShort

export default {
  REQUISITE_OPF,
  REQUISITE_OPF_SHORT
}
