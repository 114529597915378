import { serviceName } from '@/components/services/requisites/utils'

const _ = { get: require('lodash/get') }

export default function(h) {
  const vat = _.get(this.value, 'data.tax.vat', false)

  return h(
    'div',
    {
      class: 'text-overflow'
    },
    [ this.getTranslate(`${serviceName}.vat.${vat}`) ]
  )
}
