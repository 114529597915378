import Vue from 'vue'

import { mapGetters } from 'vuex'

import { fillDependence, generateServices, globalErrorHandler, globalErrorProcessor } from '@/utils'

import { serviceName } from '@/components/services/documentTemplates/utils'

import render from './render'

export default {
  name: 'Selector',

  mixins: [
    generateServices({
      name: serviceName,

      async inputFilter(result) {
        if (!result.File) {
          await fillDependence.call(this, result, {
            service: 'storage',
            permissions: [ 'me', 'reseller', true ],
            pathToId: 'FileId',
            pathToObject: 'File',
            outputPath: 'File',
            requestParams: {
              query: {
                return: 'meta'
              }
            }
          })
        }

        return result
      },

      find: {
        defaultFilter: {
          CompanyId: undefined
        },
        alwaysCreateFromWebSocket: true,
        alwaysUpdateFromWebSocket: true,
        alwaysRemoveFromWebSocket: true
      },
      update: false
    })
  ],

  props: {
    CompanyId: String,

    type: {
      type: String,
      default: 'contract'
    }
  },

  data() {
    return {
      showFileManager: false,
      showEditFileDialog: false,

      editFileProgress: false,
      uploadFileProgress: false,
      createDocumentProgress: false,

      document: undefined,

      googleFileURL: undefined,

      currentType: undefined
    }
  },

  computed: {
    ...mapGetters({ uploadedFile: 'file/uploadedFile' })
  },

  watch: {
    uploadedFile: {
      async handler({ data }) {
        if (this.currentType === this.type && !this.createDocumentProgress) {
          this.showFileManager = false
          await this.createDocumentTemplate(data.id)
        }
      },
      deep: true
    },

    CompanyId() {
      this.setDocument()
    }
  },

  mounted() {
    this.setDocument()
  },

  methods: {
    async setDocument() {
      const data = await this.rest[serviceName].find({ query: { CompanyId: this.CompanyId } })
      if (Array.isArray(data) && data.length) {
        this.document = data.find(({ type }) => type === this.type)
      }
    },

    async createDocumentTemplate(id) {
      if (id && !this.document) {
        try {
          this.createDocumentProgress = true
          await this.rest[serviceName].create({
            FileId: id,
            CompanyId: this.CompanyId,
            type: this.type
          })
        } catch (error) {
          globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
        } finally {
          this.createDocumentProgress = false
        }
      }
    },

    async editFile() {
      try {
        this.editFileProgress = true
        const query = {}
        const { File = {} } = this.document || {}
        if (this.checkPermissions('advanced.storage.update')) {
          query.OwnerId = File.Owner && File.Owner.id || File.OwnerId
        }
        const { data } = await Vue.$GRequest.get('/storage/edit', File.id, { query })
        if (data) {
          this.googleFileURL = data.result
          this.showEditFileDialog = true
        }
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      } finally {
        this.editFileProgress = false
      }
    },

    async saveFile() {
      try {
        this.uploadFileProgress = true
        const { data } = await Vue.$GRequest.remove('/storage/edit/', this.document.File.id)
        if (data.result) {
          this.googleFileURL = undefined
          this.showEditFileDialog = false
        }
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      } finally {
        this.uploadFileProgress = false
      }
    }
  },

  render
}
