const baseFields = {
  requisite: 'Requisite',
  title: 'Title',
  type: 'Type',
  sum: 'Sum',
  contact: {
    firstName: 'First name',
    middleName: 'Middle name',
    lastName: 'Last name',
    email: 'E-mail',
    phone: 'Phone',
    matches: 'Matches the data specified in the profile of your personal account'
  },
  signer: {
    position: {
      nominative: 'Position',
      genitive: 'Position'
    },
    basis: {
      nominative: 'Acts on basis of',
      genitive: 'Acts on basis of'
    },
    fullName: {
      nominative: 'Full name',
      genitive: 'Full name'
    }
  },
  data: {
    legal: {
      businessType: 'Company type',
      name: 'Name of company',
      opf: 'OPF',
      ogrn: 'OGRN',
      inn: 'INN',
      kpp: 'KPP',
      okpo: 'OKPO'
    },
    bank: {
      name: 'Name of bank',
      rs: 'Checking account',
      ks: 'Corr. account',
      bik: 'BIK'
    },
    tax: {
      system: 'Tax system',
      vat: 'With/without VAT'
    },
    legalAddress: {
      title: 'Legal address',
      country: 'Country',
      city: 'City',
      index: 'Index',
      address: 'Address',
      addressMatches: 'Matches with legal address',
      phone: 'Phone',
      phoneAdd: 'Extension number'
    },
    postalAddress: {
      title: 'Postal address',
      country: 'Country',
      city: 'City',
      index: 'Index',
      address: 'Address',
      addressMatches: 'Matches with legal address',
      phone: 'Phone',
      phoneAdd: 'Extension number'
    },
    personal: {
      firstName: 'First name',
      middleName: 'Middle name',
      lastName: 'Last name',
      document: {
        series: 'Document series',
        number: 'Document number',
        issuer: 'Issuer',
        issuerCode: 'Issuer code',
        issueDate: 'Issue date',
        registrationAddress: 'Registration address',
        registrationDate: 'Registration date',
        citizenship: 'Citizenship'
      }
    }
  },
  moderation: 'Moderation',
  documents: {
    templates: {
      shared: 'Shared templates',
      assigned: 'Assigned templates',
      purpose: 'Purpose',
      purposes: {
        bill: 'Bill',
        contract: 'Contract',
        contractAnnex1: 'Contract annex #1',
        addAgreementCreditPayment: 'Agreement credit payment',
        invoice: 'Invoice',
        UTD: 'UTD',
        UTDplus: 'UTD plus',
        Letter1: 'Letter 1',
        Letter2: 'Letter 2',
        Letter3: 'Letter 3'
      }
    }
  },
  country: 'Country',
  city: 'City',
  index: 'Index',
  address: 'Address',
  addressMatches: 'Matches with legal address',
  phone: 'Phone',
  phoneAdd: 'Extension number'
}

export default {
  title: 'Requisites',

  titles: {
    create: 'Create new requisites',
    document: {
      templates: 'Templates'
    },
    empty: {
      title: 'No requisites created',
      sub: 'Create a new requisite using the button below'
    },
    generate: {
      document: 'Download document'
    },
    requisites: 'Requisites',
    notRequired: 'Optional part'
  },

  dialogs: {
    titles: {
      create: 'Create requisites',
      update: 'Update requisites',
      remove: 'Remove requisites'
    }
  },

  headers: {
    ...baseFields,

    businessInfo: 'Business info',
    contactInfo: 'Contact info'
  },

  labels: {
    ...baseFields
  },

  subheaders: {
    title: 'Title',
    contact: 'Responsible person for signing the contract',
    signer: 'Signer',
    general: 'General',
    legal: 'Legal',
    bank: 'Bank',
    tax: 'Tax',
    personal: 'Personal',
    document: 'Document',
    legalAddress: 'Legal address',
    postalAddress: 'Postal address',
    documents: {
      templates: {
        shared: 'Shared templates',
        assigned: 'Assigned templates'
      }
    }
  },

  contents: {
    documents: {
      templates: {
        shared: 'Document templates that reseller could assign to his users',
        assigned: 'Document templates that will be used to generate current user\'s documents'
      }
    },
    confirm: {
      remove: 'Confirm remove this requisite.'
    }
  },

  types: {
    business: 'Business',
    personal: 'Personal'
  },

  errors: {
    ogrn: 'Must be from 13 to 15 digits',
    inn: 'Must be from 10 to 12 digits',
    kpp: 'Must be 9 digits',
    okpo: 'Must be 8 digits',
    rs: 'Must be 20 digits',
    ks: 'Must be 20 digits',
    bik: 'Must be 9 digits',
    index: 'Must be 6 digits',
    document: {
      number: 'Must be 6 digits'
    },
    documentsNoRequisitesFound: 'No requisites found',
    documentsNoDocumentTemplateFound: 'No document template found'
  },

  hints: {
    title: 'Title of requisite for quick search on the platform when invoicing.',
    required: 'Fields marked with * are required',
    data: {
      legal: {
        name: 'Без кавычек и правовой формы, например ваша компания ООО "Ландыши", впишите только Ландыши',
        opf: 'Организационно-правовая форма (ИП, ООО...)',
        ogrn: 'Основной государственный регистрационный номер',
        inn: 'Идентификационный номер налогоплательщика',
        kpp: 'Код причины постановки на учет',
        okpo: 'Общероссийский классификатор предприятий и организаций'
      }
    },
    nominative: 'Nominative',
    genitive: 'Genitive',
    sum: 'Minimal sum 100 rubles',
    address: 'Street, house, building, office / apartment',
    validation: {
      state: {
        true: 'Filled',
        false: 'Errors',
        undefined: 'Not filled'
      }
    }
  },

  vat: {
    true: 'With VAT',
    false: 'Without VAT'
  },

  buttons: {
    add: 'Add requisite',
    edit: 'Edit requisite',
    document: {
      templates: 'Users documents templates'
    }
  },

  snackbars: {
    created: 'Requisite successfully created.',
    updated: 'Requisite successfully updated.',
    removed: 'Requisite successfully removed.'
  },

  tooltips: {
    isActive: 'Active or inactive requisite. If the requisite is inactive, you will not be able to use it for invoicing.',
    favorites: 'Make the requisite a favorite',
    filter: {
      isActive: 'Show deactivated requisites.'
    }
  }
}
