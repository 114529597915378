import { STATES, getRUB, isStringNotEmpty, permissionPresets } from '@/utils'

import BillingsForm from '@/components/services/billings/form'
import HeadTitle from '@/components/misc/HeadTitle'
import BalanceOverdraft from '@/components/misc/BalanceOverdraft'
import DocumentTemplatesGenerateButton from '@/components/services/documentTemplates/generate/button'
import OneRowString from '@/components/misc/OneRowString'
import ReportButton from '@/components/button/report'
import EntityInfo from '@/components/misc/EntityInfo'
import copyIcon from '@/components/copy/icon'
import Copy from '@/components/misc/Copy'

const _ = {
  get: require('lodash/get'),
  set: require('lodash/set')
}

function renderLoginAsButton(h) {
  return h(
    'g-button',
    {
      class: 'ma-0 clipboard-button',
      props: {
        icon: 'exit_to_app',
        flat: true,
        color: 'grey',
        loading: this.statusLoginAs === STATES.loading,
        disabled: this.statusLoginAs === STATES.loading || !this.checkPermissions('secret.loginAs', permissionPresets.manager)
      },
      on: {
        mousedown: this.clickHandler,
        mouseup: this.clickHandler,
        touchstart: this.clickHandler,
        touchend: this.clickHandler
      },
      scopedSlots: {
        dialog: () => renderLoginAsDialog.call(this, h)
      }
    }
  )
}
function renderLoginAsDialog(h) {
  if (this.checkPermissions('secret.loginAs', permissionPresets.manager)) {
    return h(
      'g-dialog',
      {
        props: {
          rounded: true,
          value: this.showTokenDialog,
          maxWidth: 500
        },
        on: {
          input: event => {
            this.showTokenDialog = event
          }
        }
      },
      [
        h(
          'div',
          {
            class: 'faic pa-3',
            slot: 'header'
          },
          [
            h('div', { class: 'headline' }, this.getTranslate('misc.key')),
            h('div', { class: 'ff' }),
            h(
              copyIcon,
              {
                props: {
                  value: this.token,
                  size: 24
                }
              }
            )
          ]
        ),

        h(
          'div',
          {
            class: 'pa-3'
          },
          [
            h(
              'g-textarea',
              {
                props: {
                  value: this.token,
                  hint: isStringNotEmpty(this.token) ? `${this.token.length}` : '0',
                  mode: 'outline',
                  rounded: true,
                  readonly: true,
                  grow: true,
                  autofocus: true
                },
                ref: 'token'
              }
            )
          ]
        ),

        h(
          'g-card-actions',
          {
            class: 'fjcfe facfe',
            slot: 'footer'
          },
          [
            h(
              'g-button',
              {
                props: {
                  label: this.getTranslate('misc.buttons.close'),
                  flat: true,
                  rounded: true
                },
                on: {
                  click: () => {
                    this.showTokenDialog = false
                    this.token = undefined
                  }
                }
              }
            )
          ]
        )
      ]
    )
  }
}

function renderAddFundsButton(h, { id }) {
  return h(
    'g-button',
    {
      class: 'ma-0',
      props: {
        icon: 'add',
        flat: true,
        disabled: !this.checkPermissions('advanced.billings.generate', permissionPresets.manager)
      },
      directives: [
        {
          name: 'g-tooltip',
          options: {
            value: this.getTranslate('users.tooltips.add.funds'),
            placement: 'top'
          }
        }
      ],
      on: {
        click: () => {
          this.restData.billings.create.data.OwnerId = id
          this.showAddFunds = true
        }
      },
      scopedSlots: {
        dialog: () => renderAddFundsDialog.call(this, h, { id })
      }
    }
  )
}
function renderAddFundsDialog(h, { id }) {
  if (this.checkPermissions('advanced.billings.generate', permissionPresets.manager)) {
    return h(
      'g-dialog',
      {
        props: {
          rounded: true,
          value: this.showAddFunds,
          maxWidth: 500
        },
        on: {
          input: event => {
            this.showAddFunds = event
          }
        }
      },
      [
        h(
          HeadTitle,
          {
            props: { value: this.getTranslate('users.titles.add.funds') },
            slot: 'header'
          }
        ),

        h(
          BillingsForm,
          {
            props: {
              value: this.restData.billings.create.data,
              errors: this.restData.billings.create.errors,
              owner: id
            },
            on: {
              input: event => {
                this.restData.billings.create.data = event
              },
              validation: event => {
                this.restData.billings.create.isValid = event
              }
            }
          }
        ),

        h(
          'g-card-actions',
          {
            class: 'fjcfe facfe',
            slot: 'footer'
          },
          [
            h(
              'g-button',
              {
                props: {
                  label: this.getTranslate('misc.buttons.cancel'),
                  flat: true,
                  rounded: true
                },
                on: {
                  click: () => {
                    this.showAddFunds = false
                  }
                }
              }
            ),
            h(
              'g-button',
              {
                props: {
                  label: this.getTranslate('misc.buttons.create'),
                  rounded: true,
                  depressed: true,
                  loading: this.restData.billings.create.state === 'loading',
                  disabled: !this.createDataIsValid,
                  color: 'primary'
                },
                on: {
                  click: async () => {
                    await this.createBilling()
                  }
                }
              }
            )
          ]
        )
      ]
    )
  }
}

function renderReseller(h, item) {
  const reseller = _.get(item, 'Reseller.username')
  if (reseller) {
    return h('div', { class: 'caption text--grey' }, reseller)
  }
}
function renderManager(h, item) {
  const manager = _.get(item, 'data.partnership.Manager.username')
  if (manager) {
    return h('div', { class: 'caption text--grey' }, manager)
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: {
        faifs: this.viewport.breakpoint.mdUp,
        'fc ff': this.viewport.breakpoint.smDown
      }
    },
    [
      h(
        'search-generator',
        {
          scopedSlots: {
            default: ({ item }) => {
              return h(
                'g-list-item',
                {
                  props: {
                    active: item.id === this.$route.params.id,
                    to: {
                      name: 'users.single',
                      params: { id: item.id }
                    }
                  }
                },
                [
                  h(
                    'div',
                    {
                      class: 'fc ff'
                    },
                    [
                      h(
                        'div',
                        {
                          class: 'fjcsb faic'
                        },
                        [
                          h(
                            OneRowString,
                            {
                              props: {
                                value: item.username,
                                direction: 'right',
                                length: 20
                              }
                            }
                          ),

                          h('div', { class: 'text--grey caption' }, getRUB(item.balance))
                        ]
                      ),

                      h(
                        'div',
                        {
                          class: 'faic caption'
                        },
                        [
                          renderReseller.call(this, h, item),

                          h('div', { class: 'ff' }),

                          renderManager.call(this, h, item)
                        ]
                      )
                    ]
                  )
                ]
              )
            },
            report: () => {
              if (this.checkPermissions('advanced.reports.presetUsersBalance', permissionPresets.manager)) {
                return h(
                  ReportButton,
                  {
                    props: {
                      preset: 'users_balance',
                      icon: true
                    }
                  }
                )
              }
            },
            titleCreate: () => {
              return h(HeadTitle, { props: { value: this.getTranslate('users.titles.create') } })
            }
          }
        }
      ),

      h(
        'get-create-template',
        {
          scopedSlots: {
            title: ({ data }) => {
              return h(
                'div',
                {
                  class: 'grid grid-gap--8 faic',
                  style: {
                    'grid-template-columns': '36px 1fr'
                  }
                },
                [
                  renderLoginAsButton.call(this, h),

                  h(
                    Copy,
                    {
                      class: 'users-title users-title--username subtitle-1',
                      props: { value: data.username }
                    }
                  )
                ]
              )
            },

            header: ({ data }) => {
              return h(
                'div',
                {
                  class: 'grid grid-gap--8 fjcfe',
                  style: {
                    'grid-template-columns': 'auto 36px 36px'
                  }
                },
                [
                  h(
                    BalanceOverdraft,
                    {
                      class: 'ma-0',
                      props: { UserId: data.id }
                    }
                  ),

                  renderAddFundsButton.call(this, h, data),

                  h(
                    DocumentTemplatesGenerateButton,
                    {
                      class: 'ma-0',
                      props: {
                        icon: true,
                        OwnerId: data.id,
                        ResellerId: data.ResellerId || data.Reseller && data.Reseller.id
                      }
                    }
                  )
                ]
              )
            },

            'after-form': ({ data }) => {
              return h(EntityInfo, { props: { value: data } })
            }
          }
        }
      )
    ]
  )
}
