import { BILLINGS_SOURCES } from '@/utils'

import generatorRow from '@/components/generator/row'

export default function(h, { path, serviceName }) {
  return h(
    generatorRow,
    {
      props: {
        value: this.proxy,
        defaultValue: {
          source: 'accrual',
          percent: 0,
          limitMin: 0,
          limitMax: 0
        },
        addDisabled: this.proxy.map(item => item.source).length >= BILLINGS_SOURCES.length
      },
      scopedSlots: {
        row: ({ item }) => {
          return h(
            'div',
            {
              class: {
                grid: true,
                'grid-cols--2': this.viewport.breakpoint.mdUp,
                'grid-cols--1': this.viewport.breakpoint.smDown
              }
            },
            [
              h(
                'g-select',
                {
                  props: {
                    value: item.source,
                    label: this.getTranslate(`${serviceName}.labels.${path}.source`),
                    items: BILLINGS_SOURCES.map(source => {
                      return {
                        title: this.getTranslate(`billings.sources.${source}`),
                        value: source
                      }
                    }),
                    itemsDisabled: this.proxy.map(item => item.source),
                    mode: 'outline-label',
                    rounded: true,
                    details: false
                  },
                  on: {
                    input: event => {
                      item.source = event
                    }
                  }
                }
              ),

              h(
                'g-text-field',
                {
                  props: {
                    value: item.percent,
                    label: this.getTranslate(`${serviceName}.labels.${path}.percent`),
                    suffix: '%',
                    type: 'number',
                    min: 0,
                    step: 'any',
                    mode: 'outline-label',
                    rounded: true,
                    details: false
                  },
                  on: {
                    input: event => {
                      item.percent = event
                    }
                  }
                }
              ),

              h(
                'g-text-field',
                {
                  props: {
                    value: item.limitMin,
                    label: this.getTranslate(`${serviceName}.labels.${path}.limitMin`),
                    suffix: '&#8381;',
                    type: 'number',
                    min: 0,
                    step: 'any',
                    mode: 'outline-label',
                    rounded: true,
                    details: false
                  },
                  on: {
                    input: event => {
                      item.limitMin = event
                    }
                  }
                }
              ),
              h(
                'g-text-field',
                {
                  props: {
                    value: item.limitMax,
                    label: this.getTranslate(`${serviceName}.labels.${path}.limitMax`),
                    suffix: '&#8381;',
                    type: 'number',
                    min: 0,
                    step: 'any',
                    mode: 'outline-label',
                    rounded: true,
                    details: false
                  },
                  on: {
                    input: event => {
                      item.limitMax = event
                    }
                  }
                }
              )
            ]
          )
        }
      },
      on: {
        input: event => {
          this.proxy = event
        }
      }
    }
  )
}
