import { SUBSCRIPTION_COUNTER_TYPES, SUBSCRIPTION_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import { serviceName } from '@/components/services/subscriptions/utils'

import OwnerSelect from '@/components/misc/OwnerSelect'
import operators from '@/components/services/subscriptions/form/operators'
import messageTypeIcon from '@/components/message/type/icon'

function renderFieldType(h) {
  if (Array.isArray(this.types) && this.types.length === 1) {
    return h(
      'div',
      {
        class: 'grid grid-gap--8 faic caption text--grey border border--dashed border--rounded pa-2',
        style: { gridTemplateColumns: '24px 1fr' }
      },
      [
        h(
          messageTypeIcon,
          {
            props: {
              value: this.type,
              size: 24
            }
          }
        ),

        h(
          'div',
          {},
          this.types[0].title
        )
      ]
    )
  } else {
    return h(
      'g-select',
      {
        props: {
          value: this.proxy.type,
          items: this.types,
          label: this.getTranslate(`${serviceName}.labels.type`),
          mode: 'outline',
          dense: true,
          rounded: true,
          required: true,
          details: false
        },
        on: {
          input: event => {
            this.proxy.type = event
          }
        }
      }
    )
  }
}

function renderFieldExpireAt(h) {
  return h(
    'g-menu',
    {
      props: {
        closeOnContentClick: false,
        rounded: true,
        placement: 'bottom-end',
        offsetDistance: 8
      }
    },
    [
      h(
        'div',
        {
          class: {
            'hint hint-border link border border--rounded': true,
            'border--dashed': !this.proxy.expireAt
          },
          slot: 'activator'
        },
        [
          h(
            'g-icon',
            {
              props: {
                value: 'date_range',
                color: 'grey'
              }
            }
          ),

          this.getTranslate(`${serviceName}.labels.expireAt`) + ' ' + this.expireAt.toLowerCase()
        ]
      ),

      h(
        'div',
        {
          class: 'grid grid-gap--8'
        },
        [
          h(
            'g-date-picker',
            {
              props: {
                value: this.proxy.expireAt,
                localeTag: this.locale,
                min: new Date(),
                range: false
              },
              on: {
                input: async event => {
                  this.proxy.expireAt = event
                }
              }
            }
          ),

          h(
            'div',
            {
              class: 'fjcfe pa-2'
            },
            [
              h(
                'g-button',
                {
                  class: 'ma-0',
                  props: {
                    flat: true,
                    rounded: true,
                    label: this.getTranslate('misc.buttons.clear')
                  },
                  on: {
                    click: () => {
                      this.proxy.expireAt = undefined
                    }
                  }
                }
              )
            ]
          )
        ]
      )
    ]
  )
}

function renderFieldPricePerMonth(h) {
  return h(
    'g-text-field',
    {
      props: {
        value: this.proxy.pricePerMonth,
        label: this.getTranslate(`${serviceName}.labels.pricePerMonth`),
        type: 'number',
        step: '0.0001',
        min: 0,
        mode: 'outline',
        dense: true,
        rounded: true,
        required: true,
        details: false
      },
      on: {
        input: event => {
          this.proxy.pricePerMonth = event
        }
      },
      key: 'price-per-month'
    }
  )
}

function renderOperators(h) {
  return h(
    operators,
    {
      props: {
        value: this.proxy.data
      },
      on: {
        input: event => {
          this.proxy.data = event
        }
      }
    }
  )
}

function renderFieldCounterType(h) {
  return h(
    'g-select',
    {
      props: {
        value: this.proxy.counterType,
        items: SUBSCRIPTION_COUNTER_TYPES.map(counterType => ({
          title: this.getTranslate(`${serviceName}.counterTypes.${counterType}`),
          value: counterType
        })),
        label: this.getTranslate(`${serviceName}.labels.counterType`),
        mode: 'outline',
        dense: true,
        rounded: true,
        required: true,
        details: false
      },
      on: {
        input: event => {
          this.proxy.counterType = event
        }
      },
      key: 'counter-type'
    }
  )
}

function renderFieldMaximum(h) {
  return h(
    'g-text-field',
    {
      props: {
        value: this.proxy.maximum,
        label: this.getTranslate(`${serviceName}.labels.maximum`),
        type: 'number',
        min: 0,
        mode: 'outline',
        dense: true,
        rounded: true,
        details: false
      },
      on: {
        input: event => {
          this.proxy.maximum = event
        }
      },
      key: 'maximum'
    }
  )
}

function renderFieldDiscountPrice(h) {
  return h(
    'g-text-field',
    {
      props: {
        value: this.proxy.discountPrice,
        label: this.getTranslate(`${serviceName}.labels.discountPrice`),
        type: 'number',
        step: '0.0001',
        min: 0,
        mode: 'outline',
        dense: true,
        rounded: true,
        details: false
      },
      on: {
        input: event => {
          this.proxy.discountPrice = event
        }
      },
      key: 'discount-price'
    }
  )
}

function renderFieldSenderName(h) {
  if (this.proxy.type) {
    const [ title, type ] = this.proxy.type.split('.') // eslint-disable-line

    return h(
      'g-select',
      {
        props: {
          value: this.proxy.SendernameId,
          label: this.getTranslate(`${serviceName}.labels.sendername`),
          service: 'sendernames',
          query: {
            type,
            isActive: true,
            $scope: [ 'usable' ]
          },
          itemTitle: 'name',
          itemValue: 'id',
          mode: 'outline',
          dense: true,
          rounded: true,
          clearable: true,
          autocomplete: true,
          required: !this.proxy.OwnerId,
          details: false
        },
        on: {
          input: event => {
            this.proxy.SendernameId = event
          }
        },
        key: 'sender-name'
      }
    )
  }
}

function renderFieldOwner(h) {
  return h(
    OwnerSelect,
    {
      props: {
        value: this.proxy.OwnerId,
        mode: 'outline',
        dense: true,
        rounded: true,
        details: false,
        clearable: true,
        required: !this.proxy.SendernameId
      },
      on: {
        input: event => {
          this.proxy.OwnerId = event
        }
      }
    }
  )
}

function renderContentByType(h) {
  switch (this.proxy.type) {
    case SUBSCRIPTION_TYPES['subscription.sms.sendername']: {
      return renderOperators.call(this, h)
    }

    default: {
      return h(
        'div',
        {
          class: 'grid grid-gap--8'
        },
        [
          renderFieldPricePerMonth.call(this, h),
          renderFieldCounterType.call(this, h),

          h(
            'div',
            {
              class: 'grid grid-gap--8 grid-cols--2'
            },
            [
              renderFieldMaximum.call(this, h),
              renderFieldDiscountPrice.call(this, h)
            ]
          )
        ]
      )
    }
  }
}

export default function(h) {
  return h(
    'g-form',
    {
      class: 'grid grid-gap--8',
      on: {
        submit: () => {
          this.$emit('submit', this.proxy)
        }
      }
    },
    [
      renderFieldType.call(this, h),
      renderFieldExpireAt.call(this, h),
      renderContentByType.call(this, h),
      renderFieldSenderName.call(this, h),
      renderFieldOwner.call(this, h)
    ]
  )
}
