import { SENDING_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import { sendingTypeColors } from '@/utils'

import HeadTitle from '@/components/misc/HeadTitle'
import messageTypeIcon from '@/components/message/type/icon'
import CatchSymbolsInput from '@/components/services/sendings/catchSymbolsInput'
import SendernameSelect from '@/components/misc/SendernameSelect'
import SendernamesCreateForm from '@/components/services/sendernames/form'
import SendingsWarning from '@/components/services/sendingsCreate/form/warning'
import sendingsCreateAttach from '@/components/services/sendingsCreate/form/attach'
import fallbackOptions from '@/components/services/sendingsCreate/form/fallback/options'
import generatorRow from '@/components/generator/row'
import apisender from '@/components/services/sendingsCreate/form/apisender'

const _ = { get: require('lodash/get') }

function renderTypes(h) {
  return h(
    'div',
    {
      class: 'fw'
    },
    this.localTypes.map(({ title, value, disabled }) => {
      return h(
        'g-button',
        {
          class: 'ml-0',
          props: {
            small: true,
            rounded: true,
            depressed: true,
            outline: this.proxy.type !== value,
            disabled: disabled || this.disabled || this.readonly,
            color: sendingTypeColors[value]
          },
          on: {
            click: () => {
              if (!this.disabled && !this.readonly) {
                this.proxy.type = value
              }
            }
          }
        },
        [
          h(
            'div',
            {
              class: 'faic'
            },
            [
              h(
                messageTypeIcon,
                {
                  props: {
                    value,
                    size: 16,
                    color: this.proxy.type === value ? '#ffffff' : ''
                  }
                }
              ),

              h('span', { class: 'ml-2' }, this.getTranslate(title))
            ]
          )
        ]
      )
    })
  )
}

function renderCreateSenderNameDialog(h) {
  if (this.readonly) {
    return
  }

  if (this.checkPermissions('advanced.sendernames.create', [ 'me', 'reseller', true ])) {
    return h(
      'g-dialog',
      {
        props: {
          rounded: true,
          value: this.showCreateNewSenderName,
          maxWidth: 400
        },
        on: {
          input: event => {
            this.showCreateNewSenderName = event
          }
        }
      },
      [
        h(
          HeadTitle,
          {
            props: {
              value: this.getTranslate('sendernames.titles.new')
            },
            slot: 'header'
          }
        ),

        h(
          SendernamesCreateForm,
          {
            props: {
              value: this.restData.sendernames.create.data,
              errors: this.restData.sendernames.create.errors
            },
            on: {
              input: event => {
                this.restData.sendernames.create.data = event
              },
              submit: async () => {
                if (this.restData.sendernames.create.isValid) {
                  const result = await this.rest.sendernames.create(this.restData.sendernames.create.data)
                  if (result) {
                    this.showCreateNewSenderName = false
                  }
                }
              },
              validation: event => {
                this.restData.sendernames.create.isValid = event
              }
            }
          }
        ),

        h(
          'g-card-actions',
          {
            class: 'fjcfe facfe',
            slot: 'footer'
          },
          [
            h(
              'g-button',
              {
                props: {
                  label: this.getTranslate('misc.buttons.cancel'),
                  flat: true,
                  rounded: true
                },
                on: {
                  click: () => {
                    this.showCreateNewSenderName = false
                  }
                }
              }
            ),

            h(
              'g-button',
              {
                props: {
                  label: this.getTranslate('misc.buttons.create'),
                  rounded: true,
                  depressed: true,
                  loading: this.restData.sendernames.create.state === 'loading',
                  disabled: this.restData.sendernames.create.state === 'loading' || !this.restData.sendernames.create.isValid,
                  color: 'primary'
                },
                on: {
                  click: async () => {
                    const result = await this.rest.sendernames.create(this.restData.sendernames.create.data)
                    if (result) {
                      this.showCreateNewSenderName = false
                    }
                  }
                }
              }
            )
          ]
        )
      ]
    )
  }
}

function renderCreateSenderNameButton(h) {
  if (this.readonly) {
    return
  }

  if (this.checkPermissions('advanced.sendernames.create', [ 'me', 'reseller', true ])) {
    return h(
      'g-button',
      {
        class: 'my-0 mr-0',
        props: {
          icon: 'add',
          flat: true,
          color: 'secondary',
          tabindex: -1
        },
        directives: [
          {
            name: 'g-tooltip',
            options: {
              value: this.getTranslate('sendingsCreate.createNewUser'),
              placement: 'right',
              offsetDistance: 8
            }
          }
        ],
        on: {
          click: () => {
            this.showCreateNewSenderName = true
          }
        }
      }
    )
  }
}

function renderSenderNameField(h) {
  return h(
    'div',
    {
      class: 'faic'
    },
    [
      h(
        SendernameSelect,
        {
          props: {
            value: this.proxy.payload.sender,
            type: this.proxy.type,
            errors: this.errors,
            disabled: this.disabled,
            readonly: this.readonly,
            forceAnySendername: this.isPattern,
            mode: 'outline',
            rounded: true,
            details: false
          },
          on: {
            input: event => {
              this.proxy.payload.sender = event
            }
          }
        }
      ),

      renderCreateSenderNameButton.call(this, h)
    ]
  )
}

function renderApisender(h) {
  if (this.readonly) {
    return
  }

  if (this.proxy.type === SENDING_TYPES.whatsapp && this.proxy.payload.sender) {
    return h(apisender, { props: { sender: this.proxy.payload.sender } })
  }
}

function renderIllegalRoutingWarning(h) {
  if (this.readonly) {
    return
  }

  return h(
    SendingsWarning,
    {
      props: {
        check: this.checkTestRoute,
        value: this.showIllegalRoutingWarning
      },
      on: {
        input: event => {
          this.showIllegalRoutingWarning = event
        }
      }
    }
  )
}

function renderTextField(h) {
  return h(
    CatchSymbolsInput,
    {
      props: {
        value: this.proxy.payload.text,
        sendingType: this.proxy.type,
        label: this.getTranslate('sendings.labels.text'),
        disabled: this.disabled || !this.isGeneratedFile,
        readonly: this.readonly,
        component: 'g-textarea',
        mode: 'outline',
        rounded: true,
        error: this.errors.text,
        tags: this.tags,
        customMenu: this.customMenu,
        maxLength: this.proxy.type === SENDING_TYPES.voice ? 3000 : null
      },
      on: {
        input: event => {
          this.generatedAudioFile = undefined
          this.proxy.payload.text = event
        }
      }
    }
  )
}

function renderHint(h) {
  if (this.readonly) {
    return
  }

  if (this.proxy.type === SENDING_TYPES.viber) {
    return h(
      'div',
      {
        class: 'flex faic text--grey caption py-2'
      },
      [
        h(
          'g-icon',
          {
            class: 'mr-2',
            props: { value: 'info_outline' }
          }
        ),
        this.getTranslate('sendingsCreate.hints.viberTemplates')
      ]
    )
  }

  return h('div')
}

function renderButtonFields(h) {
  if (~[
    SENDING_TYPES.viber,
    SENDING_TYPES.whatsappchat,
    SENDING_TYPES.viberchat,
    SENDING_TYPES.vkchat,
    SENDING_TYPES.telegramchat
  ].indexOf(this.proxy.type)) {
    if (this.proxy.payload.button) {
      return h(
        'div',
        {
          class: 'grid grid-cols--1 grid-gap--8'
        },
        [
          h(
            CatchSymbolsInput,
            {
              props: {
                value: this.proxy.payload.button.url,
                label: this.getTranslate('sendings.labels.button.url'),
                hint: this.getTranslate('sendings.hints.button.url'),
                type: 'url',
                mode: 'outline',
                rounded: true,
                disabled: this.disabled,
                readonly: this.readonly,
                error: _.get(this.errors, 'button.url') || this.validationButtonUrl,
                tags: this.tags,
                customMenu: this.customMenu
              },
              on: {
                input: event => {
                  this.proxy.payload.button.url = event
                }
              }
            }
          ),

          h(
            CatchSymbolsInput,
            {
              props: {
                value: this.proxy.payload.button.text,
                label: this.getTranslate('sendings.labels.button.text'),
                hint: this.getTranslate('sendings.hints.button.text'),
                mode: 'outline',
                rounded: true,
                disabled: this.disabled,
                readonly: this.readonly,
                error: _.get(this.errors, 'button.text') || this.validationButtonText,
                tags: this.tags,
                customMenu: this.customMenu
              },
              on: {
                input: event => {
                  this.proxy.payload.button.text = event
                }
              }
            }
          )
        ]
      )
    }
  }
}

function renderAttach(h) {
  if (!this.disabled || !this.readonly) {
    return h(
      sendingsCreateAttach,
      {
        props: { value: this.proxy },
        on: {
          input: event => {
            this.proxy = event
          },
          isGeneratedFile: event => {
            this.isGeneratedFile = event
          }
        }
      },
      [ renderButtonFields.call(this, h) ]
    )
  }
}

function renderWhatsAppButtons(h) {
  if (this.proxy.type === SENDING_TYPES.whatsapp) {
    if ((this.disabled || this.readonly) && (!this.proxy.payload.buttons || !this.proxy.payload.buttons.length)) {
      return
    }

    return h(
      generatorRow,
      {
        props: {
          value: this.proxy.payload.buttons,
          disabled: this.disabled || this.readonly,
          defaultValue: {
            text: undefined,
            action: undefined
          },
          max: 3
        },
        scopedSlots: {
          row: ({ item }) => {
            return h(
              'div',
              {
                class: 'grid grid-cols--2 grid-gap--8'
              },
              [
                h(
                  'g-text-field',
                  {
                    props: {
                      value: item.text,
                      label: this.getTranslate('sendings.labels.button.text'),
                      mode: 'outline',
                      rounded: true,
                      details: false,
                      disabled: this.disabled,
                      readonly: this.readonly
                    },
                    on: {
                      input: event => {
                        item.text = event
                      }
                    }
                  }
                ),

                h(
                  'g-text-field',
                  {
                    props: {
                      value: item.action,
                      label: this.getTranslate('sendings.labels.button.action'),
                      mode: 'outline',
                      rounded: true,
                      details: false,
                      disabled: this.disabled,
                      readonly: this.readonly
                    },
                    on: {
                      input: event => {
                        item.action = event
                      }
                    }
                  }
                )
              ]
            )
          }
        },
        on: {
          input: event => {
            this.proxy.payload.buttons = event
          }
        }
      }
    )
  }
}

function renderFallbackOptions(h) {
  if (this.fallback) {
    return h(
      fallbackOptions,
      {
        props: {
          value: this.proxy.$options,
          readonly: this.readonly
        },
        on: {
          input: event => {
            this.proxy.$options = event
          }
        }
      }
    )
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: 'grid grid-cols--1 grid-gap--8'
    },
    [
      renderTypes.call(this, h),
      renderSenderNameField.call(this, h),
      renderApisender.call(this, h),
      renderIllegalRoutingWarning.call(this, h),
      renderTextField.call(this, h),

      h('div', { attrs: { id: 'v-step-create-text' } }),

      renderWhatsAppButtons.call(this, h),

      renderAttach.call(this, h),
      renderHint.call(this, h),
      renderFallbackOptions.call(this, h),
      renderCreateSenderNameDialog.call(this, h)
    ]
  )
}
