import proxy from '@sigma-legacy-libs/g-proxy'

import render from './render'

export default {
  name: 'Old',

  mixins: [ proxy({ type: 'object' }) ],

  computed: {
    type() {
      return this.proxy.type ? this.proxy.type.split('.')[1] : undefined
    }
  },

  render
}
