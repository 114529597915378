import Vue from 'vue'

import { generateServices, globalErrorHandler, globalErrorProcessor, permissionPresets } from '@/utils'

import { serviceName } from '@/components/services/tokens/utils'

import render from './render'

export default {
  name: 'Create',

  mixins: [
    generateServices({
      name: serviceName,

      find: false,
      get: false,
      update: false,
      remove: false
    })
  ],

  props: {
    label: Boolean,
    OwnerId: String
  },

  data() {
    return {
      showDialog: false
    }
  },

  methods: {
    async create() {
      if (this.checkPermissions(`advanced.${serviceName}.create`, permissionPresets.me)) {
        if (this.restData[serviceName].create.isValid) {
          try {
            if (this.OwnerId) {
              Vue.set(this.restData[serviceName].create.data, 'OwnerId', this.OwnerId)
            }
            const result = await this.rest[serviceName].create(this.restData[serviceName].create.data)
            if (result) {
              this.$emit('create', result)
              this.showDialog = false
            }
          } catch (error) {
            globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
          }
        }
      }
    }
  },

  render
}
