import { BILLING_STATUS } from '@sigma-legacy-libs/essentials/lib/constants'

import { cleanUserForSendingsFilterByOwner } from '@/utils'

import OwnerSelect from '@/components/misc/OwnerSelect'
import DateTimeFilter from '@/components/misc/DateTimeFilter'
import RowWithMessageTypeIcon from '@/components/misc/RowWithMessageTypeIcon'

import { serviceName } from '@/components/services/billings/utils'

function renderTariffIdField(h) {
  if (this.checkPermissions('advanced.tariffs.find', [ 'reseller', true ])) {
    return h(
      'g-select',
      {
        props: {
          value: this.proxy.TariffId,
          label: this.getTranslate(`${serviceName}.labels.tariff`),
          service: 'tariffs',
          autocomplete: true,
          clearable: true,
          mode: 'outline',
          dense: true,
          rounded: true,
          details: false
        },
        on: {
          input: event => {
            this.proxy.TariffId = event
          }
        }
      }
    )
  }
}

function renderFirstColumn(h) {
  if (this.checkPermissions([ 'advanced.users.find', 'advanced.tariffs.find' ], [ 'reseller', true ])) {
    return h(
      'div',
      {
        class: 'grid grid-cols--1 grid-gap--8'
      },
      [
        h(
          OwnerSelect,
          {
            props: {
              value: this.proxy.OwnerId,
              clearItems: cleanUserForSendingsFilterByOwner,
              mode: 'outline',
              dense: true,
              rounded: true,
              details: false
            },
            on: {
              input: event => {
                this.proxy.OwnerId = event
              }
            }
          }
        ),

        renderTariffIdField.call(this, h)
      ]
    )
  }
}

function renderSecondColumn(h) {
  return h(
    'div',
    {
      class: 'grid grid-cols--1 grid-gap--8'
    },
    [
      h(
        'g-select',
        {
          props: {
            value: this.proxy.status,
            label: this.getTranslate(`${serviceName}.labels.status`),
            items: BILLING_STATUS.map(status => ({
              title: this.getTranslate(`${serviceName}.statuses.${status}`),
              value: status
            })),
            clearable: true,
            mode: 'outline',
            dense: true,
            rounded: true,
            details: false
          },
          on: {
            input: event => {
              this.proxy.status = event
            }
          }
        }
      ),

      h(
        'g-select',
        {
          props: {
            value: this.proxy.source,
            label: this.getTranslate(`${serviceName}.labels.source`),
            items: this.sourceItems,
            clearable: true,
            mode: 'outline',
            dense: true,
            rounded: true,
            details: false
          },
          scopedSlots: {
            selection: ({ item }) => {
              const [ source, type ] = item.value.split('.')

              return h(
                RowWithMessageTypeIcon,
                {
                  props: {
                    value: type || source,
                    title: item.title
                  }
                }
              )
            },
            item: ({ item }) => {
              const [ source, type ] = item.value.split('.')

              return h(
                RowWithMessageTypeIcon,
                {
                  props: {
                    value: type || source,
                    title: item.title
                  }
                }
              )
            }
          },
          on: {
            input: event => {
              this.proxy.source = event
            }
          }
        }
      )
    ]
  )
}

function renderThirdColumn(h) {
  return h(
    'div',
    {
      class: 'grid grid-cols--1 grid-gap--8'
    },
    [
      h(
        'g-text-field',
        {
          props: {
            value: this.proxy.id,
            label: this.getTranslate(`${serviceName}.labels.id`),
            clearable: true,
            mode: 'outline',
            dense: true,
            rounded: true,
            details: false
          },
          on: {
            input: event => {
              this.proxy.id = event
            }
          }
        }
      )
    ]
  )
}

function renderFourthColumn(h) {
  return h(
    'div',
    {
      class: 'fc faic'
    },
    [
      h(
        DateTimeFilter,
        {
          props: { value: this.proxy.createdAt },
          on: {
            input: event => {
              this.proxy.createdAt = event
            }
          }
        }
      ),

      h(
        'g-button',
        {
          props: {
            label: this.getTranslate('misc.filter.clear'),
            flat: true,
            rounded: true
          },
          on: {
            click: () => {
              this.clearFilter()
            }
          }
        }
      )
    ]
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: {
        'grid grid-gap--8 px-2': true,
        'grid-cols--4': this.viewport.breakpoint.lgUp,
        'grid-cols--3': this.viewport.breakpoint.md,
        'grid-cols--2': this.viewport.breakpoint.sm,
        'grid-cols--1': this.viewport.breakpoint.xs
      }
    },
    [
      renderFirstColumn.call(this, h),
      renderSecondColumn.call(this, h),
      renderThirdColumn.call(this, h),
      renderFourthColumn.call(this, h)
    ]
  )
}
