export default function(h) {
  return h(
    'g-button',
    {
      class: 'ma-0',
      props: {
        flat: true,
        icon: this.proxy === 'table' ? 'grid_view' : 'table_view'
      },
      directives: [
        {
          name: 'g-tooltip',
          options: {
            value: this.getTranslate(`commons.views.${this.proxy}`),
            placement: 'top'
          }
        }
      ],
      on: {
        click: () => {
          if (this.proxy === 'table') {
            this.set('cards')
          } else {
            this.set('table')
          }
        }
      }
    }
  )
}
