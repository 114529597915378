import { SENDING_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import SingleLink from '@/components/misc/SingleLink'
import EntityLink from '@/components/misc/EntityLink'
import copyIcon from '@/components/copy/icon'
import fileItemCard from '@/components/file/item/card'

import { getLocaleDateString, getLocaleTimeString, getRUB } from '@/utils'

import { serviceName } from '@/components/services/sendings/utils'

const _ = {
  get: require('lodash/get'),
  startCase: require('lodash/startCase')
}

function renderCopyIconTd(h, value) {
  return h(
    'td',
    {},
    [
      h(
        copyIcon,
        {
          props: {
            value: `${value}`
          }
        }
      )
    ]
  )
}

function renderId(h) {
  const id = _.get(this.value, 'id')
  if (id) {
    let name = `${this.service}.single`
    let prop = 'params'
    switch (this.service) {
      case 'sendings':
      case 'billings': {
        name = this.service
        prop = 'query'
      }
    }

    return h(
      'tr',
      {},
      [
        h('td', {}, this.getTranslate(`${serviceName}.labels.id`)),

        renderCopyIconTd.call(this, h, id),

        h(
          'td',
          {},
          [
            h(
              'router-link',
              {
                class: 'link',
                props: {
                  to: {
                    name,
                    [`${prop}`]: { id }
                  }
                }
              },
              id
            )
          ]
        )
      ]
    )
  }

  return h('tr')
}

function renderBillingIds(h) {
  const ids = _.get(this.value, 'meta.billings.ids')
  if (Array.isArray(ids) && ids.length) {
    return h(
      'tr',
      {},
      [
        h('td', {}, this.getTranslate(`${serviceName}.labels.meta.billing.id`)),

        renderCopyIconTd.call(this, h, ids),

        h(
          'td',
          {},
          [
            h(
              'div',
              {
                class: 'grid grid-gap--0'
              },
              ids.map(id => {
                return h(
                  'div',
                  {
                    class: 'faic'
                  },
                  [
                    h(
                      'router-link',
                      {
                        class: 'link',
                        props: {
                          to: {
                            name: 'billings',
                            query: { id }
                          }
                        }
                      },
                      id
                    ),

                    h(
                      copyIcon,
                      {
                        class: 'ml-2',
                        props: {
                          value: `${id}`
                        }
                      }
                    )
                  ]
                )
              })
            )
          ]
        )
      ]
    )
  }

  return h('tr')
}

function renderDetailedIds(h) {
  const detailedIds = _.get(this.value, 'state.detailed.ids')
  if (Array.isArray(detailedIds) && detailedIds.length) {
    return h(
      'tr',
      {},
      [
        h('td', {}, this.getTranslate(`${serviceName}.labels.state.detailed.ids`)),

        renderCopyIconTd.call(this, h, detailedIds),

        h(
          'td',
          {},
          [
            h(
              'div',
              {
                class: 'fc'
              },
              detailedIds.map(id => h('span', { class: 'lh-18' }, id))
            )
          ]
        )
      ]
    )
  }

  return h('tr')
}

function renderText(h) {
  const text = _.get(this.value, 'payload.text')
  if (text) {
    return h(
      'tr',
      {},
      [
        h('td', {}, this.getTranslate(`${serviceName}.labels.payload.text`)),

        renderCopyIconTd.call(this, h, text),

        h(
          'td',
          {},
          [
            h(
              'pre',
              {
                class: 'ma-0',
                style: { 'white-space': 'pre-wrap' }
              },
              text
            )
          ]
        )
      ]
    )
  }

  return h('tr')
}

function renderStatus(h) {
  const status = _.get(this.value, 'state.status', this.value.status)
  if (status) {
    return h(
      'tr',
      {},
      [
        h('td', {}, this.getTranslate(`${serviceName}.labels.status`)),

        renderCopyIconTd.call(this, h, status),

        h('td', {}, this.getTranslate(`${this.service || serviceName}.statuses.${status}`))
      ]
    )
  }

  return h('tr')
}
function renderType(h) {
  const type = _.get(this.value, 'type')
  if (type) {
    return h(
      'tr',
      {},
      [
        h('td', {}, this.getTranslate(`${serviceName}.labels.type`)),

        renderCopyIconTd.call(this, h, type),

        h('td', {}, this.getTranslate(`${serviceName}.types.${type}`))
      ]
    )
  }

  return h('tr')
}

function renderFile(h) {
  let value = this.value.File
  let type = 'file'
  if (!value) {
    for (const path of [ 'image', 'audio', 'video', 'file' ]) {
      value = _.get(this.value, `payload.${path}`, this.value[path])
      if (value) {
        type = path
        break
      }
    }
  }

  if (value) {
    return h(
      'tr',
      {},
      [
        h('td', {}, this.getTranslate(`${serviceName}.labels.${type}`)),

        renderCopyIconTd.call(this, h, value),

        h(
          'td',
          {},
          [
            h(
              fileItemCard,
              {
                props: {
                  value,
                  rounded: true,
                  outline: false,
                  paddingAll: false,
                  flat: true
                }
              }
            )
          ]
        )
      ]
    )
  }

  return h('tr')
}

function renderDurSec(h) {
  const durSec = _.get(this.value, 'state.communications[0].response.body.dursec')
  if (this.value.type === SENDING_TYPES.voice && durSec) {
    return h(
      'tr',
      {},
      [
        h('td', {}, this.getTranslate(`${serviceName}.labels.dursec`)),

        renderCopyIconTd.call(this, h, durSec),

        h('td', {}, `${durSec} ${this.getTranslate('misc.measure.time.sec')}`)
      ]
    )
  }

  return h('tr')
}
function renderBillSec(h) {
  const billSec = _.get(this.value, 'state.communications[0].response.body.billsec')
  if (this.value.type === SENDING_TYPES.voice && billSec) {
    return h(
      'tr',
      {},
      [
        h('td', {}, this.getTranslate(`${serviceName}.labels.billsec`)),

        renderCopyIconTd.call(this, h, billSec),

        h('td', {}, `${billSec} ${this.getTranslate('misc.measure.time.sec')}`)
      ]
    )
  }

  return h('tr')
}

function renderGroup(h) {
  const value = _.get(this.value, 'meta._recipientData.group')
  if (value) {
    return h(
      'tr',
      {},
      [
        h('td', {}, this.getTranslate(`${serviceName}.headers.operator`)),

        renderCopyIconTd.call(this, h, value),

        h('td', {}, value)
      ]
    )
  }

  return h('tr')
}

function renderPattern(h) {
  const patternId = _.get(this.value, 'meta.patternId')
  if (patternId) {
    return h(
      'tr',
      {},
      [
        h('td', {}, this.getTranslate(`${serviceName}.labels.pattern`)),

        renderCopyIconTd.call(this, h, patternId),

        h(
          'td',
          {},
          [
            h(
              EntityLink,
              {
                props: {
                  id: patternId,
                  service: 'patterns',
                  to: {
                    name: 'components',
                    query: {
                      currentTab: 'patterns',
                      id: patternId
                    }
                  }
                }
              }
            )
          ]
        )
      ]
    )
  }

  return h('tr')
}

function renderProvider(h) {
  if (this.checkPermissions('advanced.providers.get')) {
    const providerId = _.get(this.value, 'meta._routing.provider')
    if (providerId) {
      return h(
        'tr',
        {},
        [
          h('td', {}, this.getTranslate(`${serviceName}.labels.provider`)),

          renderCopyIconTd.call(this, h, providerId.id),

          h(
            'td',
            {},
            [
              h(
                SingleLink,
                {
                  props: {
                    value: providerId,
                    service: 'providers'
                  }
                }
              )
            ]
          )
        ]
      )
    }
  }

  return h('tr')
}

function renderLinkToService(h, service, pathToObject) {
  if (this.checkPermissions(`advanced.${service}.get`)) {
    const objectName = _.startCase(pathToObject)
    const idName = objectName + 'Id'
    const id = this.value[objectName] && this.value[objectName].id || this.value[idName]
    if (id) {
      return h(
        'tr',
        {},
        [
          h('td', {}, this.getTranslate(`${service}.labels.${pathToObject || service}`)),

          renderCopyIconTd.call(this, h, id),

          h(
            'td',
            {},
            [
              h(
                SingleLink,
                {
                  props: {
                    value: this.value[objectName] || id,
                    service
                  }
                }
              )
            ]
          )
        ]
      )
    }
  }

  return h('tr')
}

function renderDate(h, type) {
  const date = _.get(this.value, type)
  if (date) {
    return h(
      'tr',
      {},
      [
        h('td', {}, this.getTranslate(`misc.${type}`)),

        renderCopyIconTd.call(this, h, date),

        h(
          'td',
          {},
          [
            getLocaleDateString(date),
            getLocaleTimeString(date)
          ].join(' ')
        )
      ]
    )
  }

  return h('tr')
}

function renderRow(h, path) {
  const value = _.get(this.value, path)
  if (value) {
    return h(
      'tr',
      {},
      [
        h('td', {}, this.getTranslate(`${serviceName}.labels.${path}`)),

        renderCopyIconTd.call(this, h, value),

        h('td', {}, value)
      ]
    )
  }

  return h('tr')
}

function renderOptions(h) {
  if (this.service === 'sendingsFallbacks') {
    const options = _.get(this.value, '$options')
    const convertStatuses = statuses => {
      return statuses.map(status => this.getTranslate(`${serviceName}.statuses.${status}`).toLowerCase()).join(', ')
    }

    if (options) {
      return Object.keys(options).map(option => {
        const value = options[option]
        let content
        switch (option) {
          case 'onStatus': {
            content = convertStatuses(value)
            break
          }
          case 'onTimeout': {
            content = `${this.getTranslate(`${serviceName}.labels.options.timeout`).toLowerCase() + ': ' + value.timeout + ' ' + this.getTranslate('misc.measure.time.sec').toLowerCase()}`
            content += ', '
            content += `${this.getTranslate(`${serviceName}.labels.options.except`).toLowerCase() + ': ' + convertStatuses(value.except)}`
            break
          }
          case 'onIncomingPayload': {
            content = value.text
            break
          }
        }

        return h(
          'tr',
          {},
          [
            h('td', {}, this.getTranslate(`${serviceName}.labels.$options.${option}`)),

            renderCopyIconTd.call(this, h, value),

            h('td', {}, content)
          ]
        )
      })
    }
  }
}

function renderRowByService(h, path) {
  const value = _.get(this.value, path)
  switch (this.service) {
    case 'billings': {
      switch (path) {
        case 'amount': {
          if (value) {
            return h(
              'tr',
              {},
              [
                h('td', {}, this.getTranslate(`${this.service}.labels.${path}`)),

                renderCopyIconTd.call(this, h, value),

                h('td', {}, getRUB(value))
              ]
            )
          }

          return h('tr')
        }
        case 'source': {
          if (value) {
            const [ source, type ] = value.split('.')

            return h(
              'tr',
              {},
              [
                h('td', {}, this.getTranslate(`${this.service}.labels.source`)),

                renderCopyIconTd.call(this, h, value),

                h('td', {}, this.getTranslate(`${this.service}.sources.${type || source}`))
              ]
            )
          }

          return h('tr')
        }
        case 'data.price': {
          if (value) {
            return h(
              'tr',
              {},
              [
                h('td', {}, this.getTranslate(`${this.service}.labels.price`)),

                renderCopyIconTd.call(this, h, value),

                h('td', {}, getRUB(value))
              ]
            )
          }

          return h('tr')
        }
        case 'data.multiplier': {
          if (value) {
            return h(
              'tr',
              {},
              [
                h('td', {}, this.getTranslate(`${this.service}.labels.multiplier`)),

                renderCopyIconTd.call(this, h, value),

                h('td', {}, value)
              ]
            )
          }

          return h('tr')
        }
      }
      break
    }
  }
}

export default function(h) {
  return h(
    'table',
    {
      class: `${serviceName}-format-table`
    },
    [
      h(
        'tbody',
        {},
        [
          renderId.call(this, h),

          renderRow.call(this, h, 'chainId'),

          renderBillingIds.call(this, h),

          renderDetailedIds.call(this, h),

          renderRow.call(this, h, 'groupId'),

          renderStatus.call(this, h),
          renderType.call(this, h),

          renderRowByService.call(this, h, 'source'),

          renderRow.call(this, h, 'payload.sender'),
          renderRow.call(this, h, 'payload.recipient'),

          renderText.call(this, h),

          renderRow.call(this, h, 'payload.button.url'),
          renderRow.call(this, h, 'payload.button.text'),

          renderFile.call(this, h),

          renderRow.call(this, h, 'meta.stats.segments'),
          renderRow.call(this, h, 'meta.stats.characters'),

          renderDurSec.call(this, h),
          renderBillSec.call(this, h),

          renderRowByService.call(this, h, 'amount'),
          renderRowByService.call(this, h, 'data.price'),
          renderRowByService.call(this, h, 'data.multiplier'),

          renderOptions.call(this, h),

          renderGroup.call(this, h),
          renderPattern.call(this, h),
          renderLinkToService.call(this, h, 'users', 'owner'),
          renderLinkToService.call(this, h, 'tariffs', 'tariff'),
          renderProvider.call(this, h),

          renderDate.call(this, h, 'createdAt'),
          renderDate.call(this, h, 'updatedAt')
        ]
      )
    ]
  )
}
