export default function(h) {
  const directives = []
  if (this.tooltip) {
    directives.push({
      name: 'g-tooltip',
      options: { value: this.tooltip }
    })
  }

  return h(
    'g-button',
    {
      attrs: { 'data-clipboard-text': `${this.value}` },
      class: {
        [`copy-button-${this._uid}`]: true,
        'ma-0': this.marginless
      },
      props: {
        icon: this.icon,
        iconLibrary: this.iconLibrary,
        color: this.color,
        flat: this.flat,
        small: this.small,
        large: this.large
      },
      directives
    }
  )
}
