import { serviceName } from '@/components/services/providers/utils'

const colors = {
  active: 'success',
  inactive: 'grey',
  connecting: 'primary',
  disconnecting: 'error',
  stopped: 'warning',
  'half-open': 'info'
}
const icons = {
  active: 'check_circle_outline',
  inactive: 'pause_circle_outline',
  connecting: 'sync',
  disconnecting: 'highlight_off',
  stopped: 'do_not_disturb_on',
  'half-open': 'access_time'
}

export default function(h) {
  let value = icons[this.state]
  if (!value) {
    if (~this.state.indexOf('error')) {
      value = 'error_outline'
    }
    value = 'help_outline'
  }

  return h(
    'g-icon',
    {
      props: {
        value,
        color: colors[this.state] || 'grey',
        size: this.size
      },
      directives: [
        {
          name: 'g-tooltip',
          options: {
            value: this.getTranslate(`${serviceName}.states.${this.state}`)
          }
        }
      ]
    }
  )
}
