export default {
  title: 'Тарифы',

  tariff: 'Тариф',
  tariffs: 'Тарифы',
  new: 'Новый тариф',
  create: 'Создать новый тариф',
  activeAt: 'Активен с',
  cycle: 'Цикл',
  for: 'для',
  inherited: 'Наследовано из тарифа',
  withOperatorGroups: '(с группой оператора)',
  sendingId: 'ID отправления',
  conditions: 'Условия',
  customize: 'Изменить',
  unconditional: 'Безусловное правило',
  usersOnTariff: 'Пользователи на тарифе',
  addRule: 'Добавить правило',
  addSetConditions: 'Добавить группу условий',
  addAnotherSetConditions: 'Добавить ещё одну группу условий',
  always: 'Всегда',
  otherCases: 'Иначе',
  choosePath: '<Выберите, что проверять>',
  chooseCondition: '<Укажите, как проверять>',
  matchesWith: 'Совпадает с',
  matchesWithRegex: 'регулярным выражением',
  matchesWithMultiple: 'одним из значений',
  operator: 'ни одного оператора | один оператор | {count} оператора(ов)',
  sender: 'ни одного имени отправителя | одно имя отправителя | {count} имени отправителя',

  account: {
    rules: {
      direction: {
        incoming: 'За входящее',
        outgoing: 'За исходящее'
      },
      rule: {
        inSession: 'в сессии',
        nonSession: 'вне сессии',
        service: 'сервисное сообщение',
        marketing: 'рекламное сообщение',
        second: 'за секунду',
        minute: 'за минуту',
        segment: 'за сегмент',
        message: 'за сообщение'
      }
    }
  },

  per: {
    second: 'За секунду',
    minute: 'За минуту',
    segment: 'За сегмент'
  },

  types: {
    subscription: {
      apisender: 'Подписка API sender'
    }
  },

  sources: {
    data: {
      new: 'Новый формат',
      legacy: 'Старый формат'
    },
    rates: 'Цены'
  },

  add: {
    tariff: 'Добавить тариф'
  },

  cycles: {
    month: 'Месяц',
    week: 'Неделя',
    day: 'День',
    trigger: 'Trigger'
  },

  titles: {
    create: 'Создать новый тариф',
    defaultRule: 'Правило по умолчанию, исполнится в случае если не указаны другие правила или тарифицируемое сообщение не подходит под указанные в указанных выше правилах условиях.'
  },

  labels: {
    tariff: 'Тариф',
    title: 'Заголовок',
    type: 'Тип',
    source: 'Формат',
    cycle: 'Цикл',
    cycleStart: 'Начало цикла',
    price: 'Цена',
    comment: 'Комментарий',
    owner: 'Владелец',
    actions: 'Действие',
    refundOnFailed: 'Возместить при неудаче',
    noPayments: 'Может быть бесплатным',
    search: 'Поиск',
    currency: 'Валюта',
    per: 'За',
    fromEmail: 'E-mail',
    searchPattern: 'Поисковый паттерн',

    activePeriod: {
      start: 'Стартовая дата',
      end: 'Конечная дата'
    },

    conditions: {
      path: 'Путь',
      comparator: 'Сравнение',
      value: 'Значение',
      negate: 'Отрицание',
      multiple: 'Выберите из списка',
      regex: 'Впишите регулярное выражение'
    },

    directions: {
      outgoing: {
        service: 'Цена за сервисное сообщение',
        marketing: 'Цена за рекламное сообщение',
        nonSession: 'Цена за вне сессионное сообщение',
        inSession: 'Цена за сессионное сообщение'
      },
      incoming: {
        nonSession: 'Цена за вне сессионное сообщение',
        inSession: 'Цена за сессионное сообщение'
      }
    }
  },

  dialogs: {
    new: 'Новый тариф'
  },

  subheaders: {
    rules: 'Правила',
    conditions: 'Условия',
    activePeriod: {
      title: 'Активный период',
      start: 'Начало активного периода',
      end: 'Конец активного периода'
    },
    cycleStart: 'Начало цикла'
  },

  actions: {
    decrease: 'Списать',
    increase: 'Начислить',
    main: {
      decrease: 'Списывать',
      increase: 'Начислять'
    }
  },

  directions: {
    outgoing: 'Исходящие',
    incoming: 'Входящие'
  },

  buttons: {
    add: {
      tariff: 'Добавить тариф',
      rule: 'Добавить правило',
      condition: 'Добавить условие'
    }
  },

  tooltips: {
    empty: {
      condition: 'Условие заполнено не полностью'
    },
    custom: 'Кастомизированный',
    favorites: 'Добавить тариф в избранные'
  },

  snackbars: {
    created: 'Тариф успешно создан.',
    updated: 'Тариф успешно обновлён.',
    removed: 'Тариф успешно удалён.'
  },

  hints: {
    default: 'За сообщение',
    perCall: 'За сегмент',
    perSecond: 'За секунду',
    notFound: 'Тарифы не найдены',
    rates: 'Импорт цен станет доступным после создания тарифа'
  }
}
