import sendings from './sendings'

export default {
  title: 'User settings',

  titles: {
    sendings: 'Sendings',
    billings: 'Billings',
    referral: 'Referral',
    websocket: 'Websocket',
    smpp: 'SMPP',
    webhooks: 'Webhooks',
    notifications: 'Notifications',
    statuses: 'Mapping statuses',
    integrations: 'Integrations'
  },

  subtitles: {
    sendings: {
      duplicates: 'Duplicates',
      rateLimit: 'Rate limiting'
    },

    billings: {
      autoAccrual: 'Auto accrual',
      processChildrenOperations: 'Process children operations'
    },

    referral: {
      billings: {
        processedChildOperations: 'Billings operations'
      }
    },

    webhooks: {
      general: 'General',
      successOn: 'Conditions for successful request'
    },

    notifications: {
      enabled: 'Notifications enabled',
      events: {
        title: 'Notification events',
        billings: {
          limit: 'Critical balance notification'
        },
        providers: {
          health: {
            recipients: 'Email template for provider status notification'
          }
        }
      }
    },

    integrations: {
      pyrus: {
        credentials: 'Pyrus credentials'
      },
      yclients: {
        applicationCredentials: 'Application credentials'
      }
    }
  },

  dialogs: {
    titles: {
      webhooks: {
        onStatusChange: 'On status change',
        onIncomingMessage: 'Incoming'
      }
    }
  },

  labels: {
    sendings: {
      allowDuplicates: 'Allow duplicates',
      duplicates: {
        hashFields: 'Fields for duplicate checking',
        timeout: 'Duplicate timeout'
      },
      rateLimit: {
        maxAmount: 'Message amount',
        timeout: 'Timeout'
      },
      tilda: {
        sendFromForms: 'Allow send message from Tilda forms'
      }
    },

    billings: {
      autoAccrual: {
        limit: 'Limit',
        requiredBalance: 'Required balance'
      },
      overdraft: 'Overdraft',
      processChildrenOperations: 'Process children account operations as own',
      billOnErrors: 'Bill SMS in case of errors'
    },

    referral: {
      billings: {
        processedChildOperations: {
          source: 'Source',
          percent: 'Percent',
          limitMin: 'Min limit',
          limitMax: 'Max limit'
        }
      }
    },

    smpp: {
      enabled: 'SMPP enabled',
      rudeMode: 'SMPP rude mode',
      defaultMessageType: 'SMPP default message type',
      ipWhitelist: 'IP whitelist'
    },

    webhooks: {
      title: 'Message status change',
      url: 'Request URL',
      method: 'Request method',
      headers: 'Request headers',
      body: 'Request body',
      maxConnections: 'Maximum connections amount',
      successOn: {
        status: 'Regexp check on status',
        body: 'Regexp check on response body'
      }
    },

    notifications: {
      enabled: {
        types: sendings.types
      },
      extraRecipients: 'Notification Recipients',
      type: 'Type notification',
      events: {
        billings: {
          limit: {
            host: 'Host',
            port: 'Port',
            from: 'From',
            login: 'Login',
            password: 'Password',
            limit: 'Limit',
            include: {
              overdraft: 'Including overdraft',
              invoice: 'invoice included'
            },
            notInclude: {
              overdraft: 'Excluding overdraft',
              invoice: ''
            }
          }
        },
        providers: {
          health: {
            recipients: 'Provider health notifications recipients'
          }
        }
      }
    },

    websocket: {
      channels: 'Channels'
    },

    integrations: {
      pyrus: {
        credentials: {
          login: 'Login',
          security_key: 'Security key'
        }
      }
    }
  },

  contents: {
    webhooks: {
      onStatusChange: 'On status change',
      onIncomingMessage: 'Incoming',
      statuses: 'Statuses'
    },

    notifications: {
      events: {
        billings: {
          limit: 'Critical balance notification'
        },
        providers: {
          health: {
            recipients: 'Email template for provider status notification'
          }
        }
      }
    }
  },

  hints: {
    webhooks: {
      onStatusChange: 'Define webhook that will be fired on each message status change',
      onIncomingMessage: 'Define webhook that will be fired on incoming message'
    },

    notifications: {
      events: {
        billings: {
          limit: {
            onLimit: 'When balance is',
            noLimit: '<not specified>',
            notifyBy: 'notify via',
            noNotifications: '<no method selected>',
            activator: 'We will send you notification when your balance will reach {limit}',
            overdraft: ' + user overdraft',
            extraRecipients: 'Notifications on the balance status of all users of the system',
            invoice: 'Attach invoice for the amount {amount}'
          }
        }
      }
    },
    warning: 'Changing settings does not require user update.'
  },

  items: {
    websocket: {
      channels: {
        dbUpdates: 'DB update',
        everything: 'Everything',
        health: 'Health',
        sendingStatusUpdates: 'Sending status updates',
        providersStatesUpdates: 'Providers states updates'
      }
    }
  },

  buttons: {
    notifications: {
      events: {
        billings: {
          limit: {
            add: 'Add limit',
            invoice: {
              attach: 'Attach invoice',
              detach: 'Detach invoice',
              attached: 'Invoice will be attached for an amount {amount}'
            }
          }
        }
      }
    }
  },

  snackbars: {
    updated: 'Setting successfully updated.'
  }
}
