const countTypes = [ 'completed', 'errored', 'remaining', 'total' ]

const icons = {
  completed: 'done',
  errored: 'clear',
  remaining: 'history',
  total: 'list'
}

const colors = {
  completed: 'success',
  errored: 'error',
  remaining: 'primary',
  total: 'grey'
}

function renderCount(h, type) {
  return h(
    'g-chip',
    {
      props: {
        tiny: true,
        label: this.counts.values[type],
        icon: icons[type],
        color: colors[type]
      },
      directives: [
        {
          name: 'g-tooltip',
          options: {
            value: this.counts.tooltips[type]
          }
        }
      ]
    }
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: 'faic'
    },
    countTypes.map(type => {
      return renderCount.call(this, h, type)
    })
  )
}
