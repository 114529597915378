import Vue from 'vue'

import { unVue } from '@sigma-legacy-libs/unvue'
import { mapActions, mapGetters } from 'vuex'
import { DOCUMENT_TEMPLATES_TYPES, PAYMENTS_METHODS, RECURRENT_PAYMENTS_METHODS } from '@sigma-legacy-libs/essentials/lib/constants'

import { DEFAULT_INVOICE_PAYLOAD, DEFAULT_PAYMENT_PAYLOAD, OUR_DOMAINS, backendServerIP, getUrlToStorage, globalErrorHandler, globalErrorProcessor } from '@/utils'

import render from './render'

const _ = { get: require('lodash/get') }

export default {
  name: 'ButtonPayment',

  data() {
    return {
      step: 'first',

      show: false,

      loading: false,

      payload: {
        legal: unVue(DEFAULT_INVOICE_PAYLOAD),
        individual: unVue(DEFAULT_PAYMENT_PAYLOAD)
      }
    }
  },

  computed: {
    ...mapGetters({
      paymentStep: 'global/paymentStep',
      showPaymentDialog: 'currentTab/showPaymentDialog'
    }),

    validation() {
      let isValidType = false
      if (this.payload.legal.type === DOCUMENT_TEMPLATES_TYPES.contract) {
        isValidType = true
      } else if (this.payload.legal.type === DOCUMENT_TEMPLATES_TYPES.invoice) {
        if (this.payload.legal.data.SERVICE_PRICE) {
          isValidType = true
        }
      }

      return !!(this.payload.legal.requisiteId && this.payload.legal.companyId && isValidType)
    },

    paymentTypes() {
      const result = [
        {
          name: 'legal',
          priority: 2
        }
      ]
      if (Array.isArray(this.paymentMethods) && this.paymentMethods.length) {
        result.push({
          name: 'individual',
          priority: 1
        })
      }

      return result.sort((a, b) => a.priority - b.priority)
    },

    paymentMethods() {
      const result = []
      const methods = _.get(this.globalSettings, 'payments.methods', [])
      if (methods.some(method => ~PAYMENTS_METHODS.indexOf(method))) {
        result.push({
          name: 'cards',
          priority: 1
        })
      }
      if (~OUR_DOMAINS.indexOf(window.location.hostname)) {
        result.push({
          name: 'yooMoney',
          priority: 3
        })
      }
      if (methods.some(method => ~RECURRENT_PAYMENTS_METHODS.indexOf(method))) {
        result.push({
          name: 'recurrent',
          priority: 9
        })
      }

      return result.sort((a, b) => a.priority - b.priority)
    }
  },

  watch: {
    paymentStep() {
      if (this.paymentStep) {
        this.step = this.paymentStep
        this.setShowPaymentDialog(true)
        this.setPaymentStep(undefined)
      }
    }
  },

  mounted() {
    this.payload.individual.OwnerId = this.account.id
  },

  methods: {
    ...mapActions({
      setPaymentStep: 'global/setPaymentStep',
      setShowPaymentDialog: 'currentTab/setShowPaymentDialog',
      setShowCreateRecurrentPaymentDialog: 'currentTab/setShowCreateRecurrentPaymentDialog'
    }),

    async requestPayment() {
      const amount = this.payload.individual.amount
      if (amount && this.payload.individual.acceptOffer) {
        try {
          this.loading = true

          const method = this.payload.individual.method

          const { data } = await Vue.$GRequest.create('payments', {
            method,
            amount,
            urlSuccess: `${backendServerIP}/api/payments/webhook/${method}/success/`,
            urlFail: `${backendServerIP}/api/payments/webhook/${method}/fail/`
          })

          if (data && data.url) {
            window.location.href = data.url
          }
        } catch (error) {
          globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
        } finally {
          this.loading = false
        }
      }
    },

    async requestDocument() {
      try {
        this.loading = true
        const { data: file } = await Vue.$GRequest.find('documentTemplates/generate', { query: this.payload.legal })
        if (file) {
          window.location.href = getUrlToStorage([ file.OwnerId, file.id ])
        }
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      } finally {
        this.loading = false
      }
    }
  },

  render
}
