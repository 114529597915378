export default {
  RUB: {
    title: 'ruble',
    short: 'rub.',
    symbol: {
      html: '&#8381;',
      unicode: '₽'
    }
  },
  EUR: {
    title: 'euro',
    short: 'eur',
    symbol: {
      html: '&#8364;',
      unicode: '€'
    }
  },
  USD: {
    title: 'dollar',
    short: 'usd',
    symbol: {
      html: '&#36;',
      unicode: '$'
    }
  }
}
