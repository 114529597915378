import { REGEXP_SENDERNAME_SMS, REGEXP_SENDERNAME_VOICE } from '@sigma-legacy-libs/essentials/lib/constants'

export const serviceName = 'sendernames'

export function translateHandler(item) {
  if (item.type) {
    if (item.expected === REGEXP_SENDERNAME_VOICE.toString()) {
      return this.getTranslate(`${serviceName}.errors.isVoice`)
    }
    if (item.expected === REGEXP_SENDERNAME_SMS.toString()) {
      return this.getTranslate(`${serviceName}.errors.isSms`)
    }

    return item.message
  }
}

export function errorProcessor(errors) {
  if (!Array.isArray(errors)) {
    errors = [ errors ]
  }

  const resultErrors = []

  errors.forEach(error => {
    if (process.env.NODE_ENV === 'development') {
      resultErrors.push({
        type: 'console',
        raw: error
      })
    }

    if (Array.isArray(error.data) && error.data.length) {
      error.data.forEach(item => {
        resultErrors.push({
          type: 'field',
          field: item.field,
          translate: translateHandler.call(this, item),
          raw: error
        })
      })
    }
  })

  return resultErrors
}

export function errorProcessorHandler(errors) {
  return errorProcessor.call(this.ctx, errors)
}

export default {
  translateHandler,

  errorProcessor,
  errorProcessorHandler,

  serviceName
}
