import { fillDependence, permissionPresets, serviceTemplate } from '@/utils'

import { serviceName } from '@/components/services/tariffRatesUpdating/utils'

export default {
  mixins: [
    serviceTemplate({
      serviceName,

      async inputFilter(result) {
        if (!result.ParentTariff) {
          await fillDependence.call(this, result, {
            service: 'tariffs',
            permissions: permissionPresets.admin,
            pathToId: 'parentTariffId',
            pathToObject: 'ParentTariff',
            outputPath: 'ParentTariff',
            picks: [ 'id', 'title', 'type' ]
          })
        }
        if (!result.ChildTariff) {
          await fillDependence.call(this, result, {
            service: 'tariffs',
            permissions: permissionPresets.admin,
            pathToId: 'childTariffId',
            pathToObject: 'ChildTariff',
            outputPath: 'ChildTariff',
            picks: [ 'id', 'title', 'type' ]
          })
        }

        return result
      },

      permission: {
        get: { serviceName: 'tariffs' },
        remove: { serviceName: 'tariffs' },
        create: { serviceName: 'tariffs' },
        update: { serviceName: 'tariffs' }
      },

      width: 500
    })
  ]
}
