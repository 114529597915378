import messageTypeIcon from '@/components/message/type/icon'

export default function(h) {
  const { type } = this.value
  if (type) {
    const [ title, sendingType ] = type.split('.') // eslint-disable-line

    return h(
      'div',
      {
        class: 'fjcc faic square--36'
      },
      [
        h(
          messageTypeIcon,
          {
            props: {
              value: sendingType
            }
          }
        )
      ]
    )
  }
}
