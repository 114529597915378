import { serviceName } from '@/components/services/subscriptions/utils'

export default function(h) {
  const { data } = this.value
  const operators = Object.keys(data).reduce((result, operator) => {
    const { approved, isPaid } = data[operator]
    if (approved && isPaid) {
      result.push(this.getTranslate(`${serviceName}.operators.${operator}`))
    }

    return result
  }, [])

  if (operators.length) {
    return h(
      'div',
      {
        class: 'text-overflow'
      },
      operators.join(', ')
    )
  } else {
    return h(
      'div',
      {
        class: 'text-overflow text--grey'
      },
      this.getTranslate(`${serviceName}.hints.noOperators`)
    )
  }
}
