import { serviceName } from '@/components/services/credentials/utils'

export default function(h) {
  return h(
    'g-form',
    {
      class: 'grid pa-3',
      style: { 'grid-template-columns': 'repeat(1, minmax(200px, 1fr))' },
      on: {
        submit: () => {
          this.$emit('submit', this.proxy)
        },
        validation: event => {
          this.$emit('validation', event)
        }
      }
    },
    [
      h(
        'g-text-field',
        {
          props: {
            value: this.proxy.title,
            label: this.getTranslate(`${serviceName}.labels.title`),
            mode: 'outline',
            rounded: true,
            dense: true,
            clearable: true,
            required: true,
            details: false,
            autofocus: true
          },
          on: {
            input: event => {
              this.proxy.title = event
            }
          }
        }
      ),

      h('div', { class: 'caption text--grey' }, this.getTranslate(`${serviceName}.hints.data`)),

      h(
        'g-text-field',
        {
          props: {
            value: this.proxy.records.username,
            label: this.getTranslate(`${serviceName}.labels.records.username`),
            mode: 'outline',
            name: 'new-username',
            autocomplete: 'new-username',
            rounded: true,
            dense: true,
            clearable: true,
            details: false
          },
          on: {
            input: event => {
              this.proxy.records.username = event
            }
          }
        }
      ),

      h(
        'g-text-field',
        {
          props: {
            value: this.proxy.records.password,
            label: this.getTranslate(`${serviceName}.labels.records.password`),
            mode: 'outline',
            name: 'new-password',
            autocomplete: 'new-password',
            type: this.passwordVisibility ? 'password' : 'text',
            afterIcon: this.passwordVisibility ? 'visibility' : 'visibility_off',
            afterIconCallback: () => {
              this.passwordVisibility = !this.passwordVisibility
            },
            clearable: true,
            dense: true,
            rounded: true,
            details: false
          },
          on: {
            input: event => {
              this.proxy.records.password = event
            }
          }
        }
      ),

      h(
        'g-text-field',
        {
          props: {
            value: this.proxy.records.token,
            label: this.getTranslate(`${serviceName}.labels.records.token`),
            mode: 'outline',
            dense: true,
            rounded: true,
            clearable: true,
            details: false
          },
          on: {
            input: event => {
              this.proxy.records.token = event
            }
          }
        }
      ),

      h(
        'g-text-field',
        {
          props: {
            value: this.proxy.records.apikey,
            label: this.getTranslate(`${serviceName}.labels.records.apikey`),
            mode: 'outline',
            dense: true,
            rounded: true,
            clearable: true,
            details: false
          },
          on: {
            input: event => {
              this.proxy.records.apikey = event
            }
          }
        }
      )
    ]
  )
}
