import { createdAtFilter, getLocaleDateString } from '@/utils'

import render from './render'

function isSingleDate(date) {
  if (date.length !== 2) {
    return false
  }

  date = date.map(ts => {
    const d = new Date(ts)
    d.setHours(0, 0, 0, 0)

    return d.getTime()
  })

  if (date[0] === date[1]) {
    return true
  }

  return false
}

export default {
  name: 'DateTimeFilter',

  mixins: [ createdAtFilter ],

  data() {
    return {
      showDialog: false
    }
  },

  computed: {
    activatorContent() {
      let result = this.getTranslate('misc.dateTimeFilter.empty')
      if (this.proxy.date.length === 1 || isSingleDate(this.proxy.date)) {
        result = getLocaleDateString(this.proxy.date[0]) + ' ' + this.proxy.time.$gt + ' — ' + this.proxy.time.$lt
      } else if (this.proxy.date.length === 2) {
        result =
          getLocaleDateString(this.proxy.date[0]) +
          ' ' +
          this.proxy.time.$gt +
          ' — ' +
          getLocaleDateString(this.proxy.date[1]) +
          ' ' +
          this.proxy.time.$lt
      }

      return result
    }
  },

  render
}
