import { unVue } from '@sigma-legacy-libs/unvue'

import { BILLINGS_FILTER, dateFilter, permissionPresets } from '@/utils'

import { serviceName } from '@/components/services/billings/utils'

import render from './render'

export default {
  name: 'Filters',

  mixins: [ dateFilter ],

  computed: {
    sourceItems() {
      const permission = `advanced.${serviceName}.find`
      const result = [
        {
          title: this.getTranslate('sendings.types.accrual'),
          value: 'accrual'
        },
        {
          title: this.getTranslate('sendings.types.subtract'),
          value: 'subtract'
        }
      ]

      if (this.checkPermissions(permission, permissionPresets.reseller)) {
        result.push(...this.getSendingTypesByPermission(permission, permissionPresets.reseller).map(type => {
          return {
            title: `${this.getTranslate('sendings.sendings')} ${this.getTranslate(type.title)}`,
            value: `sendings.${type.value}`
          }
        }))
      }

      return result
    }
  },

  methods: {
    getDefaultFilter() {
      return unVue(BILLINGS_FILTER)
    },

    clearFilter() {
      this.proxy = unVue(this._inputFilter(this.getDefaultFilter()))
    }
  },

  render
}
