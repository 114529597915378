import formatTab from '@/components/services/sendings/outgoing/details/tabs/format'
import billingsTab from '@/components/services/sendings/outgoing/details/tabs/billings'
import routingTab from '@/components/services/sendings/outgoing/details/tabs/routing'
import fallbacksTab from '@/components/services/sendings/outgoing/details/tabs/fallbacks'
import replacementTab from '@/components/services/sendings/outgoing/details/tabs/replacement'

import SourceBox from '@/components/source/box'
import providersLogs from '@/components/services/providers/logs'

import { serviceName } from '@/components/services/sendings/utils'

const _ = { get: require('lodash/get') }

function renderDataFormatTab(h) {
  if (this.value) {
    return h(
      formatTab,
      {
        props: { value: this.value },
        key: 'format'
      }
    )
  }
}
function renderDataSourceTab(h) {
  if (this.value) {
    return h(
      SourceBox,
      {
        props: { value: this.value },
        key: 'source'
      }
    )
  }
}
function renderBillingsTab(h) {
  const value = _.get(this.value, 'meta.billings')
  const id = _.get(this.value, 'meta.billing.id')
  if (value || id) {
    return h(
      billingsTab,
      {
        props: {
          value,
          id
        },
        key: 'billings'
      }
    )
  }
}
function renderFallbacksTab(h) {
  const id = _.get(this.value, 'id')
  if (id) {
    return h(
      fallbacksTab,
      {
        props: { id },
        key: 'fallbacks'
      }
    )
  }
}
function renderLogsTab(h) {
  const provider = _.get(this.value, 'meta._routing.provider')
  if (provider) {
    const start = new Date(this.value.createdAt).getTime()
    const end = new Date(start)
    end.setDate(end.getDate() + 3)

    return h(
      providersLogs,
      {
        props: {
          ProviderId: provider,
          SendingId: this.value.id,
          start,
          end: end.getTime(),
          readonly: true,
          dialog: true
        },
        key: 'logs'
      }
    )
  }
}
function renderRoutingTab(h) {
  if (this.value) {
    return h(
      routingTab,
      {
        props: { value: this.value },
        key: 'routing'
      }
    )
  }
}
function renderReplacementTab(h) {
  return h(
    replacementTab,
    {
      props: { value: this.value },
      key: 'replacement'
    }
  )
}

function renderDialog(h) {
  return h(
    'g-dialog',
    {
      props: {
        rounded: true,
        value: this.showDialog,
        maxWidth: this.viewport.breakpoint.mdUp ? 1000 : '100%'
      },
      on: {
        input: event => {
          if (event === false) {
            this.$emit('close', true)
          }
          this.showDialog = event
        }
      }
    },
    [
      h(
        'g-tabs-header',
        {
          props: {
            value: this.currentTab,
            tabs: this.tabs.map(tab => {
              return {
                key: tab,
                title: this.getTranslate(`${serviceName}.tabs.${tab}`)
              }
            })
          },
          on: {
            input: event => {
              this.currentTab = event
            }
          },
          slot: 'header'
        }
      ),

      h(
        'g-tabs',
        {
          props: { value: this.currentTab },
          scopedSlots: {
            body: () => [
              renderDataFormatTab.call(this, h),
              renderDataSourceTab.call(this, h),
              renderBillingsTab.call(this, h),
              renderFallbacksTab.call(this, h),
              renderRoutingTab.call(this, h),
              renderReplacementTab.call(this, h),
              renderLogsTab.call(this, h)
            ]
          }
        }
      ),

      h(
        'g-card-actions',
        {
          class: 'fjcfe facfe',
          slot: 'footer'
        },
        [
          h(
            'g-button',
            {
              props: {
                label: this.getTranslate('misc.buttons.close'),
                flat: true,
                rounded: true
              },
              on: {
                click: () => {
                  this.showDialog = false
                }
              }
            }
          )
        ]
      )
    ]
  )
}

export default function(h) {
  return renderDialog.call(this, h)
}
