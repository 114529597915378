import { unVue } from '@sigma-legacy-libs/unvue'

import { SENDINGS_OUTGOING_FILTER, dateFilter } from '@/utils'

import render from './render'

export default {
  name: 'SendingsFilter',

  mixins: [ dateFilter ],

  methods: {
    getDefaultFilter() {
      return unVue(SENDINGS_OUTGOING_FILTER)
    },

    clearFilter() {
      this.proxy = unVue(this._inputFilter(this.getDefaultFilter()))
    }
  },

  render
}
