export default {
  title: 'Правила',

  one: 'Правило',
  many: 'Правила',

  titles: {
    create: 'Создать правило обработки сообщений'
  },

  labels: {
    title: 'Название',
    conditions: 'Условия',
    actions: 'Действия',
    search: 'Поиск',
    text: 'Текст',
    sender: 'Имя отправителя',
    moderation: 'Модерация',
    type: 'Тип'
  },

  dialogs: {
    new: 'Новое правило обработки сообщений'
  },

  hints: {
    notFound: 'Правила обработки сообщений не найдены',
    texts: 'Будет выбран случайным образом один из текстов указанных здесь.',
    fallbacks: 'Порядок переотправки будет соблюден.'
  },

  contents: {
    confirm: {
      remove: 'Подтвердите удаления {title}?'
    }
  },

  tooltips: {
    isActive: 'Активировать или деактивировать правило обработки сообщений.',
    favorites: 'Добавить в избранное правило обработки сообщений'
  },

  subtitles: {
    conditions: 'Условия',
    actions: 'Действия'
  },

  buttons: {
    texts: 'Тексты',
    fallbacks: 'Переотправки'
  },

  snackbars: {
    created: 'Правило обработки сообщений успешно создано.',
    updated: 'Правило обработки сообщений успешно обновлено.',
    removed: 'Правило обработки сообщений успешно удалено.'
  }
}
