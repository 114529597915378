import { serviceName } from '@/components/services/sendings/utils'

import render from './render'

const _ = { get: require('lodash/get') }

export default {
  name: 'Dialog',

  props: {
    value: null,

    show: Boolean
  },

  data() {
    return {
      showDialog: false,

      currentTab: 'format'
    }
  },

  computed: {
    tabs() {
      const result = [ 'format' ]
      if (this.checkPermissions(`advanced.${serviceName}.get`)) {
        const billings = _.get(this.value, 'meta.billings') || _.get(this.value, 'meta.billing.id')
        const provider = _.get(this.value, 'meta._routing.provider')
        const fallbacks = _.get(this.value, 'fallbacks')
        const contentHistory = _.get(this.value, 'meta.contentHistory')
        result.push('source')
        if (billings) {
          result.push('billings')
        }
        if (Array.isArray(fallbacks) && fallbacks.length) {
          result.push('fallbacks')
        }
        result.push('routing')
        if (provider) {
          result.push('logs')
        }
        if (contentHistory && contentHistory.length > 1) {
          result.push('replacement')
        }
      }

      return result
    }
  },

  watch: {
    show() {
      this.showDialog = !!this.show
    }
  },

  mounted() {
    this.showDialog = !!this.show
  },

  render
}
