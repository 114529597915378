import { isPhoneNumber } from '@sigma-legacy-libs/essentials/lib/validators'

import buttonCopy from '@/components/button/copy'

import { isObjectNotEmpty } from '@/utils'

function renderSearchField(h) {
  return h(
    'g-form',
    {
      class: 'ff faic',
      on: {
        submit: () => {
          if (this.isValid) {
            this.getHlr(this.searchValue)
          }
        },
        validation: () => {
          if (isPhoneNumber(this.searchValue).isValid) {
            this.isValid = true
          } else {
            this.isValid = false
          }
        }
      }
    },
    [
      h(
        'g-text-field',
        {
          props: {
            value: this.searchValue,
            label: this.getTranslate('misc.search'),
            mode: 'solo',
            type: 'text', // В Chrome браузере тип number обрезает +, а для isPhoneNumber необходим +
            flat: true,
            details: false,
            loading: this.loading
          },
          on: {
            input: event => {
              this.searchValue = event
            }
          }
        }
      ),

      h(
        'g-button',
        {
          props: {
            icon: 'search',
            rounded: true,
            flat: true,
            depressed: true,
            color: 'primary',
            disabled: !this.isValid,
            type: 'submit'
          },
          directives: [
            {
              name: 'g-tooltip',
              options: {
                value: this.getTranslate('misc.search')
              }
            }
          ]
        }
      )
    ]
  )
}

function renderResultElement(h, value) {
  if (value) {
    return h(
      'div',
      {
        class: {
          'faic small': true,
          'pr-2': this.viewport.breakpoint.smDown
        }
      },
      [
        h('div', { class: 'text--grey mr-1' }, value),

        h(
          'div',
          {
            class: 'text-overflow',
            directives: [
              {
                name: 'g-tooltip',
                options: {
                  value: this.searchResult[value]
                }
              }
            ]
          },
          this.searchResult[value]
        )
      ]
    )
  }
}

function renderSearchResult(h) {
  const searchResultColumns = [
    {
      fields: [ 'mcc', 'mnc', 'type' ],
      width: '60px'
    },
    {
      fields: [ 'operator', 'group', 'code' ],
      width: '180px'
    }
  ]

  if (this.searchResult && isObjectNotEmpty(this.searchResult)) {
    const stringToCopy = Object.keys(this.searchResult).map(key => `${key}: ${this.searchResult[key]}`)
      .join('\n')

    return h(
      'div',
      {
        class: {
          faic: true,
          'fjcfe facfe': this.viewport.breakpoint.mdUp,
          'fjcsb faife': this.viewport.breakpoint.smDown
        }
      },
      [
        h(
          'div',
          {
            class: {
              faic: this.viewport.breakpoint.mdUp,
              'pa-2 fc': this.viewport.breakpoint.smDown
            }
          },
          searchResultColumns.map(column => {
            return h(
              'div',
              {
                class: 'fc text-overflow',
                style: {
                  'min-width': this.viewport.breakpoint.mdUp && column.width,
                  'max-width': this.viewport.breakpoint.mdUp && column.width,
                  width: this.viewport.breakpoint.mdUp && column.width
                }
              },
              column.fields.map(field => {
                return renderResultElement.call(this, h, field)
              })
            )
          })
        ),

        h(
          buttonCopy,
          {
            props: {
              value: stringToCopy,
              flat: true
            }
          }
        ),

        h(
          'g-button',
          {
            props: {
              icon: 'clear',
              flat: true
            },
            on: {
              click: () => {
                this.cleanData()
              }
            },
            directives: [
              {
                name: 'g-tooltip',
                options: {
                  value: this.getTranslate('hlr.tooltips.clearSearchResult')
                }
              }
            ]
          }
        )
      ]
    )
  }
}

export default function(h) {
  return h(
    'g-card',
    {
      props: {
        minHeight: this.viewport.breakpoint.mdUp ? 64 : undefined,
        maxHeight: this.viewport.breakpoint.mdUp ? 64 : undefined,
        rounded: true
      }
    },
    [
      h(
        'div',
        {
          class: {
            'faic ff': this.viewport.breakpoint.mdUp,
            'fc fjcc facc': this.viewport.breakpoint.smDown
          }
        },
        [
          renderSearchField.call(this, h),
          renderSearchResult.call(this, h)
        ]
      )
    ]
  )
}
