import render from './render'

export default {
  name: 'YooMoney',

  data() {
    return {
      loading: false,

      sum: 1000,
      paymentType: 'PC'
    }
  },

  computed: {
    validation() {
      if (!this.sum) {
        return false
      }

      return true
    },

    payload() {
      return {
        receiver: '410011603821523', // https://sigmasms.slack.com/archives/DQV41N7HB/p1656062571162399
        'quickpay-form': 'shop',
        targets: [ window.location.origin, '#', 'admin', 'users', this.account.id ].join('/'),
        paymentType: this.paymentType,
        sum: this.sum,
        successURL: window.location.origin,
        formcomment: `Пополнение счёта кабинета "${this.account.username}".`,
        'short-dest': `Пополнение счёта кабинета "${this.account.username}".`,
        label: this.account.id,
        'need-email': true,
        'need-phone': true
      }
    }
  },

  render
}
