import messageTypeIcon from '@/components/message/type/icon'
import RowWithMessageTypeIcon from '@/components/misc/RowWithMessageTypeIcon'

export default function(h) {
  return h(
    'div',
    {
      class: 'grid faic',
      style: { 'grid-template-columns': '24px 1fr 36px' }
    },
    [
      h(
        'div',
        {
          class: 'fjcc faic square--24'
        },
        [
          h(
            messageTypeIcon,
            {
              props: {
                value: this.proxy.type
              }
            }
          )
        ]
      ),

      h(
        'g-select',
        {
          props: {
            value: this.proxy.type,
            label: this.getTranslate(`${this.serviceName}.labels.type`),
            items: this.getSendingTypesByPermission(`advanced.${this.serviceName}.update`).map(item => ({
              title: this.getTranslate(item.title),
              value: item.value
            })),
            mode: 'outline',
            dense: true,
            rounded: true,
            details: false,
            required: true
          },
          scopedSlots: {
            item: ({ item }) => {
              return h(
                RowWithMessageTypeIcon,
                {
                  props: {
                    value: item.value,
                    title: item.title
                  }
                }
              )
            }
          },
          on: {
            input: event => {
              this.proxy.type = event
            }
          }
        }
      )
    ]
  )
}
