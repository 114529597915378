export const RENDER_SETTINGS_SITE_SCHEMA = [
  {
    key: 'resellers',
    icon: 'groups',
    children: [
      { subtitle: 'resellers.domains' },
      'resellers.domains'
    ]
  },

  {
    key: 'auth',
    icon: 'vpn_key',
    children: [
      { subtitle: 'auth.registration.defaults' },
      'auth.registration.defaults'
    ]
  },

  {
    key: 'frontend',
    icon: 'color_lens',
    children: [
      'frontend.title',
      { subtitle: 'frontend.colors' },
      'frontend.colors',
      { subtitle: 'frontend.documents' },
      'frontend.documents.privacyPolicy',
      'frontend.documents.publicOffer',
      { subtitle: 'frontend.jivosite' },
      'frontend.jivosite'
    ]
  },

  {
    key: 'notifications',
    icon: 'notifications',
    children: [
      { subtitle: 'notifications.enabled' },
      'notifications.enabled',
      { subtitle: 'notifications.email' },
      'notifications.email.smtp',
      'notifications.email.templates',
      'notifications.email.templates.manager',
      { subtitle: 'notifications.sms' },
      'notifications.sms.senderUserId',
      'notifications.sms.sendername',
      'notifications.sms.templates',
      { subtitle: 'notifications.events' },
      'notifications.events.billings.limit',
      'notifications.events.providers.health.recipients',
      { subtitle: 'notifications.extras' },
      'notifications.extraRecipients'
    ]
  },

  {
    key: 'payments',
    icon: 'payments',
    children: [ 'payments.methods' ]
  },

  {
    key: 'globalBlacklistManager',
    icon: '',
    children: [
      'globalBlacklistManager.mainListId',
      'globalBlacklistManager.temporaryAvailableListId'
    ]
  },

  {
    key: 'apisender',
    icon: '',
    children: [ 'apisender.userApiKey' ]
  },

  {
    key: 'operators',
    icon: '',
    children: [ 'operators.prices' ]
  },

  {
    key: 'integrations',
    icon: 'extension',
    children: [
      { subtitle: 'integrations.yclients.applicationCredentials' },
      'integrations.yclients.applicationCredentials'
    ]
  },

  {
    key: 'misc',
    icon: 'settings',
    children: [ 'misc.rootId' ]
  }
]

export const RENDER_SETTINGS_SITE_SCHEMA_RESELLER = [
  {
    key: 'auth',
    icon: 'vpn_key',
    children: [ 'auth.registration.defaults' ]
  },
  {
    key: 'frontend',
    icon: 'color_lens',
    children: [
      'frontend.title',
      { subtitle: 'frontend.colors' },
      'frontend.colors',
      { subtitle: 'frontend.documents' },
      'frontend.documents.privacyPolicy',
      'frontend.documents.publicOffer',
      { subtitle: 'frontend.jivosite' },
      'frontend.jivosite'
    ]
  },
  {
    key: 'notifications',
    icon: 'notifications',
    children: [
      { subtitle: 'notifications.enabled' },
      'notifications.enabled',
      { subtitle: 'notifications.email' },
      'notifications.email.smtp',
      'notifications.email.templates',
      'notifications.email.templates.manager',
      { subtitle: 'notifications.sms' },
      'notifications.sms.senderUserId',
      'notifications.sms.sendername',
      'notifications.sms.templates',
      { subtitle: 'notifications.events' },
      'notifications.events.billings.limit'
    ]
  },
  {
    key: 'payments',
    icon: 'payments',
    children: [ 'payments.methods', 'payments.allowedGlobalMethods' ]
  }
]

export default {
  RENDER_SETTINGS_SITE_SCHEMA,
  RENDER_SETTINGS_SITE_SCHEMA_RESELLER
}
