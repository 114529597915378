const _ = { get: require('lodash/get') }

export default function(h) {
  if (process.env.NODE_ENV === 'production') {
    const jivosite = _.get(this.globalSettings, 'frontend.jivosite')

    return h(
      'div',
      {},
      [
        h(
          'script',
          {
            attrs: {
              type: 'text/javascript',
              async: true,
              src: '//code.jivosite.com/widget.js',
              'data-jv-id': jivosite
            },
            on: {
              load: async () => {
                while (!window.jivo_api) {
                  await new Promise(resolve => setTimeout(resolve, 100))
                }

                window.jivo_api.setContactInfo({
                  name: this.account.data.firstName,
                  email: this.account.data.email,
                  phone: this.account.data.phone
                })
                window.jivo_api.setCustomData([
                  {
                    title: this.getTranslate('users.labels.username'),
                    content: this.account.username,
                    link: window.location.origin + '/#/admin/users/' + this.account.id
                  }
                ])
              }
            }
          }
        )
      ]
    )
  }
}
