import Vue from 'vue'

import ExpandArrow from '@/components/misc/ExpandArrow'
import HeadTitle from '@/components/misc/HeadTitle'

import { serviceName } from '@/components/services/permissions/utils'

const colors = {
  false: 'error',
  me: 'warning',
  manager: 'info',
  reseller: 'primary',
  true: 'success'
}
const icons = {
  false: 'cancel',
  me: 'person',
  manager: 'person_add_alt_1',
  reseller: 'groups',
  true: 'verified'
}
const permissionsOptions = [ false, 'me', 'manager', 'reseller', true ]

function renderInfoRow(h, { icon, color, permission, trigger = true }) {
  if (trigger) {
    return h(
      'div',
      {
        class: 'faifs mb-3'
      },
      [
        h(
          'div',
          {
            class: 'fjcc facc faic square--48'
          },
          [
            h(
              'g-icon',
              {
                props: {
                  value: icon,
                  color
                }
              }
            )
          ]
        ),

        h(
          'div',
          {
            class: 'fc ff',
            style: { 'padding-top': '12px' }
          },
          [
            h('div', { class: `subtitle-1 text--${color}` }, permission),
            h('div', { class: 'caption text--grey' }, this.getTranslate(`${serviceName}.hints.${permission}`))
          ]
        )
      ]
    )
  }
}

function renderInfoDialog(h) {
  return h(
    'g-dialog',
    {
      props: {
        rounded: true,
        value: this.showInfoDialog,
        maxWidth: 500
      },
      on: {
        input: event => {
          this.showInfoDialog = event
        }
      }
    },
    [
      h(
        HeadTitle,
        {
          props: { value: this.getTranslate(`${serviceName}.dialogs.titles.info`) },
          slot: 'header'
        }
      ),

      h(
        'div',
        {
          class: 'fc ff py-3 pl-3 pr-5'
        },
        [
          renderInfoRow.call(this, h, {
            icon: 'cancel',
            color: 'error',
            permission: 'false'
          }),
          renderInfoRow.call(this, h, {
            icon: 'person',
            color: 'warning',
            permission: 'me'
          }),
          renderInfoRow.call(this, h, {
            icon: 'groups',
            color: 'primary',
            permission: 'reseller'
          }),
          renderInfoRow.call(this, h, {
            icon: 'verified',
            color: 'success',
            permission: 'true',
            trigger: this.checkPermissions('advanced.settingsUser.set')
          })
        ]
      ),

      h(
        'g-card-actions',
        {
          class: 'fjcfe facfe',
          slot: 'footer'
        },
        [
          h(
            'g-button',
            {
              props: {
                label: this.getTranslate('misc.buttons.close'),
                flat: true,
                rounded: true
              },
              on: {
                click: () => {
                  this.showInfoDialog = false
                }
              }
            }
          )
        ]
      )
    ]
  )
}

function renderPermissionsSearch(h) {
  if (this.permissions) {
    return h(
      'div',
      {
        class: 'faic py-3 pl-3'
      },
      [
        h(
          'g-text-field',
          {
            style: { 'max-width': '40%' },
            props: {
              value: this.permissionSearch,
              label: this.getTranslate('misc.search'),
              defaultValue: '',
              mode: 'outline',
              dense: true,
              rounded: true,
              clearable: true,
              details: false
            },
            on: {
              input: event => {
                this.permissionSearch = event
              }
            }
          }
        ),

        h('div', { class: 'ff' }),

        h(
          'g-button',
          {
            class: 'my-0',
            props: {
              icon: 'info',
              flat: true,
              large: true
            },
            on: {
              click: () => {
                this.showInfoDialog = true
              }
            },
            directives: [
              {
                name: 'g-tooltip',
                options: { value: this.getTranslate(`${serviceName}.tooltips.info`) }
              }
            ]
          }
        )
      ]
    )
  }
}

function renderPermissionsTags(h, parent) {
  return h(
    'div',
    {
      class: 'fw faic'
    },
    parent.children.map(child => {
      return h(
        'div',
        {
          class: 'faic small uppercase text--grey lh-14 mr-2'
        },
        [
          this.getTranslate(`${serviceName}.labels.${child.$name}`),

          h('div', { class: 'mr-1' }, ':'),

          h(
            'div',
            {
              class: `text--${colors[this.permissions[child.$key]]}`
            },
            this.permissions[child.$key]
          )
        ]
      )
    })
  )
}

function renderPermissionsChild(h, child) {
  if (child.$multiple) {
    return child.$options.map(option => {
      return h(
        'g-checkbox',
        {
          style: { 'margin-left': '-14px' },
          props: {
            value: this.permissions[child.$key],
            label: option.$value,
            trueValue: option.$value,
            color: 'primary'
          },
          on: {
            input: event => {
              this.updatePermission(child.$key, event)
            }
          }
        }
      )
    })
  } else {
    return h(
      'tr',
      {},
      [
        h(
          'td',
          {
            class: {
              'text-nowrap py-1 pl-3 pr-3': true,
              'body-1': this.viewport.breakpoint.mdUp,
              caption: this.viewport.breakpoint.smDown
            }
          },
          this.getTranslate(`${serviceName}.labels.${child.$name}`)
        ),

        ...permissionsOptions.map(permission => {
          const optionIndex = child.$options.findIndex(option => option.$value === permission)
          const option = child.$options[optionIndex] || {}
          const isActive = this.permissions[child.$key] === option.$value
          const disabled = permission !== option.$value

          if (disabled) {
            return h('td')
          }

          return h(
            'td',
            {
              class: 'py-1 px-1'
            },
            [
              h(
                'g-chip',
                {
                  class: 'ma-0',
                  props: {
                    icon: icons[permission],
                    tiny: this.viewport.breakpoint.smDown,
                    small: this.viewport.breakpoint.mdUp,
                    rounded: true,
                    loading: this.loading.update[`${child.$key}:${permission}`],
                    color: isActive ? colors[permission] : 'grey',
                    label: `${permission}`
                  },
                  directives: [
                    {
                      name: 'g-tooltip',
                      options: { value: this.getTranslate(`${serviceName}.hints.${permission}`) }
                    }
                  ],
                  on: {
                    click: () => {
                      if (this.permissions[child.$key] !== permission) {
                        Vue.set(this.loading.update, `${child.$key}:${permission}`, true)
                      }
                      this.updatePermission(child.$key, permission)
                    }
                  }
                }
              )
            ]
          )
        }),

        h('td', { class: 'w--100' })
      ]
    )
  }
}

function renderPermissions(h) {
  if (this.permissions) {
    return h(
      'g-expansion-panels',
      {
        class: 'mb-3',
        props: { flat: true }
      },
      this.computedPermissions.map(parent => {
        return h(
          'g-expansion-panel',
          {
            scopedSlots: {
              header: ({ expanded }) => {
                return h(
                  'div',
                  {
                    class: 'default-expansion-panel-header pl-3 pr-0'
                  },
                  [
                    h(
                      'div',
                      {
                        class: 'fc ff py-2'
                      },
                      [
                        h(
                          'div',
                          {
                            class: 'body-1',
                            style: 'padding-bottom: 2px'
                          },
                          parent.title
                        ),
                        renderPermissionsTags.call(this, h, parent)
                      ]
                    ),

                    h(ExpandArrow, { props: { expanded } })
                  ]
                )
              }
            }
          },
          [
            h(
              'div',
              {
                style: { 'overflow-x': this.viewport.breakpoint.smDown ? 'scroll' : undefined }
              },
              [
                h(
                  'table',
                  {
                    class: 'permissions_table'
                  },
                  parent.children.map(child => {
                    return renderPermissionsChild.call(this, h, child)
                  })
                )
              ]
            )
          ]
        )
      })
    )
  }
}

function renderPreloader(h) {
  if (this.loading.find) {
    return h(
      'div',
      {
        class: 'fjcc facc faic ff pa-5'
      },
      [ h('g-progress', { props: { indeterminate: true } }) ]
    )
  }
}

function renderContent(h) {
  if (this.loading.find) {
    return renderPreloader.call(this, h)
  } else {
    return h(
      'div',
      {
        class: 'grid grid-cols--1 grid-gap--0'
      },
      [
        renderPermissionsSearch.call(this, h),
        renderPermissions.call(this, h)
      ]
    )
  }
}

export default function(h) {
  if (this.checkPermissions('advanced.users.setPermissions', [ 'reseller', true ])) {
    return h(
      'div',
      {
        class: 'fc ff'
      },
      [
        renderContent.call(this, h),
        renderInfoDialog.call(this, h)
      ]
    )
  }
}
