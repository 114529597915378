import Vue from 'vue'

import { downloadable, globalErrorHandler, globalErrorProcessor, tableHeaderParser } from '@/utils'

import render from './render'

const _ = { omit: require('lodash/omit') }

export default {
  name: 'SendingsGroup003',

  mixins: [ downloadable({ permissionAccept: 'advanced.reports.get' }) ],

  props: {
    filter: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      loading: false,

      report: [],
      order: {}
    }
  },

  computed: {
    headers() {
      return tableHeaderParser.call(this, [
        {
          title: 'sendings.headers.owner',
          value: 'Owner.username',
          align: 'left',
          class: 'pl-2 pr-2',
          sortable: true
        },
        {
          title: 'sendings.headers.tariff',
          value: 'Tariff.title',
          align: 'left',
          class: 'pl-2 pr-2',
          sortable: true
        },
        {
          title: 'sendings.headers.provider',
          value: 'Provider.title',
          align: 'left',
          class: 'pl-2 pr-2',
          sortable: true
        },
        {
          title: 'sendings.headers.count_delivered',
          value: 'count_delivered',
          align: 'right',
          class: 'pl-2 pr-2',
          sortable: true
        },
        {
          title: 'sendings.headers.count_seen',
          value: 'count_seen',
          align: 'right',
          class: 'pl-2 pr-2',
          sortable: true
        },
        {
          title: 'sendings.headers.count_failed',
          value: 'count_failed',
          align: 'right',
          class: 'pl-2 pr-2',
          sortable: true
        },
        {
          title: 'sendings.headers.count_sent',
          value: 'count_sent',
          align: 'right',
          class: 'pl-2 pr-2',
          sortable: true
        },
        {
          title: 'sendings.headers.count_total',
          value: 'count_total',
          align: 'right',
          class: 'pl-0 pr-5',
          sortable: true
        },
        {
          title: 'sendings.headers.billing_tariff_price',
          value: 'billing_tariff_price',
          align: 'right',
          class: 'pl-2 pr-2',
          sortable: true
        },
        {
          title: 'sendings.headers.billing_sum_pending_complete',
          value: 'billing_sum_pending_complete',
          align: 'right',
          class: 'pl-2 pr-2',
          sortable: true
        },
        {
          title: 'sendings.headers.billing_sum_refunded_invalid',
          value: 'billing_sum_refunded_invalid',
          align: 'right',
          class: 'pl-2 pr-2',
          sortable: true
        },
        {
          title: 'sendings.headers.billing_sum_all',
          value: 'billing_sum_all',
          align: 'right',
          class: 'pl-2 pr-2',
          sortable: true
        }
      ])
    },

    total() {
      let countSumMultiplierCompleted = 0
      let countSumCompleted = 0
      let countSumMultiplierRefunded = 0
      let countSumRefunded = 0
      let countSumMultiplier = 0
      let countSum = 0
      let countDelivered = 0
      let countFailed = 0
      let countSeen = 0
      let countSent = 0
      let countTotal = 0

      this.report.forEach(item => {
        countSumMultiplierCompleted += item.billing_multiplier_sum_pending_complete
        countSumCompleted += item.billing_sum_pending_complete
        countSumMultiplierRefunded += item.billing_multiplier_sum_refunded_invalid
        countSumRefunded += item.billing_sum_refunded_invalid
        countSumMultiplier += item.billing_multiplier_sum_all
        countSum += item.billing_sum_all
        countDelivered += item.message_parts_delivered
        countFailed += item.message_parts_failed
        countSeen += item.message_parts_seen
        countSent += item.message_parts_sent
        countTotal += item.message_parts_all
      })

      return {
        countSumMultiplierCompleted,
        countSumCompleted,
        countSumMultiplierRefunded,
        countSumRefunded,
        countSumMultiplier,
        countSum,
        countDelivered,
        countFailed,
        countSeen,
        countSent,
        countTotal
      }
    }
  },

  methods: {
    async getReport() {
      try {
        this.loading = true
        const { data } = await Vue.$GRequest.find(
          'reports',
          {
            query: Object.assign({}, _.omit(this.filter, [ '$scope' ]), { $preset: 'sendings.group003' })
          }
        )
        if (data) {
          this.report = data
        }
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      } finally {
        this.loading = false
      }
    }
  },

  render
}
