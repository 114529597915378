import messageTypeIcon from '@/components/message/type/icon'

function renderIcon(h, ctx) {
  return h(
    messageTypeIcon,
    {
      props: {
        value: ctx.props.value,
        size: ctx.props.as === 'chip' ? ctx.props.size - 4 : ctx.props.size
      }
    }
  )
}
function renderTitle(h, ctx) {
  return h(
    'div',
    {
      class: ctx.props.as === 'chip' ? 'caption' : 'text-overflow'
    },
    ctx.props.title
  )
}
function renderRemove(h, ctx) {
  const remove = ctx.props.remove
  if (typeof remove === 'function') {
    const size = ctx.props.size - 4

    return h(
      'div',
      {
        class: `cursor-pointer square--${size}`
      },
      [
        h(
          'g-icon',
          {
            props: {
              value: 'clear',
              color: 'grey',
              size
            },
            on: {
              click: () => {
                remove(ctx.props.value)
              }
            }
          }
        )
      ]
    )
  }
}

export default function(h, ctx) {
  let size = ctx.props.size
  const classes = [ 'grid', 'faic' ]
  const style = {}
  switch (ctx.props.as) {
    case 'chip': {
      size = size - 4
      classes.push('grid-gap--4', 'mr-2', 'my-1', 'px-2', 'py-1', 'rounded', `lh--${size}`)
      style.background = '#e0e0e0'
      break
    }
  }
  style['grid-template-columns'] = `${size}px 1fr ${size}px`

  return h(
    'div',
    {
      class: classes,
      style
    },
    [
      renderIcon.call(this, h, ctx),
      renderTitle.call(this, h, ctx),
      renderRemove.call(this, h, ctx)
    ]
  )
}
