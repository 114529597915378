import Vue from 'vue'

import buttonFavorite from '@/components/button/favorite'
import messageRulesItemTitle from '@/components/services/messageRules/item/title'

import { serviceName } from '@/components/services/messageRules/utils'

function renderTitle(h) {
  return h(
    'td',
    {
      class: 'pl-2 w--100'
    },
    [
      h(
        messageRulesItemTitle,
        {
          props: {
            value: this.value,
            edit: false
          }
        }
      )
    ]
  )
}

function renderEditButton(h) {
  return h(
    'td',
    {
      class: 'px-0'
    },
    [
      h(
        'div',
        {
          class: {
            'fjcfe faic': true,
            'hover-child': this.viewport.breakpoint.mdUp
          }
        },
        [
          h(
            buttonFavorite,
            {
              props: {
                id: this.value.id,
                serviceName
              }
            }
          ),

          h(
            'g-button',
            {
              props: {
                flat: true,
                icon: 'edit'
              },
              on: {
                click: () => {
                  Vue.router.push({
                    name: `${serviceName}.single`,
                    params: { id: this.value.id }
                  })
                }
              }
            }
          )
        ]
      )
    ]
  )
}

export default function(h) {
  return h(
    'tr',
    {
      class: 'hover-parent hover-parent--opacity'
    },
    [
      renderTitle.call(this, h),
      renderEditButton.call(this, h)
    ]
  )
}
