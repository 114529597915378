import Vue from 'vue'
import Prism from 'prismjs'
import numeral from 'numeral'

import 'prismjs/components/prism-json.js'

import { getLocaleDateString, getLocaleTimeString } from '@/utils'

import HeadTitle from '@/components/misc/HeadTitle'
import SingleLink from '@/components/misc/SingleLink'
import FileType from '@/components/file/type'

const name = 'storage'

function renderCancelButton(h, state) {
  return h(
    'g-button',
    {
      props: {
        flat: true,
        rounded: true,
        disabled: state
      },
      on: {
        click: () => {
          this.hideDialog()
        }
      }
    },
    this.getTranslate('misc.buttons.cancel')
  )
}

function renderRow(h, icon, path, content) {
  return h(
    'div',
    {
      class: 'faic text-nowrap caption lh-16 pl-3 pr-3'
    },
    [
      h(
        'g-icon',
        {
          class: 'mr-1',
          props: {
            value: icon,
            color: 'grey',
            size: 13
          }
        }
      ),

      h(
        'div',
        {
          class: 'text--grey mr-1'
        },
        this.getTranslate(`${name}.prefixes.${path}`)
      ),

      content
    ]
  )
}

function renderOwner(h) {
  if (this.checkPermissions('advanced.storage.get', [ 'reseller', true ])) {
    return renderRow.call(this, h, 'person', 'owner', h(
      SingleLink,
      {
        class: 'ml-1',
        props: { value: this.proxy.Owner },
        on: {
          click: event => {
            event.stopPropagation()
          }
        }
      }
    ))
  }
}

function renderExpiredAt(h) {
  if (this.checkPermissions(`advanced.${name}.update`)) {
    return h(
      'g-menu',
      {
        class: 'ml-1',
        props: {
          value: this.showEditExpireMenu,
          closeOnContentClick: false
        },
        on: {
          input: event => {
            this.showEditExpireMenu = event
          }
        }
      },
      [
        h(
          'div',
          {
            class: 'faic',
            slot: 'activator'
          },
          [
            h('div', { class: 'link link--dashed' }, getLocaleDateString(this.proxy.expiredAt)),
            h('div', { class: 'text--grey pl-1 pr-1' }, this.getTranslate('misc.in')),

            getLocaleTimeString(this.proxy.expiredAt)
          ]
        ),

        h(
          'g-date-picker',
          {
            props: {
              value: this.proxy.expiredAt,
              localeTag: this.locale,
              min: new Date(Date.now() + 2 * 24 * 60 * 60 * 1000),
              max: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000),
              range: false
            },
            on: {
              input: async event => {
                this.proxy.expiredAt = event
                this.showEditExpireMenu = false
              }
            }
          }
        )
      ]
    )
  } else {
    return h(
      'div',
      {
        class: 'ml-1'
      },
      getLocaleDateString(this.proxy.expiredAt)
    )
  }
}

function renderLocks(h) {
  if (this.proxy.locks.length) {
    return h(
      'div',
      {
        class: 'fc'
      },
      [
        h(
          'div',
          {
            class: 'faic caption text--grey pt-3 pb-2 pl-3 pr-3'
          },
          [
            h(
              'g-icon',
              {
                class: 'mr-1',
                props: {
                  value: 'lock',
                  size: 13
                }
              }
            ),

            this.getTranslate(`${name}.prefixes.locks`)
          ]
        ),

        h(
          'g-list',
          {},
          this.proxy.locks.map(lock => {
            const [ service, id ] = lock.split(':')

            return h('g-list-item', {}, [
              h(
                'div',
                {
                  class: 'fc'
                },
                [
                  service === 'googleDriveApi' ? 'Google Drive API' : this.getTranslate(`${service}.title`),

                  h('div', { class: 'caption text--grey' }, id)
                ]
              ),

              h('div', { class: 'ff' }),

              h(
                'g-button',
                {
                  class: 'ma-0',
                  props: {
                    flat: true,
                    icon: 'lock_open',
                    loading: this.restData[name].update.state === 'loading',
                    disabled: this.restData[name].update.state === 'loading'
                  },
                  on: {
                    click: () => {
                      const index = this.proxy.locks.findIndex(item => item === lock)
                      this.proxy.locks.splice(index, 1)
                    }
                  }
                }
              )
            ])
          })
        )
      ]
    )
  } else {
    return renderRow.call(this, h, 'history', 'willBeDeleted', renderExpiredAt.call(this, h))
  }
}

function renderHeader(h) {
  let value = ''
  switch (this.type) {
    case 'editMeta':
      value = this.getTranslate(`${name}.titles.properties`)
      break
    case 'remove':
      value = this.getTranslate('commons.titles.confirm.remove')
      break
    case 'source':
      value = this.getTranslate('commons.titles.data')
      break
    case 'locks':
      value = this.getTranslate(`${name}.titles.locks`)
      break
  }

  return h(
    HeadTitle,
    {
      props: { value },
      slot: 'header'
    }
  )
}

function renderBody(h) {
  switch (this.type) {
    case 'editMeta': {
      return h(
        'div',
        {
          class: 'fc'
        },
        [
          h(
            'div',
            {
              class: 'faic pr-3 pb-3'
            },
            [
              h(
                FileType,
                {
                  class: 'ml-3 mr-3',
                  props: { value: this.proxy }
                }
              ),

              h(
                'g-text-field',
                {
                  props: {
                    value: this.proxy.title,
                    label: this.getTranslate(`${name}.labels.title`),
                    mode: 'outline',
                    rounded: true,
                    details: false,
                    clearable: true
                  },
                  on: {
                    input: event => {
                      this.proxy.title = event
                    },
                    keypress: event => {
                      if (event.keyCode === 13) {
                        this.updateFile()
                      }
                    }
                  }
                }
              )
            ]
          ),

          renderRow.call(this, h, 'info', 'id', this.proxy.id),
          renderRow.call(this, h, 'description', 'filename', this.proxy.filename),
          renderRow.call(this, h, 'dns', 'size', numeral(this.proxy.size).format('0b')),
          renderRow.call(this, h, 'more_horiz', 'hash', this.proxy.hash),
          renderRow.call(this, h, 'event', 'created', h(
            'span',
            {},
            [
              getLocaleDateString(this.proxy.createdAt),
              h('span', { class: 'pr-1 pl-1 text--grey' }, this.getTranslate('misc.in')),
              getLocaleTimeString(this.proxy.createdAt)
            ]
          )),
          renderRow.call(this, h, 'event', 'updated', h(
            'span',
            {},
            [
              getLocaleDateString(this.proxy.updatedAt),
              h('span', { class: 'pr-1 pl-1 text--grey' }, this.getTranslate('misc.in')),
              getLocaleTimeString(this.proxy.updatedAt)
            ]
          )),

          renderOwner.call(this, h),
          renderLocks.call(this, h)
        ]
      )
    }
    case 'export': {
      return h(
        'div',
        {
          class: 'fc pt-5 pb-5'
        },
        [
          h(
            'div',
            {
              class: 'fjcc facc ff'
            },
            [
              h('g-progress', {
                props: {
                  indeterminate: true,
                  color: 'grey'
                }
              })
            ]
          ),

          h(
            'div',
            {
              class: 'text-center mt-4'
            },
            this.getTranslate(`${name}.hints.export`)
          )
        ]
      )
    }
    case 'remove': {
      return h(
        'div',
        {
          class: 'pa-3'
        },
        this.getTranslate('commons.contents.confirm.remove')
      )
    }
    case 'edit': {
      return h(
        'iframe',
        {
          attrs: {
            src: this.googleFileURL,
            frameborder: 0
          },
          class: 'position-absolute h--100 w--100'
        }
      )
    }
    case 'source': {
      return h(
        'pre',
        {
          class: 'language-json ma-0 pa-2 w--100',
          style: { 'border-radius': 0 },
          domProps: { innerHTML: Prism.highlight(JSON.stringify(this.proxy, null, 2), Prism.languages.json, 'json') }
        }
      )
    }
  }
}

function renderFooter(h) {
  switch (this.type) {
    case 'editMeta': {
      return h(
        'g-card-actions',
        {
          class: 'fjcfe facfe',
          slot: 'footer'
        },
        [
          renderCancelButton.call(this, h, this.restData[name].update.state === 'loading'),

          h(
            'g-button',
            {
              props: {
                label: this.getTranslate('misc.buttons.update'),
                flat: true,
                rounded: true,
                loading: this.restData[name].update.state === 'loading',
                disabled: this.restData[name].update.state === 'loading',
                color: 'primary'
              },
              on: {
                click: () => {
                  this.updateFile()
                }
              }
            }
          )
        ]
      )
    }
    case 'remove': {
      return h(
        'g-card-actions',
        {
          class: 'fjcfe facfe',
          slot: 'footer'
        },
        [
          renderCancelButton.call(this, h, this.restData[name].remove.state === 'loading'),

          h(
            'g-button',
            {
              props: {
                label: this.getTranslate('misc.buttons.remove'),
                flat: true,
                rounded: true,
                loading: this.restData[name].remove.state === 'loading',
                disabled: this.restData[name].remove.state === 'loading',
                color: 'error'
              },
              on: {
                click: () => {
                  this.removeFile()
                }
              }
            }
          )
        ]
      )
    }
    case 'edit': {
      return h(
        'g-card-actions',
        {
          class: 'fjcfe facfe',
          slot: 'footer'
        },
        [
          renderCancelButton.call(this, h, this.loading),

          h(
            'g-button',
            {
              props: {
                label: this.getTranslate('misc.buttons.save'),
                rounded: true,
                depressed: true,
                loading: this.loading,
                disabled: this.loading,
                color: 'primary'
              },
              on: {
                click: () => {
                  this.loading = true
                  setTimeout(() => {
                    Vue.$GRequest
                      .remove(`/${name}/edit/`, this.proxy.id)
                      .then(() => this.hideDialog())
                      .catch(() => this.hideDialog())
                  }, 5 * 1000)
                }
              }
            }
          )
        ]
      )
    }
    case 'source': {
      return h(
        'g-card-actions',
        {
          class: 'fjcfe facfe',
          slot: 'footer'
        },
        [ renderCancelButton.call(this, h) ]
      )
    }
  }
}

export default function(h) {
  const props = {
    rounded: true,
    value: this.show,
    closeOnClick: this.type !== 'export',
    closeOnEsc: this.type !== 'export'
  }
  switch (this.type) {
    case 'editMeta':
    case 'export':
    case 'remove':
      props.maxWidth = 400
      break
    case 'edit':
      props.fullscreen = true
      break
    case 'source':
      props.maxWidth = 1000
      break
  }

  return h(
    'g-dialog',
    {
      props,
      on: {
        input: event => {
          if (this.type !== 'export') {
            this.hideDialog(event)
          }
        }
      }
    },
    [ renderHeader.call(this, h), renderBody.call(this, h), renderFooter.call(this, h) ]
  )
}
