import { Draggable } from '@/utils'

import render from './render'

export default {
  name: 'Draggable',

  props: {
    value: {
      type: Array,
      default: () => []
    },
    handle: String
  },

  data() {
    return {
      draggable: undefined
    }
  },

  watch: {
    value: {
      handler() {
        this.draggable.list = this.value
        this.$nextTick(() => this.draggable.refreshEvents())
      },
      deep: true
    },
    'draggable.list': {
      handler() {
        this.$emit('input', this.draggable.list)
      },
      deep: true
    }
  },

  mounted() {
    if (!this.draggable) {
      this.draggable = new Draggable(this.$refs.draggable, this.handle, this.value)
    }
  },

  render
}
