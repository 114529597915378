import tariffs from '@/components/services/tariffRatesUpdating/item/tariffs'
import buttons from '@/components/services/subscriptions/item/buttons'

function renderTariffs(h) {
  return h(tariffs, { props: { value: this.value } })
}

function renderTariffsCell(h) {
  return h(
    'td',
    {
      class: 'px-2'
    },
    [ renderTariffs.call(this, h) ]
  )
}

function renderButtonsCell(h) {
  return h(
    'td',
    {
      class: 'px-2 w--100'
    },
    [
      h(
        'div',
        {
          class: 'fjcfe'
        },
        [ h(buttons, { props: { value: this.value } }) ]
      )
    ]
  )
}

export default function(h) {
  return h(
    'tr',
    {
      class: 'hover-parent hover-parent--opacity'
    },
    [
      renderTariffsCell.call(this, h),
      renderButtonsCell.call(this, h)
    ]
  )
}
