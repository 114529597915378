import { isArrayNotEmpty } from '@/utils'

import HeadTitle from '@/components/misc/HeadTitle'

const _ = { get: require('lodash/get') }

function renderDialog(h) {
  return h(
    'g-dialog',
    {
      props: {
        rounded: true,
        value: this.show,
        maxWidth: 400
      },
      on: {
        input: event => {
          this.show = event
        }
      }
    },
    [
      h(
        HeadTitle, {
          props: { value: this.getTranslate('requisites.titles.requisites') },
          slot: 'header'
        }
      ),

      h(
        'div',
        {
          class: 'fc pa-3'
        },
        this.requisites.map(requisite => {
          return h(
            'div',
            {
              class: 'fc mb-3',
              style: { 'border-bottom': '1px dashed rgba(0, 0, 0, 0.24)' }
            },
            [
              h(
                'div',
                {
                  class: 'title text-overflow'
                },
                requisite.title
              ),

              h(
                'div',
                {
                  class: 'fr body-1'
                },
                [
                  h(
                    'span',
                    {
                      class: 'text--grey pr-1'
                    },
                    `${this.getTranslate('requisites.labels.data.personal.firstName')}:`
                  ),
                  [
                    _.get(requisite, 'data.personal.firstName'),
                    _.get(requisite, 'data.personal.middleName'),
                    _.get(requisite, 'data.personal.lastName')
                  ]
                    .filter(item => !!item)
                    .join(' ')
                ]
              ),

              h(
                'div',
                {
                  class: 'fr body-1 text-overflow'
                },
                [
                  h(
                    'span',
                    {
                      class: 'text--grey pr-1'
                    },
                    `${this.getTranslate('requisites.labels.data.legal.name')}:`
                  ),
                  _.get(requisite, 'data.legal.name')
                ]
              ),

              h(
                'div',
                {
                  class: 'fr body-1'
                },
                [
                  h(
                    'span',
                    {
                      class: 'text--grey pr-1'
                    },
                    `${this.getTranslate('requisites.labels.data.legal.inn')}:`
                  ),
                  _.get(requisite, 'data.legal.inn')
                ]
              )
            ]
          )
        })
      ),

      h(
        'g-card-actions',
        {
          class: 'fjcfe facfe',
          slot: 'footer'
        },
        [
          h(
            'g-button',
            {
              props: {
                label: this.getTranslate('misc.buttons.close'),
                flat: true,
                rounded: true
              },
              on: {
                click: () => {
                  this.show = false
                }
              }
            }
          )
        ]
      )
    ]
  )
}

function renderContent(h) {
  if (this.checkPermissions('advanced.requisites.get', [ 'reseller', true ])) {
    if (isArrayNotEmpty(this.requisites)) {
      return h(
        'g-button',
        {
          props: {
            flat: true,
            rounded: true,
            outline: true,
            icon: 'chrome_reader_mode',
            label: this.title ? this.getTranslate('requisites.titles.requisites') : undefined
          },
          on: {
            click: () => {
              this.show = true
            }
          },
          scopedSlots: {
            dialog: () => renderDialog.call(this, h)
          }
        }
      )
    }
  }
}

export default function(h) {
  return renderContent.call(this, h)
}
