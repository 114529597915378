import proxy from '@sigma-legacy-libs/g-proxy'

import render from './render'

export default function(options) {
  return {
    name: 'SMPPIPWhitelist',

    mixins: [ proxy({ type: 'array' }) ],

    methods: {
      _inputFilter(value) {
        return value.join('\n')
      },

      watchValueHandler() {
        return
      },
      watchProxyHandler() {
        return
      }
    },

    render(h) {
      return render.call(this, h, options)
    }
  }
}
