import { TASK_FREQUENCY } from '@sigma-legacy-libs/essentials/lib/constants'

import { BILLINGS_SOURCES, getISODate, getYesterday } from '@/utils'

import Columns from '@/components/misc/Columns'

function renderEndsAt(h) {
  if (this.proxy.data.frequency !== 'once') {
    return h(
      'g-menu',
      {
        class: 'w--100',
        props: {
          closeOnContentClick: false,
          disabled: !this.proxy.startsAt,
          maxWidth: 282,
          rounded: true,
          placement: 'right'
        }
      },
      [
        h(
          'g-text-field', {
            props: {
              value: getISODate(this.proxy.data.endsAt),
              label: this.getTranslate('tasks.labels.data.endsAt'),
              afterIcon: 'event',
              defaultValue: null,
              readonly: true,
              disabled: !this.proxy.startsAt,
              mode: 'outline-label',
              rounded: true,
              details: false
            },
            slot: 'activator'
          }
        ),

        h(
          'div',
          {
            class: 'fc ff'
          },
          [
            h(
              'g-date-picker',
              {
                props: {
                  value: this.proxy.data.endsAt,
                  localeTag: this.locale,
                  range: false,
                  min: this.proxy.startsAt
                },
                on: {
                  input: event => {
                    this.proxy.data.endsAt = event
                  }
                }
              }
            ),

            h(
              'g-card-actions',
              {
                class: 'fjcfe facfe'
              },
              [
                h(
                  'g-button',
                  {
                    props: {
                      label: this.getTranslate('misc.buttons.clear'),
                      flat: true,
                      rounded: true
                    },
                    on: {
                      click: () => {
                        this.proxy.data.endsAt = undefined
                      }
                    }
                  }
                )
              ]
            )
          ]
        )
      ]
    )
  }
}

export default function(h) {
  return h(
    'g-form',
    {
      class: 'grid grid-cols--1 pa-3',
      on: {
        submit: () => {
          this.$emit('submit', this.proxy)
        },
        validation: event => {
          this.$emit('validation', event)
        }
      }
    },
    [
      h(
        Columns,
        {},
        [
          h(
            'g-text-field',
            {
              props: {
                value: this.proxy.title,
                label: this.getTranslate('tasks.labels.title'),
                required: true,
                autofocus: true,
                clearable: true,
                mode: 'outline',
                rounded: true,
                details: false
              },
              on: {
                input: event => {
                  this.proxy.title = event
                }
              }
            }
          ),

          h(
            'g-select',
            {
              props: {
                value: this.proxy.OwnerId,
                label: this.getTranslate('tasks.labels.owner'),
                itemsDisabled: [ this.account.id ],
                service: 'users',
                query: {
                  isActive: true
                },
                itemTitle: 'username',
                autocomplete: true,
                required: true,
                mode: 'outline',
                rounded: true,
                details: false
              },
              on: {
                input: event => {
                  this.proxy.OwnerId = event
                }
              }
            }
          )
        ]
      ),

      h(
        Columns,
        {},
        [
          h('g-select', {
            props: {
              value: this.proxy.data.source,
              label: this.getTranslate('tasks.labels.data.source'),
              items: BILLINGS_SOURCES.map(source => {
                return {
                  title: this.getTranslate(`billings.sources.${source}`),
                  value: source
                }
              }),
              required: true,
              mode: 'outline',
              rounded: true,
              details: false
            },
            on: {
              input: event => {
                this.proxy.data.source = event
              }
            }
          }),

          h(
            'g-text-field',
            {
              props: {
                value: this.proxy.data.amount,
                label: this.getTranslate('tasks.labels.data.amount'),
                type: 'number',
                step: 'any',
                min: 0,
                required: true,
                clearable: true,
                mode: 'outline',
                rounded: true,
                details: false
              },
              on: {
                input: event => {
                  this.proxy.data.amount = event
                }
              }
            }
          )
        ]
      ),

      h(
        Columns,
        {},
        [
          h(
            'g-select',
            {
              props: {
                value: this.proxy.data.frequency,
                label: this.getTranslate('tasks.labels.data.frequency'),
                items: TASK_FREQUENCY.reduce((result, frequency) => {
                  if (frequency !== TASK_FREQUENCY.weekly) {
                    result.push({
                      title: this.getTranslate(`tasks.frequencies.${frequency}`),
                      value: frequency
                    })
                  }

                  return result
                }, []),
                required: true,
                mode: 'outline-label',
                rounded: true,
                details: false
              },
              on: {
                input: event => {
                  this.proxy.data.frequency = event
                }
              }
            }
          ),

          h(
            'g-menu',
            {
              class: 'w--100',
              props: {
                closeOnContentClick: false,
                maxWidth: 282,
                rounded: true,
                placement: 'left'
              }
            },
            [
              h(
                'g-text-field',
                {
                  props: {
                    value: getISODate(this.proxy.startsAt),
                    label: this.getTranslate('tasks.labels.startsAt'),
                    afterIcon: 'event',
                    defaultValue: null,
                    readonly: true,
                    mode: 'outline-label',
                    rounded: true,
                    details: false
                  },
                  slot: 'activator'
                }
              ),

              h(
                'div',
                {
                  class: 'fc ff'
                },
                [
                  h(
                    'g-date-picker',
                    {
                      props: {
                        value: this.proxy.startsAt,
                        localeTag: this.locale,
                        range: false,
                        min: getYesterday()
                      },
                      on: {
                        input: event => {
                          this.proxy.startsAt = event
                        }
                      }
                    }
                  ),

                  h(
                    'g-card-actions',
                    {
                      class: 'fjcfe facfe'
                    },
                    [
                      h(
                        'g-button',
                        {
                          props: {
                            label: this.getTranslate('misc.buttons.clear'),
                            flat: true,
                            rounded: true
                          },
                          on: {
                            click: () => {
                              this.proxy.startsAt = undefined
                            }
                          }
                        }
                      )
                    ]
                  )
                ]
              )
            ]
          ),

          renderEndsAt.call(this, h)
        ]
      )
    ]
  )
}
