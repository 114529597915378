import { REPORTS_ENCODINGS } from '@sigma-legacy-libs/essentials/lib/constants'

import Columns from '@/components/misc/Columns'
import HeadTitle from '@/components/misc/HeadTitle'

const delimiters = [
  {
    title: 'misc.delimiters.comma',
    value: ','
  },
  {
    title: 'misc.delimiters.semicolon',
    value: ';'
  }
]

function renderForm(h) {
  if (this.downloadable) {
    return h(
      'div',
      {
        class: 'pa-3 text--grey'
      },
      this.getTranslate('sendings.hints.remoteReport')
    )
  } else {
    return h(
      Columns,
      {
        class: 'pa-3'
      },
      [
        h(
          'g-select',
          {
            props: {
              value: this.encoding,
              hint: this.getTranslate('reports.labels.encoding'),
              items: REPORTS_ENCODINGS.map(encoding => ({
                title: this.getTranslate(`misc.encodings.${encoding}`),
                value: encoding
              })),
              disabled: this.loading,
              mode: 'outline',
              dense: true,
              rounded: true
            },
            on: {
              input: event => {
                this.encoding = event
              }
            }
          }
        ),

        h(
          'g-select',
          {
            props: {
              value: this.delimiter,
              hint: this.getTranslate('reports.labels.delimiter'),
              items: delimiters.map(({ title, value }) => ({
                title: this.getTranslate(title),
                value
              })),
              disabled: this.loading,
              mode: 'outline',
              dense: true,
              rounded: true
            },
            on: {
              input: event => {
                this.delimiter = event
              }
            }
          }
        )
      ]
    )
  }
}

function renderFooter(h) {
  if (this.downloadable) {
    return h(
      'g-card-actions',
      {
        class: 'flex-end',
        slot: 'footer'
      },
      [
        h(
          'g-button',
          {
            props: {
              label: this.getTranslate('misc.buttons.close'),
              flat: true,
              rounded: true,
              disabled: this.loading
            },
            on: {
              click: () => {
                this.proxy = false
              }
            }
          }
        )
      ]
    )
  } else {
    return h(
      'g-card-actions',
      {
        class: 'flex-end',
        slot: 'footer'
      },
      [
        h(
          'g-button',
          {
            props: {
              label: this.getTranslate('misc.buttons.cancel'),
              flat: true,
              rounded: true,
              disabled: this.loading
            },
            on: {
              click: () => {
                this.proxy = false
              }
            }
          }
        ),

        h(
          'g-button',
          {
            props: {
              label: this.getTranslate('misc.buttons.download'),
              rounded: true,
              depressed: true,
              loading: this.loading,
              disabled: this.loading,
              color: 'primary'
            },
            on: {
              click: () => {
                this.getReport()
              }
            }
          }
        )
      ]
    )
  }
}

function renderDialog(h) {
  return h(
    'g-dialog',
    {
      props: {
        value: this.proxy,
        rounded: true,
        maxWidth: 400
      },
      on: {
        input: event => {
          this.proxy = event
        }
      }
    },
    [
      h(
        HeadTitle,
        {
          props: { value: this.getTranslate('reports.title') },
          slot: 'header'
        }
      ),

      renderForm.call(this, h),
      renderFooter.call(this, h)
    ]
  )
}

function renderContent(h) {
  return h(
    'g-button',
    {
      props: {
        flat: true,
        icon: 'cloud_download',
        label: !this.icon && this.viewport.breakpoint.mdUp ? this.getTranslate('misc.buttons.get.report') : undefined,
        rounded: !this.icon && this.viewport.breakpoint.mdUp
      },
      on: {
        click: () => {
          this.proxy = true
          if (this.downloadable) {
            this.getRemoteReport()
          }
        }
      },
      scopedSlots: {
        dialog: () => renderDialog.call(this, h)
      }
    }
  )
}

export default function(h) {
  if (this.checkPermissions('advanced.reports.generate', [ 'me', 'reseller', true ])) {
    return renderContent.call(this, h)
  }
}
