import { unVue } from '@sigma-legacy-libs/unvue'
import { EMPTY_REQUISITES, EMPTY_USERS } from '@sigma-legacy-libs/essentials/lib/constants'

import { generateServices, tableHeaderParser } from '@/utils'

import { serviceName } from '@/components/services/requisites/utils'

import render from './render'

const _ = { defaultsDeep: require('lodash/defaultsDeep') }

export default {
  name: 'UsersRequisites',

  mixins: [
    generateServices({
      name: serviceName,

      inputFilter(result) {
        _.defaultsDeep(result, unVue(EMPTY_REQUISITES)) // потому что с сервера приходит урезанный payload

        return result
      },

      find: {
        defaultFilter: {
          OwnerId: undefined
        }
      },

      get: false,
      create: false,
      update: false,
      remove: false
    })
  ],

  props: {
    OwnerId: String,
    User: {
      type: Object,
      default: () => unVue(EMPTY_USERS)
    }
  },

  data() {
    return {
      requisiteId: undefined,
      dialogType: undefined,

      showDialog: false
    }
  },

  computed: {
    headers() {
      return tableHeaderParser.call(this, [
        {
          value: 'isActive'
        },
        {
          value: 'type'
        },
        {
          title: `${serviceName}.headers.title`,
          value: 'title',
          align: 'left',
          class: 'pl-2 pr-2'
        },
        {
          title: `${serviceName}.headers.businessInfo`,
          value: 'businessInfo',
          align: 'left',
          class: 'pl-2 pr-2'
        },
        {
          title: `${serviceName}.headers.contactInfo`,
          value: 'contactInfo',
          align: 'left',
          class: 'pl-2 pr-2'
        },
        {
          value: 'actions'
        }
      ])
    }
  },

  mounted() {
    this.restData[serviceName].find.filter.OwnerId = this.OwnerId
  },

  render
}
