import { PAYMENTS_STATUSES } from '@sigma-legacy-libs/essentials/lib/constants'

export const serviceName = 'payments'

export const icons = {
  [PAYMENTS_STATUSES.init]: 'schedule',
  [PAYMENTS_STATUSES.completed]: 'check_circle_outline',
  [PAYMENTS_STATUSES.refundRequested]: 'replay',
  [PAYMENTS_STATUSES.refunded]: 'reply_all',
  [PAYMENTS_STATUSES.partialRefunded]: 'reply',
  [PAYMENTS_STATUSES.error]: 'error_outline'
}

export const colors = {
  [PAYMENTS_STATUSES.init]: 'grey',
  [PAYMENTS_STATUSES.completed]: 'success',
  [PAYMENTS_STATUSES.refundRequested]: 'info',
  [PAYMENTS_STATUSES.refunded]: 'info',
  [PAYMENTS_STATUSES.partialRefunded]: 'info',
  [PAYMENTS_STATUSES.error]: 'error'
}

export default {
  icons,
  colors,

  serviceName
}
