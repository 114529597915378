import { getLocaleDateString, getRUB } from '@/utils'

import copyIcon from '@/components/copy/icon'
import RefreshButton from '@/components/button/refresh'
import ReferralDateFilter from '@/components/misc/ReferralPayments/filter'
import pagination from '@/components/misc/pagination'

const name = 'billings'

function renderPagination(h) {
  if (this.restData[name].find.pagination.total) {
    return h(
      'div',
      {
        class: 'grid grid-gap--8 fjcfe faic pa-2',
        style: { 'grid-template-columns': '1fr 36px' }
      },
      [
        h(
          pagination, {
            props: {
              value: this.restData[name].find.pagination
            },
            on: {
              input: event => {
                this.restData[name].find.pagination = event
              }
            }
          }
        ),

        h(
          RefreshButton,
          {
            class: 'ma-0',
            props: {
              state: this.restData[name].find.state,
              shortClickMethod: () => this.rest[name].find(),
              longClickMethod: () => this.rest[name].find({}, { noCache: true })
            }
          }
        )
      ]
    )
  }
}

function renderContent(h) {
  if (this.restData[name].find.state === 'ready') {
    return h(
      'g-table',
      {
        props: {
          value: this.restData[name].find.order,
          items: this.restData[name].find.data,
          headers: this.headers,
          hideHead: this.restData[name].find.pagination.total < 1,
          noDataText: this.getTranslate('referral.no.data.text')
        },
        scopedSlots: {
          items: ({ item }) => {
            return [
              h(
                'tr',
                {
                  key: item.id
                },
                [
                  h(
                    'td',
                    {
                      class: 'w--100 pl-2 pr-2 text-left'
                    },
                    item.data.hidden.referredUser
                  ),

                  h(
                    'td',
                    {
                      class: 'pl-2 pr-2 text-right'
                    },
                    getRUB(item.amount)
                  ),

                  h(
                    'td',
                    {
                      class: 'pl-2 pr-2 text--grey text-right',
                      style: { 'white-space': 'pre' }
                    },
                    getLocaleDateString(item.createdAt)
                  )
                ]
              )
            ]
          }
        }
      }
    )
  } else {
    return h(
      'div',
      {
        class: 'w--100 fjcc facc pt-3'
      },
      [ h('g-progress', { props: { indeterminate: true } }) ]
    )
  }
}

function renderSum(h) {
  if (this.loading) {
    return h(
      'g-progress',
      {
        class: 'ml-2',
        props: {
          indeterminate: true,
          size: 16,
          width: 1
        }
      }
    )
  } else {
    return h('span', { class: 'pl-1' }, getRUB(this.lastMonthSum))
  }
}

function renderLastMonthSum(h) {
  const date = new Date()
  date.setMonth(date.getMonth() - 1)

  return h(
    'div',
    {
      class: this.viewport.breakpoint.xs ? 'flex pa-0 text-nowrap' : null
    },
    [
      `${this.getTranslate('referral.contents.lastMonthSum')} ${date.toLocaleString(this.locale, { month: 'long' })}:`,
      renderSum.call(this, h)
    ]
  )
}

function renderReferralCode(h) {
  return h(
    'div',
    {
      class: {
        faic: true,
        'mb-1': this.viewport.breakpoint.smDown
      }
    },
    [
      this.getTranslate('referral.contents.referralCode'),

      h(
        'pre',
        {
          class: 'ml-1'
        },
        this.referralCode
      ),
      [
        h(
          copyIcon,
          {
            props: {
              value: this.referralCode,
              icon: 'ui-copy',
              library: 'icofont',
              size: 15,
              snackbar: {
                text: this.getTranslate('users.snackbars.refCopied'),
                type: 'success'
              },
              tooltip: this.getTranslate('users.tooltips.copy.referral.code')
            }
          }
        ),
        h(
          copyIcon,
          {
            props: {
              value: new URL(`#/registration?ref=${this.referralCode}`, window.location.origin).href,
              icon: 'link',
              library: 'icofont',
              size: 15,
              snackbar: {
                text: this.getTranslate('users.snackbars.linkCopied'),
                type: 'success'
              },
              tooltip: this.getTranslate('users.tooltips.copy.referral.link')
            }
          }
        )
      ]
    ]
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: 'fc fjcc facc'
    },
    [
      h(
        'div',
        {
          class: 'pa-3 faic fjcsb',
          style: {
            'min-height': '48px',
            'flex-direction': this.viewport.breakpoint.xs ? 'column' : null
          }
        },
        [
          h(
            'div',
            {
              style: {
                'align-self': 'fjcfs facfs',
                'font-size': this.viewport.breakpoint.smDown ? '12px' : undefined
              }
            },
            [ renderReferralCode.call(this, h), renderLastMonthSum.call(this, h) ]
          ),

          h(
            ReferralDateFilter,
            {
              props: { value: this.restData[name].find.filter },
              on: {
                input: event => {
                  this.restData[name].find.filter = event
                }
              }
            }
          )
        ]
      ),
      renderContent.call(this, h),
      renderPagination.call(this, h)
    ]
  )
}
