function renderPasswordFiled(h) {
  return h(
    'g-text-field',
    {
      props: {
        value: this.proxy,
        label: this.getTranslate('users.labels.password'),
        error: this.errors.password || this.passwordValidation,
        name: 'password',
        afterIcon: this.passwordVisibility ? 'visibility' : 'visibility_off',
        afterIconCallback: () => {
          this.passwordVisibility = !this.passwordVisibility
        },
        mode: 'outline-label',
        dense: true,
        rounded: true,
        clearable: true,
        type: this.passwordVisibility ? 'password' : 'text',
        required: this.required,
        tabindex: 2
      },
      on: {
        input: event => {
          this.proxy = event
        }
      }
    }
  )
}

function renderGeneratePasswordButton(h) {
  return h(
    'g-button',
    {
      class: 'ma-0',
      props: {
        icon: 'settings_backup_restore',
        flat: true,
        tabindex: -1
      },
      directives: [
        {
          name: 'g-tooltip',
          options: {
            value: this.getTranslate('registration.tooltips.password.generate'),
            placement: 'right'
          }
        }
      ],
      on: {
        click: () => {
          this.passwordGenerator()
        }
      }
    }
  )
}

function rendeShowButton(h) {
  return h(
    'g-button',
    {
      class: 'ma-0',
      props: {
        label: this.getTranslate('misc.buttons.passwordEdit'),
        flat: true,
        rounded: true,
        tabindex: 2
      },
      on: {
        click: () => {
          this.editable = true
        }
      }
    }
  )
}

export default function(h) {
  if (this.editable || this.isNew) {
    return h(
      'div',
      {
        class: 'grid grid-gap--8 faic',
        style: { 'grid-template-columns': '1fr auto' }
      },
      [
        renderPasswordFiled.call(this, h),
        renderGeneratePasswordButton.call(this, h)
      ]
    )
  } else {
    return h(
      'div',
      {
        class: 'grid faic fjcc'
      },
      [ rendeShowButton.call(this, h) ]
    )
  }
}
