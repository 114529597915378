export default function(h) {
  return h(
    'g-button',
    {
      props: {
        label: this.label,
        icon: this.icon,
        flat: true,
        disabled: this.disabled
      },
      on: {
        click: () => {
          const iframe = document.createElement('iframe')

          document.body.appendChild(iframe)

          const iframeContent = iframe.contentDocument ? iframe.contentDocument : iframe.contentWindow.document
          iframeContent.getElementsByTagName('body')[0].innerHTML = this.value

          iframe.contentWindow.focus()
          iframe.contentWindow.print()

          document.body.removeChild(iframe)
        }
      }
    }
  )
}
