import ExpandArrow from '@/components/misc/ExpandArrow'
import OwnerSelect from '@/components/misc/OwnerSelect'

const _ = { get: require('lodash/get') }

const name = 'billings'
const actions = [ 'addFunds', 'charge' ]

function renderSource(h) {
  if (this.action === 'charge') {
    return h(
      'g-select',
      {
        props: {
          value: this.proxy.source,
          label: this.getTranslate(`${name}.labels.source`),
          items: this.computedSource,
          error: this.errors.source,
          mode: 'outline',
          rounded: true,
          required: true
        },
        on: {
          input: event => {
            this.proxy.source = event
          }
        }
      }
    )
  }
}

function renderAmount(h) {
  if ([ 'charge', 'addFunds' ].indexOf(this.action) > -1) {
    return h(
      'g-text-field',
      {
        props: {
          value: this.proxy.amount,
          label: this.getTranslate(`${name}.labels.amount`),
          hint: this.getTranslate(`${name}.hints.amount`),
          error: this.errors.amount,
          mode: 'outline',
          autofocus: true,
          rounded: true,
          type: 'number',
          min: 0,
          step: 'any',
          clearable: true,
          required: true
        },
        on: {
          input: event => {
            this.proxy.amount = event
          }
        }
      }
    )
  }
}

function renderAdvanced(h) {
  return h(
    'g-expansion-panels',
    {
      props: { flat: true }
    },
    [
      h(
        'g-expansion-panel',
        {
          scopedSlots: {
            header: ({ expanded }) => {
              return h(
                'div',
                {
                  class: 'default-expansion-panel-header pr-0'
                },
                [
                  h('div', {}, this.getTranslate('misc.advanced')),
                  h('div', { class: 'ff' }),
                  h(ExpandArrow, { props: { expanded } })
                ]
              )
            },
            default: () => {
              return h(
                'div',
                {
                  class: 'grid grid-cols--1 pa-3'
                },
                [
                  h(
                    'div',
                    {
                      class: 'grid grid-cols--2 fiac'
                    },
                    [
                      h(
                        'g-text-field',
                        {
                          props: {
                            value: this.proxy.data.multiplier,
                            label: this.getTranslate(`${name}.labels.multiplier`),
                            error: _.get(this.errors, 'data.multiplier'),
                            mode: 'outline',
                            rounded: true,
                            type: 'number',
                            min: 1,
                            step: 'any',
                            details: false,
                            clearable: true,
                            required: true
                          },
                          on: {
                            input: event => {
                              this.proxy.data.multiplier = event
                            }
                          }
                        }
                      ),

                      h(
                        'g-switch',
                        {
                          props: {
                            value: this.proxy.data.hidden.noRef,
                            label: this.getTranslate(`${name}.labels.noRef`)
                          },
                          on: {
                            input: event => {
                              this.proxy.data.hidden.noRef = event
                            }
                          }
                        }
                      )
                    ]
                  ),

                  h(
                    'g-textarea',
                    {
                      props: {
                        value: this.proxy.data.hidden.comment,
                        label: this.getTranslate(`${name}.labels.comment`),
                        error: _.get(this.errors, 'data.hidden.comment'),
                        mode: 'outline',
                        rounded: true,
                        grow: true,
                        rows: 4,
                        clearable: true
                      },
                      on: {
                        input: event => {
                          this.proxy.data.hidden.comment = event
                        }
                      }
                    }
                  )
                ]
              )
            }
          }
        }
      )
    ]
  )
}

export default function(h) {
  const itemsDisabled = []
  if (!this.checkPermissions('advanced.billings.generate')) {
    itemsDisabled.push(this.account.id)
  }

  return h(
    'g-form',
    {
      class: 'pb-2',
      on: {
        submit: () => {
          this.$emit('submit', this.proxy)
        },
        validation: event => {
          this.$emit('validation', event)
        }
      }
    },
    [
      h(
        'div',
        {
          class: 'fc ff pa-3'
        },
        [
          h(
            OwnerSelect,
            {
              props: {
                value: this.proxy.OwnerId,
                error: this.errors.owner,
                mode: 'outline',
                rounded: true,
                itemsDisabled,
                required: true
              },
              on: {
                input: event => {
                  this.proxy.OwnerId = event
                }
              }
            }
          ),

          h(
            'g-select',
            {
              props: {
                value: this.action,
                label: this.getTranslate(`${name}.labels.action`),
                items: actions.map(action => ({
                  title: this.getTranslate(`${name}.actions.${action}`),
                  value: action
                })),
                mode: 'outline',
                rounded: true,
                error: this.errors.action,
                required: true
              },
              on: {
                input: event => {
                  this.action = event
                }
              }
            }
          ),

          renderSource.call(this, h),
          renderAmount.call(this, h)
        ]
      ),

      renderAdvanced.call(this, h)
    ]
  )
}
