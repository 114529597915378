import { getToday } from '@/utils/misc' // Этот путь должен быть прямым иначе webpack не сможет его найти

export const SENDINGS_INCOMING_FILTER = {
  OwnerId: undefined,
  type: undefined,
  from: undefined,
  createdAt: getToday()
}

export default { SENDINGS_INCOMING_FILTER }
