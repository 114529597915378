import tasksInfo from '@/components/services/tasks/info'
import tasksCounts from '@/components/services/tasks/counts'
import tasksActions from '@/components/services/tasks/actions'
import SingleLink from '@/components/misc/SingleLink'
import FileItemCard from '@/components/file/item/card'
import copyIcon from '@/components/copy/icon'

const _ = { get: require('lodash/get') }

function renderCutText(h, path, asDesc = false) {
  const value = _.get(this.value, path)
  const directives = []
  if (asDesc) {
    directives.push({
      name: 'g-tooltip',
      options: { value }
    })
  }
  if (value) {
    return h(
      'div',
      {
        class: 'grid grid-gap--4',
        style: { 'grid-template-columns': '15px 1fr' }
      },
      [
        h(
          copyIcon,
          {
            class: 'ma-0',
            props: {
              value,
              size: 15
            }
          }
        ),

        h(
          'div',
          {
            class: {
              'text-overflow': true,
              'text--grey': asDesc
            },
            directives
          },
          value
        )
      ]
    )
  }
}

function renderContent(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--2 grid-col--1 pa-2'
    },
    [
      renderCutText.call(this, h, 'data.sending.payload.sender'),
      renderCutText.call(this, h, 'data.sending.payload.text', true)
    ]
  )
}

function renderFileInfo(h) {
  if (this.value.File) {
    return h(
      FileItemCard,
      {
        props: {
          value: this.value.File,
          outline: false,
          displayMenu: false,
          flat: true,
          compact: true,
          paddingAll: this.viewport.breakpoint.smDown,
          small: true
        }
      }
    )
  }
}

function renderOwnerCell(h) {
  if (this.checkPermissions('advanced.users.get', [ 'reseller', true ])) {
    return h(
      'td',
      {
        class: 'px-2'
      },
      [
        h(
          SingleLink,
          {
            props: {
              value: this.value.Owner,
              length: 15
            }
          }
        )
      ]
    )
  }
}

function renderFormattedDate(h, date) {
  return h(
    'div',
    {
      class: 'text--grey text-right',
      style: { 'white-space': 'pre' },
      domProps: { innerHTML: date }
    }
  )
}

function renderRow(h) {
  return h(
    'tr',
    {
      class: 'hover-parent hover-parent--opacity',
      key: this.value.id
    },
    [
      h(
        'td',
        {
          class: 'px-2'
        },
        [ h(tasksInfo, { props: { value: this.value } }) ]
      ),

      h(
        'td',
        {
          class: 'px-2'
        },
        [ h(tasksCounts, { props: { value: this.value } }) ]
      ),

      renderOwnerCell.call(this, h, this.value),

      h(
        'td',
        {
          class: 'px-2 w--100'
        },
        [ renderContent.call(this, h) ]
      ),

      h(
        'td',
        {
          class: 'px-2'
        },
        [ renderFileInfo.call(this, h, this.value) ]
      ),
      h(
        'td',
        {
          class: 'px-2'
        },
        [ h(tasksActions, { props: { value: this.value } }) ]
      ),

      h(
        'td',
        {
          class: 'px-2'
        },
        [ renderFormattedDate.call(this, h, this.value.$formattedCreatedAt) ]
      ),

      h(
        'td',
        {
          class: 'px-2'
        },
        [ renderFormattedDate.call(this, h, this.value.$formattedUpdatedAt) ]
      )
    ]
  )
}

function renderPanel(h) {
  return h(
    'g-expansion-panel',
    {
      scopedSlots: {
        header: () => {
          return h(
            'div',
            {
              class: 'faic px-2'
            },
            [
              h(
                tasksInfo,
                {
                  class: 'py-1',
                  props: { value: this.value }
                }
              ),

              h('div', { class: 'ff' }),

              renderFormattedDate.call(this, h, this.value.$formattedCreatedAt)
            ]
          )
        },
        default: () => {
          return h(
            'div',
            {
              class: 'fc ff'
            },
            [
              renderContent.call(this, h),

              h(
                tasksCounts,
                {
                  class: 'pa-2',
                  props: { value: this.value }
                }
              ),

              renderFileInfo.call(this, h, this.value),

              h(tasksActions, { props: { value: this.value } })
            ]
          )
        }
      },
      key: this.value.id
    }
  )
}

export default function(h) {
  switch (this.type) {
    case 'panel':
      return renderPanel.call(this, h)
    case 'row':
    default:
      return renderRow.call(this, h)
  }
}
