import Vue from 'vue'

import buttonFavorite from '@/components/button/favorite'
import providersState from '@/components/services/providers/state'

import { serviceName } from '@/components/services/providers/utils'

function renderEditButton(h) {
  if (this.edit) {
    return h(
      'div',
      {
        class: {
          faic: true,
          'hover-child': this.viewport.breakpoint.mdUp
        }
      },
      [
        h(
          buttonFavorite,
          {
            props: {
              id: this.value.id,
              serviceName
            }
          }
        ),

        h(
          'g-button',
          {
            class: 'ma-0',
            props: {
              flat: true,
              icon: 'edit'
            },
            on: {
              click: () => {
                Vue.router.push({
                  name: `${serviceName}.single`,
                  params: { id: this.value.id }
                })
              }
            }
          }
        )
      ]
    )
  }
}

function renderComment(h) {
  if (this.value.comment) {
    return h(
      'div',
      {
        class: 'small text--grey text-overflow'
      },
      this.value.comment
    )
  }
}

function renderContent(h) {
  return h(
    'div',
    {
      class: 'faic'
    },
    [
      h(
        'div',
        {
          class: 'fjcc facc faic square--32 mr-2'
        },
        [
          h(
            'div',
            {
              class: 'fjcc facc faic square--24'
            },
            [ h(providersState, { props: { id: this.value.id } }) ]
          )
        ]
      ),

      h(
        'div',
        {
          class: 'grid grid-cols--1 grid-gap--4 ff'
        },
        [
          h(
            'router-link',
            {
              class: 'body-1 text-overflow link link--passive',
              props: {
                to: {
                  name: `${serviceName}.single`,
                  params: { id: this.value.id }
                }
              }
            },
            this.value.title
          ),

          renderComment.call(this, h)
        ]
      ),

      renderEditButton.call(this, h)
    ]
  )
}

export default function(h) {
  return renderContent.call(this, h)
}
