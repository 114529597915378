import { site } from '@sigma-legacy-libs/essentials/lib/schemas'

import notificationsTemplate from '@/components/services/settings/notifications/template'

export default function(h, { path, serviceName }) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8'
    },
    [
      h(
        'div',
        {
          class: 'body-1 text-center'
        },
        this.getTranslate(`${serviceName}.contents.${path}.title`)
      ),

      h(
        'div',
        {
          class: 'grid grid-gap--8'
        },
        Object.keys(site.SETTINGS_SITE_SCHEMA[path].$default).map(prop => {
          return h(
            notificationsTemplate,
            {
              props: {
                value: this.proxy,
                service: serviceName,
                path,
                prop
              },
              on: {
                input: event => {
                  this.proxy = event
                }
              }
            }
          )
        })
      )
    ]
  )
}
