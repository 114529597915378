export default {
  title: 'Templates',

  labels: {
    id: 'ID',
    title: 'Title',
    type: 'Type',
    owner: 'Owner'
  },

  buttons: {
    create: 'Create template',
    use: 'Use template'
  },
  links: { edit: 'Change template' },
  dialogs: { create: 'Name your template' },

  contents: {
    confirm: {
      remove: 'Are you sure you want to delete the template {title}?'
    }
  },

  snackbars: {
    created: 'Template successfully created.',
    updated: 'Template successfully updated.',
    removed: 'Template successfully removed.'
  }
}
