import { serviceName, stateColors, stateIcons } from '@/components/services/apisender/utils'

function renderPreLoader(h) {
  return h(
    'g-progress',
    {
      props: {
        indeterminate: true,
        color: 'primary',
        size: 24,
        width: 1
      }
    }
  )
}

function renderIcon(h) {
  return h(
    'g-icon',
    {
      props: {
        value: this.value.$state ? stateIcons[this.value.$state] : 'help_outline',
        color: this.value.$state ? stateColors[this.value.$state] : 'grey',
        size: 24
      },
      directives: [
        {
          name: 'g-tooltip',
          options: {
            value: this.getTranslate(`${serviceName}.states.instance.${this.value.$state}`)
          }
        }
      ]
    }
  )
}

function renderContent(h) {
  if (this.loading) {
    return renderPreLoader.call(this, h)
  }

  return renderIcon.call(this, h)
}

export default function(h) {
  return h(
    'div',
    {
      class: 'faic fjcc square--24'
    },
    [ renderContent.call(this, h) ]
  )
}
