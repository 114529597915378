import Clock from '@/components/misc/Clock'
import Copyright from '@/components/misc/Copyright'
import buttonPayment from '@/components/button/payment'
import buttonAccount from '@/components/button/account'
import navigationList from '@/components/misc/navigationList'
import UserWidget from '@/components/misc/UserWidget'
import SupportButton from '@/components/button/support'
import MobilePanel from '@/components/misc/MobilePanel'
import LocaleSwitch from '@/components/misc/LocaleSwitch'
import JivoSite from '@/components/misc/JivoSite'
import TourWrapper from '@/components/misc/TourWrapper'
import AudioPlayer from '@/components/misc/AudioPlayer'
import globalDialog from '@/components/global/dialog'
import fileInput from '@/components/file/input'
import UploadProgressBar from '@/components/misc/UploadProgressBar'

const _ = { get: require('lodash/get') }

function renderSupportLink(h) {
  const contacts = _.get(this.globalSettings, 'contacts')
  if (contacts) {
    return h(
      'router-link',
      {
        class: 'link mr-1',
        props: { to: { name: 'support' } }
      },
      this.getTranslate('misc.links.support')
    )
  }
}

function renderUserWidget(h) {
  if (this.viewport.breakpoint.smDown) {
    const overdraft = _.get(this.globalSettings, 'billings.overdraft', 0)

    return [
      h(
        UserWidget,
        {
          props: {
            flat: true,
            overdraft
          }
        }
      ),
      h('g-divider')
    ]
  }
}

function renderButtonPayment(h) {
  return h(buttonPayment)
}

function renderButtonAccount(h) {
  if (this.viewport.breakpoint.mdUp) {
    return h(buttonAccount)
  }
}

function renderClock(h) {
  if (!this.viewport.breakpoint.smDown) {
    return h(Clock)
  }
}

function renderJivoSite(h) {
  const jivosite = _.get(this.globalSettings, 'frontend.jivosite')
  if (jivosite) {
    return h(JivoSite)
  }
}

export default function(h) {
  return h(
    'g-app',
    {},
    [
      h(
        'g-toolbar',
        {
          class: {
            'pr-3': true,
            platinum: this.isPlatinum
          },
          props: { fixed: true }
        },
        [
          h(
            'g-button',
            {
              class: 'ma-3',
              props: {
                flat: true,
                icon: 'menu',
                color: 'white'
              },
              on: {
                click: () => {
                  this.showSidebar = !this.showSidebar
                  // eslint-disable-next-line no-undef
                  if (typeof jivo_api !== 'undefined') {
                    jivo_api.close() // eslint-disable-line
                  }
                }
              }
            }
          ),

          h(
            'div',
            {
              class: 'fz-20'
            },
            this.getTranslate(`${this.$route.name.replace('.single', '')}.title`)
          ),

          h('div', { class: 'ff' }),

          h(SupportButton),

          renderButtonPayment.call(this, h),
          renderButtonAccount.call(this, h),
          renderClock.call(this, h)
        ]
      ),

      h(
        'g-sidebar',
        {
          props: {
            value: this.showSidebar,
            fixed: true,
            mobile: this.viewport.breakpoint.smDown
          },
          on: {
            input: event => {
              this.showSidebar = event
            }
          }
        },
        [
          h(
            'div',
            {
              class: 'fc ff h--100'
            },
            [
              renderUserWidget.call(this, h),

              h(navigationList, { class: 'sidebar' }),

              h('div', { class: 'ff' }),

              h(
                'div',
                {
                  class: 'grid grid-cols--1 grid-gap--8 pa-2'
                },
                [
                  h(
                    'div',
                    {
                      class: 'grid grid-gap--8 caption',
                      style: { 'grid-template-columns': 'repeat(2, minmax(min-content, max-content))' }
                    },
                    [
                      h(LocaleSwitch),

                      renderSupportLink.call(this, h)
                    ]
                  ),

                  h(Copyright)
                ]
              )
            ]
          )
        ]
      ),

      h(
        'g-content',
        {},
        [
          h(
            'div',
            {
              class: 'pa-3'
            },
            [ this.$slots.default ]
          )
        ]
      ),

      renderJivoSite.call(this, h),

      h(MobilePanel),
      h(TourWrapper),
      h(UploadProgressBar),
      h(AudioPlayer),
      h(fileInput),
      h(globalDialog)
    ]
  )
}
