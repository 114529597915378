import proxy from '@sigma-legacy-libs/g-proxy'
import MarkdownIt from 'markdown-it'

import { PAYMENTS_METHODS } from '@sigma-legacy-libs/essentials/lib/constants'

import render from './render'

const md = new MarkdownIt({
  html: true,
  linkify: true,
  typography: true
})

export default {
  name: 'PaymentsOffer',

  mixins: [ proxy() ],

  props: {
    method: {
      type: String,
      validator: value => {
        return ~PAYMENTS_METHODS.indexOf(value)
      }
    }
  },

  data() {
    return {
      showDialog: false,

      html: undefined
    }
  },

  watch: {
    method() {
      this.getDocument()
    }
  },

  mounted() {
    this.getDocument()
  },

  methods: {
    async getDocument() {
      this.proxy = false
      this.html = undefined
      if (this.method) {
        try {
          const response = await fetch(`/docs/${this.locale}/misc/${this.method}-payment-offer.md`, { cache: 'no-cache' })
          if (!response.ok) {
            throw new Error(response.status)
          }
          this.html = md.render(await response.text())
        } catch (error) {
          this.proxy = true
        }
      }
    }
  },

  render
}
