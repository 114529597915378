import Vue from 'vue'

import HeadTitle from '@/components/misc/HeadTitle'
import RefreshButton from '@/components/button/refresh'
import PaymentsList from '@/components/services/payments/list'
import RecurrentPaymentsForm from '@/components/services/recurrentPayments/form'
import RecurrentPaymentsItem from '@/components/services/recurrentPayments/item'
import RecurrentPaymentsFilter from '@/components/services/recurrentPayments/filter'
import pagination from '@/components/misc/pagination'

import { STATES, permissionPresets } from '@/utils'

import { serviceName } from '@/components/services/recurrentPayments/utils'

function renderEmptyState(h, options) {
  if (options.isPage) {
    return h('g-empty', { props: { title: this.getTranslate(`${serviceName}.hints.notFound`) } })
  } else {
    return h(
      'div',
      {
        class: 'fc fjcc facc faic pt-4 pb-4 pl-3 pr-3'
      },
      [
        h(
          'g-icon',
          {
            props: {
              value: 'bookmark_border',
              size: 48,
              color: 'grey'
            }
          }
        ),

        h(
          'span',
          {
            class: 'headline text-center pt-4'
          },
          this.getTranslate(`${serviceName}.titles.empty.title`)
        ),

        h(
          'span',
          {
            class: 'subheading text-center text--grey pb-4'
          },
          this.getTranslate(`${serviceName}.titles.empty.sub`)
        ),

        h(
          'g-icon',
          {
            props: {
              value: 'keyboard_arrow_down',
              size: 48,
              color: 'grey'
            }
          }
        )
      ]
    )
  }
}

function renderCreateButton(h, trigger = true, options) {
  if (this.checkPermissions(`advanced.${serviceName}.create`, [ 'me', 'reseller', true ])) {
    if (trigger) {
      return h(
        'g-button',
        {
          class: 'ml-2',
          props: {
            label: options.isPage && this.viewport.breakpoint.smUp ? undefined : this.getTranslate(`${serviceName}.buttons.add`),
            icon: 'add',
            rounded: true,
            depressed: true,
            color: 'secondary'
          },
          on: {
            click: () => {
              this.showCreateDialog = true
            }
          }
        }
      )
    }
  }
}

function renderCreateDialog(h) {
  if (this.checkPermissions(`advanced.${serviceName}.create`, [ 'me', 'reseller', true ])) {
    return h(
      'g-dialog',
      {
        props: {
          rounded: true,
          value: this.showCreateDialog,
          maxWidth: 450
        },
        on: {
          input: event => {
            this.showCreateDialog = event
            this.setShowCreateRecurrentPaymentDialog(event)
          }
        }
      },
      [
        h(
          HeadTitle,
          {
            props: { value: this.getTranslate(`${serviceName}.titles.create`) },
            slot: 'header'
          }
        ),

        h(
          RecurrentPaymentsForm,
          {
            props: {
              value: this.restData[serviceName].create.data,
              errors: this.restData[serviceName].create.errors,
              isNew: true
            },
            on: {
              input: event => {
                this.restData[serviceName].create.data = event
              },
              submit: () => {
                this.createRecurrentPayment()
              },
              validation: event => {
                this.restData[serviceName].create.isValid = event
              }
            }
          }
        ),

        h(
          'g-card-actions',
          {
            class: 'fjcfe facfe',
            slot: 'footer'
          },
          [
            h(
              'g-button',
              {
                props: {
                  label: this.getTranslate('misc.buttons.cancel'),
                  flat: true,
                  rounded: true,
                  disabled: this.restData[serviceName].create.state === STATES.loading
                },
                on: {
                  click: () => {
                    this.showCreateDialog = false
                  }
                }
              }
            ),

            h(
              'g-button',
              {
                props: {
                  label: this.getTranslate('misc.buttons.create'),
                  rounded: true,
                  depressed: true,
                  loading: this.restData[serviceName].create.state === STATES.loading,
                  disabled: !this.restData[serviceName].create.isValid || this.restData[serviceName].create.state === STATES.loading,
                  color: 'primary'
                },
                on: {
                  click: this.createRecurrentPayment
                }
              }
            )
          ]
        )
      ]
    )
  }
}

function renderPaymentsDialog(h) {
  if (this.checkPermissions('advanced.payments.get', [ 'me', 'reseller', true ])) {
    return h(
      'g-dialog',
      {
        props: {
          rounded: true,
          value: this.showPaymentsDialog,
          maxWidth: 500
        },
        on: {
          input: event => {
            this.showPaymentsDialog = event
          }
        }
      },
      [
        h(
          HeadTitle,
          {
            props: { value: this.getTranslate(`${serviceName}.titles.payments`) },
            slot: 'header'
          }
        ),

        h(PaymentsList({ RecurrentPaymentId: this.RecurrentPaymentId })),

        h(
          'g-card-actions',
          {
            class: 'fjcfe facfe',
            slot: 'footer'
          },
          [
            h(
              'g-button',
              {
                props: {
                  label: this.getTranslate('misc.buttons.close'),
                  flat: true,
                  rounded: true
                },
                on: {
                  click: () => {
                    this.showPaymentsDialog = false
                  }
                }
              }
            )
          ]
        )
      ]
    )
  }
}

function renderUnlockConfirmDialog(h) {
  if (this.checkPermissions(`advanced.${serviceName}.remove`, permissionPresets.admin)) {
    return h(
      'g-dialog',
      {
        props: {
          rounded: true,
          value: this.showUnlockConfirmDialog,
          maxWidth: 450
        },
        on: {
          input: event => {
            this.showUnlockConfirmDialog = event
          }
        }
      },
      [
        h(
          HeadTitle,
          {
            props: { value: this.getTranslate(`${serviceName}.titles.unlock`) },
            slot: 'header'
          }
        ),

        h('div', { class: 'pa-3' }, this.getTranslate(`${serviceName}.contents.unlock`)),

        h(
          'g-card-actions',
          {
            class: 'fjcfe facfe',
            slot: 'footer'
          },
          [
            h(
              'g-button',
              {
                props: {
                  label: this.getTranslate('misc.buttons.cancel'),
                  flat: true,
                  rounded: true,
                  disabled: this.unlockLoading
                },
                on: {
                  click: () => {
                    this.showUnlockConfirmDialog = false
                  }
                }
              }
            ),

            h(
              'g-button',
              {
                props: {
                  label: this.getTranslate('misc.buttons.unlock'),
                  icon: 'done',
                  color: 'success',
                  rounded: true,
                  depressed: true,
                  loading: this.unlockLoading,
                  disabled: this.unlockLoading
                },
                on: {
                  click: () => {
                    this.unlockRecurrentPayment()
                  }
                }
              }
            )
          ]
        )
      ]
    )
  }
}
function renderRemoveConfirmDialog(h) {
  if (this.checkPermissions(`advanced.${serviceName}.remove`, [ 'me', 'reseller', true ])) {
    return h(
      'g-dialog',
      {
        props: {
          rounded: true,
          value: this.showRemoveConfirmDialog,
          maxWidth: 450
        },
        on: {
          input: event => {
            this.showRemoveConfirmDialog = event
          }
        }
      },
      [
        h(
          HeadTitle,
          {
            props: { value: this.getTranslate(`${serviceName}.titles.remove`) },
            slot: 'header'
          }
        ),

        h('div', { class: 'pa-3' }, this.getTranslate(`${serviceName}.contents.remove`)),

        h(
          'g-card-actions',
          {
            class: 'fjcfe facfe',
            slot: 'footer'
          },
          [
            h(
              'g-button',
              {
                props: {
                  label: this.getTranslate('misc.buttons.cancel'),
                  flat: true,
                  rounded: true,
                  disabled: this.restData[serviceName].remove.state === STATES.loading
                },
                on: {
                  click: () => {
                    this.showRemoveConfirmDialog = false
                  }
                }
              }
            ),

            h(
              'g-button',
              {
                props: {
                  label: this.getTranslate('misc.buttons.remove'),
                  rounded: true,
                  depressed: true,
                  loading: this.restData[serviceName].remove.state === STATES.loading,
                  disabled: this.restData[serviceName].remove.state === STATES.loading,
                  color: 'error'
                },
                on: {
                  click: () => {
                    this.removeRecurrentPayment()
                  }
                }
              }
            )
          ]
        )
      ]
    )
  }
}

function renderFilterTags(h, trigger) {
  if (trigger) {
    const tags = Object.keys(this.restData[serviceName].find.filter)

    return h(
      'div',
      {
        class: 'faic px-2'
      },
      tags.map(tag => {
        if (tag === 'id') {
          const value = this.restData[serviceName].find.filter[tag]
          if (value !== undefined) {
            return h(
              'g-chip',
              {
                props: {
                  small: true,
                  color: 'grey',
                  cancelable: true,
                  cancelCallback: () => {
                    this.restData[serviceName].find.filter[tag] = undefined
                    Vue.router.replace({
                      ...Vue.router.currentRoute,
                      query: { recurrentPaymentId: undefined }
                    })
                  },
                  label: [ this.getTranslate('misc.filteredBy', { filter: tag }), value ].join(' ')
                }
              }
            )
          }
        }
      })
    )
  }
}

function renderRefreshButton(h, trigger) {
  if (trigger) {
    return h(
      RefreshButton,
      {
        props: {
          state: this.restData[serviceName].find.state,
          shortClickMethod: () => this.rest[serviceName].find(),
          longClickMethod: () => this.rest[serviceName].find({}, { noCache: true })
        }
      }
    )
  }
}

function renderPagination(h, options, place) {
  let trigger = false
  switch (place) {
    case 'top':
      trigger = this.restData[serviceName].find.pagination.total > 0
      break
    case 'bottom':
      trigger = this.restData[serviceName].find.pagination.total >= 10
      break
  }

  return h(
    'div',
    {
      class: 'faic h--48'
    },
    [
      renderCreateButton.call(this, h, trigger && !options.isPage, options),

      renderFilterTags.call(this, h, place === 'top'),

      h('div', { class: 'ff' }),

      h(
        pagination, {
          props: {
            value: this.restData[serviceName].find.pagination,
            show: this.restData[serviceName].find.pagination.total >= this.restData[serviceName].find.pagination.limit
          },
          on: {
            input: event => {
              this.restData[serviceName].find.pagination = event
            }
          }
        }
      ),

      renderRefreshButton.call(this, h, trigger)
    ]
  )
}

function renderFilter(h, options) {
  if (options.isPage) {
    return h(
      RecurrentPaymentsFilter,
      {
        props: {
          value: this.restData[serviceName].find.filter
        },
        on: {
          input: event => {
            this.restData[serviceName].find.filter = event
          }
        },
        scopedSlots: {
          buttons: () => {
            return [ renderCreateButton.call(this, h, options.isPage, options) ]
          }
        }
      }
    )
  }
}

function renderPreloader(h) {
  return h(
    'div',
    {
      class: 'fjcc facc pa-3'
    },
    [ h('g-progress', { props: { indeterminate: true } }) ]
  )
}

function renderList(h, options) {
  if (this.restData[serviceName].find.state === STATES.ready) {
    if (this.restData[serviceName].find.pagination.total > 0) {
      return h(
        options.isPage ? 'g-expansion-panels' : 'div',
        {
          class: {
            'grid pa-3': !options.isPage,
            'grid-cols--3': !options.isPage && this.viewport.breakpoint.mdUp,
            'grid-cols--2': !options.isPage && this.viewport.breakpoint.sm,
            'grid-cols--1': !options.isPage && this.viewport.breakpoint.xs
          },
          props: { flat: true }
        },
        this.restData[serviceName].find.data.map(item => {
          return h(
            RecurrentPaymentsItem,
            {
              props: {
                value: item,
                type: options.isPage ? 'panel' : 'card'
              },
              on: {
                payments: id => {
                  this.RecurrentPaymentId = id
                  this.showPaymentsDialog = true
                },
                unlockRecurrentPayment: id => {
                  this.RecurrentPaymentId = id
                  this.showUnlockConfirmDialog = true
                },
                removeRecurrentPayment: id => {
                  this.RecurrentPaymentId = id
                  this.showRemoveConfirmDialog = true
                }
              }
            }
          )
        })
      )
    } else {
      return renderEmptyState.call(this, h, options)
    }
  } else if (this.restData[serviceName].find.state === STATES.loading) {
    return renderPreloader.call(this, h)
  }
}

function renderFooter(h, options) {
  if (this.restData[serviceName].find.pagination.total === 0) {
    return h(
      'div',
      {
        class: 'fjcc facc'
      },
      [ renderCreateButton.call(this, h, !options.isPage, options) ]
    )
  }
}

function renderDivider(h, trigger) {
  if (trigger) {
    return h('g-divider')
  }

  return h('div') // Не удаляй потому что перестанет рендериться всё что после этого компонента в основном рендере
}

export default function(h, options) {
  if (this.checkPermissions(`advanced.${serviceName}.get`, [ 'me', 'reseller', true ])) {
    return h(
      'div',
      {
        class: 'fc ff'
      },
      [
        renderCreateDialog.call(this, h),
        renderPaymentsDialog.call(this, h),
        renderUnlockConfirmDialog.call(this, h),
        renderRemoveConfirmDialog.call(this, h),

        renderFilter.call(this, h, options),

        renderDivider.call(this, h, options.isPage),

        renderPagination.call(this, h, options, 'top'),

        renderDivider.call(this, h, options.isPage && this.restData[serviceName].find.pagination.total),

        renderList.call(this, h, options),

        renderDivider.call(this, h, options.isPage && this.restData[serviceName].find.pagination.total),

        renderPagination.call(this, h, options, 'bottom'),

        renderFooter.call(this, h, options)
      ]
    )
  }
}
