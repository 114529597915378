import { TASK_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import HeadTitle from '@/components/misc/HeadTitle'
import OneRowString from '@/components/misc/OneRowString'

import { serviceName } from '@/components/services/tasks/utils'

function renderTaskInfo(h) {
  if (Array.isArray(this.analytics) && this.analytics.length > 0) {
    return h(
      'div',
      {
        class: 'pa-3'
      },
      this.analytics.map(item => {
        const content = [ h(OneRowString, { props: { value: item.subheader || item.title } }) ]
        if (item.error) {
          content.push(h('span', { class: 'pr-1' }, ':'))
          content.push(h('span', { class: 'text--error' }, item.error))
        }
        const props = {}
        if (item.id) {
          props.to = {
            name: 'contacts.single',
            params: { id: item.id }
          }
        }

        return h(
          item.subheader ? 'g-subheader' : item.id ? 'router-link' : 'div',
          {
            class: {
              faic: true,
              link: !!item.id,
              'pl-0': !!item.subheader
            },
            props
          },
          content
        )
      })
    )
  }
}

function renderDialog(h) {
  return h(
    'g-dialog',
    {
      props: {
        rounded: true,
        value: this.showDialog,
        maxWidth: 400
      },
      on: {
        input: event => {
          if (event === false) {
            this.clearAnalytics()
          }
          this.showDialog = event
        }
      }
    },
    [
      h(
        HeadTitle,
        {
          props: {
            value: this.getTranslate(`${serviceName}.titles.analytics`),
            slot: 'header'
          }
        }
      ),

      renderTaskInfo.call(this, h),

      h(
        'g-card-actions',
        {
          class: 'fjcfe',
          slot: 'footer'
        },
        [
          h(
            'g-button',
            {
              props: {
                label: this.getTranslate('misc.buttons.close'),
                flat: true,
                rounded: true
              },
              on: {
                click: () => {
                  this.clearAnalytics()
                  this.showDialog = false
                }
              }
            }
          )
        ]
      )
    ]
  )
}

function renderListItem(h) {
  return h(
    'g-list-item',
    {
      props: {
        label: this.getTranslate(`${serviceName}.labels.contactLists`),
        icon: 'quick_contacts_dialer'
      },
      on: {
        click: () => {
          this.analysis()
        }
      }
    }
  )
}

function renderContent(h) {
  return h(
    'div',
    {},
    [ renderListItem.call(this, h), renderDialog.call(this, h) ]
  )
}

export default function(h) {
  if (this.value.type === TASK_TYPES['sendings:bulk']) {
    return renderContent.call(this, h)
  }
}
