import OwnerSelect from '@/components/misc/OwnerSelect'
import RowWithMessageTypeIcon from '@/components/misc/RowWithMessageTypeIcon'

export default function(h) {
  let cols = 3
  if (this.viewport.breakpoint.smUp) {
    if (!this.checkPermissions('advanced.users.get', [ 'reseller', true ])) {
      cols = 2
    }
  } else {
    cols = 1
  }

  return h(
    'div',
    {
      class: `grid grid-gap--8 pa-2 grid-cols--${cols}`
    },
    [
      h(
        'g-text-field',
        {
          props: {
            value: this.proxy.$search,
            label: this.getTranslate('commons.labels.search'),
            mode: 'outline',
            dense: true,
            rounded: true,
            details: false,
            clearable: true
          },
          on: {
            input: event => {
              this.proxy.$search = event
            }
          }
        }
      ),

      h(
        'g-select',
        {
          props: {
            value: this.proxy.type,
            label: this.getTranslate('templates.labels.type'),
            items: this.sendingTypes.map(type => {
              return {
                title: this.getTranslate(type.title),
                value: type.value
              }
            }),
            mode: 'outline',
            dense: true,
            rounded: true,
            details: false,
            clearable: true
          },
          scopedSlots: {
            selection: ({ item }) => {
              return h(RowWithMessageTypeIcon, {
                props: {
                  value: item.value,
                  title: item.title
                }
              })
            },
            item: ({ item }) => {
              return h(RowWithMessageTypeIcon, {
                props: {
                  value: item.value,
                  title: item.title
                }
              })
            }
          },
          on: {
            input: event => {
              this.proxy.type = event
            }
          }
        }
      ),

      h(
        OwnerSelect,
        {
          props: {
            value: this.proxy.OwnerId,
            mode: 'outline',
            dense: true,
            rounded: true,
            details: false,
            clearable: true
          },
          on: {
            input: event => {
              this.proxy.OwnerId = event
            }
          }
        }
      )
    ]
  )
}
