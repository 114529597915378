import notificationsEventsBillingsLimit from '@/components/services/settings/notifications/events/billings/limit'

export default function(h, { UserId, ResellerId, serviceName }) {
  return h(
    notificationsEventsBillingsLimit({
      UserId,
      ResellerId,
      serviceName
    }),
    {
      props: { value: this.proxy },
      on: {
        input: event => {
          this.proxy = event
        }
      }
    }
  )
}
