import Vue from 'vue'

function renderTexts(h) {
  return this.proxy.map((text, index) => {
    return h(
      'div',
      {
        class: 'grid grid-gap--8 faic',
        style: { 'grid-template-columns': '32px 1fr 36px' }
      },
      [
        h(
          'g-icon',
          {
            props: {
              value: 'text_fields',
              color: 'grey'
            }
          }
        ),

        h(
          'g-textarea',
          {
            props: {
              value: text,
              label: this.getTranslate('conditions.labels.text'),
              mode: 'outline',
              details: false,
              dense: true,
              rounded: true,
              clearable: true
            },
            on: {
              input: event => {
                Vue.set(this.proxy, index, event)
              }
            }
          }
        ),

        h(
          'g-button',
          {
            class: 'ma-0',
            props: {
              icon: 'remove',
              flat: true,
              color: 'error'
            },
            on: {
              click: () => {
                this.remove(index)
              }
            }
          }
        )
      ]
    )
  })
}

export default function(h) {
  if (Array.isArray(this.proxy)) {
    return h(
      'div',
      {
        class: 'grid grid-cols--1 grid-gap--8'
      },
      [
        renderTexts.call(this, h),

        h(
          'div',
          {
            class: 'faic fjcfe ff'
          },
          [
            h(
              'g-button',
              {
                class: 'mr-0',
                props: {
                  icon: 'add',
                  flat: true,
                  rounded: true,
                  color: 'primary'
                },
                on: {
                  click: () => {
                    this.add()
                  }
                }
              }
            )
          ]
        )
      ]
    )
  }
}
