import { PAYMENTS_METHODS } from '@sigma-legacy-libs/essentials/lib/constants'

export const DEFAULT_INVOICE_PAYLOAD = {
  requisiteId: undefined,
  companyId: undefined,
  type: undefined,
  data: {
    SERVICE_PRICE: 1000
  }
}
export const DEFAULT_PAYMENT_PAYLOAD = {
  method: PAYMENTS_METHODS[0],
  amount: 3000,
  acceptOffer: false,
  OwnerId: undefined
}

export default {
  DEFAULT_INVOICE_PAYLOAD,
  DEFAULT_PAYMENT_PAYLOAD
}
