import titleDivider from '@/components/title/divider'
import generatorRow from '@/components/generator/row'
import rulesGenerator from '@/components/misc/rules/conditions'
import RoutesFormType from '@/components/services/routes/form/type'
import RoutesFormTitle from '@/components/services/routes/form/title'

import { serviceName } from '@/components/services/routingRules/utils'

function renderTypeField(h) {
  if (!this.type) {
    return h(
      RoutesFormType,
      {
        props: {
          value: this.proxy,
          serviceName
        },
        on: {
          input: event => {
            this.proxy = event
          }
        }
      }
    )
  }
}

function renderTitleField(h) {
  return h(
    RoutesFormTitle,
    {
      props: {
        value: this.proxy,
        disabled: this.disabled,
        serviceName
      },
      on: {
        input: event => {
          this.proxy = event
        }
      }
    }
  )
}

function renderRulesGenerator(h) {
  return h(
    rulesGenerator,
    {
      props: {
        value: this.proxy.rules,
        type: this.proxy.type
      },
      on: {
        input: event => {
          this.proxy.rules = event
        }
      }
    }
  )
}

function renderSelectorProviderGroup(h, item) {
  if (item.title) {
    return h(
      'div',
      {
        class: 'fc ff plpx-12'
      },
      [
        h(
          'div',
          {
            class: 'fz-14'
          },
          item.title
        ),

        h(
          'div',
          {
            class: 'fz-11 text--warning'
          },
          this.getTranslate(`${serviceName}.hints.willBeCreated`)
        )
      ]
    )
  }

  return h(
    'div',
    {
      class: 'grid faic',
      style: { 'grid-template-columns': '24px 1fr' }
    },
    [
      h(
        'a',
        {
          attrs: { href: `#/admin/routes/providerGroups/${item.id}` },
          class: 'link link--passive square--24'
        },
        [
          h(
            'g-icon',
            {
              props: {
                value: 'link',
                color: 'grey'
              }
            }
          )
        ]
      ),

      h(
        'g-select',
        {
          props: {
            value: item.id,
            label: this.getTranslate(`${serviceName}.labels.providerGroup`),
            query: {
              type: this.proxy.type,
              isActive: true
            },
            itemsDisabled: this.proxy.ProviderGroups.map(({ id }) => id),
            service: 'providerGroups',
            disabled: this.disabled,
            autocomplete: true,
            clearable: true,
            mode: 'outline',
            combobox: true,
            rounded: true,
            dense: true,
            details: false
          },
          on: {
            input: event => {
              item.id = event
            }
          }
        }
      )
    ]
  )
}

function renderProviderGroupsFields(h) {
  if (Array.isArray(this.proxy.ProviderGroups)) {
    return h(
      'div',
      {
        class: 'grid grid-cols--1'
      },
      [
        h(titleDivider, { props: { value: this.getTranslate(`${serviceName}.subtitles.providerGroups`) } }),

        h(
          generatorRow,
          {
            props: {
              value: this.proxy.ProviderGroups,
              disabled: this.disabled,
              defaultValue: {
                id: undefined,
                percentage: 100
              }
            },
            on: {
              input: event => {
                this.proxy.ProviderGroups = event
              }
            },
            scopedSlots: {
              row: ({ item }) => {
                return h(
                  'div',
                  {
                    class: 'grid ff',
                    style: {
                      'grid-template-columns': this.viewport.breakpoint.mdUp ? '300px 1fr' : '100%',
                      'max-width': '100%'
                    }
                  },
                  [
                    renderSelectorProviderGroup.call(this, h, item),

                    h(
                      'g-text-field',
                      {
                        props: {
                          value: item.percentage,
                          defaultValue: 100,
                          label: this.getTranslate(`${serviceName}.labels.percentage`),
                          disabled: this.disabled,
                          type: 'number',
                          min: 1,
                          max: 100,
                          step: 'any',
                          mode: 'outline',
                          rounded: true,
                          required: true,
                          dense: true,
                          details: false
                        },
                        on: {
                          input: event => {
                            item.percentage = event
                          }
                        }
                      }
                    )
                  ]
                )
              }
            }
          }
        )
      ]
    )
  }
}

export default function(h) {
  return h(
    'g-form',
    {
      class: 'grid',
      style: { 'grid-template-columns': '100%' },
      on: {
        submit: () => {
          this.$emit('submit', this.proxy)
        }
      }
    },
    [
      renderTypeField.call(this, h),
      renderTitleField.call(this, h),
      renderRulesGenerator.call(this, h),
      renderProviderGroupsFields.call(this, h)
    ]
  )
}
