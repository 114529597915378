import {
  RENDER_SETTINGS_USER_SCHEMA,
  RENDER_SETTINGS_USER_SCHEMA_ME,
  RENDER_SETTINGS_USER_SCHEMA_RESELLER,
  RENDER_SETTINGS_USER_SCHEMA_RESELLER_CHILD
} from '@/utils'

import { serviceName } from '@/components/services/settingsUser/utils'

import render from './render'

export default function(options) {
  return {
    computed: {
      renderSchema() {
        if (this.checkPermissions('advanced.settingsUser.set')) {
          return RENDER_SETTINGS_USER_SCHEMA
        }
        if (this.checkPermissions('advanced.settingsUser.set', 'reseller')) {
          if (options.UserId === this.account.id) {
            return RENDER_SETTINGS_USER_SCHEMA_RESELLER
          }

          return RENDER_SETTINGS_USER_SCHEMA_RESELLER_CHILD
        }

        return RENDER_SETTINGS_USER_SCHEMA_ME
      }
    },

    render(h) {
      return render.call(this, h, Object.assign({
        renderSchema: this.renderSchema,
        serviceName
      }, options))
    }
  }
}

