import RefreshButton from '@/components/button/refresh'
import UsersRequisitesItem from '@/components/services/users/requisites/item'
import UsersRequisitesDialogs from '@/components/services/users/requisites/dialogs'
import pagination from '@/components/misc/pagination'

import { serviceName } from '@/components/services/requisites/utils'

import { STATES } from '@/utils'

function renderPreloader(h) {
  return h(
    'div',
    {
      class: 'fjcc facc pa-3'
    },
    [ h('g-progress', { props: { indeterminate: true } }) ]
  )
}

function renderEmptyState(h) {
  return h(
    'div',
    {
      class: 'fc fjcc facc faic pt-4 pb-4 pl-3 pr-3'
    },
    [
      h(
        'g-icon',
        {
          props: {
            value: 'bookmark_border',
            size: 48,
            color: 'grey'
          }
        }
      ),

      h(
        'span',
        {
          class: 'headline text-center pt-4'
        },
        this.getTranslate(`${serviceName}.titles.empty.title`)
      ),

      h(
        'span',
        {
          class: 'subheading text-center text--grey pb-4'
        },
        this.getTranslate(`${serviceName}.titles.empty.sub`)
      ),

      h(
        'g-icon',
        {
          props: {
            value: 'keyboard_arrow_down',
            size: 48,
            color: 'grey'
          }
        }
      )
    ]
  )
}

function renderContentByViewport(h) {
  const on = {
    [`${serviceName}.update`]: event => {
      this.requisiteId = event
      this.dialogType = `${serviceName}.update`
      this.showDialog = true
    },
    [`${serviceName}.remove`]: event => {
      this.requisiteId = event
      this.dialogType = `${serviceName}.remove`
      this.showDialog = true
    }
  }
  if (this.viewport.breakpoint.mdUp) {
    return h(
      'g-table',
      {
        props: {
          headers: this.headers,
          hideHead: this.restData[serviceName].find.pagination.total < 1,
          items: this.restData[serviceName].find.data,
          value: this.restData[serviceName].find.order,
          noDataText: this.getTranslate('misc.no.data.text')
        },
        on: {
          input: event => {
            this.restData[serviceName].find.order = event
          }
        },
        scopedSlots: {
          items: ({ item }) => {
            return h(
              UsersRequisitesItem,
              {
                props: {
                  value: item,
                  type: 'table'
                },
                on
              }
            )
          }
        }
      }
    )
  } else {
    return h(
      'g-expansion-panels',
      {
        props: { flat: true }
      },
      this.restData[serviceName].find.data.map(item => {
        return h(
          UsersRequisitesItem,
          {
            props: {
              value: item,
              type: 'panel'
            },
            on
          }
        )
      })
    )
  }
}

function renderList(h) {
  if (this.restData[serviceName].find.state === STATES.ready) {
    if (this.restData[serviceName].find.pagination.total > 0) {
      return renderContentByViewport.call(this, h)
    } else {
      return renderEmptyState.call(this, h)
    }
  } else if (this.restData[serviceName].find.state === STATES.loading) {
    return renderPreloader.call(this, h)
  }

  return h('div')
}

function renderCreateButton(h, trigger = true) {
  if (this.checkPermissions(`advanced.${serviceName}.create`, [ 'me', 'reseller', true ])) {
    if (trigger) {
      return h(
        'g-button',
        {
          class: 'ml-2',
          props: {
            label: this.viewport.breakpoint.mdUp ? this.getTranslate(`${serviceName}.buttons.add`) : undefined,
            icon: 'add',
            rounded: true,
            depressed: true,
            color: 'secondary'
          },
          on: {
            click: () => {
              this.dialogType = `${serviceName}.create`
              this.showDialog = true
            }
          }
        }
      )
    }
  }

  return h('div')
}

function renderRefreshButton(h, trigger) {
  if (trigger) {
    return h(
      RefreshButton,
      {
        props: {
          state: this.restData[serviceName].find.state,
          shortClickMethod: () => this.rest[serviceName].find(),
          longClickMethod: () => this.rest[serviceName].find({}, { noCache: true })
        }
      }
    )
  }
}

function renderPagination(h, place) {
  let trigger = false
  switch (place) {
    case 'top':
      trigger = this.restData[serviceName].find.pagination.total > 0
      break
    case 'bottom':
      trigger = this.restData[serviceName].find.pagination.total >= 10
      break
  }

  return h(
    'div',
    {
      class: 'faic h--48'
    },
    [
      renderCreateButton.call(this, h, trigger),

      h('div', { class: 'ff' }),

      h(
        pagination, {
          props: {
            value: this.restData[serviceName].find.pagination,
            show: this.restData[serviceName].find.pagination.total >= this.restData[serviceName].find.pagination.limit
          },
          on: {
            input: event => {
              this.restData[serviceName].find.pagination = event
            }
          }
        }
      ),

      renderRefreshButton.call(this, h, trigger)
    ]
  )
}

function renderFooter(h) {
  if (this.restData[serviceName].find.pagination.total === 0) {
    return h(
      'div',
      {
        class: 'fjcc facc'
      },
      [ renderCreateButton.call(this, h) ]
    )
  }

  return h('div')
}

function renderDialogs(h) {
  return h(
    UsersRequisitesDialogs,
    {
      props: {
        id: this.requisiteId,
        type: this.dialogType,
        show: this.showDialog,
        OwnerId: this.OwnerId,
        User: this.User
      },
      on: {
        show: event => {
          if (event === false) {
            this.requisiteId = undefined
            this.dialogType = undefined
          }
          this.showDialog = event
        }
      }
    }
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: 'fc ff'
    },
    [
      renderPagination.call(this, h, 'top'),
      renderList.call(this, h),
      renderPagination.call(this, h, 'bottom'),
      renderFooter.call(this, h),
      renderDialogs.call(this, h)
    ]
  )
}
