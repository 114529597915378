import { SENDING_TYPES, TARIFFS_VOICE_PER } from '@sigma-legacy-libs/essentials/lib/constants'

import { serviceName, transformType } from '@/components/services/tariffs/utils'

const directions = [ 'outgoing', 'incoming' ]

const _ = {
  get: require('lodash/get'),
  set: require('lodash/set')
}

function renderPrice(h, path, hint) {
  return h(
    'g-text-field',
    {
      props: {
        value: _.get(this.proxy, path),
        hint: hint || this.getTranslate(`${serviceName}.labels.price`),
        suffix: this.getTranslate(`currency.${this.proxy.currency}.symbol.unicode`),
        type: 'number',
        min: 0,
        step: 'any',
        mode: 'outline',
        rounded: true,
        dense: true
      },
      key: path,
      on: {
        input: event => {
          _.set(this.proxy, path, event)
        }
      }
    }
  )
}

function renderPer(h, path) {
  return h(
    'g-select',
    {
      props: {
        value: _.get(this.proxy, path),
        hint: this.getTranslate(`${serviceName}.labels.per`),
        items: TARIFFS_VOICE_PER.map(value => {
          return {
            title: this.getTranslate(`${serviceName}.per.${value}`),
            value
          }
        }),
        type: 'text',
        mode: 'outline',
        rounded: true,
        dense: true
      },
      on: {
        input: event => {
          _.set(this.proxy, path, event)
        }
      }
    }
  )
}

function renderPriceByType(h, direction) {
  if (!this.proxy.data[direction]) {
    return h('div')
  }

  switch (direction) {
    case 'outgoing': {
      switch (this.proxy.type) {
        case transformType(SENDING_TYPES.viber): {
          return h(
            'div',
            {
              class: 'grid grid-gap--8'
            },
            Object.keys(this.proxy.data[direction]).map(messageType => {
              return renderPrice.call(
                this,
                h,
                [ 'data', direction, messageType, 'price' ].join('.'),
                this.getTranslate(`${serviceName}.labels.directions.${direction}.${messageType}`)
              )
            })
          )
        }
        case transformType(SENDING_TYPES.vk): {
          return h(
            'div',
            {
              class: 'grid grid-gap--8'
            },
            [ renderPrice.call(this, h, [ 'data', direction, 'price' ].join('.')) ]
          )
        }
        case transformType(SENDING_TYPES.voice): {
          return h(
            'div',
            {
              class: 'grid grid-gap--8'
            },
            [
              renderPrice.call(this, h, [ 'data', direction, 'price' ].join('.')),
              renderPer.call(this, h, [ 'data', direction, 'per' ].join('.'))
            ]
          )
        }
        case transformType(SENDING_TYPES.whatsapp): {
          return h(
            'div',
            {
              class: 'grid grid-gap--8'
            },
            Object.keys(this.proxy.data[direction]).map(messageType => {
              return renderPrice.call(
                this,
                h,
                [ 'data', direction, messageType, 'price' ].join('.'),
                this.getTranslate(`${serviceName}.labels.directions.${direction}.${messageType}`)
              )
            })
          )
        }
      }

      break
    }

    case 'incoming': {
      switch (this.proxy.type) {
        case transformType(SENDING_TYPES.viber):
        case transformType(SENDING_TYPES.vk):
        case transformType(SENDING_TYPES.voice): {
          return h(
            'div',
            {
              class: 'grid grid-gap--8'
            },
            [ renderPrice.call(this, h, [ 'data', direction, 'price' ].join('.')) ]
          )
        }
        case transformType(SENDING_TYPES.whatsapp): {
          return h(
            'div',
            {
              class: 'grid grid-gap--8'
            },
            Object.keys(this.proxy.data[direction]).map(messageType => {
              return renderPrice.call(
                this,
                h,
                [ 'data', direction, messageType, 'price' ].join('.'),
                this.getTranslate(`${serviceName}.labels.directions.${direction}.${messageType}`)
              )
            })
          )
        }
      }

      break
    }
  }
}

function renderDirectionTitle(h, direction) {
  return h(
    'div',
    {
      class: `${serviceName}-direction-title`
    },
    this.getTranslate(`${serviceName}.directions.${direction}`)
  )
}

function renderDirection(h, direction) {
  return h(
    'div',
    {
      class: `${serviceName}-direction`
    },
    [
      renderDirectionTitle.call(this, h, direction),
      renderPriceByType.call(this, h, direction)
    ]
  )
}

function renderDirections(h) {
  return h(
    'div',
    {
      class: `${serviceName}-directions`
    },
    directions.map(direction => renderDirection.call(this, h, direction))
  )
}

function renderContentByType(h) {
  return renderDirections.call(this, h)
}

export default function(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8'
    },
    [ renderContentByType.call(this, h) ]
  )
}
