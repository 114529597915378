import { mapActions, mapGetters } from 'vuex'

import render from './render'

export default {
  name: 'ButtonFavorite',

  props: {
    id: {
      type: String,
      required: true
    },
    serviceName: {
      type: String,
      required: true
    }
  },

  computed: {
    ...mapGetters({ favorites: 'favorites/favorites' }),

    isFavorite() {
      return !!(this.favorites[this.serviceName] && ~this.favorites[this.serviceName].indexOf(this.id))
    }
  },

  mounted() {
    this.refresh(this.serviceName)
  },

  methods: {
    ...mapActions({
      toggle: 'favorites/toggle',
      refresh: 'favorites/refresh'
    })
  },

  render
}
