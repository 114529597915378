import Vue from 'vue'

function renderButtonSupport(h) {
  if (this.paymentResult === 'fail') {
    return h(
      'g-button',
      {
        props: {
          label: this.getTranslate('misc.buttons.support'),
          flat: true,
          rounded: true,
          color: 'primary'
        },
        on: {
          click: () => {
            Vue.router.push({ name: 'support' })
          }
        }
      }
    )
  }
}

export default function(h) {
  return h(
    'g-dialog',
    {
      props: {
        rounded: true,
        value: this.show,
        closeOnEsc: false,
        closeOnClick: false,
        maxWidth: 550
      },
      on: {
        input: event => {
          this.show = event
        }
      }
    },
    [
      h(
        'div',
        {
          class: 'faic pa-3'
        },
        [
          h(
            'g-icon',
            {
              class: 'mr-3',
              props: {
                value: this.paymentResult === 'success' ? 'check_circle' : 'error',
                color: this.paymentResult === 'success' ? 'success' : 'error',
                size: 48
              }
            }
          ),

          h('div', { domProps: { innerHTML: this.paymentResult && this.getTranslate(`payments.contents.payment.result.${this.paymentResult}`) } })
        ]
      ),

      h(
        'g-card-actions',
        {
          class: 'fjcfe facfe pt-0',
          slot: 'footer'
        },
        [
          renderButtonSupport.call(this, h),

          h(
            'g-button',
            {
              props: {
                label: this.getTranslate('misc.buttons.close'),
                flat: true,
                rounded: true
              },
              on: {
                click: () => {
                  this.show = false
                  Vue.router.push('/')
                }
              }
            }
          )
        ]
      )
    ]
  )
}
