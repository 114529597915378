import Vue from 'vue'

import buttonCopy from '@/components/button/copy'
import buttonFavorite from '@/components/button/favorite'

import { serviceName } from '@/components/services/tokens/utils'

export default function(h) {
  return h(
    'div',
    {
      class: {
        'grid grid-gap--8': true,
        'hover-child': this.viewport.breakpoint.mdUp
      },
      style: { 'grid-template-columns': 'repeat(3, 36px)' }
    },
    [
      h(
        buttonCopy,
        {
          class: 'ma-0',
          props: {
            value: this.value.token
          }
        }
      ),

      h(
        buttonFavorite,
        {
          class: 'ma-0',
          props: {
            id: this.value.id,
            serviceName
          }
        }
      ),

      h(
        'g-button',
        {
          class: 'ma-0',
          props: {
            flat: true,
            icon: 'edit'
          },
          on: {
            click: () => {
              Vue.router.push({
                name: `${serviceName}.single`,
                params: { id: this.value.id }
              })
            }
          }
        }
      )
    ]
  )
}
