import { SUBSCRIPTION_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import subscriptionType from '@/components/services/subscriptions/item/type'
import pricePerMonth from '@/components/services/subscriptions/item/pricePerMonth'
import expireAt from '@/components/services/subscriptions/item/expireAt'
import operators from '@/components/services/subscriptions/item/operators'
import buttons from '@/components/services/subscriptions/item/buttons'
import SingleLink from '@/components/misc/SingleLink'

function renderType(h) {
  return h(subscriptionType, { props: { value: this.value } })
}

function renderPricePerMonth(h) {
  return h(pricePerMonth, { props: { value: this.value } })
}

function renderExpireAt(h) {
  return h(expireAt, { props: { value: this.value } })
}

function renderOperators(h) {
  return h(operators, { props: { value: this.value } })
}

function renderContentByType(h) {
  switch (this.value.type) {
    case SUBSCRIPTION_TYPES['subscription.sms.sendername']: {
      return renderOperators.call(this, h)
    }
    default: {
      return renderPricePerMonth.call(this, h)
    }
  }
}

function renderOwner(h) {
  if (this.checkPermissions('advanced.users.get')) {
    return h(
      SingleLink,
      {
        props: {
          value: this.value.Owner || this.value.OwnerId,
          length: 10
        }
      }
    )
  }
}

function renderButtons(h) {
  return h(buttons, { props: { value: this.value } })
}

export default function(h) {
  return h(
    'g-card',
    {
      class: 'hover-parent hover-parent--opacity pa-2',
      props: {
        outline: true,
        rounded: true
      }
    },
    [
      h(
        'div',
        {
          class: 'grid grid-gap--8 faic',
          style: { gridTemplateColumns: '36px 1fr auto' }
        },
        [
          renderType.call(this, h),

          h(
            'div',
            {
              class: 'grid grid-gap--2 caption'
            },
            [
              renderContentByType.call(this, h),

              h(
                'div',
                {
                  class: 'grid grid-gap--8 text-overflow small faic',
                  style: { 'grid-template-columns': 'repeat(2, minmax(min-content, max-content))' }
                },
                [
                  renderExpireAt.call(this, h),
                  renderOwner.call(this, h)
                ]
              )
            ]
          ),

          renderButtons.call(this, h)
        ]
      )
    ]
  )
}
