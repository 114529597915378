import FileManager from '@/components/file/manager'
import FileDialog from '@/components/file/dialog'
import FileItemCard from '@/components/file/item/card'
import FileMenu from '@/components/file/menu'

const name = 'storage'

function renderDefaultState(h) {
  return h(
    'div',
    {
      class: {
        'ff pb-3': true,
        'fjcsb faic': this.viewport.breakpoint.mdUp,
        'fc fjcc facc': this.viewport.breakpoint.smDown
      }
    },
    [
      h(
        'div',
        {
          class: 'fc ff'
        },
        [
          h(
            'div',
            {
              class: 'body-1',
              domProps: { innerHTML: this.title }
            }
          ),

          h(
            'div',
            {
              class: 'caption text--grey',
              domProps: { innerHTML: this.hint }
            }
          )
        ]
      ),

      h(
        'g-button',
        {
          props: {
            label: this.getTranslate('misc.buttons.upload'),
            icon: 'file_upload',
            flat: true,
            rounded: true,
            color: 'primary'
          },
          on: {
            click: () => {
              this.showFileManager = true
            }
          }
        }
      )
    ]
  )
}

function renderContent(h) {
  if (this.proxy) {
    if (this.restData[name].get.state === 'ready') {
      if (this.restData[name].get.data) {
        return h(
          'div',
          {
            class: 'fc'
          },
          [
            h('div', { class: 'subtitle-1 mb-2' }, this.title),

            h(
              FileItemCard,
              {
                props: { value: this.restData[name].get.data }
              },
              [
                h(
                  FileMenu,
                  {
                    props: {
                      value: this.restData[name].get.data,
                      include: [ 'edit', 'download', 'export' ]
                    },
                    on: {
                      dialog: event => {
                        this.currentEditFile = event.currentEditFile
                        this.googleFileURL = event.googleFileURL
                        this.editDialogType = event.editDialogType
                        this.showEditDialog = true
                      }
                    }
                  },
                  [
                    h(
                      'g-list-item',
                      {
                        class: 'text--error',
                        on: {
                          click: () => {
                            this.proxy = undefined
                          }
                        }
                      },
                      [
                        h(
                          'g-icon',
                          {
                            class: 'mr-3',
                            props: {
                              value: 'clear',
                              size: 19
                            }
                          }
                        ),

                        this.getTranslate('misc.buttons.remove')
                      ]
                    )
                  ]
                )
              ]
            )
          ]
        )
      } else {
        return renderDefaultState.call(this, h)
      }
    } else {
      return h('g-progress', { props: { indeterminate: true } })
    }
  } else {
    return renderDefaultState.call(this, h)
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: 'grid'
    },
    [
      renderContent.call(this, h),

      h(
        FileManager,
        {
          props: {
            currentChosenFileId: this.proxy,
            flat: true,
            readonly: true,
            embedded: true,
            isPublic: true,
            show: this.showFileManager,
            types: [ 'doc', 'docx', 'pdf' ]
          },
          on: {
            show: event => {
              this.showFileManager = event
            },
            input: event => {
              this.proxy = event.id
              this.showFileManager = false
            },
            finish: event => {
              this.proxy = event.id
              this.showFileManager = false
            }
          }
        }
      ),

      h(
        FileDialog,
        {
          props: {
            value: this.currentEditFile,
            googleFileURL: this.googleFileURL,
            show: this.showEditDialog,
            type: this.editDialogType
          },
          on: {
            show: event => {
              this.showEditDialog = event
            },
            input: event => {
              this.currentEditFile = event
            }
          }
        }
      )
    ]
  )
}
