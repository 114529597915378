import ColorPicker from '@/components/misc/ColorPicker'

export default function(h, { path, serviceName }) {
  return h(
    'div',
    {
      class: {
        grid: true,
        'grid-cols--2': this.viewport.breakpoint.mdUp,
        'grid-cols--1': this.viewport.breakpoint.smDown
      }
    },
    Object.keys(this.proxy).map(color => {
      return h(
        ColorPicker,
        {
          props: {
            value: this.proxy[color],
            label: this.getTranslate(`${serviceName}.labels.${path}.${color}`),
            hint: this.getTranslate(`${serviceName}.hints.${path}.${color}`)
          },
          on: {
            input: event => {
              this.proxy[color] = event
            }
          }
        }
      )
    })
  )
}
