import { CONTACTLIST_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import { STATES } from '@/utils'

import { serviceName } from '@/components/services/contacts/utils'

import SpacerCell from '@/components/misc/SpacerCell'
import SingleLink from '@/components/misc/SingleLink'
import FileManager from '@/components/file/manager'
import RefreshButton from '@/components/button/refresh'
import ContactsItem from '@/components/services/contacts/item'
import ContactsFilter from '@/components/services/contacts/filter'
import ContactsDialogs from '@/components/services/contacts/dialogs'
import pagination from '@/components/misc/pagination'

function renderDivider(h, trigger) {
  if (trigger) {
    return h('g-divider')
  }

  return h('div')
}

function renderPreloader(h) {
  return h(
    'div',
    {
      class: 'fjcc facc pa-3'
    },
    [ h('g-progress', { props: { indeterminate: true } }) ]
  )
}

function renderSearchGenerator(h) {
  return h(
    'search-generator',
    {
      scopedSlots: {
        create: () => {
          return h(
            'g-button',
            {
              props: {
                icon: 'add',
                rounded: true,
                depressed: true,
                color: 'secondary'
              },
              on: {
                click: () => {
                  this.dialogType = 'contactLists.create'
                  this.showDialog = true
                }
              }
            }
          )
        },
        filter: ({ filter }) => {
          return h(
            'g-select',
            {
              props: {
                value: filter.type,
                label: this.getTranslate('contactLists.labels.type'),
                items: CONTACTLIST_TYPES.map(type => {
                  return {
                    title: this.getTranslate(`contactLists.types.${type}`),
                    value: type
                  }
                }),
                clearable: true,
                mode: 'outline',
                dense: true,
                rounded: true,
                details: false
              },
              on: {
                input: event => {
                  filter.type = event
                }
              }
            }
          )
        },
        default: ({ item }) => {
          return h(
            'g-list-item',
            {
              props: {
                active: item.id === this.$route.params.id,
                suffix: item.type === 'blacklist' ? 'not_interested' : undefined,
                to: {
                  name: `${serviceName}.single`,
                  params: { id: item.id }
                }
              }
            },
            [
              h(
                'div',
                {
                  class: 'fc'
                },
                [
                  h(SpacerCell, { props: { value: item.title } }),

                  h(
                    SingleLink,
                    {
                      class: 'text--grey caption',
                      props: {
                        value: item.Owner,
                        type: 'text'
                      }
                    }
                  )
                ]
              )
            ]
          )
        }
      }
    }
  )
}

function renderRemoveContactListButton(h) {
  return h(
    'g-button',
    {
      props: {
        label: this.viewport.breakpoint.mdUp ? this.getTranslate('contactLists.buttons.remove') : undefined,
        icon: 'delete',
        flat: true,
        rounded: true,
        color: 'error'
      },
      directives: [
        {
          name: 'g-tooltip',
          options: { value: this.isActive || this.getTranslate('contactLists.tooltips.remove') }
        }
      ],
      on: {
        click: () => {
          this.contactListId = this.restData.contactLists.get.data.id
          this.dialogType = 'contactLists.remove'
          this.showDialog = true
        }
      }
    }
  )
}

function renderAddContactButton(h) {
  return h(
    'g-button',
    {
      props: {
        label: this.viewport.breakpoint.mdUp ? this.getTranslate(`${serviceName}.buttons.add`) : undefined,
        icon: 'exposure_plus_1',
        flat: true,
        rounded: true,
        color: 'primary'
      },
      directives: [
        {
          name: 'g-tooltip',
          options: { value: this.isActive || this.getTranslate(`${serviceName}.tooltips.add`) }
        }
      ],
      on: {
        click: () => {
          this.contactListId = this.restData.contactLists.get.data.id
          this.dialogType = `${serviceName}.create`
          this.showDialog = true
        }
      }
    }
  )
}

function renderImportContactsButton(h) {
  return h(
    'g-button',
    {
      props: {
        label: this.viewport.breakpoint.mdUp ? this.getTranslate('misc.buttons.import') : undefined,
        icon: 'cloud_upload',
        flat: true,
        rounded: true
      },
      directives: [
        {
          name: 'g-tooltip',
          options: { value: this.isActive || this.getTranslate('contactLists.tooltips.import') }
        }
      ],
      on: {
        click: () => {
          this.showFileManager = true
        }
      }
    }
  )
}

function renderFilterButton(h) {
  return h(
    'g-button',
    {
      props: {
        label: this.viewport.breakpoint.mdUp ? this.getTranslate('misc.buttons.filter') : undefined,
        icon: 'filter_list',
        flat: true,
        rounded: true
      },
      on: {
        click: () => {
          this.showFilter = !this.showFilter
        }
      }
    }
  )
}

function renderContactListsContent(h) {
  if (this.restData.contactLists.get.state === STATES.ready) {
    return h(
      'div',
      {
        class: 'faic'
      },
      [
        h(
          'g-button',
          {
            props: {
              icon: 'edit',
              flat: true,
              rounded: true
            },
            on: {
              click: () => {
                this.contactListId = this.restData.contactLists.get.data.id
                this.dialogType = 'contactLists.update'
                this.showDialog = true
              }
            }
          }
        ),

        h(
          'div',
          {
            class: 'fc'
          },
          [
            h(
              'div',
              {
                class: 'subtitle-1'
              },
              this.restData.contactLists.get.data.title
            ),

            h(
              'div',
              {
                class: 'text--grey caption'
              },
              this.getTranslate(`contactLists.types.${this.restData.contactLists.get.data.type}`)
            )
          ]
        ),

        h('div', { class: 'ff' }),

        h(
          'g-card-actions',
          {
            class: 'fjcfe facfe'
          },
          [
            renderRemoveContactListButton.call(this, h),
            renderAddContactButton.call(this, h),
            renderImportContactsButton.call(this, h),
            renderFilterButton.call(this, h)
          ]
        )
      ]
    )
  } else if (this.restData.contactLists.get.state === STATES.loading) {
    return renderPreloader.call(this, h)
  }
}

function renderContactListsFilter(h) {
  if (this.showFilter) {
    return h(
      ContactsFilter, {
        props: {
          value: this.restData[serviceName].find.filter
        },
        on: {
          input: event => {
            this.restData[serviceName].find.filter = event
          }
        }
      }
    )
  }
}

function renderContactsListByViewport(h) {
  const contactsEvents = [ `${serviceName}.update`, `${serviceName}.remove` ]
  const on = contactsEvents.reduce((result, event) => {
    result[event] = e => {
      this.contactId = e
      this.dialogType = event
      this.showDialog = true
    }

    return result
  }, {})
  switch (true) {
    case this.viewport.breakpoint.smDown: {
      return h(
        'g-expansion-panels',
        {
          props: { flat: true }
        },
        this.restData[serviceName].find.data.map(item => {
          return h(
            ContactsItem,
            {
              props: { value: item },
              on
            }
          )
        })
      )
    }

    case this.viewport.breakpoint.mdUp:
    default: {
      return h(
        'g-table',
        {
          props: {
            headers: this.headers,
            hideHead: this.restData[serviceName].find.pagination.total < 1 || this.viewport.breakpoint.smDown,
            items: this.restData[serviceName].find.data,
            value: this.restData[serviceName].find.order,
            noDataText: this.getTranslate('misc.no.data.text')
          },
          on: {
            input: event => {
              this.restData[serviceName].find.order = event
            }
          },
          scopedSlots: {
            items: ({ item }) => {
              return h(
                ContactsItem,
                {
                  props: { value: item },
                  on
                }
              )
            }
          }
        }
      )
    }
  }
}

function renderContactsList(h) {
  if (this.restData[serviceName].find.state === STATES.ready) {
    if (this.restData[serviceName].find.pagination.total > 0) {
      return renderContactsListByViewport.call(this, h)
    } else {
      return h('g-empty')
    }
  } else if (this.restData[serviceName].find.state === STATES.loading) {
    return renderPreloader.call(this, h)
  }
}

function renderPagination(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8 fjcfe faic pa-2',
      style: { 'grid-template-columns': '1fr auto 36px' }
    },
    [
      h('div', { class: 'ff' }),

      h(
        pagination, {
          props: {
            value: this.restData[serviceName].find.pagination
          },
          on: {
            input: event => {
              this.restData[serviceName].find.pagination = event
            }
          }
        }
      ),

      h(
        RefreshButton,
        {
          class: 'ma-0',
          props: {
            state: this.restData[serviceName].find.state,
            shortClickMethod: () => this.rest[serviceName].find(),
            longClickMethod: () => this.rest[serviceName].find({}, { noCache: true })
          }
        }
      )
    ]
  )
}

function renderContactsContent(h) {
  return h(
    'g-card',
    {
      class: 'mt-3',
      props: { rounded: true }
    },
    [
      renderDivider.call(this, h),
      renderPagination.call(this, h, 'top'),
      renderDivider.call(this, h, this.restData[serviceName].find.pagination.total),
      renderContactsList.call(this, h),
      renderDivider.call(this, h, this.restData[serviceName].find.pagination.total),
      renderPagination.call(this, h, 'bottom')
    ]
  )
}

function renderContent(h) {
  if (this.$route.params.id) {
    return h(
      'div',
      {
        class: 'fc ff'
      },
      [
        renderContactListsContent.call(this, h),
        renderContactListsFilter.call(this, h),
        renderContactsContent.call(this, h)
      ]
    )
  }

  return h('div')
}

function renderContactsDialogs(h) {
  return h(
    ContactsDialogs,
    {
      props: {
        id: this.contactId || this.contactListId,
        type: this.dialogType,
        show: this.showDialog,
        ListId: this.restData.contactLists.get.data && this.restData.contactLists.get.data.id
      },
      on: {
        show: event => {
          if (event === false) {
            this.contactId = undefined
            this.contactListId = undefined
            this.dialogType = undefined
          }
          this.showDialog = event
        }
      }
    }
  )
}

function renderImportDialogBody(h) {
  switch (this.importContactsResult.state) {
    case 'loading': {
      return [
        h(
          'g-progress',
          {
            class: 'mr-3',
            props: {
              indeterminate: true,
              size: 24
            }
          }
        ),

        this.getTranslate(`${serviceName}.contents.import.loading`)
      ]
    }
    case 'success': {
      return [
        h(
          'g-icon',
          {
            class: 'mr-3',
            props: {
              value: 'done',
              color: 'success'
            }
          }
        ),

        h(
          'div',
          {
            domProps: { innerHTML: this.getTranslate(`${serviceName}.contents.import.success`, { count: this.importContactsResult.total }) }
          }
        )
      ]
    }
    case 'error': {
      return [
        h(
          'g-icon',
          {
            class: 'mr-3',
            props: {
              value: 'info',
              color: 'warning'
            }
          }
        ),

        h(
          'div',
          { domProps: { innerHTML: this.getTranslate(`${serviceName}.contents.import.error`) } }
        )
      ]
    }
  }
}

function renderImportDialogFooter(h) {
  switch (this.importContactsResult.state) {
    case 'success':
    case 'error': {
      return h(
        'g-card-actions',
        {
          class: 'fjcfe facfe',
          slot: 'footer'
        },
        [
          h(
            'g-button',
            {
              props: {
                label: this.getTranslate('misc.buttons.close'),
                flat: true,
                rounded: true
              },
              on: {
                click: () => {
                  this.showImportContacts = false
                  this.importContactsResult.state = undefined
                  this.importContactsResult.total = 0
                }
              }
            }
          )
        ]
      )
    }
  }
}

function renderImportDialog(h) {
  return h(
    'g-dialog',
    {
      props: {
        rounded: true,
        value: this.showImportContacts,
        closeOnClick: false,
        closeOnEsc: false,
        maxWidth: 500
      },
      on: {
        input: event => {
          this.showImportContacts = event
        }
      }
    },
    [
      h('div', { class: 'faic pa-3' }, renderImportDialogBody.call(this, h)),

      renderImportDialogFooter.call(this, h)
    ]
  )
}

function renderFileManager(h) {
  return h(
    FileManager,
    {
      props: {
        show: this.showFileManager,
        flat: true,
        readonly: true,
        embedded: true,
        types: [ 'csv', 'xls', 'xlsx' ]
      },
      on: {
        show: event => {
          this.showFileManager = event
        },
        input: event => {
          this.showFileManager = false
          this.showImportContacts = true
          this.importContacts(event.id)
        }
      }
    }
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: 'faifs'
    },
    [
      renderSearchGenerator.call(this, h),
      renderContent.call(this, h),
      renderContactsDialogs.call(this, h),
      renderImportDialog.call(this, h),
      renderFileManager.call(this, h)
    ]
  )
}
