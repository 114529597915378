export default {
  titles: {
    dialog: {
      remove: 'Подтверждение'
    }
  },

  contents: {
    dialog: {
      remove: 'Подтвердите удаление версии: {version}'
    }
  },

  labels: {
    mcc: 'MCC',
    mnc: 'MNC',
    step: 'Степ',
    trafficType: 'Тип трафика',
    sender: 'Отправитель',
    price: 'Цена',
    version: 'Версия'
  },

  traffic: {
    identity: 'Identity',
    marketing: 'Реклама',
    service: 'Сервисное'
  },

  hints: {
    notFound: 'Тарифные цены не найдены. Нажмите кнопку ниже чтобы импортировать их.'
  }
}
