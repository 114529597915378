import generatorContent from '@/components/generator/content'

function renderRemoveFieldButton(h, item, index, length, removeField) {
  return h(
    'g-button',
    {
      class: 'ma-0',
      props: {
        icon: 'remove',
        flat: true,
        disabled: !item.key && !item.value,
        color: 'error'
      },
      on: {
        click: () => {
          removeField(index)
        }
      }
    }
  )
}
function renderAddFieldButton(h, item, index, length, addField) {
  if (index === length - 1) {
    return h(
      'g-button',
      {
        class: 'ma-0',
        props: {
          icon: 'add',
          flat: true,
          disabled: !(item.key && item.value),
          color: 'success'
        },
        on: {
          click: () => {
            addField()
          }
        }
      }
    )
  }
}

function renderGenerator(h, { path, serviceName }) {
  return h(
    generatorContent,
    {
      props: {
        value: this.proxy,
        toObject: true,
        defaultValue: {
          key: undefined,
          value: undefined
        }
      },
      on: {
        input: event => {
          this.proxy = event
        }
      },
      scopedSlots: {
        default: ({ item, index, length, addField, removeField }) => {
          return h(
            'div',
            {
              class: 'grid grid-gap--8',
              style: { 'grid-template-columns': '36px 1fr 1fr 36px' }
            },
            [
              renderRemoveFieldButton.call(this, h, item, index, length, removeField),

              h(
                'g-text-field',
                {
                  props: {
                    value: item.key,
                    label: this.getTranslate(`${serviceName}.labels.${path}.domain`),
                    mode: 'outline',
                    rounded: true,
                    dense: true,
                    details: false,
                    clearable: true
                  },
                  on: {
                    input: event => {
                      item.key = event
                    }
                  }
                }
              ),

              h(
                'g-select',
                {
                  props: {
                    value: item.value,
                    label: this.getTranslate(`${serviceName}.labels.${path}.user`),
                    service: 'users',
                    query: { isActive: true },
                    itemTitle: 'username',
                    autocomplete: true,
                    mode: 'outline',
                    rounded: true,
                    dense: true,
                    details: false,
                    clearable: true
                  },
                  on: {
                    input: event => {
                      item.value = event
                    }
                  }
                }
              ),

              renderAddFieldButton.call(this, h, item, index, length, addField)
            ]
          )
        }
      }
    }
  )
}

function renderSaveButton(h) {
  return h(
    'g-button',
    {
      class: 'ma-0',
      props: {
        flat: true,
        rounded: true,
        label: this.getTranslate('misc.buttons.save'),
        icon: 'save',
        color: 'primary'
      },
      on: {
        click: () => {
          this.$emit('input', this.proxy)
        }
      }
    }
  )
}

function renderFooter(h) {
  return h(
    'div',
    {
      class: 'fjcfe facfe'
    },
    [ renderSaveButton.call(this, h) ]
  )
}

export default function(h, options) {
  return h(
    'div',
    {
      class: 'grid'
    },
    [
      renderGenerator.call(this, h, options),
      renderFooter.call(this, h, options)
    ]
  )
}
