import { generateServices } from '@/utils'

import { serviceName } from '@/components/services/subscriptions/utils'

import render from './render'

export default {
  name: serviceName,

  mixins: [
    generateServices({
      name: serviceName,

      get: false,
      remove: false,
      update: false
    })
  ],

  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      loading: false,
      showDialog: false,
      subscription: undefined
    }
  },

  watch: {
    value: {
      handler() {
        this.get()
      },
      deep: true
    }
  },

  mounted() {
    this.get()
  },

  methods: {
    async get() {
      if (this.value && this.value.id) {
        this.restData[serviceName].create.data.SendernameId = this.value.id
        this.restData[serviceName].create.data.OwnerId = this.value.OwnerId
        const [ result ] = await this.rest[serviceName].find({ query: { SendernameId: this.value.id } })
        this.subscription = result
      }
    }
  },

  render
}
