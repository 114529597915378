import render from './render'

export default {
  name: 'UsersRequisitesItem',

  props: {
    value: {
      type: Object,
      default: () => ({})
    },

    type: {
      type: String,
      default: 'table',
      validator: value => {
        return ~[ 'panel', 'table' ].indexOf(value)
      }
    }
  },

  render
}
