import { SUBSCRIPTION_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import subscriptionType from '@/components/services/subscriptions/item/type'
import pricePerMonth from '@/components/services/subscriptions/item/pricePerMonth'
import expireAt from '@/components/services/subscriptions/item/expireAt'
import operators from '@/components/services/subscriptions/item/operators'
import buttons from '@/components/services/subscriptions/item/buttons'
import SingleLink from '@/components/misc/SingleLink'

function renderType(h) {
  return h(subscriptionType, { props: { value: this.value } })
}

function renderPricePerMonth(h) {
  return h(pricePerMonth, { props: { value: this.value } })
}

function renderExpireAt(h) {
  return h(expireAt, { props: { value: this.value } })
}

function renderOperators(h) {
  return h(operators, { props: { value: this.value } })
}

function renderOwner(h) {
  if (this.checkPermissions('advanced.users.get')) {
    return h(
      SingleLink,
      {
        props: {
          value: this.value.Owner || this.value.OwnerId
        }
      }
    )
  }
}

function renderContentByType(h) {
  switch (this.value.type) {
    case SUBSCRIPTION_TYPES['subscription.sms.sendername']: {
      return renderOperators.call(this, h)
    }
    default: {
      return renderPricePerMonth.call(this, h)
    }
  }
}

function renderTypeCell(h) {
  return h(
    'td',
    {
      class: 'px-2'
    },
    [ renderType.call(this, h) ]
  )
}

function renderCellByType(h) {
  return h(
    'td',
    {
      class: 'px-2'
    },
    [ renderContentByType.call(this, h) ]
  )
}

function renderExpireAtCell(h) {
  return h(
    'td',
    {
      class: 'px-2'
    },
    [ renderExpireAt.call(this, h) ]
  )
}

function renderOwnerCell(h) {
  return h(
    'td',
    {
      class: 'px-2'
    },
    [ renderOwner.call(this, h) ]
  )
}

function renderButtonsCell(h) {
  return h(
    'td',
    {
      class: 'px-2 w--100'
    },
    [
      h(
        'div',
        {
          class: 'fjcfe'
        },
        [ h(buttons, { props: { value: this.value } }) ]
      )
    ]
  )
}

export default function(h) {
  return h(
    'tr',
    {
      class: 'hover-parent hover-parent--opacity'
    },
    [
      renderTypeCell.call(this, h),
      renderCellByType.call(this, h),
      renderExpireAtCell.call(this, h),
      renderOwnerCell.call(this, h),
      renderButtonsCell.call(this, h)
    ]
  )
}
