import proxy from '@sigma-legacy-libs/g-proxy'

import render from './render'

export default {
  name: 'NotificationTemplate',

  mixins: [ proxy({ type: 'object' }) ],

  props: {
    service: String,
    path: String,
    prop: String
  },

  data() {
    return {
      showDialog: false
    }
  },

  methods: {
    watchValueHandler() {
      return
    },
    watchProxyHandler() {
      return
    }
  },

  render
}
