import { unVue } from '@sigma-legacy-libs/unvue'
import { EMPTY_REQUISITES, EMPTY_USERS } from '@sigma-legacy-libs/essentials/lib/constants'

import { generateServices, globalErrorHandler, globalErrorProcessor } from '@/utils'

import { serviceName } from '@/components/services/requisites/utils'

import render from './render'

const _ = { defaultsDeep: require('lodash/defaultsDeep') }

export default {
  name: 'RequisitesDialogs',

  mixins: [
    generateServices({
      name: serviceName,

      inputFilter(result) {
        _.defaultsDeep(result, unVue(EMPTY_REQUISITES)) // потому что с сервера приходит урезанный payload

        return result
      },

      find: false
    })
  ],

  props: {
    id: String,
    type: String,

    show: {
      type: Boolean,
      default: false
    },

    OwnerId: String,
    User: {
      type: Object,
      default: () => unVue(EMPTY_USERS)
    }
  },

  watch: {
    id() {
      this.get()
    }
  },

  mounted() {
    this.get()
  },

  methods: {
    async get() {
      if (this.checkPermissions(`advanced.${serviceName}.get`, [ 'me', 'reseller', true ])) {
        if (this.id) {
          try {
            await this.rest[serviceName].get(this.id)
          } catch (error) {
            globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
          }
        }
      }
    },
    async create() {
      if (this.checkPermissions(`advanced.${serviceName}.create`, [ 'me', 'reseller', true ])) {
        if (this.restData[serviceName].create.isValid) {
          try {
            const result = await this.rest[serviceName].create(Object.assign({ OwnerId: this.OwnerId }, this.restData[serviceName].create.data))
            if (result) {
              this.$emit('show', false)
            }
          } catch (error) {
            globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
          }
        }
      }
    },
    async update() {
      if (this.checkPermissions(`advanced.${serviceName}.update`, [ 'me', 'reseller', true ])) {
        if (this.restData[serviceName].update.isValid) {
          try {
            const result = await this.rest[serviceName].update(this.restData[serviceName].get.data.id, this.restData[serviceName].get.data)
            if (result) {
              this.$emit('show', false)
            }
          } catch (error) {
            globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
          }
        }
      }
    },
    async remove() {
      if (this.checkPermissions(`advanced.${serviceName}.remove`, [ 'me', 'reseller', true ])) {
        if (this.id) {
          try {
            const result = await this.rest[serviceName].remove(this.id)
            if (result) {
              this.$emit('show', false)
            }
          } catch (error) {
            globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
          }
        }
      }
    }
  },

  render
}
