export default function(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8',
      style: { 'grid-template-columns': `repeat(${this.proxy.length}, 1fr)` }
    },
    this.proxy.map((price, index) => {
      return h(
        'g-text-field',
        {
          props: {
            value: price,
            suffix: this.getTranslate(`currency.${this.meta.currency}.symbol.unicode`) + ' / ' + (index ? this.getTranslate('tariffs.hints.perSecond') : this.proxy.length === 1 ? this.getTranslate('tariffs.hints.default') : this.getTranslate('tariffs.hints.perCall')),
            type: 'number',
            min: 0,
            step: 'any',
            mode: 'outline',
            dense: true,
            rounded: true,
            details: false
          },
          on: {
            input: event => {
              this.proxy[index] = event
            }
          }
        }
      )
    })
  )
}
