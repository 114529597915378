import Conditions from '@sigma-legacy-libs/essentials/lib/utils/Conditions'

import rulesRow from '@/components/misc/rules/conditions/row'
import ruleDialog from '@/components/misc/rules/conditions/dialog'

import { serviceName } from '@/components/services/tariffs/utils'

function renderDialog(h) {
  if (this.proxy[this.index]) {
    return h(
      ruleDialog,
      {
        props: {
          show: this.showDialog,
          value: this.proxy[this.index].value,
          tag: this.tag,
          tagEdit: true,
          tagDisabled: this.proxy.map(({ tag }) => tag),
          readonly: this.readonly
        },
        on: {
          dialog: event => {
            this.showDialog = event
          },
          input: event => {
            this.proxy[this.index].value = event
          },
          tag: event => {
            this.proxy[this.index].tag = event
            this.tag = event
          }
        }
      }
    )
  }
}

function renderRemoveButton(h, index) {
  if (!this.readonly) {
    return h(
      'g-button',
      {
        class: 'ma-0',
        props: {
          flat: true,
          icon: 'remove',
          color: 'error'
        },
        on: {
          click: () => {
            this.remove(index)
          }
        }
      }
    )
  }
}

function renderAddButton(h, { tag, value }, index) {
  if (!this.readonly) {
    if (tag && value && index === this.proxy.length - 1) {
      return h(
        'g-menu',
        {
          props: { rounded: true }
        },
        [
          h(
            'g-button',
            {
              class: 'ma-0',
              props: {
                flat: true,
                icon: 'add',
                color: 'success'
              },
              slot: 'activator'
            }
          ),

          h(
            'g-list',
            {
              props: {
                dense: true,
                rounded: true
              }
            },
            Conditions.tagsOnly.map(tag => {
              return h(
                'g-list-item',
                {
                  props: {
                    label: this.getTranslate(`conditions.tags.${tag}`),
                    disabled: this.proxy.some(condition => condition.tag === tag)
                  },
                  on: {
                    click: () => {
                      this.add(tag)
                    }
                  }
                }
              )
            })
          )
        ]
      )
    }
  }
}

function renderRule(h, condition, index) {
  return h(
    rulesRow,
    {
      props: {
        tag: condition.tag,
        value: condition.value,
        type: this.type,
        readonly: this.readonly
      },
      on: {
        dialog: event => {
          this.tag = condition.tag
          this.index = index
          this.showDialog = event
        },
        clear: () => {
          condition.value = undefined
        }
      }
    }
  )
}

function renderConditions(h) {
  return h(
    'div',
    {
      class: `${serviceName}-rules__conditions`
    },
    this.proxy.map((condition, index) => {
      return h(
        'div',
        {
          class: {
            [`${serviceName}-rules__condition`]: true,
            [`${serviceName}-rules__condition--readonly`]: this.readonly
          },
          key: `condition-${index}`
        },
        [
          renderRemoveButton.call(this, h, index),
          renderRule.call(this, h, condition, index),
          renderAddButton.call(this, h, condition, index)
        ]
      )
    })
  )
}

export default function(h) {
  if (Array.isArray(this.proxy) && this.proxy.length) {
    return h(
      'div',
      {
        class: 'fc ff'
      },
      [
        renderConditions.call(this, h),
        renderDialog.call(this, h)
      ]
    )
  }
}
