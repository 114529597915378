import render from './render'

export default {
  name: 'Item',

  props: {
    value: {
      type: Object,
      default: () => ({})
    },

    type: {
      type: String,
      default: 'card',
      validator: value => {
        return !!~[ 'row', 'panel' ].indexOf(value)
      }
    },

    ctx: null
  },

  render
}
