import titleDivider from '@/components/title/divider'
import form from '@/components/services/tariffRatesUpdating/form'
import card from '@/components/services/tariffRatesUpdating/item/card'

import { STATES } from '@/utils'

import { serviceName } from '@/components/services/tariffRatesUpdating/utils'

function renderDialog(h) {
  return h(
    'g-dialog',
    {
      props: {
        value: this.showDialog,
        rounded: true,
        width: 500
      },
      on: {
        input: value => {
          this.showDialog = value
        }
      }
    },
    [
      h(
        'div',
        {
          class: 'pa-2'
        },
        [
          h(
            form,
            {
              props: {
                value: this.restData[serviceName].create.data
              },
              on: {
                validation: event => {
                  this.restData[serviceName].create.isValid = event
                },
                input: event => {
                  this.restData[serviceName].create.data = event
                }
              }
            }
          )
        ]
      ),

      h(
        'div',
        {
          class: 'grid grid-gap--8 pa-2',
          style: { gridTemplateColumns: '1fr repeat(2, minmax(min-content, max-content))' },
          slot: 'footer'
        },
        [
          h('div'),
          h(
            'g-button',
            {
              class: 'ma-0',
              props: {
                flat: true,
                rounded: true,
                label: this.getTranslate('misc.buttons.cancel')
              },
              on: {
                click: () => {
                  this.showDialog = false
                }
              }
            }
          ),
          h(
            'g-button',
            {
              class: 'ma-0',
              props: {
                depressed: true,
                rounded: true,
                loading: this.restData[serviceName].create.state === STATES.loading,
                disabled: !this.restData[serviceName].create.isValid || this.restData[serviceName].create.state === STATES.loading,
                color: 'primary',
                label: this.getTranslate('misc.buttons.create')
              },
              on: {
                click: async () => {
                  await this.rest[serviceName].create(this.restData[serviceName].create.data)
                  this.showDialog = false
                }
              }
            }
          )
        ]
      )
    ]
  )
}

function renderPreLoader(h) {
  return h(
    'g-progress',
    {
      props: {
        indeterminate: true
      }
    }
  )
}

function renderParent(h) {
  if (this.restData[serviceName].find.state === STATES.loading) {
    return renderPreLoader.call(this, h)
  }

  if (this.parent) {
    return h(
      card,
      {
        props: {
          value: this.parent
        }
      }
    )
  } else {
    return h(
      'div',
      {
        class: 'fjcc'
      },
      [
        h(
          'g-button',
          {
            class: 'ma-0',
            props: {
              flat: true,
              rounded: true,
              color: 'primary',
              label: this.getTranslate(`${serviceName}.buttons.addParent`)
            },
            on: {
              click: () => {
                this.createParent()
              }
            }
          }
        )
      ]
    )
  }
}

function renderDivider(h) {
  return h(
    titleDivider,
    {
      props: {
        value: this.getTranslate(`${serviceName}.titles.child`)
      }
    }
  )
}

function renderAddChildButton(h) {
  return h(
    'div',
    {
      class: 'fjcc'
    },
    [
      h(
        'g-button',
        {
          class: 'ma-0',
          props: {
            flat: true,
            rounded: true,
            color: 'primary',
            label: this.getTranslate(`${serviceName}.buttons.addChild`)
          },
          on: {
            click: () => {
              this.createChild()
            }
          }
        }
      )
    ]
  )
}

function renderChild(h) {
  if (this.restData[serviceName].find.state === STATES.loading) {
    return renderPreLoader.call(this, h)
  }

  if (Array.isArray(this.child) && this.child.length > 0) {
    return this.child.map(child => {
      return h(
        card,
        {
          props: {
            value: child
          }
        }
      )
    })
  }

  return h('div')
}

export default function(h) {
  return h(
    'g-card',
    {
      class: 'grid grid-gap--8 fjcc pa-2 mt-2',
      props: {
        rounded: true,
        outline: true,
        title: this.getTranslate(`${serviceName}.title`)
      }
    },
    [
      renderParent.call(this, h),
      renderDivider.call(this, h),
      renderAddChildButton.call(this, h),
      renderChild.call(this, h),
      renderDialog.call(this, h)
    ]
  )
}
