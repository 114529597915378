export default {
  title: 'Правила маршрутизаций',

  titles: {
    create: 'Создать правило маршрутизаций'
  },

  routingRule: 'Правило маршрутизации',
  routingRules: 'Правила маршрутизаций',
  one: 'Правило маршрутизации',
  many: 'Правила маршрутизаций',
  condition: 'Условие',
  conditions: 'Условия',
  ruleType: 'Типа правила',
  percentage: 'Процент',

  labels: {
    title: 'Название правила',
    type: 'Тип',
    routingRule: 'Правило',
    condition: 'Условие',
    providerGroup: 'Группа провайдеров',
    percentage: 'Процент',
    search: 'Поиск',
    textToCheck: 'Текст для проверки',
    group: {
      pattern: 'Шаблон группы'
    },
    add: {
      condition: 'Добавить условие'
    },
    phone: 'Телефон'
  },

  dialogs: {
    new: 'Новое правило маршрутизации'
  },

  rules: {
    payload: {
      text: 'Текст',
      sender: 'Отправитель',
      recipient: 'Получатель'
    },
    meta: {
      _recipientData: {
        code: 'Код оператора',
        group: 'Группа оператора'
      }
    }
  },

  hints: {
    notFound: 'Правила марщрутизаций не найдены',
    wrongType: 'Указан неподдерживаемый тип сообщений.',
    willBeCreated: 'Будет создан',
    noRule: 'Условие ещё не выбрано. Нажмите на эту строку чтобы выбрать.',
    noRules: 'Не выбрано ни одного условия. Нажмите добавить условие.',
    click: 'Нажмите чтобы отредактировать.'
  },

  tooltips: {
    rules: 'Список правил для маршрутизации сообщения.',
    conditions: 'Список условий для маршрутизации сообщения.',
    providerGroups: 'Список групп провайдеров.',
    isActive: 'Активировать или деактивировать правило.',
    add: {
      condition: 'Добавить условие'
    },
    favorites: 'Сделать правило маршрутизации избранным'
  },

  subtitles: {
    rules: 'Правила',
    conditions: 'Условия',
    providerGroups: 'Группы провайдеров'
  },

  buttons: {
    add: {
      condition: 'Добавить условие',
      providerGroup: 'Добавить группу провайдеров'
    },
    routingRule: 'Выберите правило'
  },

  snackbars: {
    created: 'Правило маршрутизации успешно создано.',
    updated: 'Правило маршрутизации успешно обновлено.',
    removed: 'Правило маршрутизации успешно удалено.'
  },

  errors: {
    regexp: 'Ошибка в регулярном выражении',
    phone: 'Телефон не найден'
  }
}
