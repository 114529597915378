function renderSumField(h) {
  return h(
    'g-text-field',
    {
      props: {
        value: this.sum,
        label: this.getTranslate('payments.labels.sum'),
        suffix: this.getTranslate(),
        type: 'number',
        step: 1,
        min: 0,
        mode: 'outline-label',
        dense: true,
        rounded: true,
        required: true,
        clearable: true,
        details: false
      },
      on: {
        input: event => {
          this.sum = event
        }
      }
    }
  )
}

function renderPaymentTypeField(h) {
  return h(
    'g-select',
    {
      props: {
        value: this.paymentType,
        label: this.getTranslate('payments.labels.paymentType'),
        items: [ 'PC', 'AC', 'MC' ].map(value => {
          return {
            title: this.getTranslate(`payments.yooMoney.paymentTypes.${value}`),
            value
          }
        }),
        mode: this.viewport.breakpoint.mdUp ? 'outline-label' : 'outline',
        dense: true,
        rounded: true,
        required: true,
        clearable: true,
        details: false
      },
      on: {
        input: event => {
          this.paymentType = event
        }
      }
    }
  )
}

function renderWarning(h) {
  return h(
    'div',
    {
      class: 'text--grey caption plpx-12'
    },
    this.getTranslate('payments.hints.delay')
  )
}

function renderHiddenFields(h) {
  return Object.keys(this.payload).map(field => {
    return h(
      'input',
      {
        attrs: {
          hidden: true,
          value: this.payload[field],
          name: field
        }
      }
    )
  })
}

function renderRequestButton(h) {
  return h(
    'g-button',
    {
      class: 'ma-0',
      props: {
        rounded: true,
        depressed: true,
        color: 'primary',
        label: this.getTranslate('misc.buttons.pay'),
        loading: this.loading,
        disabled: !this.validation || this.loading,
        type: 'submit'
      }
    }
  )
}

export default function(h) {
  return h(
    'form',
    {
      class: 'grid grid-gap--8 pa-2',
      attrs: {
        action: 'https://yoomoney.ru/quickpay/confirm.xml',
        method: 'post'
      }
    },
    [
      renderHiddenFields.call(this, h),

      h(
        'div',
        {
          class: {
            'grid grid-gap--8': true,
            'grid-cols--2': this.viewport.breakpoint.mdUp
          }
        },
        [
          renderSumField.call(this, h),
          renderPaymentTypeField.call(this, h)
        ]
      ),

      renderWarning.call(this, h),

      h(
        'div',
        {
          class: 'fjcfe'
        },
        [ renderRequestButton.call(this, h) ]
      )
    ]
  )
}
