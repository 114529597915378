import Vue from 'vue'

import rules from '@/components/services/tariffs/rules'
import defaultRule from '@/components/services/tariffs/defaultRule'

import { serviceName } from '@/components/services/tariffs/utils'

function renderRules(h) {
  if (this.proxy.data.rules) {
    return h(
      rules,
      {
        props: {
          value: this.proxy.data.rules,
          meta: {
            type: this.type,
            currency: this.proxy.currency
          }
        },
        on: {
          input: event => {
            this.proxy.data.rules = event
          }
        }
      }
    )
  }
}

function renderDefaultRule(h) {
  if (this.proxy._defaultRule) {
    return h(
      defaultRule,
      {
        props: {
          value: this.proxy._defaultRule,
          meta: {
            type: this.type,
            currency: this.proxy.currency
          }
        },
        on: {
          input: event => {
            this.proxy._defaultRule = event
          },
          remove: () => {
            Vue.delete(this.proxy, '_defaultRule')
          }
        }
      }
    )
  }
}

function renderRefund(h) {
  if (this.proxy.type && this.proxy.type.split('.')[0] === 'sendings') {
    return h(
      'g-switch',
      {
        props: {
          value: this.proxy.data.options.refundOnFailed,
          label: this.getTranslate(`${serviceName}.labels.refundOnFailed`)
        },
        on: {
          input: event => {
            this.proxy.data.options.refundOnFailed = event
          }
        }
      }
    )
  }
}

function renderNoPayments(h) {
  return h(
    'g-switch',
    {
      props: {
        value: this.proxy.data.options.noPayments,
        label: this.getTranslate(`${serviceName}.labels.noPayments`)
      },
      on: {
        input: event => {
          this.proxy.data.options.noPayments = event
        }
      }
    }
  )
}

function renderAdditional(h) {
  if (this.proxy.data.options) {
    return h(
      'div',
      {
        class: {
          'grid grid-gap--8 border border--dashed border--rounded pa-2': true,
          'grid-cols--2': this.viewport.breakpoint.mdUp
        }
      },
      [
        renderRefund.call(this, h),
        renderNoPayments.call(this, h)
      ]
    )
  }
}

function renderCommentField(h) {
  if (this.proxy.data.hidden) {
    return h(
      'g-textarea',
      {
        props: {
          value: this.proxy.data.hidden.comment,
          label: this.getTranslate(`${serviceName}.labels.comment`),
          mode: 'outline',
          grow: true,
          dense: true,
          rounded: true,
          details: false
        },
        on: {
          input: event => {
            this.proxy.data.hidden.comment = event
          }
        }
      }
    )
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8'
    },
    [
      renderRules.call(this, h),
      renderDefaultRule.call(this, h),
      renderAdditional.call(this, h),
      renderCommentField.call(this, h)
    ]
  )
}
