export default {
  title: 'YClients',

  titles: {
    create: 'Создать салон'
  },

  labels: {
    salon: 'ID cалона',
    token: 'Токен',
    isActive: 'Активен'
  },

  tooltips: {
    filter: {
      isActive: 'Показать неактивные записи'
    }
  },

  snackbars: {
    created: 'Салон создан',
    updated: 'Салон обновлен',
    removed: 'Салон удален'
  }
}
