import { TASK_FREQUENCY, TASK_STATES } from '@sigma-legacy-libs/essentials/lib/constants'

const _ = { get: require('lodash/get') }

export default function(h) {
  const frequency = _.get(this.value, 'data.frequency')
  if (frequency) {
    return h(
      'g-menu',
      {
        props: {
          disabled: this.value.state === TASK_STATES.completed,
          placement: 'bottom'
        }
      },
      [
        h(
          'g-button',
          {
            props: {
              flat: true,
              rounded: true,
              icon: 'keyboard_arrow_down',
              label: this.getTranslate(`tasks.frequencies.${frequency}`),
              disabled: this.value.state === TASK_STATES.completed
            },
            slot: 'activator'
          }
        ),

        h(
          'g-list',
          {
            props: { dense: this.viewport.breakpoint.mdUp }
          },
          TASK_FREQUENCY.reduce((result, frequency) => {
            if (frequency !== TASK_FREQUENCY.once) {
              result.push(
                h(
                  'g-list-item',
                  {
                    props: { label: this.getTranslate(`tasks.frequencies.${frequency}`) },
                    on: {
                      click: () => {
                        this.changeFrequency(this.value.id, frequency)
                      }
                    }
                  }
                )
              )
            }

            return result
          }, [])
        )
      ]
    )
  }
}
