import { allowedSendingTypes } from '@/utils'

import RowWithMessageTypeIcon from '@/components/misc/RowWithMessageTypeIcon'

function renderAllowDuplicatesField(h, { path, serviceName }) {
  return h(
    'g-switch',
    {
      props: {
        value: this.proxy.allowDuplicates,
        label: this.getTranslate(`${serviceName}.labels.${path}.allowDuplicates`)
      },
      on: {
        input: event => {
          this.proxy.allowDuplicates = event
        }
      }
    }
  )
}

function renderSenderNamesCheckField(h, { path, serviceName }) {
  return h(
    'g-select',
    {
      props: {
        value: this.proxy.sendernamesCheck,
        label: this.getTranslate(`${serviceName}.labels.${path}.sendernamesCheck`),
        items: allowedSendingTypes.map(type => ({
          title: this.getTranslate(`sendings.types.${type}`),
          value: type
        })),
        mode: 'outline-label',
        dense: true,
        rounded: true,
        multiple: true,
        clearable: true,
        details: false
      },
      on: {
        input: event => {
          this.proxy.sendernamesCheck = event
        }
      },
      scopedSlots: {
        selection: ({ item, removeByValue }) => {
          return h(
            RowWithMessageTypeIcon,
            {
              props: {
                value: item.value,
                title: item.title,
                as: 'chip',
                remove: () => {
                  removeByValue(item)
                }
              }
            }
          )
        },
        item: ({ item }) => {
          return h(
            RowWithMessageTypeIcon,
            {
              props: {
                value: item.value,
                title: item.title
              }
            }
          )
        }
      }
    }
  )
}
function renderPatternsCheckField(h, { path, serviceName }) {
  if (this.checkPermissions('advanced.patterns.get', [ 'reseller', true ])) {
    return h(
      'g-select',
      {
        props: {
          value: this.proxy.patternsCheck,
          label: this.getTranslate(`${serviceName}.labels.${path}.patternsCheck`),
          items: allowedSendingTypes.map(type => ({
            title: this.getTranslate(`sendings.types.${type}`),
            value: type
          })),
          mode: 'outline-label',
          dense: true,
          rounded: true,
          multiple: true,
          clearable: true,
          details: false
        },
        on: {
          input: event => {
            this.proxy.patternsCheck = event
          }
        },
        scopedSlots: {
          selection: ({ item, removeByValue }) => {
            return h(
              RowWithMessageTypeIcon,
              {
                props: {
                  value: item.value,
                  title: item.title,
                  as: 'chip',
                  remove: () => {
                    removeByValue(item)
                  }
                }
              }
            )
          },
          item: ({ item }) => {
            return h(
              RowWithMessageTypeIcon,
              {
                props: {
                  value: item.value,
                  title: item.title
                }
              }
            )
          }
        }
      }
    )
  }
}

function renderBalanceField(h, { path, serviceName }) {
  return h(
    'g-text-field',
    {
      props: {
        value: this.proxy.balance,
        label: this.getTranslate(`${serviceName}.labels.${path}.balance`),
        type: 'number',
        step: 'any',
        mode: 'outline-label',
        dense: true,
        rounded: true,
        clearable: true,
        details: false
      },
      on: {
        input: event => {
          this.proxy.balance = event
        }
      }
    }
  )
}

function renderSelectTariffsField(h, { path, serviceName }) {
  if (this.checkPermissions('advanced.tariffs.get', [ 'reseller', true ])) {
    return h(
      'g-select',
      {
        props: {
          value: this.proxy.tariffs,
          label: this.getTranslate(`${serviceName}.labels.${path}.tariffs`),
          service: 'tariffs',
          mode: 'outline-label',
          picks: [ 'id', 'title', 'source' ],
          dense: true,
          rounded: true,
          multiple: true,
          autocomplete: true,
          clearable: true,
          details: false
        },
        on: {
          input: event => {
            this.proxy.tariffs = event
          }
        },
        scopedSlots: {
          selection: ({ item, removeByValue }) => {
            return h(
              RowWithMessageTypeIcon,
              {
                props: {
                  value: item.source ? item.source.split('.')[1] : undefined,
                  title: item.title,
                  as: 'chip',
                  remove: () => {
                    removeByValue(item)
                  }
                }
              }
            )
          },
          item: ({ item }) => {
            return h(
              RowWithMessageTypeIcon,
              {
                props: {
                  value: item.source ? item.source.split('.')[1] : undefined,
                  title: item.title
                }
              }
            )
          }
        }
      }
    )
  }
}

function renderSelectRoutingGroupsField(h, { path, serviceName }) {
  if (this.checkPermissions('advanced.routingGroups.get', [ 'reseller', true ])) {
    return h(
      'g-select',
      {
        props: {
          value: this.proxy.routingGroups,
          label: this.getTranslate(`${serviceName}.labels.${path}.routingGroups`),
          service: 'routingGroups',
          picks: [ 'id', 'title', 'type' ],
          multiple: true,
          autocomplete: true,
          mode: 'outline-label',
          dense: true,
          rounded: true,
          clearable: true,
          details: false
        },
        on: {
          input: event => {
            this.proxy.routingGroups = event
          }
        },
        scopedSlots: {
          selection: ({ item, removeByValue }) => {
            return h(
              RowWithMessageTypeIcon,
              {
                props: {
                  value: item.type,
                  title: item.title,
                  as: 'chip',
                  remove: () => {
                    removeByValue(item)
                  }
                }
              }
            )
          },
          item: ({ item }) => {
            return h(
              RowWithMessageTypeIcon,
              {
                props: {
                  value: item.type,
                  title: item.title
                }
              }
            )
          }
        }
      }
    )
  }
}

function renderSelectSenderNamesField(h, { path, serviceName }) {
  if (this.checkPermissions('advanced.sendernames.get', [ 'reseller', true ])) {
    return h(
      'g-select',
      {
        props: {
          value: this.proxy.sendernames,
          label: this.getTranslate(`${serviceName}.labels.${path}.sendernames`),
          service: 'sendernames',
          picks: [ 'id', 'name', 'type' ],
          itemTitle: 'name',
          mode: 'outline-label',
          dense: true,
          multiple: true,
          autocomplete: true,
          rounded: true,
          clearable: true,
          details: false
        },
        on: {
          input: event => {
            this.proxy.sendernames = event
          }
        },
        scopedSlots: {
          selection: ({ item, removeByValue }) => {
            return h(
              RowWithMessageTypeIcon,
              {
                props: {
                  value: item.type,
                  title: item.name,
                  as: 'chip',
                  remove: () => {
                    removeByValue(item)
                  }
                }
              }
            )
          },
          item: ({ item }) => {
            return h(
              RowWithMessageTypeIcon,
              {
                props: {
                  value: item.type,
                  title: item.name
                }
              }
            )
          }
        }
      }
    )
  }
}

function renderSelectPatternsField(h, { path, serviceName }) {
  if (this.checkPermissions('advanced.patterns.get', [ 'reseller', true ])) {
    return h(
      'g-select',
      {
        props: {
          value: this.proxy.patterns,
          label: this.getTranslate(`${serviceName}.labels.${path}.patterns`),
          service: 'patterns',
          picks: [ 'id', 'title', 'type' ],
          mode: 'outline-label',
          dense: true,
          multiple: true,
          autocomplete: true,
          rounded: true,
          clearable: true,
          details: false
        },
        on: {
          input: event => {
            this.proxy.patterns = event
          }
        },
        scopedSlots: {
          selection: ({ item, removeByValue }) => {
            return h(
              RowWithMessageTypeIcon,
              {
                props: {
                  value: item.type,
                  title: item.title,
                  as: 'chip',
                  remove: () => {
                    removeByValue(item)
                  }
                }
              }
            )
          },
          item: ({ item }) => {
            return h(
              RowWithMessageTypeIcon,
              {
                props: {
                  value: item.type,
                  title: item.title
                }
              }
            )
          }
        }
      }
    )
  }
}

export default function(h, options) {
  return h(
    'div',
    {
      class: 'grid'
    },
    [
      renderAllowDuplicatesField.call(this, h, options),
      renderSenderNamesCheckField.call(this, h, options),
      renderPatternsCheckField.call(this, h, options),
      renderBalanceField.call(this, h, options),
      renderSelectTariffsField.call(this, h, options),
      renderSelectRoutingGroupsField.call(this, h, options),
      renderSelectSenderNamesField.call(this, h, options),
      renderSelectPatternsField.call(this, h, options)
    ]
  )
}
