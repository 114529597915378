import Vue from 'vue'

import { BILLING_STATUS } from '@sigma-legacy-libs/essentials/lib/constants'

import { getRUB } from '@/utils'

import { serviceName } from '@/components/services/sendings/utils'

import render from './render'

const _ = { get: require('lodash/get') }

export default {
  name: 'Price',

  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      timeout: {},
      billings: [],

      providerAmount: _.get(this.value, 'meta.billing.providerAmount')
    }
  },

  computed: {
    hasProviderAmount() {
      return this.providerAmount !== null && this.providerAmount !== undefined && this.checkPermissions('advanced.sendings.get')
    },

    directives() {
      const result = []

      if (this.hasProviderAmount && !isNaN(this.providerAmount)) {
        result.push({
          name: 'g-tooltip',
          options: {
            value: `${this.getTranslate(`${serviceName}.hints.purchase`)}: ${getRUB(this.providerAmount)}`
          }
        })
      }

      return result
    },

    billingsAmount() {
      return this.billings.reduce((sum, { amount }) => {
        if (sum === undefined) {
          sum = 0
        }

        sum += parseFloat(amount)

        if (isNaN(sum)) {
          sum = undefined
        }

        return sum
      }, undefined)
    },

    refunded() {
      return _.get(this.value, 'meta.billing.refunded') || this.billings.length && this.billings.every(({ status }) => status === BILLING_STATUS.refunded)
    },

    ids() {
      return _.get(this.value, 'meta.billings.ids', [ _.get(this.value, 'meta.billing.id') ])
    },

    priceColor() {
      if (this.hasProviderAmount) {
        switch (true) {
          case this.providerAmount < this.billingsAmount: return 'text--error'
          case this.providerAmount > this.billingsAmount: return 'text--success'
          case this.providerAmount == this.billingsAmount: return 'text--primary'
        }
      }

      return 'text--grey'
    }
  },

  mounted() {
    Vue.$socket.on('billings.created', this.updateBilling)
    Vue.$socket.on('billings.updated', this.updateBilling)
    Vue.$socket.on('billings.removed2', this.removeBilling)

    this.get()
  },

  beforeDestroy() {
    for (const id in this.timeout) {
      if (this.timeout[id]) {
        clearTimeout(this.timeout[id])
      }
    }
  },

  methods: {
    async get() {
      if (!this.hasProviderAmount) {
        this.providerAmount = _.get(this.value, 'meta.billings.provider.amount')
      }

      if (this.ids.length) {
        for (const id of this.ids) {
          try {
            const { data } = await Vue.$GRequest.get('billings', id)
            this.billings.push(data)
            if (this.timeout[id]) {
              clearTimeout(this.timeout[id])
            }
          } catch (error) {
            this.timeout[id] = setTimeout(() => this.get(), 5 * 1000)
          }
        }
      }
    },
    updateBilling(billing) {
      if (billing && billing.id) {
        if (Array.isArray(this.billings) && this.billings.length) {
          const index = this.billings.findIndex(({ id }) => id === billing.id)
          if (~index) {
            this.billings.splice(index, 1, billing)
          }
        }
      }
    },
    removeBilling({ id }) {
      if (id) {
        if (Array.isArray(this.billings) && this.billings.length) {
          const index = this.billings.findIndex(item => item.id === id)
          if (~index) {
            this.billings.splice(index, 1)
          }
        }
      }
    }
  },

  render
}
