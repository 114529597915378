import jwtDecode from 'jwt-decode'

import { socketsRenew } from '@/utils'

const _ = { toSafeInteger: require('lodash/toSafeInteger') }

let timer = false

export default {
  namespaced: true,

  state: {
    token: false
  },

  mutations: {
    setToken(state, token) {
      if (token) {
        window.localStorage.setItem('token', token)
        state.token = token
      } else {
        window.localStorage.removeItem('token')
        state.token = false
      }
    }
  },

  actions: {
    setTimer({ state, dispatch }) {
      if (timer) {
        clearInterval(timer)
      }
      timer = setInterval(() => {
        if (state.token) {
          dispatch('login/logout', null, { root: true })
        }
        clearInterval(timer)
      }, 60 * 60 * 1000)
    },

    setToken({ commit }, token) {
      let decoded
      if (token) {
        const now = _.toSafeInteger(Date.now() / 1000)
        decoded = jwtDecode(token)
        if (now >= decoded.exp) {
          token = false
        }
      }

      commit('setToken', token)

      return decoded
    },

    async setAccountFromToken({ dispatch, state }) {
      if (state.token) {
        const decode = jwtDecode(state.token)

        await dispatch('setTimer')
        await dispatch('account/getAccount', decode.id, { root: true })

        socketsRenew()
      } else {
        if (timer) {
          clearInterval(timer)
        }

        dispatch('account/setAccount', false, { root: true })
      }
    }
  },

  getters: {
    token: state => state.token
  }
}
