export default {
  title: 'Хранилище',

  titles: {
    locks: 'Блокировки',
    properties: 'Свойства файла',
    editLocks: 'Редактировать блокировки файла'
  },

  headers: {
    title: 'Название',
    owner: 'Владелец',
    size: 'Размер',
    createdAt: 'Дата создания',
    expiredAt: 'Срок хранения'
  },

  labels: {
    title: 'Название файла'
  },

  hints: {
    emptyState: 'Нет файлов для отображения.',
    expire: 'Это временное хранилище. Все файлы имеют срок хранения и будут удалены по его достижению.',
    export: 'Пожалуйста подождите, ваш PDF файл формируется',
    locks:
      'Этот файл используется в одном или нескольких сервисах и не будет удалён автоматически по истечению срока хранения.',
    editData: 'Редактировать содержимое файла',
    editMeta: 'Редактировать информацию о файле'
  },

  prefixes: {
    id: 'ID',
    filename: 'Имя файла',
    size: 'Размер',
    created: 'Дата создания',
    updated: 'Дата обновления',
    expired: 'Срок хранения',
    owner: 'Владелец',
    locked: 'Заблокирован',
    locks: 'Заблокирован в следующих сервисах',
    hash: 'Хэш',
    duration: 'Продолжительность',
    channels: 'Каналы',
    sampleRate: 'Sample Rate',
    bitsPerSample: 'BitsPerSample',
    willBeDeleted: 'Файл будет удалён'
  },

  buttons: {
    setExpiredAt: 'Установить срок хранения',
    editLocks: 'Редактировать блокировки',
    properties: 'Совйства файла'
  },

  snackbars: {
    created: 'Файл успешно создан.',
    updated: 'Файл успешно обновлён.',
    removed: 'Файл успешно удалён.'
  }
}
