import Prism from 'prismjs'
import 'prismjs/components/prism-json.js'

export function renderSudoDataButton(h, source) {
  return h(
    'g-button',
    {
      class: 'ma-0',
      props: {
        icon: 'code',
        flat: true,
        color: 'info'
      },
      on: {
        click: () => {
          this.sudoData.source = Prism.highlight(JSON.stringify(source, null, 2), Prism.languages.json, 'json')
          this.sudoData.showDialog = true
          this.$emit('sudoData', this.sudoData)
        }
      }
    }
  )
}
