export default {
  title: 'Регистрация',

  labels: {
    username: 'Логин',
    password: 'Пароль',
    firstName: 'Имя',
    lastName: 'Фамилия',
    email: 'E-mail',
    phone: 'Телефон',
    usedReferralCode: 'Реферальный код'
  },

  buttons: {
    signin: 'Войти',
    signup: 'Зарегистрироваться'
  },

  tooltips: {
    password: {
      generate: 'Сгенерировать пароль'
    }
  },

  hints: {
    username: 'Минимум 5, максимум 25 символов',
    password: 'Пароль',
    firstName: 'Имя',
    lastName: 'Фамилия',
    email: 'Для восстановления пароля и уведомлений о балансе',
    phone: 'Телефон',
    required: 'Поля отмеченные звёздочкой обязательны.'
  }
}
