import { unVue } from '@sigma-legacy-libs/unvue'

import { attach, serviceTemplate } from '@/utils'

import { serviceName } from '@/components/services/messageRuleGroups/utils'

export default {
  mixins: [
    serviceTemplate({
      serviceName,

      inputFilter(result) {
        if (Array.isArray(result.MessageRules) && result.MessageRules.length) {
          result._MessageRules = unVue(result.MessageRules)
          result.MessageRules = result.MessageRules.map(item => {
            return {
              id: item.id,
              priority: item.priority,
              $attach: true
            }
          }).sort((a, b) => (a.priority || 0) - (b.priority || 0))
        } else {
          result.MessageRules = []
        }

        return result
      },
      outputFilter(result) {
        if (Array.isArray(result.MessageRules) && result.MessageRules.length) {
          attach(result, 'MessageRules')
        } else {
          result.MessageRules = undefined
        }

        return result
      }
    })
  ]
}
