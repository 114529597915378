import Vue from 'vue'

import { TASK_STATES } from '@sigma-legacy-libs/essentials/lib/constants'

import { getISODate } from '@/utils'

import analytics from '@/components/services/tasks/analytics'
import batchParams from '@/components/services/tasks/batchParams'
import SourceBox from '@/components/source/box'

const _ = { get: require('lodash/get') }

function renderMessageLink(h) {
  const text = _.get(this.value, 'data.sending.payload.button.text')
  const url = _.get(this.value, 'data.sending.payload.button.url')

  if (text && url) {
    return h(
      'g-button',
      {
        props: {
          flat: true,
          icon: 'link'
        },
        on: {
          click: () => {
            window.open(url, '_blank')
          }
        }
      }
    )
  }
}

function renderPauseButton(h) {
  if (this.checkPermissions('advanced.tasks.update', [ 'me', 'reseller', true ])) {
    if (~[ TASK_STATES.running, TASK_STATES.scheduled ].indexOf(this.value.state)) {
      return h(
        'g-button',
        {
          props: {
            flat: true,
            icon: 'pause',
            color: 'secondary'
          },
          on: {
            click: () => {
              this.updateTask(this.value.id, TASK_STATES.paused)
            }
          }
        }
      )
    }
  }
}

function renderRunOrDelayButton(h) {
  if (this.checkPermissions('advanced.tasks.update', [ 'me', 'reseller', true ])) {
    if (this.value.state === TASK_STATES.paused) {
      return h(
        'g-menu',
        {
          class: 'mx-2',
          props: {
            closeOnContentClick: false,
            placement: 'bottom-end',
            offsetDistance: 16,
            rounded: true
          }
        },
        [
          h(
            'g-button',
            {
              props: {
                flat: true,
                icon: 'play_arrow',
                color: 'secondary'
              },
              slot: 'activator'
            }
          ),

          h(
            'div',
            {
              class: 'fc',
              props: { flat: true }
            },
            [
              h(
                'g-date-picker',
                {
                  props: {
                    value: this.changeableDate.date,
                    localeTag: this.locale,
                    range: false,
                    min: new Date().setDate(new Date().getDate() - 1),
                    filter: dates => dates.map(date => date && getISODate(date))
                  },
                  on: {
                    input: event => {
                      this.changeableDate.date = event
                    }
                  }
                }
              ),

              h(
                'div',
                {
                  class: 'px-2'
                },
                [
                  h(
                    'g-text-field',
                    {
                      props: {
                        value: this.changeableDate.time,
                        type: 'time',
                        mode: 'outline',
                        dense: true,
                        rounded: true,
                        details: false
                      },
                      on: {
                        input: event => {
                          this.changeableDate.time = event
                        }
                      }
                    }
                  )
                ]
              ),

              h(
                'g-card-actions',
                {
                  class: 'fjcfe'
                },
                [
                  h(
                    'g-button',
                    {
                      props: {
                        label: this.computedDate ? this.getTranslate('tasks.actions.schedule') : this.getTranslate('tasks.actions.start'),
                        rounded: true,
                        depressed: true,
                        loading: this.loading,
                        disabled: this.loading,
                        color: 'secondary'
                      },
                      on: {
                        click: () => {
                          this.updateTask(this.value.id, TASK_STATES.scheduled, this.computedDate)
                        }
                      }
                    }
                  )
                ]
              )
            ]
          )
        ]
      )
    }
  }
}

function renderSourceDataDialog(h) {
  return h(
    'g-dialog',
    {
      props: {
        value: this.showSourceDataDialog,
        close: true,
        rounded: true,
        maxWidth: 1000
      },
      on: {
        input: event => {
          this.showSourceDataDialog = event
        }
      }
    },
    [
      h(
        SourceBox,
        {
          props: { value: this.value },
          on: {
            close: event => {
              this.showSourceDataDialog = event
            }
          }
        }
      )
    ]
  )
}
function renderSourceListItem(h) {
  if (this.checkPermissions('advanced.tasks.update')) {
    return h(
      'g-list-item',
      {
        props: {
          label: this.getTranslate('misc.sourceData'),
          icon: 'code',
          color: 'primary'
        },
        on: {
          click: () => {
            this.showSourceDataDialog = !this.showSourceDataDialog
          }
        }
      }
    )
  }
}

function renderStatisticListItem(h) {
  const groupId = _.get(this.value, 'data.sending.groupId')
  if (this.value.type === 'sendings:bulk' && groupId) {
    return h(
      'g-list-item',
      {
        props: {
          label: this.getTranslate('tasks.labels.analytics'),
          icon: 'assessment'
        },
        on: {
          click: () => {
            const $lt = new Date(this.value.updatedAt)
            $lt.setMinutes($lt.getMinutes() + 1)

            Vue.router.push({
              name: 'sendings',
              query: { currentTab: 'outgoing' },
              params: {
                filter: {
                  groupId,
                  createdAt: {
                    $gt: this.value.createdAt,
                    $lt: $lt.toISOString()
                  }
                }
              }
            })
          }
        }
      }
    )
  }
}

function renderRemoveListItem(h) {
  if (this.checkPermissions('advanced.tasks.remove', [ 'me', 'reseller', true ])) {
    if (!~[ TASK_STATES.running, TASK_STATES.scheduled ].indexOf(this.value.state)) {
      return h(
        'g-list-item',
        {
          props: {
            icon: 'delete',
            color: 'error',
            label: this.getTranslate('misc.buttons.remove')
          },
          on: {
            click: () => {
              this.showConfirmRemoveDialog = true
            }
          }
        }
      )
    }
  }
}

function renderConfirmRemoveDialog(h) {
  return h(
    'g-dialog',
    {
      props: {
        value: this.showConfirmRemoveDialog,
        rounded: true,
        maxWidth: 300
      },
      on: {
        input: event => {
          this.showConfirmRemoveDialog = event
        }
      }
    },
    [
      h(
        'div',
        {
          class: 'pa-3'
        },
        [ this.getTranslate('tasks.contents.confirm.remove', { title: this.value.title }) ]
      ),

      h(
        'g-card-actions',
        {
          class: 'fjcfe'
        },
        [
          h(
            'g-button',
            {
              props: {
                flat: true,
                rounded: true,
                label: this.getTranslate('misc.buttons.cancel')
              },
              on: {
                click: () => {
                  this.showConfirmRemoveDialog = false
                }
              }
            }
          ),

          h(
            'g-button',
            {
              props: {
                flat: true,
                rounded: true,
                color: 'error',
                label: this.getTranslate('misc.buttons.remove')
              },
              on: {
                click: () => {
                  this.removeTask(this.value.id)
                }
              }
            }
          )
        ]
      )
    ]
  )
}

function renderMenu(h) {
  return h(
    'g-menu',
    {
      class: 'ml-2',
      props: { rounded: true }
    },
    [
      h(
        'g-button',
        {
          props: {
            flat: true,
            icon: 'more_vert'
          },
          slot: 'activator'
        }
      ),

      h(
        'g-list',
        {
          props: {
            dense: true,
            rounded: true
          }
        },
        [
          renderSourceListItem.call(this, h),
          renderStatisticListItem.call(this, h),

          h(analytics, { props: { value: this.value } }),

          renderRemoveListItem.call(this, h)
        ]
      )
    ]
  )
}

export default function(h) {
  return h(
    'g-card-actions',
    {
      class: 'fjcfe ff hover-child'
    },
    [
      renderMessageLink.call(this, h),

      h(batchParams, { props: { value: this.value } }),

      renderPauseButton.call(this, h),
      renderRunOrDelayButton.call(this, h),

      renderMenu.call(this, h),

      renderSourceDataDialog.call(this, h),
      renderConfirmRemoveDialog.call(this, h)
    ]
  )
}
