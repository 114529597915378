import { TARIFFS_SOURCES } from '@sigma-legacy-libs/essentials/lib/constants'

import { serviceName, sourceColors } from '@/components/services/tariffs/utils'

import RowWithMessageTypeIcon from '@/components/misc/RowWithMessageTypeIcon'

export default function(h) {
  return h(
    'div',
    {
      class: {
        'grid grid-gap--8': true,
        'grid-cols--1': this.viewport.breakpoint.smDown
      },
      style: { 'grid-template-columns': this.viewport.breakpoint.mdUp ? 'repeat(2, 250px)' : undefined }
    },
    [
      h(
        'g-select',
        {
          props: {
            value: this.proxy.type,
            label: this.getTranslate(`${serviceName}.labels.type`),
            items: this.types,
            mode: 'outline',
            rounded: true,
            dense: true,
            details: false,
            clearable: true
          },
          on: {
            input: event => {
              this.proxy.type = event
            }
          },
          scopedSlots: {
            selection: ({ item }) => {
              return h(
                RowWithMessageTypeIcon,
                {
                  props: {
                    value: item.value ? item.value.split('.')[1] : undefined,
                    title: item.title
                  }
                }
              )
            },
            item: ({ item }) => {
              return h(
                RowWithMessageTypeIcon,
                {
                  props: {
                    value: item.value ? item.value.split('.')[1] : undefined,
                    title: item.title
                  }
                }
              )
            }
          }
        }
      ),

      h(
        'g-select',
        {
          props: {
            value: this.proxy.source,
            label: this.getTranslate(`${serviceName}.labels.source`),
            items: TARIFFS_SOURCES.map(value => {
              return {
                title: this.getTranslate(`${serviceName}.sources.${value === 'data' ? 'data.new' : value}`),
                value
              }
            }),
            mode: 'outline',
            rounded: true,
            dense: true,
            details: false,
            clearable: true
          },
          on: {
            input: event => {
              this.proxy.source = event
            }
          },
          scopedSlots: {
            item: ({ item }) => {
              return h(
                'div',
                {
                  class: 'grid faic',
                  style: {
                    'grid-template-columns': '24px 1fr'
                  }
                },
                [
                  h(
                    'div',
                    {
                      class: `bg--${sourceColors[item.value] || 'grey'} square--24`,
                      style: {
                        'border-radius': '50%'
                      }
                    }
                  ),

                  item.title
                ]
              )
            }
          }
        }
      )
    ]
  )
}
