export const fillStringZeros = (value, targetLength = 2, padString = '0') => {
  value = '' + value
  if (value.length > targetLength) {
    value = value.slice(-targetLength)
  }
  value = value.padStart(targetLength, padString)

  return value
}

export default { fillStringZeros }
