import titleDivider from '@/components/title/divider'
import generatorRow from '@/components/generator/row'
import RoutesFormType from '@/components/services/routes/form/type'
import RoutesFormTitle from '@/components/services/routes/form/title'

import { serviceName } from '@/components/services/providerGroups/utils'

function renderTypeField(h) {
  if (!this.type) {
    return h(
      RoutesFormType,
      {
        props: {
          value: this.proxy,
          serviceName
        },
        on: {
          input: event => {
            this.proxy = event
          }
        }
      }
    )
  }
}

function renderTitleField(h) {
  return h(
    RoutesFormTitle,
    {
      props: {
        value: this.proxy,
        serviceName
      },
      on: {
        input: event => {
          this.proxy = event
        }
      }
    }
  )
}

function renderProvidersFields(h) {
  if (Array.isArray(this.proxy.Providers)) {
    return h(
      'div',
      {
        class: 'grid grid-cols--1'
      },
      [
        h(titleDivider, { props: { value: this.getTranslate(`${serviceName}.subtitles.providers`) } }),

        h(
          generatorRow,
          {
            props: {
              value: this.proxy.Providers,
              disabled: this.disabled,
              defaultValue: {
                id: undefined,
                priority: 0,
                $attach: true
              },
              sortable: true,
              sortableProperty: 'priority'
            },
            scopedSlots: {
              row: ({ item }) => {
                const attrs = {}
                if (item.id) {
                  attrs.href = `#/admin/routes/providers/${item.id}`
                }

                return h(
                  'div',
                  {
                    class: 'grid faic',
                    style: { 'grid-template-columns': '24px 1fr' }
                  },
                  [
                    h(
                      'a',
                      {
                        attrs,
                        class: 'link link--passive square--24'
                      },
                      [
                        h(
                          'g-icon',
                          {
                            props: {
                              value: 'link',
                              color: 'grey'
                            }
                          }
                        )
                      ]
                    ),

                    h(
                      'g-select',
                      {
                        props: {
                          value: item.id,
                          label: this.getTranslate(`${serviceName}.labels.provider`),
                          disabled: this.disabled,
                          itemsDisabled: this.proxy.Providers.map(({ id }) => id),
                          service: 'providers',
                          query: { isActive: true },
                          clearable: true,
                          mode: 'outline',
                          rounded: true,
                          dense: true,
                          details: false,
                          autocomplete: true
                        },
                        on: {
                          input: event => {
                            item.id = event
                          }
                        }
                      }
                    )
                  ]
                )
              }
            },
            on: {
              input: event => {
                this.proxy.Providers = event
              }
            }
          }
        )
      ]
    )
  }
}

export default function(h) {
  return h(
    'g-form',
    {
      class: 'grid',
      style: { 'grid-template-columns': '100%' },
      on: {
        submit: () => {
          this.$emit('submit', this.proxy)
        }
      }
    },
    [
      renderTypeField.call(this, h),
      renderTitleField.call(this, h),
      renderProvidersFields.call(this, h)
    ]
  )
}
