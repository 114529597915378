import EntityInfo from '@/components/misc/EntityInfo'
import ExpandArrow from '@/components/misc/ExpandArrow'
import TemplatesForm from '@/components/services/templates/form'
import messageTypeIcon from '@/components/message/type/icon'
import ConfirmRemoveButton from '@/components/misc/ConfirmRemoveButton'

import { serviceName } from '@/components/services/templates/utils'

function renderUseTemplateButton(h) {
  return h(
    'g-button',
    {
      class: { 'hover-child': this.viewport.breakpoint.mdUp },
      props: {
        label: this.viewport.breakpoint.mdUp ? this.getTranslate(`${serviceName}.buttons.use`) : undefined,
        icon: 'insert_drive_file',
        flat: true,
        rounded: true,
        small: true,
        color: 'primary',
        disabled: !~this.sendingTypes.findIndex(({ value }) => value === this.proxy.type)
      },
      on: {
        click: () => {
          this.$emit('template', this.proxy.id)
        }
      }
    }
  )
}

export default function(h) {
  return h(
    'g-expansion-panel',
    {
      props: { preventClick: true },
      scopedSlots: {
        header: ({ toggle, expanded }) => {
          return h(
            'div',
            {
              class: 'faic position-relative hover-parent hover-parent--opacity'
            },
            [
              h(
                'div',
                {
                  class: 'fjcc facc faic square--48'
                },
                [
                  h(
                    messageTypeIcon,
                    {
                      props: {
                        value: this.proxy.type,
                        size: 20
                      }
                    }
                  )
                ]
              ),

              h('div', { class: 'text-overflow pr-3' }, this.proxy.title),

              h('div', { class: 'ff' }),

              renderUseTemplateButton.call(this, h, expanded),

              h(
                ExpandArrow,
                {
                  props: {
                    expanded,
                    toggle
                  }
                }
              )
            ]
          )
        }
      }
    },
    [
      h(
        'div',
        {
          class: 'fc'
        },
        [
          h(
            TemplatesForm,
            {
              props: {
                value: this.proxy
              },
              on: {
                input: event => {
                  this.proxy = event
                }
              }
            }
          ),

          h(EntityInfo, { props: { value: this.proxy } }),

          h(
            'g-card-actions',
            {
              class: 'fjcfe facfe'
            },
            [
              h(
                ConfirmRemoveButton,
                {
                  props: {
                    content: this.getTranslate(`${serviceName}.contents.confirm.remove`, { title: this.proxy.title }),
                    loading: this.restData[serviceName].remove.state === 'loading',
                    disabled: this.restData[serviceName].remove.state === 'loading',
                    callback: () => {
                      this.removeTemplate()
                    }
                  }
                }
              ),

              h(
                'g-button',
                {
                  props: {
                    label: this.getTranslate('misc.buttons.update'),
                    rounded: true,
                    depressed: true,
                    loading: this.restData[serviceName].update.state === 'loading',
                    disabled: this.restData[serviceName].update.state === 'loading',
                    color: 'primary'
                  },
                  on: {
                    click: () => {
                      this.updateTemplate()
                    }
                  }
                }
              )
            ]
          )
        ]
      )
    ]
  )
}
