import HeadTitle from '@/components/misc/HeadTitle'
import DocumentTemplatesGenerateForm from '@/components/services/documentTemplates/generate/form'

const name = 'documentTemplates'

function renderDialog(h) {
  return h(
    'g-dialog',
    {
      props: {
        rounded: true,
        value: this.showDialog,
        closeOnEsc: false,
        closeOnClick: false,
        maxWidth: 500
      },
      on: {
        input: event => {
          this.showDialog = event
        }
      }
    },
    [
      h(
        HeadTitle,
        {
          props: { value: this.getTranslate(`${name}.titles.generate`) },
          slot: 'header'
        }
      ),

      h(
        DocumentTemplatesGenerateForm,
        {
          class: 'pa-3',
          props: {
            value: this.payload,
            OwnerId: this.OwnerId,
            ResellerId: this.ResellerId
          },
          on: {
            input: event => {
              this.payload = event
            },
            show: event => {
              this.showDialog = event
            }
          }
        }
      ),

      h(
        'g-card-actions',
        {
          class: 'fjcfe facfe',
          slot: 'footer'
        },
        [
          h(
            'g-button',
            {
              props: {
                label: this.getTranslate('misc.buttons.close'),
                flat: true,
                rounded: true,
                disabled: this.loading
              },
              on: {
                click: () => {
                  this.showDialog = false
                }
              }
            }
          ),

          h(
            'g-button',
            {
              props: {
                label: this.getTranslate('misc.buttons.download'),
                rounded: true,
                depressed: true,
                loading: this.loading,
                disabled: this.loading || !this.validation,
                color: 'primary'
              },
              on: {
                click: () => {
                  this.generate()
                }
              }
            }
          )
        ]
      )
    ]
  )
}

export default function(h) {
  return h(
    'g-button',
    {
      props: {
        flat: true,
        rounded: true,
        icon: 'description'
      },
      directives: [
        {
          name: 'g-tooltip',
          options: {
            value: this.getTranslate(`${name}.buttons.download`),
            placement: 'right'
          }
        }
      ],
      on: {
        click: () => {
          this.showDialog = true
        }
      },
      scopedSlots: {
        dialog: () => renderDialog.call(this, h)
      }
    }
  )
}
