import componentNotFound from '@/components/misc/componentNotFound'

export default function(h, { serviceName }) {
  try {
    return h(
      require(`@/components/services/${serviceName}/item/${this.type}`).default,
      {
        props: {
          value: this.value,
          size: this.size
        }
      }
    )
  } catch (error) {
    return h(componentNotFound)
  }
}
