export default {
  title: 'Documentation',

  categories: {
    platform: 'Platform',
    api: 'API'
  },

  file: {
    title: 'Documentation'
  },

  files: {
    instruction: 'Instruction',
    'HTTP-REST': 'HTTP-REST',
    SMPP: 'SMPP'
  },

  buttons: {
    toc: 'Contents'
  }
}
