export default {
  title: 'Routes',

  titles: {
    full: {
      title: 'Full route',
      create: {
        route: 'Route',
        routingRules: 'Routing rule',
        providerGroups: 'Provider group'
      }
    },
    direct: {
      title: 'Direct route',
      create: 'Create direct route'
    },
    create: 'Create route'
  },

  subtitles: {
    routingRules: 'Routing rules',
    providerGroups: 'Provider groups'
  },

  labels: {
    direct: {
      provider: {
        choose: 'Choose provider'
      }
    },
    title: 'Route title',
    routingRules: 'Choose routing rule',
    providerGroups: 'Choose provider groups'
  },

  hints: {
    direct: {
      choose: 'Found a similar route.<br>Choose one of the already created direct routes.'
    }
  },

  buttons: {
    full: 'Full',
    direct: 'Direct',
    inRoute: 'In route',
    inRule: 'In rule',
    create: {
      rule: 'Rule',
      group: 'Group'
    }
  },

  types: {
    full: 'Full',
    direct: 'Direct'
  }
}
