import Vue from 'vue'

import draggable from '@/components/misc/draggable'
import routesConditions from '@/components/trees/routes/conditions'
import messageRuleGroupsCreateFull from '@/components/services/messageRuleGroups/create/full'
import messageRuleGroupsCreateDirect from '@/components/services/messageRuleGroups/create/direct'
import messageRulesActions from '@/components/trees/messageRules/actions'

import { isObjectEmpty } from '@/utils'

import { serviceName } from '@/components/services/messageRuleGroups/utils'

function renderAddButton(h) {
  return h(
    'g-button',
    {
      class: this.showAddMessageRuleGroupMenu ? 'my-0 ml-0' : 'ma-0',
      props: {
        flat: true,
        icon: this.showAddMessageRuleGroupMenu ? 'clear' : 'add',
        color: this.showAddMessageRuleGroupMenu ? 'black' : 'secondary',
        label: this.showAddMessageRuleGroupMenu ? undefined : this.getTranslate(`${serviceName}.buttons.add`),
        rounded: true
      },
      on: {
        click: () => {
          this.showAddMessageRuleGroupMenu = !this.showAddMessageRuleGroupMenu
        }
      }
    }
  )
}

function renderAddMenuContent(h) {
  if (this.showAddMessageRuleGroupMenu) {
    return h(
      'div',
      {
        class: 'add-widget__menu'
      },
      [
        h(
          'g-select',
          {
            props: {
              value: this.groupSelector,
              label: this.getTranslate(`${serviceName}.labels.group`),
              itemsDisabled: this.proxy.map(({ id }) => id),
              service: 'messageRuleGroups',
              query: { isActive: true },
              mode: 'outline',
              details: false,
              dense: true,
              rounded: true,
              autocomplete: true,
              itemsWithTooltips: true
            },
            on: {
              input: event => {
                this.messageRuleGroupsEventProcessing(event)
              }
            }
          }
        ),

        h(
          'g-menu',
          {
            props: {
              rounded: true,
              offsetSkidding: 8,
              offsetDistance: -38
            }
          },
          [
            h(
              'g-button',
              {
                class: 'my-0',
                props: {
                  flat: true,
                  icon: 'add',
                  color: 'secondary'
                },
                slot: 'activator'
              }
            ),

            h(
              'g-list',
              {
                props: {
                  dense: true,
                  rounded: true
                }
              },
              [
                h(
                  'g-list-item',
                  {
                    props: {
                      label: this.getTranslate(`${serviceName}.dialogs.types.full`)
                    },
                    on: {
                      click: () => {
                        this.showDialog.full = true
                      }
                    }
                  }
                ),
                h(
                  'g-list-item',
                  {
                    props: {
                      label: this.getTranslate(`${serviceName}.dialogs.types.direct`)
                    },
                    on: {
                      click: () => {
                        this.showDialog.direct = true
                      }
                    }
                  }
                )
              ]
            )
          ]
        ),

        h(
          messageRuleGroupsCreateFull,
          {
            props: {
              show: this.showDialog.full
            },
            on: {
              input: event => {
                this.messageRuleGroupsEventProcessing(event)
              },
              show: event => {
                this.showDialog.full = event
              }
            }
          }
        ),
        h(
          messageRuleGroupsCreateDirect,
          {
            props: {
              show: this.showDialog.direct
            },
            on: {
              input: event => {
                this.messageRuleGroupsEventProcessing(event)
              },
              show: event => {
                this.showDialog.direct = event
              }
            }
          }
        )
      ]
    )
  }
}

function renderAddMenu(h) {
  if (this.viewport.breakpoint.mdUp) {
    return h(
      'transition',
      {
        props: { name: 'route-show-menu' }
      },
      [ renderAddMenuContent.call(this, h) ]
    )
  }

  return renderAddMenuContent.call(this, h)
}

function renderExpand(h) {
  if (this.proxy.length > 1) {
    return h(
      'div',
      {
        class: `${serviceName}-tree__section link`
      },
      [
        h(
          'div',
          {
            class: {
              [`${serviceName}-tree__row`]: true,
              [`${serviceName}-tree__row--expand`]: true,
              [`${serviceName}-tree__row--loading`]: this.expandLoading,
              [`${serviceName}-tree__row--expanded`]: this.expanded,
              [`${serviceName}-tree__row--collapsed`]: !this.expanded
            },
            on: {
              click: () => {
                this.expandAll()
              }
            }
          },
          this.expanded ? this.getTranslate('commons.collapse.all') : this.getTranslate('commons.expand.all')
        )
      ]
    )
  }
}

function renderHeader(h) {
  return h(
    'div',
    {
      class: 'grid grid-cols--1 grid-gap--8'
    },
    [
      h(
        'div',
        {
          class: 'add-widget'
        },
        [
          renderAddButton.call(this, h),
          renderAddMenu.call(this, h)
        ]
      ),

      renderExpand.call(this, h)
    ]
  )
}

function renderRow(h, options = {}) {
  return h(
    'div',
    {
      class: {
        [`${serviceName}-tree__row`]: true,
        [`${serviceName}-tree__row--${this.getRowState(options.service, options.id)}`]: true
      },
      on: {
        click: event => {
          this.clickRow(event, options)
        }
      }
    },
    [
      h(
        'div',
        {
          class: `${serviceName}-tree__title`,
          directives: [
            {
              name: 'g-tooltip',
              options: { value: options.title }
            }
          ]
        },
        options.title
      ),

      h('div', { class: `${serviceName}-tree__description` }, this.getTranslate(`${options.service}.one`)),
      h('div', { class: `${serviceName}-tree__info` }, options.info)
    ]
  )
}

function renderConditions(h, conditions) {
  if (Array.isArray(conditions) && conditions.length) {
    return h(routesConditions, { props: { value: conditions } })
  }
}

function renderActions(h, actions) {
  if (!isObjectEmpty(actions)) {
    return h(
      messageRulesActions,
      {
        props: {
          value: Object.keys(actions).map(tag => {
            return {
              tag,
              value: actions[tag]
            }
          })
        }
      }
    )
  }
}

function renderMessageRules(h, parentId) {
  if (Array.isArray(this.MessageRules[parentId]) && this.MessageRules[parentId].length) {
    const service = 'messageRules'

    return this.MessageRules[parentId].map(({ id, title, conditions, actions }) => {
      return h(
        'div',
        {
          class: `${serviceName}-tree__level ${serviceName}-tree__level--2`
        },
        [
          h(
            'div',
            {
              class: {
                [`${serviceName}-tree__section`]: true,
                'hover-parent hover-parent--opacity': true
              }
            },
            [
              h(
                'div',
                {
                  class: {
                    [`${serviceName}-tree__row`]: true,
                    [`${serviceName}-tree__row--childless`]: true
                  }
                },
                [
                  h(
                    'div',
                    {
                      class: `${serviceName}-tree__title`,
                      directives: [
                        {
                          name: 'g-tooltip',
                          options: { value: title }
                        }
                      ]
                    },
                    title
                  ),

                  h('div', { class: `${serviceName}-tree__description` }, this.getTranslate(`${service}.one`))
                ]
              ),

              h(
                'g-button',
                {
                  class: {
                    'my-0 mr-0': true,
                    'hover-child': this.viewport.breakpoint.mdUp
                  },
                  props: {
                    icon: 'edit',
                    flat: true,
                    small: true
                  },
                  on: {
                    click: () => {
                      Vue.router.push(`/admin/messageProcessing/${service}/${id}`)
                    }
                  }
                }
              )
            ]
          ),

          h(
            'div',
            {
              class: 'grid grid-gap--8'
            },
            [
              renderConditions.call(this, h, conditions),
              renderActions.call(this, h, actions)
            ]
          )
        ]
      )
    })
  }
}

function renderMessageRuleGroupsRemoveButton(h, { id }) {
  if (!this.readonly) {
    return h(
      'g-menu',
      {
        props: {
          value: this.showConfirmRemoveMenu[id],
          closeOnContentClick: false,
          transparent: true,
          placement: 'right',
          offsetDistance: -32
        },
        on: {
          input: event => {
            Vue.set(this.showConfirmRemoveMenu, id, event)
          }
        }
      },
      [
        h(
          'g-button',
          {
            class: 'my-0 ml-1 mr-0',
            props: {
              icon: 'remove',
              color: 'error',
              flat: true,
              small: true
            },
            slot: 'activator',
            on: {
              click: () => {
                Vue.set(this.showConfirmRemoveMenu, id, true)
              }
            }
          }
        ),

        h(
          'g-card',
          {
            class: 'py-2 px-3',
            props: { rounded: true }
          },
          [
            h(
              'div',
              {
                class: 'faic'
              },
              [
                this.getTranslate('misc.buttons.remove') + '?',

                h(
                  'div',
                  {
                    class: 'link link--dashed link--error ml-2',
                    on: {
                      click: () => {
                        this.removeMessageRuleGroup(id)
                      }
                    }
                  },
                  this.getTranslate('misc.buttons.yes')
                )
              ]
            )
          ]
        )
      ]
    )
  }
}

function renderContent(h) {
  if (this.proxy.length) {
    return h(
      draggable,
      {
        class: `${serviceName}-tree`,
        props: { value: this.proxy },
        scopedSlots: {
          item: ({ id, title }) => {
            return h(
              'div',
              {
                class: `${serviceName}-tree__level ${serviceName}-tree__level--1`,
                key: id
              },
              [
                h(
                  'div',
                  {
                    class: {
                      [`${serviceName}-tree__section`]: true,
                      'hover-parent hover-parent--opacity': true,
                      'hover--active': this.showConfirmRemoveMenu[id]
                    }
                  },
                  [
                    renderRow.call(this, h, {
                      id,
                      title,
                      service: serviceName,
                      click: () => this.getEntities(serviceName, 'MessageRules', id),
                      dblclick: () => this.getAllEntities(serviceName, 'MessageRules', id)
                    }),

                    h(
                      'div',
                      {
                        class: {
                          'fjcfe facfe': true,
                          'hover-child': this.viewport.breakpoint.mdUp
                        }
                      },
                      [
                        h(
                          'g-button',
                          {
                            class: 'my-0 mr-0',
                            props: {
                              icon: 'edit',
                              flat: true,
                              small: true
                            },
                            on: {
                              click: () => {
                                Vue.router.push(`/admin/messageProcessing/${serviceName}/${id}`)
                              }
                            }
                          }
                        ),

                        renderMessageRuleGroupsRemoveButton.call(this, h, { id })
                      ]
                    )
                  ]
                ),

                h(
                  'div',
                  {
                    class: {
                      [`${serviceName}-tree__box`]: true,
                      [`${serviceName}-tree__box--${this.getRowState(serviceName, id)}`]: true
                    }
                  },
                  [ renderMessageRules.call(this, h, id) ]
                )
              ]
            )
          }
        }
      }
    )
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: 'grid grid-cols--1 grid-gap--8'
    },
    [
      renderHeader.call(this, h),
      renderContent.call(this, h)
    ]
  )
}
