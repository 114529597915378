export function renderReleaseBucketButton(h, options = {}) {
  if (this.restData[options.name].find.bucketLength > 0) {
    return h(
      'g-button',
      {
        style: { 'min-width': 'auto' },
        class: 'my-0',
        props: {
          label: this.restData[options.name].find.bucketLength,
          icon: 'add',
          rounded: true,
          depressed: true,
          disabled: this.restData[options.name].find.state === 'loading',
          color: 'primary'
        },
        on: {
          click: () => {
            this.rest[options.name].bucketRelease()
          }
        }
      }
    )
  }
}
