import { CONTACTLIST_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

export default function(h) {
  return h(
    'g-form',
    {
      class: 'grid grid-cols--1',
      on: {
        submit: () => {
          this.$emit('submit', this.proxy)
        },
        validation: event => {
          this.$emit('validation', event)
        }
      }
    },
    [
      h(
        'g-text-field',
        {
          props: {
            value: this.proxy.title,
            label: this.getTranslate('contactLists.labels.title'),
            required: true,
            autofocus: true,
            details: false,
            mode: 'outline',
            rounded: true
          },
          on: {
            input: event => {
              this.proxy.title = event
            }
          }
        }
      ),

      h(
        'g-select',
        {
          props: {
            value: this.proxy.type,
            label: this.getTranslate('contactLists.labels.type'),
            items: CONTACTLIST_TYPES.map(type => ({
              title: this.getTranslate(`contactLists.types.${type}`),
              value: type
            })),
            error: this.errors.type,
            required: true,
            details: false,
            mode: 'outline',
            rounded: true
          },
          on: {
            input: event => {
              this.proxy.type = event
            }
          }
        }
      )
    ]
  )
}
