import proxy from '@sigma-legacy-libs/g-proxy'

import { unVue } from '@sigma-legacy-libs/unvue'

import { DEFAULT_INVOICE_PAYLOAD } from '@/utils'

import render from './render'

const _ = { defaultsDeep: require('lodash/defaultsDeep') }

export default {
  name: 'InvoiceDialog',

  mixins: [ proxy({ type: 'object' }) ],

  props: {
    OwnerId: String,
    ResellerId: String,

    showForm: Boolean
  },

  computed: {
    isValid() {
      return (
        this.proxy.requisiteId &&
        this.proxy.companyId &&
        this.proxy.type &&
        !isNaN(this.proxy.data.SERVICE_PRICE) &&
        !(this.proxy.data.SERVICE_PRICE <= 0)
      )
    }
  },

  methods: {
    _inputFilter(data) {
      if (!data) {
        return unVue(DEFAULT_INVOICE_PAYLOAD)
      }

      _.defaultsDeep(data, DEFAULT_INVOICE_PAYLOAD)

      return data
    }
  },

  render
}
