import SingleLink from '@/components/misc/SingleLink'
import messageType from '@/components/services/sendernames/item/type'
import title from '@/components/services/sendernames/item/title'
import moderation from '@/components/services/sendernames/item/moderation'
import moderationButtons from '@/components/services/sendernames/item/moderation/buttons'
import comments from '@/components/services/sendernames/item/comments'
import buttons from '@/components/services/sendernames/item/buttons'

function renderOwner(h) {
  if (this.checkPermissions('advanced.users.get')) {
    return h(
      SingleLink,
      {
        props: {
          value: this.value.Owner || this.value.OwnerId
        }
      }
    )
  }
}

function renderTitle(h) {
  return h(title, { props: { value: this.value } })
}

function renderModeration(h) {
  return h(moderation, { props: { value: this.value } })
}

function renderDescription(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8 small text--grey',
      style: { 'grid-template-columns': 'repeat(2, minmax(min-content, max-content))' }
    },
    [
      renderModeration.call(this, h),
      renderOwner.call(this, h)
    ]
  )
}

function renderFirstRow(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8 faic',
      style: { 'grid-template-columns': '36px 1fr auto' }
    },
    [
      h(messageType, { props: { value: this.value } }),

      h(
        'div',
        {
          class: 'grid grid-gap--2'
        },
        [
          renderTitle.call(this, h),
          renderDescription.call(this, h)
        ]
      ),

      h(buttons, { props: { value: this.value } })
    ]
  )
}

function renderSecondRow(h) {
  if (~[ 'medium', 'large' ].indexOf(this.size) && this.hasComments) {
    return h(comments, { props: { value: this.value } })
  }
}

function renderThirdRow(h) {
  if (~[ 'medium', 'large' ].indexOf(this.size)) {
    return h(moderationButtons, { props: { value: this.value } })
  }
}

export default function(h) {
  return h(
    'g-card',
    {
      class: 'hover-parent hover-parent--opacity pa-2',
      props: {
        outline: true,
        rounded: true,
        accent: true,
        accentColorName: this.value.isActive ? 'success' : 'error'
      }
    },
    [
      h(
        'div',
        {
          class: 'grid grid-gap--8'
        },
        [
          renderFirstRow.call(this, h),
          renderSecondRow.call(this, h),
          renderThirdRow.call(this, h)
        ]
      )
    ]
  )
}
