import serversProperties from '@/components/services/servers/properties'

import { serviceName } from '@/components/services/servers/utils'

export default function(h) {
  return h(
    'g-form',
    {
      class: 'grid pa-3',
      style: { 'grid-template-columns': 'repeat(1, minmax(200px, 1fr))' },
      on: {
        submit: () => {
          this.$emit('submit', this.proxy)
        }
      }
    },
    [
      h(
        'div',
        {
          class: 'faic'
        },
        [
          h(
            'g-text-field',
            {
              props: {
                value: this.proxy.title,
                label: this.getTranslate(`${serviceName}.labels.title`),
                mode: 'outline',
                rounded: true,
                dense: true,
                clearable: true,
                required: true,
                details: false,
                autofocus: true
              },
              on: {
                input: event => {
                  this.proxy.title = event
                }
              }
            }
          ),

          h(
            'g-switch',
            {
              class: 'ml-3',
              props: { value: this.proxy.isActive },
              on: {
                input: event => {
                  this.proxy.isActive = event
                }
              }
            }
          )
        ]
      ),

      h(
        'g-text-field',
        {
          props: {
            value: this.proxy.host,
            label: this.getTranslate(`${serviceName}.labels.host`),
            mode: 'outline',
            rounded: true,
            dense: true,
            clearable: true,
            required: true,
            details: false
          },
          on: {
            input: event => {
              this.proxy.host = event
            }
          }
        }
      ),

      h(
        'g-text-field',
        {
          props: {
            value: this.proxy.port,
            label: this.getTranslate(`${serviceName}.labels.port`),
            type: 'number',
            mode: 'outline',
            step: 'any',
            rounded: true,
            dense: true,
            clearable: true,
            required: true,
            details: false
          },
          on: {
            input: event => {
              this.proxy.port = event
            }
          }
        }
      ),

      h(
        'g-select',
        {
          class: 'mr-3',
          props: {
            value: this.proxy.processor,
            label: this.getTranslate(`${serviceName}.labels.processor`),
            items: this.processors,
            loading: this.processorsLoading,
            mode: 'outline',
            rounded: true,
            clearable: true,
            autocomplete: true,
            dense: true,
            details: false
          },
          on: {
            input: event => {
              this.proxy.processor = event
            }
          }
        }
      ),

      h(
        serversProperties,
        {
          props: {
            value: this.proxy.properties,
            processor: this.proxy.processor
          },
          on: {
            input: event => {
              this.proxy.properties = event
            }
          }
        }
      )
    ]
  )
}
