import title from '@/components/services/tariffs/item/title'
import buttons from '@/components/services/tariffs/item/buttons'

import { sourceColors } from '@/components/services/tariffs/utils'
import { colorsHex } from '@/utils'

function renderTitle(h) {
  return h(
    'td',
    {
      class: 'pl-2 w--100'
    },
    [ h(title, { props: { value: this.value } }) ]
  )
}

function renderButtons(h) {
  return h(
    'td',
    {
      class: 'pr-2'
    },
    [
      h(
        buttons,
        {
          props: {
            value: this.value
          }
        }
      )
    ]
  )
}

export default function(h) {
  return h(
    'tr',
    {
      class: 'hover-parent hover-parent--opacity',
      style: {
        'border-left': `6px solid ${colorsHex[sourceColors[this.value.source]]}`
      }
    },
    [
      renderTitle.call(this, h),
      renderButtons.call(this, h)
    ]
  )
}
