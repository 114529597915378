export default {
  title: 'Журнал событий платежей',

  tabs: {
    request: 'Запрос',
    response: 'Ответ'
  },

  directions: {
    incoming: 'Входящий',
    outgoing: 'Исходящий'
  },

  hints: {
    notFound: 'Не найдено ни одной записи'
  }
}
