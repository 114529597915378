import ButtonReport from '@/components/button/report'
import HeadTitle from '@/components/misc/HeadTitle'
import billingsForm from '@/components/services/billings/form'
import billingsFilter from '@/components/services/billings/filter'
import billingsTasksTemplate from '@/components/services/billings/tasks/template'

import { serviceName } from '@/components/services/billings/utils'
import { routerQueryReplace } from '@/utils'

const presets = [ `${serviceName}.details`, `${serviceName}.group001` ]

function renderFieldPreset(h) {
  if (this.checkPermissions('advanced.reports.get', [ 'me', 'reseller', true ])) {
    return h(
      'g-select',
      {
        style: {
          'min-width': this.viewport.breakpoint.smDown ? '100%' : '308px',
          'max-width': this.viewport.breakpoint.smDown ? '100%' : '308px'
        },
        class: {
          'mr-2': this.viewport.breakpoint.mdUp,
          'mb-2': this.viewport.breakpoint.smDown
        },
        props: {
          value: this.preset,
          items: presets.map(preset => ({
            title: this.getTranslate(`${serviceName}.presets.${preset}`),
            value: preset
          })),
          required: true,
          mode: 'outline',
          rounded: true,
          dense: true,
          details: false
        },
        on: {
          input: event => {
            this.preset = event
          }
        },
        slot: 'title'
      }
    )
  }
}

function renderButtonFilter(h) {
  return h(
    'g-button',
    {
      class: 'my-0 ml-2',
      props: {
        label: this.viewport.breakpoint.mdUp ? this.getTranslate('misc.buttons.filter') : undefined,
        icon: 'filter_list',
        flat: true,
        rounded: true
      },
      on: {
        click: () => {
          this.showFilter = !this.showFilter
        }
      },
      slot: 'title'
    }
  )
}

function renderFilter(h) {
  if (this.showFilter) {
    return h(
      billingsFilter,
      {
        props: {
          preset: this.preset,
          value: this.filter
        },
        on: {
          input: event => {
            this.filter = event
          }
        },
        slot: 'filter'
      }
    )
  }
}

function renderCreateDialog(h) {
  return h(
    'g-dialog',
    {
      props: {
        rounded: true,
        value: this.showCreateBilling,
        maxWidth: 500
      },
      on: {
        input: event => {
          this.showCreateBilling = event
        }
      }
    },
    [
      h(
        HeadTitle,
        {
          props: {
            value: this.getTranslate(`${serviceName}.dialogs.create`)
          },
          slot: 'header'
        }
      ),

      h(
        billingsForm,
        {
          props: {
            value: this.restData[serviceName].create.data,
            errors: this.restData[serviceName].create.errors
          },
          on: {
            input: event => {
              this.restData[serviceName].create.data = event
            },
            validation: event => {
              this.restData[serviceName].create.isValid = event
            }
          }
        }
      ),

      h(
        'g-card-actions',
        {
          class: 'fjcfe',
          slot: 'footer'
        },
        [
          h(
            'g-button',
            {
              props: {
                label: this.getTranslate('misc.buttons.cancel'),
                flat: true,
                rounded: true,
                disabled: this.restData[serviceName].create.state === 'loading'
              },
              on: {
                click: () => {
                  this.showCreateBilling = false
                }
              }
            }
          ),

          h(
            'g-button',
            {
              props: {
                label: this.getTranslate('misc.buttons.create'),
                rounded: true,
                depressed: true,
                loading: this.restData[serviceName].create.state === 'loading',
                disabled: !this.createDataIsValid,
                color: 'primary'
              },
              on: {
                click: () => {
                  this.billingsCreate()
                }
              }
            }
          )
        ]
      )
    ]
  )
}

function renderCreateButton(h) {
  if (this.checkPermissions(`advanced.${serviceName}.generate`, [ 'reseller', true ])) {
    return h(
      'g-button',
      {
        style: { 'margin-bottom': this.viewport.breakpoint.smDown ? '66px' : undefined },
        props: {
          fab: true,
          icon: 'add',
          fixed: true,
          bottom: true,
          right: true,
          color: 'secondary'
        },
        on: {
          click: () => {
            this.showCreateBilling = true
          }
        },
        scopedSlots: {
          dialog: () => renderCreateDialog.call(this, h)
        }
      }
    )
  }
}

function renderBillingsTabItem(h) {
  return h(
    'g-card',
    {
      props: { rounded: true },
      key: serviceName
    },
    [
      h(
        this.presetNameKebab,
        {
          props: { filter: this.filter },
          on: {
            input: event => {
              this.filter = event
            }
          }
        },
        [
          renderFieldPreset.call(this, h),

          h(
            ButtonReport,
            {
              class: 'ma-0',
              props: {
                preset: this.preset,
                filter: this.filter
              },
              slot: 'title'
            }
          ),

          renderButtonFilter.call(this, h),
          renderFilter.call(this, h)
        ]
      ),

      renderCreateButton.call(this, h)
    ]
  )
}

function renderTasksTabItem(h) {
  if (this.checkPermissions('advanced.tasks.find', [ 'reseller', true ])) {
    return h(billingsTasksTemplate, { key: 'tasks' })
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: 'fc ff'
    },
    [
      h(
        'g-tabs-header',
        {
          class: 'pl-3',
          props: {
            value: this.currentTab,
            tabs: this.tabs.map(key => {
              return {
                key,
                title: this.getTranslate(`${serviceName}.tabs.${key}`)
              }
            })
          },
          on: {
            input: event => {
              routerQueryReplace({ currentTab: event })
            }
          }
        }
      ),

      h(
        'g-tabs',
        {
          props: { value: this.currentTab }
        },
        [
          renderBillingsTabItem.call(this, h),
          renderTasksTabItem.call(this, h)
        ]
      )
    ]
  )
}
