import Vue from 'vue'

import { TASK_STATES } from '@sigma-legacy-libs/essentials/lib/constants'

import { globalErrorHandler, globalErrorProcessor } from '@/utils'

import render from './render'

export default {
  name: 'TasksFrequency',

  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      loading: false
    }
  },

  methods: {
    async changeFrequency(id, frequency) {
      try {
        this.loading = true
        await Vue.$GRequest.update('tasks', id, {
          state: TASK_STATES.paused,
          data: { frequency }
        })
        this.addSnackbar({
          text: this.getTranslate('tasks.snackbars.updated'),
          type: 'success'
        })
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      } finally {
        this.loading = false
      }
    }
  },

  render
}
