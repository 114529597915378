export default {
  title: 'Маршруты',

  titles: {
    full: {
      title: 'Полный маршрут',
      create: {
        route: 'Маршрут',
        routingRules: 'Правило маршрутизации',
        providerGroups: 'Группа провайдеров'
      }
    },
    direct: {
      title: 'Прямой маршрут',
      create: 'Создать прямой маршрут'
    },
    create: 'Создать маршрут'
  },

  subtitles: {
    routingRules: 'Правила маршрута',
    providerGroups: 'Группы провайдеров'
  },

  labels: {
    direct: {
      provider: {
        choose: 'Выберите провайдера'
      }
    },
    title: 'Название маршрута',
    routingRules: 'Выберите правила',
    providerGroups: 'Выберите группы провайдеров'
  },

  hints: {
    direct: {
      choose: 'Найден похожий маршрут.<br>Выберите один из уже созданных прямых маршрутов.'
    }
  },

  buttons: {
    full: 'Полный',
    direct: 'Прямой',
    inRoute: 'В маршрут',
    inRule: 'В правило',
    create: {
      rule: 'Правило',
      group: 'Группа'
    }
  },

  types: {
    full: 'Полный',
    direct: 'Прямой'
  }
}
