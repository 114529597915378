import Vue from 'vue'

import { unVue } from '@sigma-legacy-libs/unvue'
import { Macro } from '@sigma-legacy-libs/cache'
import { mapActions } from 'vuex'
import { isUUID } from '@sigma-legacy-libs/essentials/lib/validators'
import { EMPTY_SENDINGS, SENDING_PAYLOADS, SENDING_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import {
  currentTab,
  generateServices,
  getHammerTime,
  getLocaleDateString,
  getLocaleTimeString,
  globalErrorHandler,
  globalErrorProcessor,
  isArrayNotEmpty,
  isStringNotEmpty,
  sendingsOutputFilter
} from '@/utils'

import { serviceName } from '@/components/services/sendings/utils'

import render from './render'

const _ = {
  isPlainObject: require('lodash/isPlainObject'),
  isArray: require('lodash/isArray'),
  isString: require('lodash/isString'),
  merge: require('lodash/merge'),
  get: require('lodash/get'),
  set: require('lodash/set'),
  pick: require('lodash/pick')
}

const Cache = new Macro({
  ttl: 5 * 1000,
  ttlInterval: 1000
})

const defaultDate = {
  until: 0,
  delay: {
    date: undefined,
    time: getHammerTime()
  }
}

function sendingValidator(data) {
  const { type, payload } = data

  if (!type || !~SENDING_TYPES.indexOf(type)) {
    return false
  }

  if (payload && !payload.sender) {
    return false
  }

  switch (type) {
    case SENDING_TYPES.voice: {
      return !!payload.text && !!payload.tts || !!payload.audio
    }
    case SENDING_TYPES.viber: {
      const buttonText = _.get(payload, 'button.text')
      const buttonUrl = _.get(payload, 'button.url')

      if (payload.image) {
        if (payload.text) {
          return !!(buttonText && buttonUrl)
        }

        return !!payload.image
      } else {
        if (payload.text) {
          if (buttonText || buttonUrl) {
            return buttonText && (buttonText + '').length <= 30 && buttonUrl && (buttonUrl + '').length <= 2048
          }
        }

        return !!payload.text
      }
    }
    case SENDING_TYPES.whatsapp: {
      return !!payload.image || !!payload.file || !!payload.text
    }

    default: {
      return !!payload.text
    }
  }
}

export default {
  name: 'SendingsCreateTemplate',

  mixins: [
    generateServices([
      {
        name: serviceName,

        outputFilter: sendingsOutputFilter,

        find: false,
        get: false,
        update: false,
        remove: false,
        create: {
          async method(data, params = {}) {
            try {
              const { data: result } = await Vue.$GRequest.create(serviceName, data, params)
              if (result.status === 'failed') {
                throw new Error(result.error)
              }

              return result
            } catch (error) {
              throw error
            }
          },
          params: {
            query: {
              $scope: [ 'full' ]
            }
          }
        }
      },
      {
        name: 'templates',

        outputFilter: sendingsOutputFilter,

        find: false,
        get: false,
        update: false,
        remove: false,

        create: {
          params: {
            query: {
              $scope: [ 'full' ]
            }
          }
        }
      }
    ]),

    currentTab('create')
  ],

  data() {
    return {
      showConfirmCreateSendings: false,
      showCreateTemplates: false,
      showEditTemplate: false,
      showScheduled: false,
      showDelayMenu: false,
      showUntilMenu: false,
      showMessagePreview: false,

      timezoneOffset: this.$store.getters['global/date'].getTimezoneOffset(),
      changeableDates: unVue(defaultDate),

      recipientsError: undefined,

      template: undefined
    }
  },

  computed: {
    recipientValidation() {
      const { recipient } = this.restData[serviceName].create.data

      return recipient && Array.isArray(recipient.include) && recipient.include.length > 0
    },

    payloadValidation() {
      return sendingValidator(this.restData[serviceName].create.data)
    },

    fallbacksValidation() {
      const { fallbacks } = this.restData[serviceName].create.data

      if (Array.isArray(fallbacks) && fallbacks.length) {
        return fallbacks.every(fallback => sendingValidator(fallback))
      } else {
        return true
      }
    },

    createDisabled() {
      return !(this.recipientValidation && this.payloadValidation && this.fallbacksValidation)
    },

    computedDate() {
      let delay = 0
      if (this.changeableDates.delay.date) {
        const hours = ('' + Math.floor(Math.abs(this.timezoneOffset / 60))).padStart(2, '0')
        const minutes = ('' + Math.abs(this.timezoneOffset % 60)).padStart(2, '0')
        const timezone = (this.timezoneOffset > 0 ? '-' : '+') + hours + ':' + minutes
        this.dateString = this.changeableDates.delay.date + 'T' + this.changeableDates.delay.time + ':00' + timezone
        delay = new Date(this.changeableDates.delay.date + 'T' + this.changeableDates.delay.time + ':00' + timezone)
      }

      let until
      if (this.changeableDates.until > 0) {
        until = new Date(delay)
        until.setHours(until.getHours() + this.changeableDates.until)
      }

      return {
        delay,
        until
      }
    },

    computedTypeRecipient() {
      const data = this.restData[serviceName].create.data
      if (
        data.schedule && data.schedule.delay ||
        data.recipient.include.length > 100 ||
        data.recipient.include.find(isUUID) ||
        data.recipient.exclude && data.recipient.exclude.length
      ) {
        return 'bulk'
      }
      if (isArrayNotEmpty(data.recipient)) {
        return 'butch'
      }
      if (isStringNotEmpty(data.recipient)) {
        return 'single'
      }
    },

    fallbacks() {
      return this.restData[serviceName].create.data.fallbacks.length > 0
    },

    dateScheduleDelay() {
      if (this.computedDate && this.computedDate.delay) {
        return `${getLocaleDateString(this.computedDate.delay)} ${getLocaleTimeString(this.computedDate.delay)}`
      }

      return undefined
    }
  },

  watch: {
    computedDate: {
      handler() {
        this.restData[serviceName].create.data.schedule = this.computedDate
      },
      deep: true
    },

    async template() {
      try {
        if (this.template) {
          const { data } = await this.cachedGet(`templates:${this.template}`, this.template)
          if (data) {
            const template = _.pick(data, [ 'type', 'payload', 'recipient', 'fallbacks' ])

            for (const key in template) {
              const element = template[key]
              switch (key) {
                case 'type': {
                  if (typeof element === 'string' && element) {
                    this.restData[serviceName].create.data[key] = element
                  }
                  break
                }
                case 'payload': {
                  for (const path of SENDING_PAYLOADS[template.type]) {
                    if (path !== 'recipient') {
                      const value = _.get(element, path, undefined)
                      _.set(this.restData[serviceName].create.data.payload, path, value) // Здесь необходим _.set так как есть path === 'button.url' или path === 'button.text'
                    }
                  }
                  break
                }
                case 'recipient': {
                  if (_.isPlainObject(element)) {
                    for (const prop in element) {
                      this.restData[serviceName].create.data.recipient[prop] = []
                      for (const value of element[prop]) {
                        this.restData[serviceName].create.data.recipient[prop].push(value)
                      }
                    }
                  }
                  break
                }
                case 'fallbacks': {
                  if (Array.isArray(element) && element.length > 0) {
                    this.restData[serviceName].create.data[key] = element
                  }
                  break
                }
              }
            }

            this.setCurrentTab({
              name: 'create',
              to: { name: 'create' }
            })
          }
        }
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      } finally {
        this.template = undefined
      }
    }
  },

  mounted() {
    const tourName = 'create'

    if (
      !window.localStorage.getItem(`tour-${tourName}`) &&
      isArrayNotEmpty(this.sendingTypes) &&
      this.viewport.breakpoint.mdUp
    ) {
      setTimeout(() => {
        if (tourName === this.$route.name) {
          this.$tours[tourName].start()
          window.localStorage.setItem(`tour-${tourName}`, true)
        }
      }, 1000)
    }
  },

  beforeDestroy() {
    if (this.$tours.create.isRunning) {
      this.$tours.create.stop()
    }
  },

  methods: {
    cachedGet: Cache.wrapWithCache(async (key, id) => {
      return await Vue.$GRequest.get('templates', id)
    }),

    templateRecipient(recipient) {
      if (
        _.isArray(this.restData[serviceName].create.data.recipient.include) &&
        !this.restData[serviceName].create.data.recipient.include.length
      ) {
        Vue.set(this.restData[serviceName].create.data.recipient, 'include', recipient.include)
      }
      if (
        _.isArray(this.restData[serviceName].create.data.recipient.exclude) &&
        !this.restData[serviceName].create.data.recipient.exclude.length
      ) {
        Vue.set(this.restData[serviceName].create.data.recipient, 'exclude', recipient.exclude)
      }
    },

    saveTemplate() {
      this.restData.templates.create.data = _.merge(
        {},
        { OwnerId: this.account.id },
        _.pick(unVue(this.restData[serviceName].create.data), [
          'type',
          'payload',
          'recipient',
          'schedule',
          'fallbacks'
        ])
      )
      this.showCreateTemplates = true
    },

    setFallbacks() {
      this.restData[serviceName].create.data.fallbacks.splice(
        this.restData[serviceName].create.data.fallbacks.length,
        0,
        _.pick(unVue(EMPTY_SENDINGS), [
          'type',
          'payload',
          '$options'
        ])
      )
    },

    async createSending() {
      try {
        const result = await this.rest[serviceName].create(this.restData[serviceName].create.data)
        if (result) {
          this.restData[serviceName].create.data = unVue(EMPTY_SENDINGS)
          this.changeableDates = unVue(defaultDate)
          this.showScheduled = false
        }
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      } finally {
        this.showConfirmCreateSendings = false
      }
    },
    async createTemplate() {
      try {
        await this.rest.templates.create(this.restData.templates.create.data)
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      } finally {
        this.showCreateTemplates = false
      }
    },

    ...mapActions({ setCurrentTab: 'currentTab/setCurrentTab' })
  },

  render
}
