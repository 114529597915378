import proxy from '@sigma-legacy-libs/g-proxy'

import { generateAmount } from '@sigma-legacy-libs/essentials/lib/utils'
import { PAYMENTS_COMMISSIONS } from '@sigma-legacy-libs/essentials/lib/constants'

import render from './render'

import { serviceName } from '@/components/services/payments/utils'

export default {
  name: 'PaymentsForm',

  mixins: [ proxy({ type: 'object' }) ],

  props: {
    methods: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    commission() {
      if (this.proxy.method) {
        return PAYMENTS_COMMISSIONS[this.proxy.method]
      }
    },
    commissionHint() {
      if (this.commission) {
        return `${this.getTranslate(`${serviceName}.hints.commission`)} ${this.commission}%`
      }
    }
  },

  watch: {
    $route: {
      handler() {
        this.setOwnerId()
      },
      deep: true
    }
  },

  mounted() {
    this.setOwnerId()
  },

  methods: {
    setOwnerId() {
      const owner = this.$route.params.owner
      if (owner && !this.proxy.OwnerId) {
        this.proxy.OwnerId = owner
      }
    },

    getCommission() {
      const amount = this.proxy.amount || 0
      const amountWithCommission = generateAmount(amount, this.commission)

      return (amountWithCommission - amount).toFixed(2)
    }
  },

  render
}
